import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  rowContainer: {
    marginTop: '2em',
    marginBottom: '1em',
    paddingRight: '1em',
    paddingLeft: '1rem',
    color: theme.palette.primary.main,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  gridItem: {
    marginBottom: 0,
  },
  icon: {
    color: theme.palette.grey[700],
    cursor: 'pointer',
    fontSize: 20,
    marginTop: '5px',
    marginBottom: '8px',
    marginLeft: '4px',
    '&:hover': {
      color: theme.palette.grey[900],
    },
  },
  warningText: {
    fontWeight: 700,
    fontSize: 18,
    color: theme.palette.warning.main,
  },
  pendingChangeButton: {
    color: theme.palette.warning.main,
  },
}));
