import {Container} from '@material-ui/core';
import React, {ReactNode} from 'react';
import {CSSProperties} from '@material-ui/styles';

type Props = {
  children?: ReactNode;
  style?: CSSProperties;
};
export function PrivatePage({children = null, style = {}}: Props) {
  if (children === null) {
    return null;
  }
  return <Container style={{...style}}>{children}</Container>;
}
