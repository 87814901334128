import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useState} from 'react';
import {Modal} from '@src/components/ui/layout/Modal';
import {ApprovalExpiration} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/ApprovalExpiration';
import {GuardianMessage} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/GuardianMessage';
import {OwnershipExpiration} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/OwnershipExpirationStep';
import {OwnershipQuestion} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/OwnershipQuestionStep';
import {OwnershipSelection} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/OwnershipSelectionStep';
import {Success} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/Success';
import {SharedCarePlan, LonelyPatient, User, UserType} from '@src/models';

export const enum FormStates {
  // Guardian only.
  GuardianMessageStep = 'GuardianMessageStep',
  OwnershipSelectionStep = 'OwnershipSelectionStep',

  // Provider only.
  OwnershipQuestionStep = 'OwnershipQuestionStep',
  OwnershipExpirationStep = 'OwnershipExpirationStep',

  SCPCreatedStep = 'SCPCreatedStep',
}

type Props = {
  patient: Optional<LonelyPatient>;
  show: boolean;
  user: Optional<User>;
  userType: UserType;
  onCancel: () => void;
  setScp: (scp: SharedCarePlan) => void;
  setPatient: (p: LonelyPatient) => void;
  onCreated?: () => void;
};

export const CreateSCP = ({
  patient,
  show,
  user,
  userType,
  onCancel,
  setScp,
  setPatient,
  onCreated,
}: Props) => {
  const [formState, setFormState] = useState<FormStates | null>(
    userType === UserType.Professional
      ? FormStates.OwnershipQuestionStep
      : FormStates.GuardianMessageStep,
  );
  const [noOwnership, setNoOwnership] = useState<boolean | null>(null);
  const patientName = patient
    ?.map(p => p.preferredFirstName)
    .orElse('this patient');

  return (
    <Modal title="Create shared care plan" show={show} onClose={onCancel}>
      {formState === FormStates.OwnershipQuestionStep && (
        <OwnershipQuestion
          setFormState={setFormState}
          onCancel={onCancel}
          patientName={patientName}
        />
      )}

      {formState === FormStates.GuardianMessageStep &&
        userType === UserType.Guardian && (
          <GuardianMessage
            patientName={patientName}
            formState={formState}
            setFormState={setFormState}
            onCancel={onCancel}
          />
        )}

      {formState === FormStates.OwnershipSelectionStep && (
        <OwnershipSelection
          onCancel={onCancel}
          patient={Optional.of(patient)}
          userType={userType}
          setFormState={setFormState}
          setNoOwnership={setNoOwnership}
        />
      )}

      {formState === FormStates.OwnershipExpirationStep && (
        <OwnershipExpiration
          patient={Optional.of(patient)}
          onCancel={onCancel}
          providerId={user.get().id}
          setFormState={setFormState}
          userType={userType}
        />
      )}

      {formState === FormStates.SCPCreatedStep && (
        <>
          {userType === UserType.Professional && (
            <ApprovalExpiration
              patient={Optional.of(patient)}
              onCancel={onCancel}
              setScp={setScp}
              setPatient={setPatient}
              onCreated={onCreated}
            />
          )}

          {userType === UserType.Guardian && (
            <Success
              patient={Optional.of(patient)}
              setScp={setScp}
              setPatient={setPatient}
              noOwnership={!!noOwnership}
            />
          )}
        </>
      )}
    </Modal>
  );
};
