import styled from 'styled-components';

export const BasicTable = styled.table<{align?: string}>`
  width: 100%;
  font-weight: 300;
  text-align: ${props => props.align ?? 'center'};
  margin: 0;
  padding: 0;
  border: 0;
`;

export const BasicTableHeadCell = styled.th`
  color: #000;
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 300;
  letter-spacing: 1.2px;
`;
