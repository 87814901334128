import React, {useState, useCallback} from 'react';
import {Grid, FormControlLabel, Switch} from '@material-ui/core';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import {ConnectedLogin} from '../../stepFunctions';
import {ResponsiblePersonCard} from '../../../FinanciallyResponsiblePersonFormModal/ResponsiblePersonCard';
import {InstructionsText} from '../../../../patient/relationships/InvitePerson/InstructionsText';
import {useConnectedLoginStyles} from './styles';
import {SearchByGuardian} from '@src/components/ui/form/search/SearchByGuardian';
import {TextInput, Button} from '@src/components/ui/form';
import {useStyles} from '@src/components/shared/patient/relationships/InvitePerson/styles';
import {useUser} from '@src/hooks/useUser';
import {UserType, ResponsiblePerson} from '@src/models';
import {isEmpty} from '@src/util/typeTests';
import {yObject, rqString, yBoolean} from '@src/schema/types';
import {Modal} from '@src/components/ui/layout/Modal';
import {WarningText} from '@src/components/ui/layout/text/body/WarningText';

type Props = {
  onHide: () => void;
  show: boolean;
  connectedLogins: ConnectedLogin[];
  setConnectedLogins: (logins: ConnectedLogin[]) => unknown;
};

export function AddLoginForm({
  onHide,
  show,
  connectedLogins,
  setConnectedLogins,
}: Props) {
  const [, userType] = useUser();

  const [person, setPerson] = useState<ResponsiblePerson | null>(null);
  const [enterManually, setEnterManually] = useState(true);

  const classes = {...useStyles(), ...useConnectedLoginStyles()};
  const hasIsSelf = connectedLogins.findIndex(login => login.isSelf) !== -1;

  const {
    control,
    errors,
    getValues,
    setValue,
    reset,
    handleSubmit,
    watch,
    formState,
  } = useForm({
    defaultValues: {
      email: '',
      relationshipName: '',
      isSelf: false,
    },
    resolver: yupResolver(
      yObject({
        email: rqString(
          'Please provide an email address for the Connected Login',
        ),
        relationshipName: rqString(
          'Please provide the relationship for the Connected Login',
        ),
        isSelf: yBoolean,
      }),
    ),
  });

  const {touched} = formState;

  const toggleSelf = useCallback(() => {
    const currentIsSelf = getValues('isSelf');
    setValue('isSelf', !currentIsSelf);
    setValue('relationshipName', !currentIsSelf ? 'Self' : '');
  }, [getValues, setValue]);

  const toggleForm = useCallback(() => {
    reset();
    onHide();
  }, [onHide, reset]);

  const setEmail = useCallback(
    (searchedEmail: string) => {
      setValue('email', searchedEmail);
    },
    [setValue],
  );

  const isSelf = watch('isSelf');
  const email = watch('email');
  const relationshipName = watch('relationshipName');

  const emailIsDuplicate = !!connectedLogins.find(l => l.email === email);

  if (!show) return null;

  const isConnectedLogin = userType === UserType.Guardian;
  /**
   * Be careful when changing this as it represents a data privacy risk.
   * Guardians cannot search for other Guardians.
   */
  const doSearch = !isConnectedLogin && !enterManually;

  const form = (
    <>
      <InstructionsText isSelf={isSelf} />
      <Grid container direction="column">
        <Grid item hidden={hasIsSelf}>
          <Controller
            control={control}
            name="isSelf"
            render={({name, value}) => (
              <FormControlLabel
                control={
                  <Switch
                    name={name}
                    checked={value}
                    color="primary"
                    inputProps={{'aria-label': 'checkbox'}}
                    onChange={toggleSelf}
                  />
                }
                label="This is the Patient's email address"
              />
            )}
          />
        </Grid>

        <Grid item hidden={isSelf}>
          <Controller
            control={control}
            required
            title="Connected Login's Relationship"
            name="relationshipName"
            render={props => (
              <TextInput
                onChange={e => {
                  setValue('relationshipName', e.target.value);
                }}
                name="relationshipName"
                title="Connected Login's Relationship"
                value={props.value}
                required
                error={errors.relationshipName?.message}
                touched={touched.relationshipName}
              />
            )}
          />
        </Grid>

        <Grid item className={classes.inputGrid} hidden={!doSearch}>
          <SearchByGuardian
            name="guardian"
            selectedUser={person?.fullName ?? ''}
            selectUser={g => {
              setEmail(g?.email.orElse('') ?? '');
            }}
            renderUser={(user: ResponsiblePerson) => (
              <div>
                {user.fullName} ({user.email.orElse('No Email')})
              </div>
            )}
            title="Search for someone already in the system"
          />
        </Grid>
        <Grid item hidden={doSearch}>
          <Controller
            control={control}
            required
            name="email"
            render={props => (
              <TextInput
                onChange={e => {
                  setValue('email', e.target.value);
                }}
                name="email"
                title={`${
                  isSelf && !isConnectedLogin ? 'Patient' : 'Connected Login'
                } email address`}
                value={props.value}
                required
                placeholder={`Please enter the ${
                  isSelf && !isConnectedLogin
                    ? "patient's"
                    : "Connected Login's"
                } full email address`}
                error={errors.email?.message}
                touched={touched.email}
              />
            )}
          />
        </Grid>

        {emailIsDuplicate && (
          <WarningText warningVariant="warning">
            This email has already been added to the list of Connected Logins.
          </WarningText>
        )}

        {userType !== UserType.Guardian && (
          <Grid item className={classes.inputGrid}>
            <Button
              bStyle="outlined"
              onClick={() => {
                setEnterManually(!enterManually);
                setPerson(null);
                setValue('email', '');
              }}
            >
              {enterManually
                ? `Search for ${
                    isSelf && !isConnectedLogin ? 'patient' : 'Connected Login'
                  }`
                : 'Enter email for new user'}
            </Button>
          </Grid>
        )}
      </Grid>

      {person && <ResponsiblePersonCard relationship={''} person={person} />}
    </>
  );

  const submitButton = (
    <Button
      bStyle="contained"
      bSize="medium"
      disabled={
        email.trim().length === 0 ||
        isEmpty(relationshipName) ||
        emailIsDuplicate
      }
      onClick={handleSubmit(values => {
        setConnectedLogins([...connectedLogins, values]);
        toggleForm();
      })}
    >
      Submit
    </Button>
  );

  return (
    <Modal
      onClose={onHide}
      show={show}
      title={`Invite ${
        isSelf && !isConnectedLogin ? 'Patient' : 'Connected Login'
      }`}
      modalActions={submitButton}
    >
      {form}
    </Modal>
  );
}
