import {Optional} from '@ahanapediatrics/ahana-fp';
import {useStatusAction} from '.';
import {SCPChangeRequestStatusAction, SharedCarePlan} from '@src/models';
import {useRequestHandler} from '@src/hooks/useRequestHandler';

/**
 *
 * Combines use of two generic hooks:
 * `useAction` and `useRequestHandler`
 */

export const useOnReRequest = ({
  scp,
  runBeforeRequest,
  asyncRunAfterRequest,
  runFinally,
  changeRequestId,
  runOnError,
}: {
  scp: Optional<SharedCarePlan>;
  runBeforeRequest: () => void;
  asyncRunAfterRequest: (scp: SharedCarePlan) => Promise<unknown>;
  runFinally: () => void;
  runOnError: () => void;
  changeRequestId: number;
}) => {
  const approve = useStatusAction({
    scp,
    changeRequestId,
    action: SCPChangeRequestStatusAction.ReRequest,
  });

  return useRequestHandler({
    runBeforeRequest,
    asyncRequest: approve,
    asyncRunAfterRequest,
    runFinally,
    runOnError,
  });
};
