import {Optional} from '@ahanapediatrics/ahana-fp';
import {VisitDocumentation} from '@src/models';

type GetCanSignProps = {
  documentation: Optional<VisitDocumentation>;
};

export function getCanSign({documentation}: GetCanSignProps) {
  const signingDate = documentation
    .map(v => v.signature)
    .map(s => s.signingDate)
    .isPresent();

  return !signingDate;
}
