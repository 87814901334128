import React, {useCallback, useState} from 'react';
import {Grid} from '@material-ui/core';
import {usePageContext} from '..';
import {LightHeading} from '@src/components/ui/layout/text/heading/LightHeading';
import {CallPool} from '@src/models';
import {ConfirmModal} from '@src/components/ui/layout/ConfirmModal';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {useOnRemoveProviderGroupEndpoint} from '@src/hooks/providerGroups/endpoints/useRemoveProviderGroupEndpoint';
import {RemovableList} from '@src/components/ui/molecules/lists/RemovableList';
import {NAMES} from '@src/components/ui/layout/text/names';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';

export function Current({callPool}: {callPool: CallPool}) {
  const endpoints = callPool.endpoints;

  const {reloadCallPools} = usePageContext();

  const mappable = endpoints.map(e => ({
    label: e.name,
    id: e.id,
  }));
  const [endpointIdToRemove, setEndpointIdToRemove] = useState<number | null>(
    null,
  );

  const onRemoveEndpoint = useOnRemoveProviderGroupEndpoint({
    runBeforeRequest: useCallback(() => {}, []),
    asyncRunAfterRequest: useCallback(async () => {
      await reloadCallPools();
      flashSuccess('Success');
    }, [reloadCallPools]),
    runFinally: useCallback(() => {}, []),
    runOnError: useCallback(
      () =>
        flashError(
          'Something went wrong removing the Endpoint from this Group',
        ),
      [],
    ),
    groupId: callPool.id,
    endpointId: endpointIdToRemove,
  });

  const handleRemoveClicked = useCallback(
    (id: number) => {
      setEndpointIdToRemove(id);
    },
    [setEndpointIdToRemove],
  );

  const handleCancelRemove = useCallback(() => {
    setEndpointIdToRemove(null);
  }, [setEndpointIdToRemove]);

  return (
    <>
      <Grid item xs={12}>
        <LightHeading>Current</LightHeading>
      </Grid>
      <Grid item xs={12}>
        <RemovableList
          handleActionClicked={handleRemoveClicked}
          mappable={mappable}
          emptyMessage="This Group currently has no Endpoints."
        />
      </Grid>

      <ConfirmModal
        onConfirm={onRemoveEndpoint}
        text={`Confirming will remove this ${toTitleCase(
          NAMES.endpoint,
        )} from the ${toTitleCase(NAMES.callPool)}.`}
        show={endpointIdToRemove !== null}
        onHide={handleCancelRemove}
        onCancel={handleCancelRemove}
        cancelText="Cancel"
        confirmText="Remove Endpoint"
        titleText="Remove Endpoint"
      />
    </>
  );
}
