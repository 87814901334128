import React from 'react';
import {Divider, List, ListItem, ListItemText} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import {Control} from './functions/getControls';

type Props = {
  controls: Control[];
  showButtonText: boolean;
};

export function Controls({controls, showButtonText}: Props) {
  const theme = useTheme();

  return (
    <List style={{padding: '1rem 0'}}>
      {controls.map((control, i) => {
        if (!control.include) {
          return null;
        }

        if (control.divider) {
          return (
            <React.Fragment key={i}>
              <Divider />
            </React.Fragment>
          );
        } else {
          const color =
            control.label === 'End Visit'
              ? theme.palette.error.main
              : control.label === 'Pause Visit'
              ? theme.palette.warning.main
              : 'rgba(0, 0, 0, 0.54)';

          return (
            <React.Fragment key={i}>
              <ListItem
                button
                key={control.label}
                onClick={control.onClick}
                disabled={control.disabled}
                alignItems="flex-start"
                style={{
                  width: '100%',
                  paddingRight: '6px',
                  paddingLeft: '6px',
                }}
              >
                <ListItemText
                  style={{fontSize: '0.3rem', textAlign: 'center', color}}
                  primary={control.icon}
                  secondary={showButtonText ? control.label : null}
                />
              </ListItem>
            </React.Fragment>
          );
        }
      })}
    </List>
  );
}
