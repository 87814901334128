import {makeStyles, Theme, createStyles} from '@material-ui/core';
import {md, mdLg, lgXl, sm, xs} from '@src/components/ui/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootComponent: {
      color: theme.palette.secondary.contrastText,
    },
    loadingLimiter: {
      maxHeight: '200px',
    },
    userList: {
      marginBottom: md,
    },
    userListRow: {
      margin: `${xs} 0`,
      padding: sm,
      backgroundColor: 'rgba(255,255,255,0.2)',
      borderRadius: xs,
    },
    userIcon: {
      paddingRight: sm,
    },
    textBox: {
      textAlign: 'left',
    },
    messageText: {
      fontStyle: 'italic',
    },
    actionGrid: {
      height: mdLg,
      textAlign: 'right',
    },
    userAvatarGrid: {
      height: lgXl,
      paddingRight: sm,
    },
    actionItem: {
      cursor: 'pointer',
    },
    sectionTitle: {
      color: theme.palette.secondary.contrastText,
      marginBottom: sm,
    },
    plusIcon: {
      cursor: 'pointer',
      marginLeft: '1rem',
    },
    connectionText: {
      wordBreak: 'break-word',
    },
  }),
);
