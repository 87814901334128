import styled from 'styled-components';
import {
  error,
  errorText,
  lightGrey,
  primary,
  secondary,
  sm,
  xs,
  xxs,
  success,
  secondaryText,
  successText,
} from '@src/components/ui/theme';

export const UploadingFileStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-bottom: 2px;
  margin-top: 2px;
  }
`;

export const UploadingFileDetails = styled.div<{
  completed: boolean;
  uploadError: boolean;
}>`
  position: relative;
  margin: ${xxs} 0;
  border-radius: ${xs};
  overflow: hidden;
  border: ${props => (props.completed ? 'none' : `solid thin ${lightGrey}`)};
  background-color: ${props =>
    props.uploadError ? error : props.completed ? success : secondary};
  color: ${props =>
    props.uploadError
      ? errorText
      : props.completed
      ? successText
      : secondaryText};
  width: 100%;
  .uploading-file--name {
    font-weight: ${props => (props.completed ? 'bold' : 'normal')};
  }
`;

export const ProgressBar = styled.div<{
  completed: boolean;
  uploadError: boolean;
  progress: number;
}>`
  display: ${props => (props.completed ? 'none' : 'block')};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: ${props => (props.uploadError ? error : primary)};
  width: ${props => props.progress ?? 0}%;
`;

export const UploadingFileName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${xs};
  position: relative;
  z-index: 2;
`;

export const UploadingFileIcon = styled.div<{error: boolean}>`
  padding: ${sm};
  margin-right: ${sm};
  color: ${props => (props.error ? errorText : secondary)};
  text-align: center;
`;
