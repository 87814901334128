import {Room} from 'twilio-video';
import {getDeviceIds, saveDeviceIds} from '../../shared/devices/deviceIds';
import {AdmittanceProps} from './types';
import {
  createLocalTwilioTracksFromInputs,
  stopLocalDataTracks,
} from '@src/util/videoChat';

export type OnAdmittedProps = AdmittanceProps & {
  visitId: number;
  room: Room | null;
};

export async function onAdmitted({
  visitId,
  onMediaFailure,
  audioIn,
  audioOut,
  videoIn,
  room,
}: OnAdmittedProps) {
  stopLocalDataTracks({room});

  const choices = getDeviceIds({visitId, videoIn, audioOut, audioIn});
  const {audioInId, audioOutId, videoInId} = choices;

  const localTracks = await createLocalTwilioTracksFromInputs(onMediaFailure)({
    audioInId,
    videoInId,
    audioOutId,
  });

  room?.localParticipant.publishTracks(localTracks);

  saveDeviceIds({visitId, audioInId, audioOutId, videoInId});
}
