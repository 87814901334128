import {makeNonOptional, Optional} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {StepProps} from '.';
import {useApi} from '@src/api/useApi';
import {flashError} from '@src/components/shared/notifications/flash';
import {CareTeamCard} from '@src/components/ui/molecules/cards/professionals/CareTeamCard';
import {Button} from '@src/components/ui/form';
import {SearchByProvider} from '@src/components/ui/form/search/SearchByProvider';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {useUser} from '@src/hooks';
import {FlowProps} from '@src/hooks/useSteps';
import {Professional, User, UserType} from '@src/models';
import {getProviderDescription} from '@src/util/provider';

type Props = StepProps & FlowProps;

export function ProviderSelectionStep({
  setNextAvailable,
  details,
  setDetails,
}: Props) {
  const [user] = useUser();
  const api = useApi();
  const selfProvider = user
    .getOptional()
    .cast<User & {userType: UserType.Professional}>(
      u => u.userType === UserType.Professional,
    )
    .map<Professional>(Professional.fromUser)
    .map(p => p.providerDetails);

  const [loadingDetails, setLoadingDetails] = useState(
    !(user.isLoaded() || details.isPresent()),
  );

  useEffect(() => {
    setNextAvailable(details.isPresent());
  }, [details, setNextAvailable]);

  return (
    <>
      <RequestHeader>Provider</RequestHeader>
      <RequestInstructions>
        <p>Which Provider will be handling this visit?</p>
      </RequestInstructions>
      <Grid container direction="column">
        <Grid item>
          <SearchByProvider
            required
            name="provider"
            userType="Provider"
            title="Visit Provider"
            placeholder="Please start typing to search"
            selectedUser=""
            selectUser={info => {
              setDetails(Optional.empty());
              console.log('selectUser');
              console.log(info);
              if (info) {
                setLoadingDetails(true);
                api
                  .providerDetails(info.id)
                  .get()
                  .then(makeNonOptional(setDetails))
                  .catch(e => {
                    console.error(e);
                    flashError(
                      "There was a problem loading this Provider's information. Please contact support",
                    );
                  })
                  .finally(() => {
                    setLoadingDetails(false);
                  });
              }
            }}
            renderUser={d => <div>{getProviderDescription(d)}</div>}
          />
        </Grid>
        {selfProvider.isPresent() && (
          <Grid item style={{textAlign: 'right'}}>
            <Button
              bStyle="text"
              onClick={() => {
                setDetails(selfProvider);
                setLoadingDetails(false);
              }}
            >
              I'll take this visit
            </Button>
          </Grid>
        )}
        <Grid item>
          {details
            .map(p => (
              <CareTeamCard
                isLoading={loadingDetails}
                canEdit={false}
                includeMissing={false}
                details={p}
                onEdit={() => {}}
                viewerType={UserType.Professional}
              />
            ))
            .orElse(<p>No Provider currently selected...</p>)}
        </Grid>
      </Grid>
    </>
  );
}
