import {Optional} from '@ahanapediatrics/ahana-fp';
import {PatientDetailsValues} from '../SharedPatient/patientDetailsUtils';
import {ProviderDetailsStep} from '../SharedPatient/ProviderDetailsStep';
import {ConfirmStep} from '../SharedPatient/ConfirmStep';
import {ConnectedLoginsStep} from './steps/ConnectedLoginsStep';
import {MedicalHistoryStep} from './steps/MedicalHistoryStep';
import {MedicalHistoryValues} from './steps/MedicalHistoryStep/PatientMedicalHistoryForm';
import {PatientDetailsStep} from './steps/PatientDetailsStep';
import {StepComponent} from '@src/hooks/useSteps';
import {ProviderDetails, ResponsiblePerson} from '@src/models';

export type NewPatientStep =
  | 'PatientDetails'
  | 'ConnectedLogins'
  | 'ProviderDetails'
  | 'MedicalHistory'
  | 'Confirm';

export type ConnectedLogin = {
  email: string;
  isSelf: boolean;
  relationshipName: string;
};

export type StepProps = {
  patientDetails: PatientDetailsValues;
  setPatientDetails: (d: PatientDetailsValues) => unknown;
  details: Optional<ProviderDetails>;
  setDetails: (details: Optional<ProviderDetails>) => unknown;
  providerNotKnown: boolean;
  setProviderNotKnown: (b: boolean) => unknown;
  medicalHistory: MedicalHistoryValues;
  setMedicalHistory: (v: MedicalHistoryValues) => unknown;
  includeMedicalHistory: boolean;
  setIncludeMedicalHistory: (b: boolean) => unknown;
  existingPersonForIndependentPatient: Optional<ResponsiblePerson>;
  setExistingPersonForIndependentPatient: (
    v: Optional<ResponsiblePerson>,
  ) => unknown;
  patientIsCurrentUser: boolean;
  setPatientIsCurrentUser: (b: boolean) => unknown;
  setConnectedLogins: (cls: ConnectedLogin[]) => unknown;
  connectedLogins: ConnectedLogin[];
};

/**
 * This maps a step type to its name
 */
export const StepNames: Record<NewPatientStep, string> = {
  PatientDetails: 'Patient Details',
  ConnectedLogins: 'Connected Logins',
  ProviderDetails: 'Provider Details',
  MedicalHistory: 'Medical History',
  Confirm: 'Confirm',
};

/**
 * This maps a step type to its component
 */
export const StepComponents: Record<
  NewPatientStep,
  StepComponent<StepProps>
> = {
  PatientDetails: PatientDetailsStep,
  ConnectedLogins: ConnectedLoginsStep,
  ProviderDetails: ProviderDetailsStep,
  MedicalHistory: MedicalHistoryStep,
  Confirm: ConfirmStep,
};
