import classnames from 'classnames';
import React, {useState, useCallback} from 'react';
import {codeToLabel, SystemCode} from '../sections/systems';
import {
  Selection,
  SystemsInputStyled,
  SystemState,
  NegativeReview,
  PositiveReview,
  ClearReview,
} from './layout';
import {TextInput} from '@src/components/ui/form';

type Props = {
  code: SystemCode;
  error?: string;
  getAgeAppropriateNegativeSummary: (code: SystemCode) => string;
  onChange: (v: {positive: boolean | null; remarks: string}) => void;
  placeholder?: string;
  value: {positive: boolean | null; remarks: string};
};

export function SystemsInput({
  code,
  error,
  getAgeAppropriateNegativeSummary,
  value,
  onChange,
  placeholder,
}: Props) {
  const [statusSelected, setStatusSelected] = useState(value.positive !== null);
  const [remarksAutoPopulated, setRemarksAutoPopulated] = useState(false);

  const selectStatus = useCallback(
    (positive: boolean | null) => {
      if (positive === value.positive) {
        return;
      }
      let remarks = positive === null ? '' : value.remarks;

      if (!positive && !remarksAutoPopulated) {
        remarks = getAgeAppropriateNegativeSummary(code);
        setRemarksAutoPopulated(true);
      }

      setStatusSelected(positive !== null);
      onChange({
        remarks,
        positive,
      });
    },
    [
      code,
      getAgeAppropriateNegativeSummary,
      onChange,
      remarksAutoPopulated,
      value,
    ],
  );

  return (
    <SystemsInputStyled>
      <Selection>
        <div className="systems-input--type">{codeToLabel(code)}</div>

        <SystemState className="systems-input--system-state">
          <NegativeReview
            icon={'minus'}
            size="2x"
            fixedWidth
            className={classnames([
              {
                selected: statusSelected && !value.positive,
                'not-selected': statusSelected && value.positive,
              },
            ])}
            onClick={() => selectStatus(false)}
          />
          <PositiveReview
            icon={'plus'}
            size="2x"
            fixedWidth
            className={classnames([
              {
                selected: statusSelected && value.positive,
                'not-selected': statusSelected && !value.positive,
              },
            ])}
            onClick={() => selectStatus(true)}
          />
          <ClearReview
            icon={'trash'}
            size="2x"
            fixedWidth
            className={classnames([
              {
                visible: statusSelected,
              },
            ])}
            onClick={() => {
              selectStatus(null);
              setStatusSelected(false);
              setRemarksAutoPopulated(false);
            }}
          />
        </SystemState>
      </Selection>
      {statusSelected && (
        <div>
          <TextInput
            name="remarks"
            autoExpand
            placeholder={placeholder}
            value={value.remarks}
            onChange={e => {
              onChange({...value, remarks: e.target.value});
              setRemarksAutoPopulated(false);
            }}
            error={error}
          />
        </div>
      )}
    </SystemsInputStyled>
  );
}
