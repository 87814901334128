import {useMemo} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {compareDesc} from 'date-fns';
import {
  SCPChangeRequest,
  SCPChangeRequestStatus,
  SharedCarePlan,
} from '@src/models';

export function useApprovedRequestForCurrent({
  changeRequests,
  property,
  scp,
}: {
  changeRequests: AsyncData<SCPChangeRequest>;
  property: keyof SharedCarePlan;
  scp: AsyncData<SharedCarePlan>;
}) {
  const currentValue: string = scp
    .getOptional()
    .map(m => m[property])
    .cast<string>(v => typeof v === 'string')
    .orElse('');

  return useMemo(
    () =>
      changeRequests
        .getAllOptional()
        .orElse([])
        .filter(
          cr =>
            cr.property === property &&
            cr.status === SCPChangeRequestStatus.Approved &&
            cr.newValue === currentValue,
        )
        .sort((a, b) => compareDesc(b.updatedAt, a.updatedAt))[0],
    [changeRequests, currentValue, property],
  );
}
