import {useApi} from '@src/api/useApi';
import {Resources, useResources} from '@src/hooks';
import {ProviderDetails} from '@src/models';

export const useGroupsProviders = (
  periodId: number,
  includeNonSearchable?: boolean,
): Resources<ProviderDetails> => {
  const api = useApi();

  return useResources(
    () =>
      api
        .onCallPeriods(periodId)
        .getGroupsProviders()
        .then(p => {
          if (includeNonSearchable) {
            return p;
          } else {
            return p.filter(professional => professional.searchable);
          }
        }),
    [api, periodId],
    {
      requestGate: () => {
        return periodId !== 0;
      },
    },
  );
};
