import {makeStyles} from '@material-ui/core/styles';
import {backdrop, xl, md, sm} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  overviewContainer: {
    backgroundColor: `${backdrop}`,
    minWidth: '70vw',
    paddingBottom: xl,
  },
  backContainer: {
    padding: `${sm} ${md} 0 0`,
  },
  addProviderModal: {
    minWidth: '45vw',
  },
  alertGrid: {
    padding: md,
  },
  monthNavigation: {
    padding: md,
  },
  monthNavigationLabel: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));
