import React, {useEffect, useState, useCallback} from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {Typography, useTheme, Grid} from '@material-ui/core';
import {useStyles} from './layout';
import {Modal} from '@src/components/ui/layout/Modal';
import {AsyncActionButton} from '@src/components/ui/form';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {ProviderDetails, UserType} from '@src/models';
import {useApi} from '@src/api/useApi';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {SearchByProvider} from '@src/components/ui/form/search/SearchByProvider';
import {CareTeamCard} from '@src/components/ui/molecules/cards/professionals/CareTeamCard';
import {getProviderDescription} from '@src/util/provider/providerDescription';
import {isNothing, isSomething} from '@src/util/typeTests';

type Props = {
  show: boolean;
  setShow: (v: boolean) => unknown;
  callPoolId?: number;
  memberIds: ProviderDetailsId[];
  onAdded: () => unknown;
};

export function AddProviderModal({
  show,
  setShow,
  callPoolId = 0,
  memberIds,
  onAdded,
}: Props) {
  const [details, setDetails] = useState<ProviderDetails | null>();
  const [alreadyMember, setAlreadyMember] = useState(false);
  const theme = useTheme();
  const api = useApi();
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addMember = useCallback(() => {
    api
      .callPool(callPoolId)
      .addMember(Optional.of(details).property('id', 0 as ProviderDetailsId))
      .then(() => {
        onAdded();
        flashSuccess('Provider added to Call Pool');
      })
      .catch(() => {
        flashError(
          'Oops! Something went wrong adding the Provider to this Call Pool',
        );
      })
      .finally(() => {
        setIsSubmitting(false);
        setShow(false);
      });
  }, [api, callPoolId, details, onAdded, setIsSubmitting, setShow]);

  useEffect(() => {
    if (memberIds.some(memberId => memberId === details?.id)) {
      setAlreadyMember(true);
    } else {
      setAlreadyMember(false);
    }
  }, [details, memberIds]);

  if (!callPoolId) {
    return (
      <Typography
        style={{
          fontWeight: 700,
          fontSize: 18,
          color: theme.palette.warning.main,
        }}
      >
        Oops! That Call Pool doesn't have a valid ID.
      </Typography>
    );
  }

  return (
    <Modal
      show={show}
      onClose={() => {
        setShow(false);
      }}
      title="Add Provider To Call Pool"
      modalActions={
        <AsyncActionButton
          actionInProgress={isSubmitting}
          actionWord={'Submit'}
          onClick={addMember}
          bStyle="primary"
          disabled={!details || alreadyMember}
        />
      }
    >
      <Grid container className={classes.addProviderModal} direction="column">
        <Grid item xs={12}>
          <SearchByProvider
            name="provider"
            userType={UserType.Professional}
            title="Provider"
            placeholder="Search our system for a Provider"
            disabled={false}
            selectedUser={details?.fullName ?? ''}
            selectUser={setDetails}
            renderUser={user =>
              user ? <div>{getProviderDescription(user)}</div> : <div />
            }
            touched={false}
          />
        </Grid>
        {alreadyMember && isSomething(details) && (
          <Grid item xs={12}>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: 18,
                color: theme.palette.warning.main,
                margin: '1rem 0',
              }}
            >
              This Provider is already a member of the selected Call Pool.
            </Typography>
          </Grid>
        )}
        {!isNothing(details) && (
          <Grid item xs={12}>
            <CareTeamCard
              canEdit={false}
              includeMissing={false}
              details={details}
              onEdit={() => {}}
              viewerType={UserType.Professional}
            />
          </Grid>
        )}
      </Grid>
    </Modal>
  );
}
