import {flashNotify, flashWarn} from './components/shared/notifications/flash';

export const handleQueryMessage = () => {
  console.info('Handling query messages...');
  const searchParams = new URLSearchParams(window.location.search);
  const queryMessage = searchParams.get('message') || '';
  if (searchParams.get('success') === 'true') {
    if (/new password/.test(queryMessage)) {
      flashNotify(
        'Password reset: Your password has been successfully updated.',
      );
    } else {
      flashNotify(
        `Email Verified: Your email address has been verified.
         ${
           searchParams.get('passwordNeedsSetting')
             ? 'You will soon get an email that will let you create a new password.'
             : 'You can now log in and use Refyne Connected Care.'
         }`,
      );
    }
  }

  if (searchParams.get('failure')) {
    if (/alreadyVerified/.test(queryMessage)) {
      flashNotify(
        'Your email address is already verified. You can go ahead and login',
      );
    }

    if (/verificationDuplication/.test(queryMessage)) {
      flashWarn(
        'It looks like you are trying to use a verification link that has already been used. Please log in to request a new one',
      );
    }

    if (/accessExpired/.test(queryMessage)) {
      flashWarn(
        'It looks like you are trying to use a verification link that has expired. Please log in to request a new one',
      );
    }
  }
};
