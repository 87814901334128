import styled from 'styled-components';
import {md, sm, xxs} from '../theme';

export const ButtonColumnContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  button {
    margin: ${xxs};
  }
`;

export const ButtonRowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  button {
    margin: ${sm} ${md};
  }

  button:first-child {
    margin-left: 0;
  }

  button:last-child {
    margin-right: 0;
  }
`;

export const ButtonRowCenteredContainer = styled(ButtonRowContainer)`
  justify-content: center;
`;
