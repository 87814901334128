import {isAfter} from 'date-fns';
import React, {useCallback, useEffect} from 'react';
import {useHistory} from 'react-router';
import {RemoteDataStatus} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import {Groups} from './Groups';
import {OnCall} from './OnCallSection';
import {useApi} from '@src/api/useApi';
import {PrivatePage} from '@src/components/PrivatePage';
import {Section, SectionTitle} from '@src/components/ui/layout/InfoPage';
import {PageHeader} from '@src/components/ui/layout/PageHeader';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {useAsync, useResources} from '@src/hooks';
import {useUser} from '@src/hooks/useUser';
import {OnCallPeriod, toProfessional} from '@src/models';
import {UserId} from '@src/models/User';
import {getAllPages} from '@src/util/apiHelpers/getAllPages';
import {byStartAsc} from '@src/util/sorters/byStart';
import {Button} from '@src/components/ui/form';

// eslint-disable-next-line import/no-unused-modules
export default function ShiftsAndGroupsPage() {
  const api = useApi();
  const history = useHistory();
  const [user, userType] = useUser();

  const providerId = user
    .getOptional()
    .map(u => u.id)
    .orElse(0 as UserId);

  const getGroupsRequestGate = useCallback(() => {
    return providerId !== 0;
  }, [providerId]);

  const [onCallPeriods, setOnCallPeriods] = useAsync<OnCallPeriod>();
  const [manages] = useResources(
    () =>
      api
        .provider(providerId)
        .getGroups({managedOnly: true, includeVisitsUnsupported: true}),
    [api, providerId],
    {requestGate: getGroupsRequestGate},
  );

  const [belongsTo] = useResources(
    () =>
      api
        .provider(providerId)
        .getGroups({memberOnly: true, includeVisitsUnsupported: true}),
    [api, providerId],
    {requestGate: getGroupsRequestGate},
  );

  const goToReporting = useCallback(() => history.push('/reporting'), [
    history,
  ]);

  const getPeriods = useCallback(() => {
    getAllPages(options =>
      api.provider(providerId).getOnCallPeriods(options),
    ).then(result => {
      const periods = result
        .filter(p => isAfter(p.end, new Date()))
        .sort(byStartAsc);
      setOnCallPeriods(periods);
    });
  }, [api, providerId, setOnCallPeriods]);

  const deleteOnCallPeriod = useCallback(
    (period: OnCallPeriod) => {
      api
        .provider(providerId)
        .deleteOnCallPeriod(period.id)
        .then(() => {
          setOnCallPeriods(
            onCallPeriods
              .filter(oncallPeriod => oncallPeriod.id !== period.id)
              .getAllOptional()
              .orElse([]),
          );
        });
    },
    [api, onCallPeriods, providerId, setOnCallPeriods],
  );

  useEffect(() => {
    if (providerId) {
      if (onCallPeriods.is(RemoteDataStatus.NotAsked)) {
        setOnCallPeriods();
        getPeriods();
      }
    }
  });

  const professional = user.getOptional().cast(toProfessional);
  if (!professional.isPresent()) {
    return null;
  }

  const onCallNumber = professional.flatMap(u => u.phone);
  const details = professional.map(i => i.providerDetails);

  const seesPatients = details.property('seesPatients', false);

  return (
    <PrivatePage>
      <PageHeader>Shifts and Groups</PageHeader>
      {seesPatients && (
        <Section>
          <SectionTitle>My Shifts</SectionTitle>
          <ParagraphText>
            All times are listed in the local timezone of your computer.
          </ParagraphText>
          <OnCall
            onCallNumber={onCallNumber}
            onCallPeriods={onCallPeriods}
            onCreateOnCallPeriods={periods => {
              let updatedPeriods = onCallPeriods;
              periods.forEach(period => {
                const preExisting = onCallPeriods.findIndex(
                  oc => oc.id === period.id,
                );
                if (preExisting === -1) {
                  updatedPeriods = updatedPeriods.concat(period);
                } else {
                  updatedPeriods = updatedPeriods.update(preExisting, period);
                }
              });
              setOnCallPeriods(updatedPeriods.getAllOptional().orElse([]));
            }}
            onDeleteOnCallPeriod={deleteOnCallPeriod}
            providerId={providerId}
          />
        </Section>
      )}

      <Groups belongsTo={belongsTo} manages={manages} userType={userType} />
      <Section>
        <SectionTitle>Reporting</SectionTitle>
        <ParagraphText>Click below to access reporting</ParagraphText>
        <Grid container>
          <Grid item>
            <Button onClick={goToReporting}>Group Reporting</Button>
          </Grid>
        </Grid>
      </Section>
    </PrivatePage>
  );
}
