import * as H from 'history';
import React from 'react';
import {Button} from '../../../ui/form';
import {flashError} from '../../notifications/flash';
import {LonelyVisit, LonelyPatient} from '@src/models';
import {NotAuthorizedError} from '@src/api/exceptions';
import {AppAPI} from '@src/api/AppAPI';

export const getVisitGenerator = (api: AppAPI) => (
  setVisit: (v: LonelyVisit) => unknown,
  setPatient: (p: LonelyPatient) => unknown,
  history: H.History,
) => (visitId: number, patient: LonelyPatient | undefined) => {
  if (!patient) {
    api
      .visit(visitId)
      .get()
      .then(v => {
        setVisit(v);
        setPatient(v.patient);
      })
      .catch(e => {
        if (e instanceof NotAuthorizedError) {
          flashError(
            <>
              You are not authorized to enter this Visit.
              <br />
              Please contact Support if you believe that you should be.
            </>,
          );
          history.push('/dashboard');
        }
      });
  }
};

type ModalActionsProps = {
  devicesReady: boolean;
  enterExamRoom: () => unknown;
  goBack: () => unknown;
};
export function ModalActions({
  devicesReady,
  enterExamRoom,
  goBack,
}: ModalActionsProps) {
  return (
    <>
      <Button bStyle="outlined" onClick={goBack}>
        Cancel
      </Button>
      <Button
        bStyle="contained"
        disabled={!devicesReady}
        onClick={enterExamRoom}
      >
        Enter Exam Room
      </Button>
    </>
  );
}
