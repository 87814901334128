import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useRef, useCallback} from 'react';
import {APIWithSearch} from '@src/api/APIWithSearch';
import {useAsync} from '@src/hooks';

export const useSuggestionGetter = <T>({
  api,
  filter = () => true,
  includeNonSearchable,
}: {
  api: APIWithSearch<T>;
  filter?: (val: T) => boolean;
  includeNonSearchable?: boolean;
}): [AsyncData<T>, (str: string) => void] => {
  const currentSearch = useRef('');
  const [userSuggestions, setSuggestions, reset] = useAsync<T>();

  const getSuggestions = useCallback(
    (value: string) => {
      const searchString = value.trim();
      if (searchString.length < 3) {
        currentSearch.current = '';
        reset();
      } else if (currentSearch.current !== searchString) {
        currentSearch.current = searchString;
        setSuggestions();
        api.search(searchString, includeNonSearchable).then(s => {
          if (currentSearch.current === value) {
            setSuggestions(s.filter(filter));
          }
        });
      }
    },
    [api, filter, includeNonSearchable, reset, setSuggestions],
  );
  return [userSuggestions, getSuggestions];
};
