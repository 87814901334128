import {useCallback, useMemo, useState} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useOnAddGroupDocument} from './useAddGroupDocument';
import {LegalDocument} from '@src/models';
import {useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {
  flashSuccess,
  flashError,
} from '@src/components/shared/notifications/flash';
import {NAMES} from '@src/components/ui/layout/text/names';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';

export type AddableSectionResources = {
  addableDocuments: AsyncData<LegalDocument>;
  handleAddClicked: (v: number) => unknown;
  handleCancelAdd: () => unknown;
  onAddDocument: () => Promise<void>;
  documentIdToAdd: number | null;
};

export function useAddableSectionResources({
  editDocumentsForGroupId,
  reloadGroupDocuments,
}: {
  editDocumentsForGroupId: number | null;
  reloadGroupDocuments: () => Promise<unknown>;
}): AddableSectionResources {
  const api = useApi();

  const [documentIdToAdd, setDocumentIdToAdd] = useState<number | null>(null);

  const [addableDocuments, reloadAddableDocuments] = useResources(
    () => api.callPool(editDocumentsForGroupId ?? 0).getAddableDocuments(),
    [api, editDocumentsForGroupId],
    {
      requestGate: () => {
        return editDocumentsForGroupId !== null;
      },
    },
  );

  const onAddDocument = useOnAddGroupDocument({
    runBeforeRequest: useCallback(() => {}, []),
    asyncRunAfterRequest: useCallback(async () => {
      await reloadGroupDocuments();
      await reloadAddableDocuments();
      flashSuccess('Success');
    }, [reloadGroupDocuments, reloadAddableDocuments]),
    runFinally: useCallback(() => {}, []),
    runOnError: useCallback(
      () =>
        flashError(
          `Something went wrong adding the ${toTitleCase(
            NAMES.document,
          )} to this ${toTitleCase(NAMES.callPool)}`,
        ),
      [],
    ),
    groupId: editDocumentsForGroupId,
    documentId: documentIdToAdd,
  });

  const handleAddClicked = useCallback(
    (id: number) => {
      setDocumentIdToAdd(id);
    },
    [setDocumentIdToAdd],
  );

  const handleCancelAdd = useCallback(() => {
    setDocumentIdToAdd(null);
  }, [setDocumentIdToAdd]);

  return useMemo(
    () => ({
      addableDocuments,
      documentIdToAdd,
      handleAddClicked,
      handleCancelAdd,
      onAddDocument,
    }),
    [
      addableDocuments,
      documentIdToAdd,
      handleAddClicked,
      handleCancelAdd,
      onAddDocument,
    ],
  );
}
