import React, {useState} from 'react';
import {connect} from 'react-redux';
import * as Sentry from '@sentry/browser';
import {AppBar, Toolbar, useMediaQuery} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import {ExitSurveyModal} from '../surveys/ExitSurveyModal';
import {useStyles} from './styles';
import {Tabs} from './Tabs';
import {Drawer} from './Drawer';
import {ReduxState} from '@src/store';
import * as actions from '@src/store/actions';
import {OperationsState} from '@src/store/reducers/operations';
import {UserType} from '@src/models';
import ConfigService from '@src/ConfigService';
import {useApi} from '@src/api/useApi';
import {useUser} from '@src/hooks/useUser';
import {useLogout} from '@src/hooks/useLogout';
import {UserId} from '@src/models/User';

type GeneralProps = {
  permissions: PermissionsProps;
};

export type PermissionsProps = {
  canCreateGroups?: boolean;
};

type HCUNavigationProps = {
  userType?: UserType;
};

type StateProps = {
  operations: OperationsState;
};

const mapStateToProps = ({operations}: ReduxState): StateProps => ({
  operations: operations,
});

type DispatchProps = {
  clearOperations?: () => unknown;
};

const mapDispatchToProps: DispatchProps = {
  clearOperations: actions.clearOperations,
};

type Props = GeneralProps & HCUNavigationProps & StateProps & DispatchProps;

function Navigation({
  userType,
  operations,
  clearOperations,
  permissions,
}: Props) {
  const api = useApi();
  const logout = useLogout();
  const [user] = useUser();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const userId = user
    .getOptional()
    .map(u => u.id)
    .orElse(0 as UserId);

  const onLogout = async (didSurvey: boolean) => {
    const {arrivalTime, visitOccurred} = operations;
    setShowLogoutModal(false);
    await api.saveUserOperations({
      surveySkipped: !didSurvey,
      timeOnSite: arrivalTime.map(a => +new Date() - a).orElse(0),
      visitOccurred,
    });

    if (ConfigService.environmentIs('local') && clearOperations) {
      clearOperations();
      Sentry.configureScope(scope => {
        scope.setUser({
          email: undefined,
        });
      });
    }

    logout();
  };

  return (
    <div style={{width: '100vw', maxWidth: '100vw'}}>
      <ExitSurveyModal
        onLogout={onLogout}
        show={showLogoutModal}
        setShowLogoutModal={setShowLogoutModal}
        userId={userId}
        userType={userType || UserType.Unknown}
      />
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          {isMobile ? (
            <Drawer
              userType={userType || UserType.Unknown}
              setShowLogoutModal={setShowLogoutModal}
              permissions={permissions}
            />
          ) : (
            <Tabs
              userType={userType || UserType.Unknown}
              setShowLogoutModal={setShowLogoutModal}
              permissions={permissions}
            />
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
