import {linkPersonToPatient} from '../../../../creators';
import {LonelyPatient, ResponsiblePerson} from '@src/models';
import {AppAPI} from '@src/api/AppAPI';

async function doesPatientHaveConflict(
  existingPerson: ResponsiblePerson,
  api: AppAPI,
) {
  let hasConflict = false;

  await api
    .responsiblePerson(existingPerson.id)
    .getPatientRelationships()
    .then(relationships => {
      const independentPatientRelationship = relationships.find(r => r.isSelf);

      if (independentPatientRelationship) {
        hasConflict = true;
      }
    });

  return hasConflict;
}

export const HAS_CONFLICT_MESSAGE =
  'The email you entered is already associated with an existing patient record. Please contact support.';

export const checkForConflictsAndLink = async (
  existingPersonForSelf: ResponsiblePerson,
  api: AppAPI,
  patient: LonelyPatient,
) => {
  const hasConflict = doesPatientHaveConflict(existingPersonForSelf, api);

  if (hasConflict) {
    throw new Error(HAS_CONFLICT_MESSAGE);
  }

  await linkPersonToPatient(api)(
    (existingPersonForSelf as ResponsiblePerson).id,
    patient.id,
    {
      isSelf: true,
    },
  );
};
