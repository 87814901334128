import {ICD10Code, LonelyVisit} from '../../models';

/**
 * Takes an visit and returns the full diagnosis
 * @param visit
 * @returns {string}
 */
export default (visit: LonelyVisit): string => {
  const {visitDocumentation} = visit;
  if (!visitDocumentation.isPresent()) {
    return 'No diagnosis';
  }

  return visitDocumentation
    .map(v => v.icd10_codes)
    .orElse([])
    .filter(Boolean)
    .map((c: ICD10Code) => c.description)
    .join('; ');
};
