import {Grid} from '@material-ui/core';
import React from 'react';
import {useStyles} from '../../shared/FirstTime/steps/ConsentStep/layout';
import {Button} from '@src/components/ui/form';
import {Modal} from '@src/components/ui/layout/Modal';
import {Banner} from '@src/components/ui/layout/EmergencyAlert';
import {SimpleDocument} from '@src/models';
import {buildS3Url} from '@src/util/legalDocuments/buildS3Url';
import {isNothing} from '@src/util/typeTests';

type Props = {
  document: SimpleDocument | null;
  onClose: () => unknown;
};

export function PdfViewer({document, onClose}: Props) {
  const classes = useStyles();

  if (document === null) {
    return null;
  }

  const url = buildS3Url(document);

  return (
    <Modal show={true} title={document.name} onClose={onClose} fullScreen>
      {!isNothing(document) && (
        <object className={classes.docFrame} data={url}>
          <Grid container direction="row">
            <Banner
              label=""
              type="error"
              title={'Your browser does not support displaying PDFs'}
              message={
                <p>
                  Unfortunately, your browser does not support displaying PDFs
                  inside a webpage.
                  <Button
                    onClick={() => {
                      window.open(url, '_blank');
                    }}
                    bStyle="outlined"
                    fullWidth
                  >
                    Click here to open in a new tab
                  </Button>
                </p>
              }
            />
          </Grid>
        </object>
      )}
    </Modal>
  );
}
