import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import {addDays} from 'date-fns';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  StyledAsyncActionButton,
  StyledButton,
  StyledButtonRowContainer,
} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/layout';
import {DateInput} from '@src/components/ui/form';
import {ErrorMessage} from '@src/components/ui/form/ErrorMessage';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {SubLabel} from '@src/components/ui/layout/SubLabel';
import {
  setApprovalExpiration,
  setNewSCP,
} from '@src/util/sharedCarePlan/create';
import {SharedCarePlan, LonelyPatient} from '@src/models';
import {useApi} from '@src/api/useApi';

const DEFAULT_EXPIRATION_DATE = addDays(new Date(), 90);

type Props = {
  patient: Optional<LonelyPatient>;
  onCancel: () => void;
  onCreated?: () => void;
  setScp: (scp: SharedCarePlan) => void;
  setPatient: (p: LonelyPatient) => void;
};

const ButtonRowContainer = styled(StyledButtonRowContainer)`
  order: 1;
`;

export const ApprovalExpiration: React.FunctionComponent<Props> = ({
  patient,
  onCancel,
  onCreated,
  setScp,
  setPatient,
}) => {
  const api = useApi();
  const [expirationDate, setExpirationDate] = useState<Optional<Date>>();

  const [creating, setCreating] = useState(false);
  const [errors, setErrors] = useState<{[field: string]: string}>({});

  const patientName = patient
    ?.map(p => p.preferredFirstName)
    .orElse('this patient');

  useEffect(() => {
    setErrors(e => ({
      ...e,
      createSCP: creating ? '' : e.createSCP,
      expirationDate: '',
    }));
  }, [creating]);

  const onSubmit = () => {
    const patientId = patient.map(p => p.id).orElse(0);

    const scpCreationComplete = expirationDate
      ? setApprovalExpiration(api)({
          patientId,
          approvalExpiration: expirationDate.get(),
          setCreating,
          setPatient,
          setScp,
        })
      : setNewSCP(api)({
          patientId,
          setCreating,
          setPatient,
          setScp,
        });

    return scpCreationComplete.then(m => {
      if (onCreated) {
        onCreated();
      }
      return m;
    });
  };

  return (
    <Grid container direction="row">
      <SubLabel>
        {patientName}'s shared care plan was successfully created!
      </SubLabel>
      <ParagraphText>
        As a part of our goal to keep this plan's information accurate and
        updated, we will send you regular reminders to review the information
        and approve it.
      </ParagraphText>
      <ParagraphText>
        By default, we send out reminders after 90 days. However, for some
        patients it is important for the information to be double-checked more
        frequently.
      </ParagraphText>
      <ParagraphText style={{fontWeight: 700}}>
        If you would like to change the interval for reminders, you can select a
        custom date below, or click "next" to keep the default 90-day reminder.
      </ParagraphText>

      <DateInput
        error={
          !!errors.ownershipExpiration
            ? `${errors.ownershipExpiration}`
            : undefined
        }
        label="Shared care plan review reminder"
        name="expiration"
        onChange={d => setExpirationDate(Optional.of(d))}
        value={expirationDate?.orNothing() || DEFAULT_EXPIRATION_DATE}
        minDate={new Date()}
      />

      {errors.createSCP && <ErrorMessage message={errors.createSCP} />}
      {errors.expirationDate && (
        <ErrorMessage message={errors.expirationDate} />
      )}
      <ButtonRowContainer>
        <StyledButton bSize="small" bStyle="secondary" onClick={onCancel}>
          Cancel
        </StyledButton>
        <StyledAsyncActionButton
          actionInProgress={creating}
          actionWord={'Next'}
          bSize="small"
          bStyle="primary"
          onClick={onSubmit}
        >
          Next
        </StyledAsyncActionButton>
      </ButtonRowContainer>
    </Grid>
  );
};
