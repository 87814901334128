import {Resources, useResources} from '..';
import {useApi} from '@src/api/useApi';
import {CallPool} from '@src/models';
import {GetAllOptions} from '@src/api/CallPoolAPI';

export function useAllGroups({
  includeVisitsUnsupported = false,
  includeUnapproved = false,
  customerCode = undefined,
}: GetAllOptions): Resources<CallPool> {
  const api = useApi();

  return useResources<CallPool>(
    () =>
      api
        .callPool()
        .getAll({customerCode, includeVisitsUnsupported, includeUnapproved}),
    [api],
  );
}
