import React, {useCallback, useState} from 'react';
import _ from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconButton, List, ListSubheader} from '@material-ui/core';
import {getNoFilesText} from '../../../patient/files/FilesOverview/util/text';
import {useStyles} from './util/styles';
import {convertToAppFile, AppFile, GeneralFile, Patient} from '@src/models';
import {useApi} from '@src/api/useApi';
import {SigningResult} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';
import {OverviewPageInstructions} from '@src/components/ui/organisms/uploaders/files/FileUploader/instructionsTemplates/OverviewPageInstructions';
import {UploadModal} from '@src/components/ui/organisms/uploaders/files/UploadModal';
import {Files} from '@src/components/ui/organisms/uploaders/files/Folder/Files';
import {NoFiles} from '@src/components/ui/organisms/uploaders/files/Folder/NoFiles';

export function GeneralFolder({
  files,
  patientIsCurrentUser,
  patient,
  setFiles,
}: {
  files: readonly GeneralFile[];
  patientIsCurrentUser: boolean;
  patient: Patient;
  setFiles: (v: readonly GeneralFile[]) => unknown;
}) {
  const classes = useStyles();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const api = useApi();

  const onFileUploadedToS3 = useCallback(
    (result: SigningResult<AppFile>, file: File): Promise<AppFile> => {
      return api
        .patient(patient.id)
        .addGeneralFile({...result, contentType: file.type})
        .then(m => {
          setFiles([...files, m]);
          return m;
        })
        .then(f => convertToAppFile(f));
    },
    [patient, api, setFiles, files],
  );

  const uploadModalInstructions = (
    <OverviewPageInstructions
      fileCategory="general"
      preferredName={patient.preferredName}
      patientIsCurrentUser={patientIsCurrentUser}
    />
  );

  return (
    <>
      <List
        component="nav"
        subheader={
          <ListSubheader component="div">
            General Files
            <IconButton
              onClick={() => {
                setShowUploadModal(true);
              }}
            >
              <FontAwesomeIcon icon={['fas', 'plus-circle']} size="sm" />
            </IconButton>
          </ListSubheader>
        }
        className={classes.root}
      >
        {files.length <= 0 && (
          <NoFiles noFilesText={getNoFilesText('general')} />
        )}
        {files.length > 0 && <Files files={files} open={true} />}
      </List>
      {showUploadModal && (
        <UploadModal
          onFileUploadedToS3={onFileUploadedToS3}
          setShow={setShowUploadModal}
          show={showUploadModal}
          instructions={uploadModalInstructions}
        />
      )}
    </>
  );
}
