import styled from 'styled-components';
import {md, secondary} from '../theme';

export const SubLabel = styled.h1`
  color: ${secondary};
  font-size: 2em;
  font-weight: 300;
  line-height: 1.5em;
  margin-bottom: ${md};
  text-align: center;
`;
