import {Reason} from './types';

export const instructionsMessages = {
  visitNotFound: '',
  roomFull:
    'If you are supposed to be attending the visit, please contact one of the other participants.',
  visitLeft:
    'If you would like to return to the visit, please click Return To Visit.',
  visitOver:
    'The visit may have already occurred or was canceled by your doctor. Please contact your doctor if you think this is a mistake.',
  error:
    'We hit an error getting the room set up. Please contact support for more assistance or click Retry to attempt reconnecting to the room.',
  deniedAdmission: 'Someone in the call denied your request to join.',
};

export function getInstructionsText(reason: Reason) {
  return instructionsMessages[reason];
}
