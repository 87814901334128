import React from 'react';
import {Grid} from '@material-ui/core';
import {addDays, startOfMonth} from 'date-fns';
import {useStyles} from '../../layout';
import {Shift} from './Shift/Shift';
import {usePageContext} from '@src/components/providerSide/providerGroups/ProviderGroupRosterPage';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';
import {isNothing} from '@src/util/typeTests';
import {byStartAsc} from '@src/util/sorters/byStart';
import {OnCallPeriod} from '@src/models';

type Props = {
  day: number;
};

export function Shifts({day}: Props) {
  const {
    rosterMonth,
    monthTable: {setShowSinglePeriodModal, periodsMappedToDaysOfSelectedMonth},
    addPeriodModal: {setDate},
  } = usePageContext();

  const dayRoster = periodsMappedToDaysOfSelectedMonth
    .getOptional()
    .orElse(new Map<number, OnCallPeriod[]>())
    .get(day);

  function onCreateShift() {
    setDate(addDays(startOfMonth(rosterMonth), day - 1));
    setShowSinglePeriodModal(true);
  }

  const classes = useStyles();
  return (
    <Grid
      item
      container
      direction="column"
      className={classes.dayContent}
      alignItems="center"
      justify="space-between"
    >
      <Grid
        container
        item
        direction="column"
        className={classes.periodsContainer}
      >
        {isNothing(dayRoster) && (
          <Grid
            item
            container
            alignItems="center"
            justify="center"
            className={classes.noShifts}
          >
            <div>No shifts</div>
          </Grid>
        )}
        {dayRoster
          ?.sort(byStartAsc)
          ?.map(period => <Shift key={period.id} period={period} />) ?? null}
      </Grid>
      <Grid item>
        <ClickableIcon icon="plus" onClick={onCreateShift} />
      </Grid>
    </Grid>
  );
}
