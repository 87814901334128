import React from 'react';
import {usePageContext} from '..';
import {DetailsSection} from '@src/components/separateAdminApp/CreateDocumentPage/DocumentForm/DetailsSection';
import {UploadSection} from '@src/components/separateAdminApp/CreateDocumentPage/DocumentForm/UploadSection';
import {CustomerAndGroupSelector} from '@src/components/separateAdminApp/CreateDocumentPage/DocumentForm/CustomerAndGroupSelectors';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {FormContainer} from '@src/components/ui/molecules/layouts/FormContainer';

export function DocumentForm() {
  const {
    formResources: {formHandler, submitting},
  } = usePageContext();

  const submitButton = (
    <MuiAsyncActionButton
      bStyle="primary"
      bSize="normal"
      onClick={formHandler}
      actionInProgress={submitting}
      actionWord="Submit"
      disabled={submitting}
    >
      Submit
    </MuiAsyncActionButton>
  );

  return (
    <FormContainer submitButton={submitButton}>
      <DetailsSection />
      <CustomerAndGroupSelector />
      <UploadSection />
    </FormContainer>
  );
}
