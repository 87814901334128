import {makeStyles} from '@material-ui/core/styles';
import {HEADER} from '@src/components/apps/AppBox';

export const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.secondary.main,
    gridArea: HEADER,
    color: theme.palette.secondary.contrastText,
    overflow: 'hidden',
    width: '100%',
    height: 'fit-content',
  },
  toolBar: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  drawerIconContainer: {
    color: theme.palette.secondary.contrastText,
    marginLeft: 'auto',
  },
  drawerIcon: {
    height: '40px',
    width: '40px',
  },
  drawer: {
    backgroundColor: theme.palette.secondary.main,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    minWidth: '160px',
  },
  menuItem: {
    fontSize: 18,
    fontWeight: 500,
    textTransform: 'none',
    color: theme.palette.secondary.contrastText,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      color: theme.palette.secondary.contrastText,
      textDecoration: 'none',
    },
  },
  navigationSkeleton: {
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
}));
