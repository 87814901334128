import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useState} from 'react';
import {MuiAsyncActionButton} from '../../ui/form/MuiAsyncActionButton';
import {Modal} from '../../ui/layout/Modal';
import {ProviderSelector} from '../ProviderSelector';
import {Context} from '../../../util/provider/forms/addOrEdit';
import {flashError} from '../notifications/flash';
import {ResourceConflictException} from '@src/api/exceptions';
import {UserType, ProviderDetails} from '@src/models';
import {useApi} from '@src/api/useApi';
import {Instructions} from '@src/components/ui/layout';
import {Button} from '@src/components/ui/form';
import {convertDetailsToRequest} from '@src/api/ProviderDetailsAPI';
import {ProviderDetailsId} from '@src/models/ProviderDetails';

type Props = {
  show: boolean;
  patientId: number;
  onDelete: (contactId: number) => void;
  onHide: () => void;
  onUpdate: (pcpInfo: ProviderDetails) => void;
  providerDetails: Optional<ProviderDetails>;
  userType: UserType;
};

export function AddOrEditMedicalTeamMemberModal({
  patientId,
  providerDetails,
  onDelete,
  onHide,
  onUpdate,
  show,
  userType,
}: Props) {
  const api = useApi();
  const [details, setDetails] = useState(providerDetails);
  const [showPreExistingProvider, setShowPreExistingProvider] = useState(false);
  const [savingProvider, setSavingProvider] = useState(false);
  const [deletingProvider, setDeletingProvider] = useState(false);

  const saveProvider = async () => {
    setSavingProvider(true);
    const infoId = details.map(i => i.id).orElse(0 as ProviderDetailsId);
    const request = convertDetailsToRequest(details);
    if (!request.isPresent()) {
      throw new Error('Tried to save non-existent PCPInfo');
    }

    const searchable = request.property('searchable', false);
    const id = request.property('id', 0 as ProviderDetailsId);

    if (searchable) {
      await api.patient(patientId).addProviderDetailsToCareTeam(id);
      onUpdate(details.get());
      onHide();
      setSavingProvider(false);
    } else {
      let result: ProviderDetails;
      try {
        if (infoId === 0) {
          result = await api.providerDetails().create(request.get());
          await api.patient(patientId).addProviderDetailsToCareTeam(result.id!);
        } else {
          result = await api.providerDetails(infoId).update(request.get());
        }
        onUpdate(result);
        onHide();
      } catch (e) {
        if (e instanceof ResourceConflictException) {
          setShowPreExistingProvider(true);
        } else {
          flashError(
            `There was a problem ${
              providerDetails.isPresent() ? 'editing' : 'adding'
            } this medical team member. Please contact Support`,
          );
        }
      } finally {
        setSavingProvider(false);
      }
    }
  };

  const removeProvider = async () => {
    setDeletingProvider(true);
    const infoId = providerDetails
      .map(i => i.id)
      .orElseThrow(() => new Error('Tried to save non-existent PCPInfo'));
    await api.patient(patientId).removeProviderDetailsFromCareTeam(infoId);

    onDelete(infoId);
    onHide();
    setDeletingProvider(false);
  };

  if (showPreExistingProvider) {
    return (
      <Modal
        show={showPreExistingProvider}
        title="Please search for this medical professional"
        onClose={() => {
          setShowPreExistingProvider(false);
        }}
        modalActions={
          <>
            <Button
              onClick={() => {
                setShowPreExistingProvider(false);
              }}
            >
              OK
            </Button>
          </>
        }
      >
        <Instructions>
          <p>
            There is already a medical professional in our system with that
            email address.
          </p>
          <p>
            Please search for them by name and select that record. If you are
            not able to find the person in question, you can contact support for
            assistance or add the person without including their email address.
          </p>
        </Instructions>
      </Modal>
    );
  }

  return (
    <Modal
      show={show}
      title={
        providerDetails.isPresent()
          ? 'Edit medical team member'
          : 'Add medical team member'
      }
      modalActions={
        <>
          <MuiAsyncActionButton
            bSize={'small'}
            disabled={
              details.map(d => d.id === 0 && d.lastName === '').orElse(true) ||
              deletingProvider
            }
            actionInProgress={savingProvider}
            actionWord={`${details
              .map(i => (i.searchable ? 'Select' : 'Save'))
              .orElse('Save')} Contact`}
            onClick={saveProvider}
          />
          {providerDetails.isPresent() && !savingProvider && (
            <MuiAsyncActionButton
              bSize={'small'}
              disabled={false}
              actionInProgress={deletingProvider}
              actionWord={'Delete Contact'}
              onClick={removeProvider}
            />
          )}
        </>
      }
      onClose={onHide}
    >
      <ProviderSelector
        providerDescription="Medical Team Member"
        details={details}
        onChange={setDetails}
        userType={userType}
        selectionOrEditContext={
          providerDetails.isPresent()
            ? Context.EditMedicalTeamMember
            : Context.AddMedicalTeamMember
        }
      />
    </Modal>
  );
}
