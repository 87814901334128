import React from 'react';
import {useHistory} from 'react-router';
import {Grid} from '@material-ui/core';
import {CallPool} from '@src/models';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {Button} from '@src/components/ui/form';

type Props = {
  providerGroup?: CallPool;
};

export function Availability({providerGroup}: Props) {
  const history = useHistory();

  if (!providerGroup) {
    return null;
  }

  return (
    <Grid>
      <ParagraphText>
        <Button
          onClick={() =>
            history.push(
              `/provider-group/${providerGroup.id}/roster?on-demand=true`,
            )
          }
        >
          On-Demand
        </Button>
      </ParagraphText>
    </Grid>
  );
}
