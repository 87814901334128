import {AsyncData} from '@ahanapediatrics/ahana-fp';
import * as React from 'react';
import {Grid} from '@material-ui/core';
import {usePageContext} from '..';
import {PrintoutContent} from '@src/components/shared/PatientDashboard/SCP/SCPPrintout/PrintoutContent';
import {PatientRelationship} from '@src/models';
import {ForwardRef} from '@src/app-types';

type Props = {
  guardianships: AsyncData<PatientRelationship>;
};

export const SCPPrintout = React.forwardRef(
  ({guardianships}: Props, ref: ForwardRef<HTMLTableElement>) => {
    const {patient, scp, currentMedicalHistory} = usePageContext();

    const aScp = scp.getOptional().orNull();

    if (aScp === null) {
      return null;
    }

    return (
      <Grid container direction="row" ref={ref}>
        {!(
          patient.isLoaded() &&
          guardianships.isLoaded() &&
          scp.isLoaded() &&
          !scp.isEmpty() &&
          currentMedicalHistory.isLoaded()
        ) ? (
          <div>'Still loading data'</div>
        ) : (
          <PrintoutContent
            currentMedicalHistory={currentMedicalHistory}
            patient={patient.singleValue()}
            guardianships={guardianships.value()}
            scp={aScp}
          />
        )}
      </Grid>
    );
  },
);
