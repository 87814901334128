import styled from 'styled-components';
import {h1, md, primary, sm} from '../theme';

export * from './toRetire/Instructions';
export * from './PageFooter';
export * from './PageHeader';
export * from './SubLabel';

export const PageTitle = styled.div`
  color: ${primary};
  text-align: center;
  margin: ${md} 0 ${sm};
  max-width: 85vw;
  font-size: ${h1};
`;
