import {Optional} from '@ahanapediatrics/ahana-fp';
import {yupResolver} from '@hookform/resolvers';
import {Divider} from '@material-ui/core';
import React, {MutableRefObject} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {flashWarn} from '../../../../notifications/flash';
import {
  ProviderInfoFormStyled,
  SearchablePcpDisplay,
  SearchOrEnter,
} from './layout';
import {CareTeamCard} from '@src/components/ui/molecules/cards/professionals/CareTeamCard';
import {TextInput, PhoneInput} from '@src/components/ui/form';
import {ErrorMessage} from '@src/components/ui/form/ErrorMessage';
import {SearchByProvider} from '@src/components/ui/form/search/SearchByProvider';
import {Banner} from '@src/components/ui/layout/Banner';
import {ProviderDetails, UserType} from '@src/models';
import {rqString, yObject, yString} from '@src/schema/types';
import {getProviderDescription} from '@src/util/provider/providerDescription';
import {useApi} from '@src/api/useApi';
import {
  conjugate,
  reference,
} from '@src/util/stringManipulation/languageHelpers';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';
import {ProviderDetailsId} from '@src/models/ProviderDetails';

type Props = {
  error?: string;
  isPcp: boolean;
  isSCP?: boolean;
  onChange: (details: Optional<ProviderDetails>) => void;
  details: Optional<ProviderDetails>;
  providerDescription: string;
  userType: UserType;
  entryMode: EntryMode;
  setEntryMode: (mode: EntryMode) => unknown;
  setNoSCPOwner?: () => void;
  setIntentionallyAbsentProvider: (b: boolean) => unknown;
  intentionallyAbsentProvider: boolean;
  moveGuard: MutableRefObject<(dir: 'back' | 'next') => Promise<boolean>>;
  patientIsCurrentUser: boolean;
};

const pcpInfoSchema = yObject({
  firstName: yString,
  lastName: rqString('Please provide a last name for this provider'),
  speciality: yString,
  qualification: yString,
  practiceName: yString,
  email: yString,
  phone: yString,
  fax: yString,
});

export type EntryMode = 'search' | 'type' | 'unknown' | '';

type PCPInfoValues = {
  firstName: string;
  lastName: string;
  speciality: string;
  qualification: string;
  email: string;
  practiceName: string;
  phone: string;
  fax: string;
};

const infoToValues = (p: ProviderDetails): PCPInfoValues => ({
  firstName: p.firstName ?? '',
  lastName: p.lastName ?? '',
  speciality: p.speciality ?? '',
  qualification: p.qualification ?? '',
  email: p.email.orElse(''),
  practiceName: p.practice.property('name') ?? p.practiceName ?? '',
  phone: p.practice.property('phone') ?? p.practicePhone ?? '',
  fax: p.practice.property('fax') ?? p.practiceFax ?? '',
});

/**
 * While this has previously treated as a form, it's really just a complex
 * Input
 *
 * @param props
 */
export function ProviderSelector({
  error,
  details,
  onChange,
  isPcp,
  providerDescription,
  userType,
  isSCP,
  entryMode,
  setEntryMode,
  setNoSCPOwner,
  setIntentionallyAbsentProvider,
  intentionallyAbsentProvider,
  moveGuard,
  patientIsCurrentUser,
}: Props) {
  const api = useApi();

  const infoId = details.map(p => p.id).orElse(0 as ProviderDetailsId);
  const providerId = details.map(p => p.providerId).orNull();

  const formStart = details.map(infoToValues).orElse({
    firstName: '',
    lastName: '',
    speciality: '',
    qualification: '',
    email: '',
    practiceName: '',
    phone: '',
    fax: '',
  });

  const {errors, handleSubmit, control, reset} = useForm({
    defaultValues: formStart,
    resolver: yupResolver(pcpInfoSchema),
  });

  moveGuard.current = async (dir: 'next' | 'back') => {
    if (dir === 'back') {
      return true;
    }

    const handleManualEntry = (
      data: PCPInfoValues,
      res: (v: boolean) => unknown,
    ) => {
      const needsEmailCheck =
        userType !== UserType.Guardian &&
        data.email &&
        data.email !== formStart.email;

      const handlePassingEntry = () => {
        onChange(
          Optional.of(
            new ProviderDetails({
              id: details.property('id', 0 as ProviderDetailsId),
              firstName: data.firstName ?? '',
              lastName: data.lastName ?? '',
              fullName: `${data.firstName ?? ''} ${data.lastName ?? ''}`,
              email: Optional.of(data.email),
              speciality: data.speciality,
              qualification: data.qualification,
              practiceName: data.practiceName,
              practiceFax: data.fax,
              practicePhone: data.phone,
              searchable: false,
              seesPatients: false,
              providerId: null,
              practice: Optional.empty(),
              lastUpdatedBy: Optional.empty(),
              updatedAt: new Date(),
              identifiers: [],
            }),
          ),
        );

        res(true);
      };

      if (needsEmailCheck) {
        api
          .providerDetails()
          .findByEmail(data.email)
          .then(pds => {
            if (pds.length > 0) {
              onChange(Optional.empty());
              flashWarn(
                'There is already a doctor with this email address. Please search for them by name',
              );
              res(false);
            } else {
              handlePassingEntry();
            }
          });
      } else {
        handlePassingEntry();
      }
    };

    return new Promise(res =>
      handleSubmit(
        data => {
          if (entryMode === 'type') {
            handleManualEntry(data, res);
          } else {
            const gotProvider =
              intentionallyAbsentProvider || details.isPresent();

            if (gotProvider) {
              res(true);
            }
          }
        },
        () => {
          if (entryMode === 'type') {
            flashWarn('Please check for errors in the form above');
            res(false);
          } else {
            // There have been a few intermittent bugs where this if statement is true
            // but it is hard to reproduce it, so checking just in case.
            if (details.isPresent() || intentionallyAbsentProvider) {
              res(true);
            } else {
              flashWarn('Please choose a provider');
              res(false);
            }
          }
        },
      )(),
    );
  };

  const changeEntryModeTo = (type: EntryMode) => () => {
    setEntryMode(type);
    onChange(Optional.empty());

    setIntentionallyAbsentProvider(type === 'unknown');
    if (isSCP && setNoSCPOwner && type === 'unknown') {
      setNoSCPOwner();
    }
    reset();
  };

  const isNew = infoId === 0;
  const styling = isPcp ? 'primary care doctor' : 'doctor';

  const ref = reference('this patient', patientIsCurrentUser);
  const c = conjugate(patientIsCurrentUser);

  return (
    <ProviderInfoFormStyled>
      <ErrorMessage message={error} />
      {entryMode === 'unknown' && (
        <>
          {isSCP ? (
            <Banner type="warning" style={{marginBottom: '1rem'}}>
              You've told us that you don't have any information about which
              doctor should own this shared care plan. You can continue creating
              and editing the care plan, but it will be in a draft state until a
              doctor is designated as its owner.
            </Banner>
          ) : (
            <Banner type="info" style={{marginBottom: '1rem'}}>
              You've told us that you don't have any information about{' '}
              {ref('poss')} {styling}. That's fine, but we won't be able to
              notify anyone about this patient's visits. If you want to change
              this, please click one of the links below.
            </Banner>
          )}
        </>
      )}

      {(entryMode === '' || entryMode === 'search') && (
        <SearchByProvider
          name="provider"
          userType="Provider"
          title={providerDescription}
          placeholder="Search our system for your doctor"
          disabled={false}
          selectedUser={
            !isNew && providerId ? details.property('fullName', '') : ''
          }
          selectUser={p => {
            onChange(Optional.of(p));
          }}
          renderUser={user => <div>{getProviderDescription(user)}</div>}
          touched={false}
        />
      )}

      <div hidden={entryMode !== 'type'} style={{marginBottom: '1rem'}}>
        <Controller
          control={control}
          as={TextInput}
          title={`${toTitleCase(styling)}'s first name`}
          name="firstName"
          touched={true}
          error={errors.firstName?.message}
        />
        <Controller
          control={control}
          as={TextInput}
          required
          title={`${toTitleCase(styling)}'s last name`}
          name="lastName"
          touched={true}
          error={errors.lastName?.message}
        />
        <Controller
          control={control}
          as={TextInput}
          title={`${toTitleCase(styling)}'s speciality`}
          name="speciality"
          touched={true}
          error={errors.speciality?.message}
        />
        <Controller
          control={control}
          as={TextInput}
          title={`${toTitleCase(styling)}'s qualification`}
          name="qualification"
          touched={true}
          error={errors.qualification?.message}
        />
        {userType !== UserType.Guardian && (
          <Controller
            control={control}
            as={TextInput}
            title={`${toTitleCase(styling)}'s email`}
            name="email"
            touched={true}
            error={errors.email?.message}
          />
        )}
        <Controller
          control={control}
          as={TextInput}
          title={`${toTitleCase(styling)}'s practice name`}
          name="practiceName"
          touched={true}
          error={errors.practiceName?.message}
        />
        <Controller
          control={control}
          as={PhoneInput}
          title={`${toTitleCase(styling)}'s practice phone`}
          name="phone"
          touched={true}
          error={errors.phone?.message}
        />
        <Controller
          control={control}
          as={PhoneInput}
          title={`${toTitleCase(styling)}'s practice fax`}
          name="fax"
          touched={true}
          error={errors.fax?.message}
        />

        {entryMode === 'type' && isSCP && (
          <Banner type="warning">
            The doctor you want to select as the owner of this shared care plan
            may not yet be a Refyne Connected Care user. You can continue
            creating and editing the plan, but it will be in a draft state until
            the doctor joins Refyne Connected Care.
          </Banner>
        )}
      </div>
      <div hidden={!details.isPresent()}>
        <SearchablePcpDisplay>
          <CareTeamCard
            canEdit={false}
            includeMissing={false}
            details={details.orNull()}
            onEdit={() => {}}
            viewerType={userType}
          />
        </SearchablePcpDisplay>
      </div>
      <Divider />
      <div>
        <SearchOrEnter
          hidden={entryMode === 'search' || entryMode === ''}
          onClick={changeEntryModeTo('search')}
        >
          Search for {styling} instead
        </SearchOrEnter>
        <SearchOrEnter
          hidden={entryMode === 'type'}
          onClick={changeEntryModeTo('type')}
        >
          Enter {styling} information manually
        </SearchOrEnter>

        <SearchOrEnter
          hidden={entryMode === 'unknown' || !(isPcp || isSCP)}
          onClick={changeEntryModeTo('unknown')}
        >
          {isPcp && (
            <>
              {ref('Nom', {first: true})} {c('do')}n't have a primary care
              doctor, or I don't know who they are
            </>
          )}
          {isSCP && <>I don't know who this care plan's owner should be</>}
        </SearchOrEnter>
      </div>
    </ProviderInfoFormStyled>
  );
}
