import React, {useCallback, useState} from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {format} from 'date-fns';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import {
  CircularProgress,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextareaAutosize,
  Tooltip,
} from '@material-ui/core';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/panels/SCPChangeLog/styles';
import {ApproveButton} from '@src/components/shared/PatientDashboard/SCP/panels/SCPChangeLog/ApproveButton';
import {
  formatProperty,
  formatRequester,
} from '@src/util/sharedCarePlan/changeRequest';
import {
  SharedCarePlan,
  SCPChangeRequest,
  SCPChangeRequestStatus,
} from '@src/models';
import {UserId} from '@src/models/User';
import {LoadingIndeterminate} from '@src/components/ui/atoms/progressBarsAndIndicators/LoadingIndeterminate';
import {useOnUpdateChangeRequest} from '@src/hooks/scp/changeRequests/useOnUpdateChangeRequest';
import {useOnDeleteChangeRequest} from '@src/hooks/scp/changeRequests/useOnDeleteChangeRequest';
import {isPartner} from '@src/util/sharedCarePlan';
import {flashSuccess} from '@src/components/shared/notifications/flash';
import {md, sm} from '@src/components/ui/theme';
import {useUser} from '@src/hooks';

type Props = {
  changeRequest: SCPChangeRequest;
  isMyPendingChangesModal?: boolean;
};

function NonEditModeIcons({
  predicates: {isOwner, isMyPendingChangesModal, editing},
  setEditing,
  setEditedValue,
  newValue,
  scp,
  changeRequest,
}: {
  predicates: {
    isOwner: boolean;
    isMyPendingChangesModal: boolean;
    editing: boolean;
  };
  setEditing: (v: boolean) => unknown;
  setEditedValue: (v: string) => unknown;
  newValue: string;
  scp: Optional<SharedCarePlan>;
  changeRequest: SCPChangeRequest;
}) {
  const classes = useStyles();

  const {reloadChangeRequests} = usePageContext();

  const {onDelete, deleting} = useOnDeleteChangeRequest({
    scp,
    changeRequest,
    reloadChangeRequests,
  });

  return (
    <TableCell align="left">
      <Grid container direction="row" style={{paddingLeft: 0}}>
        {!isOwner && isMyPendingChangesModal && !editing && (
          <Grid item xs={4} style={{marginRight: '12px'}}>
            <Tooltip title="Edit pending change">
              <IconButton
                className={classes.iconButton}
                style={{padding: '8px'}}
                onClick={() => {
                  setEditing(true);
                  setEditedValue(newValue);
                }}
              >
                <EditIcon className={classes.icon} />
              </IconButton>
            </Tooltip>

            <LoadingIndeterminate active={deleting}>
              <Tooltip title="Delete pending change">
                <IconButton
                  onClick={() => {}}
                  className={classes.iconButton}
                  style={{padding: '8px'}}
                >
                  <DeleteIcon className={classes.icon} onClick={onDelete} />
                </IconButton>
              </Tooltip>
            </LoadingIndeterminate>
          </Grid>
        )}
      </Grid>
    </TableCell>
  );
}

function EditModeIcons({
  setDisplayedValue,
  setEditedValue,
  setEditing,
  scp,
  changeRequest,
  editedValue,
  newValue,
}: {
  setDisplayedValue: (v: string) => unknown;
  setEditedValue: (v: string) => unknown;
  setEditing: (v: boolean) => unknown;
  scp: Optional<SharedCarePlan>;
  changeRequest: SCPChangeRequest;
  editedValue: string;
  newValue: string;
}) {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const {reloadScp} = usePageContext();

  const onUpdateChangeRequest = useOnUpdateChangeRequest({
    scp,
    editedValue,
    changeRequestId: changeRequest.id,
    runBeforeRequest: () => {
      setSaving(true);
    },
    asyncRunAfterRequest: async () => {
      setDisplayedValue(editedValue);
      reloadScp();
    },
    runFinally: () => {
      setEditing(false);
      setSaving(false);
    },
    runOnError: () => {},
  });

  return (
    <>
      {saving && (
        <IconButton className={classes.iconButton} style={{padding: '8px'}}>
          <CircularProgress size={18} className={classes.icon} />
        </IconButton>
      )}

      {!saving && (
        <>
          <Tooltip title="Save">
            <IconButton
              className={classes.iconButton}
              style={{padding: '8px'}}
              onClick={onUpdateChangeRequest}
            >
              <SaveIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton
              className={classes.iconButton}
              style={{padding: '8px'}}
              onClick={() => {
                setEditedValue(newValue);
                setEditing(false);
              }}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
}

export function PendingChangeRow({
  isMyPendingChangesModal = false,
  changeRequest,
}: Props) {
  const classes = useStyles();
  const [user] = useUser();

  const {reloadScp, scp, ownerPartners, ownerId} = usePageContext();

  const oScp = scp.getOptional();
  const {
    newValue,
    property,
    requester,
    updatedAt,
    qualification,
  } = changeRequest;

  const userId = user
    .getOptional()
    .map(u => u.id)
    .orElse(0 as UserId);

  const isOwner = userId === ownerId;

  const isUserPartner = isPartner({
    inviterUserId: ownerId,
    maybePartnerUserId: userId,
    partnerships: ownerPartners.getAllOptional().orElse([]),
  });

  const [editing, setEditing] = useState(false);
  const [editedValue, setEditedValue] = useState('');
  const [displayedValue, setDisplayedValue] = useState(newValue);

  const runFinallyAfterApproved = useCallback(() => {
    flashSuccess('Approved!');
  }, []);

  const asyncRunAfterApproved = useCallback(async () => {
    await reloadScp();
  }, [reloadScp]);

  return (
    <TableRow
      key={changeRequest.id}
      style={{maxHeight: '10px', overflowY: 'scroll'}}
    >
      <TableCell>{format(updatedAt, 'M.d.yyyy')}</TableCell>

      <TableCell align="left">
        {formatRequester(requester, qualification)}
      </TableCell>

      <TableCell align="left">{formatProperty(property)} </TableCell>

      <TableCell
        style={{
          wordBreak: 'break-word',
          whiteSpace: 'normal',
        }}
        align="left"
        className={
          changeRequest.status === SCPChangeRequestStatus.Pending
            ? classes.pending
            : ''
        }
      >
        {!editing ? (
          displayedValue
        ) : (
          <TextareaAutosize
            onChange={e => setEditedValue(e.target.value)}
            defaultValue={displayedValue}
            value={editedValue}
          />
        )}

        {!isOwner && isMyPendingChangesModal && editing && (
          <EditModeIcons
            setDisplayedValue={setDisplayedValue}
            setEditedValue={setEditedValue}
            setEditing={setEditing}
            scp={oScp}
            changeRequest={changeRequest}
            editedValue={editedValue}
            newValue={newValue}
          />
        )}
      </TableCell>
      <NonEditModeIcons
        predicates={{isOwner, isMyPendingChangesModal, editing}}
        setEditing={setEditing}
        newValue={newValue}
        scp={oScp}
        changeRequest={changeRequest}
        setEditedValue={setEditedValue}
      />
      {(isOwner || isUserPartner) && (
        <Grid item style={{marginLeft: sm, padding: md}}>
          <ApproveButton
            scp={oScp}
            changeRequest={changeRequest}
            asyncRunAfterApproved={asyncRunAfterApproved}
            runFinally={runFinallyAfterApproved}
          />
        </Grid>
      )}
    </TableRow>
  );
}
