import classnames from 'classnames';
import React, {ReactElement} from 'react';
import AutoSuggest from '../AutoSuggest';
import {useSuggestionGetter} from './useSuggestionGetter';
import {ResponsiblePerson} from '@src/models';
import {useApi} from '@src/api/useApi';

type Props = {
  name: string;
  className?: string;
  instructions?: string;
  selectedUser: string;
  selectUser: (user: ResponsiblePerson | null) => void;
  renderUser: (user: ResponsiblePerson) => ReactElement;
  title?: string;
  placeholder?: string;
  required?: boolean;
  slimline?: boolean;
  disabled?: boolean;
  touched?: boolean;
  error?: string | boolean;
};

export function SearchByGuardian({
  className,
  name,
  slimline,
  placeholder,
  touched,
  renderUser,
  selectUser,
  error,
  selectedUser = '',
  instructions = '',
  title = '',
  disabled = false,
  required = false,
}: Props) {
  const api = useApi();
  const [userSuggestions, getSuggestions] = useSuggestionGetter({
    api: api.responsiblePerson(),
  });

  return (
    <div className={classnames(['search-by-user', className, {disabled}])}>
      <AutoSuggest
        required={required}
        className="search-by-user--input"
        disabled={disabled}
        slimline={slimline}
        error={error}
        placeholder={placeholder || ''}
        getSuggestionKey={(user: ResponsiblePerson) => {
          if (user.id === -1) {
            return '-1';
          }

          return `${user.id}`;
        }}
        getSuggestions={getSuggestions}
        getSuggestionValue={(user: ResponsiblePerson) => {
          return user.email
            .map(e => `${user.firstName} (${e})`)
            .orElse(user.firstName);
        }}
        instructions={instructions}
        name={name}
        onChange={selectUser}
        renderSuggestion={renderUser}
        suggestions={userSuggestions}
        title={title}
        touched={touched}
        value={selectedUser}
      />
    </div>
  );
}
