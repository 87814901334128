import React from 'react';
import styled from 'styled-components';
import {useStyles} from './layout';
import {DownloadButton} from '@src/components/providerSide/DownloadButton';
import {useApi} from '@src/api/useApi';
import {LonelyVisit} from '@src/models';

type Props = {
  visit: LonelyVisit;
};

const VisitLinkContainer = styled.div`
  display: inline-block;
`;

export function ReportLink({visit}: Props) {
  const api = useApi();
  const classes = useStyles();

  return (
    <VisitLinkContainer>
      {visit.isResolved && (
        <>
          <DownloadButton
            className={classes.iconLink}
            fileUrl={api.visit(visit.id).getReportDownloadURL()}
            authorized
            icon={['fas', 'file-medical-alt']}
          />
        </>
      )}
    </VisitLinkContainer>
  );
}
