import {AsyncData} from '@ahanapediatrics/ahana-fp';
import React, {useState} from 'react';
import {AddProviderModal} from '../AddProviderModal';
import {MembersTable} from './MembersTable';
import {Button} from '@src/components/ui/form';
import {ProviderDetails} from '@src/models';

type Props = {
  callPoolId: number;
  members: AsyncData<ProviderDetails>;
  reloadMembers: () => Promise<unknown>;
};
export function MembersSection({callPoolId, members, reloadMembers}: Props) {
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);

  return (
    <>
      <AddProviderModal
        callPoolId={callPoolId}
        show={showAddMemberModal}
        setShow={setShowAddMemberModal}
        onAdded={reloadMembers}
        memberIds={members
          .getAllOptional()
          .orElse([])
          .map(m => m.id)}
      />
      <MembersTable
        callPoolId={callPoolId}
        members={members}
        onRemoved={reloadMembers}
      />
      <Button
        onClick={() => {
          setShowAddMemberModal(true);
        }}
        bStyle="primary"
        style={{margin: '1rem 0'}}
      >
        Add Member
      </Button>
    </>
  );
}
