import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {AsyncData, RemoteDataStatus} from '@ahanapediatrics/ahana-fp';
import React from 'react';
import {isSupported} from 'twilio-video';
import {DeadEndPage} from '../../ui/layout/pages/deadEnd/DeadEndPage';
import {VisitState} from '@src/models';

/*
 * Be careful if you decide to add transitions to the drawer opening and
 * closing.
 *
 * It seems to mess with the autosizing TextInputs in the documentation form.
 * I tried adding event handlers to the `SlideProps` of the `Drawer`, but they
 * never seemed to fire.
 */
export const useStyles = makeStyles<Theme, {width: number}>(theme =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: 'none',
    },
    appBarShift: props => ({
      marginLeft: props.width,
      width: `calc(100% - ${props.width}px)`,
    }),
    expandMenuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: props => ({
      width: props.width,
    }),
    drawerClose: props => ({
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    }),
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    factsDisplayContainer: {
      padding: '2rem',
    },
    contentDrawerOpen: props => ({
      marginLeft: props.width + 2,
    }),
    contentDrawerClosed: {
      marginLeft: theme.spacing(7) + 3,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(9) + 2,
      },
    },
    clickHere: {
      cursor: 'pointer',
      color: theme.palette.common.white,
    },
    videoError: {
      alignItems: 'center',
      backgroundColor: '#3a3a3e',
      color: 'white',
      width: '99vw',
      minHeight: '5rem',
      overflow: 'hidden',
      wordWrap: 'break-word',
      padding: '1rem',
    },

    videoControls: {
      width: '100vw',
      position: 'fixed',
      bottom: 0,
      zIndex: 2,
      backgroundColor: 'inherit',
    },
    videoControlAction: {
      backgroundColor: 'rgba(200, 200, 200, 0.8)',
    },
    controlButtonActive: {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
    controlButtonDisabled: {
      color: theme.palette.error.main,
      '&:hover': {
        color: theme.palette.error.dark,
      },
    },
    loading: {
      color: theme.palette.common.white,
      fontSize: 24,
    },
  }),
);

export const DeadEndError = (
  visit: AsyncData<{id: number; status: VisitState}>,
) => {
  if (visit.is(RemoteDataStatus.Failure)) {
    return (
      <DeadEndPage
        title="The exam room for this visit cannot be found"
        className="visit-not-found"
        buttonText="Return to Dashboard"
        buttonTarget="/"
        severity="warning"
      >
        <p>
          There was a problem setting up the Exam Room for your visit.
          <br />
          Please contact your doctor via telephone instead.
        </p>
        <p>The support team are looking into this.</p>
      </DeadEndPage>
    );
  }
  if (!isSupported) {
    return (
      <DeadEndPage
        title="This browser is not supported for seeing your doctor"
        className="unsupported-browser"
        severity="danger"
      >
        <p>
          At the moment, there are a few browsers that we cannot support for
          talking to your doctor.
        </p>
        <p>
          In particular, we cannot support privacy browsers, such as DuckDuckGo,
          because they do not permit us access to your camera and microphone.
        </p>
        <p>Please try a different browser.</p>
      </DeadEndPage>
    );
  }

  return null;
};
