import React, {ReactNode} from 'react';
import {makeStyles, createStyles, FormLabel} from '@material-ui/core';
import {sm} from '@src/components/ui/theme';

type Props = {
  children: ReactNode | null;
};

const useStyles = makeStyles(() =>
  createStyles({
    margin: {
      marginBottom: sm,
    },
  }),
);

export function LightHeading({children}: Props) {
  const classes = useStyles();

  return (
    <FormLabel component="div" className={classes.margin}>
      {children}
    </FormLabel>
  );
}
