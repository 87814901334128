import {useCallback} from 'react';
import {UpdateOnCallPeriodRequest} from '@src/api/OnCallAPI';
import {useApi} from '@src/api/useApi';
import {OnCallPeriod} from '@src/models';

export const useRemoveGroup = (
  period: OnCallPeriod,
  groupIdToRemove: number | null,
) => {
  const api = useApi();

  return useCallback(() => {
    if (groupIdToRemove === null) {
      return Promise.resolve(period);
    }

    if (period.callPools.length === 1) {
      flashError('Oops! The only Group cannot be removed from a Shift.');
      return Promise.resolve(period);
    }

    const request: UpdateOnCallPeriodRequest = {
      start: period.start,
      end: period.end,
      selectedCallPools: [...period.callPools.map(g => g.id)].filter(id => {
        return id !== groupIdToRemove;
      }),
    };

    return api.onCallPeriods(period.id).update(request);
  }, [period, groupIdToRemove, api]);
};
function flashError(arg0: string) {
  throw new Error('Function not implemented.');
}
