import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  // Table
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    minWidth: 650,
  },

  providerText: {
    color: theme.palette.grey[700],
  },
}));
