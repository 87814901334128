import * as React from 'react';
import styled from 'styled-components';
import {
  danger,
  dangerLightest,
  info,
  infoLightest,
  md,
  warning,
  xs,
} from '../theme';

const AlertStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px solid transparent;
  padding: ${md};
  margin-top: ${xs};
  margin-bottom: ${md};
`;

const AlertInfo = styled(AlertStyled)`
  background-color: ${infoLightest};
  border-color: ${info};
`;

const AlertWarn = styled(AlertStyled)`
  background-color: #fcf8e3;
  border-color: ${warning};
  color: #8a6d3b;
`;

const AlertError = styled(AlertStyled)`
  background-color: ${dangerLightest};
  border-color: ${danger};
`;

type Props = {
  className?: string;
  children: React.ReactNode;
  aStyle: 'info' | 'warn' | 'error';
};

const WRAPPERS = {
  info: AlertInfo,
  warn: AlertWarn,
  error: AlertError,
};

export function Alert({children, className = '', aStyle}: Props) {
  const Wrapper = WRAPPERS[aStyle];
  return <Wrapper className={className}>{children}</Wrapper>;
}

export default Alert;
