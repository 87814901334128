import * as React from 'react';
import GuardianDashboard from '../guardianSide/GuardianDashboard';
import OnCallDashboard from '../providerSide/oncall/OnCallDashboard';
import {UserType} from '@src/models';
import {useUser} from '@src/hooks/useUser';

// eslint-disable-next-line import/no-unused-modules
export default function Dashboard() {
  const [, userType] = useUser();

  if (userType === UserType.Guardian) {
    return <GuardianDashboard />;
  }
  if (userType === UserType.Professional) {
    return <OnCallDashboard />;
  }

  console.error(`No Dashboard for ${userType}`);
  return <div>Please contact Support immediately</div>;
}
