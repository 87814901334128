import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import React, {useCallback} from 'react';
import {SeePatientLink} from '../oncall/SeePatientLink';
import {ActionsContextMenu} from './VisitActions/ActionsContextMenu';
import {VisitColumn} from './ColumnSets';
import {LoadingMessage, MobileLinkCell, useStyles} from './layout';
import {DesktopAndMobileTableProps} from '.';
import {LonelyVisit} from '@src/models';
import {useCurrentTime} from '@src/hooks';

export function MobileTable({
  columns,
  directToRoom,
  hairChecked,
  onCancel,
  onShareLink,
  onUpdate,
  returnText,
  visits,
}: DesktopAndMobileTableProps) {
  const classes = useStyles();
  const currentTime = useCurrentTime();

  const columnMapper = useCallback(
    (visit: LonelyVisit) => (column: VisitColumn) => (
      <TableRow key={column.name || column.id}>
        <TableCell variant="head">{column.name}</TableCell>

        <TableCell>
          {column.data(visit, {
            currentTime,
          })}
        </TableCell>
      </TableRow>
    ),
    [currentTime],
  );

  if (!visits.isLoaded()) {
    return (
      <TableContainer className={classes.mobileTableContainer}>
        <Table>
          <TableBody>
            <TableRow>
              <LoadingMessage colSpan={columns.length + 1}>
                Loading visits <FontAwesomeIcon icon={'spinner'} spin />
              </LoadingMessage>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else if (visits.isEmpty()) {
    return (
      <TableContainer className={classes.mobileTableContainer}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={100}>No visits</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return (
    <div>
      {visits
        .sort((a, b) => +a.start - +b.start)
        .map(visit => {
          return (
            <TableContainer
              className={classes.mobileTableContainer}
              key={visit.id}
            >
              <Table key={visit.id}>
                <TableBody>
                  <TableRow>
                    <ActionsContextMenu
                      onCancel={onCancel}
                      onShareLink={onShareLink}
                      onUpdate={onUpdate}
                      returnText={returnText}
                      visit={visit}
                    />
                  </TableRow>
                  {columns.map(columnMapper(visit))}
                  <MobileLinkCell colSpan={2}>
                    <SeePatientLink
                      directToRoom={directToRoom}
                      hairChecked={hairChecked[visit.id]}
                      visit={visit}
                    />
                  </MobileLinkCell>
                </TableBody>
              </Table>
            </TableContainer>
          );
        })}
    </div>
  );
}
