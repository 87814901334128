import React, {useCallback, useState} from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
} from '@material-ui/core';
import {useHistory} from 'react-router';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import {useStyles} from './layout';
import {CallPool} from '@src/models';
import {useUser, useResources} from '@src/hooks';
import {UserId} from '@src/models/User';
import {useApi} from '@src/api/useApi';
import {Button} from '@src/components/ui/form';
import {HelpToolTip} from '@src/components/ui/atoms/buttonsAndIcons/HelpToolTip';

type Props = {
  providerGroup?: CallPool;
  parent: CallPool;
  children: CallPool[];
};

export function RelatedProviderGroups({
  providerGroup,
  parent,
  children,
}: Props) {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [user] = useUser();
  const api = useApi();

  const providerId = user
    .getOptional()
    .map(u => u.id)
    .orElse(0 as UserId);

  const [managedAndMemberGroupsForUser] = useResources(
    () => api.provider(providerId).getGroups({includeVisitsUnsupported: true}),
    [api, providerId],
  );

  const mappedManagedAndMemberGroupsForUser = managedAndMemberGroupsForUser
    .getAllOptional()
    .orElse([])
    .reduce((accumulator, group) => {
      return {...accumulator, [group.id]: true};
    }, {} as Record<number, boolean>);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  function getParentListItem() {
    const isSelf = parent?.id === providerGroup?.id;

    return (
      <ListItem>
        <ListItemText
          disableTypography
          primary={
            <Typography
              className={`${classes.listItemText} ${
                isSelf ? classes.selfText : ''
              }`}
              onClick={() => {
                if (isSelf) {
                  return;
                }
                history.push(`/provider-group/${parent?.id}`);
              }}
            >
              {parent?.nameForProviders}
            </Typography>
          }
        />
        {parent?.id === providerGroup?.id && (
          <ListItemIcon>
            <StarBorder />
          </ListItemIcon>
        )}
        {open ? (
          <ExpandLess onClick={handleClick} style={{cursor: 'pointer'}} />
        ) : (
          <ExpandMore onClick={handleClick} style={{cursor: 'pointer'}} />
        )}
      </ListItem>
    );
  }

  function getChildrenListItems() {
    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map(c => {
            const isSelf = c?.id === providerGroup?.id;
            const canAccessGroup = mappedManagedAndMemberGroupsForUser[c.id];

            return (
              <ListItem className={classes.nested} key={c.id}>
                <ListItemText
                  disableTypography
                  primary={
                    <Button
                      bStyle="text"
                      onClick={() => {
                        if (isSelf) {
                          return;
                        }

                        history.push(`/provider-group/${c.id}`);
                      }}
                      disabled={!canAccessGroup}
                      className={`${classes.listItemText} ${
                        isSelf ? classes.selfText : ''
                      }`}
                    >
                      {c?.nameForProviders}
                    </Button>
                  }
                />
                {c.id === providerGroup?.id && (
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                )}
                {!canAccessGroup && (
                  <ListItemIcon>
                    <HelpToolTip text="Since you are not a Member or Manager of this group, you cannot view its page." />
                  </ListItemIcon>
                )}
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    );
  }

  return (
    <List component="nav" className={classes.root}>
      {getParentListItem()}
      {getChildrenListItems()}
    </List>
  );
}
