import React, {useEffect} from 'react';
import {usePageContext} from '..';
import {HistoryBox} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/HistoryBox';
import {MedicalHistoryDetails, UserType, Guardian} from '@src/models';
import {renderMedicalHistorySections} from '@src/components/shared/forms/medicalHistoryFunctions';
import {useAsync, useUser} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {
  reference,
  conjugate,
} from '@src/util/stringManipulation/languageHelpers';

interface MedicalInput<T> {
  name: keyof T;
  instructions: string;
  title: string;
  placeholder?: string;
}

const immunizationOptions = () => [
  {value: 'yes', label: 'Yes'},
  {value: 'not_sure', label: "I don't know"},
  {value: 'no', label: 'No'},
];

export function MedicalHistoryPanel() {
  const api = useApi();

  const [user] = useUser();

  const [patientIsCurrentUser, setPatientIsCurrentUser] = useAsync<boolean>();

  const {
    currentMedicalHistory,
    setCurrentMedicalHistory,
    patient,
    reviewMode,
    selectedPanel,
  } = usePageContext();

  useEffect(() => {
    if (patientIsCurrentUser.isAsked()) {
      return;
    }
    patient.getOptional().ifPresent(p => {
      setPatientIsCurrentUser();
      api
        .patient(p.id)
        .findSelfRelationship()
        .then(r => {
          if (r === null) {
            setPatientIsCurrentUser(false);
          }
          const selfId = r?.personId;
          setPatientIsCurrentUser(
            user
              .getOptional()
              .cast<Guardian>(u => u.userType === UserType.Guardian)
              .map(g => g.responsiblePersonDetails)
              .map(rp => rp.id)
              .map(rpId => rpId === selfId)
              .orElse(false),
          );
        })
        .catch(e => console.warn(e));
    });
  }, [api, patient, patientIsCurrentUser, setPatientIsCurrentUser, user]);

  if (selectedPanel !== 'medicalHistory' || !patientIsCurrentUser.isLoaded())
    return null;

  const isProfessional = user
    .getOptional()
    .filter(u => u.userType === UserType.Professional)
    .isPresent();

  const canCurrentUserEdit = isProfessional ? true : !reviewMode;

  const oPatient = patient.getOptional();
  const patientId = oPatient.map(p => p.id).orElse(0);
  const preferredFirstName = oPatient
    .map(p => p.preferredFirstName)
    .orElse('this patient');

  const renderInput = ({
    name,
    title,
    instructions,
    placeholder = '',
  }: MedicalInput<MedicalHistoryDetails>) => (
    <HistoryBox
      canEdit={canCurrentUserEdit}
      title={title}
      instructions={instructions}
      property={name}
      placeholder={placeholder}
      medicalHistory={currentMedicalHistory.getOptional()}
      onUpdateMedicalHistory={setCurrentMedicalHistory}
      patientId={patientId}
    />
  );

  const rf = reference(
    preferredFirstName,
    patientIsCurrentUser.getOptional().orElse(false),
  );

  const c = conjugate(patientIsCurrentUser.getOptional().orElse(false));
  return (
    <>
      {renderMedicalHistorySections(
        {
          patientName: preferredFirstName,
          patientIsUser: patientIsCurrentUser.getOptional().orElse(false),
          immunizationComponent: () => (
            <HistoryBox
              canEdit={true}
              title={'Immunizations up to date?'}
              instructions={`${c('Is')} ${rf(
                'poss',
              )} immunizations up to date?`}
              property={'immunizations_up_to_date'}
              medicalHistory={currentMedicalHistory.getOptional()}
              onUpdateMedicalHistory={setCurrentMedicalHistory}
              patientId={patientId}
              options={immunizationOptions()}
            />
          ),
        },
        renderInput,
      )}
    </>
  );
}
