import classnames from 'classnames';
import React, {ReactElement} from 'react';
import AutoSuggest from '../AutoSuggest';
import {useSuggestionGetter} from './useSuggestionGetter';
import {getProviderDescription} from '@src/util/provider';
import {useApi} from '@src/api/useApi';
import {ProviderDetails} from '@src/models';

const userIsPCP = (
  u: {id: -1; createUserMessage: string} | ProviderDetails,
): u is ProviderDetails => u.id !== -1;

type Props = {
  name: string;
  className?: string;
  instructions?: string;
  selectedUser: string;
  selectUser: (user: ProviderDetails | null) => void;
  userType: 'Provider';
  renderUser: (user: ProviderDetails) => ReactElement;
  title: string;
  placeholder?: string;
  required?: boolean;
  slimline?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  touched?: boolean;
  error?: string | boolean;
  includeNonSearchable?: boolean;
};

export function SearchByProvider({
  className,
  disabled = false,
  error,
  instructions = '',
  placeholder,
  renderUser,
  required = false,
  selectedUser = '',
  selectUser,
  slimline,
  title = '',
  touched,
  includeNonSearchable,
  name,
}: Props) {
  const api = useApi();
  const [userSuggestions, getSuggestions] = useSuggestionGetter({
    api: api.provider(),
    includeNonSearchable,
  });

  return (
    <div
      className={classnames(['search-by-user', className, {disabled}])}
      style={{marginBottom: '1rem'}}
    >
      <AutoSuggest
        required={required}
        className="search-by-user--input"
        disabled={disabled}
        slimline={slimline}
        error={error}
        placeholder={placeholder || ''}
        getSuggestionKey={(
          user: {id: -1; createUserMessage: string} | ProviderDetails,
        ) => {
          return `${user.id}`;
        }}
        getSuggestions={getSuggestions}
        getSuggestionValue={(
          details: {id: -1; createUserMessage: string} | ProviderDetails,
        ) => {
          if (userIsPCP(details)) {
            return getProviderDescription(details);
          }
          return details.createUserMessage;
        }}
        instructions={instructions}
        name={name}
        onChange={selectUser}
        renderSuggestion={renderUser}
        suggestions={userSuggestions}
        title={title}
        touched={touched}
        value={selectedUser}
      />
    </div>
  );
}
