import React, {useCallback} from 'react';
import {Grid} from '@material-ui/core';
import {usePageContext} from '..';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';
import {LightHeading} from '@src/components/ui/layout/text/heading/LightHeading';
import {ConfirmModal} from '@src/components/ui/layout/ConfirmModal';
import {RemovableList} from '@src/components/ui/molecules/lists/RemovableList';
import {NAMES} from '@src/components/ui/layout/text/names';

export function Current() {
  const {
    clientConfigurationsResources: {
      setClientConfigurationToRemoveId,
      onRemoveClientConfiguration,
      clientConfigurationToRemoveId,
      groupClientConfigurations,
    },
  } = usePageContext();

  const mappable = groupClientConfigurations
    .getAllOptional()
    .orElse([])
    .map(e => ({
      label: e.customerCode,
      id: e.id,
    }));

  const handleRemoveClicked = useCallback(
    (id: number) => {
      setClientConfigurationToRemoveId(id);
    },
    [setClientConfigurationToRemoveId],
  );

  const handleCancelRemove = useCallback(() => {
    setClientConfigurationToRemoveId(null);
  }, [setClientConfigurationToRemoveId]);

  return (
    <>
      <Grid item xs={12}>
        <LightHeading>Current</LightHeading>
      </Grid>
      <Grid item xs={12}>
        <RemovableList
          handleActionClicked={handleRemoveClicked}
          mappable={mappable}
          emptyMessage={`This ${toTitleCase(
            NAMES.callPool,
          )} currently has no ${toTitleCase(NAMES.customerCode)}s`}
        />
      </Grid>

      <ConfirmModal
        onConfirm={onRemoveClientConfiguration}
        text={`Confirming will remove this ${toTitleCase(
          NAMES.customerCode,
        )} from the ${toTitleCase(NAMES.callPool)}.`}
        show={clientConfigurationToRemoveId !== null}
        onHide={handleCancelRemove}
        onCancel={handleCancelRemove}
        cancelText="Cancel"
        confirmText={`Remove ${toTitleCase(NAMES.customerCode)}`}
        titleText={`Remove ${toTitleCase(NAMES.customerCode)}`}
      />
    </>
  );
}
