import React from 'react';
import {Grid} from '@material-ui/core';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';

export function NoResource({noResourceText}: {noResourceText: string}) {
  const instructions = <ParagraphText>{noResourceText}</ParagraphText>;

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          textAlign: 'center',
          maxWidth: '600px',
          margin: '3rem auto',
        }}
      >
        {instructions}
      </Grid>
    </Grid>
  );
}
