import {makeStyles, Theme, createStyles} from '@material-ui/core';
import {md, mdLg, sm, xs} from '@src/components/ui/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootComponent: {
      color: theme.palette.secondary.contrastText,
    },
    formList: {
      marginBottom: md,
    },
    formListRow: {
      margin: `${xs} 0`,
      padding: sm,
      backgroundColor: 'rgba(255,255,255,0.2)',
      borderRadius: xs,
    },
    messageText: {
      fontStyle: 'italic',
    },
    actionGrid: {
      height: mdLg,
      textAlign: 'right',
    },
    actionItem: {
      color: theme.palette.secondary.contrastText,
    },
    sectionTitle: {
      color: theme.palette.secondary.contrastText,
      marginBottom: sm,
    },
  }),
);
