import React, {ReactNode} from 'react';
import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';

type Props = {
  children: ReactNode | null;
  title?: string;
  type?: 'error' | 'info' | 'warning';
  style?: Object;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertContainer: {
      borderRadius: '4px',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.black}`,
    },
    alertContent: {
      padding: 0,
      width: '100%',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontSize: 15,
    },
    iconBase: {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      fontSize: '1.25rem',
      marginRight: '0.75rem',
      padding: '1rem',
      flex: '0 0 1rem',
      backgroundColor: theme.palette.primary.main,
      opacity: 1,
      '& .MuiSvgIcon-root': {
        fill: 'white',
      },
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
    },
    info: {
      backgroundColor: theme.palette.primary.main,
    },
    error: {
      backgroundColor: theme.palette.error.main,
    },
  }),
);

export const Banner = ({type = 'info', title, children, style}: Props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      className={classes.alertContainer}
      style={style}
    >
      <Alert
        severity={type}
        classes={{
          root: classes.alertContent,
          icon: `${classes.iconBase} ${classes[type]}`,
        }}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {children}
      </Alert>
    </Grid>
  );
};
