import {useCallback, useMemo, useState} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {
  useAddableSectionResources,
  AddableSectionResources,
} from './useAddableSectionResources';
import {LegalDocument} from '@src/models';
import {useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';

export type EditDocumentsModal = {
  editDocumentsForGroupId: number | null;
  setEditDocumentsForGroupId: (callPoolId: number | null) => unknown;
  hideDocumentsModal: () => unknown;
  groupDocuments: AsyncData<LegalDocument>;
  addableSectionResources: AddableSectionResources;
};

export function useEditDocumentsModal(): EditDocumentsModal {
  const api = useApi();

  const [editDocumentsForGroupId, setEditDocumentsForGroupId] = useState<
    number | null
  >(null);

  const [groupDocuments, reloadGroupDocuments, , resetResources] = useResources(
    () => api.callPool(editDocumentsForGroupId ?? 0).getDocuments(),
    [api, editDocumentsForGroupId],
    {
      requestGate: () => {
        return editDocumentsForGroupId !== null;
      },
    },
  );

  const addableSectionResources = useAddableSectionResources({
    editDocumentsForGroupId,
    reloadGroupDocuments,
  });

  const hideDocumentsModal = useCallback(() => {
    resetResources();
    setEditDocumentsForGroupId(null);
  }, [resetResources]);

  return useMemo(
    () => ({
      editDocumentsForGroupId,
      setEditDocumentsForGroupId,
      groupDocuments,
      hideDocumentsModal,
      addableSectionResources,
    }),
    [
      addableSectionResources,
      editDocumentsForGroupId,
      groupDocuments,
      hideDocumentsModal,
    ],
  );
}
