import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Grid} from '@material-ui/core';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {ViewContentContainer} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/ViewContentContainer';
import {isUserRequester} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/utils/isUserRequest';
import {LastUpdated} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/LastUpdated';
import {UpdateMode} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/UpdateModal';
import {Actions} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/PendingView/Actions';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {sm, md} from '@src/components/ui/theme';
import {SCPChangeRequest, SCPChangeRequestStatus} from '@src/models';
import {useUser} from '@src/hooks';
import {useLastUpdatedGetter} from '@src/hooks/scp/changeRequests/useLastUpdatedGetter';
import {Banner} from '@src/components/ui/layout/Banner';

export function PendingValue({
  changeRequest,
  setShowUpdateModal,
  setPendingChangeToggled,
}: {
  changeRequest: SCPChangeRequest;
  setShowUpdateModal: (v: UpdateMode) => unknown;
  setPendingChangeToggled: (toggled: boolean) => unknown;
}) {
  const {isOwner, isUserPartner} = usePageContext();
  const [user] = useUser();

  const isRequester = isUserRequester(
    changeRequest,
    user.getOptional().orNothing(),
  );

  const hasOwnerAccess = isOwner || isUserPartner;
  const viewOnly = !hasOwnerAccess && !isRequester;

  const {newValue} = changeRequest;

  const updatable = useLastUpdatedGetter(Optional.of(changeRequest));
  const isObsolete = changeRequest.status === SCPChangeRequestStatus.Obsolete;

  return (
    <ViewContentContainer>
      <Grid container direction="row">
        {isObsolete && (
          <Grid item xs={12} style={{marginTop: sm, marginBottom: md}}>
            <Banner type="warning">
              Another Change Request has been accepted already. Please either
              re-request this Change Request or delete it and create a new one.
            </Banner>
          </Grid>
        )}

        <Grid item xs={12} style={{marginTop: sm}}>
          <Typography>{newValue}</Typography>
        </Grid>

        <Grid item xs={12} style={{marginTop: md}}>
          <LastUpdated updated={updatable} />
        </Grid>

        {!viewOnly && (
          <Actions
            changeRequest={changeRequest}
            setShowUpdateModal={setShowUpdateModal}
            setPendingChangeToggled={setPendingChangeToggled}
          />
        )}
      </Grid>
    </ViewContentContainer>
  );
}
