import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import {Link} from 'react-router-dom';
import {AhanaLogo} from './AhanaLogo';
import {RefyneLogo} from './RefyneLogo';
import ConfigService from '@src/ConfigService';

const useStyles = makeStyles(theme => ({
  svg: {
    height: '30px',
    x: '0px',
    y: '0px',
    viewBox: '0 0 113.6 25',
    maxWidth: '100%',
  },
  navLogo: {
    zIndex: 2,
    marginRight: '0px',
    maxWidth: '124px',
    minWidth: '124px',
  },
}));

export function Logo({to, style}: {to?: string; style?: Object}) {
  const classes = useStyles();

  const domain = ConfigService.getBaseDomain();

  const LogoComponent = domain === 'ahana.health' ? AhanaLogo : RefyneLogo;

  return (
    <Link to={to ?? '/'} className={classes.navLogo} style={style}>
      <LogoComponent />
    </Link>
  );
}
