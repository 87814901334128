import React, {useState, useEffect, useRef} from 'react';
import {RouteComponentProps} from 'react-router';
import {Redirect} from 'react-router-dom';
import {
  getTwilioParticipants,
  getAnonymousVisitorCookie,
} from '../../../util/videoChat';
import {
  getAnonymousAccessToken,
  getAuthorizedAccessToken,
  renderExamRoom,
} from './mainFunctions';
import {Reason as ErrorReason} from './pages/functions/types';
import {useUser} from '@src/hooks';
import {useApi} from '@src/api/useApi';

type Props = RouteComponentProps<{
  visitId: string;
}>;

function VideoChat({match}: Props) {
  const [, , , , isAnonymous] = useUser();

  const api = useApi();

  const visitId = +match.params.visitId;

  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<ErrorReason | null>(null);

  const anonInfo = useRef(getAnonymousVisitorCookie());

  useEffect(() => {
    if (token) {
      return;
    }

    if (isAnonymous && anonInfo.current) {
      getAnonymousAccessToken({
        api,
        visitId,
        anonInfo: anonInfo.current,
        setError,
        setToken,
      });
    }

    if (!isAnonymous) {
      getAuthorizedAccessToken({api, visitId, setError, setToken});
    }

    if (!error && token) {
      getTwilioParticipants({api, visitId})
        .then(l => {
          if (l.length >= 4) {
            setError('roomFull');
          }
        })
        .catch(e => {
          console.error(e);
          setError('error');
        });
    }
  }, [isAnonymous, api, error, token, visitId, anonInfo]);

  if (error) {
    return <Redirect to={`/dead-end/${error}/${visitId}`} />;
  }

  if (isAnonymous && !getAnonymousVisitorCookie()) {
    return <Redirect to={`/waiting-room/${visitId}`} />;
  }

  return renderExamRoom({
    token,
    isAnonymous,
    visitId,
    anonInfo: anonInfo.current,
  });
}

export default VideoChat;
