import React, {useCallback, useState} from 'react';
import {
  Checkbox,
  FormGroup,
  FormLabel,
  FormControlLabel,
} from '@material-ui/core';
import {MultipleChoiceField, MultipleChoiceOption} from '@src/models';

interface Props {
  disabled?: boolean;
  field: MultipleChoiceField;
  onChange?: (multipleChoiceOptionId: number[]) => unknown;
  options: MultipleChoiceOption[];
}

export default function MultipleResponse({
  disabled = true,
  field,
  onChange = () => {},
  options = [],
}: Props) {
  const [responses, setResponses] = useState<{[key: number]: boolean}>({});

  const handleMultiChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
      const updatedResponses = {...responses, [id]: !responses[id]};
      setResponses(updatedResponses);
      onChange(
        Object.entries(updatedResponses).reduce(
          (ids: number[], [key, value]) =>
            value ? [...ids, Number(key)] : ids,
          [],
        ),
      );
    },
    [onChange],
  );

  return (
    <fieldset>
      <FormLabel component="div" required={field.required} disabled={disabled}>
        {/*
         * Please note the subset of HTML tags considered valid within a <legend> tag.
         * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/legend#technical_summary
         *
         * > Phrasing content and headings (h1–h6 elements).
         *
         * https://developer.mozilla.org/en-US/docs/Web/HTML/Content_categories#phrasing_content
         * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements
         */}
        <legend
          className="inline-block"
          dangerouslySetInnerHTML={{__html: field.description}}
        />
      </FormLabel>
      <FormGroup>
        {options.map(option => (
          <FormControlLabel
            key={option.id}
            control={
              <Checkbox
                checked={responses[option.id] ?? false}
                onChange={e => handleMultiChange(e, option.id)}
                name={option.name}
                disabled={disabled}
              />
            }
            label={option.description}
          />
        ))}
      </FormGroup>
    </fieldset>
  );
}
