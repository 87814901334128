import {Paper} from '@material-ui/core';
import React, {useCallback, useEffect, useRef} from 'react';
import SignaturePad, {PointGroup} from 'signature_pad';

type ChangeHandler = (data: PointGroup[]) => unknown;

interface Props {
  data?: PointGroup[];
  onChange?: ChangeHandler;
}

export default function DrawInput({data = [], onChange}: Props) {
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const signaturePadInstance = useRef<SignaturePad | null>(null);
  const onChangeRef = useRef<ChangeHandler>(onChange ?? (() => {}));

  const handleResize = useCallback(() => {
    if (canvas.current !== null) {
      const backup = signaturePadInstance.current?.toData();

      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.current.width = (canvas.current.offsetWidth * ratio) | 0;
      canvas.current.height = (canvas.current.offsetHeight * ratio) | 0;
      canvas.current.getContext('2d')?.scale(ratio, ratio);

      if (backup) {
        signaturePadInstance.current?.fromData(backup);
      }
    }
  }, []);

  const handleRef = useCallback(
    (c: HTMLCanvasElement | null) => {
      canvas.current = c;

      if (canvas.current) {
        signaturePadInstance.current = new SignaturePad(canvas.current);

        handleResize();

        signaturePadInstance.current.addEventListener('endStroke', () => {
          onChangeRef.current(signaturePadInstance.current!.toData());
        });
      }
    },
    [handleResize],
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    onChangeRef.current = onChange ?? (() => {});
  }, [onChange]);

  useEffect(() => {
    signaturePadInstance.current?.fromData(data);
  }, [data]);

  return (
    <Paper variant="outlined" square>
      <canvas ref={handleRef} style={{width: '100%'}} />
    </Paper>
  );
}
