import {Grid} from '@material-ui/core';
import React, {useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {StepProps} from '.';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {Button} from '@src/components/ui/form';
import {FlowProps} from '@src/hooks/useSteps';
import {useResources} from '@src/hooks';
import {CallPool} from '@src/models';
import {useApi} from '@src/api/useApi';

type Props = StepProps & FlowProps;

export function VisitTypeStep({
  patient,
  visitType,
  setNextAvailable,
  proceedToNextStep,
}: Props) {
  const api = useApi();
  const [availableGroups] = useResources<CallPool>(
    () => api.patient(patient?.id ?? 0).getOnDemandCallPools(),
    [api, patient],
  );

  useEffect(() => {
    setNextAvailable(visitType.value !== null);
  }, [visitType.value, setNextAvailable]);

  return (
    <>
      <RequestHeader>Visit Type</RequestHeader>
      <RequestInstructions>
        <p>
          What type of Visit do you want to create for{' '}
          {patient?.firstName ?? 'this patient'}?
        </p>
      </RequestInstructions>
      <Grid container direction="column">
        <Grid item>
          <Button
            isInRow={true}
            onClick={() => {
              visitType.set('Scheduled');
            }}
            bStyle={visitType.value === 'Scheduled' ? 'contained' : 'outlined'}
          >
            Scheduled Visit
          </Button>

          <Button
            isInRow={true}
            disabled={availableGroups.getAllOptional().orElse([]).length === 0}
            onClick={() => {
              visitType.set('OnDemand');
            }}
            bStyle={visitType.value === 'OnDemand' ? 'contained' : 'outlined'}
          >
            {!availableGroups.isLoaded() ? (
              <>
                Checking for available providers{' '}
                <FontAwesomeIcon icon={'spinner'} spin />
              </>
            ) : availableGroups.isEmpty() ? (
              <>No providers available</>
            ) : (
              <>On-Demand Visit</>
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
