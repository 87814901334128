import React from 'react';
import {isSupported} from 'twilio-video';
import {MediaFailure} from '../../../../../util/videoChat';
import {Button} from '../../../../ui/form';
import {Modal} from '../../../../ui/layout/Modal';

type Props = {
  failure: MediaFailure;
  returnToSafety: () => void;
};

const Messages: Record<MediaFailure, React.ReactNode> = {
  [MediaFailure.None]: (
    <>
      <p>
        This message only appears if there's a problem accessing your webcam or
        microphone that we didn't expect.
      </p>
      <p>
        If you see this, please contact Support at the number below and let them
        know that you're seeing the "No Media Failure Error" message.
      </p>
    </>
  ),
  [MediaFailure.DeviceCannotBeStarted]: (
    <>
      <p>
        This message means that your browser has told us that a device is
        available, but every time we ask for access, the browser tells us that
        it can't start it.
      </p>
      <p>
        If you see this, please let Support know, but your best bet is to try
        another supported browser or another device.
      </p>
    </>
  ),
  [MediaFailure.PermissionDenied]: (
    <>
      <p>
        It looks like we have been denied permission to access your devices.
      </p>
      <p>
        This can happen if you accidentally press the wrong button when asked
        for permission or if you denied Refyne Connected Care permission at some
        point in the past.
      </p>
      <p>
        This can also happen if you close the permission request without
        allowing or blocking access.
      </p>
      <p>
        Please re-enable permission for Refyne Connected Care to access your
        webcam and then refresh the page to try again.
      </p>
    </>
  ),
  [MediaFailure.PermissionDismissed]: (
    <>
      <p>It looks like you didn't give us permission to access your devices.</p>
      <p>
        This can happen when you dismiss the request for permission without
        actually answering the question.
      </p>
      <p>
        Please refresh the page so that your browser asks for permission again.
      </p>
    </>
  ),
  [MediaFailure.DeviceInUse]: (
    <>
      <p>
        It looks like your webcam and/or mic are in use by another application.
      </p>
      <p>This happens most frequently on browser on a Windows machine</p>
      <p>
        Please close any applications that are using your webcam and then
        refresh this page to try again.
      </p>
    </>
  ),
  [MediaFailure.NotSupported]: (
    <>
      {isSupported && (
        <p>
          This is not an error that we normally expect to see in browsers that
          we support. Please contact our tech team on the number below.
        </p>
      )}
      {!isSupported && (
        <p>
          As far as we can tell, your browser is not supported. Please use a
          different browser.
        </p>
      )}
    </>
  ),
  [MediaFailure.NoDevicesFound]: (
    <>
      <p>
        When we asked the browser for your webcams and microphones, it told us
        your don't have any.
      </p>
      <p>
        Please check and make sure that your device has a camera built-in or
        attached.
      </p>
      <p>
        If your devices are correctly attached and you're sure they're working
        properly, then please make sure that your operating system is configure
        to allow your browser to access them.
      </p>
      <p>
        If you're still having problems, please contact our Tech Team and the
        number below.
      </p>
    </>
  ),
  [MediaFailure.DeviceNotFound]: (
    <>
      <p>
        When we asked the browser for your webcams and microphones, it told us
        your don't have any.
      </p>
      <p>
        Please check and make sure that your device has a camera built-in or
        attached.
      </p>
      <p>
        If your devices are correctly attached and you're sure they're working
        properly, then please make sure that your operating system is configure
        to allow your browser to access them.
      </p>
      <p>
        If you're still having problems, please contact our Tech Team and the
        number below.
      </p>
    </>
  ),
  [MediaFailure.Unknown]: (
    <>
      <p>
        We got an error that we didn't expect when we requested access to your
        webcam and microphone.
      </p>
      <p>Please contact our Tech Team and the number below.</p>
    </>
  ),
};

export const NoAccessToDevicesModal: React.FunctionComponent<Props> = ({
  returnToSafety,
  failure,
}) => {
  return (
    <Modal
      show={failure !== MediaFailure.None}
      title="Cannot access camera and microphone"
      onClose={returnToSafety}
      modalActions={
        <Button bStyle={'primary'} block onClick={returnToSafety}>
          Go back
        </Button>
      }
    >
      <div>
        <p>We are not able to access your camera and microphone.</p>
        {Messages[failure]}
        <p>
          If you are not able to resolve this issue, please contact Connected
          Care Support on <a href="tel:+18778642378">+1-877-864-2378</a>.
        </p>
      </div>
    </Modal>
  );
};
