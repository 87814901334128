import {Optional, RemoteDataStatus} from '@ahanapediatrics/ahana-fp';
import React, {useEffect, useState, useCallback} from 'react';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {Button} from '../../ui/form/Button';
import {ButtonColumnContainer} from '../../ui/layout/ButtonContainers';
import {MobileResponsiveButtonContainer} from '../CreateOrJoinVisit/layout';
import {CallPoolSelector} from './CallPoolSelector';
import {Visits} from './Visits';
import {ProviderList} from './ProviderList';
import {CallPool, Patient, SimpleVisit, ProviderDetails} from '@src/models';
import {useAsync} from '@src/hooks';
import {
  RequestBox,
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {useApi} from '@src/api/useApi';
import {ReduxState} from '@src/store';
import {ProviderDetailsId} from '@src/models/ProviderDetails';

type Props = {
  patient: Optional<Patient>;
};

enum TypeOfVisit {
  Scheduled,
  WalkIn,
}

export function VisitType({patient}: Props) {
  const api = useApi();
  const history = useHistory();
  const configuration = useSelector((state: ReduxState) => state.configuration);
  const [allowedTypes, setAllowedTypes] = useState<TypeOfVisit[]>([]);
  const [type, setType] = useState<Optional<TypeOfVisit>>(Optional.empty());
  const [iDontSeeMyVisit, setIDontSeeMyVisit] = useState(false);
  const [scheduledVisits, setScheduledVisits] = useAsync<SimpleVisit>();
  const [callPools, setCallPools] = useAsync<CallPool>();
  const [selectedCallPoolId, selectCallPoolId] = useState(0);
  const [poolMembers, setPoolMembers, resetMembers] = useAsync<
    ProviderDetails
  >();

  useEffect(() => {
    configuration.getOptional().ifPresent(config => {
      const {customerConfig} = config;
      const allowed = customerConfig?.supportedVisitTypes ?? [];

      if (allowed.length === 0) {
        setAllowedTypes([TypeOfVisit.WalkIn, TypeOfVisit.Scheduled]);
      } else {
        setAllowedTypes(
          allowed.map(visitType =>
            visitType === 'onDemand'
              ? TypeOfVisit.WalkIn
              : TypeOfVisit.Scheduled,
          ),
        );
      }
    });
  }, [configuration]);

  useEffect(() => {
    if (allowedTypes.length === 1) {
      setType(Optional.of(allowedTypes[0]));
    }
  }, [allowedTypes]);

  useEffect(() => {
    if (scheduledVisits.is(RemoteDataStatus.NotAsked) && patient.isPresent()) {
      api
        .patient(patient.get().id)
        .getActiveVisits()
        .then(setScheduledVisits);
    }
  }, [api, patient, scheduledVisits, setScheduledVisits]);

  useEffect(() => {
    if (!patient.isPresent()) {
      return;
    }
    type.ifPresent(visitType => {
      if (visitType === TypeOfVisit.WalkIn) {
        setCallPools();
        api
          .patient(patient.get().id)
          .getOnDemandCallPools()
          .then(setCallPools);
      } else {
        setCallPools();
        api
          .callPool()
          .getOpenScheduledPools()
          .then(setCallPools);
      }
    });
  }, [api, patient, setCallPools, type]);

  useEffect(() => {
    if (selectedCallPoolId !== 0 && type.orNull() === TypeOfVisit.Scheduled) {
      setPoolMembers();
      api
        .callPool(selectedCallPoolId)
        .getMembers()
        .then(m => {
          setPoolMembers(m.filter(mb => mb.seesPatients));
        });
    }
  }, [api, selectedCallPoolId, setPoolMembers, type]);

  const startVisitCreation = useCallback(
    (providerDetailsId: ProviderDetailsId) => {
      history.push(
        `/new-visit/${selectedCallPoolId}/${
          patient.get().id
        }?providerDetailsId=${providerDetailsId}`,
      );
    },
    [history, patient, selectedCallPoolId],
  );

  if (!patient.isPresent()) {
    return null;
  }

  return (
    <RequestBox>
      {allowedTypes.length === 2 && (
        <>
          <RequestHeader>What type of visit is this?</RequestHeader>
          <RequestInstructions>
            <p>
              A scheduled visit is one that has already been confirmed by your
              physician. If you haven't pre-scheduled your appointment, please
              choose <strong>Walk-in Visit</strong> and we will get you
              connected.
            </p>
          </RequestInstructions>
          <MobileResponsiveButtonContainer>
            <Button
              bStyle="primary"
              fullWidth
              onClick={() => {
                setType(Optional.of(TypeOfVisit.Scheduled));
                setCallPools();
                setIDontSeeMyVisit(false);
                selectCallPoolId(0);
                resetMembers();
              }}
            >
              Scheduled Visit
            </Button>
            <Button
              bStyle="primary"
              fullWidth
              onClick={() => {
                setType(Optional.of(TypeOfVisit.WalkIn));
                setCallPools();
                setIDontSeeMyVisit(false);
                selectCallPoolId(0);
                resetMembers();
              }}
            >
              Walk-in Visit
            </Button>
          </MobileResponsiveButtonContainer>
        </>
      )}
      {type.filter(t => t === TypeOfVisit.Scheduled).isPresent() && (
        <>
          <RequestHeader>Do you see your visit?</RequestHeader>
          <RequestInstructions>
            <p>
              If you see your scheduled visit below, please select the proper
              appointment. If you do not see your scheduled appointment, please
              click <strong>I don't see my visit</strong>.
            </p>
          </RequestInstructions>
          <Visits
            patient={patient.get()}
            visits={scheduledVisits.getAllOptional().orNothing()}
          />
          <ButtonColumnContainer>
            <Button
              bStyle="secondary"
              onClick={() => {
                setIDontSeeMyVisit(true);
              }}
            >
              I don't see my visit
            </Button>
          </ButtonColumnContainer>
        </>
      )}
      {iDontSeeMyVisit && (
        <CallPoolSelector
          patientId={patient.get().id}
          selectedCallPool={null}
          availablePools={callPools}
          header="Who is your appointment with today?"
          instructions={
            <>
              <p>
                Look for the practice, clinic or department that you're talking
                to today and select it.
              </p>
              <p>You'll then be able to tell us which doctor you're seeing.</p>
            </>
          }
          onSelect={cpId => {
            selectCallPoolId(cpId);
          }}
        />
      )}
      {type.filter(t => t === TypeOfVisit.WalkIn).isPresent() && (
        <CallPoolSelector
          patientId={patient.get().id}
          selectedCallPool={null}
          availablePools={callPools}
          header="Who would you like to see today?"
          instructions={
            <p>
              Look for the group that you are hoping to speak with today and
              click <strong>Continue</strong>.
            </p>
          }
          onContinue={cpId => {
            history.push(`/new-visit/${cpId}/${patient.get().id}`);
          }}
        />
      )}
      {poolMembers.isAsked() && (
        <>
          <RequestHeader>Who is the person you will be seeing?</RequestHeader>
          <RequestInstructions>
            <p>
              If you know the name of the person you are seeing, please select
              them from the list below.
            </p>
            <p>
              If you don't see their name or don't recall, please click the
              button below.
            </p>
          </RequestInstructions>
          <Button
            style={{marginBottom: '1rem'}}
            onClick={() => {
              startVisitCreation(0 as ProviderDetailsId);
            }}
          >
            Not Listed/Unsure
          </Button>
          <ProviderList members={poolMembers} onSelect={startVisitCreation} />
        </>
      )}
    </RequestBox>
  );
}
