import React from 'react';
import {isAfter, isBefore, isPast} from 'date-fns';
import {PeriodToEdit, Errors} from '../functions';
import {FormSection} from './FormSection';
import {DateInput, TextInput, TimeInput} from '@src/components/ui/form';
import {isNothing} from '@src/util/typeTests';

type Props = {
  periodToEdit?: PeriodToEdit;
  errors: Errors<{
    startDate: Date;
    startTime: Date | null;
    hours: number;
    minutes: number;
  }>;
  startProps: {
    startDate: Date;
    setStartDate: (v: Date) => unknown;
    startTime: Date | null;
    setStartTime: (v: Date | null) => unknown;
  };
  durationProps: {
    hours: number;
    setHours: (v: number) => unknown;
    minutes: number;
    setMinutes: (v: number) => unknown;
  };
};

export function TimeSection({
  periodToEdit,
  errors,
  startProps: {startDate, startTime, setStartDate, setStartTime},
  durationProps: {hours, minutes, setHours, setMinutes},
}: Props) {
  const now = new Date();
  const isEditMode = !isNothing(periodToEdit);

  const content = (
    <>
      <DateInput
        disabled={isEditMode && isAfter(now, startDate)}
        name={'startDate'}
        value={startDate}
        onChange={date =>
          setStartDate(date ? (isPast(date) ? now : date) : new Date(0))
        }
        label={'What day should this coverage start?'}
        error={errors.startDate}
      />
      <TimeInput
        disabled={isEditMode && isBefore(startDate, now)}
        name={'startTime'}
        value={startTime}
        onChange={date => setStartTime(date)}
        title={'What time should this coverage start?'}
        error={errors.startTime}
      />
      <TextInput
        name={'hours'}
        type={'number'}
        title={'Hours'}
        inputProps={{min: 0}}
        value={`${hours}`}
        onChange={e => {
          setHours(parseInt(e.target.value, 10));
        }}
        touched={true}
        error={errors.hours}
      />
      <TextInput
        name={'minutes'}
        type={'number'}
        title={'Minutes'}
        value={`${minutes}`}
        inputProps={{min: 0}}
        onChange={e => {
          setMinutes(parseInt(e.target.value, 10));
        }}
        touched={true}
        error={errors.minutes}
      />
    </>
  );

  return <FormSection title="Date & Time" content={content} />;
}
