import React from 'react';
import {TableHead, TableRow} from '@material-ui/core';

type Props = {
  headings: React.ReactNode[];
};

export function TableHeader({headings}: Props) {
  return (
    <TableHead>
      <TableRow>{headings}</TableRow>
    </TableHead>
  );
}
