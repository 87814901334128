import React, {useEffect, useRef, useCallback} from 'react';
import {Button} from '../ui/form';
import {Modal} from '../ui/layout/Modal';
import {flashWarn} from './notifications/flash';
import {useLogout} from '@src/hooks/useLogout';

type Props = {
  show: boolean;
  continueWorking: () => unknown;
};

export function TimeoutModal({continueWorking, show}: Props) {
  const logout = useLogout();

  const timeoutId = useRef(0);

  useEffect(() => {
    if (show) {
      timeoutId.current = window.setTimeout(() => {
        flashWarn('We have logged you out in order to keep you data secure');
        return logout();
      }, 60_000);
    } else {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = 0;
      }
    }
  }, [show, logout]);

  const backToWork = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = 0;
    }
    continueWorking();
  }, [continueWorking]);

  const actions = (
    <>
      <Button bStyle="outlined" onClick={logout}>
        Logout
      </Button>
      <Button bStyle="primary" onClick={backToWork}>
        Continue
      </Button>
    </>
  );

  return (
    <Modal
      show={show}
      title="Your session is about to time out"
      modalActions={actions}
    >
      <div>
        We haven&apos;t detected any activity from you for a while. To keep your
        data secure, you will be logged off in the next minute unless you ask us
        not to.
      </div>
    </Modal>
  );
}
