import {Controller, useForm} from 'react-hook-form';
import React from 'react';
import {yupResolver} from '@hookform/resolvers';
import * as yup from 'yup';
import {FormControlLabel, Switch} from '@material-ui/core';
import {Modal} from '../../../../ui/layout/Modal';
import {TextInput, AsyncActionButton} from '@src/components/ui/form';
import {yObject, rqString} from '@src/schema/types';
import {PayloadForAnonymousVisitor} from '@src/util/videoChat/twilio/anonymousVisitor';
import {setAnonymousVisitorCookie} from '@src/util/videoChat';

import {MuiCheckBoxInput} from '@src/components/ui/form/MuiCheckBoxInput';
import {useQuery} from '@src/hooks/useQuery';

type IdentificationModalProps = {
  setAnonymousInfo: (r: PayloadForAnonymousVisitor) => unknown;
  setShowIdentificationModal: (v: boolean) => unknown;
};

const schema = yObject({
  firstName: rqString('Please tell us your first name'),
  lastName: rqString('Please tell us your last name'),
  visitorConfirmedAuthorization: yup
    .boolean()
    .oneOf([true], 'Field must be checked'),
  isPatientSelf: yup.boolean().default(false),
  patientName: yup.string().when('isSelfGuardian', {
    is: false,
    then: yup.string().required("Please provide this patient's name"),
  }),
});

export function IdentificationModal({
  setAnonymousInfo,
  setShowIdentificationModal,
}: IdentificationModalProps) {
  const query = useQuery();
  const firstName = query.get('firstName') ?? '';
  const lastName = query.get('lastName') ?? '';
  const isPatient = query.get('isPatient') === 'true';
  const {
    formState,
    control,
    errors,
    setValue,
    handleSubmit,
    watch,
    getValues,
  } = useForm<PayloadForAnonymousVisitor & {isPatientSelf: boolean}>({
    defaultValues: {
      firstName,
      lastName,
      patientName: isPatient ? `${firstName} ${lastName}` : '',
      visitorConfirmedAuthorization: isPatient,
      isPatientSelf: isPatient,
    },
    resolver: yupResolver(schema),
  });

  const {isSubmitting, touched} = formState;

  const submit = handleSubmit(function(data) {
    setAnonymousInfo(data);
    setAnonymousVisitorCookie(data);
  });

  const isPatientSelf = watch('isPatientSelf');

  function toggleMyDetails() {
    if (!isPatientSelf) {
      setValue(
        'patientName',
        `${getValues().firstName} ${getValues().lastName}`,
      );

      setValue('isPatientSelf', true);
      setValue('visitorConfirmedAuthorization', true);
    } else {
      setValue('patientName', '');
      setValue('isPatientSelf', false);
      setValue('visitorConfirmedAuthorization', false);
    }
  }

  return (
    <Modal
      onClose={() => setShowIdentificationModal(false)}
      show={true}
      title="Visitor Information"
      modalActions={
        <AsyncActionButton
          actionInProgress={isSubmitting}
          actionWord="Submit"
          bStyle="primary"
          onClick={submit}
          style={{marginTop: '2rem'}}
          disabled={isSubmitting}
        />
      }
    >
      <Controller
        control={control}
        name="firstName"
        title="First Name"
        render={props => (
          <TextInput
            onChange={e => {
              setValue('firstName', e.target.value);
              if (isPatientSelf) {
                setValue(
                  'patientName',
                  `${e.target.value} ${getValues('lastName')}`,
                );
              }
            }}
            name="firstName"
            title="First Name"
            value={props.value}
            required
            error={errors.firstName?.message}
            touched={touched.firstName}
          />
        )}
      />
      <Controller
        control={control}
        name="lastName"
        title="Last Name"
        render={props => (
          <TextInput
            required
            error={errors.lastName?.message}
            touched={touched.lastName}
            onChange={e => {
              setValue('lastName', e.target.value);
              if (isPatientSelf) {
                setValue(
                  'patientName',
                  `${getValues('firstName')} ${e.target.value} `,
                );
              }
            }}
            name="lastName"
            title="Last Name"
            value={props.value}
          />
        )}
      />

      <Controller
        defaultValue={false}
        control={control}
        name="isPatientSelf"
        title="Use my details"
        required
        error={errors.isPatientSelf?.message}
        touched={touched.isPatientSelf}
        render={props => (
          <FormControlLabel
            control={
              <Switch
                name="patientIsSelf"
                checked={isPatientSelf}
                color="primary"
                inputProps={{'aria-label': 'checkbox'}}
                onChange={toggleMyDetails}
              />
            }
            label="I am the patient"
          />
        )}
      />

      <div hidden={isPatientSelf}>
        <Controller
          control={control}
          as={TextInput}
          name="patientName"
          title="Patient Name"
          error={errors.patientName?.message}
          touched={touched.patientName}
          disabled={isPatientSelf}
        />
      </div>

      <div hidden={isPatientSelf}>
        <Controller
          control={control}
          disabled={isSubmitting}
          as={MuiCheckBoxInput}
          onChange={(value: boolean) => {
            setValue('visitorConfirmedAuthorization', value);
          }}
          name="visitorConfirmedAuthorization"
          error={errors.visitorConfirmedAuthorization?.message}
          required
          title="I am authorized to seek medical care for this patient"
        />
      </div>
    </Modal>
  );
}
