import React, {useCallback, useState} from 'react';
import {
  Paper,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from '@material-ui/core';
import {getTableItems, TableItem} from './functions';
import {CallPool} from '@src/models';
import {HorizontalSection} from '@src/components/ui/atoms/section/HorizontalSection';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {Button, SelectInput} from '@src/components/ui/form';
import {Modal} from '@src/components/ui/layout/Modal';
import {md} from '@src/components/ui/theme';
import {useOnUpdate} from '@src/hooks/providerGroups/useOnUpdate';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';
import {NAMES} from '@src/components/ui/layout/text/names';

type Props = {
  providerGroup: CallPool;
  onClose: () => unknown;
  resetGroup: () => void;
};

export function EditModal({providerGroup, onClose, resetGroup}: Props) {
  const {nameForProviders, nameForGuardians, description} = providerGroup;

  const [newVisitsUnsupported, setNewVisitsUnsupported] = useState(
    providerGroup?.rules?.visitsUnsupported === true ? true : false,
  );

  const [submitting, setSubmitting] = useState(false);

  const onSubmitRules = useOnUpdate({
    runBeforeRequest: useCallback(() => {
      setSubmitting(true);
    }, []),
    asyncRunAfterRequest: useCallback(async () => {
      flashSuccess('Success');
      resetGroup();
      onClose();
    }, [onClose, resetGroup]),
    runFinally: useCallback(() => {
      setSubmitting(false);
    }, [setSubmitting]),
    runOnError: useCallback(
      () =>
        flashError(
          `Something went wrong updating the ${toTitleCase(
            NAMES.group,
          )}'s rules`,
        ),
      [],
    ),
    groupId: providerGroup.id,
    details: {
      nameForProviders,
      nameForGuardians,
      visitsUnsupported: newVisitsUnsupported,
      description,
    },
  });

  const tableItems = getTableItems(providerGroup);

  return (
    <Modal
      show
      title="Edit Rules"
      onClose={onClose}
      modalActions={
        <>
          <MuiAsyncActionButton
            bStyle="primary"
            disabled={submitting}
            actionInProgress={submitting}
            actionWord="Save"
            onClick={onSubmitRules}
          >
            Save
          </MuiAsyncActionButton>
          <Button bStyle="outlined" onClick={onClose}>
            Cancel
          </Button>
        </>
      }
    >
      <Grid>
        <HorizontalSection>
          <TableContainer
            style={{maxHeight: '50vh', overflow: 'auto'}}
            component={Paper}
          >
            <TableBody>
              {tableItems.map(item => {
                return (
                  <TableRow>
                    <TableCell variant="head">{item.name}</TableCell>
                    <TableCell>
                      {item.name === TableItem.SupportVisits ? (
                        <Grid>
                          <SelectInput
                            value={newVisitsUnsupported ? '0' : '1'}
                            name=""
                            title=""
                            placeholder=""
                            onChange={() => {
                              setNewVisitsUnsupported(!newVisitsUnsupported);
                            }}
                            options={[
                              {label: 'Yes', value: '1'},
                              {label: 'No', value: '0'},
                            ]}
                            error=""
                            fontSize={md}
                          />
                        </Grid>
                      ) : (
                        item.data()
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </TableContainer>
        </HorizontalSection>
      </Grid>
    </Modal>
  );
}
