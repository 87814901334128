import styled from 'styled-components';
import {
  primary,
  primaryLight,
  grey,
  xs,
  sm,
  md,
  lg,
  xl,
  danger,
  xxs,
  xxl,
  disabledBg,
  disabledText,
} from '@src/components/ui/theme';

export const PageTitleContainer = styled.section`
  padding: ${md};
  margin-bottom: ${lg};
  text-align: center;
`;

export const PageTitle = styled.h1<{
  landing: boolean;
}>`
  margin: ${props => (props.landing ? `${md} 0` : '0')};
  padding: 0;
  color: ${primary};
  text-align: ${props => (props.landing ? 'center' : '')};
  font-weight: bold;
  font-size: 3em;
  line-height: 1em;
  user-select: none;
`;

export const PageContent = styled.div`
  padding: 0 ${xl};
`;

export const SinglePageContainer = styled.div`
  background-color: ${primaryLight};
  text-align: center;
  display: table;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
`;

export const ClickHere = styled.span<{
  adminTool?: boolean;
}>`
  color: ${props => (props.adminTool ? `${grey}` : `${primary}`)};
  cursor: pointer;
`;

export const Message = styled.h1<{
  small?: boolean;
  danger?: boolean;
}>`
  color: ${props => (props.danger ? `${danger}` : `${grey}`)};
  font-size: ${props => (props.small ? '1.2rem' : '2rem')};
  font-weight: 300;
  text-align: center;
  line-height: 1.5em;
`;

export const StyledButton = styled.div<{
  isForm: boolean;
  disabled: boolean;
}>`
  background-color: ${props => (props.disabled ? `${disabledBg}` : primary)};
  color: ${props => (props.disabled ? `${disabledText}` : '#ffff')};

  border-color: transparent;
  border-width: ${xxs};
  border-style: solid;
  border-radius: ${xs};

  box-sizing: border-box;

  cursor: ${props => (props.disabled ? 'progress' : 'pointer')};

  display: inline-block;
  font-weight: bold;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  margin: ${props => (props.isForm ? `${sm}` : `0 ${xxl} 0 0`)};
  width: ${props => (props.isForm ? '45%' : '75%')};
  padding: ${props => (props.isForm ? `${sm}` : `${sm} ${md}`)};
  text-transform: uppercase;
  text-align: center;
`;

export * from './Dashboard';
export * from './ForgotPassword';
export * from './Form';
export * from './ProviderRoster';
