import {Optional, RemoteDataStatus} from '@ahanapediatrics/ahana-fp';
import {Icon, makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import React, {useEffect, useRef, useState, useCallback} from 'react';
import {useHistory, useParams} from 'react-router';
import {
  PatientDetailsValues,
  patientToValues,
} from '../SharedPatient/patientDetailsUtils';
import {FlowPage} from '../FlowPage';
import {StepComponents, StepNames, EditPatientStep} from './functions';
import {
  Guardian,
  Patient,
  ProviderDetails,
  UserType,
  User,
  ResponsiblePerson,
} from '@src/models';
import {Button} from '@src/components/ui/form';
import {convertDetailsToRequest} from '@src/api/ProviderDetailsAPI';
import {useAsync, useUser} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {isNothing} from '@src/util/typeTests';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

const useStyles = makeStyles({
  spinner: {
    fontSize: '1em',
    marginLeft: '.75em',
  },
});

// eslint-disable-next-line import/no-unused-modules
export default function EditPatient() {
  const api = useApi();
  const history = useHistory();
  const classes = useStyles();
  const [user, userType, updateUser] = useUser();
  const params = useParams<{patientId: string}>();

  const patientId = +params.patientId;
  /*
   * PATIENT INFO START
   */
  const [patientToEdit, setPatientToEdit] = useAsync<Patient>();
  const [patientDetails, setPatientDetails] = useState<PatientDetailsValues>(
    patientToValues(Optional.empty(), Optional.empty()),
  );
  const [pDetails, setPDetails] = useState(Optional.empty<ProviderDetails>());
  const [providerNotKnown, setProviderNotKnown] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [patientIsCurrentUser, setPatientIsCurrentUser] = useState(false);
  const [
    existingPersonForIndependentPatient,
    setExistingPersonForIndependentPatient,
  ] = useState<Optional<ResponsiblePerson>>(Optional.empty());

  const initialize = (patient: Optional<Patient>, u: Optional<User>) => {
    const userPersonId = u
      .cast<Guardian>(us => us.userType === UserType.Guardian)
      .map(g => g.responsiblePersonDetails)
      .map(r => r.id)
      .orElse(0 as NonProfessionalId);

    const userRelationship = patient
      .map(p => p.relationships)
      .orElse([])
      .find(rel => {
        return rel.personId === userPersonId;
      });

    const selfRel = patient.map(p => p.selfRelationship).orNull();

    if (!isNothing(selfRel)) {
      setPatientIsCurrentUser(selfRel.personId === userPersonId);
    }

    setPatientDetails(patientToValues(patient, Optional.of(userRelationship)));
    setPDetails(patient.flatMap(p => p.pcp));
    setDataReady(true);
  };

  const resetState = useCallback(() => {
    initialize(patientToEdit.getOptional(), user.getOptional());
    return true;
  }, [patientToEdit, user]);

  useEffect(() => {
    if (
      user.getOptional().isPresent() &&
      patientToEdit.is(RemoteDataStatus.NotAsked)
    ) {
      setPatientToEdit();

      const patientApi = api.patient(patientId);

      Promise.all([
        patientApi.get().then(p => {
          setPatientToEdit(p);
          return Optional.of(p);
        }),
      ]).then(([p]) => initialize(p, user.getOptional()));
    }
  }, [api, patientToEdit, patientId, setPatientToEdit, resetState, user]);

  /*
   * PATIENT INFO END
   */

  const getPlan = useCallback(() => {
    return [
      'PatientDetails',
      'ProviderDetails',
      'Confirm',
    ] as EditPatientStep[];
  }, []);

  const moveGuard = useRef<(dir: 'back' | 'next') => Promise<boolean>>(() =>
    Promise.resolve(true),
  );

  const stepProperties = {
    patientId: patientToEdit.getOptional().property('id', 0),
    patientDetails,
    setPatientDetails,
    details: pDetails,
    setDetails: setPDetails,
    providerNotKnown,
    setProviderNotKnown,
    existingPersonForIndependentPatient,
    setExistingPersonForIndependentPatient,
    patientIsCurrentUser,
  };

  const savePatient = async () => {
    const {
      guardianRelationship,
      authorized,
      ...updatedPatient
    } = patientDetails;
    if (userType === UserType.Guardian) {
      const guardian = user
        .getOptional()
        .cast<Guardian>(() => true)
        .orElseThrow(() => new Error('Unexpected error updating Patient'));
      await api
        .responsiblePerson(
          guardian.responsiblePersonDetails
            .map(rp => rp.id)
            .orElse(0 as NonProfessionalId),
        )
        .updateRelationshipName(patientId, patientDetails.guardianRelationship);

      if (patientIsCurrentUser) {
        api.getUser().then(updateUser);
      }
    }
    // Right now, there's nothing we can do with any email addresses
    const {email, ...pt} = updatedPatient;
    await api.patient(patientId).update({
      ...pt,
      details: convertDetailsToRequest(pDetails),
    });
  };

  const getForwardNavigationButtons = ({
    isLastStep,
    proceedToNextStep,
    saving,
  }: {
    currentStepName: string;
    isLastStep: boolean;
    proceedToNextStep: () => unknown;
    saving: boolean;
  }) => {
    return [
      <Button disabled={saving} bStyle="primary" onClick={proceedToNextStep}>
        {isLastStep ? 'Confirm' : 'Next'}
        {saving && (
          <Icon
            className={clsx(classes.spinner, 'fas fa-spinner fa-spin')}
          ></Icon>
        )}
      </Button>,
    ];
  };

  return (
    <FlowPage
      dataReady={dataReady}
      getPlan={getPlan}
      moveGuard={moveGuard}
      resetState={resetState}
      StepComponents={StepComponents}
      StepNames={StepNames}
      stepProperties={stepProperties}
      onCancel={() => history.goBack()}
      onPerformTask={savePatient}
      onTaskPerformed={async () => {
        if (userType === UserType.Guardian) {
          history.push(`/dashboard`);
        } else {
          history.push(`/patient/${patientId}`);
        }
        return true;
      }}
      getForwardNavigationButtons={getForwardNavigationButtons}
      title="Edit Patient"
    />
  );
}
