import {createContext, useContext} from 'react';

/**
 *
 * This is as generic function that wraps our context and takes care of
 *  checking if the value passed is undefined.
 */
export const createGenericContext = <T extends unknown>() => {
  const genericContext = createContext<T | undefined>(undefined);

  // If the value provided to the context is not defined or throw an error
  const useGenericContext = () => {
    const contextIsDefined = useContext(genericContext);
    if (!contextIsDefined) {
      throw new Error('useGenericContext must be used within a Provider');
    }
    return contextIsDefined;
  };

  return [useGenericContext, genericContext.Provider] as const;
};
