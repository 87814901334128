import React, {useCallback} from 'react';
import {Control, Controller, DeepMap, FieldError} from 'react-hook-form';
import {CreateProvider} from '.';
import {TextInput} from '@src/components/ui/form';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';
import {MuiCheckBoxInput} from '@src/components/ui/form/MuiCheckBoxInput';
import {NAMES} from '@src/components/ui/layout/text/names';

type SharedForm = {
  control: Control<CreateProvider>;
  errors: DeepMap<CreateProvider, FieldError>;
  setSeesPatientsValue: (v: boolean) => unknown;
  resetSeesPatientsForm: () => void;
};

// eslint-disable-next-line import/no-unused-modules
export default function SharedForm({
  control,
  errors,
  setSeesPatientsValue,
  resetSeesPatientsForm,
}: SharedForm) {
  const onSeesPatientsChange = useCallback(
    v => {
      setSeesPatientsValue(v);
      resetSeesPatientsForm();
    },
    [resetSeesPatientsForm, setSeesPatientsValue],
  );

  return (
    <div>
      <Controller
        name="firstName"
        control={control}
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            value={value}
            name={name}
            required
            title="First Name"
            onChange={onChange}
            onBlur={onBlur}
            error={errors.firstName?.message}
            touched={isTouched}
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            value={value}
            name={name}
            required
            title="Last Name"
            onChange={onChange}
            onBlur={onBlur}
            error={errors.lastName?.message}
            touched={isTouched}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            value={value}
            name={name}
            required
            title="Email"
            onChange={onChange}
            onBlur={onBlur}
            error={errors.email?.message}
            touched={isTouched}
          />
        )}
      />

      <Controller
        name="confirmEmail"
        control={control}
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            value={value}
            name={name}
            required
            title="Retype Email"
            onChange={onChange}
            onBlur={onBlur}
            error={errors.confirmEmail?.message}
            touched={isTouched}
          />
        )}
      />

      <Controller
        control={control}
        name="seesPatients"
        render={({value, name}) => (
          <MuiCheckBoxInput
            value={value}
            name={name}
            title={`${toTitleCase(
              NAMES.provider,
            )} is certified to treat patients`}
            onChange={onSeesPatientsChange}
            required
            error={errors.seesPatients?.message}
          />
        )}
      />
    </div>
  );
}
