import {captureException} from '@sentry/browser';
import * as React from 'react';
import {useHistory} from 'react-router';
import styled from 'styled-components';
import {primary} from '@src/components/ui/theme';

const StyledNotFoundPage = styled.div`
  padding: 30px 8px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center div {
    margin: 4px 0;
  }
`;

const LinkToHome = styled.span`
  cursor: pointer;
  color: ${primary};
  &:hover {
    text-decoration: underline;
  }
`;

export function NotFoundPage() {
  const history = useHistory();
  return (
    <StyledNotFoundPage>
      <div>
        The page that you&apos;re looking for ({window.location.pathname}) could
        not be found
      </div>
      <div>
        Our engineering teams have been notified and will look into this as soon
        as possible.
      </div>
      <div>
        In the meantime,{' '}
        <LinkToHome
          role="link"
          tabIndex={0}
          onClick={() => {
            captureException(
              new Error(`Page '${window.location.pathname}' not found`),
            );
            history.push('/');
          }}
        >
          click here
        </LinkToHome>{' '}
        to return to the application.
      </div>
    </StyledNotFoundPage>
  );
}
