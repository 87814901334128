import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, useHistory, withRouter} from 'react-router';
import {isSupported} from 'twilio-video';
import {ReduxState} from '../../../../store';
import {
  AudioInputInfo,
  AudioOutputInfo,
  VideoInputInfo,
} from '../../../../store/reducers/media';
import {Button} from '../../../ui/form';
import {AnonymousLanding} from '../pages/AnonymousLanding';
import {ParagraphText} from '../../../ui/layout/text/body/ParagraphText';
import {getLinkHref, Routes} from '../../general/AppRoutes';
import {SessionConfigForm} from './SessionConfigForm';
import {ModalActions} from './functions';
import {useReturnPath, useUser} from '@src/hooks';
import {getAnonymousVisitorCookie} from '@src/util/videoChat';
import {Modal} from '@src/components/ui/layout/Modal';

type StateProps = {
  selectedAudioOutput: Optional<AudioOutputInfo>;
  selectedAudioInput: Optional<AudioInputInfo>;
  selectedVideoInput: Optional<VideoInputInfo>;
};
const mapStateToProps = (state: ReduxState): StateProps => ({
  ...state.media,
});

type OwnProps = RouteComponentProps<{visitId: string}>;

type Props = StateProps & OwnProps;

/**
 *
 * Entrance to ExamRoom or AnonymousExamRoom should follow this path:
 * 1) AnonymousLandingPage (if necessary)
 * 2) CheckHairRoom
 * 3) VideoChat
 * 4) ExamRoom/AnonymousExamRoom
 */

const CheckHairRoom = ({
  match,
  selectedAudioOutput,
  selectedAudioInput,
  selectedVideoInput,
}: Props) => {
  const history = useHistory();
  const [, userType, , , isAnonymous] = useUser();

  const [anonymousInfo, setAnonymousInfo] = useState(
    getAnonymousVisitorCookie(),
  );

  const [devicesReady, setDevicesReady] = useState(false);

  const visitId = +match.params.visitId;

  const goBack = useReturnPath();

  const enterExamRoom = useCallback(() => {
    history.push(
      `${getLinkHref(Routes.ExamRoom, {visitId})}${window.location.search}`,
    );
  }, [history, visitId]);

  if (isAnonymous && !anonymousInfo) {
    return <AnonymousLanding setAnonymousInfo={setAnonymousInfo} />;
  }

  return (
    <Grid container direction="row">
      <div className="waiting-room--actions">
        <Button onClick={goBack} bSize="small">
          Go back
        </Button>
      </div>

      <Modal
        show={isSupported}
        fullScreen
        title="Let's get ready for your visit!"
        onClose={goBack}
        modalActions={
          <ModalActions
            goBack={goBack}
            devicesReady={devicesReady}
            enterExamRoom={enterExamRoom}
          />
        }
      >
        <>
          {userType === 'Guardian' && (
            <ParagraphText style={{maxWidth: '75%'}}>
              Our team has been notified that you are here. Let&apos;s make sure
              you&apos;re ready to talk with them.
            </ParagraphText>
          )}
          {userType !== 'Guardian' && (
            <ParagraphText style={{maxWidth: '75%'}}>
              You&apos;re nearly ready to enter the exam room.
            </ParagraphText>
          )}
          <ParagraphText style={{maxWidth: '75%'}}>
            First, let&apos;s make sure your camera, speakers, and microphone
            are set up correctly.
          </ParagraphText>

          <SessionConfigForm
            cancelText="Go Back"
            onSave={() => {}}
            selectedAudioOutput={selectedAudioOutput}
            selectedAudioInput={selectedAudioInput}
            selectedVideoInput={selectedVideoInput}
            setDevicesReady={setDevicesReady}
          />
        </>
      </Modal>
    </Grid>
  );
};

export default withRouter(connect(mapStateToProps)(CheckHairRoom));
