import React, {useEffect, useRef, useState} from 'react';

/**
 * Makes any loading indicator passed to `loadingIndicator` stop rendering on a delay to avoid abrupt
 * UI changes.
 */
export function MakeIndeterminate({
  active,
  children,
  customDelayMs,
  loadingIndicator,
}: {
  active: boolean;
  children?: React.ReactNode;
  customDelayMs?: number;
  loadingIndicator: React.ReactNode;
}) {
  const [delayedActive, setDelayedActive] = useState(true);
  const timeoutId = useRef<number>();

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    if (active) {
      setDelayedActive(active);
    } else {
      timeoutId.current = window.setTimeout(() => {
        setDelayedActive(active);
      }, customDelayMs ?? 500);
    }
  }, [active, customDelayMs]);

  return delayedActive ? loadingIndicator : <>{children}</>;
}
