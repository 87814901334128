import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  contentCell: {
    color: theme.palette.secondary.contrastText,
    padding: '1rem 0 1rem 1rem',
    fontSize: 16,
    fontWeight: 300,
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  labelCell: {
    fontSize: 16,
    fontWeight: 700,
    paddingLeft: 0,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    color: theme.palette.secondary.contrastText,
    padding: '1rem 0',
  },
  lastRow: {
    border: 'none',
    '& td': {
      border: 'none',
    },
  },
}));
