import styled from 'styled-components';
import {md, primary, primaryDark, sm, xxs} from '@src/components/ui/theme';

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    margin: ${xxs} 0;
  }
`;

export const ProviderInfoFormStyled = styled.div`
  margin-bottom: ${md};
  min-width: 75%;
`;

export const Instructions = styled.div`
  margin-bottom: ${md};
`;

export const SearchOrEnter = styled.div`
  text-align: left;
  cursor: pointer;
  padding-right: ${md};
  margin-bottom: ${sm};
  color: ${primary};

  &:hover {
    color: ${primaryDark};
  }
`;

export const SearchablePcpDisplay = styled.div`
  margin-bottom: ${md};
`;

export const NoPcpMessage = styled.div`
  margin: ${md} 0;
`;

export const WarningContent = styled.div`
  margin: ${md} auto;
  max-width: 40vw;
`;

export const ProviderChecker = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
