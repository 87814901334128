import {useCallback, useMemo, useState} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {
  useAddableSectionResources,
  AddableSectionResources,
} from './useAddableSectionResources';
import {useOnRemoveGroupClientConfiguration} from './useRemoveClientConfiguration';
import {ClientConfiguration} from '@src/models';
import {useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';

export type EditClientConfigurationsModal = {
  editClientConfigurationsForGroupId: number | null;
  setEditClientConfigurationsForGroupId: (callPoolId: number | null) => unknown;
  hideClientConfigurationsModal: () => unknown;
  groupClientConfigurations: AsyncData<ClientConfiguration>;
  addableSectionResources: AddableSectionResources;
  onRemoveClientConfiguration: () => Promise<void>;
  setClientConfigurationToRemoveId: (v: number | null) => unknown;
  clientConfigurationToRemoveId: number | null;
};

export function useEditClientConfigurationsModal(
  reloadCallPools: () => Promise<unknown>,
): EditClientConfigurationsModal {
  const api = useApi();

  const [
    editClientConfigurationsForGroupId,
    setEditClientConfigurationsForGroupId,
  ] = useState<number | null>(null);

  const [
    clientConfigurationToRemoveId,
    setClientConfigurationToRemoveId,
  ] = useState<number | null>(null);

  const [
    groupClientConfigurations,
    reloadGroupClientConfigurations,
    ,
    resetResources,
  ] = useResources(
    () =>
      api
        .clientConfigurations()
        .getForCallPool(editClientConfigurationsForGroupId ?? 0),
    [api, editClientConfigurationsForGroupId],
    {
      requestGate: () => {
        return editClientConfigurationsForGroupId !== null;
      },
    },
  );

  const addableSectionResources = useAddableSectionResources({
    editClientConfigurationsForGroupId,
    reloadGroupClientConfigurations,
  });

  const onRemoveClientConfiguration = useOnRemoveGroupClientConfiguration({
    runBeforeRequest: useCallback(() => {}, []),
    asyncRunAfterRequest: useCallback(async () => {
      await reloadGroupClientConfigurations();
      await reloadCallPools();
      flashSuccess('Success');
    }, [reloadCallPools, reloadGroupClientConfigurations]),
    runFinally: useCallback(() => {}, []),
    runOnError: useCallback(
      () =>
        flashError(
          'Something went wrong removing the Endpoint from this Group',
        ),
      [],
    ),
    groupId: editClientConfigurationsForGroupId,
    clientConfigurationId: clientConfigurationToRemoveId,
  });

  const hideClientConfigurationsModal = useCallback(() => {
    resetResources();
    setEditClientConfigurationsForGroupId(null);
  }, [resetResources]);

  return useMemo(
    () => ({
      addableSectionResources,
      editClientConfigurationsForGroupId,
      setEditClientConfigurationsForGroupId,
      groupClientConfigurations,
      hideClientConfigurationsModal,
      setClientConfigurationToRemoveId,
      onRemoveClientConfiguration,
      clientConfigurationToRemoveId,
    }),
    [
      addableSectionResources,
      editClientConfigurationsForGroupId,
      setEditClientConfigurationsForGroupId,
      groupClientConfigurations,
      hideClientConfigurationsModal,
      setClientConfigurationToRemoveId,
      onRemoveClientConfiguration,
      clientConfigurationToRemoveId,
    ],
  );
}
