import {format} from 'date-fns';
import {LonelyVisit} from '@src/models';
import {providerAndQualification} from '@src/util/provider';

export function getDate(start: Date) {
  return format(start, 'LLLL d, yyyy');
}

export function getStartTime(start: Date) {
  return format(start, 'h:mm aaaa');
}

export function getVisitProviderDescription(visit: LonelyVisit) {
  const providerDetails = visit.providerDetails.orNull();

  return providerDetails
    ? providerAndQualification(providerDetails)
    : 'Unknown';
}
