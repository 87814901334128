import {format} from 'date-fns';
import React, {useState} from 'react';
import {Button, Grid} from '@material-ui/core';
import {CallPoolsSection, TimeSection} from '../ModalSections';
import {PeriodToEdit, getNewPeriod, Errors} from '../functions';
import {OnCallPeriodPurpose} from '..';
import {useProviderGroups} from '../hooks/useProviderGroups';
import {onSave, SinglePeriodValues} from './functions';
import {CoverageInstructions} from './CoverageInstructions';
import {Modal} from '@src/components/ui/layout/Modal';
import {useApi} from '@src/api/useApi';
import {CallPool, OnCallPeriod} from '@src/models';
import {UserId} from '@src/models/User';
import {AsyncActionButton} from '@src/components/ui/form';
import {ErrorMessage} from '@src/components/ui/form/ErrorMessage';
import {flashError} from '@src/components/shared/notifications/flash';

type Props = {
  onCreateOnCallPeriods: (period: OnCallPeriod[]) => unknown;
  preselectedProviderGroups?: CallPool[];
  availableGroups?: CallPool[];
  purpose: OnCallPeriodPurpose;
  period?: PeriodToEdit;
  providerId?: UserId;
  show: boolean;
  forDate?: Date;
  requiredGroupsSelections?: CallPool[];
  onClose: () => unknown;
};

export function SinglePeriodModal({
  availableGroups,
  forDate = new Date(),
  period = getNewPeriod(forDate),
  preselectedProviderGroups = [],
  providerId = 0 as UserId,
  purpose,
  onCreateOnCallPeriods,
  onClose,
  requiredGroupsSelections = [],
  show,
}: Props) {
  const api = useApi();
  const [errors, setErrors] = useState<Errors<SinglePeriodValues>>({});
  const [selectedProviderGroups, setSelectedProviderGroups] = useState<
    number[]
  >(preselectedProviderGroups.map(g => g.id));

  const [errorMessage, setErrorMessage] = useState('');
  const [saving, setSaving] = useState(false);

  const [startDate, setStartDate] = useState(period.startDate);
  const [startTime, setStartTime] = useState<Date | null>(period.startTime);
  const [hours, setHours] = useState(period.hours);
  const [minutes, setMinutes] = useState(period.minutes);

  const providerGroups = useProviderGroups(availableGroups, providerId);

  const editMode = period.id > 0;

  const modalTitle =
    purpose === 'Coverage'
      ? 'Which hours can you cover?'
      : 'Create On Demand Shift';

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={modalTitle}
      modalActions={
        <>
          <AsyncActionButton
            actionInProgress={saving}
            actionWord={editMode ? 'Save Changes' : 'Save'}
            disabled={selectedProviderGroups.length === 0}
            onClick={() => {
              setSaving(true);
              onSave(api)(
                {
                  periodId: period.id,
                  providerId,
                  startDate,
                  startTime: startTime ? format(startTime, 'HH:mm') : '',
                  hours,
                  minutes,
                  selectedCallPools: selectedProviderGroups,
                },
                {
                  setErrors,
                  setErrorMessage,
                },
              )
                .then(p => {
                  onCreateOnCallPeriods([p]);
                  onClose();
                })
                .catch(e => {
                  console.error(e);
                  const isServerError = e;

                  if (!isServerError) {
                    return;
                  } else {
                    flashError(
                      'Something went wrong trying to create this On-Call Period. Please contact support.',
                    );
                  }
                })
                .finally(() => {
                  setSaving(false);
                });
            }}
          />
          {editMode && <Button onClick={onClose}>Cancel</Button>}
        </>
      }
    >
      <Grid container direction="row">
        {purpose === 'Coverage' && <CoverageInstructions editMode={editMode} />}

        <ErrorMessage message={errorMessage} />

        <TimeSection
          errors={errors}
          durationProps={{setHours, hours, setMinutes, minutes}}
          startProps={{setStartDate, setStartTime, startDate, startTime}}
        />
        <CallPoolsSection
          frequency="Once"
          providerGroups={providerGroups.getAllOptional().orElse([])}
          purpose={purpose}
          setSelectedProviderGroups={setSelectedProviderGroups}
          selectedProviderGroups={selectedProviderGroups}
          requiredGroupsSelections={requiredGroupsSelections.map(g => g.id)}
        />
      </Grid>
    </Modal>
  );
}
