import {makeStyles} from '@material-ui/core/styles';
import {md, lg} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  insuranceCardContainer: {
    margin: `${md} 0`,
  },
  selectionsContainer: {
    margin: `${lg} 0`,
  },
  resetContainer: {
    padding: md,
  },
  resultInsuranceCardContainer: {
    margin: `0 ${md}`,
  },
}));
