import React from 'react';
import {Grid} from '@material-ui/core';
import {usePageContext} from '..';
import {Current} from '@src/components/separateAdminApp/ListCallPools/editEndpoints/Current';
import {Available} from '@src/components/separateAdminApp/ListCallPools/editEndpoints/Available';
import {useStyles} from '@src/components/providerSide/providerGroups/ProviderGroupRosterPage/MonthTable/Day/Shifts/styles';
import {Modal} from '@src/components/ui/layout/Modal';
import {Button} from '@src/components/ui/form';
import {LoadingIndeterminate} from '@src/components/ui/atoms/progressBarsAndIndicators/LoadingIndeterminate';
import {HorizontalSection} from '@src/components/ui/atoms/section/HorizontalSection';
import {allLoaded} from '@src/util/apiHelpers';

export function EndpointsModal() {
  const {showEditEndpoints, hideEndpointsModal, callPools} = usePageContext();

  const callPool = callPools.find(cp => cp.id === showEditEndpoints)!;

  const loading = !allLoaded(callPools);

  const classes = useStyles({loading});

  return (
    <Modal
      show={showEditEndpoints !== null}
      title={'Edit Endpoints'}
      onClose={hideEndpointsModal}
      modalActions={
        <Button bStyle="outlined" onClick={hideEndpointsModal}>
          Cancel
        </Button>
      }
    >
      <Grid className={classes.modalListContainer}>
        <LoadingIndeterminate active={loading}>
          <HorizontalSection>
            <Current callPool={callPool} />
          </HorizontalSection>
          <HorizontalSection lastSection>
            <Available callPool={callPool} />
          </HorizontalSection>
        </LoadingIndeterminate>
      </Grid>
    </Modal>
  );
}
