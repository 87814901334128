import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';
import {useStyles} from '../layout';
import {CurrentVisits} from './CurrentVisits';
import {SimpleVisit} from '@src/models';

type Props = {
  currentVisits: AsyncData<SimpleVisit>;
  readyForRequests: boolean;
  patientId: number;
};

export function VisitsList({
  currentVisits,
  readyForRequests,
  patientId,
}: Props) {
  const classes = useStyles();

  return (
    <Grid item container direction="row">
      <Typography variant="h2" className={classes.rightSectionTitle}>
        Visits
      </Typography>

      {!readyForRequests && (
        <TableContainer style={{minHeight: '100px'}}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>Checking for visits...</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {readyForRequests && (
        <CurrentVisits visits={currentVisits.value()} patientId={patientId} />
      )}
    </Grid>
  );
}
