import {addMonths, format} from 'date-fns';
import React from 'react';
import {Grid} from '@material-ui/core';
import {useStyles} from '../ProviderGroupOverviewPage/layout';
import {usePageContext} from '.';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';

export function MonthNavigator() {
  const classes = useStyles();
  const {rosterMonth, setRosterMonth} = usePageContext();

  return (
    <Grid container direction="row" className={classes.monthNavigation}>
      <Grid item xs={1}>
        <ClickableIcon
          icon="angle-left"
          size="2x"
          fixedWidth
          onClick={() => {
            setRosterMonth(addMonths(rosterMonth, -1));
          }}
        />
      </Grid>
      <Grid item xs className={classes.monthNavigationLabel}>
        {format(rosterMonth, 'MMM yyyy')}
      </Grid>
      <Grid item xs={1}>
        <ClickableIcon
          icon="angle-right"
          size="2x"
          fixedWidth
          onClick={() => setRosterMonth(addMonths(rosterMonth, 1))}
        />
      </Grid>
    </Grid>
  );
}
