import React from 'react';
import {TableCell, Typography} from '@material-ui/core';
import {capitalize} from 'lodash';
import {useStyles} from '../../shared/Tables/styles';
import {MedicalHistoryResult} from './utils/resultFunctions';
import {MedicalHistoryUIElement} from './utils/getMedicalHistoryRow';

type Props = {
  result: MedicalHistoryResult;
  row: MedicalHistoryUIElement;
};

export function ResultCell({result, row}: Props) {
  const classes = useStyles();
  const text = result[row.name].text;

  const mapImmunizationOption = (option: string) => {
    if (option === 'yes' || option === 'no') {
      return capitalize(option);
    }

    if (option === 'not_sure') {
      return 'Unknown';
    }

    return option;
  };

  return (
    <TableCell className={classes.tableCell}>
      <Typography color="primary" variant="body1">
        {row.name === 'immunizations_up_to_date'
          ? mapImmunizationOption(text)
          : text}
      </Typography>
    </TableCell>
  );
}
