import {TextField} from '@material-ui/core';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {Autocomplete} from '@material-ui/lab';
import React, {useEffect, useState} from 'react';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {CallPool} from '@src/models';
import {isSomething} from '@src/util/typeTests';
import {by} from '@src/util/arrayManipulation/by';

type Props = {
  selectedIds: number[];
  onUpdateSelection: (selection: number[]) => unknown;
  callPools: AsyncData<CallPool>;
};

export function CallPoolsMultiselect({
  callPools,
  onUpdateSelection,
  selectedIds,
}: Props) {
  const [selection, setSelection] = useState<CallPool[]>([]);

  useEffect(() => {
    if (!callPools.isLoaded()) {
      return;
    }

    setSelection(
      selectedIds
        .map(id => callPools.find(c => c.id === id))
        .filter(isSomething),
    );
  }, [callPools, selectedIds, setSelection]);

  return (
    <PageLoading active={!callPools.isLoaded()} message="Loading">
      <Autocomplete
        multiple
        options={[...callPools.getAllOptional().orElse([])].sort(
          by('nameForProviders'),
        )}
        getOptionLabel={option => option.nameForProviders}
        value={selection}
        onChange={(__, value) => {
          onUpdateSelection(value.map(c => c.id));
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label=""
            placeholder="Add Provider Groups..."
          />
        )}
      />
    </PageLoading>
  );
}
