import React, {useState, useEffect} from 'react';
import {
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/styles';
import {PendingChangeRow} from '@src/components/shared/PatientDashboard/SCP/panels/SCPChangeLog/PendingChangeRow';
import {ChangeRow} from '@src/components/shared/PatientDashboard/SCP/panels/SCPChangeLog/ChangeRow';
import {SCPChangeRequest, SCPChangeRequestStatus} from '@src/models';
import {Button} from '@src/components/ui/form/Button/index';
import {useStyles as useChangeLogStyles} from '@src/components/shared/PatientDashboard/SCP/panels/SCPChangeLog/styles';
import {SCPHeading} from '@src/components/shared/PatientDashboard/SCP/SCPHeading';

const PAGE_SIZE = 10;

type Props = {
  noTitle?: boolean;
  isMyPendingChangesModal?: boolean;
};

export const SCPChangeLog = ({
  isMyPendingChangesModal = false,
  noTitle,
}: Props) => {
  const classes = useStyles();
  const changeLogClasses = useChangeLogStyles();

  const {changeRequests, selectedPanel} = usePageContext();

  const aChangeRequests = changeRequests.getAllOptional().orElse([]);

  const [endIndex, setEndIndex] = useState(PAGE_SIZE);
  const [displayedChangeRequests, setDisplayedChangeRequests] = useState<
    readonly SCPChangeRequest[]
  >(aChangeRequests);

  const [infoTooltipOpen, setInfoTooltipOpen] = useState(false);

  useEffect(() => {
    if (aChangeRequests.length) {
      setDisplayedChangeRequests(aChangeRequests.slice(0, endIndex + 1));
    }
  }, [endIndex, aChangeRequests]);

  if (selectedPanel !== 'changeLog') return null;

  return (
    <Grid className={classes.rowContainer} container direction="row">
      <Grid container direction="row">
        <Grid item>{!noTitle && <SCPHeading>Edit History</SCPHeading>}</Grid>
      </Grid>

      <TableContainer style={{marginBottom: '2rem'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="left">Editor</TableCell>
              <TableCell align="left">Field</TableCell>
              <TableCell align="left">
                Change
                <Tooltip
                  title="Pending changes (bold/yellow) require approval"
                  onClose={() => setInfoTooltipOpen(false)}
                  open={infoTooltipOpen}
                >
                  <IconButton
                    onClick={() => setInfoTooltipOpen(true)}
                    className={changeLogClasses.iconButton}
                    style={{padding: '8px'}}
                  >
                    <HelpIcon className={changeLogClasses.icon} />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedChangeRequests.map(changeRequest => {
              if (changeRequest.status === SCPChangeRequestStatus.Approved) {
                return <ChangeRow changeRequest={changeRequest} />;
              } else if (
                changeRequest.status === SCPChangeRequestStatus.Pending
              ) {
                return (
                  <PendingChangeRow
                    isMyPendingChangesModal={isMyPendingChangesModal}
                    key={changeRequest.id}
                    changeRequest={changeRequest}
                  />
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {aChangeRequests.length === endIndex ||
      !aChangeRequests.length ||
      aChangeRequests.length < 10 ? null : (
        <Button
          onClick={() =>
            setEndIndex(
              endIndex + Math.min(PAGE_SIZE, aChangeRequests.length - endIndex),
            )
          }
        >
          Load More
        </Button>
      )}
    </Grid>
  );
};
