import React from 'react';
import {RouteComponentProps, useHistory} from 'react-router';
import Form from './Form';
import {PrivatePage} from '@src/components/PrivatePage';

// eslint-disable-next-line import/no-unused-modules
export default function AssignedFormPage({
  match,
}: RouteComponentProps<{formId: string}>) {
  const formId = Number(match.params.formId);
  const history = useHistory();

  return (
    <PrivatePage>
      <Form
        formId={formId}
        onSubmit={success => {
          if (success) {
            history.goBack();
          }
        }}
      />
    </PrivatePage>
  );
}
