import styled from 'styled-components';
import {makeStyles, createStyles} from '@material-ui/core';
import {sm} from '@src/components/ui/theme';

export const CenteredText = styled.div`
  text-align: center;
`;

export const useStyles = makeStyles(() =>
  createStyles({
    modalRow: {
      margin: `${sm} 0`,
      width: '100%',
    },
    modalInstructions: {
      fontSize: '1.25em',
    },
  }),
);
