import React from 'react';
import {Controller} from 'react-hook-form';
import {usePageContext} from '..';
import {useStyles} from '@src/components/separateAdminApp/CreateProviderGroupPage/styles';
import {TextInput} from '@src/components/ui/form';

export function DetailsSection() {
  const classes = useStyles();
  const {
    formResources: {control, errors},
  } = usePageContext();

  return (
    <>
      <Controller
        name="name"
        control={control}
        render={({onChange, onBlur, value, name}) => (
          <TextInput
            value={value}
            name={name}
            required
            placeholder="Name of the form"
            title="Form Name"
            onChange={onChange}
            onBlur={onBlur}
            error={errors.name?.message}
            className={classes.inputField}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({onChange, onBlur, value, name}) => (
          <TextInput
            value={value}
            name={name}
            required
            placeholder="Short description of the form"
            title="Description"
            onChange={onChange}
            onBlur={onBlur}
            error={errors.description?.message}
            className={classes.inputField}
          />
        )}
      />
    </>
  );
}
