import {Optional} from '@ahanapediatrics/ahana-fp';
import React from 'react';
import {SearchOrEnter} from '../layout';
import {handleNoProviderSelected} from '../functions';
import {Context, FormMode} from '../../../../util/provider/forms/addOrEdit';
import {UserType, ProviderDetails} from '@src/models';

type Props = {
  onChange: (pcpInfo: Optional<ProviderDetails>, type: FormMode) => void;
  setEntryMode: (v: 'search' | 'type') => void;
  setShowDetails: (v: boolean) => void;
  setNoProviderSelected: (v: boolean) => void;
  selectionOrEditContext: Context;
  hideManualOption?: boolean;
  setNoSCPOwner?: () => void;
  userType: UserType;
};

export function IntentionallyAbsentOption({
  onChange,
  setEntryMode,
  setShowDetails,
  setNoProviderSelected,
  hideManualOption = false,
  setNoSCPOwner,
  selectionOrEditContext,
  userType,
}: Props) {
  let optionText =
    "I am not sure which doctor to select, or I don't have their information";

  if (selectionOrEditContext === Context.PrimaryCareProvider) {
    optionText =
      "This patient doesn't have a primary care doctor, or I don't know who they are";
  }

  if (
    !hideManualOption &&
    userType === UserType.Guardian &&
    selectionOrEditContext === Context.SharedCarePlanOwner
  ) {
    optionText = "I don't know who this care plan's owner should be";
  }

  return (
    <SearchOrEnter
      onClick={() => {
        handleNoProviderSelected({
          setShowDetails,
          setEntryMode,
          onChange,
          setNoProviderSelected,
          setNoSCPOwner: setNoSCPOwner,
        });
      }}
    >
      {optionText}
    </SearchOrEnter>
  );
}
