import {Grid} from '@material-ui/core';
import React, {useState} from 'react';
import {usePageContext} from '..';
import {SCPStatus, UserType} from '@src/models';
import {Button} from '@src/components/ui/form/Button';
import {Alert} from '@src/components/ui/layout/Alert';
import {Banner} from '@src/components/ui/layout/Banner';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/styles';
import {messages} from '@src/components/shared/PatientDashboard/SCP/NotificationBanner/messages';
import {ReactivateModal} from '@src/components/shared/PatientDashboard/SCP/NotificationBanner/ReactivateModal';
import {RetractModal} from '@src/components/shared/PatientDashboard/SCP/NotificationBanner/RetractModal';
import {useUser} from '@src/hooks';
import {allLoaded} from '@src/util/apiHelpers';

export const NotificationBanner = () => {
  const classes = useStyles();
  const [showRetractModal, setShowRetractModal] = useState(false);
  const [showReactivateModal, setShowReactivateModal] = useState(false);

  const [user, userType] = useUser();

  const {scp, patient, setScp} = usePageContext();

  if (!allLoaded(scp, user)) {
    return (
      <PageLoading active={true} message={'Loading information...'}>
        <Alert aStyle="info">
          <div>Loading...</div>
        </Alert>
      </PageLoading>
    );
  }

  const u = user.singleValue();
  const m = scp.singleValue();

  const message = messages[userType][m.status] ?? (() => 'No Message');

  const isOwner = m.providerDetails
    .map(pd => pd.providerId)
    .map(i => u.id === i && u.userType === UserType.Professional)
    .orElse(false);

  const patientName = patient
    .getOptional()
    .map(p => p.preferredFirstName)
    .orElse('this patient');

  return (
    <Grid
      container
      direction="row"
      className={classes.rowContainer}
      style={{
        marginTop: m.status === SCPStatus.Retracted ? '2rem' : 0,
        marginBottom: 0,
      }}
    >
      <Banner
        type={
          [
            SCPStatus.Draft,
            SCPStatus.Retracted,
            SCPStatus.OwnershipExpired,
          ].includes(m.status)
            ? 'warning'
            : 'info'
        }
      >
        <div>{message(isOwner)}</div>

        {m.status === SCPStatus.Retracted && isOwner && (
          <>
            <div>
              <Button
                bStyle="warning"
                bSize="small"
                onClick={() => {
                  setShowReactivateModal(true);
                }}
                style={{marginTop: '1rem'}}
              >
                Start Reactivation
              </Button>
            </div>
            <ReactivateModal
              scp={m}
              patientName={patientName}
              setScp={setScp}
              setShowModal={setShowReactivateModal}
              show={showReactivateModal}
            />
          </>
        )}

        {u.userType === UserType.Professional &&
          m.status !== SCPStatus.Retracted &&
          m.status !== SCPStatus.Draft && (
            <div>
              <Button
                bStyle="primary"
                bSize="small"
                onClick={() => {
                  setShowRetractModal(true);
                }}
                style={{marginTop: '1rem'}}
              >
                Retract
              </Button>
              <RetractModal
                scp={m}
                patientName={patientName}
                setScp={setScp}
                setShowModal={setShowRetractModal}
                show={showRetractModal}
              />
            </div>
          )}
      </Banner>
    </Grid>
  );
};
