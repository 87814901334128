import {makeStyles} from '@material-ui/core/styles';
import {backdrop, xl, md, sm} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  overviewContainer: {
    backgroundColor: `${backdrop}`,
    minWidth: '70vw',
    padding: `${xl} 0`,
  },

  alertGrid: {
    padding: md,
  },
  button: {
    marginRight: sm,
  },
  buttonContainer: {
    marginTop: sm,
  },
}));
