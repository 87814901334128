import React, {useEffect} from 'react';
import {SessionConfigForm} from '../../../shared/VideoChat/CheckHairRoom/SessionConfigForm';
import {StepProps} from '.';

const saveConfiguration = () => {};

export function ConfigStep({
  selectedAudioOutput,
  selectedAudioInput,
  selectedVideoInput,
  setNextAvailable,
}: StepProps) {
  useEffect(() => {
    setNextAvailable(false);
  }, [setNextAvailable]);

  return (
    <SessionConfigForm
      cancelText="Go Back"
      onSave={saveConfiguration}
      selectedAudioOutput={selectedAudioOutput}
      selectedAudioInput={selectedAudioInput}
      selectedVideoInput={selectedVideoInput}
      setDevicesReady={setNextAvailable}
    />
  );
}
