import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {
  LabelContainer,
  LabelContents,
  LabelDescription,
  ButtonContainer,
} from '../SelectorLabels';
import {useStyles} from './layout';
import {Endpoint, UserType} from '@src/models';
import {useUser} from '@src/hooks';
import {Button} from '@src/components/ui/form';

type Props = {
  endpoint: Endpoint;
  selectEndpoint: (id: Endpoint['id']) => unknown;
  selected: boolean;
};

export function EndpointOption({endpoint, selectEndpoint, selected}: Props) {
  const [, userType] = useUser();
  const classes = useStyles();
  return (
    <Grid item onClick={() => selectEndpoint(endpoint.id)} xs={12}>
      <LabelContainer selected={selected} style={{padding: '.5em .5em'}}>
        <LabelContents>
          <Typography variant="h4" className={classes.poolName}>
            {endpoint.name}
          </Typography>

          <LabelDescription>
            {userType === UserType.Guardian
              ? endpoint.nonProfessionalInstructions
              : endpoint.professionalInstructions}{' '}
          </LabelDescription>
        </LabelContents>

        <ButtonContainer>
          <Button
            bStyle="contained"
            onClick={e => {
              e.preventDefault();
              selectEndpoint(endpoint.id);
            }}
          >
            Select this option
          </Button>
        </ButtonContainer>
      </LabelContainer>
    </Grid>
  );
}
