import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import React, {useCallback, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router';
import {onUpdatePatient} from './functions';
import {IconButton} from './IconButton';
import {StyledGuardianDashboard, useStyles} from './layout';
import {NoPatients} from './NoPatients';
import {PatientCard} from './PatientCard';
import {UnapprovedPatientDetails} from './UnapprovedPatientDetails';
import {useApi} from '@src/api/useApi';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {Button} from '@src/components/ui/form';
import {HeroContainer, HeroHead} from '@src/components/ui/layout/Dashboard';
import {mdLg} from '@src/components/ui/theme';
import {useResources, useUser} from '@src/hooks';
import {usePatientUpdateEvents} from '@src/hooks/socket';
import {useCondensedCards} from '@src/hooks/useCondensedCards';
import {Guardian, Invitation, Patient} from '@src/models';
import {UserId} from '@src/models/User';
import {ReduxState} from '@src/store';
import {AppConfig} from '@src/store/reducers/configuration';

type StateProps = {
  configuration: AsyncData<AppConfig>;
};
const mapStateToProps = ({configuration}: ReduxState): StateProps => ({
  configuration,
});

type Props = StateProps;

const GuardianDashboard = ({configuration}: Props) => {
  const api = useApi();
  const history = useHistory();
  const classes = useStyles();
  const [user] = useUser();
  const {
    allExpanded,
    allCollapsed,
    isCollapsed,
    loadPatients,
    collapse,
    expand,
    collapseAll,
    expandAll,
  } = useCondensedCards();

  const guardianId = user
    .getOptional()
    .map(u => u.id)
    .orElse(0 as UserId);

  const [patients, , setPatients] = useResources<Patient>(
    () => api.guardian(guardianId).getPatients(),
    [api, guardianId],
  );
  const [invitations, , setInvitations] = useResources<Invitation>(
    () => api.guardian(guardianId).getInvitations(),
    [api, guardianId],
  );
  const [guardian] = useResources<Guardian>(
    () => api.guardian(guardianId).get(),
    [api, guardianId],
  );

  const patientIds = useMemo(
    () =>
      patients
        .getAllOptional()
        .orElse([])
        .map(p => p.id),
    [patients],
  );

  const invitationIds = useMemo(
    () =>
      invitations
        .getAllOptional()
        .orElse([])
        .map(i => i.id),
    [invitations],
  );

  const handlePatientUpdateEvent = useCallback(
    patient => {
      console.log(`Handling ${patient.id}`);
      onUpdatePatient(api, patients, setPatients)(patient);
    },
    [api, patients, setPatients],
  );

  usePatientUpdateEvents(
    [...patientIds, ...invitationIds],
    handlePatientUpdateEvent,
  );

  useEffect(() => {
    loadPatients(patientIds);
  }, [loadPatients, patientIds]);

  const setAllCards = useCallback(
    (doCollapse: boolean) => {
      if (doCollapse) {
        collapseAll();
      } else {
        expandAll();
      }
    },
    [collapseAll, expandAll],
  );

  const guardianFriendlyName = guardian
    .getOptional()
    .map(g => g.responsiblePersonDetails)
    .property('firstName');

  const onAddPatient = useCallback(() => {
    history.push('/new-patient');
    window.scrollTo(0, 0);
  }, [history]);

  const onHereForAVisit = useCallback(() => {
    history.push('/here-for-a-visit');
    window.scrollTo(0, 0);
  }, [history]);

  const invitationCount = invitations
    .getAllOptional()
    .map(i => i.length)
    .orElse(0);
  const patientCount = patients
    .getAllOptional()
    .map(i => i.length)
    .orElse(0);

  const checkingUserDetails = !user.isLoaded() || !configuration.isLoaded();
  const loadingPatients = !patients.isLoaded();

  const hasNoPatients = patients.isLoaded() && patients.isEmpty();
  const hasPatients = patients.isLoaded() && !patients.isEmpty();
  const hasInvitations = invitations.isLoaded() && !invitations.isEmpty();

  return (
    <StyledGuardianDashboard>
      <PageLoading
        active={checkingUserDetails || loadingPatients}
        message={
          checkingUserDetails
            ? 'Checking user details...'
            : 'Loading patients...'
        }
      >
        <>
          <HeroContainer>
            <HeroHead>Welcome, {guardianFriendlyName}</HeroHead>
          </HeroContainer>
          <Grid container spacing={1} className={classes.dashboardGrid}>
            {hasPatients && (
              <Grid item container xs={12} justify="center">
                <Button
                  bStyle="primary"
                  onClick={onHereForAVisit}
                  style={{fontSize: mdLg}}
                >
                  I'm here for a visit
                </Button>
              </Grid>
            )}
            {hasNoPatients && <NoPatients hideIcon={hasInvitations} />}
            <Grid item xs={12}>
              {hasInvitations && (
                <Grid container direction="row" justify="center" wrap="wrap">
                  {invitations
                    .filter(i => i.patient.isPresent())
                    .mapValue(invitation => (
                      <UnapprovedPatientDetails
                        key={invitation.id}
                        invitation={invitation}
                        onApprove={pId => {
                          setInvitations(
                            invitations
                              .filter(i => i.patientId !== pId)
                              .getAllOptional()
                              .orElse([]),
                          );

                          api
                            .guardian(guardianId)
                            .getPatients()
                            .then(pts => {
                              setPatients(pts);
                            });
                        }}
                      />
                    ))}
                </Grid>
              )}
              {hasPatients && (
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <IconButton
                      active={allCollapsed()}
                      icon={['fas', 'list']}
                      onClick={() => {
                        setAllCards(true);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      active={allExpanded()}
                      icon={['fas', 'address-card']}
                      onClick={() => {
                        setAllCards(false);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {hasPatients &&
                patients
                  .sort((a, b) =>
                    a.preferredName.localeCompare(b.preferredName),
                  )
                  .map(patient => (
                    <PatientCard
                      key={patient.id}
                      isCollapsed={isCollapsed(patient.id)}
                      currentInvitations={invitations
                        .getAllOptional()
                        .orElse([])}
                      patient={patient}
                      patients={patients.getAllOptional().orElse([])}
                      setCollapsed={coll => {
                        if (coll) {
                          collapse(patient.id);
                        } else {
                          expand(patient.id);
                        }
                      }}
                      onUpdatePatient={onUpdatePatient(
                        api,
                        patients,
                        setPatients,
                      )}
                      setPatients={setPatients}
                    />
                  ))}
            </Grid>
            <Grid item xs={12} container justify="center">
              <Button
                bStyle="primary"
                onClick={onAddPatient}
                style={{fontSize: mdLg}}
              >
                Add {invitationCount + patientCount > 0 ? 'another' : 'a'}{' '}
                Patient
              </Button>
            </Grid>
          </Grid>
        </>
      </PageLoading>
    </StyledGuardianDashboard>
  );
};

export default connect(mapStateToProps)(GuardianDashboard);
