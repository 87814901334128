import {bell} from '@src/components/shared/VideoChat/CheckHairRoom/SessionConfigForm/bell';
import {startSound} from '@src/components/shared/VideoChat/CheckHairRoom/SessionConfigForm/functions';
import {AudioSampleState} from '@src/components/shared/VideoChat/CheckHairRoom/SessionConfigForm/AudioOutConfigSection';
import {
  onParticipantConnected,
  onParticipantDisconnected,
  AttachRoomEventListenersProps,
} from '@src/util/videoChat/twilio/anonymousVisitor';
import {isLocalParticipantAdmitted} from '@src/util/videoChat';

export function attachParticipantConnected({
  room,
  setParticipants,
  setNotification,
}: Omit<AttachRoomEventListenersProps, 'setShowReloadingModal'>) {
  room.on('participantConnected', p => {
    if (isLocalParticipantAdmitted({room})) {
      startSound(bell, (v: AudioSampleState) => {});
    }

    onParticipantConnected({
      setParticipants,
      participant: p,
      setNotification,
    });
  });
}

export function attachParticipantDisconnected({
  room,
  setParticipants,
}: Omit<
  AttachRoomEventListenersProps,
  'setShowReloadingModal' | 'setNotification'
>) {
  room.on('participantDisconnected', p =>
    onParticipantDisconnected({
      setParticipants,
      participant: p,
    }),
  );
}
