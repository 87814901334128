import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Table,
  ListItemText,
} from '@material-ui/core';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {useStyles} from './styles';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {isNothing} from '@src/util/typeTests';
import {useIconStyles} from '@src/components/ui/atoms/buttonsAndIcons/styles';

export type Mappable = {
  id: number;
  label: string;
  description?: string;
};

export type ActionProps = {
  icon: IconProp;
  iconColor: string;
  handleActionClicked: (id: number) => void;
};

export type Props = {
  mappable: Mappable[];
  emptyMessage: string;
  primaryActionProps?: ActionProps;
  secondaryActionProps?: ActionProps;
};

export function ActionList({
  primaryActionProps,
  secondaryActionProps,
  mappable,
  emptyMessage,
}: Props) {
  const classes = useStyles();
  const iconClasses = useIconStyles();

  return (
    <>
      {mappable.length > 0 ? (
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table>
            <TableBody>
              {mappable.map(m => (
                <TableRow key={m.id}>
                  <TableCell className={classes.textCell}>
                    <ListItemText
                      primary={m.label}
                      secondary={m.description ?? ''}
                    />
                  </TableCell>
                  {!isNothing(primaryActionProps) && (
                    <TableCell className={classes.actionCell}>
                      <IconButton
                        className={iconClasses.clickableIcon}
                        style={{color: primaryActionProps.iconColor}}
                      >
                        <FontAwesomeIcon
                          icon={primaryActionProps.icon}
                          size="sm"
                          onClick={() => {
                            primaryActionProps.handleActionClicked(m.id);
                          }}
                          className={iconClasses.clickableIcon}
                          style={{color: primaryActionProps.iconColor}}
                        />
                      </IconButton>
                      {!isNothing(secondaryActionProps) && (
                        <IconButton
                          className={iconClasses.clickableIcon}
                          style={{color: secondaryActionProps.iconColor}}
                        >
                          <FontAwesomeIcon
                            icon={secondaryActionProps.icon}
                            size="sm"
                            onClick={() => {
                              secondaryActionProps.handleActionClicked(m.id);
                            }}
                            className={iconClasses.clickableIcon}
                            style={{color: secondaryActionProps.iconColor}}
                          />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <ParagraphText>{emptyMessage}</ParagraphText>
      )}
    </>
  );
}
