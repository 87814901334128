import {RouteComponentProps} from 'react-router';
import {BasicDetailsResult} from '../BasicDetailsComparer/Rows/utils/resultFunctions';
import {ConnectedLoginsResult} from '../shared/types';
import {AppAPI} from '@src/api/AppAPI';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {
  FinanciallyResponsiblePerson,
  PaymentInformationResult,
} from '@src/models/Patient';

export const onSubmit = async ({
  api,
  setSubmitting,
  basicDetailsResult,
  history,
  firstId,
  secondId,
  pcpDetailsId,
  financiallyResponsiblePersonResult,
  connectedLogins,
  paymentInformationResult,
}: {
  setSubmitting: (v: boolean) => unknown;
  basicDetailsResult: BasicDetailsResult;
  pcpDetailsId: number;
  api: AppAPI;
  history: RouteComponentProps<{}>['history'];
  firstId: number;
  secondId: number;
  financiallyResponsiblePersonResult: FinanciallyResponsiblePerson;
  connectedLogins: ConnectedLoginsResult;
  paymentInformationResult: PaymentInformationResult;
}) => {
  setSubmitting(true);

  const {
    firstName,
    lastName,
    pronouns,
    genderAssignedAtBirth,
    phone,
    dob,
    nickName,
  } = basicDetailsResult;

  const cleanText = (text: string) => {
    return text === '—' ? '' : text;
  };

  const mergedPatientDetails = {
    firstName: cleanText(firstName.text),
    lastName: cleanText(lastName.text),
    pronouns: cleanText(pronouns.text),
    genderAssignedAtBirth: cleanText(genderAssignedAtBirth.text),
    phone: cleanText(phone.text),
    dob: new Date(dob.text),
    nickName: cleanText(nickName.text),
  };

  api
    .patient(firstId)
    .mergePatients({
      mergedPatientDetails,
      duplicatePatientId: secondId,
      pcpDetailsId,
      financiallyResponsiblePerson: financiallyResponsiblePersonResult,
      guardianships: connectedLogins,
      paymentInformation: paymentInformationResult,
    })
    .then(() => {
      flashSuccess(
        "Patients successfully merged! You are now viewing the merged Patient's record.",
      );
      history.push(`/patient/${firstId}`);
    })
    .finally(() => setSubmitting(false))
    .catch(e => {
      console.log('e', e);
      flashError('Something went wrong trying to merge the patients.');
    });
};
