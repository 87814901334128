import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {Grid} from '@material-ui/core';
import {Button} from '../../../../ui/form';
import {MuiHeading} from '../../../../ui/layout/MuiHeading';
import {ParagraphText} from '../../../../ui/layout/text/body/ParagraphText';
import {IdentificationModal} from './IdentificationModal';
import {maybeStoreRedirectLink, removeRedirectLink} from '@src/util/visits';
import {PayloadForAnonymousVisitor} from '@src/util/videoChat/twilio/anonymousVisitor';

type AnonymousLandingProps = {
  setAnonymousInfo: (r: PayloadForAnonymousVisitor) => unknown;
};

export function AnonymousLanding({setAnonymousInfo}: AnonymousLandingProps) {
  const history = useHistory();
  const [showIdentificationModal, setShowIdentificationModal] = useState(false);

  return (
    <Grid container>
      {showIdentificationModal && (
        <IdentificationModal
          setAnonymousInfo={setAnonymousInfo}
          setShowIdentificationModal={setShowIdentificationModal}
        />
      )}

      <Grid item xs={12} style={{textAlign: 'center', marginTop: '3rem'}}>
        <MuiHeading color="primary">Do you need to log in?</MuiHeading>

        <ParagraphText>
          If you already have an Refyne Connected Care account, please select
          Log In.
        </ParagraphText>

        <ParagraphText>
          If you don't have an account, you can access this visit without
          logging in.
        </ParagraphText>

        <Grid item xs={12}>
          <Button
            bStyle="outlined"
            onClick={() => {
              setShowIdentificationModal(true);
              removeRedirectLink();
            }}
            style={{marginTop: '2rem'}}
            isInRow={true}
          >
            Join Without Logging In
          </Button>

          <Button
            bStyle="primary"
            onClick={() => {
              maybeStoreRedirectLink();
              history.push('/login');
            }}
            style={{marginTop: '2rem'}}
          >
            Log In
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
