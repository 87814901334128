import React, {useCallback, useState} from 'react';
import {Grid} from '@material-ui/core';
import {usePageContext} from '..';
import {LightHeading} from '@src/components/ui/layout/text/heading/LightHeading';
import {ConfirmModal} from '@src/components/ui/layout/ConfirmModal';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {useOnAddProviderGroupEndpoint} from '@src/hooks/providerGroups/endpoints/useAddProviderGroupEndpoint';
import {AddableList} from '@src/components/ui/molecules/lists/AddableList';
import {useUnaddedEndpoints} from '@src/hooks/endpoints/useUnaddedEndpoints';
import {CallPool} from '@src/models';

export function Available({callPool}: {callPool: CallPool}) {
  const callPoolId = callPool.id;
  const {reloadCallPools, endpointsResources} = usePageContext();

  const [endpoints] = endpointsResources;
  const unaddedEndpoints = useUnaddedEndpoints({endpoints, callPool});
  const mappable = unaddedEndpoints.map(e => ({label: e.name, id: e.id}));

  const [endpointIdToAdd, setEndpointIdToAdd] = useState<number | null>(null);

  const onAddProviderGroupEndpoint = useOnAddProviderGroupEndpoint({
    runBeforeRequest: useCallback(() => {}, []),
    asyncRunAfterRequest: useCallback(async () => {
      await reloadCallPools();
      flashSuccess('Success');
    }, [reloadCallPools]),
    runFinally: useCallback(() => {}, []),
    runOnError: useCallback(
      () =>
        flashError('Something went wrong adding the Endpoint to this Group'),
      [],
    ),
    groupId: callPoolId,
    endpointId: endpointIdToAdd,
  });

  const handleAddClicked = useCallback(
    (id: number) => {
      setEndpointIdToAdd(id);
    },
    [setEndpointIdToAdd],
  );

  const handleCancelAdd = useCallback(() => {
    setEndpointIdToAdd(null);
  }, [setEndpointIdToAdd]);

  return (
    <>
      <Grid item xs={12}>
        <LightHeading>Available</LightHeading>
      </Grid>
      <Grid item xs={12}>
        <AddableList
          handleAddClicked={handleAddClicked}
          mappable={mappable}
          emptyMessage="This Group currently has no Endpoints that can be added to it."
        />
      </Grid>

      <ConfirmModal
        onConfirm={onAddProviderGroupEndpoint}
        text="Confirming will add this Endpoint to the Group."
        show={endpointIdToAdd !== null}
        onHide={handleCancelAdd}
        onCancel={handleCancelAdd}
        cancelText="Cancel"
        confirmText="Add Endpoint"
        titleText="Add Endpoint"
      />
    </>
  );
}
