import {CardElement} from '@stripe/react-stripe-js';
import React, {useEffect, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {Button} from '@material-ui/core';
import {CardElementHolder, CCBox, CCForm, CCFormInstructions} from './layout';
import {CreditCardForm} from '.';
import {TextInput} from '@src/components/ui/form';
import {ErrorMessage} from '@src/components/ui/form/ErrorMessage';
import {danger, md} from '@src/components/ui/theme';
import {CreditCard, Patient} from '@src/models';

type Props = {
  patient: Patient;
  submitError?: string;
  creditCard: Optional<CreditCard>;
};

export const EditCreditCard = ({patient, submitError, creditCard}: Props) => {
  const {control, formState} = useFormContext<CreditCardForm>();
  const {errors} = formState;
  const [editDetails, setEditDetails] = useState(false);

  useEffect(() => {
    setEditDetails(!creditCard.isPresent());
  }, [creditCard]);

  return (
    <CCForm>
      <CCFormInstructions>
        We only use your credit card information to cover out-of-pocket expenses
        such as co-pays or co-insurance.
      </CCFormInstructions>
      <CCFormInstructions>
        If you don't have insurance you can also pay for a visit directly.
      </CCFormInstructions>
      {!editDetails && creditCard.isPresent() && (
        <>
          <CCFormInstructions>
            We currently have some credit card details on file. The last four
            digits of that card are <em>{creditCard.get().last4}.</em>
          </CCFormInstructions>
          <CCFormInstructions>
            If you'd like to change the card on file, please click below.
          </CCFormInstructions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setEditDetails(true);
            }}
          >
            Edit Credit Card Details
          </Button>
        </>
      )}
      {(editDetails || !creditCard.isPresent()) && (
        <>
          {submitError && <ErrorMessage message={submitError} />}
          <CCBox>
            <Controller
              control={control}
              name="cardholder"
              render={({name, onChange, value}) => (
                <TextInput
                  name={name}
                  required
                  placeholder="Cardholder Name"
                  value={value}
                  onChange={onChange}
                  touched
                  error={errors.cardholder?.message}
                />
              )}
            />
            <CardElementHolder>
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: md,
                      color: '#424770',
                      backgroundColor: '#fff',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    invalid: {
                      color: danger,
                    },
                  },
                }}
              />
            </CardElementHolder>
          </CCBox>
        </>
      )}
    </CCForm>
  );
};
