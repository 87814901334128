import {CircularProgress, Grid, IconButton, Input} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import React, {useState} from 'react';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    color: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 18,
    marginLeft: '3px',
  },
  input: {
    padding: 0,
  },
}));

type Props = {
  editable?: boolean;
  name: string;
  updateGuardian: (name: string, value: string) => Promise<unknown>;
  defaultValue: string;
  value: string;
  tooltip: string;
  readOnly?: boolean;
};

export function EditableGuardianProperty({
  defaultValue,
  editable = true,
  name,
  updateGuardian,
  value,
}: Props) {
  const [canEdit, setCanEdit] = useState(false);
  const [mutableValue, setMutableValue] = useState(value);
  const [saving, setSaving] = useState(false);
  const classes = useStyles();

  return (
    <>
      {editable && canEdit ? (
        <>
          <Input
            name={name}
            value={mutableValue}
            onChange={e => setMutableValue(e.target.value)}
            autoFocus={true}
            classes={{input: classes.input}}
          />

          {updateGuardian &&
            (saving ? (
              <CircularProgress size={18} style={{padding: 0, margin: 0}} />
            ) : (
              <IconButton
                className={classes.icon}
                onClick={() => {
                  setSaving(true);
                  updateGuardian(name, mutableValue).then(() => {
                    setCanEdit(false);
                    setSaving(false);
                  });
                }}
                style={{padding: 0}}
              >
                <CheckIcon style={{padding: 0}} />
              </IconButton>
            ))}
          <IconButton
            className={classes.icon}
            onClick={() => {
              setCanEdit(false);
            }}
            style={{padding: 0}}
          >
            <CloseIcon style={{padding: 0}} />
          </IconButton>
        </>
      ) : (
        <>
          <Grid
            container
            className={classes.rowContainer}
            direction="row"
            alignItems="flex-end"
          >
            <Grid item>{value || defaultValue}</Grid>

            {editable && (
              <Grid item>
                <IconButton
                  className={classes.icon}
                  onClick={() => {
                    setCanEdit(true);
                    setMutableValue(value);
                  }}
                  style={{padding: 0}}
                >
                  <EditIcon className={classes.icon} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
}
