import React from 'react';
import {ParagraphText} from '../../../ui/layout/text/body/ParagraphText';
import {reference} from '@src/util/stringManipulation/languageHelpers';

type Props = {
  userIsConnected: boolean;
};

export function GuardianText({userIsConnected}: Props) {
  return (
    <ParagraphText>
      Once you unlink {userIsConnected ? 'yourself' : 'this Connected Login'},{' '}
      {reference('', userIsConnected)('nom', {alternate: true})} will no longer
      be connected to this patient.{' '}
      {!userIsConnected && (
        <b>
          You will, however, be able to re-add the Connected Login, if
          necessary.
        </b>
      )}
    </ParagraphText>
  );
}
