import {Auth0Provider} from '@auth0/auth0-react';
import {AppState} from '@auth0/auth0-react/dist/auth0-provider';
import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import * as Sentry from '@sentry/browser';
import {captureException, captureMessage} from '@sentry/browser';
import 'audio-context-polyfill';
import 'core-js';
import {isAfter} from 'date-fns';
import {createBrowserHistory} from 'history';
import 'raf';
import React, {StrictMode} from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {polyfill as smoothScrollPolyfill} from 'smoothscroll-polyfill';
import {isSupported} from 'twilio-video';
import {SnackbarProvider} from 'notistack';
import {Router} from 'react-router';
import {AppBaseException} from './api/exceptions';
import {App} from './components/apps/App';
import {flashError} from './components/shared/notifications/flash';
import {NotificationListener} from './components/shared/notifications/NotificationListener';
import {ToolTipStyle} from './components/ui/layout/ToolTip';
import {GlobalStyle} from './components/ui/theme/ResetCss';
import {AddressSearchStyle} from './components/ui/form/AddressInput';
import ConfigService from './ConfigService';
import {DeepLinks} from './DeepLinks';
import {APIEvents, ApplicationEvents} from './events';
import {handleQueryMessage} from './handleQueryMessage';
import './IconLibrary';
import './polyfill';
import {getStore} from './store';
import {getTheme} from './theme/muiTheme';
import {isIOS} from './util/browserTools/deviceDetection';
import {UserProvider} from './hooks';
import {CondensedCardProvider} from './hooks/useCondensedCards';
import {getLastPathSegment} from './util/browserTools/getLastPathSegment';
import './input.css';
const browserDetection = require('@braintree/browser-detection');

smoothScrollPolyfill();

const params = new URLSearchParams(window.location.search);
const parameterSource = params.get('source') || '';
const pathSource = getLastPathSegment();

if (
  parameterSource.length > 0 ||
  (pathSource.length > 0 && window.location.pathname.includes('login'))
) {
  ConfigService.setCustomerCode(parameterSource || pathSource);
} else {
  const savedCode = ConfigService.getCustomerCode();
  if (savedCode !== null) {
    console.debug(`Using saved 'source' code: ${savedCode}`);
  }
}

const releaseInfo = ConfigService.getReleaseInfo();
const configService = ConfigService.getEnvironmentInstance();

if (releaseInfo.maintenanceModeStart) {
  console.info(
    `Maintenance Mode detected: ${releaseInfo.maintenanceModeStart}`,
  );
  const {maintenanceModeStart} = releaseInfo;
  if (isAfter(new Date(), maintenanceModeStart)) {
    location.pathname = '/maintenanceMode.html';
  } else {
    const maintenancePollInterval = window.setInterval(() => {
      if (isAfter(new Date(), maintenanceModeStart)) {
        clearInterval(maintenancePollInterval);
        location.pathname = '/maintenanceMode.html';
      }
    }, 10_000);
  }
}

(async () => {
  console.info('Booting app...');

  const dsn = await configService.get('RAVEN_DSN');

  console.info('Setting up Sentry...');
  Sentry.init({
    dsn,
    environment: releaseInfo.RAVEN_ENVIRONMENT,
    release: releaseInfo.RELEASE,
  });

  const store = getStore();

  const checkBrowserSupport = () => {
    console.info('Checking browser support...');
    if (!isSupported) {
      if (
        browserDetection.isIosWebview() ||
        browserDetection.isSamsungBrowser() ||
        browserDetection.isAndroid()
      ) {
        flashError(
          <div>
            <p>
              <strong>Video not supported on this browser.</strong> It seems
              like you're using a mobile browser and may have followed a link in
              your email. Lots of mobile devices open links up within the email
              client, rather than in the real Chrome or Safari.
            </p>
            <p>
              If this is the case, please copy the link and paste it into a
              supported browser.
            </p>
          </div>,
          {permanent: true},
        );
      } else {
        flashError(
          <div>
            <strong>Video not supported on this browser.</strong>
          </div>,
          {permanent: true},
        );
      }
    }
  };

  /**
   * We have to get any stored auth before initializing the store or else we end up clearing it
   */
  console.info('Setting up auth...');

  if (Object.values(DeepLinks).includes(window.location.hash)) {
    console.info(`Deep Link: ${window.location.hash}`);
  }

  window.addEventListener(
    ApplicationEvents.ReadyForNotifications,
    handleQueryMessage,
  );

  window.addEventListener(
    APIEvents.HttpResponseException,
    ((e: CustomEvent<Response>) => {
      const {detail} = e;
      if (detail.status >= 500) {
        captureException(
          new AppBaseException(APIEvents.HttpResponseException, {details: e}),
        );
        flashError(
          'The Refyne Connected Care server has responded with an unexpected error. Support has been notified',
        );
      }
    }) as EventListener,
    false,
  );

  window.addEventListener(
    APIEvents.HttpRequestException,
    ((e: CustomEvent) => {
      captureException(
        new AppBaseException(APIEvents.HttpRequestException, {details: e}),
      );
      flashError(
        "There's a problem connecting to the Refyne Connected Care server. Please wait a few minutes and try again.",
      );
      console.log(e);
    }) as EventListener,
    false,
  );

  window.addEventListener(
    APIEvents.ApplicationException,
    ((e: CustomEvent) => {
      captureException(
        new AppBaseException(APIEvents.ApplicationException, {details: e}),
      );
      flashError(
        'There was some sort of error with the application. Support has been notified.',
      );
      console.log(e);
    }) as EventListener,
    false,
  );

  const reportDeparture = () => {
    captureMessage('Page was Hidden or Unloaded');
  };

  window.addEventListener(
    isIOS() ? 'pagehide' : 'beforeunload',
    reportDeparture,
  );

  const domain = (await configService.get('AUTH0_DOMAIN')) || '';
  const clientID = (await configService.get('AUTH0_CLIENT_ID')) || '';
  const adminClientID =
    (await configService.get('AUTH0_ADMIN_CLIENT_ID')) || '';

  const history = createBrowserHistory();

  const onRedirectCallback = (appState: AppState) => {
    console.warn('onRedirectCallback');

    // Currently the only string value assigned to path will be
    // a waiting-room or exam-room path.
    const prev = window.localStorage.getItem('redirect');

    if (prev) {
      window.localStorage.removeItem('redirect');
      history.replace(prev);
    } else {
      // Use the router's history module to replace the url
      history.replace(appState?.returnTo || window.location.pathname);
    }
  };

  const root = document.getElementById('root');
  if (root) {
    render(
      <Auth0Provider
        domain={domain}
        clientId={ConfigService.isAdminSite() ? adminClientID : clientID}
        redirectUri={window.location.origin}
        audience="https://ahanapediatrics.com/api"
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        appState={JSON.stringify({source: ConfigService.getCustomerCode()})}
      >
        <Provider store={store}>
          <SnackbarProvider maxSnack={4} autoHideDuration={3000}>
            <MuiThemeProvider theme={getTheme()}>
              <CssBaseline />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <UserProvider>
                  <CondensedCardProvider>
                    <StrictMode>
                      <GlobalStyle />
                      <ToolTipStyle />
                      <AddressSearchStyle />
                      <Router history={history}>
                        <App />
                      </Router>
                    </StrictMode>
                  </CondensedCardProvider>
                </UserProvider>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
            <NotificationListener />
          </SnackbarProvider>
        </Provider>
      </Auth0Provider>,
      root,
      () => {
        checkBrowserSupport();
      },
    );
  } else {
    throw new Error('No root element found!');
  }
})();
