import {Optional} from '@ahanapediatrics/ahana-fp';
import {splitCamelCaseToString} from '../stringManipulation/camelCase';
import {fullName} from '../users/getDemographics';
import {User} from '@src/models';

export function formatRequester(
  requester: User,
  qualification: Optional<string>,
) {
  const oQualification = qualification.orElse('');
  const qualificationText = oQualification ? `, ${oQualification}` : '';

  return fullName(requester) + qualificationText;
}

export function formatProperty(s: string): string {
  if (s === 'personalManagementSteps') return 'Medical Care Preferences';
  return splitCamelCaseToString(s);
}
