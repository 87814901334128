import React, {useEffect} from 'react';
import {isSupported, RemoteParticipant} from 'twilio-video';
import {AppBar, IconButton, Toolbar, Typography} from '@material-ui/core';
import clsx from 'clsx';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {flashWarn, clearFlashes} from '../notifications/flash';
import {SIDEBAR_WIDTH} from '../VideoChat/layout';
import {useStyles} from './layout';

type WarningMessages = {
  disabled: string;
  unsupported: string;
  noPermission: string;
  admittedButAwaitingRemote: string;
  awaitingAdmittance: string;
};

const warningMessages: WarningMessages = {
  disabled: 'Video is currently disabled',
  unsupported:
    'Video is not supported on this browser. Please use Chrome or Firefox',
  noPermission: 'Your browser has denied access to the camera and microphone.',
  admittedButAwaitingRemote:
    'Your video will start once someone else arrives to this visit',
  awaitingAdmittance: 'Your visit will start when someone lets you join',
};

type HeaderProps = {
  sidebarOpen: boolean;
  setSidebarOpen: (v: boolean) => void;
  notification: JSX.Element | null;
  remoteParticipants: RemoteParticipant[];
  waitingForAdmittance: boolean;
};

export function Header({
  sidebarOpen,
  setSidebarOpen,
  notification,
  remoteParticipants,
  waitingForAdmittance,
}: HeaderProps) {
  const classes = useStyles({
    width: SIDEBAR_WIDTH,
  });

  useEffect(() => {
    if (waitingForAdmittance) {
      flashWarn(warningMessages.awaitingAdmittance, {permanent: true});
    }
  }, []);

  useEffect(() => {
    const admittedButAwaitingRemote =
      remoteParticipants.length <= 0 && !waitingForAdmittance;
    const needWarning = admittedButAwaitingRemote || !isSupported;

    const videoWarningMessage = admittedButAwaitingRemote
      ? warningMessages.admittedButAwaitingRemote
      : !isSupported
      ? warningMessages.unsupported
      : null;

    if (!waitingForAdmittance && !needWarning) {
      clearFlashes();
    }

    if (needWarning && videoWarningMessage) {
      flashWarn(videoWarningMessage, {permanent: true});
    }
  }, [remoteParticipants, waitingForAdmittance]);

  return (
    <>
      <AppBar
        id="header"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: sidebarOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              setSidebarOpen(true);
            }}
            edge="start"
            className={clsx(classes.expandMenuButton, {
              [classes.hide]: sidebarOpen,
            })}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
          <Typography variant="h6">{notification}</Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
    </>
  );
}
