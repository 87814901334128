import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {ErrorMessage} from '@src/components/ui/form/ErrorMessage';
import {ProviderSelector} from '@src/components/shared/ProviderSelector';
import {Context} from '@src/util/provider/forms/addOrEdit';
import {
  StyledAsyncActionButton,
  StyledButton,
  StyledButtonRowContainer,
} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/layout';
import {FormStates} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/index';
import {
  createSCPWithoutOwner,
  createSCPWithOwner,
} from '@src/util/sharedCarePlan/create';
import {LonelyPatient, UserType} from '@src/models';
import {useApi} from '@src/api/useApi';
import {
  getEmptyProviderDetails,
  ProviderDetailsId,
} from '@src/models/ProviderDetails';
import {UserId} from '@src/models/User';

type Props = {
  onCancel: () => void;
  patient: Optional<LonelyPatient>;
  setFormState: (s: FormStates) => void;
  setNoOwnership: (o: boolean) => void;
  userType: UserType;
};

export const OwnershipSelection: React.FunctionComponent<Props> = ({
  patient,
  userType,
  onCancel,
  setFormState,
  setNoOwnership,
}) => {
  const api = useApi();
  const [providerInfo, setProviderInfo] = useState(
    Optional.of(getEmptyProviderDetails()),
  );
  const [creating, setCreating] = useState(false);
  const [errors, setErrors] = useState<{[field: string]: string}>({});

  const infoId = providerInfo?.map(p => p.id).orElse(0 as ProviderDetailsId);
  const patientId = patient.map(p => p.id).orElse(0);
  const providerId = providerInfo.map(p => p.providerId).orElse(0 as UserId);

  useEffect(() => {
    setErrors(oldErrors => ({
      ...oldErrors,
      ownerSelection: '',
      createSCP: creating ? '' : oldErrors.createSCP,
    }));
  }, [providerInfo, creating]);

  return (
    <Grid container direction="row">
      <ProviderSelector
        providerDescription="Owning provider"
        details={Optional.of(providerInfo)}
        onChange={i => {
          setProviderInfo(Optional.of(i));
        }}
        userType={userType}
        sharedCarePlanContextOptions={{
          setNoSCPOwner: () => {
            setProviderInfo(Optional.empty());
          },
        }}
        selectionOrEditContext={Context.SharedCarePlanOwner}
      />

      {errors.createSCP && <ErrorMessage message={errors.createSCP} />}
      {errors.ownerSelection && (
        <ErrorMessage message={errors.ownerSelection} />
      )}

      <StyledButtonRowContainer>
        <StyledButton bStyle="secondary" bSize="small" onClick={onCancel}>
          Cancel
        </StyledButton>
        <StyledAsyncActionButton
          actionInProgress={creating}
          actionWord={'Confirm owner selection'}
          bStyle="primary"
          bSize="small"
          onClick={() => {
            if (providerInfo === null)
              return setErrors({
                ...errors,
                ownerSelection:
                  'Please select an owner or designate that you are unsure of who the owner should be',
              });

            if (!infoId || infoId === 0) {
              createSCPWithoutOwner(api)({
                patientId,
                details: providerInfo.orNull(),
                setCreating,
                setErrors,
                errors,
                onCreated: () => {
                  setNoOwnership(true);
                  setFormState(FormStates.SCPCreatedStep);
                },
              });
            } else {
              createSCPWithOwner(api)({
                patientId,
                providerId,
                setCreating,
                setErrors,
                errors,
                onCreated: () => {
                  setFormState(FormStates.SCPCreatedStep);
                },
              });
            }
          }}
        />
      </StyledButtonRowContainer>
    </Grid>
  );
};
