import React, {useState} from 'react';
import {SharedCarePlan} from '@src/models';
import {Modal} from '@src/components/ui/layout/Modal';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {AsyncActionButton} from '@src/components/ui/form/AsyncActionButton';
import {TextInput} from '@src/components/ui/form/TextInput';
import {AppAPI} from '@src/api/AppAPI';

const api = AppAPI.getInstance();

type Props = {
  scp: SharedCarePlan;
  patientName: string;
  setScp: (scp: SharedCarePlan) => unknown;
  setShowModal: (v: boolean) => unknown;
  show: boolean;
};

export const RetractModal = ({
  scp,
  patientName,
  setScp,
  show,
  setShowModal,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reasoning, setReasoning] = useState('');

  return (
    <>
      <Modal
        show={show}
        title="Are you sure you want to retract this Shared Care Plan?"
        onClose={() => {
          setShowModal(false);
          setReasoning('');
        }}
        modalActions={
          <AsyncActionButton
            actionInProgress={isSubmitting}
            actionWord={'Retract'}
            disabled={!reasoning}
            bStyle="primary"
            onClick={() => {
              setIsSubmitting(true);

              api
                .scp(scp.id)
                .retractSCP({reasoning})
                .then(newScp => {
                  setScp(newScp);
                  setShowModal(false);
                  setReasoning('');
                })
                .finally(() => {
                  setIsSubmitting(false);
                });
            }}
          />
        }
      >
        <ParagraphText>
          As a medical professional, you can retract this Shared Care Plan if
          you see incorrect information that you think would place {patientName}{' '}
          in a position of significant risk were another provider to act on that
          information while caring for {patientName}.
        </ParagraphText>
        <ParagraphText>
          This will prevent other users from accessing this plan until the
          provider who owns it resolves the problem.
        </ParagraphText>
        <TextInput
          required
          name="reasoning"
          title="Reasoning"
          type="text"
          value={reasoning}
          onChange={e => setReasoning(e.target.value)}
          instructions="Please enter your reasoning for retracting this Shared Care Plan"
        />
      </Modal>
    </>
  );
};
