import React, {useState, useCallback} from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {Menu, MenuItem} from '@material-ui/core';

import {GroupsModal} from '../modals/GroupsModal';
import {ConfirmModal} from '@src/components/ui/layout/ConfirmModal';
import {OnCallPeriod} from '@src/models';
import {SinglePeriodModal} from '@src/components/providerSide/OnCallPeriodModals';
import {UserId} from '@src/models/User';
import {convertOnCallPeriod} from '@src/components/providerSide/OnCallPeriodModals/SinglePeriodModal/functions';
import {flashError} from '@src/components/shared/notifications/flash';
import {useApi} from '@src/api/useApi';
import {usePageContext} from '@src/components/providerSide/providerGroups/ProviderGroupRosterPage';
import {useShiftMenuStyles} from '@src/components/providerSide/providerGroups/ProviderGroupRosterPage/MonthTable/layout';
import {useUser} from '@src/hooks/useUser';

type Props = {
  period: OnCallPeriod;
};

export function ShiftMenu({period}: Props) {
  const {id: periodId} = period;

  const classes = useShiftMenuStyles();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const [showDeleteShiftModal, setShowDeleteShiftModal] = useState(false);
  const [showEditShiftModal, setShowEditShiftModal] = useState(false);
  const [showGroupsModal, setShowGroupsModal] = useState(false);

  const api = useApi();

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setShowDeleteShiftModal(false);
  }, []);

  const {
    dayComponent: {setPeriodToPopulate},
    monthTable: {reloadPeriodsMappedToDaysOfSelectedMonth},
  } = usePageContext();

  const [user] = useUser();
  const providerId = user
    .getOptional()
    .map(u => u.id)
    .orElse(0 as UserId);

  return (
    <>
      <MoreHorizIcon onClick={handleClick} className={classes.menuIcon} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setShowGroupsModal(true);
          }}
        >
          Edit Groups
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setPeriodToPopulate(period);
          }}
        >
          Edit Providers
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setShowEditShiftModal(true);
          }}
        >
          Edit Shift
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowDeleteShiftModal(true);
          }}
        >
          Delete Shift
        </MenuItem>
      </Menu>

      {showGroupsModal && (
        <GroupsModal
          period={period}
          show={showGroupsModal}
          onHide={() => {
            setShowGroupsModal(false);
          }}
          onCancel={() => {
            setShowGroupsModal(false);
          }}
        />
      )}
      {showDeleteShiftModal && (
        <ConfirmModal
          onConfirm={() => {
            return api
              .onCallPeriods(periodId)
              .deleteOnCallPeriod()
              .then(() => {
                reloadPeriodsMappedToDaysOfSelectedMonth();
                handleClose();
              })
              .catch(() => {
                flashError(
                  'Something went wrong deleting this Shift. Please try again or contact Support.',
                );
              });
          }}
          text="Confirming will remove this Shift. It will also remove all the Providers from the Shift."
          show={showDeleteShiftModal}
          onHide={() => {
            setShowDeleteShiftModal(false);
          }}
          onCancel={() => {
            setShowDeleteShiftModal(false);
          }}
          cancelText="Cancel"
          confirmText="Remove Shift"
          titleText="Are you sure?"
        />
      )}
      {showEditShiftModal && (
        <SinglePeriodModal
          period={period ? convertOnCallPeriod(period) : undefined}
          preselectedProviderGroups={period?.callPools}
          providerId={providerId}
          purpose="Coverage"
          show={showEditShiftModal}
          onClose={() => setShowEditShiftModal(false)}
          onCreateOnCallPeriods={reloadPeriodsMappedToDaysOfSelectedMonth}
        />
      )}
    </>
  );
}
