import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {ChangeEvent, ChangeEventHandler, useState} from 'react';
import {Contact, ContactRequest, SharedCarePlan} from '../../../models';
import {DateInput, PhoneInput, TextInput} from '../../ui/form';
import {AddressInput} from '../../ui/form/AddressInput';
import {getEmptyAddress} from '../../ui/form/AddressInput/functions';
import {MuiAsyncActionButton} from '../../ui/form/MuiAsyncActionButton';
import {Modal} from '../../ui/layout/Modal';
import {ParagraphText} from '../../ui/layout/text/body/ParagraphText';
import {useApi} from '@src/api/useApi';

type Props = {
  show: boolean;
  onDelete: (contactId: number) => void;
  onHide: () => void;
  onUpdate: (contact: Contact) => void;
  contact: Optional<Contact>;
  scp: Optional<SharedCarePlan>;
};

const eventSetter = (
  f: (s: string) => void,
): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => (
  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) => f(e.target.value);

export const ContactModal: React.FunctionComponent<Props> = ({
  contact,
  scp,
  onDelete,
  onHide,
  onUpdate,
  show,
}) => {
  const api = useApi();
  const [name, setName] = useState(contact.map(c => c.name).orElse(''));
  const [role, setRole] = useState(contact.map(c => c.role).orElse(''));

  const [phone, setPhone] = useState(contact.map(c => c.phone).orElse(''));
  const [dob, setDob] = useState<Date | null>(contact.map(c => c.dob).orNull());
  const [address, setAddress] = useState(
    contact.map(c => c.address).orElseGet(getEmptyAddress),
  );
  const [missingData, setMissingData] = useState(false);
  const [touched, setTouched] = useState(false);
  const [savingContact, setSavingContact] = useState(false);

  const modalActions = (
    <>
      {contact.isPresent() && (
        <MuiAsyncActionButton
          disabled={!contact.isPresent()}
          actionInProgress={savingContact}
          actionWord={'Delete Contact'}
          onClick={() => {
            setSavingContact(true);
            const id = contact.map(c => c.id).orElse(0);
            api
              .scp(scp.map(m => m.id).orElse(0))
              .deleteContact(id)
              .then(() => {
                onDelete(id);
              })
              .finally(() => {
                setSavingContact(false);
              });
          }}
        />
      )}

      <MuiAsyncActionButton
        bSize="medium"
        actionInProgress={savingContact}
        actionWord={'Save Contact'}
        disabled={!name || !role || !phone || !dob}
        onClick={() => {
          setSavingContact(true);

          setTouched(true);
          if (name.trim().length === 0) {
            setMissingData(true);
            return;
          }
          const contactBody: ContactRequest = {
            id: contact.map(c => c.id).orElse(0),
            name,
            role,
            phone,
            secondNumber: '',
            dob: dob ? dob.toISOString() : '',
            address,
            isPrimary: contact.map(c => c.isPrimary).orElse(false),
            isEmergency: contact.map(c => c.isEmergency).orElse(false),
            updatedAt: new Date().toISOString(),
          };
          const scpApi = api.scp(scp.map(m => m.id).orElse(0));
          const result = contact.isPresent()
            ? scpApi.updateContact(contactBody)
            : scpApi.createContact(contactBody);
          result.then(onUpdate).then(() => {
            setSavingContact(false);
          });
        }}
      />
    </>
  );

  return (
    <Modal
      show={show}
      title={contact.isPresent() ? 'Edit contact' : 'Add contact'}
      onClose={onHide}
      modalActions={modalActions}
    >
      <>
        <ParagraphText style={{marginBottom: '1rem'}}>
          Please {contact.isPresent() ? 'update' : 'enter'} your contact's
          information here
        </ParagraphText>
        <ParagraphText style={{marginBottom: '1rem'}}>
          All of the required pieces information, marked with an asterisk are
          needed before you can save this
        </ParagraphText>
        <div>
          <TextInput
            required
            title="Name"
            name="name"
            placeholder="What is your name?"
            value={name}
            onChange={eventSetter(setName)}
            error={missingData ? "Please provide this contact's name" : ''}
            touched={touched}
          />
          <TextInput
            title="Relationship"
            name="role"
            placeholder="How are you related to this patient?"
            value={role}
            onChange={eventSetter(setRole)}
            touched={touched}
            required
            error={missingData ? "Please provide this contact's name" : ''}
          />
          <PhoneInput
            title="Phone"
            name="phone"
            placeholder="Please enter the best number to reach you at"
            value={phone}
            onChange={setPhone}
            touched={touched}
            required
          />

          <DateInput
            name="dob"
            value={dob}
            onChange={date => {
              if (date === null) {
                return;
              }

              setDob(date);
            }}
            label="Contact's date of birth"
            required
          />
          <AddressInput
            value={address}
            onChange={setAddress}
            touched={touched}
          />
        </div>
      </>
    </Modal>
  );
};
