import React from 'react';
import {Button} from '../../../ui/form';
import {Modal} from '../../../ui/layout/Modal';
import {ParagraphText} from '../../../ui/layout/text/body/ParagraphText';
import {Modal as ModalType} from './index';
import {VideoChatEvents, sendEvent} from '@src/events';

type Props = {
  showModal: boolean;
  setShowModal: (m: ModalType) => void;
};

export function PresentSCPModal({showModal, setShowModal}: Props) {
  return (
    <Modal
      show={showModal}
      title="Instructions - Present Shared Care Plan"
      onClose={() => {
        setShowModal('none');
      }}
      modalActions={
        <Button
          onClick={() => {
            setShowModal('none');
            sendEvent(VideoChatEvents.PresentSCP);
          }}
          bStyle="primary"
        >
          Start Presenting
        </Button>
      }
    >
      <ParagraphText style={{marginRight: '2rem'}}>
        Please click <span style={{fontWeight: 700}}>Start Presenting</span>.
        This will prompt you to start screen sharing.{' '}
        <b>
          Please choose to share your entire screen or the current browser when
          the prompt opens.
        </b>
      </ParagraphText>

      <ParagraphText style={{marginRight: '2rem'}}>
        Once you begin sharing, another tab will open for the the Shared Care
        Plan.
      </ParagraphText>
      <ParagraphText style={{marginRight: '2rem'}}>
        Don't worry, your visit won't go anywhere! It will still be open in this
        tab, and you can always click on it to view the exam room.
      </ParagraphText>
    </Modal>
  );
}
