import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import {danger} from '../theme';

const RedFlagStyled = styled(FontAwesomeIcon)`
  color: ${danger};
`;
export const RedFlag = () => (
  <RedFlagStyled icon={'flag'} className="red-flag-icon" />
);
