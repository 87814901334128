import React from 'react';
import {usePageContext} from '..';
import SearchProvider from '../../SearchProvider';
import {TextInputs} from './TextInputs';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {FormContainer} from '@src/components/ui/molecules/layouts/FormContainer';
import {isNothing} from '@src/util/typeTests';
import {HorizontalSection} from '@src/components/ui/atoms/section/HorizontalSection';
import {WarningText} from '@src/components/ui/layout/text/body/WarningText';

export function Form() {
  const {
    formResources: {formHandler, submitting, professionalAlreadyUser},
    searchResources,
  } = usePageContext();

  const {details} = searchResources;
  const oDetails = details.getOptional();

  const submitButton = (
    <MuiAsyncActionButton
      bStyle="primary"
      bSize="normal"
      onClick={formHandler}
      actionInProgress={submitting}
      actionWord="Submit"
      disabled={submitting || professionalAlreadyUser}
    >
      Submit
    </MuiAsyncActionButton>
  );

  return (
    <FormContainer submitButton={submitButton}>
      <HorizontalSection lastSection={isNothing(oDetails.orNull())}>
        <SearchProvider
          showOnlyProviderCard
          emailLabel="Current Email"
          professionalSearchResources={searchResources}
        />
      </HorizontalSection>
      {!isNothing(oDetails.orNull()) && (
        <HorizontalSection lastSection>
          <TextInputs />
        </HorizontalSection>
      )}
      {professionalAlreadyUser && (
        <WarningText warningVariant="warning" style={{marginBottom: 0}}>
          This Professional already has a User account.
        </WarningText>
      )}
    </FormContainer>
  );
}
