import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useRef, useState, useCallback} from 'react';
import {useHistory} from 'react-router';
import {Icon, makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {
  PatientDetailsValues,
  patientToValues,
} from '../SharedPatient/patientDetailsUtils';
import {FlowPage} from '../FlowPage';
import {
  ConnectedLogin,
  NewPatientStep,
  StepComponents,
  StepNames,
} from './stepFunctions';
import {medicalHistorySchema} from './steps/MedicalHistoryStep/PatientMedicalHistoryForm';
import {savePatient} from './savePatient';
import {Button} from '@src/components/ui/form';
import {useUser} from '@src/hooks';
import {
  ProviderDetails,
  UserType,
  LonelyPatient,
  ResponsiblePerson,
} from '@src/models';
import {useApi} from '@src/api/useApi';

const useStyles = makeStyles({
  spinner: {
    fontSize: '1em',
    marginLeft: '.75em',
  },
});

// eslint-disable-next-line import/no-unused-modules
export default function NewPatient() {
  const api = useApi();
  const history = useHistory();
  const classes = useStyles();
  const [user, userType] = useUser();

  /*
   * PATIENT INFO START
   */
  const [patientDetails, setPatientDetails] = useState<PatientDetailsValues>(
    patientToValues(Optional.empty(), Optional.empty()),
  );
  const [details, setDetails] = useState(Optional.empty<ProviderDetails>());
  const [providerNotKnown, setProviderNotKnown] = useState(false);
  const [includeMedicalHistory, setIncludeMedicalHistory] = useState(false);
  const [medicalHistory, setMedicalHistory] = useState(
    medicalHistorySchema.default(),
  );
  const [
    existingPersonForIndependentPatient,
    setExistingPersonForIndependentPatient,
  ] = useState<Optional<ResponsiblePerson>>(Optional.empty());

  const [patientIsCurrentUser, setPatientIsCurrentUser] = useState(false);
  const [connectedLogins, setConnectedLogins] = useState<ConnectedLogin[]>([]);

  /*
   * PATIENT INFO END
   */

  const getPlan = useCallback(() => {
    const flow: NewPatientStep[] = ['PatientDetails'];

    if (userType === UserType.Professional) {
      flow.push('ConnectedLogins');
    }

    flow.push('ProviderDetails');

    if (includeMedicalHistory) {
      flow.push('MedicalHistory');
    }
    flow.push('Confirm');

    return flow;
  }, [includeMedicalHistory, userType]);

  const moveGuard = useRef<(dir: 'back' | 'next') => Promise<boolean>>(() =>
    Promise.resolve(true),
  );

  const resetState = useCallback(() => {
    setPatientDetails(patientToValues(Optional.empty(), Optional.empty()));
    setDetails(Optional.empty());
    setMedicalHistory(medicalHistorySchema.default());
    setExistingPersonForIndependentPatient(Optional.empty());
    setPatientIsCurrentUser(false);
    return true;
  }, [setPatientDetails, setDetails, setMedicalHistory]);

  const stepProperties = {
    patientDetails,
    setPatientDetails,
    connectedLogins,
    setConnectedLogins,
    details,
    setDetails,
    providerNotKnown,
    setProviderNotKnown,
    medicalHistory,
    setMedicalHistory,
    includeMedicalHistory,
    setIncludeMedicalHistory,
    existingPersonForIndependentPatient,
    setExistingPersonForIndependentPatient,
    patientIsCurrentUser,
    setPatientIsCurrentUser,
  };

  const getForwardNavigationButtons = ({
    currentStepName,
    isLastStep,
    proceedToNextStep,
    saving,
  }: {
    currentStepName: string;
    isLastStep: boolean;
    proceedToNextStep: () => unknown;
    saving: boolean;
  }) => {
    const buttons = [
      <Button
        disabled={saving}
        bStyle="primary"
        onClick={() => {
          if (currentStepName === StepNames['ProviderDetails']) {
            setIncludeMedicalHistory(false);
          }
          proceedToNextStep();
        }}
      >
        {isLastStep
          ? 'Confirm'
          : currentStepName === StepNames['ProviderDetails']
          ? 'Review Info'
          : 'Next'}
        {saving && (
          <Icon
            className={clsx(classes.spinner, 'fas fa-spinner fa-spin')}
          ></Icon>
        )}
      </Button>,
    ];

    if (currentStepName === StepNames['ProviderDetails']) {
      buttons.push(
        <Button
          bStyle="outlined"
          onClick={() => {
            setIncludeMedicalHistory(true);
            proceedToNextStep();
          }}
        >
          Add Medical History
        </Button>,
      );
    }

    return buttons;
  };

  return (
    <FlowPage
      title="Create Patient"
      getPlan={getPlan}
      moveGuard={moveGuard}
      resetState={resetState}
      onCancel={() => history.goBack()}
      StepComponents={StepComponents}
      StepNames={StepNames}
      stepProperties={stepProperties}
      onPerformTask={() => {
        return savePatient({
          user,
          userType,
          patientDetails,
          providerDetails: details,
          api,
          connectedLogins,
          medicalHistory,
        });
      }}
      onTaskPerformed={async (p: LonelyPatient | null) => {
        if (userType === UserType.Guardian || p === null) {
          history.push(`/dashboard`);
        } else {
          history.push(`/patient/${p.id}`);
        }
        return true;
      }}
      getForwardNavigationButtons={getForwardNavigationButtons}
    />
  );
}
