import {makeStyles} from '@material-ui/core';
import styled from 'styled-components';
import {ButtonRowContainer} from '../../ui/layout/ButtonContainers';
import {md, sm, xs} from '../../ui/theme';

export const useStyles = makeStyles(theme => ({
  poolEntry: {
    margin: `${sm} 0`,
    padding: `${sm}`,
  },
  description: {padding: `0 0 0 ${md}`},
  shiftsTableIcon: {
    padding: `0 ${xs}`,
    cursor: 'pointer',
  },
  tableContainer: {
    backgroundColor: theme.palette.common.white,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export const ButtonContainer = styled(ButtonRowContainer)`
  justify-content: flex-start;
`;
