import {Grid, Typography} from '@material-ui/core';
import {format} from 'date-fns';
import React from 'react';
import {PatientDetailsValues} from './patientDetailsUtils';
import genderAndPronouns from '@src/util/patient/genderAndPronouns';

interface Props {
  patientDetails: PatientDetailsValues;
}

export function PatientInformation({patientDetails}: Props) {
  const gap = genderAndPronouns(patientDetails);
  return (
    <Grid container>
      <Grid item xs={12} style={{marginBottom: '1rem'}}>
        <Typography variant="h6">
          {patientDetails.firstName}
          {patientDetails.nickName && ` (${patientDetails.nickName})`}{' '}
          {patientDetails.lastName}
        </Typography>

        <div>DOB: {format(patientDetails.dob!, 'MM/dd/yyyy')}</div>
        {gap && <div>{gap}</div>}
        <div>Contact number: {patientDetails.phone}</div>
        {patientDetails.isSelf && (
          <div>Patient will manage their own account</div>
        )}
      </Grid>
    </Grid>
  );
}
