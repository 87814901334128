import React, {useCallback, useContext} from 'react';
import {MenuItem, MenuItemProps} from '@material-ui/core';
import {ContextMenuContext} from '.';

// Having to omit the button prop is a long-standing MUI bug.
// https://github.com/mui/material-ui/issues/21127
type Props = Omit<MenuItemProps, 'button'>;

export default function ContextMenuItem({onClick = () => {}, ...props}: Props) {
  const {setClosed} = useContext(ContextMenuContext);

  const handleClick = useCallback(
    event => {
      setClosed();
      onClick(event);
    },
    [onClick, setClosed],
  );

  return <MenuItem onClick={handleClick} {...props} />;
}
