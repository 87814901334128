import styled from 'styled-components';
import {primary, xs} from '@src/components/ui/theme';

export const PersonInformation = styled.div``;

export const ContactInformation = styled.div``;

export const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  color: ${primary};
  padding: 0 ${xs};

  div {
    padding: 0 ${xs};
  }
`;
