import React from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {
  Grid,
  FormControlLabel,
  FormControl,
  Checkbox,
  FormLabel,
  Typography,
  Paper,
} from '@material-ui/core';
import {useStyles} from '../styles';
import {PaymentInformation} from '@src/models';
import {MergeOptions} from '@src/models/Patient';
import {fromPaymentInformation} from '@src/components/guardianSide/PaymentDetails';
import {isEmpty} from '@src/util/typeTests';
import {lg} from '@src/components/ui/theme';

type Props = {
  checkboxesState: Record<string, boolean>;
  setCheckboxesState: (o: Record<string, boolean>) => unknown;
  mergeOptions: MergeOptions;
};

export function getLabel(info: PaymentInformation) {
  const details = fromPaymentInformation(Optional.of(info));

  const noMedicaid = !details.hasMedicaid;
  const {insuranceProvider, insuranceId, insuranceGroup} = details;

  return (
    <Paper variant="outlined" style={{padding: lg}}>
      <Typography variant="h6">
        Insurance ID: {!isEmpty(insuranceId) ? insuranceId : 'Unknown'}
      </Typography>

      <Typography variant="subtitle1">
        Group:{' '}
        {noMedicaid
          ? 'N/A (Medicaid)'
          : !isEmpty(insuranceGroup)
          ? insuranceGroup
          : 'Unknown'}
      </Typography>
      <Typography variant="subtitle1">
        Provider: {!isEmpty(insuranceProvider) ? insuranceProvider : 'Unknown'}
      </Typography>
    </Paper>
  );
}

export function SelectInsuranceStep({
  setCheckboxesState,
  checkboxesState,
  mergeOptions,
}: Props) {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxesState({
      ...checkboxesState,
      [event.target.name]: event.target.checked,
    });
  };

  const selectionsLength = Object.values(checkboxesState).filter(v => v).length;
  const error = selectionsLength > 2 || selectionsLength < 1;

  return (
    <Grid container direction="row">
      <FormControl
        error={error}
        required
        component="fieldset"
        style={{width: '100%'}}
      >
        <Grid
          container
          direction="row"
          xs={12}
          className={classes.selectionsContainer}
        >
          {mergeOptions.paymentInfos.map(p => {
            return (
              <Grid
                item
                key={p.id}
                xs={4}
                className={classes.insuranceCardContainer}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxesState[`${p.id}`]}
                      name={`${p.id}`}
                      onChange={handleChange}
                    />
                  }
                  label={getLabel(p)}
                />
              </Grid>
            );
          })}
        </Grid>
        <FormLabel>Pick 1-2 Selections</FormLabel>
      </FormControl>
    </Grid>
  );
}
