import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import React from 'react';
import {Button} from '@src/components/ui/form';
import {Acknowledge} from '@src/components/ui/form/Acknowledge';
import {ResponsiblePersonCard} from '@src/components/shared/forms/FinanciallyResponsiblePersonFormModal/ResponsiblePersonCard';
import {ButtonRowContainer} from '@src/components/ui/layout/ButtonContainers';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {PatientRelationship, Patient} from '@src/models';

type Props = {
  canProceed: boolean;
  patient: Optional<Patient>;
  financialRelationship: Optional<PatientRelationship>;
  onEdit: () => unknown;
  onProceed: () => unknown;
};

export function ResponsiblePersonView({
  patient,
  canProceed,
  onProceed,
  onEdit,
  financialRelationship,
}: Props) {
  const responsiblePerson = financialRelationship.map(r => r.person);

  return (
    <Grid container direction="row">
      <Grid item>
        <Grid item>
          <ParagraphText>
            This is the person who will be contacted for any questions around
            insurance or payment for{' '}
            {patient.property('preferredFirstName', 'this patient')}
            's visit.
          </ParagraphText>

          {responsiblePerson.isPresent() ? (
            <ResponsiblePersonCard
              relationship={financialRelationship.get().relationship}
              person={responsiblePerson.get()}
            />
          ) : (
            <ParagraphText>
              {patient.property('firstName', 'This patient')} doesn't have a
              responsible person set up yet.
            </ParagraphText>
          )}
          <ButtonRowContainer>
            <Button bStyle="secondary" onClick={onEdit}>
              {responsiblePerson.isPresent()
                ? 'Edit Responsible Person'
                : 'Add Responsible Person'}
            </Button>
          </ButtonRowContainer>
        </Grid>

        {responsiblePerson.isPresent() && (
          <Acknowledge
            disabled={!responsiblePerson.isPresent()}
            setValue={v => {
              onProceed();
            }}
            value={Optional.of(canProceed)}
            message="Confirm that the information above is correct"
            style={{marginTop: '1rem'}}
          />
        )}
      </Grid>
    </Grid>
  );
}
