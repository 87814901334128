import {FormControl, Grid, MenuItem, Select} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {OnCallColumns, ScheduledColumns} from '../../VisitTable/ColumnSets';
import {useStyles} from './layout';
import {useApi} from '@src/api/useApi';
import {MuiHeading} from '@src/components/ui/layout/MuiHeading';
import {VisitTable} from '@src/components/providerSide/VisitTable';
import {DashboardContent} from '@src/components/providerSide/PastVisitsDashboard/layout';
import {PrivatePage} from '@src/components/PrivatePage';
import {PageHeader} from '@src/components/ui/layout';
import {useResources} from '@src/hooks';
import {useUser} from '@src/hooks/useUser';
import {CallPool, LonelyVisit, SimpleVisit} from '@src/models';
import {UserId} from '@src/models/User';
import {ReduxState} from '@src/store';
import {getAllPages} from '@src/util/apiHelpers/getAllPages';
import {getCallPoolName} from '@src/util/callPools/getCallPoolName';
import {not} from '@src/util/predicateHelpers/not';
import {visitIsOnDemand} from '@src/util/visits/visitIsOnDemand';
import {AvailableVisitsOptions} from '@src/api/ProviderAPI';
import {useVisitCreateEvents, useVisitUpdateEvents} from '@src/hooks/socket';

export enum VisitsFilter {
  Owned = 'owned',
  Unclaimed = 'unclaimed',
}

export default function OnCallDashboard() {
  const api = useApi();
  const [user, userType] = useUser();
  const classes = useStyles();

  const currentUserId = user
    .getOptional()
    .map(u => u.id)
    .orElse(0 as UserId);

  const hairChecked = useSelector(({media}: ReduxState) => media.hairChecked);

  // If this id is 0, it means we aren't filtering by call pool but by visitsFilter.
  // Otherwise, show visits for the selected call pool.
  const [callPoolId, setCallPoolId] = useState<number>(0);
  const [visitsFilter, setVisitsFilter] = useState<VisitsFilter | null>(null);

  const [callPools] = useResources<CallPool>(
    () => api.provider(currentUserId).getGroups({memberOnly: true}),
    [api, currentUserId],
    {requestGate: () => currentUserId !== 0},
  );

  const loadVisitsWithFilter = useCallback(() => {
    let options: AvailableVisitsOptions = {};
    if (callPoolId > 0) {
      options = {callPoolId};
    } else {
      options = {filter: visitsFilter};
    }

    return getAllPages<LonelyVisit>(({start, pageSize}) =>
      api
        .provider(currentUserId)
        .getAvailableVisits({start, pageSize}, options),
    );
  }, [currentUserId, callPoolId, visitsFilter, api]);

  const [visits, reloadVisits, setVisits] = useResources<LonelyVisit>(
    loadVisitsWithFilter,
    [loadVisitsWithFilter],
    {requestGate: () => currentUserId !== 0 || !callPools.isLoaded()},
  );

  const visitList = visits.getAllOptional().orElse([]);

  const removeVisit = useCallback(
    (v: SimpleVisit) => setVisits(visitList.filter(viz => viz.id !== v.id)),
    [visitList, setVisits],
  );

  const updateVisit = useCallback(
    (v: LonelyVisit) =>
      setVisits(visitList.map(item => (item.id === v.id ? v : item))),
    [visitList, setVisits],
  );

  useVisitCreateEvents(reloadVisits);

  useVisitUpdateEvents(
    visitList.map(a => a.id),
    updateVisit,
  );

  useEffect(() => {
    reloadVisits();
  }, [reloadVisits]);

  const liveVisits = visits.filter(v => v.isLive);

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<{value: unknown}>) => {
      if (e.target.value === 'allVisits') {
        setCallPoolId(0);
        setVisitsFilter(null);
      } else if (
        [VisitsFilter.Owned, VisitsFilter.Unclaimed].includes(
          e.target.value as VisitsFilter,
        )
      ) {
        setCallPoolId(0);
        setVisitsFilter(e.target.value as VisitsFilter);
      } else {
        setCallPoolId(parseInt(e.target.value as string));
      }
    },
    [],
  );

  return (
    <PrivatePage>
      <DashboardContent>
        <PageHeader>Waiting Room</PageHeader>

        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.filterGrid}
          spacing={1}
        >
          <Grid item>Filter by: </Grid>

          <Grid item>
            <FormControl>
              <Select
                value={
                  callPoolId === 0 ? visitsFilter ?? 'allVisits' : callPoolId
                }
                onChange={onFilterChange}
                style={{fontSize: '1rem'}}
              >
                <MenuItem value={'allVisits'}>All</MenuItem>
                <MenuItem value={'owned'}>My Visits</MenuItem>
                <MenuItem value={'unclaimed'}>Need Provider</MenuItem>

                {callPools
                  ?.getAllOptional()
                  .orElse([])
                  .map(cp => (
                    <MenuItem key={cp.id} value={cp.id}>
                      {getCallPoolName(cp, userType)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid style={{marginBottom: '2rem'}}>
          <MuiHeading
            variant="h3"
            color="primary"
            weight="light"
            style={{marginBottom: '0.5rem'}}
          >
            On Demand Visits
          </MuiHeading>
          <VisitTable
            directToRoom={false}
            visits={liveVisits.filter(visitIsOnDemand)}
            hairChecked={hairChecked}
            columns={OnCallColumns}
            onCancel={removeVisit}
            onUpdate={updateVisit}
            returnText="Go back to Waiting Room"
          />
        </Grid>

        <Grid style={{marginBottom: '2rem'}}>
          <MuiHeading
            variant="h3"
            color="primary"
            weight="light"
            style={{marginBottom: '0.5rem'}}
          >
            Scheduled Visits
          </MuiHeading>
          <VisitTable
            directToRoom={false}
            visits={liveVisits.filter(not(visitIsOnDemand))}
            hairChecked={hairChecked}
            columns={ScheduledColumns}
            onCancel={removeVisit}
            onUpdate={updateVisit}
            returnText="Go back to Waiting Room"
          />
        </Grid>
      </DashboardContent>
    </PrivatePage>
  );
}
