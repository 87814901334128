import React from 'react';
import {Typography, Paper, Grid} from '@material-ui/core';
import {useStyles} from '../styles';
import {getLabel} from './SelectInsuranceStep';
import {md} from '@src/components/ui/theme';
import {PaymentInformationResult} from '@src/models/Patient';
import {isNothing} from '@src/util/typeTests';

type Props = {
  paymentInformationResult: PaymentInformationResult;
  excludeText?: boolean;
};

export function Result({paymentInformationResult, excludeText}: Props) {
  const classes = useStyles();
  const {primary, secondary} = paymentInformationResult;

  return (
    <Paper square elevation={0} className={classes.resetContainer}>
      {!excludeText && (
        <Typography style={{marginBottom: md}}>
          The Insurance Information section is ready to merge! Please review
          your selection below:
        </Typography>
      )}
      <Grid
        container
        direction="row"
        xs={12}
        item
        className={classes.selectionsContainer}
      >
        {!isNothing(primary) && (
          <Grid
            className={classes.resultInsuranceCardContainer}
            alignItems="center"
          >
            <Grid>{getLabel(primary)}</Grid>
            <Grid>
              <Typography variant="subtitle2" style={{textAlign: 'center'}}>
                (Primary)
              </Typography>
            </Grid>
          </Grid>
        )}
        {!isNothing(secondary) && (
          <Grid className={classes.resultInsuranceCardContainer}>
            <Grid>{getLabel(secondary)}</Grid>
            <Grid>
              <Typography variant="subtitle2" style={{textAlign: 'center'}}>
                (Secondary)
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
