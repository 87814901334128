import React, {useCallback, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {Files} from '@src/components/ui/organisms/uploaders/files/Folder/Files';
import {useStyles} from '@src/components/shared/patient/files/FilesOverview/util/styles';
import {AppFile} from '@src/models';

export function Folder({
  files,
  labels: {
    mainText: {primary, secondary},
  },
}: {
  files: readonly AppFile[];
  labels: {
    mainText: {
      primary: JSX.Element;
      secondary?: JSX.Element;
    };
  };
}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleFolderClick = useCallback(() => {
    setOpen(o => !o);
  }, [setOpen]);

  return (
    <>
      <ListItem button onClick={handleFolderClick}>
        <ListItemIcon>
          <FontAwesomeIcon
            icon={['fas', 'folder']}
            size="lg"
            className={classes.folderIcon}
          />
        </ListItemIcon>
        <ListItemText primary={primary} secondary={secondary} />
        {open ? (
          <FontAwesomeIcon icon={['fas', 'chevron-up']} />
        ) : (
          <FontAwesomeIcon icon={['fas', 'chevron-down']} />
        )}
      </ListItem>
      <Files open={open} files={files} />
    </>
  );
}
