import React, {useCallback, useEffect} from 'react';
import {useHistory} from 'react-router';
import {Grid} from '@material-ui/core';
import {Header} from '../VisitRequest/layout';
import {useApi} from '@src/api/useApi';
import {PrivatePage} from '@src/components/PrivatePage';
import {DashboardContent} from '@src/components/providerSide/PastVisitsDashboard/layout';
import {Button} from '@src/components/ui/form';
import {PageHeader} from '@src/components/ui/layout';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {
  RequestBox,
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {useAsync, useUser} from '@src/hooks';
import {Patient, PatientRelationship} from '@src/models';

type ButtonRowProps = {
  name: string;
  onClick: () => unknown;
};
function ButtonRow({name, onClick}: ButtonRowProps) {
  return (
    <Grid item container>
      <Grid item xs />
      <Grid item xs={4}>
        <Button fullWidth bStyle="primary" onClick={onClick}>
          {name}
        </Button>
      </Grid>
      <Grid item xs />
    </Grid>
  );
}

// eslint-disable-next-line import/no-unused-modules
export default function HereForAVisit() {
  const api = useApi();
  const history = useHistory();
  const [user] = useUser();
  const [patients, setPatients] = useAsync<Patient>();
  const [relationships, setRelationships] = useAsync<PatientRelationship>();
  const [
    selfRelationship,
    setSelfRelationship,
  ] = useAsync<PatientRelationship | null>();

  useEffect(() => {
    if (relationships.isAsked()) {
      return;
    }
    user.getOptional().ifPresent(u => {
      setRelationships();
      setSelfRelationship();
      setPatients();

      const gApi = api.guardian(u.id);

      gApi.getPatients().then(setPatients);
      gApi.getPatientRelationships().then(setRelationships);
      gApi.findSelfRelationship().then(setSelfRelationship);
    });
  }, [
    api,
    patients,
    setPatients,
    relationships,
    setRelationships,
    selfRelationship,
    setSelfRelationship,
    user,
  ]);

  const createForPatient = useCallback(
    (patientId: number) => () => {
      window.scrollTo(0, 0);
      history.push(`/visit-request/${patientId}`);
    },
    [history],
  );

  const getPatientName = useCallback(
    (patientId: number) => {
      const ptList = patients.getAllOptional().orElse([]);
      const pt = ptList.find(p => p.id === patientId);
      if (!pt) {
        return 'Please contact support';
      }
      return pt.preferredName;
    },
    [patients],
  );

  return (
    <PrivatePage>
      <PageLoading
        active={
          !(relationships.isLoaded() && user.isLoaded() && patients.isLoaded())
        }
        message="Loading patient information"
        fullSize
      >
        <DashboardContent>
          <Header>
            <PageHeader>I'm here for a Visit</PageHeader>
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </Header>
          <RequestBox>
            <RequestHeader>Who is the visit for?</RequestHeader>
            <RequestInstructions>
              Please select a patient from the list below.
            </RequestInstructions>
            <Grid container direction="column" alignItems="center" spacing={1}>
              {selfRelationship
                .getOptional()
                .map(r =>
                  r === null ? null : (
                    <ButtonRow
                      key={r.id}
                      name="Me"
                      onClick={createForPatient(r.patientId)}
                    />
                  ),
                )
                .orNull()}

              {relationships
                .getAllOptional()
                .orElse([])
                .map(r => (
                  <ButtonRow
                    key={r.id}
                    name={getPatientName(r.patientId)}
                    onClick={createForPatient(r.patientId)}
                  />
                ))}
            </Grid>
          </RequestBox>
        </DashboardContent>
      </PageLoading>
    </PrivatePage>
  );
}
