import React, {ReactElement, useEffect, useCallback} from 'react';
import {useSnackbar, OptionsObject} from 'notistack';
import styled from 'styled-components';
import {ApplicationEvents, sendEvent} from '../../../events';
import {useWindowEvent} from '@src/hooks';

const Closer = styled.span`
  cursor: pointer;
`;

export type ToastDetails =
  | {
      type: 'warning' | 'info' | 'error' | 'success';
      message: string | ReactElement<{}>;
      permanent: boolean;
      size: 'normal' | 'big';
    }
  | {
      type: 'clear';
      message: '';
    };

const notificationBacklog: ToastDetails[] = [];
const queueNotification = ((event: CustomEvent<ToastDetails>) => {
  const {detail} = event;
  notificationBacklog.push(detail);
}) as EventListener;

window.addEventListener(ApplicationEvents.Toast, queueNotification);

export function NotificationListener() {
  const {closeSnackbar, enqueueSnackbar} = useSnackbar();

  const handleEvent = useCallback(
    (event: CustomEvent<ToastDetails>) => {
      const {detail} = event;

      if (detail.type === 'clear') {
        closeSnackbar();
      } else {
        const {message, permanent = false} = detail;
        const key = Date.now();
        const options: OptionsObject = {
          key,
          variant: detail.type,
          persist: permanent,
          action: k => (
            <>
              <Closer
                onClick={() => {
                  closeSnackbar(k);
                }}
              >
                GOT IT
              </Closer>
            </>
          ),
        };

        enqueueSnackbar(message, options);
      }
    },
    [closeSnackbar, enqueueSnackbar],
  ) as EventListener;

  useWindowEvent(ApplicationEvents.Toast, handleEvent);

  useEffect(() => {
    window.removeEventListener(ApplicationEvents.Toast, queueNotification);

    sendEvent(ApplicationEvents.ReadyForNotifications);
    let details;
    while ((details = notificationBacklog.shift())) {
      sendEvent(ApplicationEvents.Toast, details);
    }

    return () => {
      window.addEventListener(ApplicationEvents.Toast, queueNotification);
    };
  }, [handleEvent]);

  return null;
}
