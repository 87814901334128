import {createStyles, makeStyles, Theme} from '@material-ui/core';
import styled from 'styled-components';
import {
  xxl,
  primary,
  md,
  grey,
  lighterGrey,
  xs,
  lg,
  sm,
} from '@src/components/ui/theme';

export const DashHead = styled.div`
  color: ${grey};
  display: flex;
  flex-direction: column;
  margin: ${xxl} 0 0;
`;

export const DashHeadWelcome = styled.div`
  font-size: 2rem;
  font-weight: bold;
`;

export const DashName = styled.span`
  color: ${primary};
`;

export const AdminToolsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 60%;
  margin-top: ${md};
  text-align: center;
  background: ${lighterGrey};
  border-radius: ${xs};
  box-sizing: border-box;
  line-height: 1.5em;
  padding: ${lg};
`;

export const AdminTool = styled.div`
  flex: 1;
  max-width: 30%;
  background-color: #fff;
  border-radius: ${xs};
  cursor: pointer;
  padding: ${md} 0;
`;

export const AdminToolIcon = styled.div`
  padding: ${md};

  display: block;
  margin: 0 auto;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionCard: {
      margin: `0 ${sm}`,
    },
  }),
);
