import React from 'react';
import {Room, RemoteParticipant} from 'twilio-video';
import _ from 'lodash';
import {Grid} from '@material-ui/core';
import {Button} from '../../../../ui/form/Button';
import {
  SetParticipants,
  formatAnonymousName,
  respondToJoinRequest,
} from '@src/util/videoChat';

type Props = {
  room: Room | null;
  waitingParticipant: RemoteParticipant;
  setWaitingParticipants: SetParticipants;
};

export function WaitingVisitorRow({
  waitingParticipant,
  setWaitingParticipants,
  room,
}: Props) {
  return (
    <Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        style={{marginBottom: '1rem', height: '2rem', lineHeight: '2rem'}}
      >
        <Grid item>{formatAnonymousName(waitingParticipant.identity)}</Grid>
        <Grid item>
          <Button
            isInRow={true}
            bSize="small"
            bStyle="outlined"
            onClick={() => {
              respondToJoinRequest({
                room,
                waitingParticipant,
                response: 'deny',
                setWaitingParticipants,
              });
            }}
          >
            Deny entry
          </Button>
          <Button
            isInRow={true}
            bStyle="primary"
            bSize="small"
            onClick={() => {
              respondToJoinRequest({
                room,
                waitingParticipant,
                response: 'admit',
                setWaitingParticipants,
              });
            }}
          >
            Admit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
