import React, {useCallback} from 'react';
import {Grid} from '@material-ui/core';
import {CallPool, OnCallPeriod} from '@src/models';
import {
  flashSuccess,
  flashError,
} from '@src/components/shared/notifications/flash';
import {getProviderDescription} from '@src/util/provider';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {ConfirmModal} from '@src/components/ui/layout/ConfirmModal';
import {HorizontalSection} from '@src/components/ui/atoms/section/HorizontalSection';
import {usePageContext} from '@src/components/providerSide/providerGroups/ProviderGroupRosterPage';
import {getProvidersNotAuthorizedForGroups} from '@src/util/callPools/shifts/providerGroupAuthorization';
import {isSomething} from '@src/util/typeTests';
import {AvatarList} from '@src/components/ui/molecules/lists/AvatarList';
import {useRemoveGroup} from '@src/hooks/providerGroups/periods/useRemoveGroup';

type Props = {
  groupToRemove: CallPool;
  period: OnCallPeriod;
  setGroupToRemove: (id: CallPool | null) => unknown;
};

export function RemoveGroup({groupToRemove, period, setGroupToRemove}: Props) {
  const {
    monthTable: {reloadPeriodsMappedToDaysOfSelectedMonth},
  } = usePageContext();

  const currentGroups = period.callPools;
  const proposedGroups = currentGroups.filter(cp => cp.id !== groupToRemove.id);

  const notAuthorized = getProvidersNotAuthorizedForGroups(
    proposedGroups,
    period,
  );

  const clearGroupToRemove = useCallback(() => setGroupToRemove(null), [
    setGroupToRemove,
  ]);

  const removeGroup = useRemoveGroup(period, groupToRemove.id);

  const onRemoveGroup = useCallback(() => {
    return removeGroup()
      .then(clearGroupToRemove)
      .then(reloadPeriodsMappedToDaysOfSelectedMonth)
      .then(() => {
        flashSuccess('Group removed');
      })
      .catch(() => {
        flashError(
          'Oops! Something went wrong update Providers for this On-Call Period',
        );
      })
      .finally(() => {
        setGroupToRemove(null);
      });
  }, [
    removeGroup,
    clearGroupToRemove,
    reloadPeriodsMappedToDaysOfSelectedMonth,
    setGroupToRemove,
  ]);

  const aUnauthorizedProviderDetails = notAuthorized
    .map(pd => pd.orNull())
    .filter(isSomething);

  const namedIndividuals = aUnauthorizedProviderDetails.map(pd => {
    return {namedIndividual: pd, description: getProviderDescription(pd)};
  });

  return (
    <ConfirmModal
      show={groupToRemove !== null}
      confirmText="Submit"
      onCancel={clearGroupToRemove}
      onHide={clearGroupToRemove}
      onConfirm={async () => {
        await onRemoveGroup();
      }}
      text=""
    >
      {notAuthorized.length > 0 && (
        <Grid container direction="row">
          <HorizontalSection>
            <Grid item xs={12}>
              <ParagraphText>
                If this group is deleted, the following Professionals will no
                longer be associated with any of this Shift's Groups and{' '}
                <Bold>will be removed from the Shift</Bold>.
              </ParagraphText>
            </Grid>
          </HorizontalSection>
          <HorizontalSection equalTopBottomMargins>
            <AvatarList namedIndividuals={namedIndividuals} />
          </HorizontalSection>
        </Grid>
      )}
      <HorizontalSection lastSection>
        <ParagraphText>Are you sure you would like to proceed?</ParagraphText>
      </HorizontalSection>
    </ConfirmModal>
  );
}
