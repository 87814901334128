import {makeStyles} from '@material-ui/core';
import {sm} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  rowContainer: {
    width: '100%',
    paddingLeft: '1em',
    paddingRight: '1em',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    marginBottom: '1.2rem',
  },
  condensedContainer: {
    width: '100%',
    paddingLeft: '1em',
    paddingRight: '1em',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 16,
    color: 'rgba(255,255,255,0.5)',
    marginBottom: '1rem',
  },
  name: {
    fontWeight: 700,
    lineHeight: 1.3,
  },
  text: {
    color: theme.palette.secondary.contrastText,
    lineHeight: 1.3,
    marginBottom: sm,
    textAlign: 'center',
    width: '100%',
  },
  condensedText: {
    color: theme.palette.secondary.contrastText,
    textAlign: 'left',
    width: '100%',
  },
  condensedAvatar: {
    margin: 0,
  },
  avatarGrid: {
    minWidth: '45px',
  },
  dark: {
    color: theme.palette.common.black,
  },
  icon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 24,
    marginLeft: 'auto',
  },
}));
