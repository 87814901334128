interface Named {
  firstName?: string | null;
  lastName?: string | null;
}
export const byName = (a: Named, b: Named) => {
  const byLastName = (a.lastName ?? '').localeCompare(b.lastName ?? '');
  if (byLastName === 0) {
    return (a.firstName ?? '').localeCompare(b.firstName ?? '');
  }
  return byLastName;
};
