import {makeStyles} from '@material-ui/core/styles';

export const useComponentStyles = makeStyles(theme => ({
  cardContainer: {
    borderRadius: '3px',
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
    margin: '1rem auto',
  },
  detail: {
    fontSize: 16,
    fontWeight: 300,
    paddingLeft: 0,
  },
  name: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));
