import React, {useCallback, useState} from 'react';
import {CallPoolsMultiselect} from '../../ui/organisms/selectors/CallPoolsMultiselect';
import {useApi} from '@src/api/useApi';
import {AsyncActionButton, Button} from '@src/components/ui/form';
import {Instructions} from '@src/components/ui/layout';
import {Modal} from '@src/components/ui/layout/Modal';
import {CallPool} from '@src/models';
import {difference} from '@src/util/arrayManipulation/operations';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {useAllGroups} from '@src/hooks/providerGroups/useAllGroups';

type Props = {
  callPoolList?: CallPool[];
  detailsId: ProviderDetailsId;
  show: boolean;
  onClose: () => unknown;
  onUpdated: () => unknown;
};
export function AddToCallPoolModal({
  callPoolList = [],
  detailsId,
  show,
  onClose,
  onUpdated,
}: Props) {
  const [selectedIds, setSelectedIds] = useState(callPoolList.map(c => c.id));
  const [updating, setUpdating] = useState(false);
  const api = useApi();
  const [allGroups] = useAllGroups({});

  const saveCallPools = useCallback(async () => {
    setUpdating(true);
    const originalIds = callPoolList.map(cp => cp.id);

    const toAdd = difference(selectedIds, originalIds);
    const toRemove = difference(originalIds, selectedIds);

    const adding = Promise.all(
      toAdd.map(cpId => api.callPool(cpId).addMember(detailsId)),
    );
    const removing = Promise.all(
      toRemove.map(cpId => api.callPool(cpId).removeMember(detailsId)),
    );
    await Promise.all([adding, removing]);

    setUpdating(true);
    onUpdated();
  }, [api, callPoolList, onUpdated, detailsId, selectedIds]);

  return (
    <Modal
      show={show}
      title="Add to Call Pools"
      onClose={onClose}
      modalActions={
        <>
          <AsyncActionButton
            actionInProgress={updating}
            actionWord="Save"
            bStyle="outlined"
            onClick={saveCallPools}
          />

          <Button bStyle="outlined" onClick={onClose}>
            Cancel
          </Button>
        </>
      }
    >
      <Instructions>
        <p>Select the Call Pools you want.</p>
        <p>Remove the ones you don't.</p>
        <p>You can type to search or just scroll through.</p>
      </Instructions>
      <CallPoolsMultiselect
        selectedIds={selectedIds}
        onUpdateSelection={setSelectedIds}
        callPools={allGroups}
      />
    </Modal>
  );
}
