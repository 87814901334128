import {PatientRelationship} from '@src/models';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

type IsUserGuardianProps = {
  relationship: PatientRelationship;
  userPersonId: NonProfessionalId;
};

type IsUserRelationshipPersonProps = IsUserGuardianProps;
type IsUserIndependentPatientProps = IsUserGuardianProps;

export function isUserRelationshipPerson({
  relationship,
  userPersonId,
}: IsUserRelationshipPersonProps) {
  return relationship.personId === userPersonId;
}

/**
   * TRUTH TABLE
   * 
   * userIsGuardian
   * 
   * isRemovingSelf  | isGuardianRelationship    |  comments
   * ___________________________________________________________________
   * true            | true                      |  current user is guardian removing self
   * true            | false                     |  is a bug IF isSelf is also false
   * false           | false                     |  bug (component shouldn't have rendered) 
   * false           | true                      |  current user is Guardian or Provider removing a Guardian from patient

   */

export function isUserGuardian({
  relationship,
  userPersonId,
}: IsUserGuardianProps) {
  const isRemovingSelf = isUserRelationshipPerson({
    relationship,
    userPersonId,
  });
  const isGuardianRelationship = relationship.isGuardian;

  return isRemovingSelf && isGuardianRelationship;
}

/**
 * TRUTH TABLE
 *
 * userIsIndependentPatient
 *
 * isRemovingSelf  | isIndependentRelationship |  comments
 * ___________________________________________________________________
 * true            | true                      |  current user is independent patient, removing self
 * true            | false                     |  is a bug IF isGuardian is also false
 * false           | false                     |  is a bug IF isGuardian is also false
 * false           | true                      |  Bug. Component shouldn't have rendered. Providers can't unlink Independent Patients.
 */

export function isUserIndependentPatient({
  relationship,
  userPersonId,
}: IsUserIndependentPatientProps) {
  const isRemovingSelf = isUserRelationshipPerson({
    relationship,
    userPersonId,
  });

  const isIndependentRelationship = relationship.isSelf;

  return isRemovingSelf && isIndependentRelationship;
}
