import {makeStyles} from '@material-ui/styles';

import {md, sm, xs} from '@src/components/ui/theme';

export const useStyles = makeStyles({
  contactCard: {
    marginTop: xs,
  },
  divider: {
    marginTop: md,
    marginBottom: sm,
  },
  heading: {
    marginBottom: md,
  },
  section: {
    marginBottom: md,
  },
});
