import React from 'react';
import {Avatar, Grid} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {useStyles} from './layout';
import {PatientFundamental} from './PatientFundamental';
import {UserAvatar} from '@src/components/ui/layout/UserAvatar';
import {LonelyPatient} from '@src/models';
import asYearsOrMonths from '@src/util/dateManipulation/asYearsOrMonths';

type Props = {
  patient?: LonelyPatient;
  color?: 'dark' | 'light';
};

export function CondensedFundamentals({patient, color}: Props) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.condensedContainer}
      container
      justify="flex-start"
      direction="row"
      alignItems="center"
    >
      <Grid item xs={1} className={classes.avatarGrid}>
        {patient ? (
          <UserAvatar user={patient!} condensed />
        ) : (
          <Skeleton variant="circle">
            <Avatar className={classes.condensedAvatar} />
          </Skeleton>
        )}
      </Grid>
      <Grid item xs>
        <PatientFundamental
          condensed
          variant="h2"
          color={color}
          patient={patient}
          renderer={pt =>
            `${pt.preferredFirstName} ${pt.lastName}, ${asYearsOrMonths(
              pt.dob,
              {
                shortened: true,
              },
            )}`
          }
        />
      </Grid>
    </Grid>
  );
}
