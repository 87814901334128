import React, {ReactNode} from 'react';

// Twilio
import {Room} from 'twilio-video';

// Layout
import {Grid, List, ListItem} from '@material-ui/core';
import {useStyles} from '../layout';
import {SIDEBAR_WIDTH} from '../../VideoChat/layout';
import {useVideoStyles} from '@src/components/ui/layout/VideoStyles';

type VideoGalleryProps = {
  mainScreenNode: ReactNode;
  room: Room | null;
  remoteParticipants: JSX.Element[];
  localParticipant: JSX.Element;
  hide: boolean;
};

export function SidebarVideoGallery({
  mainScreenNode,
  room,
  remoteParticipants,
  localParticipant,
  hide,
}: VideoGalleryProps) {
  const videoClasses = useVideoStyles();

  const classes = useStyles({
    width: SIDEBAR_WIDTH,
  });

  if (!room) {
    return null;
  }

  return (
    <Grid container direction="row">
      <Grid
        item
        xs={12}
        md={10}
        className={videoClasses.mainScreenContainer}
        id="main-screen-container"
      >
        {mainScreenNode}
      </Grid>
      <Grid item md={2} id="sidebar-gallery">
        <List
          style={{
            position: 'fixed',
            top: '0',
            flex: '1 0 auto',
            height: '100%',
            display: 'flex',
            outline: '0',
            overflowY: 'auto',
            flexDirection: 'column',
            direction: 'rtl',
          }}
        >
          <div className={classes.toolbar} />

          <ListItem>{localParticipant}</ListItem>
          {remoteParticipants.map((p, i) => (
            <ListItem key={i}>{p}</ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
