import {RemoteDataStatus} from '@ahanapediatrics/ahana-fp';
import {UserAttributes} from '@optimizely/optimizely-sdk';
import {
  createInstance,
  OptimizelyProvider,
  ReactSDKClient,
} from '@optimizely/react-sdk';
import React, {useEffect, useState, useCallback, lazy, Suspense} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {addBreadcrumb, Severity} from '@sentry/browser';
import {Route} from 'react-router';
import ConfigService from '../../ConfigService';
import * as actions from '../../store/actions';
import FirstTime from '../shared/FirstTime';
import {TimeoutModal} from './../shared/TimeoutModal';
import {LoadingApp} from './LoadingApp';
import {SocketContextProvider} from '@src/contexts';
import {isVideoChatPath} from '@src/util/videoChat';
import {AppConfig} from '@src/store/reducers/configuration';
import {ReduxState} from '@src/store';
import {LegalDocument, UserType} from '@src/models';
import {
  ignoreTimeout,
  useAsync,
  useInactivityTracker,
  useUser,
  useWindowEvent,
} from '@src/hooks';
import {ApplicationEvents} from '@src/events';
import {useApi} from '@src/api/useApi';
import {lastName, email} from '@src/util/users/getDemographics';
import {SessionExpiredModal} from '@src/components/shared/SessionExpiredModal';

const RoutedApp = lazy(() => import('./RoutedApp'));
const AdminApp = lazy(() => import('./SeparateAdminApp'));

type OptimizelyUser = {
  id: string;
  attributes?: UserAttributes;
};

export function PrivateApp() {
  const api = useApi();
  const [user, userType, , , isAnonymous] = useUser();
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);

  const [optimizely, setOptimizely] = useState<ReactSDKClient | null>(null);
  const [requiredDocs, setRequiredDocs] = useAsync<LegalDocument>();
  const [optimizelyUser, setOptimizelyUser] = useState<
    OptimizelyUser | null | undefined
  >(null);

  useWindowEvent(ApplicationEvents.GetAccessTokenFailed, () => {
    if (!showSessionExpiredModal) {
      setShowSessionExpiredModal(true);
    }
  });

  const handleTimeout = () => {
    setShowTimeoutModal(
      !(
        ignoreTimeout(window.location.pathname) ||
        userType === UserType.Guardian
      ),
    );
  };

  useInactivityTracker(handleTimeout);

  const configuration = useSelector((state: ReduxState) => state.configuration);

  const dispatch = useDispatch();
  const setConfig = (c: AppConfig) => dispatch(actions.setConfig(c));

  useEffect(() => {
    if (configuration.is(RemoteDataStatus.NotAsked)) {
      dispatch(actions.setLoadingConfig());
      api.getConfiguration().then(setConfig);
    }
  });

  useEffect(() => {
    const configService = ConfigService.getEnvironmentInstance();
    configService.get('OPTIMIZELY_KEY').then((sdkKey: string | undefined) => {
      setOptimizely(
        createInstance({
          sdkKey,
        }),
      );
    });
  }, []);

  useEffect(() => {
    if (
      !configuration.isLoaded() ||
      !requiredDocs.is(RemoteDataStatus.NotAsked) ||
      (userType === UserType.Unknown && !isVideoChatPath())
    ) {
      return;
    }

    const oUser = user.getOptional();

    if (oUser.isPresent()) {
      if (userType === UserType.Admin) {
        setRequiredDocs([]);
      } else {
        const docIds = configuration
          .getOptional()
          .property(
            userType === UserType.Guardian
              ? 'guardianConsentDocs'
              : 'providerConsentDocs',
            [],
          );

        setRequiredDocs();
        api
          .legalDocument()
          .query(docIds)
          .then(setRequiredDocs);
      }
    } else if (isAnonymous) {
      setRequiredDocs([]);
      setOptimizelyUser(undefined);
    }
  }, [
    api,
    setRequiredDocs,
    configuration,
    user,
    requiredDocs,
    userType,
    isAnonymous,
  ]);

  useEffect(() => {
    user.getOptional().ifPresent(u => {
      setOptimizelyUser({
        id: `${u.id}`,
        attributes: {
          email: email(u).orElse('unknown@example.com'),
          userType: u.userType,
          development: ConfigService.environmentIs('local', 'staging'),
        },
      });
    });
  }, [user]);

  const onCloseSessionExpiredModal = useCallback(() => {
    setShowSessionExpiredModal(false);
  }, [setShowSessionExpiredModal]);

  const onCloseTimeoutModal = useCallback(() => {
    setShowTimeoutModal(false);
  }, [setShowTimeoutModal]);

  const currentSignatures = user
    .getOptional()
    .map(u => u.signatures)
    .orElse([]);
  const unsignedDocuments = requiredDocs.filter(d => {
    return !currentSignatures.some(sig => sig.document.id === d.id);
  });

  addBreadcrumb({
    category: 'private-app',
    message: 'PrivateApp boot up',
    data: {
      isAnonymous,
      optimizely: !!optimizely ? 1 : 0,
      optimizelyUser: optimizelyUser === null ? 1 : 0,
      unsignedDocuments,
      user,
    },
    level: Severity.Info,
  });

  const isAdmin = userType === UserType.Admin;

  if (!optimizely || optimizelyUser === null) {
    console.log('Nothing to show because optimizely not loaded');
    return <LoadingApp message="Checking configuration..." />;
  }

  if (!isAdmin && !unsignedDocuments.isLoaded()) {
    console.log('Nothing to show because unsigned docs not loaded');
    return <LoadingApp message="Checking documentation..." />;
  }

  if ((!user.isLoaded() || !unsignedDocuments.isLoaded()) && !isAnonymous) {
    return <LoadingApp message="Checking authentication..." />;
  }

  const unsignedDocs = unsignedDocuments.getAllOptional().orElse([]);
  const docsToSign = unsignedDocs.length !== 0;
  const noLastName =
    user
      .getOptional()
      .map(lastName)
      .orElse('')
      .trim().length === 0;

  if ((docsToSign || noLastName) && !(isAnonymous || isAdmin)) {
    return <Route component={FirstTime} />;
  }

  return (
    <OptimizelyProvider optimizely={optimizely} user={optimizelyUser}>
      <Suspense fallback={<LoadingApp message="Loading app..." />}>
        {userType === UserType.Admin ? (
          <AdminApp />
        ) : (
          <SocketContextProvider>
            <RoutedApp />
          </SocketContextProvider>
        )}
      </Suspense>

      <TimeoutModal
        show={showTimeoutModal}
        continueWorking={onCloseTimeoutModal}
      />

      <SessionExpiredModal
        show={showSessionExpiredModal}
        onClose={onCloseSessionExpiredModal}
      />
    </OptimizelyProvider>
  );
}
