import {RadioSelection, MedicalHistoryResult} from '../resultFunctions';

export const handleRadioButtonClick = ({
  event,
  radioSelection,
  setResult,
  result,
}: {
  event: React.ChangeEvent<HTMLInputElement>;
  radioSelection: RadioSelection;
  setResult: (r: MedicalHistoryResult) => unknown;
  result: MedicalHistoryResult;
}) => {
  setResult({
    ...result,
    [event.target.name]: {
      text: event.target.value,
      radioSelection,
    },
  });
};
