import {Optional} from '@ahanapediatrics/ahana-fp';
import {PaymentDetailsForm} from '../PaymentDetails';
import {InsuranceType, Patient} from '@src/models';
import {RefFunction} from '@src/util/stringManipulation/languageHelpers';
import {useReference} from '@src/hooks/useReference';
import {useConjugate} from '@src/hooks/useConjugate';

const buildInsuranceInfo = (
  summary: string[][],
  details: PaymentDetailsForm,
  patientRef: RefFunction,
) => {
  const {hasMedicaid, insuranceGroup, insuranceId, insuranceProvider} = details;
  summary.push([
    `${patientRef('Poss')} insurance comes from ${insuranceProvider}.`,
  ]);

  const insuranceRow = [];
  const id = hasMedicaid ? 'Member Number' : 'ID';
  insuranceRow.push(
    `${patientRef('Poss', {alternate: true})} ${id} is ${
      insuranceId ? insuranceId : 'unknown'
    }`,
  );

  if (!hasMedicaid) {
    insuranceRow.push(
      ` and ${patientRef('poss', {
        alternate: true,
      })} group is ${insuranceGroup || 'unknown'}`,
    );
  }
  insuranceRow.push('.');
  summary.push(insuranceRow);
};

export const useLogic = (patient: Optional<Patient>) => {
  const ref = useReference(patient);
  const c = useConjugate(patient);

  return (details: PaymentDetailsForm, type: InsuranceType) => {
    const summary: string[][] = [];
    const {
      hasHealthInsurance,
      insuranceId,
      insuranceProvider,
      hasInsuranceAndUnknownDetails,
    } = details;

    if (hasInsuranceAndUnknownDetails) {
      summary.push([
        `${ref('Nom')} ${c('do')} have insurance but ${c(
          'have',
        )} not provided specific details about it.`,
      ]);
    } else if (!hasHealthInsurance) {
      summary.push([`${ref('Nom')} ${c('do')} not have ${type} insurance.`]);
    } else {
      if (insuranceProvider) {
        buildInsuranceInfo(summary, details, ref);
      }

      if (!insuranceId) {
        summary.push([
          `We need more information about ${ref(
            'poss',
          )}} insurance provider before we can proceed.`,
        ]);
      }
    }
    return summary;
  };
};
