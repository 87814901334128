import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import {StepProps} from '../../functions';
import {ProviderInfoForm, ProfessionalBasics, schema} from './ProviderInfoForm';
import {FlowProps} from '@src/hooks/useSteps';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {canonicalizeNumber} from '@src/util/numberManipulation/phone/canonicalizeNumber';

type Props = FlowProps & StepProps;

export function ProfessionalInfoStep({
  moveGuard,
  userBasics,
  setUserBasics,
  setNextAvailable,
}: Props) {
  const {formState, control, errors, handleSubmit, setValue, watch} = useForm<
    ProfessionalBasics
  >({
    defaultValues: userBasics,
    resolver: yupResolver(schema),
  });

  const allFields = watch();

  useEffect(() => {
    setNextAvailable(
      allFields.firstName.trim().length > 0 &&
        allFields.lastName.trim().length > 0 &&
        (!allFields.seesPatients ||
          canonicalizeNumber(allFields.phone.trim()).length === 10 ||
          allFields.phone.trim().length === 0) &&
        (!allFields.seesPatients || allFields.qualification.trim().length > 0),
    );
  }, [allFields, setNextAvailable]);

  moveGuard.current = () =>
    new Promise(res => {
      handleSubmit(
        data => {
          setUserBasics(data);
          res(true);
        },
        () => {
          res(false);
        },
      )();
    });

  return (
    <>
      <RequestHeader>Your Details</RequestHeader>
      <RequestInstructions>
        <p>Please confirm your details below.</p>
        <p>
          You only need to provide a phone number if you intend to take
          on-demand visit request. In this case, please provide a number that
          can receive SMS messages.
        </p>
      </RequestInstructions>
      <ProviderInfoForm
        formState={formState}
        seesPatientsState={allFields.seesPatients}
        errors={errors}
        control={control}
        setValue={setValue}
      />
    </>
  );
}
