import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {ChangeEventHandler} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {PickProperties} from 'ts-essentials';
import {ProviderInfoFormStyled} from './layout';
import {JSONType} from '@src/app-types';
import {PhoneInput, TextInput} from '@src/components/ui/form';
import {Banner} from '@src/components/ui/layout/Banner';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {ProviderDetails, UserType, Practice} from '@src/models';
import {getEmptyProviderDetails} from '@src/models/ProviderDetails';
import {$str, Processor, $opt} from '@src/models/ResponseParser';
import {Context, FormMode} from '@src/util/provider/forms/addOrEdit';

type Props = {
  isSharedCarePlan?: boolean;
  onChange: (pcpInfo: Optional<ProviderDetails>, type: FormMode) => void;
  details: Optional<ProviderDetails>;
  userType: UserType;
  selectionOrEditContext: Context;
};

type ProviderInfoValues = {
  firstName: string;
  lastName: string;
  speciality: string;
  qualification: string;
  email: string;
  practiceName: string;
  practicePhone: string;
  practiceFax: string;
};

const pcpInfoToValues = (
  pcp: Optional<ProviderDetails>,
): ProviderInfoValues => {
  const practice = pcp.flatMap(p => p.practice);

  return {
    firstName: pcp.property('firstName', ''),
    lastName: pcp.property('lastName', ''),
    speciality: pcp.property('speciality', ''),
    qualification: pcp.property('qualification', ''),
    email: pcp.map(p => p.email).orElse(''),
    practiceName: practice.property('name', ''),
    practicePhone: practice.property('phone', ''),
    practiceFax: practice.property('fax', ''),
  };
};

type PracticeKey = keyof Pick<Practice, 'name' | 'fax' | 'phone'>;
let PRACTICE_KEYS: Record<
  PracticeKey,
  keyof PickProperties<ProviderDetails, string | null>
> = {
  name: 'practiceName',
  fax: 'practiceFax',
  phone: 'practicePhone',
};

const isPracticeKey = (k: string | PracticeKey): k is PracticeKey =>
  k in PRACTICE_KEYS;

export function ManualEntry({
  details,
  onChange,
  userType,
  selectionOrEditContext,
}: Props) {
  const {errors, formState, control} = useForm<ProviderInfoValues>();

  const {
    firstName,
    lastName,
    speciality,
    qualification,
    email,
    practiceName,
    practicePhone,
  } = pcpInfoToValues(details);

  const buildProviderHandler = <T extends {}>(
    k: keyof ProviderDetails,
    pr: Processor<T>,
  ): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => e => {
    const old = details.orElseGet(getEmptyProviderDetails);
    const value = e.target.value as JSONType<T>;

    const newPcpInfo = new ProviderDetails({
      ...old,
      [k]: pr(value),
    });

    onChange(Optional.of(newPcpInfo), FormMode.ManualEntry);
  };

  const buildPracticeEventHandler = (
    k: keyof Practice,
  ): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => e => {
    const stringHandler = buildPracticeStringHandler(k);
    stringHandler(e.target.value);
  };

  const buildPracticeStringHandler = (k: keyof Practice) => (value: string) => {
    const old = details.orElseGet(getEmptyProviderDetails);

    const newDetails = new ProviderDetails({
      ...old,
    });

    let newPractice = old.practice;
    if (old.practice.isPresent()) {
      const newP = newPractice.get();
      newPractice = Optional.of(
        new Practice({
          ...newP,
          [k]: value,
        }),
      );
    } else {
      if (isPracticeKey(k)) {
        const practiceKey = PRACTICE_KEYS[k];
        newDetails[practiceKey!] = value;
      }
    }

    const newPcpInfo = new ProviderDetails({
      ...newDetails,
      practice: newPractice,
    });

    onChange(Optional.of(newPcpInfo), FormMode.ManualEntry);
  };

  const searchable = details.map(p => p.searchable).orElse(false);

  const {touched} = formState;

  const handlers = {
    firstName: buildProviderHandler('firstName', $str),
    lastName: buildProviderHandler('lastName', $str),
    speciality: buildProviderHandler('speciality', $str),
    qualification: buildProviderHandler('qualification', $str),
    email: buildProviderHandler('email', $opt($str)),
    practiceName: buildPracticeEventHandler('name'),
    practicePhone: buildPracticeStringHandler('phone'),
    practiceFax: buildPracticeStringHandler('fax'),
  };

  return (
    <ProviderInfoFormStyled>
      <ParagraphText
        hidden={selectionOrEditContext === Context.SharedCarePlanOwner}
        style={{marginBottom: '1rem'}}
      >
        Please enter the doctor's information here.
      </ParagraphText>
      <Controller
        control={control}
        required
        disabled={searchable}
        error={errors.firstName?.message}
        touched={touched.firstName}
        defaultValue={firstName || ''}
        name="firstName"
        render={props => (
          <TextInput
            name="firstName"
            required
            disabled={searchable}
            onChange={value => {
              props.onChange(value);
              handlers['firstName'](value);
            }}
            title="Doctor's first name"
            value={props.value}
          />
        )}
      />

      <Controller
        control={control}
        required
        disabled={searchable}
        error={errors.lastName?.message}
        touched={touched.lastName}
        defaultValue={lastName || ''}
        name="lastName"
        render={props => (
          <TextInput
            name="lastName"
            required
            disabled={searchable}
            onChange={value => {
              props.onChange(value);
              handlers['lastName'](value);
            }}
            title="Doctor's last name"
            value={props.value}
          />
        )}
      />
      <Controller
        control={control}
        required
        disabled={searchable}
        error={errors.speciality?.message}
        touched={touched.speciality}
        defaultValue={speciality || ''}
        name="speciality"
        render={props => (
          <TextInput
            name="speciality"
            disabled={searchable}
            onChange={value => {
              props.onChange(value);
              handlers['speciality'](value);
            }}
            title="Doctor's speciality"
            value={props.value}
          />
        )}
      />

      <Controller
        control={control}
        required
        disabled={searchable}
        error={errors.qualification?.message}
        touched={touched.qualification}
        defaultValue={qualification || ''}
        name="qualification"
        render={props => (
          <TextInput
            name="qualification"
            disabled={searchable}
            onChange={value => {
              props.onChange(value);
              handlers['qualification'](value);
            }}
            title="Doctor's qualification"
            value={props.value}
          />
        )}
      />

      {userType !== UserType.Guardian && (
        <Controller
          control={control}
          required
          disabled={searchable}
          error={errors.email?.message}
          touched={touched.email}
          defaultValue={email || ''}
          name="email"
          render={props => (
            <TextInput
              name="email"
              disabled={searchable}
              onChange={value => {
                props.onChange(value);
                handlers['email'](value);
              }}
              title="Doctor's email"
              value={props.value}
            />
          )}
        />
      )}

      <Controller
        control={control}
        required
        disabled={searchable}
        error={errors.practiceName?.message}
        touched={touched.practiceName}
        defaultValue={practiceName || ''}
        name="practiceName"
        render={props => (
          <TextInput
            name="practiceName"
            disabled={searchable}
            onChange={value => {
              props.onChange(value);
              handlers['practiceName'](value);
            }}
            title="Doctor's practice name"
            value={props.value}
          />
        )}
      />

      <Controller
        control={control}
        disabled={searchable}
        error={errors.practicePhone?.message}
        touched={touched.practicePhone}
        defaultValue={practicePhone || ''}
        name="practicePhone"
        render={props => (
          <PhoneInput
            name="practicePhone"
            disabled={searchable}
            onChange={value => {
              props.value = value;
              handlers['practicePhone'](value);
            }}
            title="Doctor's practice phone"
            value={details
              ?.map(p => p.practice)
              .map(practice => practice.phone)
              .orElse('')}
          />
        )}
      />

      {selectionOrEditContext === Context.SharedCarePlanOwner &&
        userType === UserType.Guardian && (
          <Banner type="warning">
            The doctor you want to select as the owner of this shared care plan
            may not yet be a Refyne Connected Care user. You can continue
            creating and editing the plan, but it will be in a draft state until
            the doctor joins Refyne Connected Care.
          </Banner>
        )}
    </ProviderInfoFormStyled>
  );
}
