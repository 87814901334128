import React from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useHistory} from 'react-router';
import {Grid} from '@material-ui/core';
import {PatientOverviewHeader} from '../layout';
import {getActionButtons} from './getActionButtons';
import {VisitsSection} from './VisitsSection';
import {useUser} from '@src/hooks';
import {LonelyVisit, UserType, LonelyPatient} from '@src/models';

type Props = {
  hairChecked: Record<number, boolean>;
  inExam?: boolean;
  patient: LonelyPatient;
  setVisits: (v: LonelyVisit | readonly LonelyVisit[]) => unknown;
  visits: AsyncData<LonelyVisit>;
};

export function ProviderOnlySections({
  hairChecked,
  inExam = false,
  patient,
  setVisits,
  visits,
}: Props) {
  const [, userType] = useUser();
  const history = useHistory();

  const actions = getActionButtons({
    visits,
    patient,
    history,
  });

  return (
    <>
      {userType === UserType.Professional && !inExam && (
        <PatientOverviewHeader>
          <Grid container direction="row" spacing={1}>
            {actions.map((action, index) => (
              <Grid item key={index}>
                {action}
              </Grid>
            ))}
          </Grid>
        </PatientOverviewHeader>
      )}

      {userType === UserType.Professional && (
        <VisitsSection
          hairChecked={hairChecked}
          visits={visits}
          setVisits={setVisits}
          inExam={inExam}
        />
      )}
    </>
  );
}
