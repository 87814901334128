import React from 'react';
import {Grid} from '@material-ui/core';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {Button} from '@src/components/ui/form';
import {isEmpty} from '@src/util/typeTests';
import {md} from '@src/components/ui/theme';
import {LightParagraphText} from '@src/components/ui/layout/text/body/LightParagraphText';

export function RequestButtonMessageGroup({
  currentValue,
  canEdit,
  handleEditButtonClicked,
  userOwnsNewestChange,
}: {
  currentValue: string;
  canEdit: boolean;
  handleEditButtonClicked: () => unknown;
  userOwnsNewestChange: boolean;
}) {
  const {isOwner, isUserPartner} = usePageContext();

  const showShowEditButton =
    isOwner || isUserPartner || (!userOwnsNewestChange && canEdit);

  const showEmptyMessage = isEmpty(currentValue);

  return (
    <Grid container direction="row">
      {showEmptyMessage && (
        <Grid item xs={12} style={{marginTop: md}}>
          <LightParagraphText>No information yet.</LightParagraphText>
        </Grid>
      )}

      {showShowEditButton && (
        <Grid item xs={12} style={{marginTop: md}}>
          <EditButton onClick={handleEditButtonClicked} isOwner={isOwner} />
        </Grid>
      )}
    </Grid>
  );
}

function EditButton({
  onClick,
  isOwner,
}: {
  onClick: () => void;
  isOwner: boolean;
}) {
  return (
    <Grid item>
      <Button bStyle="outlined" bSize="small" onClick={onClick}>
        {isOwner ? 'Make Edit' : 'Suggest Change'}
      </Button>
    </Grid>
  );
}
