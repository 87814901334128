import {Optional} from '@ahanapediatrics/ahana-fp';
import {
  $NewPatient,
  LonelyPatient,
  ResponsiblePerson,
  ProviderDetails,
} from '@src/models';
import {AppAPI} from '@src/api/AppAPI';
import {
  valuesToIndependentPatientInfo,
  IndependentPatientValues,
  LinkPatientToPersonRequest,
} from '@src/util/relationships/responsiblePerson';
import {DetailsRequest} from '@src/api/ProviderDetailsAPI';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {UserId} from '@src/models/User';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

const handleProviderInfo = (api: AppAPI) => async (
  newPatient: LonelyPatient,
  details: Optional<DetailsRequest>,
) => {
  let providerDetails: ProviderDetails | null = null;
  let detailsId = details.property('id', 0 as ProviderDetailsId);
  if (details.isPresent() && detailsId === 0) {
    providerDetails = await api.providerDetails().create(details.get());
    detailsId = providerDetails.id;
  }
  if (detailsId !== 0) {
    return api.patient(newPatient.id).setPcp(detailsId);
  } else {
    return api.patient(newPatient.id).removePcp();
  }
};

export const signupResponsiblePerson = (api: AppAPI) => async (
  values: IndependentPatientValues,
): Promise<ResponsiblePerson> => {
  const responsiblePersonPayload = valuesToIndependentPatientInfo(values);

  return api.responsiblePerson().create({
    ...responsiblePersonPayload,
  });
};

export const linkPersonToPatient = (api: AppAPI) => async (
  personId: NonProfessionalId,
  patientId: number,
  options: LinkPatientToPersonRequest,
) => {
  return api
    .responsiblePerson(personId)
    .linkPersonToPatient(patientId, options);
};

export function signupNewPatient(api: AppAPI) {
  return async (patient: $NewPatient): Promise<LonelyPatient> => {
    const {
      firstName,
      lastName,
      nickName,
      dob,
      genderAssignedAtBirth,
      phone,
      pronouns,
      details,
    } = patient;

    const newPatient = await api.patient().create({
      firstName,
      lastName,
      nickName,
      dob,
      phone,
      genderAssignedAtBirth,
      pronouns,
      details,
    });

    return handleProviderInfo(api)(newPatient, details);
  };
}

export function signupNewPatientForGuardian(api: AppAPI) {
  return async (
    patient: $NewPatient,
    guardianId: UserId,
    relationship: string,
  ): Promise<LonelyPatient> => {
    const {
      firstName,
      lastName,
      nickName,
      dob,
      phone,
      genderAssignedAtBirth,
      pronouns,
      details,
    } = patient;
    const newPatient = await api.guardian(guardianId).createPatient({
      firstName,
      lastName,
      nickName,
      dob,
      genderAssignedAtBirth,
      phone,
      pronouns,
      details,
      relationship,
    });
    return handleProviderInfo(api)(newPatient, details);
  };
}
