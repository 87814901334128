import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Grid, useTheme} from '@material-ui/core';
import * as React from 'react';
import {SplashText} from '../../ui/layout/text/body/SplashText';

type Props = {hideIcon: boolean};

export function NoPatients({hideIcon = false}: Props) {
  const theme = useTheme();

  return (
    <Grid container style={{marginBottom: '1rem'}}>
      <Grid item xs={12} style={{textAlign: 'center'}}>
        <SplashText>
          From here you can set up accounts for yourself or anyone you help seek
          healthcare for. Then you will be all set for your current or future
          telemedicine visits.
        </SplashText>

        {!hideIcon && (
          <FontAwesomeIcon
            icon="users"
            size="8x"
            style={{padding: '1rem', color: theme.palette.grey[500]}}
          />
        )}
      </Grid>
    </Grid>
  );
}
