import React from 'react';
import {
  Grid,
  TableCell,
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core';
import {useStyles} from '../../shared/Tables/styles';
import {MedicalHistoryResult} from './utils/resultFunctions';
import {MedicalHistoryUIElement} from './utils/getMedicalHistoryRow';

import {handleRadioButtonClick} from './utils/handlers/handleRadioButtonClick';
import {handleTextInputChange} from './utils/handlers/overrideInputHandlers';
import {SelectInput} from '@src/components/ui/form';
import {immunizationOptions} from '@src/components/providerSide/VisitDocumentationForm/sections';

type Props = {
  setResult: (r: MedicalHistoryResult) => unknown;
  result: MedicalHistoryResult;
  row: MedicalHistoryUIElement;
};

export function OverrideCell({result, setResult, row}: Props) {
  const classes = useStyles();
  const isOverrideSelected = result[row.name].radioSelection === 'override';

  return (
    <TableCell className={classes.tableCell}>
      <div>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <FormControlLabel
              name={row.name}
              control={
                <Radio
                  onChange={event =>
                    handleRadioButtonClick({
                      event,
                      radioSelection: 'override',
                      result,
                      setResult,
                    })
                  }
                  checked={isOverrideSelected}
                />
              }
              label={''}
            />
          </Grid>
          <Grid item xs={10}>
            {row.name !== 'immunizations_up_to_date' && (
              <TextField
                onClick={() => {
                  setResult({
                    ...result,
                    [row.name]: {
                      text: isOverrideSelected ? result[row.name].text : '',
                      radioSelection: 'override',
                    },
                  });
                }}
                placeholder="Please type an override value to resolve the conflict"
                multiline
                value={isOverrideSelected ? result[row.name].text : ''}
                onChange={event =>
                  handleTextInputChange({
                    event,
                    fieldName: row.name,
                    radioSelection: 'override',
                    result,
                    setResult,
                  })
                }
              />
            )}

            {row.name === 'immunizations_up_to_date' && (
              <SelectInput
                placeholder="Please select the patient's gender assigned at birth"
                name="immunizations_up_to_date"
                options={immunizationOptions()}
                title="Immunizations up to date?"
                value={isOverrideSelected ? result[row.name].text : ''}
                onChange={event => {
                  setResult({
                    ...result,
                    [row.name]: {
                      text: event.target.value,
                      radioSelection: 'override',
                    },
                  });
                }}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </TableCell>
  );
}
