import React from 'react';
import {Control, Controller, DeepMap, FieldError} from 'react-hook-form';
import styled from 'styled-components';
import {AsyncActionButton, TextInput} from '../../../ui/form';
import {AddressInput} from '../../../ui/form/AddressInput';
import {PhoneInput} from '../../../ui/form/PhoneInput';
import {h3, primary, xs, xxs} from '../../../ui/theme';
import {FinanciallyResponsiblePersonValues} from '../FinanciallyResponsiblePersonFormModal/functions';
import {DateInput} from '@src/components/ui/form/DateInput';

const Heading = styled.div`
  font-size: ${h3};
  color: ${primary};
  width: available;
`;

const PersonInformation = styled.div``;

const ContactInformation = styled.div``;

type Props = {
  canEditEmail?: boolean;
  errors: DeepMap<FinanciallyResponsiblePersonValues, FieldError>;
  control: Control<FinanciallyResponsiblePersonValues>;
  isSubmitting: boolean;
  onSubmit: () => Promise<void>;
  setDob: (d: Date | null) => unknown;
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${xs};
  flex: 2 0;
  width: 100%;

  button {
    margin: ${xxs};
  }
`;

const GuardianInfoFormBaseStyled = styled.div`
  width: 100%;
`;

export function GuardianInfoForm({
  errors,
  control,
  canEditEmail = true,
  isSubmitting,
  onSubmit,
  setDob,
}: Props) {
  return (
    <GuardianInfoFormBaseStyled>
      <PersonInformation>
        <Controller
          control={control}
          as={TextInput}
          required
          name="firstName"
          title="First Name"
          error={errors.firstName?.message}
        />
        <Controller
          control={control}
          as={TextInput}
          required
          name="lastName"
          title="Last Name"
          error={errors.lastName?.message}
        />
        <Controller
          control={control}
          as={DateInput}
          onChange={(date: Date | null) => setDob(date)}
          required
          name="dob"
          label="Your date of birth"
          error={errors.dob?.message}
        />
      </PersonInformation>

      <ContactInformation>
        <Heading>Contact information</Heading>
        <div style={{display: canEditEmail ? 'block' : 'none'}}>
          <Controller
            control={control}
            as={TextInput}
            required
            name="email"
            title="Email address"
            error={errors.email?.message}
          />
        </div>

        <Controller
          control={control}
          as={PhoneInput}
          required
          name="phone"
          title="Phone Number"
          error={errors.phone?.message}
        />
        <Controller
          control={control}
          as={AddressInput}
          name="address"
          required
          error={errors.address?.line1?.message}
        />
      </ContactInformation>

      <ButtonContainer>
        <AsyncActionButton
          block
          bSize={'small'}
          actionInProgress={isSubmitting}
          actionWord="Save"
          onClick={() => {
            onSubmit();
          }}
        />
      </ButtonContainer>
    </GuardianInfoFormBaseStyled>
  );
}
