export function getSteps(peopleMatch: boolean) {
  return [
    peopleMatch ? 'View Financially Responsible Person' : 'Select',
    'Relationship To Patient?',
  ];
}

export function getStepContent(step: number, needToSelectPerson: boolean) {
  switch (step) {
    case 0:
      return !needToSelectPerson
        ? ` The following Financially Responsible Person will be
      automatically merged. Please review and proceed to the next
      step.`
        : `The current Patient records have different Financially
        Responsible Persons. Please select one.`;
    case 1:
      return 'What relationship does this person have with the Patient?';
    default:
      return 'Unknown step';
  }
}
