import React from 'react';
import {ProviderGroupForm} from './ProviderGroupForm';
import {PrivatePage} from '@src/components/PrivatePage';
import {PageHeader} from '@src/components/ui/layout';

// eslint-disable-next-line import/no-unused-modules
export default function CreateProviderGroupPage() {
  return (
    <PrivatePage>
      <PageHeader>Create a Provider Group</PageHeader>
      <ProviderGroupForm />
    </PrivatePage>
  );
}
