import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {BottomNavigation, BottomNavigationAction} from '@material-ui/core';
import {Room} from 'twilio-video';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import {VideoChatEvents} from '../../../../../events';
import {useStyles, DOCUMENTATION_WIDTH, SIDEBAR_WIDTH} from '../../layout';
import {Button} from '../../../../ui/form/Button';
import {useWindowEvent} from '@src/hooks';
import {
  setUpScreensharingTrack,
  Tracks,
  stopLocalVideoTracks,
} from '@src/util/videoChat';
import {isMobileOperatingSystem} from '@src/util/browserTools';
import {isNothing} from '@src/util/typeTests';

// Layout

// Icons

type Props = {
  showDocumentation?: boolean;
  isVideoDisabled: boolean;
  isMuted: boolean;
  setIsVideoDisabled: (v: boolean) => unknown;
  setIsMuted: (v: boolean) => unknown;
  room: Room | null;
  isAnyoneScreensharing: boolean;
  setVideoTracks: (fn: (vts: Tracks) => Tracks) => void;
  isLocalScreensharing?: boolean;
  patientId: number;
};

export const LocalParticipantControls = ({
  showDocumentation,
  isVideoDisabled,
  isMuted,
  setIsVideoDisabled,
  setIsMuted,
  room,
  isAnyoneScreensharing,
  isLocalScreensharing,
  setVideoTracks,
  patientId,
}: Props) => {
  const showScreensharing =
    !isAnyoneScreensharing && !isMobileOperatingSystem();

  const showStopScreensharing =
    isAnyoneScreensharing && isLocalScreensharing && !isMobileOperatingSystem();

  useWindowEvent(VideoChatEvents.PresentSCP, () => {
    if (showScreensharing && !isNothing(room)) {
      setUpScreensharingTrack({
        room,
        setVideoTracks,
      }).then(() => window.open(`/my-scp/${patientId}?inVisit`));
    }
  });

  const classes = useStyles({
    width: showDocumentation ? DOCUMENTATION_WIDTH : SIDEBAR_WIDTH,
  });

  if (!room) return null;

  return (
    <>
      <BottomNavigation
        className={classes.videoControls}
        id="bottom-navigation"
      >
        <BottomNavigationAction
          className={classes.videoControlAction}
          onClick={() => setIsVideoDisabled(!isVideoDisabled)}
          icon={
            isVideoDisabled ? (
              <FontAwesomeIcon
                className={classes.controlButtonDisabled}
                icon={'video-slash'}
                size="lg"
                fixedWidth
              />
            ) : (
              <FontAwesomeIcon
                className={classes.controlButtonActive}
                icon={'video'}
                size="lg"
                fixedWidth
              />
            )
          }
        />

        <BottomNavigationAction
          className={classes.videoControlAction}
          onClick={() => setIsMuted(!isMuted)}
          icon={
            isMuted ? (
              <FontAwesomeIcon
                className={classes.controlButtonDisabled}
                icon={'microphone-slash'}
                size="lg"
                fixedWidth
              />
            ) : (
              <FontAwesomeIcon
                className={classes.controlButtonActive}
                icon={'microphone'}
                size="lg"
                fixedWidth
              />
            )
          }
        />

        {showScreensharing && (
          <BottomNavigationAction
            onClick={() =>
              setUpScreensharingTrack({
                room,
                setVideoTracks,
              })
            }
            className={classes.videoControlAction}
            icon={<ScreenShareIcon />}
          />
        )}

        {showStopScreensharing && (
          <BottomNavigationAction
            className={classes.videoControlAction}
            icon={
              <Button
                bStyle="danger"
                bSize="small"
                onClick={() => stopLocalVideoTracks({room})}
              >
                Stop Presenting
              </Button>
            }
          />
        )}
      </BottomNavigation>
    </>
  );
};
