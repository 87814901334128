import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import AutoWidth from './AutoWidth';

const FONTS = [
  'Homemade Apple',
  'La Belle Aurore',
  'Mrs Saint Delafield',
  'Sacramento',
];

interface Props {
  name: string;
  font: string | null;
  onChange?: (props: Props) => unknown;
}

export default function TextInput({name, font, onChange = () => {}}: Props) {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <TextField
          value={name}
          onChange={event =>
            onChange({name: event.target.value.substring(0, 255), font})
          }
          variant="standard"
        />
      </Grid>
      <Grid item>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Choose a font
          </FormLabel>
          <RadioGroup
            value={font}
            onChange={event => onChange({name, font: event.target.value})}
          >
            {FONTS.map(fontName => (
              <FormControlLabel
                key={fontName}
                value={fontName}
                control={<Radio />}
                label={
                  <AutoWidth
                    fontFamily={`'${fontName}', cursive`}
                    text={name || 'Your Name'}
                    maxWidth="215px"
                  />
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}
