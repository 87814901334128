import React, {useState, useEffect, useRef} from 'react';

import {NetworkQualityStats} from 'twilio-video';
import {LocalParticipantControls} from './LocalParticipantControls';
import {ParticipantVideoProps} from './types';
import {NetworkIndicator, AudioVideoIndicator} from './indicators';
import {
  trackpubsToTracks,
  Tracks,
  attachVideoTrackEventListeners,
  attachAudioTrackEventListeners,
  attachParticipantEventListeners,
  onToggleLocalAudio,
  onToggleLocalVideo,
  doesParticipantRequirePermission,
  syncTwilioTrackWithComponentState,
  preventUnnecessaryStreaming,
  syncComponentTrackStateWithTwilio,
} from '@src/util/videoChat';
import {NetworkQualityLevel} from '@src/util/videoChat/twilio/shared/participants/utils/networkQuality/networkQuality';
import {useVideoStyles} from '@src/components/ui/layout/VideoStyles';

function ParticipantVideo({
  participant,
  isLocal,
  showDocumentation,
  cutRoomAudio,
  room,
  isAnyoneScreensharing,
  isMainScreenElement,
  onVideoTracksStateChange,
  isLocalScreensharing,
  setParticipants,
  setWaitingParticipants,
  hide,
  patientId,
  visitId,
  isLocalAudioMuted,
  setIsLocalAudioMuted,
  isLocalVideoDisabled,
  setIsLocalVideoDisabled,
}: ParticipantVideoProps) {
  const videoClasses = useVideoStyles();

  const [videoTracks, setVideoTracks] = useState<Tracks>([]);
  const [audioTracks, setAudioTracks] = useState<Tracks>([]);
  const [isRemoteAudioMuted, setIsRemoteAudioMuted] = useState(false);
  const [isRemoteVideoDisabled, setIsRemoteVideoDisabled] = useState(false);

  const isMuted = isLocal ? isLocalAudioMuted : isRemoteAudioMuted;
  const setIsMuted = isLocal ? setIsLocalAudioMuted : setIsRemoteAudioMuted;
  const isVideoDisabled = isLocal
    ? isLocalVideoDisabled
    : isRemoteVideoDisabled;
  const setIsVideoDisabled = isLocal
    ? setIsLocalVideoDisabled
    : setIsRemoteVideoDisabled;

  const [networkQualityLevel, setNetworkQualityLevel] = useState<
    NetworkQualityLevel
  >(0);
  const [
    networkQualityStats,
    setNetworkQualityStats,
  ] = useState<NetworkQualityStats | null>(null);

  const anonWaitingForAdmittance = doesParticipantRequirePermission({
    participant,
  });

  const videoRef = useRef() as React.MutableRefObject<HTMLVideoElement>;
  const audioRef = useRef() as React.MutableRefObject<HTMLAudioElement>;

  // If video is disabled, force a black square to render rather than
  // the last frame from the end of the stream.
  const blackSquareTag = (
    <video style={{background: 'black'}} hidden={hide || !isVideoDisabled} />
  );

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    attachParticipantEventListeners({
      participant,
      setAudioTracks,
      setVideoTracks,
      room,
      setParticipants,
      setWaitingParticipants,
      networkQualityDataSetters: {
        setNetworkQualityStats,
        setNetworkQualityLevel,
      },
      visitId,
      isLocal,
    });

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
    };
  }, [participant]);

  useEffect(() => {
    onVideoTracksStateChange();

    const videoTrack = videoTracks[0];

    if (videoTrack && !isLocal) {
      syncComponentTrackStateWithTwilio({
        setComponentTrackState: setIsVideoDisabled,
        componentTrackState: isVideoDisabled,
        track: videoTrack,
      });
    }

    videoTrack?.attach(videoRef.current);

    attachVideoTrackEventListeners({
      videoTracks,
      videoTrack,
      setIsVideoDisabled,
      isLocal,
    });

    return () => {
      videoTracks.forEach(t => {
        t?.detach(videoRef.current);
      });
    };
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];

    if (audioTrack && !isLocal) {
      audioTrack.attach(audioRef.current);

      syncComponentTrackStateWithTwilio({
        setComponentTrackState: setIsMuted,
        componentTrackState: isMuted,
        track: audioTrack,
      });

      attachAudioTrackEventListeners({
        audioTracks,
        audioTrack,
        setIsMuted,
        isLocal,
      });

      return () => {
        audioTracks.forEach(t => {
          t?.detach(audioRef.current);
        });
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    if (isLocal) {
      onToggleLocalAudio({audioTracks, shouldAudioBeMuted: isMuted});
    }
  }, [isMuted]);

  useEffect(() => {
    if (isLocal) {
      onToggleLocalVideo({videoTracks, shouldVideoBeDisabled: isVideoDisabled});
    }
  }, [isVideoDisabled]);

  useEffect(() => {
    if (!isLocal) {
      return;
    }

    if (hide) {
      preventUnnecessaryStreaming({room});
    } else {
      syncTwilioTrackWithComponentState({isMuted, room, isVideoDisabled});
    }
  }, [hide]);

  if (anonWaitingForAdmittance) {
    return null;
  }

  return (
    <>
      <div
        className={
          !isMainScreenElement
            ? videoClasses.videoContainer
            : videoClasses.mainScreenElement
        }
        style={{display: hide ? 'none' : 'block'}}
      >
        {!hide && (
          <>
            <AudioVideoIndicator
              isVideoDisabled={isVideoDisabled}
              isMuted={isMuted}
            />
            <NetworkIndicator
              isLocal={isLocal}
              networkQualityData={{networkQualityLevel, networkQualityStats}}
              visitId={visitId}
              room={room}
              networkQualityDataSetters={{
                setNetworkQualityStats,
                setNetworkQualityLevel,
              }}
            />
          </>
        )}

        <video
          style={{background: 'black'}}
          hidden={hide || isVideoDisabled}
          ref={videoRef}
          autoPlay={true}
          className="participantVideo"
        />

        {blackSquareTag}

        {!isLocal && (
          <audio muted={hide || cutRoomAudio} ref={audioRef} autoPlay={true} />
        )}
      </div>

      {isLocal && !hide && !anonWaitingForAdmittance && (
        <LocalParticipantControls
          showDocumentation={showDocumentation}
          isVideoDisabled={isVideoDisabled}
          isMuted={isMuted}
          setIsVideoDisabled={setIsVideoDisabled}
          setIsMuted={setIsMuted}
          room={room}
          isAnyoneScreensharing={isAnyoneScreensharing}
          setVideoTracks={setVideoTracks}
          isLocalScreensharing={isLocalScreensharing}
          patientId={patientId}
        />
      )}
    </>
  );
}

export default ParticipantVideo;
