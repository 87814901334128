import React, {useEffect, useState, useCallback} from 'react';
import {RouteComponentProps} from 'react-router';
import {Heading} from './Heading';
import {Info} from './Info';
import {useApi} from '@src/api/useApi';
import {PrivatePage} from '@src/components/PrivatePage';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {PageHeader} from '@src/components/ui/layout/PageHeader';
import {useResources} from '@src/hooks';
import {VisitFile, LonelyVisit, MedicalHistory, Patient} from '@src/models';
import {allLoaded} from '@src/util/apiHelpers';
import {getAllPages} from '@src/util/apiHelpers/getAllPages';

type Props = RouteComponentProps<{visitId: string}>;

export function VisitOverview({match}: Props) {
  const visitId = +match.params.visitId;
  const api = useApi();
  const [patientId, setPatientId] = useState<number | null>(null);
  const [visit] = useResources<LonelyVisit>(() => api.visit(visitId).get(), [
    api,
    visitId,
  ]);
  const [patient] = useResources<Patient>(
    () => api.visit(visitId).getPatient(),
    [api, visitId],
  );

  useEffect(() => {
    setPatientId(
      patient
        .getOptional()
        .map(p => p.id)
        .orNull(),
    );
  }, [patient]);

  const patientIsLoaded = useCallback(() => patientId !== null, [patientId]);

  const [currentMedicalHistory] = useResources<MedicalHistory>(
    () => api.patient(patientId!).getCurrentMedicalHistory(),
    [api, patientId],
    {requestGate: patientIsLoaded},
  );

  const [visitFiles, setVisitFiles] = useResources<VisitFile>(
    () =>
      getAllPages(options => api.patient(patientId!).getVisitFiles(options)),
    [api, patientId],
    {requestGate: patientIsLoaded},
  );

  const ready = allLoaded(visit, currentMedicalHistory, visitFiles, patient);

  const aPatient = patient.getOptional().orNull();

  return (
    <PageLoading active={!ready} message="Loading Visit">
      <PrivatePage style={{paddingBottom: '2rem'}}>
        <PageHeader style={{marginBottom: 0}}>Visit Overview</PageHeader>
        <Heading visit={visit.getOptional().orNull()} />
        <Info
          patient={aPatient!}
          visit={visit}
          currentMedicalHistory={currentMedicalHistory}
          visitFiles={visitFiles.getAllOptional().orElse([])}
          setFiles={setVisitFiles}
        />
      </PrivatePage>
    </PageLoading>
  );
}
