import {AsyncData} from '@ahanapediatrics/ahana-fp';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {RouteComponentProps, useHistory} from 'react-router';
import {Grid, useTheme} from '@material-ui/core';
import {LonelyVisit} from '../../../models';
import {AsyncActionButton, Button, TextInput} from '../../ui/form';
import {NPSInput} from '../../ui/form/NPSInput';
import {MuiHeading} from '../../ui/layout/MuiHeading';
import {ParagraphText} from '../../ui/layout/text/body/ParagraphText';
import {Modal} from '@src/components/ui/layout/Modal';
import {useApi} from '@src/api/useApi';

type Props = RouteComponentProps<{visitId: string}>;

enum SurveyBlockState {
  Survey,
  Followup,
}

export function GuardianSurveyPage({match}: Props) {
  const api = useApi();
  const history = useHistory();
  const [npsScore, setNPS] = useState<number | undefined>();
  const [comments, setComments] = useState('');
  const [panel, setPanel] = useState(SurveyBlockState.Survey);
  const [visit, setVisit] = useState(AsyncData.notAsked<LonelyVisit>());
  const [submittingSurvey, setSubmittingSurvey] = useState(false);
  const theme = useTheme();

  const visitId = +match.params.visitId;

  useEffect(() => {
    api
      .visit(visitId)
      .get()
      .then(a => setVisit(AsyncData.loaded([a])))
      .catch(e => setVisit(AsyncData.errored(e)));
  }, [api, visitId]);

  return (
    <Modal
      show
      fullScreen
      title={
        panel === SurveyBlockState.Survey ? 'How was your visit?' : 'Thank you'
      }
      modalActions={
        <div style={{justifyContent: 'flex-start'}}>
          <Button
            isInRow
            onClick={() => {
              history.push('/');
            }}
            bStyle="outlined"
          >
            Skip
          </Button>
          <AsyncActionButton
            actionInProgress={submittingSurvey}
            actionWord={'Submit'}
            bStyle={'primary'}
            onClick={() => {
              setSubmittingSurvey(true);
              api
                .visit(visitId)
                .submitSurvey({npsScore, comments})
                .finally(() => {
                  setSubmittingSurvey(false);
                  setPanel(SurveyBlockState.Followup);
                });
            }}
          />
        </div>
      }
    >
      <Grid container direction="row">
        {panel === SurveyBlockState.Survey && (
          <Grid item xs={12}>
            <MuiHeading weight="light">
              Thank you for using Refyne Connected Care!
            </MuiHeading>
            <ParagraphText>
              On a scale from 0 to 10, how much do you agree with the following
              statement?
            </ParagraphText>
            <ParagraphText
              style={{color: theme.palette.primary.main, fontWeight: 700}}
            >
              I would recommend other families try a visit here if their
              doctor's office is closed.
            </ParagraphText>
            <NPSInput name={'nps'} value={npsScore} onChange={setNPS} />

            <ParagraphText style={{marginTop: '1rem', marginBottom: 0}}>
              Why? We love your input!
            </ParagraphText>

            <Grid item xs={12} md={8}>
              <TextInput
                name={'comments'}
                value={comments}
                lines={3}
                onChange={e => setComments(e.target.value)}
                placeholder={'Let us know what you think here...'}
              />
            </Grid>
          </Grid>
        )}
        {panel === SurveyBlockState.Followup && (
          <Grid item xs={12}>
            <MuiHeading weight="light">Thanks for your feedback!</MuiHeading>
            {visit.isLoaded() && (
              <ParagraphText>
                We also offer the chance to provide more detailed feedback -
                please click{' '}
                <a
                  target={'_blank'}
                  href={`https://umt.co1.qualtrics.com/jfe/form/SV_bPdslAYz0zh3Twx?uid=${
                    visit.singleValue().uid
                  }`}
                >
                  HERE
                </a>{' '}
                to fill out a survey about your experience, and help us serve
                Montana families better in the future!
              </ParagraphText>
            )}
            <Button
              onClick={() => {
                history.push('/');
              }}
            >
              Return to Dashboard
            </Button>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
}
