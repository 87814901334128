import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {grey, lighterGrey, primary, sm, xs} from '@src/components/ui/theme';

export const VisitFilesStyled = styled.div``;

export const DownloadIcon = styled(FontAwesomeIcon)``;

export const VisitFile = styled.div<{disabled: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${xs};
  overflow: hidden;
  overflow-wrap: break-word;
  border: solid thin ${grey};
  cursor: ${props => (props.disabled ? 'wait' : 'pointer')};

  ${DownloadIcon} {
    color: ${primary};
    margin-right: ${sm};
  }

  &:hover {
    background-color: ${lighterGrey};
  }
`;

export const VisitFileList = styled.div<{open: boolean}>`
  display: ${props => (props.open ? 'block' : 'none')};
`;

export const AgreeAndDownloadTitle = styled.h3`
  padding-top: 1em;
  padding-bottom: 1em;
`;
