import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {body, warning, error, md, primary, shadow, sm, xs, xxs} from '../theme';

type BannerType = 'error' | 'caution' | 'info';
type Props = {
  title: string;
  label?: string;
  message: ReactNode;
  type?: BannerType;
};

export const Banner = ({label, type, title, message}: Props) => (
  <>
    <Label>{label ?? 'Note'}</Label>
    <Alert>
      <AlertIcon type={type || 'info'}>
        {type === 'error' ? (
          // Error
          <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
        ) : type === 'caution' ? (
          // Caution
          <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
        ) : (
          // Informative
          <FontAwesomeIcon icon={['fas', 'info-circle']} />
        )}
      </AlertIcon>
      <AlertContent>
        <AlertTitle className="b-alert__heading">
          {title || 'Banner Title'}
        </AlertTitle>
        <AlertMessage className="b-alert__message">
          {message || "Here's a message that goes with the banner."}
        </AlertMessage>
      </AlertContent>
    </Alert>
  </>
);

const Label = styled.label<{dark?: boolean}>`
  font-size: ${body};
  color: ${props => (props.dark ? '#ffffff' : '#000000')};
  margin-bottom: ${sm};
  font-weight: 600;
  display: block;
`;

const Alert = styled.div`
  display: flex;
  border-radius: ${xs};
  background-color: white;
  box-shadow: ${shadow};
  overflow: hidden;
  border: 1px solid #000000;
`;

const AlertContent = styled.div`
  padding: ${md};
`;

const AlertIcon = styled.div<{type: BannerType}>`
  padding: ${xs};
  font-size: 1.25rem;
  flex: 0 0 ${md};
  margin-right: 0.75rem;
  color: white;
  padding: ${md};

  background-color: ${props =>
    props.type === 'caution'
      ? warning
      : props.type === 'error'
      ? error
      : primary};

  i {
    color: white;
  }
`;

const AlertTitle = styled.h2`
  font-size: ${parseInt(md, 10) + parseInt(xxs, 10)}em;
  font-weight: bold;
  margin-bottom: ${md};
  line-height: 1;
  color: '#000000';
`;

const AlertMessage = styled.p`
  display: flex;
`;
