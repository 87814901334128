import {Optional} from '@ahanapediatrics/ahana-fp';
import {ProviderDetails} from '@src/models';

export function getEmailFromDetails(
  details: Optional<ProviderDetails>,
): string | null {
  return details
    .map(d => d.identifiers.find(i => i.source === 'email')?.val)
    .orNull();
}
