import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    invalidField: {
      borderStyle: 'solid',
      borderColor: 'red',
    },
  }),
);
