import React, {RefObject, useCallback} from 'react';
import ReactToPrint from 'react-to-print';
import {Grid, IconButton, Tab, Tabs} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import Badge from '@material-ui/core/Badge';
import {usePageContext} from '..';
import {BUTTONS, TabButton} from './types';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/SCPTabs/styles';
import {SCPChangeRequestStatus, SCPStatus, UserType} from '@src/models';
import {useUser} from '@src/hooks';

type Props = {
  printRef: RefObject<HTMLTableElement>;
};

export const SCPTabs = ({printRef}: Props) => {
  const [, userType] = useUser();
  const classes = useStyles();
  const {
    changeRequests,
    isUserPartner,
    hasScp,
    isOwner,
    scp,
    selectedPanel,
    selectPanel,
    reviewMode,
  } = usePageContext();

  const handleChange = (e: React.ChangeEvent<{}>, v: number) => {
    selectPanel(BUTTONS[v].panel);
  };

  const status = scp
    .getOptional()
    .map(m => m?.status)
    .orNothing();

  const pendingChangeRequestsCount = changeRequests
    .getAllOptional()
    .orElse([])
    .filter(cr => cr.status === SCPChangeRequestStatus.Pending)?.length;

  const handleClickTab = useCallback(() => {
    return (details: TabButton) => {
      if (!reviewMode) {
        selectPanel(details.panel);
      }
    };
  }, [reviewMode, selectPanel]);

  if (status === SCPStatus.Retracted) {
    return null;
  }

  return (
    <Grid item container direction="row">
      <Tabs
        value={BUTTONS.findIndex(b => selectedPanel === b.panel)}
        classes={{
          root: classes.root,
        }}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
      >
        {BUTTONS.map(details => {
          let className =
            !details.requireScp || hasScp ? classes.tab : classes.hiddenTab;

          if (!isOwner && details.requireIsOwner) className = classes.hiddenTab;
          if (userType !== UserType.Guardian && details.requireIsGuardian)
            className = classes.hiddenTab;

          if (
            details.panel === 'changeLog' &&
            (isOwner || isUserPartner) &&
            pendingChangeRequestsCount > 0
          ) {
            return (
              <Tab
                label={
                  <Badge
                    classes={{
                      anchorOriginTopRightRectangle: classes.notificationBanner,
                    }}
                    badgeContent={pendingChangeRequestsCount}
                    color="error"
                    max={99}
                  >
                    {details.label}
                  </Badge>
                }
                classes={{root: className, selected: classes.selected}}
                key={details.panel}
                onClick={handleClickTab}
                disabled={
                  details.disabled ||
                  (reviewMode && userType === UserType.Guardian)
                }
              />
            );
          } else if (
            details.panel === 'ownerControls' &&
            isOwner &&
            status &&
            [
              SCPStatus.Draft,
              SCPStatus.ApprovalExpired,
              SCPStatus.OwnershipExpired,
            ].includes(status)
          ) {
            return (
              <Tab
                label={
                  <Badge
                    classes={{
                      anchorOriginTopRightRectangle: classes.notificationBanner,
                    }}
                    badgeContent={1}
                    color="error"
                    max={1}
                  >
                    {details.label}
                  </Badge>
                }
                classes={{root: className, selected: classes.selected}}
                key={details.panel}
                onClick={handleClickTab}
                disabled={
                  details.disabled ||
                  (reviewMode && userType === UserType.Guardian)
                }
              />
            );
          } else {
            return (
              <Tab
                label={details.label}
                classes={{root: className, selected: classes.selected}}
                key={details.panel}
                onClick={handleClickTab}
                disabled={
                  details.disabled ||
                  (reviewMode && userType === UserType.Guardian)
                }
              />
            );
          }
        })}

        {!reviewMode && (
          <ReactToPrint
            trigger={() => (
              <IconButton>
                <PrintIcon />
              </IconButton>
            )}
            content={() => printRef.current}
          />
        )}
      </Tabs>
    </Grid>
  );
};
