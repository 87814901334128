import React, {useState, useCallback, lazy, Suspense} from 'react';
import {useHistory} from 'react-router';
import {GuardianInfo, GuardianContactRow, GuardianContactIcon} from './layout';
import {EditableGuardianProperty} from './EditableGuardianProperty';
import {
  Guardian,
  Invitation,
  LonelyPatient,
  PatientRelationship,
  Relationship,
  UserType,
} from '@src/models';
import {useApi} from '@src/api/useApi';
import {Modal} from '@src/components/ui/layout/Modal';
import {Button} from '@src/components/ui/form/Button/index';
import {useUser} from '@src/hooks';
import {unlinkGuardianOrSelf} from '@src/util/relationships/guardianOrIndependent';
import {isNothing} from '@src/util/typeTests';
import {UserId} from '@src/models/User';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

type Props = {
  currentInvitations: readonly Invitation[];
  patient?: LonelyPatient;
  onUpdateRelationship: (r: Relationship) => Promise<unknown>;
  onRemoveRelationship: (id: number) => Promise<unknown>;
  onClose: () => void;
  readOnly?: boolean;
  show?: boolean;
  relationship?: PatientRelationship;
  guardiansCount: number;
  updatePatient: (p: LonelyPatient) => void;
};

const ConfirmUnlinkModal = lazy(() =>
  import('@src/components/shared/SimplePatientCard/ConfirmUnlinkModal'),
);

export function EditConnectedLoginModal({
  currentInvitations,
  patient,
  relationship,
  onUpdateRelationship,
  onRemoveRelationship,
  readOnly = false,
  show = false,
  onClose,
  guardiansCount,
  updatePatient,
}: Props) {
  const api = useApi();
  const history = useHistory();
  const [user] = useUser();
  const [unlinkingInProgress, setUnlinkingInProgress] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const userPersonId = user
    .getOptional()
    .cast<Guardian>(us => us.userType === UserType.Guardian)
    .map(g => g.responsiblePersonDetails)
    .map(r => r.id)
    .orElse(0 as NonProfessionalId);

  const userId = user
    .getOptional()
    .map(u => u.id)
    .orElse(0 as UserId);

  const updateRelationship = useCallback(
    (relationshipName: string) =>
      api
        .responsiblePerson(relationship?.person?.id)
        .updateRelationshipName(patient?.id ?? 0, relationshipName)
        .then(updatedRelationship => {
          if (onUpdateRelationship) {
            return onUpdateRelationship(updatedRelationship);
          }
          return Promise.resolve();
        }),
    [api, relationship, patient, onUpdateRelationship],
  );

  const unlink = useCallback(() => {
    if (isNothing(relationship) || isNothing(patient)) {
      return Promise.reject();
    }
    return unlinkGuardianOrSelf({
      api,
      setUnlinkingInProgress,
      relationship,
      onRemoveRelationship,
      patient,
      userPersonId,
      history,
    });
  }, [api, history, onRemoveRelationship, relationship, patient, userPersonId]);

  const closeModal = useCallback(() => setShowConfirmModal(false), []);

  const confirmUnlink = useCallback(() => unlink().then(closeModal), [
    closeModal,
    unlink,
  ]);

  if (!relationship || !patient) return null;

  const identity = relationship.person;

  return (
    <Modal
      show={show}
      title={`${relationship.isSelf ? 'User' : 'Connected Login'} Details`}
      onClose={onClose}
    >
      <GuardianInfo>
        <GuardianContactRow>
          <GuardianContactIcon icon={'user'} fixedWidth />
          <div>
            {identity.firstName} {identity.lastName}
          </div>
        </GuardianContactRow>
        {!relationship.isSelf && (
          <GuardianContactRow>
            <GuardianContactIcon icon={'users'} fixedWidth />
            <EditableGuardianProperty
              tooltip="Change guardian's relationship to patient"
              defaultValue="Relationship Unknown"
              name="relationship"
              updateGuardian={(__, value) => updateRelationship(value)}
              value={relationship.relationship}
              editable={!readOnly}
            />
          </GuardianContactRow>
        )}
        <GuardianContactRow>
          <GuardianContactIcon icon={['fal', 'envelope']} fixedWidth />
          <div>{identity.email.orElse('-')}</div>
        </GuardianContactRow>
        <GuardianContactRow>
          <GuardianContactIcon icon={'phone'} fixedWidth />
          <div>{identity.phone || '-'}</div>
        </GuardianContactRow>
        {userId !== 0 && !readOnly && (
          <>
            <GuardianContactRow>
              <Button
                bStyle="danger"
                bSize="small"
                onClick={() => setShowConfirmModal(true)}
              >
                Unlink
              </Button>
            </GuardianContactRow>

            <Suspense fallback={<div>Loading...</div>}>
              <ConfirmUnlinkModal
                currentInvitations={currentInvitations}
                show={showConfirmModal}
                onHide={closeModal}
                onCancel={closeModal}
                onConfirm={confirmUnlink}
                unlinkingInProgress={unlinkingInProgress}
                relationship={relationship}
                guardiansCount={guardiansCount}
                patient={patient}
                updatePatient={updatePatient}
              />
            </Suspense>
          </>
        )}
      </GuardianInfo>
    </Modal>
  );
}
