import {format} from 'date-fns';
import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import {MenuItem, FormControl, Select} from '@material-ui/core';
import {DashboardTable} from '../DashboardTable';
import {ActionCell, ActionsView, DashCard, DashCardHeader} from '../DashCard';
import {VisitDocumentationModal} from '../VisitDocumentationModal';
import {lg, primary} from '@src/components/ui/theme';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';
import {RedFlag} from '@src/components/ui/layout/RedFlag';
import {CallPool, Professional, User, UserType, LonelyVisit} from '@src/models';
import {AppAPI} from '@src/api/AppAPI';
import {useAsync} from '@src/hooks/useAsync';
import {useUser} from '@src/hooks/useUser';
import {useApi} from '@src/api/useApi';
import {getAllPages} from '@src/util/apiHelpers/getAllPages';
import {getCallPoolName} from '@src/util/callPools/getCallPoolName';
import {LoadingIndeterminate} from '@src/components/ui/atoms/progressBarsAndIndicators/LoadingIndeterminate';
import {LightParagraphText} from '@src/components/ui/layout/text/body/LightParagraphText';

const getters = (
  api: AppAPI,
  user: Optional<User>,
): {
  [type: string]: (callPoolId: number) => Promise<LonelyVisit[]>;
} => {
  if (user.filter(u => u.userType !== UserType.Professional).isPresent()) {
    throw new Error('Non-provider is trying to access Unreviewed Notes');
  }
  const provider = user
    .cast<Professional>(u => u.userType === UserType.Professional)
    .orNothing();
  if (!provider) {
    return {};
  }
  return {
    myPatientsNotes: () =>
      getAllPages<LonelyVisit>(({start, pageSize}) =>
        api.provider(provider.id).getUnreviewedNotes({start, pageSize}),
      ),

    callPoolNotes: (callPoolId: number) =>
      getAllPages<LonelyVisit>(({start, pageSize}) =>
        api
          .callPool(callPoolId)
          .getUnreviewedNotesForCallPool({start, pageSize}),
      ),
  };
};

type Props = {
  className?: string;
  title?: string;
  readonly emptyMessage?: string;
};

const ViewNoteButton = styled(ActionCell)`
  color: ${primary};
  cursor: pointer;
  font-size: 1.5em;
  width: ${lg};
`;

export function UnreviewedNotes({
  emptyMessage = 'No unreviewed notes',
  title = 'Unreviewed Notes',
  className = '',
}: Props) {
  const api = useApi();
  const [user, userType] = useUser();
  const [noteModal, setNoteModal] = useState('');
  const [notes, setNotes] = useAsync<LonelyVisit>();
  const [callPools, setCallPools] = useAsync<CallPool>();

  // If this id is 0, show provider's patient notes.
  // Otherwise show notes for the selected call pool.
  const [callPoolId, setCallPoolId] = useState<number>(0);

  const provider = user
    .getOptional()
    .cast<Professional>(u => u.userType === UserType.Professional)
    .orNothing();

  const getCallPools = useCallback(() => {
    if (!user.isLoaded()) {
      return;
    }

    api
      .provider(provider?.id)
      .getGroups({memberOnly: true})
      .then(results => setCallPools(results));
  }, [api, provider, setCallPools, user]);

  const getNotes = useCallback(() => {
    if (!user.isLoaded()) {
      return;
    }

    const source = callPoolId === 0 ? 'myPatientsNotes' : 'callPoolNotes';

    const getter = getters(api, user.getOptional())[source];
    if (!getter) {
      throw new Error(`Unknown visit source: ${source}`);
    }

    getter(callPoolId).then((ns: Array<LonelyVisit>) => {
      const filteredNotes = ns.filter(n => n.visitDocumentation);
      setNotes(filteredNotes);
    });
  }, [api, callPoolId, setNotes, user]);

  const updateN = (note: LonelyVisit) => {
    const index = notes.findIndex(n => +n.id === +note.id);
    setNotes(
      notes
        .update(index, note)
        .getAllOptional()
        .orElse([]),
    );
  };

  const removeN = (visit: LonelyVisit) => {
    const index = notes.findIndex(n => +n.id === +visit.id);

    setNotes(
      notes
        .remove(index)
        .getAllOptional()
        .orElse([]),
    );
  };

  useEffect(() => {
    if (user) {
      getNotes();
      getCallPools();
    }
  }, [user, getNotes, getCallPools]);

  useEffect(() => {
    getNotes();
  }, [callPoolId, getNotes]);

  const notesLoaded = notes.isLoaded();

  return (
    <>
      <DashCard className={className} style={{minHeight: '100%'}}>
        <DashCardHeader>
          <div>{title}</div>
        </DashCardHeader>
        <LoadingIndeterminate active={!notesLoaded}>
          {notesLoaded && !notes.isEmpty() && (
            <div>
              <DashboardTable>
                <thead>
                  <tr>
                    <th />
                    <th />
                    <th>Date</th>
                    <th>Patient</th>
                    <th>Provider</th>
                  </tr>
                </thead>
                <tbody>
                  {notes.isLoaded() &&
                    !notes.isEmpty() &&
                    notes.mapValue(note => {
                      const hasRedFlag = note.visitDocumentation
                        .map(v => v.red_flag)
                        .orElse(false);
                      return (
                        <tr key={note.id}>
                          <ViewNoteButton>
                            {note.visitDocumentation && (
                              <ClickableIcon
                                icon={'eye'}
                                onClick={() => {
                                  setNoteModal(`note${note.id}`);
                                }}
                              />
                            )}
                            <VisitDocumentationModal
                              canReview={true}
                              note={note}
                              show={noteModal === `note${note.id}`}
                              onClose={() => {
                                setNoteModal('');
                              }}
                              removeNote={(n: LonelyVisit) => removeN(n)}
                              updateNote={(n: LonelyVisit) => updateN(n)}
                            />
                          </ViewNoteButton>
                          <td>{hasRedFlag ? <RedFlag /> : ''}</td>
                          <td>{format(note.createdAt, 'P')}</td>
                          <td>{note.patient.preferredName}</td>
                          <td>
                            {note.providerDetails.property('fullName', '')}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </DashboardTable>
            </div>
          )}
          {notesLoaded && notes.isEmpty() && (
            <DashboardTable style={{textAlign: 'center'}}>
              <LightParagraphText>{emptyMessage}</LightParagraphText>
            </DashboardTable>
          )}
        </LoadingIndeterminate>
      </DashCard>
      <ActionsView>
        <FormControl>
          <Select
            value={callPoolId === 0 ? 'myPatientsNotes' : callPoolId}
            onChange={(e: React.ChangeEvent<{value: unknown}>) => {
              if (e.target.value === 'myPatientsNotes') {
                setCallPoolId(0);
              } else {
                setCallPoolId(parseInt(e.target.value as string));
              }
            }}
            style={{color: 'white', fontSize: '1rem'}}
          >
            <MenuItem value={'myPatientsNotes'}>My Patients</MenuItem>
            {callPools
              ?.getAllOptional()
              .orElse([])
              .map(cp => (
                <MenuItem key={cp.id} value={cp.id}>
                  {getCallPoolName(cp, userType)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </ActionsView>
    </>
  );
}
