import React from 'react';
import styled from 'styled-components';
import {isEmpty} from '../../../util/typeTests';

const PNStyle = styled.div`
  display: inline;
`;

type Props = {
  no: string;
  blankFiller?: string;
};

export function getPhoneNumberString({no, blankFiller = ''}: Props) {
  return isEmpty(no)
    ? blankFiller
    : no
        .replace(/\D/g, '')
        .split('')
        .reverse()
        .reduce(
          (num: string, dig: string) =>
            `${num}${[4, 8, 12].includes(num.length) ? '-' : ''}${dig}`,
          '',
        )
        .split('')
        .reverse()
        .join('');
}

export const PhoneNumber: React.FunctionComponent<Props> = ({
  no,
  blankFiller,
}) => {
  const string = getPhoneNumberString({no, blankFiller});

  return <PNStyle>{string}</PNStyle>;
};
