import {AsyncData} from '@ahanapediatrics/ahana-fp';
import React from 'react';
import {ProviderLabel} from './ProviderLabel';
import {ProviderDetails} from '@src/models';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {ProviderDetailsId} from '@src/models/ProviderDetails';

type Props = {
  members: AsyncData<ProviderDetails>;
  onSelect: (id: ProviderDetailsId) => unknown;
};

export function ProviderList({members, onSelect}: Props) {
  if (!members.isAsked()) {
    return null;
  }

  const loaded = members.isLoaded();

  return (
    <PageLoading active={!loaded} message="Loading group members...">
      {members
        .getAllOptional()
        .orElse([])
        .filter(m => m.providerId !== null)
        .map(m => (
          <ProviderLabel
            key={m.providerId ?? 0}
            details={m}
            selected={false}
            onClick={onSelect}
          />
        ))}
    </PageLoading>
  );
}
