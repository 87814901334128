import React from 'react';
import {Room, RemoteParticipant} from 'twilio-video';
import {Grid} from '@material-ui/core';
import {Button} from '../../../../ui/form/Button';
import {SetParticipants} from '@src/util/videoChat';

type Props = {
  room: Room | null;
  waitingParticipants: RemoteParticipant[];
  setWaitingParticipants: SetParticipants;
};

export function DecideAllRow({
  waitingParticipants,
  setWaitingParticipants,
  room,
}: Props) {
  if (waitingParticipants.length <= 1) return null;

  return (
    <Grid container direction="row" style={{marginTop: '2rem'}}>
      <Button
        bSize="small"
        bStyle="warning"
        isInRow={true}
        onClick={() => {
          room?.localParticipant.dataTracks?.forEach(t => {
            waitingParticipants.forEach(participant => {
              t.track.send(
                JSON.stringify({
                  visitorSid: participant.sid,
                  admitted: false,
                }),
              );

              setWaitingParticipants(prevParticipants => [
                ...prevParticipants.filter(pt => pt.sid !== participant.sid),
              ]);
            });
          });
        }}
      >
        Deny all
      </Button>
      <Button
        bStyle="primary"
        bSize="small"
        isInRow={true}
        onClick={() => {
          room?.localParticipant.dataTracks?.forEach(t => {
            waitingParticipants.forEach(participant => {
              t.track.send(
                JSON.stringify({
                  visitorSid: participant.sid,
                  admitted: true,
                }),
              );

              setWaitingParticipants(prevParticipants => [
                ...prevParticipants.filter(pt => pt.sid !== participant.sid),
              ]);
            });
          });
        }}
      >
        Admit all
      </Button>
    </Grid>
  );
}
