import React, {useEffect} from 'react';
import {RouteComponentProps, useHistory} from 'react-router';
import {Grid} from '@material-ui/core';
import {Button} from '../../../ui/form';
import {MuiHeading} from '../../../ui/layout/MuiHeading';
import {ParagraphText} from '../../../ui/layout/text/body/ParagraphText';
import {clearFlashes} from '../../notifications/flash';
import {
  getReturnButtonText,
  ReturnButtonReason,
  getHeadingText,
  getInstructionsText,
  getValidReason,
  returnButtonReasons,
  Reason,
} from './functions';
import {requestHelp} from '@src/util/applicationEvents/requestHelp';
import {useUser} from '@src/hooks';
import {UserType} from '@src/models';

type Props = RouteComponentProps<{
  visitId: string;
  reason: string;
}>;

export function ChatDeadEnd({match}: Props) {
  const visitId = +match.params.visitId;
  const reason: Reason = getValidReason(match.params.reason);

  const history = useHistory();
  const [, userType] = useUser();

  useEffect(() => {
    // Remove lingering exam room notifications.
    clearFlashes();
  }, []);

  const mainOptionButtons = (
    <Grid item xs={12}>
      <Button
        bStyle="primary"
        onClick={() => {
          if (userType === UserType.Guardian) {
            history.push('/dashboard');
          } else if (userType === UserType.Professional) {
            history.push('/on-call');
          } else {
            requestHelp();
          }
        }}
        style={{marginTop: '2rem'}}
      >
        {userType === UserType.Guardian
          ? 'Return To Dashboard'
          : userType === UserType.Professional
          ? 'Return to Waiting Room'
          : 'Help'}
      </Button>
    </Grid>
  );

  return (
    <Grid container>
      <Grid item xs={12} style={{textAlign: 'center', marginTop: '3rem'}}>
        <MuiHeading color="primary">{getHeadingText(reason)}</MuiHeading>

        <ParagraphText>{getInstructionsText(reason)}</ParagraphText>

        {returnButtonReasons.includes(reason) && (
          <Grid item xs={12}>
            <Button
              bStyle="primary"
              onClick={() => history.push(`/exam-room/${visitId}`)}
              style={{marginTop: '1rem'}}
            >
              {getReturnButtonText(reason as ReturnButtonReason)}
            </Button>
          </Grid>
        )}

        {mainOptionButtons}
      </Grid>
    </Grid>
  );
}
