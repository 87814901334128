import React, {useState} from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {AssignedForm} from '@src/models';
import Form from '@src/components/shared/AssignedFormPage/Form';
import {md, mdLg, sm, xs} from '@src/components/ui/theme';

type Props = {
  forms: readonly AssignedForm[];
  isCompleted?: boolean;
  onSubmit?: (v: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootComponent: {
      color: theme.palette.secondary.contrastText,
    },
    form: {
      display: 'block',
    },
    formList: {
      marginBottom: md,
    },
    formListRow: {
      margin: `${xs} 0`,
      padding: sm,
      backgroundColor: 'rgba(255,255,255,0.2)',
      borderRadius: xs,
    },
    messageText: {
      fontStyle: 'italic',
    },
    actionGrid: {
      height: mdLg,
      textAlign: 'right',
    },
    actionItem: {
      color: theme.palette.secondary.contrastText,
    },
    sectionTitle: {
      color: theme.palette.secondary.contrastText,
      marginBottom: sm,
    },
  }),
);

export function FormAccordion({
  forms,
  isCompleted = false,
  onSubmit = () => {},
}: Props) {
  const [expandedFile, setExpandedFile] = useState<number | string>('');

  const formLabel = isCompleted ? 'Completed Forms' : 'Pending Assigned Forms';
  const classes = useStyles();

  if (forms.length === 0) {
    return null;
  }

  const successAndClose = (didSucceed: boolean) => {
    onSubmit(didSucceed);
    setExpandedFile('');
  };

  return (
    <div className={classes.rootComponent}>
      <Grid container direction="column">
        <Typography variant="h3" className={classes.sectionTitle}>
          {formLabel}
        </Typography>
        <Grid item>
          <Grid container className={classes.formList} direction="column">
            {forms.map(form => (
              <Accordion
                expanded={form.id === expandedFile}
                onChange={() =>
                  expandedFile === ''
                    ? setExpandedFile(form.id)
                    : setExpandedFile('')
                }
                key={form.id}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid
                    key={form.id}
                    container
                    className={classes.formListRow}
                    direction="row"
                    spacing={0}
                    alignItems="center"
                  >
                    <Grid item className={classes.messageText} xs={10}>
                      <b>{form.blankForm.get().name}</b>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.form}>
                  <Form
                    formId={form.id}
                    onSubmit={successAndClose}
                    key={form.id}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
