import {yupResolver} from '@hookform/resolvers';
import {Grid, List, ListItem, Typography} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {capitalize} from 'lodash';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {CreateClientConfigurationModal} from '../CreateClientConfiguration/CreateClientConfigurationModal';
import {useStyles} from './styles';
import {useApi} from '@src/api/useApi';
import {TextInput} from '@src/components/ui/form/TextInput';
import {MuiCheckBoxInput} from '@src/components/ui/form/MuiCheckBoxInput';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {flashSuccess} from '@src/components/shared/notifications/flash';
import {SelectInput} from '@src/components/ui/form';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';
import {TimeZoneDropdown} from '@src/components/ui/molecules/selectors/TimeZoneDropdown';
import {useAll} from '@src/hooks/invoiceTargets/useAll';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {allLoaded} from '@src/util/apiHelpers';
import {HelpToolTip} from '@src/components/ui/atoms/buttonsAndIcons/HelpToolTip';
import {ClientConfiguration, PaymentControl} from '@src/models';
import {isNothing, isSomething} from '@src/util/typeTests';
import {NAMES} from '@src/components/ui/layout/text/names';
import {CustomerCodesMultiselect} from '@src/components/ui/organisms/selectors/CustomerCodesMultiselect';
import {TextButton} from '@src/components/ui/atoms/buttonsAndIcons/TextButton';
import {FormContainer} from '@src/components/ui/molecules/layouts/FormContainer';

const schema = yup.object().shape({
  approved: yup.bool().required(),
  nameForProviders: yup.string().required('Please type a name for providers'),
  description: yup.string().required('Please type a description'),
  timeZone: yup.string().required('Please select a time zone'),
  invoiceTargetId: yup.string().required('Please select a Customer'),

  // Visit Rules
  visitsUnsupported: yup.bool(),
  documentInAhana: yup.bool(),
  creditCard: yup.string().when('visitsUnsupported', {
    is: false,
    then: yup.string().required('Please select a Credit Card rule'),
  }),
  nameForGuardians: yup.string().when('visitsUnsupported', {
    is: false,
    then: yup
      .string()
      .required('Please type a name for guardians and patients'),
  }),
  insurance: yup.string().when('visitsUnsupported', {
    is: false,
    then: yup.string().required('Please select an Insurance rule'),
  }),
});

export function ProviderGroupForm() {
  const api = useApi();
  const classes = useStyles();

  const [submitting, setSubmitting] = useState(false);

  const [invoiceTargets, reloadInvoiceTargets] = useAll();
  const [
    clientConfigurationsForSelectedInvoiceTarget,
    setClientConfigurationsForSelectedInvoiceTarget,
  ] = useState<ClientConfiguration[]>([]);
  const [
    showCreateClientConfigurationModal,
    setShowCreateClientConfigurationModal,
  ] = useState(false);

  const [selectedCustomerCodes, setSelectedCustomerCodes] = useState<
    {label: string; value: number}[]
  >([]);

  const customerCodeOptions = clientConfigurationsForSelectedInvoiceTarget.map(
    config => ({
      label: config.customerCode,
      value: config.id,
    }),
  );

  const defaultValues = {
    approved: false,
    nameForProviders: '',
    nameForGuardians: '',
    description: '',
    documentInAhana: false,
    visitsUnsupported: false,
    creditCard: '',
    insurance: '',
    timeZone: '',
    // This is a string because of some edge cases in the way
    // the SelectInput displays the placeholder and error messages
    // if null, 0, or NaN is provided.
    invoiceTargetId: '',
  };

  const {control, errors, handleSubmit, reset, watch, getValues} = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const toggleVisitsUnsupported = useCallback(
    (newVisitsUnsupported: boolean) => {
      if (newVisitsUnsupported) {
        reset({
          ...getValues(),
          creditCard: '',
          nameForGuardians: '',
          documentInAhana: false,
          insurance: '',
        });
      }
    },
    [getValues, reset],
  );

  const visitsUnsupportedValue = watch('visitsUnsupported');

  const invoiceTargetIdValue = watch('invoiceTargetId');

  useEffect(() => {
    if (invoiceTargets.isLoaded() && !isNothing(invoiceTargetIdValue)) {
      const newClientConfigurations = invoiceTargets
        .getAllOptional()
        .orElse([])
        .find(it => it.id === parseInt(invoiceTargetIdValue))
        ?.clientConfigurations.orElse([]);

      setClientConfigurationsForSelectedInvoiceTarget(
        newClientConfigurations ?? [],
      );
    }
  }, [invoiceTargetIdValue, invoiceTargets]);

  const formHandler = handleSubmit(
    (data: {
      insurance?: PaymentControl;
      creditCard?: PaymentControl;
      visitsUnsupported?: boolean;
      invoiceTargetId: string;
    }) => {
      setSubmitting(true);
      const {
        insurance,
        creditCard,
        invoiceTargetId,
        visitsUnsupported,
        ...rest
      } = data;

      api
        .callPool()
        .create({
          rules: {
            payments: visitsUnsupported ? undefined : {insurance, creditCard},
            visitsUnsupported,
          },
          invoiceTargetId: parseInt(invoiceTargetId),
          clientConfigurationIds: selectedCustomerCodes.map(c => c.value),
          ...rest,
        })
        .then(() => {
          reset({...defaultValues});
          setSelectedCustomerCodes([]);
          flashSuccess(`${capitalize(NAMES.callPool)} created successfully!`);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  );

  const toggleShowCreateClientConfigurationModal = useCallback(() => {
    setShowCreateClientConfigurationModal(!showCreateClientConfigurationModal);
  }, [
    setShowCreateClientConfigurationModal,
    showCreateClientConfigurationModal,
  ]);

  const onCustomerCodeCreated = useCallback(async () => {
    await reloadInvoiceTargets();
  }, [reloadInvoiceTargets]);

  return (
    <PageLoading active={!allLoaded(invoiceTargets)} message="">
      <FormContainer
        submitButton={
          <MuiAsyncActionButton
            bStyle="primary"
            bSize="normal"
            onClick={formHandler}
            actionInProgress={submitting}
            actionWord="Submit"
            disabled={submitting}
          >
            Submit
          </MuiAsyncActionButton>
        }
      >
        <Controller
          name="nameForProviders"
          control={control}
          render={({onChange, onBlur, value, name}) => (
            <TextInput
              value={value}
              name={name}
              required
              placeholder="Name shared with professional users"
              title="Name For Professionals"
              onChange={onChange}
              onBlur={onBlur}
              error={errors.nameForProviders?.message}
              className={classes.inputField}
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({onChange, onBlur, value, name}) => (
            <TextInput
              value={value}
              name={name}
              placeholder="Description of the group suitable for all users"
              onChange={onChange}
              onBlur={onBlur}
              required
              title="Description"
              className={classes.inputField}
              error={errors.description?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="timeZone"
          render={({onChange, value}) => (
            <TimeZoneDropdown
              required
              timeZone={value}
              handleChangeTimeZone={onChange}
              errorMessage={errors.timeZone?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="invoiceTargetId"
          render={({onChange, value, name}) => (
            <SelectInput
              required
              value={value}
              name={name}
              title="Customer"
              onChange={onChange}
              placeholder="Customer"
              options={invoiceTargets
                .getAllOptional()
                .orElse([])
                .map(target => ({
                  label: target.customerName,
                  value: `${target.id}`,
                }))}
              error={errors.invoiceTargetId?.message}
            />
          )}
        />

        <div className={classes.inputField}>
          <Controller
            control={control}
            name="approved"
            render={({onChange, value, name}) => (
              <MuiCheckBoxInput
                value={value}
                name={name}
                title="This Group should be available for use on creation"
                onChange={onChange}
                required
                error={errors.approved?.message}
              />
            )}
          />
        </div>

        <Grid item className={classes.callPoolRulesContainer}>
          <Typography variant="h6" style={{marginTop: '16px'}}>
            Visit Rules
          </Typography>
          <List style={{width: '100%'}}>
            <ListItem>
              <Controller
                name="visitsUnsupported"
                control={control}
                render={({onChange, value, name}) => (
                  <MuiCheckBoxInput
                    value={value}
                    name={name}
                    title="Visits Unsupported"
                    onChange={v => {
                      onChange(v);
                      toggleVisitsUnsupported(v);
                    }}
                  />
                )}
              />
            </ListItem>
            <ListItem>
              <Controller
                name="documentInAhana"
                control={control}
                render={({onChange, value, name}) => (
                  <MuiCheckBoxInput
                    value={value}
                    name={name}
                    title="Document in Refyne Connected Care"
                    onChange={onChange}
                    disabled={visitsUnsupportedValue}
                  />
                )}
              />
            </ListItem>
            <ListItem>
              <Controller
                name="nameForGuardians"
                control={control}
                render={({onChange, onBlur, value, name}) => (
                  <TextInput
                    value={value}
                    name={name}
                    required={!visitsUnsupportedValue}
                    placeholder="Name shared with guardians and patients"
                    title="Name For Guardians/Patients"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors.nameForGuardians?.message}
                    className={classes.inputField}
                    disabled={visitsUnsupportedValue}
                  />
                )}
              />
            </ListItem>
            <ListItem>
              <Controller
                control={control}
                name="insurance"
                render={({onChange, value, name}) => (
                  <SelectInput
                    required={!visitsUnsupportedValue}
                    value={value}
                    name={name}
                    title="Insurance"
                    onChange={onChange}
                    placeholder="Is Insurance required, optional, or neither?"
                    options={[
                      {label: "Don't Ask", value: 'None'},
                      {label: 'Optional', value: 'Optional'},
                      {label: 'Required', value: 'Required'},
                    ]}
                    error={errors.insurance?.message}
                    disabled={visitsUnsupportedValue}
                  />
                )}
              />
            </ListItem>
            <ListItem>
              <Controller
                control={control}
                name="creditCard"
                render={({onChange, value, name}) => (
                  <SelectInput
                    value={value}
                    name={name}
                    required={!visitsUnsupportedValue}
                    title="Credit Card"
                    onChange={onChange}
                    placeholder="Is Credit Card info required, optional, or neither?"
                    options={[
                      {label: "Don't Ask", value: 'None'},
                      {
                        label: 'Optional',
                        value: 'Optional',
                        disabled: true,
                      },
                      {
                        label: 'Required',
                        value: 'Required',
                        disabled: true,
                      },
                    ]}
                    error={errors.creditCard?.message}
                    disabled={visitsUnsupportedValue}
                  />
                )}
              />
              <HelpToolTip text="Please contact Support to set up credit card processing." />
            </ListItem>
            <ListItem>
              <CustomerCodesMultiselect
                selections={selectedCustomerCodes}
                options={customerCodeOptions}
                handleChange={event => {
                  setSelectedCustomerCodes(
                    (event.target.value as string[])
                      .map(l =>
                        customerCodeOptions.find(val => val.label === l),
                      )
                      .filter(isSomething),
                  );
                }}
                disabled={!invoiceTargetIdValue || visitsUnsupportedValue}
              />
              {!invoiceTargetIdValue && (
                <HelpToolTip
                  text={`Please select a ${toTitleCase(
                    NAMES.customer,
                  )} above to re-enable this field.`}
                />
              )}
            </ListItem>
            <ListItem>
              <TextButton
                title={`Create new ${toTitleCase(NAMES.customerCode)}`}
                onClick={toggleShowCreateClientConfigurationModal}
                disabled={!invoiceTargetIdValue || visitsUnsupportedValue}
              />
            </ListItem>
          </List>
        </Grid>

        <Grid>
          {showCreateClientConfigurationModal && (
            <CreateClientConfigurationModal
              onCreated={onCustomerCodeCreated}
              show
              onClose={toggleShowCreateClientConfigurationModal}
              invoiceTargetId={parseInt(invoiceTargetIdValue)}
            />
          )}
        </Grid>
      </FormContainer>
    </PageLoading>
  );
}
