import * as React from 'react';
import * as yup from 'yup';
import {Controller, useFormContext} from 'react-hook-form';
import {SelectInput, TextInput} from '../../../ui/form';
import {FormSection} from './FormSection';
import {yString} from '@src/schema/types';
import {getRequiredMessage} from '@src/util/forms/getRequiredMessage';

export const planSchema = {
  plan_medications_continue: yString,
  plan_medications_start: yString,
  plan_what_now: yString,
  plan_follow_up: yup
    .string()
    .when('$isSigning', (isSigning: boolean, s: yup.StringSchema) =>
      isSigning ? s.required(getRequiredMessage('a', 'follow-up time')) : s,
    ),
  plan_followup_other: yString,
};

export type PlanValues = {
  plan_medications_continue: string;
  plan_medications_start: string;
  plan_what_now: string;
  plan_follow_up: string;
  plan_followup_other: string;
};

export const defaultPlanValues = (): PlanValues => ({
  plan_follow_up: '',
  plan_followup_other: '',
  plan_medications_continue: '',
  plan_medications_start: '',
  plan_what_now: '',
});

export const PlanLabels = {
  plan_what_now: 'Plan',
  plan_follow_up: 'When to follow up',
  plan_followup_other: 'Please add more detail on when to follow up',
  plan_medications_continue: 'Medications to continue',
  plan_medications_start: 'Medications to start',
};

const followUpOptions = [
  'Now',
  'Tomorrow',
  'Within 48h',
  '1 - 2 weeks',
  'As needed',
  'Other',
].map(s => ({label: s, value: s}));

export function PlanSection({isSigning}: {isSigning: boolean}) {
  const {errors, control, watch} = useFormContext<PlanValues>();
  const otherFollowUp = watch('plan_follow_up');

  return (
    <FormSection title="Plan">
      <Controller
        control={control}
        name="plan_what_now"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            autoExpand
            title={PlanLabels.plan_what_now}
            placeholder="What is the plan of action?"
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            touched={isTouched}
            error={errors.plan_what_now?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="plan_follow_up"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <SelectInput
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            touched={isTouched || !!errors.plan_follow_up}
            required
            options={followUpOptions}
            placeholder="Choose a timeframe"
            title={PlanLabels.plan_follow_up}
            error={errors.plan_follow_up?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="plan_followup_other"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            touched={isTouched}
            hidden={otherFollowUp !== 'Other'}
            autoExpand
            title={PlanLabels.plan_followup_other}
            placeholder=""
            error={errors.plan_followup_other?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="plan_medications_continue"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            touched={isTouched}
            autoExpand
            title={PlanLabels.plan_medications_continue}
            placeholder="Medications to continue"
            error={errors.plan_medications_continue?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="plan_medications_start"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            touched={isTouched}
            autoExpand
            title={PlanLabels.plan_medications_start}
            placeholder="Medications to start"
            error={errors.plan_medications_start?.message}
          />
        )}
      />
    </FormSection>
  );
}
