import React, {useState, useEffect, useCallback} from 'react';
import {useHistory} from 'react-router';
import {connect as reduxConnect} from 'react-redux';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {addBreadcrumb, Severity} from '@sentry/browser';
import {RemoteParticipant, Room} from 'twilio-video';
import {Redirect} from 'react-router-dom';
import * as actions from '../../../store/actions';
import {PageLoading} from '../../ui/atoms/progressBarsAndIndicators/PageLoading';
import {SIDEBAR_WIDTH} from '../VideoChat/layout';
import {Header} from './Header';
import {AnonymousSidebar} from './AnonymousSidebar';
import {ExamRoomModals} from './ExamRoomModals';
import {VideoGallery} from './VideoGallery';
import {Reconnecting} from './ExamRoomModals/Reconnecting';
import {useStyles, DeadEndError} from './layout';
import {useViewportHeight} from '@src/hooks/useViewportHeight';
import {Modal} from '@src/components/shared/VideoChat/ExamRoomModals';
import {VisitState, UserType, SafeVisit} from '@src/models';
import {useAsync} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {
  onRoomConnected,
  setUpRoom,
} from '@src/util/videoChat/twilio/anonymousVisitor';

import {
  MediaFailure,
  useDestination,
  bail,
  ejectFromRoom,
  disconnectFromTwilioRoom,
  stopTracks,
  isRoomConnected,
  reconnectToTwilioRoom,
  isLocalParticipantAdmitted,
} from '@src/util/videoChat';

import {EJECT_STATES} from '@src/util/visits';
import {useVideoStyles} from '@src/components/ui/layout/VideoStyles';
import {useRecalculateVideoLayout} from '@src/hooks/videoChat/useRecalculateVideoLayout';
import {useSafeVisitUpdateEvents} from '@src/hooks/socket';

type DispatchProps = {
  setVisitOccurred: (v: boolean) => void;
  setHairChecked: (id: number) => void;
};

const mapDispatchToProps: DispatchProps = {
  setVisitOccurred: actions.setVisitOccurred,
  setHairChecked: actions.setHairChecked,
};

type OwnProps = {
  roomName: string;
  token: string;
  visitId: number;
};

type Props = DispatchProps & OwnProps;

const _AnonymousExamRoom = ({
  roomName,
  token,
  visitId,
  setHairChecked,
  setVisitOccurred,
}: Props) => {
  const [room, setRoom] = useState<Room | null>(null);
  const [participants, setParticipants] = useState<RemoteParticipant[]>([]);

  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [cutRoomAudio, setCutRoomAudio] = useState(false);

  const [mediaFailure, setMediaFailure] = useState(
    Optional.empty<MediaFailure>(),
  );

  const [aVisit, setVisit] = useAsync<SafeVisit>();

  // UI
  const [notification, setNotification] = useState<JSX.Element | null>(
    <>Waiting for participants...</>,
  );
  const [modal, showModal] = useState<Modal>('none');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showReloadingModal, setShowReloadingModal] = useState(false);
  const [selfWaitingForAdmittance, setSelfWaitingForAdmittance] = useState(
    !isLocalParticipantAdmitted({room}),
  );

  const visitState = aVisit
    .getOptional()
    .map(v => v.status)
    .orElse(VisitState.UNKNOWN);

  const videoStyles = useVideoStyles();

  const [getDestination] = useDestination(
    UserType.Unknown,
    visitId,
    visitState,
  );

  const api = useApi();
  const viewportHeight = useViewportHeight();
  const classes = useStyles({
    width: SIDEBAR_WIDTH,
  });
  const history = useHistory();

  useSafeVisitUpdateEvents(
    aVisit
      .getOptional()
      .map(v => [v.id])
      .orElse([]),
    v => {
      console.log(`Update from visit ${v.id}`);
      setVisit(v);
    },
  );

  useEffect(() => {
    async function getVisit() {
      addBreadcrumb({
        category: 'exam-room',
        message: 'Loading visit',
        data: {visitId},
        level: Severity.Info,
      });

      setHairChecked(visitId);

      const v = await api.visit(visitId).getSafe();

      setVisit(v);
    }

    getVisit().catch(e => {
      console.error('Startup Error');
      console.error(e);
      bail({
        e,
        message:
          'We hit an error getting the room set up. Please contact support for more assistance.',
        isSerious: true,
        getDestination,
        showModal,
        history,
      });
    });
  }, [api, getDestination, history, setHairChecked, setVisit, visitId]);

  useEffect(() => {
    if (participants.length === 0) {
      setNotification(<>Waiting for participants...</>);
    } else {
      setNotification(null);
    }
  }, [participants]);

  useRecalculateVideoLayout();

  useEffect(() => {
    setUpRoom({
      visitId,
      token,
      setRoom,
    });

    return () => {
      setRoom(currentRoom => {
        if (isRoomConnected({room: currentRoom})) {
          stopTracks({room: currentRoom});
          disconnectFromTwilioRoom({room: currentRoom});
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token, visitId]);

  useEffect(() => {
    if (isRoomConnected({room})) {
      onRoomConnected({
        room,
        setParticipants,
        setNotification,
        setShowReloadingModal,
        setIsDisconnecting,
      });
    }
  }, [room]);

  useEffect(() => {
    function handlePageLeave() {
      setIsDisconnecting(true);
      stopTracks({room});
      disconnectFromTwilioRoom({room});
      window.localStorage.removeItem('redirect');
    }

    window.addEventListener('beforeunload', handlePageLeave);
    window.addEventListener('popstate', handlePageLeave);

    return () => {
      ['beforeunload', 'popstate'].forEach(eventName => {
        window.removeEventListener(eventName, handlePageLeave);
      });
    };
  }, [room]);

  useEffect(() => {
    console.debug(`STATE CHANGE: ${visitState}`);
    if (visitState === VisitState.IN_PROGRESS) {
      setVisitOccurred(true);
    }

    if (EJECT_STATES.includes(visitState)) {
      ejectFromRoom({
        reason: `Ejecting anonymous visitor due to ${visitState} state`,
        room,
        showModal,
        history,
        returnPath: getDestination('temporary'),
      });
    }
  }, [getDestination, history, setVisitOccurred, room, visitState]);

  const handleReconnect = useCallback(() => {
    if (room) {
      disconnectFromTwilioRoom({
        room,
      });
    }

    reconnectToTwilioRoom({
      visit: Optional.empty(),
      room,
      participants,
    });
  }, [room, participants]);

  const error = DeadEndError(aVisit);

  if (error) {
    return error;
  }

  if (mediaFailure.isPresent()) {
    return <Redirect to={`/waiting-room/${visitId}`} />;
  }

  return (
    <div
      className={videoStyles.examRoomContainer}
      style={{
        height: viewportHeight,
        backgroundColor: '#3a3a3e',
      }}
    >
      {showReloadingModal && (
        <Reconnecting
          visit={Optional.empty()}
          bail={bail}
          onReconnect={handleReconnect}
          shouldShowReloadingModal={showReloadingModal}
          showModal={showModal}
          userType={UserType.Unknown}
        />
      )}

      <PageLoading
        fullSize={true}
        active={!room}
        message="Guiding you to the Exam Room"
        className={classes.loading}
      >
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          notification={notification}
          remoteParticipants={participants}
          waitingForAdmittance={selfWaitingForAdmittance}
        />
        <AnonymousSidebar
          visitId={visitId}
          showModal={showModal}
          sidebarOpen={sidebarOpen}
          room={room}
          participants={participants}
          setSidebarOpen={setSidebarOpen}
        />

        <div
          id={sidebarOpen ? 'content-drawer-open' : 'content-drawer-closed'}
          className={
            sidebarOpen
              ? classes.contentDrawerOpen
              : classes.contentDrawerClosed
          }
        >
          <VideoGallery
            participants={participants}
            room={room}
            isDisconnecting={isDisconnecting}
            cutRoomAudio={cutRoomAudio}
            setNotification={setNotification}
            onMediaFailure={failure => setMediaFailure(failure)}
            visitId={visitId}
            selfWaitingForAdmittance={selfWaitingForAdmittance}
            setSelfWaitingForAdmittance={setSelfWaitingForAdmittance}
          />
        </div>

        <ExamRoomModals
          onCancelPauseOrComplete={() => {
            setCutRoomAudio(false);
          }}
          modal={modal}
          showModal={showModal}
          onLeave={() => {
            ejectFromRoom({
              reason: `Ejecting ${UserType.Unknown} because they manually paused the visit temporarily`,
              room,
              showModal,
              history,
              returnPath: getDestination('temporary'),
            });
          }}
        />
      </PageLoading>
    </div>
  );
};

export const AnonymousExamRoom = reduxConnect(
  null,
  mapDispatchToProps,
)(_AnonymousExamRoom);
