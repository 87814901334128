import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useMemo, useState} from 'react';
import {useParams} from 'react-router';
import {useResources, useUser} from '..';
import {useApi} from '@src/api/useApi';
import {Panels} from '@src/components/shared/PatientDashboard/SCP/SCPTabs/types';
import {
  AppFile,
  LonelyPatient,
  MedicalHistory,
  ProviderPartner,
  SCPChangeRequest,
  SharedCarePlan,
} from '@src/models';
import {UserId, UserType} from '@src/models/User';
import {getAllPages} from '@src/util/apiHelpers';
import {isPartner, sharingSCPInVisit} from '@src/util/sharedCarePlan';
import {getScpOwnerProviderId} from '@src/util/sharedCarePlan/ownership';

export type ScpResources = {
  patient: AsyncData<LonelyPatient>;
  setPatient: (v: LonelyPatient) => unknown;
  scp: AsyncData<SharedCarePlan>;
  reloadScp: () => Promise<unknown>;
  setScp: (v: SharedCarePlan) => unknown;
  currentMedicalHistory: AsyncData<MedicalHistory>;
  setCurrentMedicalHistory: (v: MedicalHistory) => void;
  selectedPanel: Panels;
  selectPanel: (v: Panels) => unknown;
  files: AsyncData<AppFile>;
  setFiles: (v: AppFile[]) => unknown;
  changeRequests: AsyncData<SCPChangeRequest>;
  reloadChangeRequests: () => Promise<unknown>;
  hasScp: boolean;
  isOwner: boolean;
  ownerId: UserId;
  isUserPartner: boolean;
  ownerPartners: AsyncData<ProviderPartner>;
  reviewMode: boolean;
};

export function useScpResources() {
  const api = useApi();
  const [user, userType] = useUser();
  const userId = user
    .getOptional()
    .map(u => u.id)
    .orElse(0 as UserId);

  const [selectedPanel, selectPanel] = useState<Panels>('summaryView');

  const params = useParams<{patientId: string}>();

  const [patient, , setPatient] = useResources<LonelyPatient>(
    () => api.patient(+params.patientId).get(),
    [api, +params.patientId],
  );

  const hasScp = patient.getOptional().property('hasScp', false);

  const [scp, reloadScp, setScp] = useResources<SharedCarePlan>(
    () => api.patient(patient.singleValue().id).getSCP(),
    [api, patient],
    {
      // This use of request gate means that if the Patient
      // has no SCP, the status will be AsyncData.NotAsked
      // until User creates an SCP for the Patient.
      requestGate: () => hasScp,
    },
  );

  const [currentMedicalHistory, , setCurrentMedicalHistory] = useResources(
    () => api.patient(+params.patientId).getCurrentMedicalHistory(),
    [api, patient],
    {requestGate: () => patient.isLoaded()},
  );

  const scpId = scp
    .getOptional()
    .map(s => s?.id)
    .orElse(0);

  const [files, , setFiles] = useResources<AppFile>(
    () => api.scp(scpId).getFiles(),
    [api, scp],
    // This use of request gate means that if the Patient
    // has no SCP, the status will be AsyncData.NotAsked
    // until User creates an SCP for the Patient.
    {requestGate: () => scp.isLoaded() && hasScp},
  );

  const [changeRequests, reloadChangeRequests] = useResources<SCPChangeRequest>(
    () =>
      getAllPages(options => {
        return api.scp(scpId).getChangeRequestsLog(options);
      }),
    [api, scp],
    {requestGate: () => scp.isLoaded() && hasScp},
  );

  const ownerId = getScpOwnerProviderId(scp) ?? (0 as UserId);
  const isOwner = userId === ownerId;

  const [ownerPartners] = useResources<ProviderPartner>(
    () =>
      api
        .provider(ownerId)
        .getPartners()
        .then(ps =>
          // Get partnerships where the SCP owner matches providerDetails.

          ps.filter(p =>
            p.inviterDetails.map(d => d.providerId === ownerId).orElse(false),
          ),
        ),
    [api, userId, ownerId],
    {
      requestGate: () => {
        return userType === UserType.Professional && ownerId !== 0;
      },
    },
  );

  const isUserPartner = isPartner({
    inviterUserId: ownerId,
    maybePartnerUserId: userId,
    partnerships: ownerPartners.getAllOptional().orElse([]),
  });

  const reviewMode = sharingSCPInVisit();

  return useMemo(
    () => ({
      scp,
      isOwner,
      reloadScp,
      setScp,
      patient,
      currentMedicalHistory,
      setCurrentMedicalHistory,
      selectPanel,
      selectedPanel,
      setPatient,
      files,
      setFiles,
      changeRequests,
      reloadChangeRequests,
      hasScp,
      ownerId,
      isUserPartner,
      ownerPartners,
      reviewMode,
    }),
    [
      scp,
      isOwner,
      reloadScp,
      setScp,
      patient,
      currentMedicalHistory,
      setCurrentMedicalHistory,
      selectedPanel,
      setPatient,
      files,
      setFiles,
      changeRequests,
      reloadChangeRequests,
      hasScp,
      ownerId,
      isUserPartner,
      ownerPartners,
      reviewMode,
    ],
  );
}
