import {makeStyles} from '@material-ui/core/styles';
import {md, sm} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  modalListContainer: (props: {loading?: boolean}) => ({
    minWidth: props.loading ? '45vw' : 'fit-content',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  }),
  section: {
    marginBottom: md,
  },
  header: {
    marginBottom: sm,
  },
  deleteIcon: {
    color: theme.palette.error.main,
  },
  disabledIcon: {
    fontSize: 16,
    marginRight: 'auto',
    padding: 0,
    color: theme.palette.grey[300],
  },
}));
