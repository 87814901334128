import * as React from 'react';
import {Patient} from '@src/models';
import {Button} from '@src/components/ui/form';

type Props = {
  patient: Patient;
  blockButton?: boolean;
  onClick?: React.MouseEventHandler;
};

export const EditFinanciallyResponsiblePerson = ({
  patient,
  blockButton = false,
  onClick,
}: Props) => {
  return (
    <>
      <Button
        className="edit-patient"
        bStyle="secondary"
        bSize="small"
        block={blockButton}
        onClick={e => {
          if (onClick) {
            onClick(e);
          }
        }}
      >
        {patient.financialRelationship.isPresent() ? 'Edit' : 'Add'} Responsible
        Person
      </Button>
    </>
  );
};
