import {yupResolver} from '@hookform/resolvers';
import React from 'react';
import {useForm} from 'react-hook-form';
import {StepProps} from '../../stepFunctions';
import {
  medicalHistorySchema,
  MedicalHistoryValues,
  PatientMedicalHistoryForm,
} from './PatientMedicalHistoryForm';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {FlowProps} from '@src/hooks/useSteps';

export function MedicalHistoryStep({
  moveGuard,
  patientDetails,
  setMedicalHistory,
  medicalHistory,
  patientIsCurrentUser,
}: StepProps & FlowProps) {
  moveGuard.current = () =>
    new Promise(res => {
      handleSubmit(
        data => {
          setMedicalHistory(data);
          res(true);
        },
        () => res(false),
      )();
    });

  const {errors, control, handleSubmit} = useForm<MedicalHistoryValues>({
    defaultValues: medicalHistory,
    resolver: yupResolver(medicalHistorySchema),
  });

  return (
    <>
      <RequestHeader>Medical History</RequestHeader>
      <RequestInstructions>
        <p>
          Please provide any medical history you can
          {patientIsCurrentUser ? '' : ' for this patient'}.
        </p>
      </RequestInstructions>
      <PatientMedicalHistoryForm
        errors={errors}
        control={control}
        patientName={patientDetails.firstName}
        hasScp={false}
        patientIsUser={patientIsCurrentUser}
      />
    </>
  );
}
