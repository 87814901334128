/**
 * As long as the two Patients don't have a different PCP, this function can automatically
 * decide which PCP to select.
 */

import {ProviderDetails} from '@src/models';
import {isNothing} from '@src/util/typeTests';

export function autoSelectPcp({
  pcp1,
  pcp2,
}: {
  pcp1: ProviderDetails | null;
  pcp2: ProviderDetails | null;
}) {
  const noPcps = isNothing(pcp1) && isNothing(pcp2);
  const noPcp1 = isNothing(pcp1);
  const noPcp2 = isNothing(pcp2);

  if (noPcps) {
    return null;
  }

  if (noPcp1 && !noPcp2) {
    return pcp2;
  }

  if (noPcp2 && !noPcp1) {
    return pcp1;
  }

  return pcp1;
}
