import React from 'react';
import {Loading} from './Loading';
import {MakeIndeterminate} from './MakeIndeterminate';

/**
 *
 * This is like `PageLoading`, but it:
 * 1) Has a time delay to avoid visual flashes if a resource loads
 * really quickly.
 * 2) Is intended for smaller areas, not a full page or section.
 */
export function LoadingIndeterminate({
  active,
  children,
  customDelayMs,
}: {
  active: boolean;
  children?: React.ReactNode;
  customDelayMs?: number;
}) {
  return (
    <>
      {MakeIndeterminate({
        active,
        customDelayMs,
        loadingIndicator: <Loading />,
        children,
      })}
    </>
  );
}
