import React from 'react';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {TransferOwnership} from '@src/components/shared/PatientDashboard/SCP/panels/controls/shared/TransferOwnership';

export function GuardianControls() {
  const {selectedPanel} = usePageContext();

  if (selectedPanel !== 'guardianControls') {
    return null;
  }

  return <TransferOwnership />;
}
