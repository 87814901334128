import React, {useState, useCallback} from 'react';
import {EditPendingLoginModal} from '../EditPendingLoginModal';
import {Connection} from './Connection';
import {ConnectionText, ConnectionTextContext} from './ConnectionText';
import {Invitation, LonelyPatient} from '@src/models';

type Props = {
  className?: string;
  invitation: Invitation;
  onDelete: () => unknown;
  patient: LonelyPatient;
};
export function PendingRelationship({
  className = '',
  invitation,
  onDelete,
  patient,
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const {email, isSelf = false, relationshipName} = invitation;

  const onClose = useCallback(() => setShowModal(false), []);

  return (
    <>
      <Connection
        icon="pencil"
        className={className}
        isSelf={isSelf}
        edittable={true}
        patient={patient}
        onClickConnectionAction={() => setShowModal(true)}
      >
        <ConnectionText
          context={ConnectionTextContext.Pending}
          isSelf={isSelf}
          email={email}
          relationshipName={relationshipName}
        />
      </Connection>

      {showModal && (
        <EditPendingLoginModal
          show={showModal}
          onClose={onClose}
          invitation={invitation}
          patientId={patient.id}
          onDelete={onDelete}
        />
      )}
    </>
  );
}
