import {format, parseISO} from 'date-fns';

export const mergeDateAndTime = (date: Date | null, time: string = '') => {
  let d = date;
  if (d === null) {
    d = new Date();
  }

  let t = time;
  if (time.trim() === '') {
    t = format(new Date(), 'HH:mm');
  }

  return parseISO(format(d, `yyyy-MM-dd'T${t}:00'`));
};
