import {ConnectedLogin} from '../../../stepFunctions';
import {findExistingPerson} from '../utils/findExistingPerson';
import {signUpSelfAndLink} from './signUpSelfAndLink';
import {checkForConflictsAndLink} from './checkForConflictsAndLink';
import {LonelyPatient} from '@src/models';
import {AppAPI} from '@src/api/AppAPI';
import {isNothing} from '@src/util/typeTests';

export const maybeSetUpSelfRelationship = async ({
  api,
  connectedLogins,
  patient,
}: {
  patient: LonelyPatient;
  connectedLogins: ConnectedLogin[];
  api: AppAPI;
}) => {
  const selfConnectedLogin = connectedLogins.find(login => login.isSelf);

  if (isNothing(selfConnectedLogin)) {
    return;
  }

  const existingPersonForSelf = await findExistingPerson(
    selfConnectedLogin.email,
    api,
  );

  if (!isNothing(existingPersonForSelf)) {
    await checkForConflictsAndLink(existingPersonForSelf, api, patient);
  } else {
    await signUpSelfAndLink({api, selfConnectedLogin, patient});
  }
};
