import {Grid} from '@material-ui/core';
import {isPast, format} from 'date-fns';
import React, {useState, ReactNode} from 'react';
import {OnCallPeriodPurpose} from '..';
import {TimeSection, CallPoolsSection} from '../ModalSections';
import {useProviderGroups} from '../hooks/useProviderGroups';
import {isPopulatedValues, Errors} from '../functions';
import {DayInput, DaysContainer} from './layout';
import {
  RepeatingPeriodValues,
  validate,
  getUnpopulatedRequests,
  WEEK_ARRAY,
  getPopulatedRequests,
} from './functions';
import {useApi} from '@src/api/useApi';
import {AsyncActionButton, DateInput} from '@src/components/ui/form';
import {ErrorMessage} from '@src/components/ui/form/ErrorMessage';
import {Modal} from '@src/components/ui/layout/Modal';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {OnCallPeriod, CallPool} from '@src/models';
import {UserId} from '@src/models/User';
import {flashError} from '@src/components/shared/notifications/flash';

type Props = {
  availableGroups?: CallPool[];
  onClose: () => unknown;
  onCreateOnCallPeriods: (periods: OnCallPeriod[]) => unknown;
  purpose: OnCallPeriodPurpose;
  providerId?: UserId;
  show: boolean;
  preselectedProviderGroups?: CallPool[];
  requiredGroupsSelections?: CallPool[];
};

export function RepeatingPeriodModal({
  availableGroups,
  onClose,
  onCreateOnCallPeriods,
  providerId,
  purpose,
  show,
  preselectedProviderGroups = [],
  requiredGroupsSelections = [],
}: Props) {
  const api = useApi();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const providerGroups = useProviderGroups(availableGroups, providerId);

  const [selectedProviderGroups, setSelectedProviderGroups] = useState<
    number[]
  >(preselectedProviderGroups.map(g => g.id));
  const [errors, setErrors] = useState<Errors<RepeatingPeriodValues>>({});
  const [saving, setSaving] = useState(false);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);

  const now = new Date();

  const onSave = (values: RepeatingPeriodValues) => {
    const errs = validate(values);
    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      return Promise.reject();
    }

    const initialDate = values.startDate;
    if (!initialDate) {
      return Promise.reject();
    }
    const finalDate = values.endDate;
    if (!finalDate) {
      return Promise.reject();
    }

    if (isPopulatedValues(values)) {
      const coverageRequests = getPopulatedRequests(
        initialDate,
        finalDate,
        values,
      );

      return Promise.all(
        coverageRequests.map(rq => {
          return api.onCallPeriods().createPopulated(rq);
        }),
      );
    } else {
      const coverageRequests = getUnpopulatedRequests(
        initialDate,
        finalDate,
        values,
      );

      return Promise.all(
        coverageRequests.map(rq => {
          return api.onCallPeriods().createUnpopulated(rq);
        }),
      );
    }
  };

  let modalTitle: string;
  let instructions: [ReactNode, ReactNode, ReactNode];
  if (purpose === 'Shift') {
    modalTitle = 'When do the shifts take place?';
    instructions = [
      <>
        <ParagraphText>
          Please fill in the details of the shifts you want to create.
        </ParagraphText>
        <ParagraphText>First, which days do the shifts occur on?</ParagraphText>
      </>,
      <ParagraphText>
        Next, what time do the shifts start and end?
      </ParagraphText>,
      <ParagraphText>
        Finally, how far out do you want to create shifts?
      </ParagraphText>,
    ];
  } else {
    modalTitle = 'Which hours can you cover?';
    instructions = [
      <>
        <ParagraphText>
          Thank you for making yourself available to our patients.
        </ParagraphText>
        <ParagraphText>
          Please fill in the details of when you can provide coverage.
        </ParagraphText>
        <ParagraphText>
          First, which days are you providing coverage?
        </ParagraphText>
      </>,
      <ParagraphText>
        Next, what time will you start and end coverage?
      </ParagraphText>,
      <ParagraphText>
        Finally, when should your coverage start and how long does it repeat
        for?
      </ParagraphText>,
    ];
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={modalTitle}
      modalActions={
        <AsyncActionButton
          actionInProgress={saving}
          actionWord={'Save'}
          disabled={selectedProviderGroups.length === 0}
          onClick={() => {
            setSaving(true);
            onSave({
              providerId,
              startDate,
              endDate,
              startTime: startTime ? format(startTime, 'HH:mm') : '',
              hours,
              minutes,
              selectedDays,
              selectedCallPools: selectedProviderGroups,
            })
              .then(onCreateOnCallPeriods)
              .then(onClose)

              .catch(e => {
                const isServerError = e;

                if (!isServerError) {
                  return;
                } else {
                  flashError(
                    'Something went wrong trying to create this On-Call Period. Please contact support.',
                  );
                }
              })
              .finally(() => {
                setSaving(false);
              });
          }}
        />
      }
    >
      <Grid container direction="row">
        {instructions[0]}
        <div>
          <ErrorMessage message={errors.selectedDays} />
          <DaysContainer>
            {WEEK_ARRAY.map(day => (
              <DayInput
                key={day}
                selected={selectedDays.includes(day)}
                onClick={() => {
                  if (selectedDays.includes(day)) {
                    setSelectedDays(selectedDays.filter(s => s !== day));
                  } else {
                    setSelectedDays([...selectedDays, day]);
                  }
                }}
              >
                {day}
              </DayInput>
            ))}
          </DaysContainer>
        </div>
        {instructions[1]}
        <TimeSection
          errors={errors}
          durationProps={{setHours, hours, setMinutes, minutes}}
          startProps={{setStartDate, setStartTime, startDate, startTime}}
        />

        <ParagraphText>Which Provider Groups are they for?</ParagraphText>
        <CallPoolsSection
          frequency="Repeating"
          providerGroups={providerGroups.getAllOptional().orElse([])}
          purpose={purpose}
          setSelectedProviderGroups={setSelectedProviderGroups}
          selectedProviderGroups={selectedProviderGroups}
          requiredGroupsSelections={requiredGroupsSelections.map(g => g.id)}
        />
        {instructions[2]}

        <DateInput
          name={'endDate'}
          value={endDate}
          onChange={date =>
            setEndDate(date ? (isPast(date) ? now : date) : new Date(0))
          }
          label={'What day should this coverage end?'}
          error={errors.endDate}
        />
      </Grid>
    </Modal>
  );
}
