import React from 'react';
import {useHistory} from 'react-router';
import {Button} from '../ui/form';
import {Modal} from '../ui/layout/Modal';

type Props = {
  onClose: () => unknown;
  show: boolean;
};

export function SessionExpiredModal({onClose, show}: Props) {
  const history = useHistory();

  const actions = (
    <>
      <Button
        bStyle="outlined"
        onClick={() => {
          onClose();
          history.push('/login');
        }}
      >
        Log In
      </Button>
    </>
  );

  return (
    <Modal show={show} title="Session Expired" modalActions={actions}>
      <div>Your session has expired. To continue working, please log in.</div>
    </Modal>
  );
}
