import ConfigService from '@src/ConfigService';
import {SimpleDocument} from '@src/models';

function addPeriodAfter(v: string) {
  return `${v}.`;
}

export function buildS3Url(document: SimpleDocument) {
  const environment = ConfigService.getReleaseInfo().RAVEN_ENVIRONMENT;
  const baseDomain = ConfigService.getBaseDomain();

  return `//forms.${
    environment !== 'production' ? addPeriodAfter(environment) : ''
  }${baseDomain}/${document.url}`;
}
