import {Optional} from '@ahanapediatrics/ahana-fp';
import React from 'react';
import {SearchOrEnter} from '../layout';
import {handleStartSearch} from '../functions';
import {FormMode} from '@src/util/provider/forms/addOrEdit';
import {ProviderDetails} from '@src/models';

type Props = {
  onChange: (pcpInfo: Optional<ProviderDetails>, type: FormMode) => void;
  setEntryMode: (v: 'search' | 'type') => void;
  setShowDetails: (v: boolean) => void;
  setNoProviderSelected: (v: boolean) => void;
  knownProvider: boolean;
};

export function SearchOption({
  onChange,
  setEntryMode,
  setShowDetails,
  setNoProviderSelected,
  knownProvider,
}: Props) {
  return (
    <SearchOrEnter
      onClick={() => {
        handleStartSearch({
          setShowDetails,
          setEntryMode,
          onChange,
          setNoProviderSelected,
        });
      }}
    >
      Search for {knownProvider ? 'different' : ''} doctor instead
    </SearchOrEnter>
  );
}
