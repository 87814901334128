import {IconButton} from '@material-ui/core';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import React, {useEffect, useRef, useState} from 'react';
import {ClickableIcon} from '../../../ui/layout/ClickableIcon';
import {DashboardTable} from '../../DashboardTable';
import {ActionsView, DashCard, DashCardHeader} from '../../DashCard';
import {SCPsPartnersModal} from './SCPsPartnersModal';
import {ProviderScpRow} from './ProviderSCPRow';
import {useStyles} from './styles';
import {LonelySCP} from '@src/models';
import {useAsync, useUser} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {LoadingIndeterminate} from '@src/components/ui/atoms/progressBarsAndIndicators/LoadingIndeterminate';
import {LightParagraphText} from '@src/components/ui/layout/text/body/LightParagraphText';

type Props = {
  className?: string;
  title?: string;
  readonly emptyMessage?: string;
};

export function ProviderSCPs({
  emptyMessage = 'No Shared Care Plans',
  title = 'Your Shared Care Plans',
  className = '',
}: Props) {
  const api = useApi();
  const classes = useStyles();
  const [user] = useUser();
  const [scps, setSCPs] = useAsync<LonelySCP>();

  const [view, setView] = useState<'owner' | 'careTeam'>('owner');
  const [viewModal, setViewModal] = useState(false);

  const mounted = useRef(null);

  const getSCPs = () => {
    setSCPs();
    if (!user.isLoaded()) {
      return;
    }

    const providerAPI = api.provider(user.singleValue().id);

    const scpList =
      view === 'careTeam'
        ? providerAPI.getCareTeamSCPs()
        : providerAPI.getOwnedSCPs();

    scpList.then(ms => {
      if (mounted.current) {
        setSCPs(ms);
      }
    });
  };

  useEffect(() => {
    if (user) {
      getSCPs();
    }
  }, [user, view]);

  const scpsLoaded = scps.isLoaded();

  return (
    <>
      <DashCard className={className} ref={mounted} style={{minHeight: '100%'}}>
        <DashCardHeader className="unreviewed-notes--header">
          <div>{title}</div>
          <IconButton
            onClick={() => {
              setViewModal(true);
            }}
            className={classes.partnershipsIcon}
          >
            <SupervisorAccountIcon />
          </IconButton>
        </DashCardHeader>
        <LoadingIndeterminate active={!scpsLoaded}>
          {scpsLoaded && scps.isEmpty() && (
            <DashboardTable style={{textAlign: 'center'}}>
              <LightParagraphText>{emptyMessage}</LightParagraphText>
            </DashboardTable>
          )}
          {scpsLoaded && !scps.isEmpty() && (
            <DashboardTable>
              <thead>
                <tr>
                  <th />
                  <th>Patient</th>
                  <th>Expiration Date</th>
                  {view === 'owner' && <th>Notifications</th>}
                </tr>
              </thead>
              <tbody>
                {scps.isLoaded() &&
                  !scps.isEmpty() &&
                  scps.mapValue(scp => {
                    return (
                      <ProviderScpRow key={scp.id} scp={scp} view={view} />
                    );
                  })}
              </tbody>
            </DashboardTable>
          )}
        </LoadingIndeterminate>

        <SCPsPartnersModal
          viewModal={viewModal}
          setViewModal={setViewModal}
          user={user}
        />
      </DashCard>
      <ActionsView>
        <div>Care Team</div>
        <ClickableIcon
          icon={'toggle-on'}
          flip={view === 'careTeam' ? 'horizontal' : undefined}
          size="2x"
          onClick={() => {
            if (view === 'careTeam') {
              setView('owner');
            } else {
              setView('careTeam');
            }
          }}
        />
        <div>Owner</div>
      </ActionsView>
    </>
  );
}
