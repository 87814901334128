import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import partition from 'lodash/partition';
import React, {useMemo} from 'react';
import {AssociatedUsers} from './AssociatedUsers';
import {FormRow} from './FormRow';
import {useStyles} from './styles';
import {useApi} from '@src/api/useApi';
import {useUser, useResources} from '@src/hooks';
import {AssignedForm, LonelyPatient, MedicalHistory} from '@src/models';

type Props = {
  currentMedicalHistory: AsyncData<MedicalHistory>;
  patient: LonelyPatient;
};

export function SimplePatientDetails({currentMedicalHistory, patient}: Props) {
  const classes = useStyles();
  const api = useApi();
  const [user] = useUser();

  const [forms] = useResources<AssignedForm>(
    async () => await api.patient(patient.id).getForms(),
    [api, patient],
  );

  const [completedForms, incompleteForms] = useMemo(
    () =>
      partition(forms.getAllOptional().orElse([]), form =>
        form.completedAt.isPresent(),
      ),
    [forms],
  );

  if (
    !currentMedicalHistory.isLoaded() ||
    !user.isLoaded() ||
    !forms.isLoaded()
  ) {
    return null;
  }

  return (
    <Grid container direction="column" className={classes.detailsSection}>
      <Grid item>
        <AssociatedUsers patient={patient} />
      </Grid>

      <Grid item>
        <FormRow forms={incompleteForms} />
      </Grid>

      <Grid item>
        <FormRow forms={completedForms} isCompleted />
      </Grid>
    </Grid>
  );
}
