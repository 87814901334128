import {Grid} from '@material-ui/core';
import React from 'react';
import {useStyles} from './layout';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';

export enum ConnectionTextContext {
  Pending = 'Pending',
  Connected = 'Connected',
  NewPatient = 'NewPatient',
}

type Props = {
  isSelf: boolean;
  relationshipName: string;
  fullName?: string;
  email: string;
  context: ConnectionTextContext;
};

const connectionName = (fullName = '') => ({
  [ConnectionTextContext.Pending]: 'Pending ',
  [ConnectionTextContext.NewPatient]: '',
  [ConnectionTextContext.Connected]: `${fullName} `,
});

export function ConnectionText({
  context,
  isSelf,
  relationshipName,
  fullName,
  email,
}: Props) {
  const classes = useStyles();

  const relName = isSelf ? 'Self' : `${relationshipName || 'Connection'}`;
  const name = connectionName(fullName)[context];

  return (
    <Grid
      container
      direction="row"
      className={classes.connectionText}
      spacing={1}
    >
      <Grid item>
        <Bold>{relName}:</Bold>
      </Grid>
      <Grid item>
        {name}
        {email}
      </Grid>
    </Grid>
  );
}
