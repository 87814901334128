import {FinanciallyResponsiblePersonValues} from '../../../components/shared/forms/FinanciallyResponsiblePersonFormModal/functions';
import {ResponsiblePersonRequest} from '@src/models';

export type IndependentPatientValues = Omit<
  FinanciallyResponsiblePersonValues,
  'address' | 'guardianId'
>;

export type IndependentPatientRequest = Omit<
  ResponsiblePersonRequest,
  'address' | 'guardianId'
>;

export function valuesToIndependentPatientInfo(
  values: IndependentPatientValues,
): IndependentPatientRequest {
  const {relationship, ...rp} = values;
  return {id: 0, ...rp, dob: rp.dob?.toISOString()};
}
