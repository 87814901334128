import {Reason} from './types';

const headingMessages = {
  visitNotFound: 'Oops! It looks like there was a problem loading that visit.',
  roomFull: 'There are already four participants in this visit.',
  visitLeft: 'You have left the exam room.',
  visitOver: 'This visit is already finished.',
  error: 'Something went wrong setting up this visit.',
  deniedAdmission: "Sorry, you can't join this visit.",
};

export function getHeadingText(reason: Reason) {
  return headingMessages[reason];
}
