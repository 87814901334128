import React, {useCallback, useEffect, useMemo} from 'react';
import {
  DataGrid,
  GridColDef,
  GridSelectionModelChangeParams,
} from '@material-ui/data-grid';
import {useStyles} from './layout';
import {StepProps} from '.';
import {FlowProps} from '@src/hooks/useSteps';
import {ExpandingBox} from '@src/components/shared/ExpandingBox';
import {RequestHeader} from '@src/components/ui/layout/NewThingRequest';
import {useApi} from '@src/api/useApi';
import {useResources} from '@src/hooks';
import {AssignedForm} from '@src/models';

type Props = StepProps & FlowProps;

export function PatientFormsStep({
  blankForms,
  patient,
  selectedBlankFormIds,
  setNextAvailable,
}: Props) {
  const classes = useStyles();
  const api = useApi();

  const [assignedFormsPromise] = useResources<AssignedForm>(
    () => api.patient(patient?.id).getForms(),
    [api],
  );

  const forms = useMemo(() => {
    const assignedFormIds = assignedFormsPromise
      .getAllOptional()
      .orElse([])
      .filter(af => !af.completedAt.isPresent())
      .map(af => af.blankFormId);
    return blankForms.filter(f => !assignedFormIds.includes(f.id));
  }, [api, assignedFormsPromise, blankForms]);

  const columns: GridColDef[] = [
    {field: 'id', hide: true},
    {field: 'name', headerName: 'Name', flex: 1, renderCell: ExpandingBox},
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      renderCell: ExpandingBox,
      cellClassName: classes.gridCell,
    },
  ];

  const handleSelectionModelChange = useCallback(
    ({selectionModel}: GridSelectionModelChangeParams) => {
      selectedBlankFormIds.set(selectionModel as number[]);
    },
    [selectedBlankFormIds],
  );

  useEffect(() => {
    setNextAvailable(true);
  }, [setNextAvailable]);

  return (
    <>
      <RequestHeader>Patient Forms</RequestHeader>
      <DataGrid
        columns={columns}
        rows={forms.map(({id, name, description}) => ({
          id,
          name,
          description: description.orNull(),
        }))}
        autoHeight
        checkboxSelection
        disableColumnMenu
        disableSelectionOnClick
        hideFooter
        selectionModel={selectedBlankFormIds.value}
        onSelectionModelChange={handleSelectionModelChange}
      />
    </>
  );
}
