import {makeStyles, createStyles} from '@material-ui/core';
import {xs, lg, sm, md} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: xs,
      color: theme.palette.secondary.contrastText,
      margin: `${lg} 0`,
      padding: sm,
    },
    menuButton: {
      color: theme.palette.secondary.contrastText,
    },
    requestButton: {
      color: theme.palette.secondary.contrastText,
    },
    menuButtonContainer: {
      marginRight: `${md}`,
    },
  }),
);
