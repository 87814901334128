import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {DateInput} from '@src/components/ui/form';
import {Button} from '@src/components/ui/form/Button';
import {ErrorMessage} from '@src/components/ui/form/ErrorMessage';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {
  flashError,
  flashNotify,
} from '@src/components/shared/notifications/flash';
import {useApi} from '@src/api/useApi';

type Props = {
  expirationDate: Optional<Date>;
  setExpirationDate: (d: Optional<Date>) => unknown;
  setShow: (v: boolean) => unknown;
};

export const UpdateReviewDate: React.FunctionComponent<Props> = ({
  setShow,
  setExpirationDate,
  expirationDate,
}) => {
  const {patient, setScp, scp, setPatient} = usePageContext();

  const oPatient = patient.getOptional();
  const oScp = scp.getOptional();

  const api = useApi();
  const currentExpirationDate = oScp.map(m => m.approvalExpiration).orNull();

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<{[field: string]: string}>({});

  useEffect(() => {
    setErrors({
      ...errors,
      expirationDate: '',
    });
  }, [submitting]);

  function reset() {
    setShow(false);
    setSubmitting(false);
    setExpirationDate(oScp.map(m => m.approvalExpiration));
  }

  const onSubmit = () => {
    if (!expirationDate?.orNothing()) {
      setErrors({
        ...errors,
        expirationDate: 'Please enter a reminder date before submitting',
      });
    }

    if (expirationDate?.orNothing()) {
      const patientId = oPatient.map(p => p.id).orElse(0);
      const patientApi = api.patient(patientId);

      setSubmitting(true);

      patientApi
        .getSCP()
        .then(newScp => {
          return api
            .scp(newScp.id)
            .updateApprovalExpiration(expirationDate?.get());
        })
        .then(updatedScp => {
          setScp(updatedScp);
        })
        .then(() => {
          patientApi.get().then(setPatient);
          flashNotify('New reminder date set successfully!');
        })
        .catch(e => {
          console.log(e);

          flashError(
            'Something went wrong with setting the new reminder date. Please contact support or try again.',
          );
        })
        .finally(() => {
          setShow(false);
          reset();
        });
    }
  };

  return (
    <Grid item xs={12} md={6}>
      <DateInput
        error={
          !!errors.ownershipExpiration
            ? `${errors.ownershipExpiration}`
            : undefined
        }
        label="Shared care plan review reminder"
        name="expiration"
        onChange={d => setExpirationDate(Optional.of(d))}
        value={expirationDate?.orNothing() || currentExpirationDate || null}
        minDate={new Date()}
      />
      {errors.expirationDate && (
        <ErrorMessage message={errors.expirationDate} />
      )}
      <div style={{marginTop: '1rem'}}>
        <Button bSize="small" bStyle="outlined" onClick={reset} isInRow={true}>
          Cancel
        </Button>
        <MuiAsyncActionButton
          actionInProgress={submitting}
          actionWord={'Save'}
          bSize="small"
          bStyle="primary"
          onClick={onSubmit}
        />
      </div>
    </Grid>
  );
};
