import {Optional} from '@ahanapediatrics/ahana-fp';
import {PatientDetailsValues} from '../SharedPatient/patientDetailsUtils';
import {ProviderDetailsStep} from '../SharedPatient/ProviderDetailsStep';
import {ConfirmStep} from '../SharedPatient/ConfirmStep';
import {PatientDetailsStep} from './steps/PatientDetailsStep';
import {StepComponent} from '@src/hooks/useSteps';
import {ProviderDetails, ResponsiblePerson} from '@src/models';

export type EditPatientStep = 'PatientDetails' | 'ProviderDetails' | 'Confirm';

export type StepProps = {
  patientId: number;
  patientDetails: PatientDetailsValues;
  setPatientDetails: (d: PatientDetailsValues) => unknown;
  details: Optional<ProviderDetails>;
  setDetails: (info: Optional<ProviderDetails>) => unknown;
  providerNotKnown: boolean;
  setProviderNotKnown: (b: boolean) => unknown;
  existingPersonForIndependentPatient: Optional<ResponsiblePerson>;
  setExistingPersonForIndependentPatient: (
    v: Optional<ResponsiblePerson>,
  ) => unknown;
  patientIsCurrentUser: boolean;
};
/**
 * This maps a step type to its name
 */
export const StepNames: Record<EditPatientStep, string> = {
  PatientDetails: 'Patient Details',
  ProviderDetails: 'Provider Details',
  Confirm: 'Confirm',
};

/**
 * This maps a step type to its component
 */
export const StepComponents: Record<
  EditPatientStep,
  StepComponent<StepProps>
> = {
  PatientDetails: PatientDetailsStep,
  ProviderDetails: ProviderDetailsStep,
  Confirm: ConfirmStep,
};
