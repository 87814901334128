import React, {useEffect, useState} from 'react';
import {ParagraphText} from '../../ui/layout/text/body/ParagraphText';
import {ProviderGroupSelector} from '../../shared/forms/ProviderGroupSelector';
import {OnCallPeriodPurpose} from '.';
import {WarningText} from '@src/components/ui/layout/text/body/WarningText';
import {CallPool} from '@src/models';
import {CallPoolOption, sortCallPools} from '@src/util/callPools/sortCallPools';

export type ShiftFrequency = 'Once' | 'Repeating';

type Props = {
  callPools: readonly CallPool[];
  frequency: ShiftFrequency;
  purpose: OnCallPeriodPurpose;
  selectedCallPools: number[];
  setSelectedCallPools: (cp: number[]) => unknown;
  requiredGroupsSelections?: number[];
};

export function ShiftGroupSelector({
  callPools,
  frequency,
  purpose,
  selectedCallPools,
  setSelectedCallPools,
  requiredGroupsSelections,
}: Props) {
  const [sortedCallPools, setSortedCallPools] = useState<
    Map<number, CallPoolOption>
  >(new Map<number, CallPoolOption>());

  useEffect(() => {
    const sorted = sortCallPools(callPools);
    setSortedCallPools(sorted);
  }, [callPools]);

  const optionList = [...sortedCallPools.values()];

  if (optionList.length === 0) {
    return (
      <WarningText warningVariant="warning">
        You don't belong to any call pools, so you can't create an on call
        period.
      </WarningText>
    );
  }

  const direction =
    purpose === 'Coverage'
      ? 'Please select the Provider Groups you are available for'
      : `Please select the Provider Groups for ${
          frequency === 'Once' ? 'this shift' : 'these shifts'
        }`;

  return (
    <>
      <ParagraphText>{direction}</ParagraphText>
      <ProviderGroupSelector
        groups={callPools}
        selectedGroups={selectedCallPools}
        setSelectedGroups={setSelectedCallPools}
        requiredGroupsSelections={requiredGroupsSelections}
      />
    </>
  );
}
