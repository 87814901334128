import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as React from 'react';
import {
  UploadingFileDetails,
  UploadingFileIcon,
  UploadingFileName,
  ProgressBar,
  UploadingFileStyled,
} from '@src/components/ui/organisms/uploaders/files/UploadsList/layout';
import {
  InProgressFile,
  UploadedFile,
} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';
import {EyeIcon} from '@src/components/ui/atoms/buttonsAndIcons/EyeIcon';
import {isNothing} from '@src/util/typeTests';

type Props = InProgressFile | UploadedFile<{name: string}>;

/**
 * TODO:
 * This file can be consolidated with
 * @src/components/ui/organisms/uploaders/files/UploadsList/FileBeingUploaded.tsx
 * and @src/components/ui/organisms/uploaders/files/UploadsList/CompletedUpload.tsx
 * as was done with in the @src/components/ui/organisms/uploaders/files folder.
 */
const File = ({
  file,
  progress,
  completed,
  error = '',
  onClickEyeIcon,
}: Props & {onClickEyeIcon?: () => void}) => (
  <UploadingFileStyled>
    <UploadingFileIcon error={!!error}>
      {!completed && !error && (
        <FontAwesomeIcon icon={'sync'} fixedWidth spin={progress < 100} />
      )}
      {completed &&
        !error &&
        (!isNothing(onClickEyeIcon) ? (
          <EyeIcon onClick={onClickEyeIcon} />
        ) : (
          <FontAwesomeIcon icon={['fas', 'file']} fixedWidth />
        ))}

      {error !== '' && (
        <FontAwesomeIcon icon={'exclamation-triangle'} fixedWidth />
      )}
    </UploadingFileIcon>
    <UploadingFileDetails completed={completed} uploadError={!!error}>
      <UploadingFileName>
        <div>
          {file.name}
          {error && <em> - {error}</em>}
        </div>
      </UploadingFileName>
      <ProgressBar
        completed={completed}
        progress={progress}
        uploadError={!!error}
      />
    </UploadingFileDetails>
  </UploadingFileStyled>
);

export default File;
