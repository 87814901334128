import {Grid} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {setDefaultTab} from '../../util/tabs';
import {GeneralFolder} from './GeneralFolder';
import {ScpFolders} from './ScpFolders';
import {TabLabels} from './TabLabels';
import {VisitsFolders} from './VisitFolders';
import {useApi} from '@src/api/useApi';
import {PrivatePage} from '@src/components/PrivatePage';
import {BackButton} from '@src/components/shared/BackButton';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {PageHeader} from '@src/components/ui/layout/PageHeader';
import {useAsync} from '@src/hooks';
import {useUser} from '@src/hooks/useUser';
import {GeneralFile, SCPFile, VisitFile, Patient} from '@src/models';
import {allLoaded} from '@src/util/apiHelpers';
import {getAllPages} from '@src/util/apiHelpers/getAllPages';
import {isCurrentUserPatient} from '@src/util/relationships/guardianOrIndependent/isCurrentUserPatient';
import {reference} from '@src/util/stringManipulation/languageHelpers';

type Props = RouteComponentProps<{patientId: string}>;

// eslint-disable-next-line import/no-unused-modules
export default function FilesOverview({match}: Props) {
  const patientId = +match.params.patientId;
  const [user] = useUser();
  const [visitFiles, setVisitFiles] = useAsync<VisitFile>();
  const [scpFiles, setScpFiles] = useAsync<SCPFile>();
  const [generalFiles, setGeneralFiles] = useAsync<GeneralFile>();

  const [noFiles, setNoFiles] = useState<boolean | null>(null);
  const [patient, setPatient] = useAsync<Patient>();

  const [tab, setTab] = useState<number>(-1);
  const [patientIsCurrentUser, setPatientIsCurrentUser] = useAsync<boolean>();

  const api = useApi();

  useEffect(() => {
    if (patient.isAsked()) {
      return;
    }

    setPatient();

    api
      .patient(patientId)
      .get()
      .then(p => setPatient(p));
  }, [patient, setPatient, api, patientId]);

  useEffect(() => {
    if (generalFiles.isAsked()) {
      return;
    }

    setGeneralFiles();
    api
      .patient(patientId)
      .getGeneralFiles()
      .then(setGeneralFiles);
  }, [generalFiles, setGeneralFiles, api, patientId]);

  useEffect(() => {
    if (visitFiles.isAsked()) {
      return;
    }

    setVisitFiles();
    getAllPages(options => api.patient(patientId).getVisitFiles(options)).then(
      setVisitFiles,
    );
  }, [visitFiles, setVisitFiles, api, patientId]);

  useEffect(() => {
    if (scpFiles.isAsked()) {
      return;
    }

    setScpFiles();
    api
      .patient(patientId)
      .getSCPFiles()
      .then(setScpFiles);
  }, [scpFiles, setScpFiles, api, patientId]);

  useEffect(() => {
    if (patientIsCurrentUser.isAsked()) {
      return;
    }

    setPatientIsCurrentUser();

    isCurrentUserPatient({
      patientId,
      api,
      user: user.getOptional(),
    })
      .then(setPatientIsCurrentUser)
      .catch(e => {
        console.warn(e);
      });
  }, [api, patientId, patientIsCurrentUser, setPatientIsCurrentUser, user]);

  useEffect(() => {
    if (!allLoaded(scpFiles, visitFiles, generalFiles) || tab !== -1) {
      return;
    }

    const hasGeneralFiles = generalFiles.getAllOptional().orElse([]).length > 0;
    const hasScpFiles = scpFiles.getAllOptional().orElse([]).length > 0;
    const hasVisitFiles = visitFiles.getAllOptional().orElse([]).length > 0;

    setDefaultTab([hasGeneralFiles, hasVisitFiles, hasScpFiles], setTab);

    setNoFiles(!hasScpFiles && !hasVisitFiles && !hasGeneralFiles);
  }, [generalFiles, scpFiles, visitFiles, setTab, tab]);

  const preferredName = patient
    .getOptional()
    .map(p => p.preferredName)
    .orElse('');

  const possessiveWord = reference(
    preferredName ?? 'this patient',
    patientIsCurrentUser.getOptional().orElse(false),
  )('Poss');

  const aPatient = patient.getOptional().orNull();

  const ready =
    allLoaded(
      patient,
      generalFiles,
      scpFiles,
      visitFiles,
      patientIsCurrentUser,
    ) &&
    noFiles !== null &&
    aPatient !== null;

  return (
    <PageLoading active={!ready} message="Loading Files">
      <PrivatePage style={{paddingBottom: '2rem'}}>
        <Grid container justify="space-between" alignItems="center">
          <PageHeader>{possessiveWord} Files</PageHeader>
          <div>
            <BackButton />
          </div>
        </Grid>

        <Grid container justify="center">
          <Grid item xs={12} md={8}>
            <TabLabels
              scpFiles={scpFiles.getAllOptional().orElse([])}
              tab={tab}
              setTab={setTab}
            />
            {tab === 0 && (
              <GeneralFolder
                patient={aPatient!}
                patientIsCurrentUser={patientIsCurrentUser
                  .getOptional()
                  .orElse(false)}
                files={generalFiles.getAllOptional().orElse([])}
                setFiles={setGeneralFiles}
              />
            )}
            {tab === 1 && (
              <VisitsFolders
                files={visitFiles.getAllOptional().orElse([])}
                setFiles={setVisitFiles}
                patient={aPatient!}
                patientIsCurrentUser={patientIsCurrentUser
                  .getOptional()
                  .orElse(false)}
              />
            )}
            {tab === 2 && (
              <ScpFolders files={scpFiles.getAllOptional().orElse([])} />
            )}
          </Grid>
        </Grid>
      </PrivatePage>
    </PageLoading>
  );
}
