import styled from 'styled-components';
import {makeStyles} from '@material-ui/core';
import {
  grey,
  mobileMax,
  primary,
  sm,
  xs,
  xxs,
  lg,
  primaryLight,
  primaryText,
} from '@src/components/ui/theme';

export const DashboardContent = styled.div`
  padding: ${xs};
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${sm};
  button {
    margin: ${xs};
  }

  @media (max-width: ${mobileMax}) {
    flex-direction: column;
  }
`;

export const DesktopVisitTable = styled.table`
  table-layout: auto;
  margin-top: ${sm};
  width: 100%;
`;
export const MobileVisitTable = styled.table<{selected?: boolean}>`
  border-color: ${props => (props.selected ? primary : 'transparent')};
  border-style: solid;
  border-width: ${xxs};
  table-layout: auto;
  margin-top: ${sm};
  width: 100%;
`;
export const VisitTableHeader = styled.thead`
  border-bottom: 1px solid ${grey};
`;
export const VisitTableBody = styled.tbody``;
export const VisitTableHeadCell = styled.th<{center?: boolean}>`
  padding-bottom: ${xxs};
  padding-right: ${xs};
  padding-left: ${xs};
  text-align: ${props => (props.center ? 'center' : 'left')};
  text-transform: uppercase;
  vertical-align: middle;
  @media (max-width: ${mobileMax}) {
    text-align: left;
    padding: ${xs};
  }
`;

export const VisitTableCell = styled.td<{center?: boolean}>`
  padding: ${xxs} ${xs};
  text-align: ${props => (props.center ? 'center' : 'left')};
  vertical-align: middle;
  @media (max-width: ${mobileMax}) {
    text-align: left;
    padding: ${xs};
  }
`;

// The media query is necessary to override the media query in VisitTableCell
export const DownloadCell = styled(VisitTableCell)`
  cursor: pointer;
  font-size: 1.5em;
  @media (max-width: ${mobileMax}) {
    text-align: center;
  }
`;

export const MessageTableCell = styled(VisitTableCell)`
  padding: ${xs} ${sm};
`;

export const useStyles = makeStyles(() => ({
  reportLinkButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: xxs,
    backgroundColor: primary,
    color: primaryText,
    cursor: 'pointer',
    height: lg,
    width: lg,
    margin: xxs,
    '& :hover': {
      backgroundColor: primaryLight,
    },
    '& .svg-inline--fa': {
      width: '100%',
      height: '75%',
    },
  },
}));
