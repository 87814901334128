import React from 'react';
import {Skeleton} from '@material-ui/lab';
import {MuiHeading} from '@src/components/ui/layout/MuiHeading';
import {LonelyVisit} from '@src/models';
import {isNothing} from '@src/util/typeTests';
import {getStartTime, getDate} from '@src/util/visits/getVisitDescriptions';
import {providerAndQualification} from '@src/util/provider';

type Props = {
  visit: LonelyVisit | null;
};

export function Heading({visit}: Props) {
  if (isNothing(visit)) {
    return <Skeleton variant="rect" style={{width: '100px'}} />;
  }

  const {start, providerDetails} = visit;

  const time = getStartTime(start);
  const date = getDate(start);

  const providerDescription = providerDetails
    .map(providerAndQualification)
    .orElse('Not yet assigned');

  return (
    <>
      <MuiHeading
        variant="h3"
        weight="light"
        style={{margin: 0, paddingBottom: '0.5rem'}}
      >
        {date} at {time}
      </MuiHeading>
      <MuiHeading variant="h3" weight="light" style={{marginBottom: '1rem'}}>
        Provider: {providerDescription}
      </MuiHeading>
    </>
  );
}
