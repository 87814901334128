import React from 'react';
import {Grid} from '@material-ui/core';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {Controller} from 'react-hook-form';
import {usePageContext} from '..';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {FormContainer} from '@src/components/ui/molecules/layouts/FormContainer';
import {HorizontalSection} from '@src/components/ui/atoms/section/HorizontalSection';
import {LightHeading} from '@src/components/ui/layout/text/heading/LightHeading';
import {DateInput, SelectInput} from '@src/components/ui/form';
import {CallPoolsMultiselect} from '@src/components/ui/organisms/selectors/CallPoolsMultiselect';
import {LoadingIndeterminate} from '@src/components/ui/atoms/progressBarsAndIndicators/LoadingIndeterminate';
import {WarningText} from '@src/components/ui/layout/text/body/WarningText';

export function Form() {
  const {
    storedReports,
    poolsFromReportInvoiceTargets,
    formResources: {
      control,
      errors,
      formHandler,
      showCallPoolsError,
      selectedCallPoolIds,
      setSelectedCallPoolIds,
      fileUrl,
      submitting,
    },
  } = usePageContext();

  const submitButton = (
    <MuiAsyncActionButton
      bStyle="primary"
      bSize="normal"
      onClick={formHandler}
      actionInProgress={submitting}
      actionWord="Submit"
      disabled={submitting}
    >
      Submit
    </MuiAsyncActionButton>
  );

  return (
    <FormContainer submitButton={submitButton}>
      <HorizontalSection>
        <Grid item xs={12}>
          <LightHeading>Reports</LightHeading>
          <LoadingIndeterminate active={!storedReports.isLoaded()}>
            <Controller
              control={control}
              name="storedReportId"
              render={({onChange, value, name}) => (
                <SelectInput
                  required
                  value={value}
                  name={name}
                  title="Reports"
                  onChange={onChange}
                  placeholder="Select a report..."
                  options={storedReports
                    .getAllOptional()
                    .orElse([])
                    .map(target => ({
                      label: target.name,
                      subLabel: target.description,
                      value: `${target.id}`,
                    }))}
                  error={errors.storedReportId?.message}
                />
              )}
            />
          </LoadingIndeterminate>
        </Grid>
      </HorizontalSection>
      <HorizontalSection>
        <Grid item xs={12}>
          <LightHeading>Groups</LightHeading>
          <LoadingIndeterminate active={!storedReports.isLoaded()}>
            <CallPoolsMultiselect
              callPools={AsyncData.loaded(poolsFromReportInvoiceTargets)}
              onUpdateSelection={setSelectedCallPoolIds}
              selectedIds={selectedCallPoolIds}
            />
          </LoadingIndeterminate>
        </Grid>
      </HorizontalSection>

      <HorizontalSection>
        <Grid item xs={12}>
          <LightHeading>Time Period</LightHeading>
          <Controller
            control={control}
            required
            name="start"
            error={errors.start?.message}
            render={({onChange, value}) => (
              <DateInput
                name="start"
                label="Report start date"
                value={value}
                onChange={onChange}
                error={errors.start?.message}
                required
              />
            )}
          />

          <Controller
            control={control}
            required
            name="end"
            error={errors.end?.message}
            render={({onChange, value}) => (
              <DateInput
                name="end"
                label="Report end date"
                value={value}
                onChange={onChange}
                error={errors.end?.message}
                required
              />
            )}
          />
        </Grid>
      </HorizontalSection>
      {fileUrl && (
        <iframe
          title={'Download Report'}
          src={fileUrl}
          style={{display: 'none'}}
        />
      )}
      {showCallPoolsError && (
        <WarningText warningVariant="warning">
          Please select at least one Group.
        </WarningText>
      )}
    </FormContainer>
  );
}
