import React, {useState} from 'react';

import {useTheme, Grid} from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import DoneIcon from '@material-ui/icons/Done';
import {Modal} from '@src/components/ui/layout/Modal';
import {Button} from '@src/components/ui/form';
import {getVisitLink} from '@src/util/visits';
import {useUser} from '@src/hooks';
import {UserType, LonelyPatient} from '@src/models';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {useLogout} from '@src/hooks/useLogout';
import {isSomething} from '@src/util/typeTests';

type Props = {
  patient?: LonelyPatient;
  showModal: boolean;
  setShowModal: (v: boolean) => unknown;
  visitId: number;
};

export function ShareLinkModal({
  patient,
  showModal,
  setShowModal,
  visitId,
}: Props) {
  const theme = useTheme();
  const [, userType] = useUser();
  const [showCopiedConfirmation, setShowCopiedConfirmation] = useState(false);

  const swapDetails = new URLSearchParams();
  swapDetails.set('switchToVisit', `${visitId}`);
  if (isSomething(patient)) {
    swapDetails.set('firstName', patient.preferredFirstName);
    swapDetails.set('lastName', patient.lastName);
    swapDetails.set('isPatient', 'true');
  }
  const logout = useLogout({queryParams: swapDetails});

  return (
    <Modal
      show={showModal}
      onClose={() => setShowModal(false)}
      title="Share visit"
      modalActions={
        <>
          {userType === UserType.Professional && (
            <Button
              bStyle="primary"
              onClick={() => {
                logout();
              }}
            >
              SecureSwap
            </Button>
          )}
          <CopyToClipboard text={getVisitLink(visitId)}>
            <span>
              <Button
                bStyle="primary"
                onClick={() => {
                  setShowCopiedConfirmation(true);
                  setTimeout(() => setShowCopiedConfirmation(false), 2000);
                }}
              >
                {showCopiedConfirmation ? 'Copied' : 'Copy'}
              </Button>
              {showCopiedConfirmation && (
                <DoneIcon
                  style={{
                    verticalAlign: 'middle',
                    marginLeft: '1rem',
                    color: theme.palette.success.main,
                  }}
                />
              )}
            </span>
          </CopyToClipboard>
        </>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <ParagraphText>
            Here's a link to this Visit that can be shared with anyone who needs
            access. They can access anonymously or log in with a Refyne
            Connected Care account.
          </ParagraphText>
        </Grid>
        <Grid item xs={12}>
          <Grid item>
            <ParagraphText style={{textAlign: 'center', fontWeight: 'bold'}}>
              {getVisitLink(visitId)}
            </ParagraphText>
          </Grid>
        </Grid>
        {userType === UserType.Professional && (
          <Grid item xs={12}>
            <ParagraphText>
              If the Patient is going to use your current device, you can use
              SecureSwap to log yourself out and immediately connect to the
              visit anonymously.
            </ParagraphText>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
}
