import {makeStyles} from '@material-ui/core/styles';
import {sm} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  subtitle: {
    marginLeft: sm,
    display: 'inline-block',
  },
  // Icons
  checkIcon: {
    color: theme.palette.success.main,
    marginLeft: sm,
  },
}));
