import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';
import {useMemo} from 'react';
import {compareDesc} from 'date-fns';
import {
  SCPChangeRequest,
  SharedCarePlan,
  User,
  SCPChangeRequestStatus,
} from '@src/models';
import {userIsProfessional} from '@src/util/users/userIsProfessional';
import {isUserRequester} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/utils/isUserRequest';

function getViewAuthorizedPredicates(
  cr: SCPChangeRequest,
  user: Optional<User>,
) {
  const isPending = cr.status === SCPChangeRequestStatus.Pending;
  const isObsolete = cr.status === SCPChangeRequestStatus.Obsolete;
  const userMatchesRequester = isUserRequester(cr, user.orNothing());

  return [isPending, isObsolete, userMatchesRequester];
}

/**
 * Owner/Partners: authorized to view any `SCPChangeRequest`.
 *  Returned array can have unlimited length.
 * Non-Owning Professionals: authorized to view any `SCPChangeRequest`.
 *  Returned array can have unlimited length. Array might contain their own `SCPChangeRequest`.
 * Non-Professionals: only authorized to view own `SCPChangeRequest`.
 *  Returned array will have length of 0 or 1.
 */
function filterByViewAuthorized({
  changeRequests,
  user,
  isOwnerOrPartner,
}: {
  changeRequests: AsyncData<SCPChangeRequest>;
  user: Optional<User>;
  isOwnerOrPartner: boolean;
}) {
  const isProvider = userIsProfessional(user);
  const canOnlyViewOwn = !isProvider;

  return changeRequests
    .getAllOptional()
    .orElse([])
    .filter(cr => {
      const [
        isPending,
        isObsolete,
        userMatchesRequester,
      ] = getViewAuthorizedPredicates(cr, user);

      const showObsolete =
        isObsolete && userMatchesRequester && !isOwnerOrPartner;

      const showPending =
        isPending && (!canOnlyViewOwn || userMatchesRequester);

      return showObsolete || showPending;
    });
}

function getMostRecent({
  viewAuthorizedList,
  user,
  isOwnerOrPartner,
}: {
  viewAuthorizedList: SCPChangeRequest[];
  user: Optional<User>;
  isOwnerOrPartner: boolean;
}) {
  const chronological = viewAuthorizedList.sort((a, b) =>
    compareDesc(a.updatedAt, b.updatedAt),
  );
  const isProfessional = userIsProfessional(user);
  const mostRecent = chronological[0];

  if (!isProfessional || isOwnerOrPartner) {
    return mostRecent;
  }

  // Non-Owning Professionals should see their own `SCPChangeRequest`
  // OR the most recent one they do not own.
  const mostRecentForNonOwningProfessional = chronological.find(cr =>
    isUserRequester(cr, user.orNothing()),
  );

  return mostRecentForNonOwningProfessional || mostRecent;
}

export function useRequestToDisplay({
  changeRequests,
  property,
  user,
  isOwnerOrPartner,
}: {
  changeRequests: AsyncData<SCPChangeRequest>;
  property: keyof SharedCarePlan;
  user: AsyncData<User>;
  isOwnerOrPartner: boolean;
}) {
  const optionalUser = user.getOptional();

  return useMemo(() => {
    const filteredByProperty = changeRequests.filter(
      cr => cr.property === property,
    );

    const viewAuthorizedList = filterByViewAuthorized({
      changeRequests: filteredByProperty,
      user: optionalUser,
      isOwnerOrPartner,
    });

    return getMostRecent({
      viewAuthorizedList,
      user: optionalUser,
      isOwnerOrPartner,
    });
  }, [changeRequests, optionalUser, property, isOwnerOrPartner]);
}
