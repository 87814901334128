import {AsyncData} from '@ahanapediatrics/ahana-fp';
import React from 'react';
import {usePageContext} from '..';
import {SCPInfoBox} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox';
import {ContactInfo} from '@src/components/shared/PatientDashboard/SCP/panels/Contacts/ContactInfo';
import {PatientRelationship} from '@src/models';
import {userCanEditMedicalHistoryField} from '@src/util/sharedCarePlan/permissions';
import {useUser} from '@src/hooks';

type Props = {
  guardians: AsyncData<PatientRelationship>;
};

export const Preferences = ({guardians}: Props) => {
  const [user] = useUser();

  const {reviewMode, selectedPanel} = usePageContext();

  const canCurrentUserEdit = userCanEditMedicalHistoryField(
    user.getOptional(),
    reviewMode,
  );

  const {setPatient, patient, setScp, reloadChangeRequests} = usePageContext();

  if (selectedPanel !== 'preferences') return null;

  return (
    <>
      <SCPInfoBox
        canEdit={canCurrentUserEdit}
        title="Medical Care Preferences"
        property="personalManagementSteps"
        propertyDescription="These are preferences in approaching my care that my family/caregiver and I would like to be considered in urgent and emergency settings."
      />
      <SCPInfoBox
        canEdit={canCurrentUserEdit}
        title="Communication Preferences"
        property="communicationPreferences"
        propertyDescription="These are preferences around how I would like healthcare providers to communicate with this patient."
      />
      <ContactInfo
        user={user}
        canEdit={canCurrentUserEdit}
        patient={patient}
        onUpdateScp={m => {
          setScp(m);
          reloadChangeRequests();
        }}
        updatePatient={setPatient}
        guardians={guardians}
      />
    </>
  );
};
