import styled from 'styled-components';
import {backdrop, md, sm, xs} from '../../ui/theme';

export const Content = styled.div<{infoOpen: boolean}>`
  display: ${props => (props.infoOpen ? 'block' : 'none')};
`;

export const PatientInfoHead = styled.div`
  background-color: ${backdrop};
  font-size: ${md};
  padding: ${sm} ${sm} ${md} ${sm};
`;

export const PatientName = styled.div`
  margin-bottom: ${sm};
  font-weight: bold;
`;

export const PatientGender = styled.div`
  font-weight: bolder;
`;

export const ProviderVisitTable = styled.table`
  background-color: ${backdrop};
  margin: 0 0 ${md} 0;
`;

export const ProviderVisitHead = styled.th`
  font-weight: bolder;
  padding-bottom: ${sm};
  padding-left: ${xs};
  padding-right: ${sm};
  padding-top: ${xs};
  text-align: left;
`;

export const ProviderVisitData = styled.td`
  padding-bottom: ${sm};
  text-align: left;
`;

export const ProviderVisitInfo = styled.tr``;
