import {CardHeader, Grid} from '@material-ui/core';
import React from 'react';
import {useTheme} from '@material-ui/core/styles';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/styles';
import {useUser} from '@src/hooks';
import {UserAvatar} from '@src/components/ui/layout/UserAvatar';
import {SCPHeading} from '@src/components/shared/PatientDashboard/SCP/SCPHeading';
import {providerAndQualification, ProviderCategory} from '@src/util/provider';
import {nonRCCUserSharedCareOwnerDescription} from '@src/util/sharedCarePlan';
import {SharedCarePlan, SCPStatus, UserType} from '@src/models';
import {isNothing} from '@src/util/typeTests';

type Props = {
  scp: Optional<SharedCarePlan>;
};

export const SCPOwnerBox: React.FunctionComponent<Props> = ({scp}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [, userType] = useUser();

  const providerInfo = scp.map(m => m.providerDetails).orNothing();
  const providerDescription = !isNothing(providerInfo)
    ? providerAndQualification(providerInfo)
    : '';
  const status = scp.map(m => m.status).orNothing();

  if (!providerInfo) return null;

  return (
    <Grid className={classes.rowContainer} container direction="row">
      <Grid item>
        <SCPHeading>Shared Care Plan Owner</SCPHeading>
      </Grid>

      {providerInfo && (
        <Grid item xs={12} className={classes.gridItem}>
          <CardHeader
            avatar={
              <UserAvatar
                user={providerInfo}
                style={{margin: 0}}
                unknown={!providerDescription}
              />
            }
            title={
              providerInfo.id === 0
                ? nonRCCUserSharedCareOwnerDescription(
                    providerInfo,
                    ProviderCategory.SharedCarePlanOwner,
                  )
                : providerDescription ?? 'No owner selected yet'
            }
            subheader={
              status === SCPStatus.Draft ? (
                <div
                  style={{
                    color: theme.palette.warning.main,
                    fontStyle: 'italic',
                    fontWeight: 700,
                  }}
                >
                  {providerDescription.length > 0
                    ? 'Ownership pending'
                    : `${
                        userType === UserType.Guardian
                          ? "Please navigate to this plan's Guardian Controls tab to connect a Professional"
                          : 'Please contact support if you would like to connect this Shared Care Plan to a Professional'
                      }`}
                </div>
              ) : (
                <div
                  style={{
                    color: theme.palette.success.main,
                    fontStyle: 'italic',
                    fontWeight: 700,
                  }}
                >
                  Ownership accepted
                </div>
              )
            }
          />
        </Grid>
      )}
    </Grid>
  );
};
