import React from 'react';
import {StepProps} from '../../stepFunctions';
import {ConnectedLoginsList} from './ConnectedLoginsList';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {FlowProps} from '@src/hooks/useSteps';

export function ConnectedLoginsStep({
  moveGuard,
  patientDetails,
  connectedLogins,
  setConnectedLogins,
}: StepProps & FlowProps) {
  moveGuard.current = async () => true;

  return (
    <>
      <RequestHeader>Connected Logins</RequestHeader>
      <RequestInstructions>
        <p>
          Connected Logins are people who are connected to{' '}
          {patientDetails.firstName} (relatives, guardians, partners, etc.).
        </p>

        <p>
          Please enter the email addresses of people who should be connected to{' '}
          {patientDetails.firstName}.
        </p>
      </RequestInstructions>
      <ConnectedLoginsList
        connectedLogins={connectedLogins}
        setConnectedLogins={setConnectedLogins}
      />
    </>
  );
}
