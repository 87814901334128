import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import React, {useCallback} from 'react';
import {YesOrNo} from '@src/components/ui/form/YesOrNo';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/styles';

export function CommunicationPreferencesQuestion({
  setValueUnderEdit,
  showTextInput,
  setShowTextInput,
}: {
  setValueUnderEdit: (v: string) => void;
  showTextInput: boolean;
  setShowTextInput: (v: boolean) => unknown;
}) {
  const classes = useStyles();

  const handleYesOrNoClicked = useCallback(
    (v: boolean) => {
      if (!v) {
        setValueUnderEdit('No communication preferences');
      } else {
        setValueUnderEdit('');
      }

      setShowTextInput(v);
    },
    [setShowTextInput, setValueUnderEdit],
  );
  return (
    <Grid item xs={12} className={classes.gridItem}>
      <ParagraphText style={{marginTop: '1rem'}}>
        Does this patient have communication preferences?
      </ParagraphText>
      <YesOrNo
        value={Optional.of(showTextInput)}
        setValue={handleYesOrNoClicked}
      />
    </Grid>
  );
}
