import {RouteComponentProps} from 'react-router';
import {
  isUserGuardian,
  isUserIndependentPatient,
  isUserRelationshipPerson,
} from './predicates';
import {AppAPI} from '@src/api/AppAPI';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {LonelyPatient, PatientRelationship} from '@src/models';
import {isNothing} from '@src/util/typeTests';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

type UnlinkGuardianProps = {
  setUnlinkingInProgress: (v: boolean) => unknown;
  api: AppAPI;
  relationship: PatientRelationship;
  onRemoveRelationship?: (i: number) => void;
  patient: LonelyPatient;
  onRemovePatient?: (i: number) => void;
  userPersonId: NonProfessionalId;
  history?: RouteComponentProps<{}>['history'];
};

export function unlinkGuardianOrSelf({
  api,
  setUnlinkingInProgress,
  relationship,
  onRemoveRelationship,
  patient,
  onRemovePatient,
  userPersonId,
  history,
}: UnlinkGuardianProps): Promise<void> {
  // See function definitions for truth tables.
  const userIsGuardian = isUserGuardian({userPersonId, relationship});
  const userIsIndependentPatient = isUserIndependentPatient({
    userPersonId,
    relationship,
  });

  const patientId = patient.id;

  setUnlinkingInProgress(true);

  const removeRelationship = (id: number) => {
    if (!isNothing(onRemoveRelationship)) {
      onRemoveRelationship(id);
    }
  };

  const removePatient = (id: number) => {
    if (!isNothing(onRemovePatient)) {
      onRemovePatient(id);
    }
  };

  return api
    .patient(patientId)
    .removeGuardianshipOrSelf(relationship.id)
    .then(p => {
      if (userIsGuardian || userIsIndependentPatient) {
        flashSuccess(
          userIsIndependentPatient
            ? 'You have deactivated your patient record'
            : `You have been unlinked from ${patient.firstName}`,
        );

        removeRelationship(relationship.id);
        removePatient(patientId);
      } else {
        flashSuccess(
          `Connected Login successfully unlinked from ${patient.firstName}`,
        );

        removeRelationship(relationship.id);
      }

      if (
        isUserGuardian({userPersonId, relationship}) &&
        isUserRelationshipPerson({
          userPersonId,
          relationship,
        }) &&
        !isNothing(history)
      ) {
        history.push('/dashboard');
      }
    })
    .catch(e => {
      console.error(e);
      flashError(
        'Something went wrong. Please contact support for further assistance.',
      );
    })
    .finally(() => {
      setUnlinkingInProgress(false);
    });
}
