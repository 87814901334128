import {endOfMonth, getDate, startOfMonth} from 'date-fns';
import {OnCallPeriod} from '@src/models';
import {useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';

type NumberForDayOfMonth = number;

export type PeriodsMappedToDaysOfSelectedMonth = Map<
  NumberForDayOfMonth,
  OnCallPeriod[]
>;
export const usePeriodsMappedToDaysOfSelectedMonth = (
  callPoolId: number,
  rosterMonth: Date,
) => {
  const api = useApi();
  return useResources<PeriodsMappedToDaysOfSelectedMonth>(
    () =>
      api
        .onCallPeriods(callPoolId)
        .getDateRange(startOfMonth(rosterMonth), endOfMonth(rosterMonth))
        .then(periods => {
          const roster: PeriodsMappedToDaysOfSelectedMonth = new Map<
            number,
            OnCallPeriod[]
          >();

          periods.forEach(period => {
            const dayRoster = roster.get(getDate(period.start)) ?? [];
            roster.set(getDate(period.start), [...dayRoster, period]);
          });
          return roster;
        }),
    [api, callPoolId, rosterMonth],
  );
};
