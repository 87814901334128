import React, {useCallback, useEffect, useState} from 'react';
import {FormLabel, Paper, TextField} from '@material-ui/core';
import {useStyles} from './styles';
import {NumericField} from '@src/models';

interface Props {
  field: NumericField;
  onChange?: (value: number | null) => unknown;
  readonly?: boolean;
  value: number | null;
}

export default function FormNumericInput({
  field,
  onChange = () => {},
  readonly = true,
  value,
}: Props) {
  const classes = useStyles();
  const [strValue, setStrValue] = useState(String(value ?? ''));
  const id = `form-numeric-input-${field.id}`;

  // Incoming changes of value must be handled since this component
  // keeps internal state in strValue.
  useEffect(() => {
    setStrValue(String(value ?? ''));
  }, [value]);

  const isValid = useCallback(
    (input: string): boolean => {
      if (input === '') {
        return true;
      }

      const parsed = Number(input);

      if (isNaN(parsed)) {
        return false;
      }

      return field.minValue <= parsed && parsed <= field.maxValue;
    },
    [field.maxValue, field.minValue],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Don't allow any characters besides numeric characters.
      if (!/^\-?[0-9]*$/.test(e.target.value)) {
        return;
      }

      setStrValue(e.target.value);

      // Only actually save to the backend if the value is null or an actual number.
      if (e.target.value === '') {
        return onChange(null);
      }

      const parsed = Number(e.target.value);

      if (!isNaN(parsed)) {
        return onChange(parsed);
      }
    },
    [onChange],
  );

  const valid = isValid(strValue);

  return (
    <Paper
      style={{padding: '1em'}}
      className={valid ? '' : classes.invalidField}
    >
      <FormLabel component="div" required={field.required} disabled={readonly}>
        {/*
         * Please note the subset of HTML tags considered valid within a <label> tag.
         * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label#technical_summary
         *
         * > Phrasing content, but no descendant label elements.
         * > No labelable elements other than the labeled control are allowed.
         *
         * https://developer.mozilla.org/en-US/docs/Web/HTML/Content_categories#phrasing_content
         */}
        <label
          className="inline-block"
          htmlFor={id}
          dangerouslySetInnerHTML={{__html: field.description}}
        />
      </FormLabel>
      <TextField
        id={id}
        name={field.name}
        value={strValue}
        onChange={handleChange}
        disabled={readonly}
        inputProps={{inputMode: 'numeric', pattern: '-?[0-9]*'}}
        error={!valid}
        helperText={`Number between ${field.minValue} and ${field.maxValue}`}
        fullWidth
      />
    </Paper>
  );
}
