import clsx from 'clsx';
import React, {useState} from 'react';
import {Grid} from '@material-ui/core';
import {useStyles} from './layout';
import {Button, OptionInput} from '@src/components/ui/form';
import {Modal} from '@src/components/ui/layout/Modal';
import {PromiseButton} from '@src/components/ui/form/PromiseButton';

type Props = {
  showModal: boolean;
  returnToPatient: () => void;
  onCancelVisit: (reason: string) => Promise<unknown>;
  returnText?: string;
};

const REASONS = [
  {
    label: 'Patient did not arrive',
    value: 'no-show',
  },
  {
    label: 'Guardian arrived without Patient',
    value: 'guardian-without-patient',
  },
  {
    label: 'Visit was for test/demonstration purposes',
    value: 'test-visit',
  },
  {
    label: 'Visit was created in error',
    value: 'created-in-error',
  },
  {
    label: 'Other reason',
    value: 'other',
  },
];

export const CancelSessionModal = ({
  onCancelVisit,
  returnToPatient,
  returnText = 'Return to patient',
  showModal,
}: Props) => {
  const classes = useStyles();
  const [cancellationReason, setCancellationReason] = useState('none-given');
  const [otherReason, setOtherReason] = useState('');

  return (
    <Modal
      show={showModal}
      title="Why are you canceling this Visit?"
      onClose={returnToPatient}
      modalActions={
        <>
          <Button onClick={returnToPatient} bStyle="outlined">
            {returnText}
          </Button>
          <PromiseButton
            actionWord="Cancel this visit"
            disabled={cancellationReason === 'none-given'}
            onClick={() =>
              onCancelVisit(
                cancellationReason === 'other'
                  ? otherReason
                  : cancellationReason,
              )
            }
            bStyle="danger"
          />
        </>
      }
    >
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          className={clsx(classes.modalRow, classes.modalInstructions)}
        >
          We'd like to know the reason for cancelling this visit for our
          records.
        </Grid>
        <Grid
          item
          className={clsx(classes.modalRow, classes.modalInstructions)}
        >
          Please select an option from the list below, or add your own reason.
        </Grid>
      </Grid>
      <OptionInput
        options={REASONS}
        selection={cancellationReason}
        makeSelection={setCancellationReason}
        otherSelection={otherReason}
        makeOtherSelection={setOtherReason}
        otherText="Please enter your other reason here"
      />
    </Modal>
  );
};
