import {makeStyles} from '@material-ui/core';
import styled from 'styled-components';
import {lighterGrey, primary, primaryDark, xs, xxs} from '../../theme';

export const useStyles = makeStyles(() => ({
  icd10Input: {
    width: '100%',
  },

  selection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '4px',
    margin: '4px 0',
    border: `solid thin ${primary}`,
    borderRadius: '2px',
  },

  deleteButton: {
    color: primary,
    cursor: 'pointer',
    '&:hover': {
      color: primaryDark,
    },
  },
}));

export const ICD10ModalContent = styled.div`
  height: 80vh;
`;
export const ICD10TableContainer = styled.div`
  height: 70%;
  padding: ${xxs};
  overflow-y: scroll;
  border: solid thin ${lighterGrey};
`;
export const ICD10Table = styled.table`
  width: 100%;
`;
export const ICD10TableHead = styled.thead``;
export const ICD10TableHeadCell = styled.th`
  font-weight: bold;
  padding: ${xxs} ${xxs} ${xs};
  text-align: left;
`;
export const ICD10TableBody = styled.tbody``;
export const ICD10TableRow = styled.tr`
  &:hover {
    background-color: ${primary};
  }
  cursor: pointer;
`;
export const ICD10TableCell = styled.td`
  padding: ${xs};
  text-align: left;
`;
