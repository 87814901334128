import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {format} from 'date-fns';
import React from 'react';
import {AssignProfessional} from '../VisitTable/VisitActions';
import {getFlag} from './functions';
import {VisitTableCell as Cell, VisitTableHeadCell as HeadCell} from './layout';
import {ReportLink} from '@src/components/shared/PastVisitsTable/ReportLink';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';
import {VisitLink} from '@src/components/shared/VisitLink';
import {LonelyVisit} from '@src/models';
import getTotalDiagnosis from '@src/util/visits/getTotalDiagnosis';
import {providerAndQualification} from '@src/util/provider/providerDescription';
import {visitIsSigned} from '@src/util/visits';

type Props = {
  visit: LonelyVisit;
  isSelected: boolean;
  isSelectable?: boolean;
  visitToggler: (selected: boolean) => () => void;
  onCancelVisit: (visitId: number) => unknown;
  onUpdateVisit: (visit: LonelyVisit) => unknown;
};

export function VisitRow({
  visit,
  isSelected,
  isSelectable = false,
  visitToggler,
  onUpdateVisit,
}: Props) {
  const {patient} = visit;
  const {pcp} = patient;
  return (
    <tr>
      {isSelectable && (
        <HeadCell>
          {visitIsSigned(visit) &&
            (isSelected ? (
              <ClickableIcon
                icon={['far', 'check-square']}
                onClick={visitToggler(false)}
              />
            ) : (
              <ClickableIcon
                icon={['far', 'square']}
                onClick={visitToggler(true)}
              />
            ))}
        </HeadCell>
      )}
      <Cell>{getFlag(visit)}</Cell>
      <Cell>{format(visit.start, 'PPpp')}</Cell>
      <Cell>{patient.preferredName}</Cell>
      <Cell>
        {visit.providerDetails
          .map(p => providerAndQualification(p, {skipFirstName: true}))
          .orElse('No Provider')}
      </Cell>
      <Cell>{visit.callPool.property('nameForProviders', '-')}</Cell>
      <Cell>{getTotalDiagnosis(visit)}</Cell>
      <Cell>
        {pcp
          .map(p => providerAndQualification(p, {skipFirstName: true}))
          .orElse('No PCP given')}
      </Cell>
      <Cell center>
        <VisitLink visit={visit} onUpdateVisit={onUpdateVisit} />
      </Cell>
      <Cell center>
        <ReportLink visit={visit} />
      </Cell>
      <Cell center>
        <AssignProfessional
          visit={visit}
          returnText="Return to Visits"
          onUpdate={onUpdateVisit}
        />
      </Cell>
      <Cell center>
        {visit.notesDownloaded ? <FontAwesomeIcon icon={'check'} /> : ''}
      </Cell>
    </tr>
  );
}
