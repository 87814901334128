import React from 'react';
import {Paper, Grid} from '@material-ui/core';
import {useStyles} from '../layout';
import {Shifts} from './Shifts';

type Props = {
  day: number;
};

export function Day({day}: Props) {
  const classes = useStyles();
  return (
    <Paper className={classes.day}>
      <Grid container direction="column" className={classes.dayGrid}>
        <Grid item className={classes.dayDate}>
          {day}
        </Grid>
        <Shifts day={day} />
      </Grid>
    </Paper>
  );
}
