import {differenceInYears, differenceInMonths} from 'date-fns';

/**
 * Takes a date time and returns a string listing the years and months since that
 * datetime
 * @param datetime
 * @param shortened If true, 'years' and 'months' are reduced to 'y' and 'm'
 * @returns {string}
 */
const asYearsAndMonths = (datetime: Date, shortened?: boolean) => {
  const years = differenceInYears(new Date(), datetime);
  const months = differenceInMonths(new Date(), datetime) - 12 * years;
  const Y = shortened ? 'y' : ' years';
  const M = shortened ? 'm' : ' months';
  if (years && months) {
    return `${years}${Y}, ${months}${M}`;
  } else if (years) {
    return `${years}${Y}`;
  }
  return `${months}${M}`;
};

export default asYearsAndMonths;
