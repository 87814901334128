import styled from 'styled-components';
import {
  lightGrey,
  md,
  secondary,
  sm,
  tabletMaxWidth,
  xs,
  secondaryText,
} from '@src/components/ui/theme';

export const CardContainer = styled.div`
  width: 45%;
  min-width: 300px;
  box-sizing: border-box;
  border: 1px solid ${lightGrey};
  border-radius: ${xs};
  margin: ${md};

  @media screen and (max-width: ${tabletMaxWidth}) {
    width: 100%;
    min-width: 0;
  }
`;

export const CardHeader = styled.div`
  background-color: ${secondary};
  border-top-right-radius: ${xs};
  border-top-left-radius: ${xs};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: ${tabletMaxWidth}) {
    font-size: small;
  }
`;

export const CardTitle = styled.h1`
  padding: ${xs} ${md};
  color: ${secondaryText};
  font-weight: 400;
  font-size: 1.8em;
  display: inline-block;
`;

export const CardAge = styled.span`
  display: inline-block;
  font-weight: 300;
  margin-left: ${sm};
`;

export const CardContent = styled.div`
  padding: ${md};
`;
