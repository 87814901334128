import React, {useEffect} from 'react';
import {useHistory, useParams} from 'react-router';
import {LonelyVisit, LonelyPatient} from '../../models';
import {PrivatePage} from '../PrivatePage';
import {PatientOverview} from '../shared/PatientOverview';
import {useApi} from '@src/api/useApi';
import {useAsync, useResources} from '@src/hooks';

// eslint-disable-next-line import/no-unused-modules
export default function CallOutPage() {
  const api = useApi();
  const history = useHistory();
  const params = useParams<{
    visitId: string;
  }>();

  const visitId = +params.visitId;

  const [visit] = useResources<LonelyVisit>(
    () =>
      api
        .visit(visitId)
        .get()
        .catch(() => {
          history.push('/');
          return [];
        }),
    [api, visitId],
  );
  const [patient, setPatient] = useAsync<LonelyPatient>();

  useEffect(() => {
    visit.getOptional().ifPresent(v => setPatient(v.patient));
  }, [visit, setPatient]);

  if (!patient.isLoaded()) {
    return null;
  }

  return (
    <PrivatePage>
      <PatientOverview
        patient={patient.singleValue()}
        updatePatient={setPatient}
      />
    </PrivatePage>
  );
}
