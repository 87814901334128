import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {PaymentDetailsForm} from '../../PaymentDetails';
import hmkCard from '@src/assets/hmk-plus-card-front.jpg';
import {DateInput, TextInput, YesOrNo} from '@src/components/ui/form';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {InsuranceType, Patient} from '@src/models';
import {ErrorMessage} from '@src/components/ui/form/ErrorMessage';

type Props = {
  patient: Patient;
  details: PaymentDetailsForm;
  type: InsuranceType | 'none';
};

type Step =
  | 'insuranceInfoAvailable'
  | 'medicaidQuestion'
  | 'paymentDetailsForm';

export const EditInsurance = ({details, patient, type}: Props) => {
  const {clearErrors, control, formState, setValue, watch} = useFormContext<
    PaymentDetailsForm
  >();

  const hasMedicaid = watch('hasMedicaid');
  const hasInsuranceAndUnknownDetails = watch('hasInsuranceAndUnknownDetails');

  function getFirstStep() {
    const needsInsuranceInfoAvailableStep =
      type === 'primary' &&
      (details.hasInsuranceAndUnknownDetails === null ||
        details.hasInsuranceAndUnknownDetails);

    if (needsInsuranceInfoAvailableStep) {
      return 'insuranceInfoAvailable';
    } else {
      if (hasMedicaid !== null) {
        return 'paymentDetailsForm';
      } else {
        return 'medicaidQuestion';
      }
    }
  }

  const [step, setStep] = useState<Step>(getFirstStep());
  const {errors} = formState;

  const setMedicaid = useCallback(
    (medicaid: boolean) => {
      setValue('insuranceProvider', medicaid ? 'Medicaid' : '');
      setValue('hasMedicaid', medicaid);
      setStep('paymentDetailsForm');
      clearErrors();
    },
    [clearErrors, setValue],
  );

  const setHasInsuranceAndUnknownDetails = useCallback(
    (value: boolean) => {
      setValue('hasMedicaid', null);
      setValue('hasInsuranceAndUnknownDetails', value);

      if (!value) {
        setStep('medicaidQuestion');
      } else {
        setStep('insuranceInfoAvailable');
      }
    },
    [setValue],
  );

  if (type === 'none') {
    return null;
  }

  return (
    <Grid container direction="column">
      <Grid hidden={type === 'secondary'}>
        <Grid item xs={12} style={{marginBottom: '1rem'}}>
          <ParagraphText>
            Do you have the insurance card available right now?
            <br></br>
            If not, you can complete this step later.
          </ParagraphText>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="hasInsuranceAndUnknownDetails"
            render={({value}) => (
              <YesOrNo
                value={Optional.of(value)}
                setValue={setHasInsuranceAndUnknownDetails}
                noMessage="I have the insurance details with me"
                yesMessage="Provide details later"
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        hidden={
          step === 'insuranceInfoAvailable' ||
          hasInsuranceAndUnknownDetails === true
        }
        style={{marginBottom: '1rem'}}
      >
        <Grid item xs={12} style={{marginBottom: '1rem'}}>
          <ParagraphText>
            Is this patient&apos;s {type === 'secondary' ? 'secondary ' : ''}
            insurance Medicaid?
            <br></br>
            This is what the card will probably look like.
          </ParagraphText>
          <img src={hmkCard} alt="Montana Medicaid card" />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="hasMedicaid"
            render={({value}) => (
              <YesOrNo value={Optional.of(value)} setValue={setMedicaid} />
            )}
          />
        </Grid>
        <Grid item xs={12} style={{marginBottom: '1rem'}}>
          <ErrorMessage message={errors.hasMedicaid?.message} />
        </Grid>
      </Grid>

      <Grid
        item
        xs={8}
        style={{marginBottom: '3rem'}}
        hidden={step !== 'paymentDetailsForm'}
      >
        <ParagraphText>
          Please provide the following information from{' '}
          {hasMedicaid ? "this patient's" : 'your'} insurance card.
        </ParagraphText>

        <Controller
          control={control}
          name="insuranceProvider"
          render={({name, onChange, value}) => (
            <TextInput
              hidden={!!hasMedicaid}
              name={name}
              required
              title="Insurance Provider"
              value={value}
              onChange={onChange}
              touched
              error={errors.insuranceProvider?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="insuranceId"
          render={({name, onChange, value}) => (
            <TextInput
              name={name}
              required
              title={!!hasMedicaid ? 'Member Number' : 'Insurance ID'}
              value={value}
              onChange={onChange}
              touched
              error={errors.insuranceId?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="insuranceGroup"
          render={({name, onChange, value}) => (
            <TextInput
              hidden={!!hasMedicaid}
              name={name}
              required
              title="Insurance Group Number"
              value={value}
              onChange={onChange}
              touched
              error={errors.insuranceGroup?.message}
            />
          )}
        />
        {hasMedicaid && (
          <>
            <TextInput
              name="childsName"
              title="Patient's Name"
              value={patient.preferredName}
              disabled
            />
            <DateInput
              name="childsDob"
              label="Patient's DOB"
              value={patient.dob}
              disabled
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};
