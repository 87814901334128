import {FormikProps} from 'formik';
import React, {useState, ReactNode, useEffect} from 'react';
import HelpIcon from '@material-ui/icons/Help';
import {Grid, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {MedicalHistoryValues} from '../forms/NewPatient/steps/MedicalHistoryStep/PatientMedicalHistoryForm';
import {SelectInput, TextInput} from '@src/components/ui/form';
import {LonelyPatient, Guardian, UserType} from '@src/models';
import {yObject, yString} from '@src/schema/types';
import {
  MedicalHistoryUIItem,
  renderMedicalHistorySections,
} from '@src/components/shared/forms/medicalHistoryFunctions';
import {useAsync, useUser} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {reference} from '@src/util/stringManipulation/languageHelpers';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.main,
    cursor: 'default',
    fontSize: 'inherit',
  },
}));

export const schema = yObject<MedicalHistoryValues>({
  medications: yString.default(''),
  allergies: yString.default(''),
  immunizations_up_to_date: yString.default(''),
  immunization_comments: yString.default(''),
  environment: yString.default(''),
  past_or_ongoing_health_issues: yString.default(''),
  surgeries: yString.default(''),
  medical_history: yString.default(''),
  other: yString.default(''),
});

type Props = Pick<
  FormikProps<MedicalHistoryValues>,
  'values' | 'handleChange' | 'handleBlur' | 'errors' | 'touched'
> & {
  patient: LonelyPatient;
};

const immunizationOptions = () => [
  {value: 'yes', label: 'Yes'},
  {value: 'not_sure', label: "I don't know"},
  {value: 'no', label: 'No'},
];

export function PatientMedicalHistoryForm({
  errors,
  handleBlur,
  handleChange,
  patient: {id, preferredFirstName, hasScp: hasScp},
  touched,
  values,
}: Props) {
  const classes = useStyles();
  const api = useApi();
  const [user] = useUser();

  const [tooltip, setTooltip] = useState<string | null>(null);
  const [patientIsCurrentUser, setPatientIsCurrentUser] = useAsync<boolean>();

  useEffect(() => {
    if (patientIsCurrentUser.isAsked()) {
      return;
    }

    setPatientIsCurrentUser();
    api
      .patient(id)
      .findSelfRelationship()
      .then(r => {
        if (r === null) {
          setPatientIsCurrentUser(false);
        }
        const selfId = r?.personId;
        setPatientIsCurrentUser(
          user
            .getOptional()
            .cast<Guardian>(u => u.userType === UserType.Guardian)
            .map(g => g.responsiblePersonDetails)
            .map(rp => rp.id)
            .map(rpId => rpId === selfId)
            .orElse(false),
        );
      })
      .catch(e => console.warn(e));
  }, [api, id, patientIsCurrentUser, setPatientIsCurrentUser, user]);

  const renderInput = ({
    name,
    node,
    title,
    instructions,
  }: MedicalHistoryUIItem): ReactNode | null => {
    if (hasScp && name === 'medications') {
      return null;
    }
    if (node) {
      return node;
    }

    return (
      <Grid container direction="row">
        <TextInput
          value={values[name] ? values[name] : undefined}
          name={name}
          autoExpand={true}
          title={title}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors[name]}
          touched={touched[name]}
          endAdornment={
            <ClickAwayListener
              onClickAway={() => {
                if (tooltip === name) {
                  setTooltip(null);
                }
              }}
            >
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                open={tooltip === name}
                onOpen={() => setTooltip(name)}
                onClose={() => setTooltip(null)}
                title={instructions}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <HelpIcon
                  style={{cursor: 'pointer'}}
                  onClick={e => {
                    setTooltip(tooltip === name ? null : name);
                  }}
                  className={classes.icon}
                />
              </Tooltip>
            </ClickAwayListener>
          }
        />
      </Grid>
    );
  };

  const rf = reference(
    preferredFirstName,
    patientIsCurrentUser.getOptional().orElse(false),
  );

  return (
    <div>
      {renderMedicalHistorySections(
        {
          patientName: preferredFirstName,
          patientIsUser: patientIsCurrentUser.getOptional().orElse(false),
          immunizationComponent: () => (
            <SelectInput
              name="immunizations_up_to_date"
              value={values.immunizations_up_to_date}
              placeholder="Immunizations up to date?"
              options={immunizationOptions()}
              title={`Are ${rf('poss')} immunizations up to date?`}
              onChange={handleChange}
            />
          ),
        },
        renderInput,
      )}
    </div>
  );
}
