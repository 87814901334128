import {Optional} from '@ahanapediatrics/ahana-fp';
import {PaymentDetailsForm} from '../../../PaymentDetails';
import {PaymentInformationRequestBody} from '@src/models';
import {getKeys} from '@src/util/objectManipulation/getKeys';

const toKeep = (prop: keyof PaymentDetailsForm): boolean =>
  ![
    'blockedForNonPayment',
    'cardZipCode',
    'cardholderName',
    'creditCardComplete',
    'last4',
    'skipCreditCardConfirmed',
  ].includes(prop);

export const convertDetails = (
  details: PaymentDetailsForm,
): PaymentInformationRequestBody => {
  return getKeys(details)
    .filter(toKeep)
    .reduce((body, prop) => {
      const value = details[prop];
      if (value instanceof Optional) {
        return {...body, [prop]: value.orElse(false)};
      }
      return {
        ...body,
        [prop]: value,
      };
    }, ({} as unknown) as PaymentInformationRequestBody);
};
