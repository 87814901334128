import jstz from 'jstz';
import React, {useState} from 'react';
import {Button} from '@material-ui/core';
import {useApi} from '@src/api/useApi';

type Props = {
  fileUrl: string | Promise<string>;
  text: string;
  authorized?: boolean;
};

export function PDFDownloadButton({authorized, fileUrl, text}: Props) {
  const api = useApi();
  const [downloadCount, setDownloadCount] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState('');

  const getDownloadUrl = async (): Promise<string> => {
    const timezone = jstz.determine();

    const baseUrl = await fileUrl;

    return `${baseUrl}?tz=${timezone.name()}&count=${downloadCount}${
      authorized ? `&jwt=${await api.access_token}` : ''
    }`;
  };

  return (
    <Button
      color="primary"
      size="small"
      onClick={() => {
        getDownloadUrl().then(newDownloadUrl => {
          setDownloadUrl(newDownloadUrl);
          setDownloadCount(downloadCount + 1);
        });
      }}
    >
      {text}
      {downloadUrl && (
        <iframe
          title={downloadUrl}
          src={downloadUrl}
          style={{display: 'none'}}
        />
      )}
    </Button>
  );
}
