import React from 'react';
import {makeStyles, createStyles, Theme} from '@material-ui/core';
import {primary, primaryDark} from '../../theme';

type Props = {
  hidden?: boolean;
  title: string;
  onClick: () => unknown;
  disabled?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      cursor: 'pointer',
      color: primary,
      '&:hover': {
        color: primaryDark,
      },
    },
    disabledText: {
      color: theme.palette.grey[500],
    },
  }),
);

export function TextButton({title, hidden, onClick, disabled}: Props) {
  const classes = useStyles();

  if (hidden) return null;

  return (
    <div
      className={disabled ? classes.disabledText : classes.text}
      onClick={disabled ? () => {} : onClick}
    >
      {title}
    </div>
  );
}
