import React, {useState} from 'react';
import {Typography, Popover} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStyles} from '@src/components/ui/organisms/uploaders/files/FileUploader/instructionsTemplates/styles';
import {FileCategory} from '@src/components/shared/patient/files/FilesOverview/util/types';
import {categoryWords} from '@src/components/shared/patient/files/FilesOverview/util/text';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';
import {reference} from '@src/util/stringManipulation/languageHelpers';

export function OverviewPageInstructions({
  fileCategory,
  patientIsCurrentUser,
  preferredName,
}: {
  fileCategory: FileCategory;
  patientIsCurrentUser: boolean;
  preferredName?: string;
}) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const possessiveWord = reference(
    preferredName ?? 'this patient',
    patientIsCurrentUser,
  )('poss');

  const categoryWord = categoryWords[fileCategory];

  return (
    <>
      <ParagraphText>
        Your upload will be placed in {possessiveWord}{' '}
        <Bold>{categoryWord} Files</Bold>{' '}
        <span onClick={handleClick}>
          <FontAwesomeIcon
            icon={['fas', 'info-circle']}
            size="sm"
            className={classes.icon}
            style={{cursor: 'pointer'}}
          />
        </span>{' '}
        section.
      </ParagraphText>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>
          If you would like to upload the file to a different section, please
          navigate to that section and click the{' '}
          <span>
            <FontAwesomeIcon
              icon={['fas', 'plus-circle']}
              size="sm"
              className={classes.icon}
            />
          </span>{' '}
          button.
        </Typography>
      </Popover>

      {fileCategory === 'visits' && (
        <ParagraphText>
          <Bold>Please use the drop-down</Bold> to select which visit this
          upload will be attached to.
        </ParagraphText>
      )}
    </>
  );
}
