import React, {useState} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';

import MuiAlert from '@material-ui/lab/Alert';
import {Snackbar, Typography} from '@material-ui/core';
import {PartnerRow} from './PartnerRow';
import {ProviderPartner, User} from '@src/models';

type Props = {
  setPartners: (ps: ProviderPartner[]) => unknown;
  user: AsyncData<User>;
  partners: AsyncData<ProviderPartner>;
};

export function PartnersList({partners, setPartners, user}: Props) {
  const [showFlashSuccess, setShowFlashSuccess] = useState(false);
  const [showFlashError, setShowFlashError] = useState(false);
  const oPartners = partners.getAllOptional().orElse([]);

  return (
    <>
      <Typography variant="caption" style={{fontStyle: 'italic'}}>
        Below are the Refyne Connected Care medical professionals you have
        designated to accept edits on your behalf for all of the Shared Care
        Plans that you own.
      </Typography>

      {oPartners.map(p => (
        <React.Fragment key={p.id}>
          <PartnerRow
            user={user}
            partnership={p}
            setPartners={setPartners}
            setShowFlashSuccess={setShowFlashSuccess}
            setShowFlashError={setShowFlashError}
          />
        </React.Fragment>
      ))}

      {(oPartners?.length ?? 0) === 0 && (
        <Typography style={{marginTop: '1.5em'}}>
          It looks like you don't have any partners yet. Please click on the Add
          tab to get started!
        </Typography>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showFlashSuccess}
        autoHideDuration={6000}
        message="Partner removed"
      >
        <MuiAlert variant="filled" severity="success">
          Partner removed!
        </MuiAlert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showFlashError}
        autoHideDuration={6000}
        message=""
      >
        <MuiAlert variant="filled" severity="error">
          There was a problem removing the partner. Please try again or contact
          support.
        </MuiAlert>
      </Snackbar>
    </>
  );
}
