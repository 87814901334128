import {useCallback} from 'react';
import {useApi} from '@src/api/useApi';

export const useGetDocumentSignature = (): ((
  file: File,
) => Promise<string>) => {
  const api = useApi();

  return useCallback(
    (file: File) => {
      return api.legalDocument().getUploadSignature({
        fileName: file.name,
        contentType: file.type || 'application/octet-stream',
      });
    },
    [api],
  );
};
