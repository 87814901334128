import {useCallback} from 'react';
import {useApi} from '@src/api/useApi';
import {useRequestHandler} from '@src/hooks/useRequestHandler';

const useAddGroupClientConfiguration = ({
  clientConfigurationId,
  groupId,
}: {
  clientConfigurationId: number | null;
  groupId: number | null;
}) => {
  const api = useApi();

  return useCallback(async () => {
    if (clientConfigurationId === null || groupId === null) {
      return Promise.resolve();
    }

    return api.callPool(groupId).addClientConfiguration(clientConfigurationId);
  }, [api, clientConfigurationId, groupId]);
};

export const useOnAddGroupClientConfiguration = ({
  runBeforeRequest,
  asyncRunAfterRequest,
  runFinally,
  clientConfigurationId,
  groupId,
  runOnError,
}: {
  runBeforeRequest: () => void;
  asyncRunAfterRequest: () => Promise<unknown>;
  runFinally: () => void;
  runOnError: () => void;
  clientConfigurationId: number | null;
  groupId: number | null;
}) => {
  const addEndpoint = useAddGroupClientConfiguration({
    clientConfigurationId,
    groupId,
  });

  return useRequestHandler({
    runBeforeRequest,
    asyncRequest: addEndpoint,
    asyncRunAfterRequest,
    runFinally,
    runOnError,
  });
};
