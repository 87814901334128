import {Divider} from '@material-ui/core';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  FormProvider,
  UnpackNestedValue,
  useForm,
  transformToNestObject,
} from 'react-hook-form';
import {ValidationError} from 'yup';
import {captureException} from '@sentry/browser';
import {
  flashError,
  flashSuccess,
  flashWarn,
} from '../../shared/notifications/flash';
import {AsyncActionButton} from '../../ui/form';
import {DeadEndPage} from '../../ui/layout/pages/deadEnd/DeadEndPage';
import {ParagraphText} from '../../ui/layout/text/body/ParagraphText';
import {Bold} from '../../ui/layout/text/decoration/Bold';
import {ConfirmSignModal} from './ConfirmSignModal';
import {ButtonContainer, ProviderRecordTitle} from './layout';
import {
  assessmentSchema,
  AssessmentSection,
  billingFieldsSchema,
  BillingFieldsSection,
  examSchema,
  ExamSection,
  historySchema,
  HistorySection,
  maiSchema,
  MAISection,
  planSchema,
  PlanSection,
  reasonForVisitSchema,
  ReasonForVisitSection,
  redFlagSchema,
  RedFlagSection,
  ReviewOfSystemsSection,
  rosSchema,
  VisitDocumentationValues,
  defaultVisitDocumentationValues,
  MAILabels,
  PlanLabels,
  ReasonForVisitLabels,
  AssessmentLabels,
  RedFlagLabels,
  ReviewOfSystemsLabels,
  BillingFieldsLabels,
  ExamLabels,
  HistoryLabels,
} from './sections';
import SkipValidationModal from './SkipValidationModal';
import {useSigning, parseErrorSchema} from './functions';
import {LonelyPatient} from '@src/models';
import {yObject} from '@src/schema/types';

type SaveOptions = {
  runOnDone?: boolean;
  needFlashSuccess?: boolean;
  resetDirtyState?: boolean;
};

const schema = yObject({
  ...assessmentSchema,
  ...billingFieldsSchema,
  ...maiSchema,
  ...examSchema,
  ...historySchema,
  ...planSchema,
  ...reasonForVisitSchema,
  ...redFlagSchema,
  ...rosSchema,
});

const labels: {[key: string]: string} = {
  ...AssessmentLabels,
  ...BillingFieldsLabels,
  ...MAILabels,
  ...ExamLabels,
  ...HistoryLabels,
  ...PlanLabels,
  ...ReasonForVisitLabels,
  ...RedFlagLabels,
  ...ReviewOfSystemsLabels,
};

type Props = {
  canSign: boolean;
  initialValues: VisitDocumentationValues;
  onDirty?: (dirty: boolean) => unknown;
  onDone?: () => void;
  onDoneText?: string;
  showTopButtons?: boolean;
  patient: LonelyPatient;
  onSign: () => Promise<unknown>;
  onSave: (
    data: UnpackNestedValue<VisitDocumentationValues>,
  ) => Promise<unknown>;
};

export function VisitDocumentationForm({
  canSign,
  initialValues,
  onDirty,
  onDone,
  onDoneText = 'Save and exit',
  showTopButtons = false,
  onSave,
  onSign,
  patient,
}: Props) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSkipValidationModal, setShowSkipValidationModal] = useState(false);

  const [isSigning, signingRef, setIsSigning] = useSigning(false);

  const defaultValues = {
    ...defaultVisitDocumentationValues(),
    ...initialValues,
  };

  const formMethods = useForm<VisitDocumentationValues>({
    defaultValues,
    resolver: async data => {
      try {
        const values: VisitDocumentationValues = ((await schema.validate(data, {
          abortEarly: false,
          context: {isSigning: signingRef.current},
        })) as unknown) as VisitDocumentationValues;
        return {values, errors: {}};
      } catch (e) {
        if (e instanceof ValidationError) {
          const parsedErrors = parseErrorSchema(e, false);
          const errors = transformToNestObject(parsedErrors);
          return {
            values: {},
            errors,
          };
        }
        console.error('Unexpected error occurred during validation');
        flashWarn(
          'We hit an error while trying to validate your documentation. Please try saving without signing and then contact Support',
        );
        captureException(e);
        console.error(e);
        return {values: {}, errors: {message: e.message}};
      }
    },
  });
  const {handleSubmit, formState, reset} = formMethods;
  const {isSubmitting, isDirty} = formState;

  const errorFieldLabels = useMemo(
    () => Object.keys(formMethods.errors).map(key => labels[key]),
    [formMethods.errors],
  );

  useEffect(() => {
    if (onDirty) {
      onDirty(isDirty);
    }
  }, [isDirty, onDirty]);

  const renderFields = useCallback(
    () => (
      <div>
        <ReasonForVisitSection isSigning={isSigning} />
        <ReviewOfSystemsSection patient={patient} />
        <HistorySection patient={patient} />
        <MAISection isSigning={isSigning} patient={patient} />
        <ExamSection />
        <AssessmentSection isSigning={isSigning} />
        <PlanSection isSigning={isSigning} />
        <BillingFieldsSection isSigning={isSigning} />
        <RedFlagSection isSigning={isSigning} />
      </div>
    ),
    [isSigning, patient],
  );

  const saveDocumentation = useCallback(
    (options: SaveOptions) => {
      setIsSigning(false);

      handleSubmit(
        async (data: UnpackNestedValue<VisitDocumentationValues>) => {
          const {needFlashSuccess, resetDirtyState, runOnDone} = options;

          await onSave(data);
          if (needFlashSuccess) {
            flashSuccess('Documentation saved successfully!', {
              permanent: false,
            });
          }

          if (resetDirtyState) {
            reset(data); // This resets the dirty state
          }

          if (runOnDone && onDone) {
            onDone();
          }
        },
      )();
    },
    [setIsSigning, handleSubmit, onSave, reset, onDone],
  );

  const handleSignDocumentationWithoutValidation = useCallback(
    async ({needFlashSuccess, runOnDone}: SaveOptions) => {
      try {
        await onSign();

        if (needFlashSuccess) {
          flashSuccess('Documentation saved and signed successfully!', {
            permanent: false,
          });
        }

        if (runOnDone && onDone) {
          onDone();
        }
      } catch (e) {
        flashError('Something went wrong.');
      }
    },
    [onSign, onDone],
  );

  const handleSignDocumentationWithValidation = useCallback(
    (options: SaveOptions) => {
      setIsSigning(true);

      handleSubmit(
        async () => {
          await handleSignDocumentationWithoutValidation(options);
          setIsSigning(false);
        },
        () => {
          setIsSigning(false);
          setShowSkipValidationModal(true);
        },
      )();
    },
    [setIsSigning, handleSubmit, handleSignDocumentationWithoutValidation],
  );

  if (!canSign)
    return (
      <DeadEndPage
        title="This documentation has already been signed"
        buttonText="View Past Visits"
        buttonTarget="/past-visits"
        className="documentation-already-signed"
        severity="warning"
      >
        <ParagraphText>
          This documentation can't be edited after it has already been signed.
        </ParagraphText>
        <ParagraphText>
          To view the documentation for this visit, please click{' '}
          <Bold>View Past Visits</Bold>.
        </ParagraphText>
      </DeadEndPage>
    );

  const actionButtons = (
    <ButtonContainer>
      <AsyncActionButton
        actionInProgress={isSubmitting}
        disabled={isSigning || isSubmitting}
        actionWord={'Save'}
        bStyle="primary"
        fullWidth
        onClick={() =>
          saveDocumentation({
            needFlashSuccess: true,
            runOnDone: false,
            resetDirtyState: true,
          })
        }
      />
      {canSign && (
        <AsyncActionButton
          actionInProgress={isSigning}
          disabled={isSigning || isSubmitting}
          actionWord={'Save and sign'}
          bStyle="primary"
          onClick={() => setShowConfirmModal(true)}
        />
      )}
      {onDone && (
        <AsyncActionButton
          actionInProgress={isSubmitting}
          actionWord={onDoneText}
          bStyle="primary"
          disabled={isSigning || isSubmitting}
          onClick={() =>
            saveDocumentation({
              runOnDone: true,
              needFlashSuccess: true,
              resetDirtyState: true,
            })
          }
        />
      )}
    </ButtonContainer>
  );

  return (
    <>
      {showTopButtons && actionButtons}

      <div>
        <ProviderRecordTitle>
          {patient.preferredName}
          &apos;s Documentation
        </ProviderRecordTitle>

        <FormProvider {...formMethods}>{renderFields()}</FormProvider>

        <Divider />

        {actionButtons}
      </div>
      <ConfirmSignModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onCancel={() => {}}
        onConfirm={async () => {
          saveDocumentation({
            runOnDone: false,
            needFlashSuccess: false,
            resetDirtyState: false,
          });
          handleSignDocumentationWithValidation({
            runOnDone: true,
            needFlashSuccess: true,
          });
        }}
      />
      <SkipValidationModal
        show={showSkipValidationModal}
        errorFieldLabels={errorFieldLabels}
        onConfirm={async () => {
          setIsSigning(true);
          await handleSignDocumentationWithoutValidation({
            runOnDone: true,
            needFlashSuccess: true,
          });
          setIsSigning(false);
        }}
        onCancel={() => {}}
        onHide={() => {
          setShowSkipValidationModal(false);
        }}
      />
    </>
  );
}
