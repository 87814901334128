import classnames from 'classnames';
import React, {useState} from 'react';
import styled from 'styled-components';
import {TextInput} from '../../../ui/form';
import {xs} from '../../../ui/theme';
import {ExamCode, codeToLabel} from '../sections/systems';

const ExamInputStyled = styled.div`
  margin: ${xs} 0;
  width: 100%;
  textarea {
    width: 100%;
    margin: 0;
  }
  display: flex;
  flex-direction: column;
`;

const ExamInputType = styled.div`
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
  &.has-content {
    font-weight: bold;
  }
`;

const ExamInputSelection = styled.div`
  padding-left: ${xs};
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

type Props = {
  code: ExamCode;
  remarks: string;
  onRemarksChange: (remarks: string) => void;
  placeholder?: string;
  error?: string;
  touched?: boolean;
};

export function ExamInput({
  code,
  remarks = '',
  touched,
  onRemarksChange,
  error,
  placeholder,
}: Props) {
  const [showRemarks, setShowRemarks] = useState(remarks !== '');

  return (
    <ExamInputStyled>
      <ExamInputSelection>
        <ExamInputType
          onClick={() => {
            setShowRemarks(prevState => !prevState);
          }}
          className={classnames([{'has-content': showRemarks || !!remarks}])}
        >
          {codeToLabel(code)}
        </ExamInputType>
      </ExamInputSelection>
      {showRemarks && (
        <div>
          <TextInput
            placeholder={placeholder}
            name={code}
            autoExpand
            value={remarks}
            onChange={e => onRemarksChange(e.target.value)}
            error={error}
            touched={touched}
          />
        </div>
      )}
    </ExamInputStyled>
  );
}
