import React, {useCallback, useEffect} from 'react';
import {useParams, useHistory} from 'react-router';
import {Grid} from '@material-ui/core';
import {useStyles} from '../ProviderGroupOverviewPage/layout';
import {ProviderGroupCard} from '../ProviderGroupOverviewPage/ProviderGroupCard';
import {MonthNavigator} from './MonthNavigator';
import {MonthTable} from './MonthTable';
import {PopulateCallPeriod} from './MonthTable/Day/Shifts/Shift/modals/PopulateCallPeriod';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {useQuery} from '@src/hooks/useQuery';
import {
  useRosterResources,
  RosterResources,
} from '@src/hooks/providerGroups/rosterPage/useRosterResources';
import {createGenericContext} from '@src/util/reactContext/createGenericContext';
import {BackButton} from '@src/components/shared/BackButton';
import {PageSection} from '@src/components/ui/atoms/section/PageSection';

const [usePageContext, PageContextProvider] = createGenericContext<
  RosterResources
>();

// eslint-disable-next-line import/no-unused-modules
export default function ProviderGroupRosterPage() {
  const history = useHistory();
  const classes = useStyles();

  const params = useParams<{id: string}>();
  const callPoolId = +params.id;

  const query = useQuery();

  const scheduled = query.get('scheduled') === 'true';
  const onDemand = query.get('on-demand') === 'true';

  if (!scheduled && !onDemand) {
    history.goBack();
  }

  const rosterResources = useRosterResources({callPoolId});

  const {
    dayComponent: {
      periodToPopulate,
      setPeriodToPopulate,
      providerOptionsForPeriodToPopulate,
    },
    monthTable: {reloadPeriodsMappedToDaysOfSelectedMonth},
    isPageLoading,
    setGroup,
    rosterPageGroup,
  } = rosterResources;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const resetPeriodToPopulate = useCallback(() => {
    setPeriodToPopulate(null);
  }, [setPeriodToPopulate]);

  const title = scheduled ? 'Scheduled' : onDemand ? 'On Demand' : '';

  return (
    <PageContextProvider value={rosterResources}>
      <PageLoading active={isPageLoading} message="Loading">
        <Grid
          container
          direction="column"
          className={classes.overviewContainer}
        >
          <Grid
            item
            container
            className={classes.backContainer}
            justify="flex-end"
            direction="row"
          >
            <Grid item>
              <BackButton />
            </Grid>
          </Grid>
          <ProviderGroupCard
            providerGroup={rosterPageGroup.getOptional().orNothing()}
            onUpdateGroup={setGroup}
          />
          <PageSection title={title} fullWidth>
            <MonthNavigator />
            <MonthTable />
          </PageSection>

          {periodToPopulate !== null && (
            <PopulateCallPeriod
              period={periodToPopulate}
              show={periodToPopulate !== null}
              setShow={resetPeriodToPopulate}
              periodPoolsMembers={providerOptionsForPeriodToPopulate}
              onAdded={reloadPeriodsMappedToDaysOfSelectedMonth}
            />
          )}
        </Grid>
      </PageLoading>
    </PageContextProvider>
  );
}

export {usePageContext};
