import {ReturnButtonReason} from './types';

export const buttonMessages = {
  roomFull: 'Retry',
  visitLeft: 'Return To Visit',
  error: 'Retry',
};

export function getReturnButtonText(reason: ReturnButtonReason) {
  return buttonMessages[reason];
}
