import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';
import React, {useState} from 'react';
import {Grid} from '@material-ui/core';
import {ButtonContainer} from '../layout';
import {OnCallTable} from './OnCallTable';
import {
  RepeatingPeriodModal,
  SinglePeriodModal,
} from '@src/components/providerSide/OnCallPeriodModals';
import {convertOnCallPeriod} from '@src/components/providerSide/OnCallPeriodModals/SinglePeriodModal/functions';
import {Button} from '@src/components/ui/form/Button';
import {UserId} from '@src/models/User';
import {OnCallPeriod} from '@src/models';

type Props = {
  onCallPeriods: AsyncData<OnCallPeriod>;
  onCallNumber: Optional<string>;
  onCreateOnCallPeriods: (period: OnCallPeriod[]) => unknown;
  onDeleteOnCallPeriod: (period: OnCallPeriod) => unknown;
  providerId: UserId;
};

export const OnCall = ({
  onCallNumber,
  onCallPeriods,
  onCreateOnCallPeriods,
  onDeleteOnCallPeriod,
  providerId,
}: Props) => {
  const [showOneOffModal, setShowOneOffModal] = useState(false);
  const [showRepeatingModal, setShowRepeatingModal] = useState(false);
  const [periodToEdit, setPeriodToEdit] = useState<OnCallPeriod | undefined>(
    undefined,
  );

  return (
    <>
      <Grid container direction="column">
        <OnCallTable
          onCallPeriods={onCallPeriods}
          managePeriodsProps={{
            setShowOneOffModal,
            onDeleteOnCallPeriod,
            setPeriodToEdit,
          }}
        />
      </Grid>

      <ButtonContainer style={{marginTop: '0.5rem'}}>
        <Button
          bSize="small"
          bStyle={'primary'}
          isInRow={true}
          disabled={!onCallNumber.isPresent() || !onCallPeriods.isLoaded()}
          onClick={() => {
            setPeriodToEdit(undefined);
            setShowOneOffModal(true);
          }}
        >
          {onCallNumber.isPresent()
            ? 'Add one-off coverage'
            : 'Please set your on call number'}
        </Button>
        <Button
          isInRow={true}
          bSize="small"
          bStyle={'primary'}
          disabled={!onCallNumber.isPresent() || !onCallPeriods.isLoaded()}
          onClick={() => {
            setPeriodToEdit(undefined);
            setShowRepeatingModal(true);
          }}
        >
          {onCallNumber.isPresent()
            ? 'Add repeating coverage'
            : 'Please set your on call number'}
        </Button>
      </ButtonContainer>
      {showOneOffModal && (
        <SinglePeriodModal
          purpose="Coverage"
          providerId={providerId}
          show={showOneOffModal}
          onClose={() => setShowOneOffModal(false)}
          onCreateOnCallPeriods={onCreateOnCallPeriods}
          period={periodToEdit ? convertOnCallPeriod(periodToEdit) : undefined}
          preselectedProviderGroups={periodToEdit?.callPools}
        />
      )}
      {showRepeatingModal && (
        <RepeatingPeriodModal
          purpose="Coverage"
          providerId={providerId}
          show={showRepeatingModal}
          onClose={() => setShowRepeatingModal(false)}
          onCreateOnCallPeriods={onCreateOnCallPeriods}
          preselectedProviderGroups={periodToEdit?.callPools}
        />
      )}
    </>
  );
};
