import React from 'react';
import {PatientDetailsForm} from '../../../SharedPatient/PatientDetailsForm';
import {StepProps} from '../../functions';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {FlowProps} from '@src/hooks/useSteps';

export function PatientDetailsStep({
  patientId,
  moveGuard,
  patientDetails,
  setPatientDetails,
  existingPersonForIndependentPatient,
  setExistingPersonForIndependentPatient,
  patientIsCurrentUser,
}: StepProps & FlowProps) {
  return (
    <>
      <RequestHeader>Patient Details</RequestHeader>
      <RequestInstructions>
        <p>Let's start by getting the basic information about this patient.</p>
      </RequestInstructions>
      <PatientDetailsForm
        key={patientId}
        mode="update"
        moveGuard={moveGuard}
        patientDetails={patientDetails}
        setPatientDetails={setPatientDetails}
        patientId={patientId}
        existingPersonForIndependentPatient={
          existingPersonForIndependentPatient
        }
        setExistingPersonForIndependentPatient={
          setExistingPersonForIndependentPatient
        }
        patientIsCurrentUser={patientIsCurrentUser}
      />
    </>
  );
}
