import {Optional} from '@ahanapediatrics/ahana-fp';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';

import React from 'react';
import {AddToCallPoolButton} from './AddToCallPoolButton';
import {CallPool} from '@src/models';
import {by} from '@src/util/arrayManipulation/by';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {PaymentControl} from '@src/models/CallPool';

type Props = {
  detailsId: ProviderDetailsId;
  callPools: Optional<readonly CallPool[]>;
  onUpdated: () => unknown;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      color: theme.palette.primary.main,
      display: 'inline-block',
      minWidth: '7em',
    },
    value: {},
    table: {},
  }),
);

const PaymentIcons: Record<PaymentControl, IconProp> = {
  Required: ['fas', 'check-circle'],
  Optional: ['fas', 'question-circle'],
  None: ['fas', 'times-circle'],
};

export const CallPoolsCard = ({callPools, onUpdated, detailsId}: Props) => {
  const classes = useStyles();

  if (callPools.orElse([]).length === 0) {
    return null;
  }

  return callPools
    .map(cps => (
      <>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name for Providers</TableCell>
                <TableCell>Name for Guardians</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="center">Insurance</TableCell>
                <TableCell align="center">Credit Card</TableCell>
                <TableCell align="center">
                  Refyne Connected Care Documentation?
                </TableCell>
                <TableCell align="center">Private?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...cps].sort(by('id')).map(cp => (
                <TableRow key={cp.id}>
                  <TableCell>{cp.id}</TableCell>
                  <TableCell>{cp.nameForProviders}</TableCell>
                  <TableCell>{cp.nameForGuardians}</TableCell>
                  <TableCell>{cp.description}</TableCell>
                  <TableCell align="center">
                    <FontAwesomeIcon
                      icon={
                        PaymentIcons[cp.rules.payments?.insurance ?? 'None']
                      }
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <FontAwesomeIcon
                      icon={
                        PaymentIcons[cp.rules.payments?.creditCard ?? 'None']
                      }
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">
                    {cp.documentInAhana ? (
                      <FontAwesomeIcon
                        icon={['far', 'check-circle']}
                        color="primary"
                      />
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {cp.private ? (
                      <FontAwesomeIcon
                        icon={['far', 'check-circle']}
                        color="primary"
                      />
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{marginTop: '15px'}}>
          <AddToCallPoolButton
            buttonLabel="Update Call Pools"
            callPools={[...cps]}
            detailsId={detailsId}
            onUpdated={onUpdated}
          />
        </div>
      </>
    ))
    .orNull();
};
