import {yupResolver} from '@hookform/resolvers';
import {FormProvider, useForm} from 'react-hook-form';
import React, {useState, useCallback, useEffect} from 'react';
import {convertDetails} from '../../CreateOrJoinVisit/steps/InsuranceStep/functions';
import {PaymentDetailsForm} from '../../PaymentDetails';
import {EditInsurance} from './EditInsurance';
import {useApi} from '@src/api/useApi';
import {Modal} from '@src/components/ui/layout/Modal';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {Button} from '@src/components/ui/form';
import {Patient} from '@src/models';
import {yObject, yBoolean, yString, rqString} from '@src/schema/types';

const schema = yObject({
  blockedForNonPayment: yBoolean,
  hasInsuranceAndUnknownDetails: yBoolean.nullable(),
  insuranceId: yString.when('hasInsuranceAndUnknownDetails', {
    is: true,
    then: yString,
    otherwise: rqString('Please provide this information'),
  }),
  insuranceProvider: yString.when('hasMedicaid', {
    is: value => value === true || value === null,
    then: yString,
    otherwise: rqString('Please provide your Insurance Provider'),
  }),
  insuranceGroup: yString.when('hasMedicaid', {
    is: value => value === true || value === null,
    then: yString,
    otherwise: rqString('Please provide your Insurance Group'),
  }),
  hasMedicaid: yBoolean.nullable(),
});

type Props = {
  details: PaymentDetailsForm;
  onClose: () => unknown;
  onUpdateInsurance: () => unknown;
  patient: Patient;
  type: 'primary' | 'secondary' | 'none';
};
// eslint-disable-next-line import/no-unused-modules
export default function EditInsuranceModal({
  details,
  onClose,
  onUpdateInsurance,
  patient,
  type,
}: Props) {
  const api = useApi();
  const [saving, setSaving] = useState(false);
  const formMethods = useForm<PaymentDetailsForm>({
    defaultValues: details,
    resolver: yupResolver(schema, {strict: true}),
  });
  const {handleSubmit, reset} = formMethods;

  useEffect(() => {
    reset(details);
  }, [details, reset]);

  const onSave = useCallback(
    (data: PaymentDetailsForm) => {
      setSaving(true);
      data.hasHealthInsurance = true;
      api
        .patient(patient.id)
        .updatePaymentInformation(convertDetails(data), {
          primary: type === 'primary',
        })
        .then(onUpdateInsurance)
        .then(() => setSaving(false))
        .then(() => onClose());
    },
    [api, patient.id, onClose, onUpdateInsurance, type],
  );

  return (
    <FormProvider {...formMethods}>
      <Modal
        show={type !== 'none'}
        title="Please tell us about your insurance"
        modalActions={
          <>
            <MuiAsyncActionButton
              bStyle={'primary'}
              disabled={saving}
              actionInProgress={saving}
              actionWord="Save"
              onClick={handleSubmit(onSave)}
            >
              Save
            </MuiAsyncActionButton>
            <Button bStyle="secondary" onClick={onClose}>
              Cancel
            </Button>
          </>
        }
      >
        <EditInsurance type={type} patient={patient} details={details} />
      </Modal>
    </FormProvider>
  );
}
