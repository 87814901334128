import React from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {Modal} from '../../../ui/layout/Modal';
import {
  responsiblePersonToValues,
  FinanciallyResponsiblePersonValues,
} from './functions';
import {ResponsiblePersonCard} from './ResponsiblePersonCard';
import {MuiHeading} from '@src/components/ui/layout/MuiHeading';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {ResponsiblePerson} from '@src/models';
import {Button} from '@src/components/ui/form';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

type Props = {
  conflictingRelationship: string;
  setConflictingRelationship: (r: string) => unknown;
  setValues: (values: FinanciallyResponsiblePersonValues) => unknown;
  setPersonId: (id: NonProfessionalId) => unknown;
  conflictingPerson: Optional<ResponsiblePerson>;
  setConflictingPerson: (p: Optional<ResponsiblePerson>) => unknown;
};
export function ConflictingPersonModal({
  conflictingPerson,
  setConflictingPerson,
  conflictingRelationship,
  setConflictingRelationship,
  setPersonId,
  setValues,
}: Props) {
  return (
    <Modal
      show={conflictingPerson.isPresent()}
      title="This Person is already in our system"
      onClose={() => {
        setConflictingPerson(Optional.empty());
      }}
      modalActions={
        <>
          <Button
            onClick={() => {
              setValues({
                ...responsiblePersonToValues(conflictingPerson),
                relationship: conflictingRelationship,
              });
              setPersonId(
                conflictingPerson.property('id', 0 as NonProfessionalId),
              );
              setConflictingPerson(Optional.empty());
              setConflictingRelationship('');
            }}
          >
            Choose this person
          </Button>
          <Button
            onClick={() => {
              setConflictingPerson(Optional.empty());
              setConflictingRelationship('');
            }}
          >
            Cancel
          </Button>
        </>
      }
    >
      <>
        <MuiHeading variant="h2" style={{marginBottom: '0'}}>
          We already know that email address
        </MuiHeading>
        <ParagraphText style={{marginTop: '0.5rem'}}>
          The email address that you provided is one we already know.
        </ParagraphText>
        <ParagraphText style={{marginTop: '0.5rem'}}>
          {' '}
          Please take a look at this person's details and if that's who you are
          trying to add, click "Choose this person".
        </ParagraphText>
        <ParagraphText style={{marginTop: '0.5rem'}}>
          If not, please click "Cancel" and change the email address.
        </ParagraphText>
        {conflictingPerson.isPresent() && (
          <ResponsiblePersonCard
            relationship={conflictingRelationship}
            person={conflictingPerson.get()}
          />
        )}
      </>
    </Modal>
  );
}
