import React from 'react';
import {Control, Controller, DeepMap, FieldError} from 'react-hook-form';
import {CreateProvider} from '.';
import {PhoneInput, TextInput} from '@src/components/ui/form';
import {CallPoolsMultiselect} from '@src/components/ui/organisms/selectors/CallPoolsMultiselect';
import {useAllGroups} from '@src/hooks/providerGroups/useAllGroups';

type SeesPatientsForm = {
  callPoolIds: number[];
  setCallPoolIds: (v: number[]) => unknown;
  control: Control<CreateProvider>;
  errors: DeepMap<CreateProvider, FieldError>;
};

// eslint-disable-next-line import/no-unused-modules
export default function SeesPatientsForm({
  callPoolIds,
  setCallPoolIds,
  control,
  errors,
}: SeesPatientsForm) {
  const [allGroups] = useAllGroups({includeUnapproved: true});

  return (
    <div>
      <CallPoolsMultiselect
        callPools={allGroups}
        onUpdateSelection={setCallPoolIds}
        selectedIds={callPoolIds}
      />
      <Controller
        control={control}
        name="qualification"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            value={value}
            name={name}
            required
            title="Qualification"
            onChange={onChange}
            onBlur={onBlur}
            error={errors.qualification?.message}
            touched={isTouched}
          />
        )}
      />

      <Controller
        control={control}
        name="speciality"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            value={value}
            name={name}
            required
            title="Speciality"
            onChange={onChange}
            onBlur={onBlur}
            error={errors.speciality?.message}
            touched={isTouched}
          />
        )}
      />

      <Controller
        control={control}
        name="phone"
        render={({onChange, value, name}, {isTouched}) => (
          <PhoneInput
            value={value}
            name={name}
            required
            title="Phone"
            onChange={onChange}
            error={errors.phone?.message}
            touched={isTouched}
          />
        )}
      />
    </div>
  );
}
