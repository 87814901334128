import React from 'react';
import {UserType} from '../../../../../models';
import {ParagraphText} from '../../../../ui/layout/text/body/ParagraphText';
import {useUser} from '@src/hooks/useUser';

type Props = {
  isSelf?: boolean;
};

export function InstructionsText({isSelf = false}: Props) {
  const [, userType] = useUser();

  const nonSelfInstructions =
    'To connect a Login to this patient, please enter their email address and relationship description below';

  if (userType === UserType.Guardian) {
    return <ParagraphText>{nonSelfInstructions}</ParagraphText>;
  } else {
    if (isSelf) {
      return (
        <ParagraphText>
          If you would like to invite this patient to manage their own
          healthcare, you can search for a user already in the Refyne Connected
          Care system or enter a new email address.
        </ParagraphText>
      );
    } else {
      return <ParagraphText>{nonSelfInstructions}</ParagraphText>;
    }
  }
}
