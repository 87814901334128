import React from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {GroupSection} from './GroupSection';
import {CallPool, UserType} from '@src/models';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {allLoaded} from '@src/util/apiHelpers';

export function Groups({
  belongsTo,
  manages,
  userType,
}: {
  belongsTo: AsyncData<CallPool>;
  manages: AsyncData<CallPool>;

  userType: UserType;
}) {
  const managesValues = manages.getAllOptional().orElse([]);
  const belongsToValues = belongsTo.getAllOptional().orElse([]);

  return (
    <PageLoading
      active={!allLoaded(belongsTo, manages)}
      message={'Loading Groups...'}
    >
      <GroupSection
        groups={belongsToValues}
        userType={userType}
        heading={'Provider Groups I Belong To'}
      />
      {managesValues.length > 0 && (
        <GroupSection
          groups={managesValues}
          userType={userType}
          heading={'Provider Groups I Manage'}
        />
      )}
    </PageLoading>
  );
}
