import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useEffect, useState} from 'react';
import InsuranceSummary from '../../../InsuranceSummary';
import {Patient} from '@src/models';
import {Acknowledge} from '@src/components/ui/form/Acknowledge';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {PaymentControl} from '@src/models/CallPool';
import {useConjugate, useReference} from '@src/hooks';

type Props = {
  patient: Patient;
  onAddOrEdit: () => unknown;
  setNextAvailable: (a: boolean) => unknown;
  insuranceControl: PaymentControl;
  creditCardControl: PaymentControl;
};

export const InsuranceView = ({
  onAddOrEdit,
  patient,
  setNextAvailable,
  insuranceControl,
  creditCardControl,
}: Props) => {
  const {paymentInformation, secondaryPayment} = patient;
  const [ready, setReady] = useState(false);

  const r = useReference(patient);
  const c = useConjugate(patient);

  const isReady =
    paymentInformation.map(p => p.hasHealthInsurance).orElse(false) ||
    paymentInformation
      .map(p => p.hasInsuranceAndUnknownDetails)
      .orElse(false) ||
    insuranceControl === 'Optional';

  const oPatient = Optional.of(patient);

  useEffect(() => {
    setNextAvailable(ready && isReady);
  }, [ready, setNextAvailable, isReady]);

  return (
    <>
      <ParagraphText>
        To support this visit, we need to gather {r('poss')} basic insurance
        information.
      </ParagraphText>
      {insuranceControl === 'Required' && creditCardControl === 'None' && (
        <ParagraphText>
          This visit <em>requires</em> {r('nom')} to have insurance. If{' '}
          {r('nom')} {c('do')} not have insurance, please contact your provider
          directly.
        </ParagraphText>
      )}
      {insuranceControl === 'Optional' && (
        <ParagraphText>
          If you don't have {r('poss')} insurance information available right
          now, it's OK to skip this step. Someone will follow up with you after
          your visit.
        </ParagraphText>
      )}
      <InsuranceSummary
        editable
        patient={oPatient}
        primary={Optional.of(paymentInformation)}
        secondary={Optional.of(secondaryPayment)}
        onInsuranceUpdated={() => {
          onAddOrEdit();
          setReady(false);
        }}
      />
      <Acknowledge
        value={Optional.of(ready)}
        setValue={setReady}
        message="Confirm that the information above is correct"
        disabled={!isReady}
        style={{marginTop: '1rem'}}
      />
    </>
  );
};
