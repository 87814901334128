import {format} from 'date-fns';
import React from 'react';
import {Grid} from '@material-ui/core';
import {Updatable} from '@src/components/shared/PatientDashboard/identifyLastUpdater';
import {fullName} from '@src/util/users/getDemographics';
import {LightParagraphText} from '@src/components/ui/layout/text/body/LightParagraphText';

type Props = {
  updated: Updatable;
};

export const LastUpdated = ({updated}: Props) => {
  const oQualification = updated.qualification.orElse('');

  return (
    <Grid item md={12}>
      <LightParagraphText>
        Last updated: {!updated.updatedAt.isPresent() && 'Not updated'}
        {updated.updatedAt.isPresent() && (
          <>
            {updated.updatedAt.map(date => format(date, 'M/d/yyyy')).get()}
            {updated.lastUpdatedBy
              .map(u => ` by ${fullName(u)}`)
              .orElse(' by Refyne Connected Care System')}
            {oQualification && `, ${oQualification}`}
          </>
        )}
      </LightParagraphText>
    </Grid>
  );
};
