import {Grid, Typography} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import {useHistory} from 'react-router';
import {Logo} from './Logo';
import {MenuIcons} from './MenuIcons';
import {MenuItem, MenuItems} from './MenuItems';
import {useStyles} from './styles';
import {PermissionsProps} from '.';
import {UserType} from '@src/models';
import {ApplicationEvents, sendEvent} from '@src/events';
import {md, sm} from '@src/components/ui/theme';

type Props = {
  onLogout?: () => unknown;
  setShowLogoutModal?: (v: boolean) => unknown;
  type: 'horizontal' | 'vertical';
  userType: UserType;
  permissions: PermissionsProps;
};

export function NavigationSkeleton({
  onLogout,
  setShowLogoutModal,
  type,
  userType,
  permissions,
}: Props) {
  const classes = useStyles();
  const history = useHistory();

  const menuItems = MenuItems(userType, permissions);

  const getListItem = ({to = '', label, action}: MenuItem) => {
    const onClick = () => {
      sendEvent(ApplicationEvents.MenuItemClicked);
      if (action) {
        action();
      } else {
        history.push(to);
      }
    };

    return (
      <Grid item key={label?.toString()}>
        <Typography
          variant="button"
          onClick={onClick}
          className={clsx(classes.menuItem)}
        >
          {label}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid
      className={classes.navigationSkeleton}
      container
      justify={type === 'vertical' ? 'space-between' : undefined}
      direction={type === 'vertical' ? 'column' : 'row'}
    >
      <Grid
        item
        container
        alignItems={type === 'vertical' ? 'center' : 'flex-start'}
        style={{width: '150px'}}
        onClick={() => {
          sendEvent(ApplicationEvents.MenuItemClicked);
        }}
        className={classes.menuItem}
      >
        <Logo
          to="/"
          style={{marginTop: type === 'vertical' ? '2rem' : 'inherit'}}
        />
      </Grid>
      <Grid
        item
        container
        justify="flex-start"
        direction={type === 'vertical' ? 'column' : 'row'}
        xs={type === 'horizontal'}
        alignItems={type === 'vertical' ? 'flex-start' : 'center'}
        spacing={type === 'vertical' ? 1 : 2}
      >
        {menuItems.map(getListItem)}
      </Grid>
      <Grid
        item
        container
        justify="flex-end"
        direction="row"
        alignItems="center"
        xs={type === 'vertical' ? 12 : 2}
        style={type === 'vertical' ? {marginLeft: `-${sm}`, marginTop: md} : {}}
      >
        {userType !== UserType.Unknown && (
          <MenuIcons
            justify={type === 'vertical' ? 'flex-start' : 'flex-end'}
            onLogout={onLogout}
            setShowLogoutModal={setShowLogoutModal}
          />
        )}
      </Grid>
    </Grid>
  );
}
