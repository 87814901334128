import * as React from 'react';
import {ReactNode} from 'react';
import {useHistory} from 'react-router';
import {Grid, useTheme} from '@material-ui/core';
import {Button} from '../../../form';
import {MuiHeading} from '../../MuiHeading';

type Props = {
  className: string;
  title: string;
  buttonText?: string;
  buttonTarget?: string;
  children: ReactNode[];
  severity: 'warning' | 'info' | 'danger';
};

export function DeadEndPage({
  className = '',
  title = 'Something went wrong',
  buttonText = 'Go back',
  buttonTarget = '/',
  children,
  severity = 'info',
}: Props) {
  const history = useHistory();
  const theme = useTheme();

  return (
    <Grid container className={className}>
      <Grid
        item
        xs={12}
        style={{
          textAlign: 'center',
          maxWidth: '600px',
          margin: '3rem auto',
        }}
      >
        <MuiHeading style={{color: theme.palette.grey[600]}}>
          {title}
        </MuiHeading>
        {children}
        {buttonTarget && buttonText && (
          <Grid item xs={12} style={{padding: '2rem'}}>
            <Button
              bStyle={severity === 'info' ? 'primary' : severity}
              onClick={() => history.push(buttonTarget)}
            >
              {buttonText}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
