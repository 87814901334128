import {AsyncData} from '@ahanapediatrics/ahana-fp';
import React, {useCallback, useEffect, useState} from 'react';
import {DesktopTable} from './DesktopTable';
import {MobileTable} from './MobileTable';
import {useChiefComplaint} from './ChiefComplaint';
import {VisitColumn} from './ColumnSets';
import {ShareLinkModal} from '@src/components/shared/ExamRoomModals/ShareLinkModal';
import {mobileMaxAsNumber} from '@src/components/ui/theme';
import {useWindowEvent} from '@src/hooks';
import {LonelyVisit, SimpleVisit} from '@src/models';

type Props = {
  columns: VisitColumn[];
  directToRoom: boolean;
  hairChecked: Record<number, boolean>;
  onUpdate: (v: LonelyVisit) => unknown;
  onCancel: (v: SimpleVisit) => unknown;
  returnText?: string;
  visits: AsyncData<LonelyVisit>;
};

export type DesktopAndMobileTableProps = {
  columns: VisitColumn[];
  directToRoom: boolean;
  hairChecked: Record<number, boolean>;
  onCancel: (v: SimpleVisit) => unknown;
  onShareLink: (visitId: number) => unknown;
  onUpdate: (v: LonelyVisit) => unknown;
  returnText?: string;
  visits: AsyncData<LonelyVisit>;
};

export function VisitTable({
  columns,
  directToRoom,
  hairChecked,
  onCancel,
  onUpdate,
  returnText,
  visits,
}: Props) {
  const [mode, setMode] = useState('');

  const [shareLinkVisitId, setShareLinkVisitId] = useState(0);

  useChiefComplaint();

  const updateSize = useCallback(() => {
    const width = window.innerWidth;
    if (width < mobileMaxAsNumber) {
      setMode('mobile');
    } else {
      setMode('desktop');
    }
  }, []);

  // While we respond to the resize event, we need to run at least once
  // to get the right form factor
  useWindowEvent('resize', updateSize);
  useEffect(updateSize, [updateSize]);

  const Table =
    mode === 'desktop' ? (
      <DesktopTable
        columns={columns}
        directToRoom={directToRoom}
        hairChecked={hairChecked}
        onCancel={onCancel}
        onShareLink={setShareLinkVisitId}
        onUpdate={onUpdate}
        returnText={returnText}
        visits={visits}
      />
    ) : (
      <MobileTable
        columns={columns}
        directToRoom={directToRoom}
        hairChecked={hairChecked}
        onCancel={onCancel}
        onShareLink={setShareLinkVisitId}
        onUpdate={onUpdate}
        returnText={returnText}
        visits={visits}
      />
    );

  return (
    <>
      {Table}
      {shareLinkVisitId > 0 && (
        <ShareLinkModal
          visitId={shareLinkVisitId}
          patient={
            visits
              .getAllOptional()
              .orElse([])
              .find(v => v.id === shareLinkVisitId)?.patient
          }
          showModal={shareLinkVisitId > 0}
          setShowModal={show => {
            if (!show) {
              setShareLinkVisitId(0);
            }
          }}
        />
      )}
    </>
  );
}
