import React from 'react';
import {TableCell} from '@material-ui/core';
import {Mergeable} from '../../ExpandableSection/isMergeable';
import {MergeStateIcon} from './getMergeStateIcon';
import {useStyles} from './styles';
import {md, sm} from '@src/components/ui/theme';

type Props = {
  mergeState: Mergeable;
};

export function ResultIconCell({mergeState}: Props) {
  const classes = useStyles();

  return (
    <TableCell className={classes.iconCell}>
      {MergeStateIcon({
        state: mergeState,
        style: {
          marginLeft: sm,
          display: 'block',
          margin: 'auto',
          height: md,
        },
      })}
    </TableCell>
  );
}
