import {RemoteParticipant} from 'twilio-video';
import {SetParticipants} from '@src/util/videoChat';

export type OnParticipantConnectedProps = {
  setParticipants: SetParticipants;
  setNotification?: (v: JSX.Element | null) => void;
  participant: RemoteParticipant;
};

export function onParticipantConnected({
  setParticipants,
  setNotification,
  participant,
}: OnParticipantConnectedProps): void {
  setParticipants(prevParticipants => [
    ...prevParticipants.filter(p => p !== participant),
    participant,
  ]);

  if (setNotification) {
    setNotification(null);
  }
}

export type OnParticipantDisconnectedProps = {
  setParticipants: SetParticipants;
  participant: RemoteParticipant;
};

export function onParticipantDisconnected({
  setParticipants,
  participant,
}: OnParticipantDisconnectedProps): void {
  setParticipants(prevParticipants =>
    prevParticipants.filter(p => p !== participant),
  );
}
