import React, {useState, useEffect} from 'react';

import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';

// Twilio
import {RemoteParticipant, Room} from 'twilio-video';

// Components
import {useStyles, DOCUMENTATION_WIDTH, SIDEBAR_WIDTH} from '../layout';
import {FactsDisplay} from '../../FactsDisplay';
import ParticipantVideo from './ParticipantVideo';
import {SidebarVideoGallery} from './SidebarVideoGallery';
import {getScreensharingElement} from './videoGalleryFunctions';

// Layout
import {LonelyPatient} from '@src/models';
import {
  detectIsAnyoneScreensharing,
  detectIsLocalScreensharing,
  SetParticipants,
  calculateAvailableWidth,
  doNotTransmit,
} from '@src/util/videoChat';
import {useVideoStyles} from '@src/components/ui/layout/VideoStyles';
import {debouncedRecalculateVideoLayout} from '@src/hooks/videoChat/useRecalculateVideoLayout';

type VideoGalleryProps = {
  cutRoomAudio: boolean;
  isDisconnecting: boolean;
  participants: RemoteParticipant[];
  patient: AsyncData<LonelyPatient>;
  room: Room | null;
  setNotification: (v: JSX.Element | null) => void;
  setParticipants: SetParticipants;
  setWaitingParticipants: SetParticipants;
  showDocumentation: boolean;
  visitId: number;
  waitingParticipants: RemoteParticipant[];
  waitingRoomMessages: Optional<string[]>;
};

export function VideoGallery({
  cutRoomAudio,
  isDisconnecting,
  participants,
  patient,
  room,
  setNotification,
  setParticipants,
  setWaitingParticipants,
  showDocumentation,
  visitId,
  waitingParticipants,
  waitingRoomMessages,
}: VideoGalleryProps) {
  const videoClasses = useVideoStyles();
  const [isAnyoneScreensharing, setIsAnyoneScreensharing] = useState(false);
  const [isLocalScreensharing, setIsLocalScreensharing] = useState(false);
  const [isLocalAudioMuted, setIsLocalAudioMuted] = useState(false);
  const [isLocalVideoDisabled, setIsLocalVideoDisabled] = useState(false);

  const classes = useStyles({
    width: showDocumentation ? DOCUMENTATION_WIDTH : SIDEBAR_WIDTH,
  });

  const shouldNotTransmit = doNotTransmit({participants, isDisconnecting});

  useEffect(() => {
    if (detectIsAnyoneScreensharing({room})) {
      handleIsScreensharing();
    } else {
      handleNotScreensharing();
    }
  }, [room]);

  if (!room) return null;

  function handleNotScreensharing() {
    setIsAnyoneScreensharing(false);
    setIsLocalScreensharing(false);

    if (participants.length === 0) {
      setNotification(<>Waiting for participants...</>);
    } else {
      setNotification(null);
    }

    debouncedRecalculateVideoLayout();
  }

  function handleIsScreensharing() {
    setIsAnyoneScreensharing(true);
    if (detectIsLocalScreensharing({room})) {
      setIsLocalScreensharing(true);
      setNotification(<>You're presenting to everyone</>);
    }

    debouncedRecalculateVideoLayout();
  }

  function onVideoTracksStateChange() {
    if (detectIsAnyoneScreensharing({room})) {
      handleIsScreensharing();
      return;
    } else {
      handleNotScreensharing();
    }
  }

  const remoteParticipants = [
    ...participants,
    ...waitingParticipants,
  ].map(participant => (
    <ParticipantVideo
      patientId={patient.getOptional().property('id', 0)}
      key={participant.sid}
      participant={participant}
      showDocumentation={showDocumentation}
      isLocal={false}
      cutRoomAudio={cutRoomAudio}
      room={room}
      isAnyoneScreensharing={isAnyoneScreensharing}
      isMainScreenElement={false}
      onVideoTracksStateChange={onVideoTracksStateChange}
      isLocalScreensharing={isLocalScreensharing}
      setParticipants={setParticipants}
      setWaitingParticipants={setWaitingParticipants}
      hide={shouldNotTransmit}
      visitId={visitId}
      isLocalAudioMuted={isLocalAudioMuted}
      setIsLocalAudioMuted={setIsLocalAudioMuted}
      isLocalVideoDisabled={isLocalVideoDisabled}
      setIsLocalVideoDisabled={setIsLocalVideoDisabled}
    />
  ));

  const localParticipant = (
    <ParticipantVideo
      patientId={patient.getOptional().property('id', 0)}
      participant={room?.localParticipant}
      showDocumentation={showDocumentation}
      isLocal={true}
      cutRoomAudio={cutRoomAudio}
      room={room}
      isAnyoneScreensharing={isAnyoneScreensharing}
      isMainScreenElement={false}
      onVideoTracksStateChange={onVideoTracksStateChange}
      isLocalScreensharing={isLocalScreensharing}
      setParticipants={setParticipants}
      setWaitingParticipants={setWaitingParticipants}
      hide={shouldNotTransmit}
      visitId={visitId}
      isLocalAudioMuted={isLocalAudioMuted}
      setIsLocalAudioMuted={setIsLocalAudioMuted}
      isLocalVideoDisabled={isLocalVideoDisabled}
      setIsLocalVideoDisabled={setIsLocalVideoDisabled}
    />
  );

  function getMainScreenElement() {
    if (isAnyoneScreensharing && room) {
      return getScreensharingElement({
        visitId,
        room,
        isAnyoneScreensharing,
        cutRoomAudio,
        showDocumentation,
        onVideoTracksStateChange,
        isLocalScreensharing,
        setParticipants,
        setWaitingParticipants,
        hide: shouldNotTransmit,
        patientId: patient.getOptional().property('id', 0),
        isLocalAudioMuted,
        setIsLocalAudioMuted,
        isLocalVideoDisabled,
        setIsLocalVideoDisabled,
      });
    }
  }

  const renderSidebarVideoGallery = isAnyoneScreensharing;

  return (
    <div className={videoClasses.galleryContainer}>
      {renderSidebarVideoGallery && !isDisconnecting && (
        <>
          {shouldNotTransmit && (
            <div
              style={{width: calculateAvailableWidth()}}
              className={classes.factsDisplayContainer}
            >
              <FactsDisplay facts={waitingRoomMessages} />
            </div>
          )}
          <SidebarVideoGallery
            localParticipant={localParticipant}
            remoteParticipants={remoteParticipants}
            showDocumentation={showDocumentation}
            mainScreenNode={getMainScreenElement()}
            room={room}
          />
        </>
      )}

      {!renderSidebarVideoGallery && (
        <div id="gallery">
          {remoteParticipants.length <= 0 && (
            <div
              style={{width: calculateAvailableWidth()}}
              className={classes.factsDisplayContainer}
            >
              <FactsDisplay facts={waitingRoomMessages} />
            </div>
          )}

          {localParticipant}
          {remoteParticipants}
        </div>
      )}
    </div>
  );
}
