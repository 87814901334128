import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';
import {signupNewPatientForGuardian} from '../../../creators';
import {PatientDetailsValues} from '../../../SharedPatient/patientDetailsUtils';
import {maybeSetUpSelfRelationship} from './selfRelationship/maybeSetUpSelfRelationship';
import {Guardian, UserType, User, ProviderDetails} from '@src/models';
import {AppAPI} from '@src/api/AppAPI';
import {convertDetailsToRequest} from '@src/api/ProviderDetailsAPI';

type SaveForGuardianProps = {
  user: AsyncData<User>;
  patientDetails: PatientDetailsValues;
  providerDetails: Optional<ProviderDetails>;
  api: AppAPI;
};

export const saveForGuardian = async ({
  user,
  patientDetails,
  providerDetails,
  api,
}: SaveForGuardianProps) => {
  const userAsPerson = user
    .getOptional()
    .cast<Guardian>(u => u.userType === UserType.Guardian)
    .map(g => g.responsiblePersonDetails);

  const guardian = user
    .getOptional()
    .cast<Guardian>(() => true)
    .orElseThrow(() => new Error('Unexpected error saving Patient'));

  const patient = await signupNewPatientForGuardian(api)(
    {
      ...patientDetails,
      details: convertDetailsToRequest(providerDetails),
    },
    guardian.id,
    patientDetails.guardianRelationship,
  );

  await maybeSetUpSelfRelationship({
    userAsPerson,
    patientDetails,
    api,
    patient,
  });

  return patient;
};
