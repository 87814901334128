/**
 * Used for Medical History and Basic Details sections.
 */

import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {getColumn} from './tableHeaderUtils';
import {Patient} from '@src/models';

export function getDetailColumnHeadings({
  patientOne,
  patientTwo,
}: {
  patientOne: AsyncData<Patient>;
  patientTwo: AsyncData<Patient>;
}) {
  const fieldLabelHeading = getColumn({width: '10%'});

  const patientOneHeading = getColumn({
    width: '20%',
    content: patientOne
      .getOptional()
      .map(p => p.fullName)
      .orElse(''),
  });

  const patientTwoHeading = getColumn({
    width: '20%',
    content: patientTwo
      .getOptional()
      .map(p => p.fullName)
      .orElse(''),
  });

  const overrideHeading = getColumn({
    width: '25%',
    content: 'Override',
  });

  const resultHeading = getColumn({
    width: '20%',
    content: 'Result',
  });

  const resultIconHeading = getColumn({width: '5%'});

  return [
    fieldLabelHeading,
    patientOneHeading,
    patientTwoHeading,
    overrideHeading,
    resultHeading,
    resultIconHeading,
  ];
}
