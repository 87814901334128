export const enum Routes {
  Dashboard = '/dashboard',
  Documentation = '/documentation/:visitId',
  ExamRoom = '/exam-room/:visitId',
  SCP = '/my-scp/:patientId',
  CheckHairRoom = '/waiting-room/:visitId',
  StoredReports = '/stored-reports',
}

export const getLinkHref = (
  templateUri: Routes,
  replacements: Record<string, string | number>,
) => {
  return Object.keys(replacements).reduce((uri, key) => {
    const regex = new RegExp(`:${key}`, 'g');
    return uri.replace(regex, `${replacements[key]}`);
  }, templateUri);
};
