import {Optional} from '@ahanapediatrics/ahana-fp';
import {InputValue} from '@src/hooks';
import {Patient, PatientRelationship, LegalDocument} from '@src/models';
import {
  AudioOutputInfo,
  AudioInputInfo,
  VideoInputInfo,
} from '@src/store/reducers/media';
import {CallPoolRules, PaymentProcessorInfo} from '@src/models/CallPool';

export {AssignedFormsStep} from './AssignedFormsStep';
export {CreditCardStep} from './CreditCardStep';
export {ConsentStep} from './ConsentStep';
export {DetailsStep} from './DetailsStep';
export {ConfigStep} from './ConfigStep';
export {InsuranceStep} from './InsuranceStep';
export {ResponsiblePersonStep} from './ResponsiblePersonStep';
export {UseInsuranceStep} from './UseInsuranceStep';
export {UseCreditCardStep} from './UseCreditCardStep';

export type StepProps = {
  callPoolId: number;
  paymentProcessorInfo: PaymentProcessorInfo;
  primaryReason: InputValue<string>;
  fallbackNumber: InputValue<string>;
  location: InputValue<string>;
  dontUseInsurance: InputValue<null | boolean>;
  dontUseCreditCard: InputValue<null | boolean>;
  patient: Patient;
  rules: CallPoolRules;
  financialRelationship: Optional<PatientRelationship>;
  setNextAvailable: (a: boolean) => unknown;
  onUpdatePatient: () => Promise<unknown>;
  proceedToNextStep: () => unknown;
  requiredDocs: Optional<readonly LegalDocument[]>;
  unsignedDocs: Optional<readonly LegalDocument[]>;
  selectedAudioOutput: Optional<AudioOutputInfo>;
  selectedAudioInput: Optional<AudioInputInfo>;
  selectedVideoInput: Optional<VideoInputInfo>;
  visitId: number;
};
