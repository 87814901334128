import styled from 'styled-components';
import {Button} from '../../ui/form/Button';
import {ButtonRowContainer} from '../../ui/layout/ButtonContainers';
import {EditIcon} from '../../shared/PatientDashboard/InfoCard';
import {primary, xs, xxs} from '../../ui/theme';

export const SectionContent = styled.div`
  line-height: 1.5em;
`;

export const PencilIcon = styled(EditIcon)`
  color: ${primary};
  margin-top: -${xs};
`;

export const CoverageButton = styled(Button)``;

export const ButtonContainer = styled(ButtonRowContainer)`
  justify-content: flex-start;

  ${CoverageButton} {
    margin: ${xxs};
  }
`;
