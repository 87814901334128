import {Optional} from '@ahanapediatrics/ahana-fp';
import {mapObjectsToPropertyValues} from './mapObjectsToPropertyValues';

/**
 * Simple function to filter out objects in an array
 * whose prop values are identical.
 * @param array The array of objects.
 * @param prop The prop whose value pair we are checking for duplicate values.
 */
export function removeDuplicateObjsByProperty<T, K extends keyof T>(
  array: Optional<T>[],
  prop: K,
): Optional<T>[] {
  const arrayOfPropValues = mapObjectsToPropertyValues(array, prop);

  return array.filter((obj: Optional<T>, pos: number) => {
    return arrayOfPropValues.indexOf(obj.property(prop)) === pos;
  });
}
