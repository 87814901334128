import {AsyncData} from '@ahanapediatrics/ahana-fp';
import React, {ReactNode, useCallback} from 'react';
import {CancelSessionModal} from './CancelSessionModal';
import {DocumentationChoiceModal} from './DocumentationChoiceModal';
import {GuardianLeaveRoom} from './GuardianLeaveRoom';
import {ProviderEndVisitModal} from './ProviderEndVisitModal';
import {PresentSCPModal} from './PresentSCPModal';
import {LonelyVisit, VisitAction, VisitDocumentation} from '@src/models';
import {useApi} from '@src/api/useApi';
import {isNothing} from '@src/util/typeTests';

export type Modal =
  | 'none'
  | 'cancelVisitModal'
  | 'documentationChoiceModal'
  | 'endVisitModal'
  | 'nonProfessionalLeaveConfirmationModal'
  | 'presentSCP'
  | 'getHelp';

type Props = {
  documentation: AsyncData<VisitDocumentation>;
  exitToDocumentation: () => void;
  modal: Modal;
  onCancelPauseOrComplete: () => void;
  onLeavePermanently: () => void;
  onPlanToReturn: () => void;
  showModal: (m: Modal) => void;
  visit: AsyncData<LonelyVisit>;
};

export function ExamRoomModals({
  documentation,
  exitToDocumentation,
  modal,
  onCancelPauseOrComplete,
  onLeavePermanently,
  onPlanToReturn,
  showModal,
  visit,
}: Props) {
  const api = useApi();
  const visitId = visit.getOptional().property('id', 0);
  const documentationSigned = !isNothing(
    documentation.getOptional().property('signature'),
  );

  const onClickEndVisit = useCallback(async () => {
    if (documentationSigned) {
      await api
        .visit(visitId)
        .complete()
        .then(onLeavePermanently);
    } else {
      showModal('documentationChoiceModal');
    }
  }, [api, onLeavePermanently, showModal, visitId, documentationSigned]);

  if (!visit.isLoaded()) {
    return null;
  }

  // Displays four buttons:
  // Return to patient
  // End this visit
  // Leave this visit, but do not end it
  // Cancel this visit
  const endVisitModal = (
    <ProviderEndVisitModal
      showModal={true}
      returnToPatient={() => showModal('none')}
      onPlanToReturn={onPlanToReturn}
      onEndVisit={onClickEndVisit}
      onCancelVisit={() => showModal('cancelVisitModal')}
      documentationSigned={documentationSigned}
    />
  );

  const cancelVisitModal = (
    <CancelSessionModal
      showModal={true}
      returnToPatient={() => showModal('none')}
      onCancelVisit={reason =>
        api
          .visit(visitId)
          .cancel(reason)
          .then(onLeavePermanently)
      }
    />
  );

  const documentationChoiceModal = (
    <DocumentationChoiceModal
      showModal={true}
      onCompleteVisit={() =>
        api
          .visit(visitId)
          .complete()
          .then(onLeavePermanently)
      }
      onDocumentLater={() =>
        api
          .visit(visitId)
          .act(VisitAction.DOCUMENT)
          .then(onLeavePermanently)
      }
      onDocumentNow={() =>
        api
          .visit(visitId)
          .act(VisitAction.DOCUMENT)
          .then(exitToDocumentation)
      }
      returnToPatient={() => {
        onCancelPauseOrComplete();
        showModal('none');
      }}
      visit={visit.getOptional()}
    />
  );

  const nonProfessionalLeaveConfirmationModal = (
    <GuardianLeaveRoom
      showModal={true}
      onReturnToRoom={() => {
        onCancelPauseOrComplete();
        showModal('none');
      }}
      onPlanToReturn={onPlanToReturn}
      onLeavePermanently={onLeavePermanently}
    />
  );

  const presentSCP = (
    <PresentSCPModal showModal={true} setShowModal={showModal} />
  );

  const modals: Record<Modal, ReactNode> = {
    endVisitModal,
    nonProfessionalLeaveConfirmationModal,
    cancelVisitModal,
    documentationChoiceModal,
    presentSCP,
    getHelp: null,
    none: null,
  };

  return <>{modals[modal]}</>;
}
