import styled from 'styled-components';
import {ButtonRowContainer} from '../../ui/layout/ButtonContainers';
import {lg, mobileMax, primary, shadow, xs} from '../../ui/theme';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StepperContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  box-sizing: border-radius;
  @media (max-width: ${mobileMax}) {
    flex-direction: column;
    border-radius: 4px;
    padding: 2rem;
    background-color: white;
    box-shadow: ${shadow};
  }
`;

export const StepContainer = styled.div<{last: boolean}>`
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  @media (max-width: ${mobileMax}) {
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin: 0;
    margin-bottom: ${props => (props.last ? 0 : '1rem')};
  }
`;

export const LineContainer = styled.div`
  position: absolute;
  width: 100%;
`;

export const Line = styled.span`
  width: 100%;
  border-top: 1px solid black;
`;

export const Indicator = styled.div<{upcoming?: boolean}>`
  height: 30px;
  width: 30px;
  background-color: ${props => (props.upcoming ? '#a6a6a6' : primary)};
  border-radius: 50%;
  position: relative;

  @media (max-width: ${mobileMax}) {
    width: 32px;
  }
`;

export const StepNumber = styled.span`
  color: white;
  font-weight: 500;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StepName = styled.p`
  color: '#000000';
  text-align: center;
  font-weight: 500;
  margin-top: 0.5rem;
  font-size: 14px;

  @media (max-width: ${mobileMax}) {
    margin: 0;
    width: 100%;
    text-align: right;
  }
`;

export const BannerContainer = styled.div`
  margin-top: ${lg};
`;

export const MobileResponsiveButtonContainer = styled(ButtonRowContainer)`
  @media (max-width: ${mobileMax}) {
    align-items: center;
    flex-direction: column;

    button {
      margin: ${xs} 0;
    }
  }
`;
