import React from 'react';
import {useParams} from 'react-router';
import {useHistory} from 'react-router-dom';
import {PageLoading} from '../ui/atoms/progressBarsAndIndicators/PageLoading';
import {PrivatePage} from '../PrivatePage';
import {PatientOverview} from './PatientOverview';
import {flashWarn} from './notifications/flash';
import {useApi} from '@src/api/useApi';
import {LonelyPatient} from '@src/models';
import {useResources} from '@src/hooks';

// eslint-disable-next-line import/no-unused-modules
export default function PatientPage() {
  const api = useApi();
  const params = useParams<{patientId: string}>();
  const patientId = +params.patientId;
  const history = useHistory();

  const [patient, setPatient] = useResources<LonelyPatient>(
    () =>
      api
        .patient(patientId)
        .get()
        .catch(() => {
          flashWarn("Oops! We couldn't find that patient.");
          history.push('/dashboard');
          return [];
        }),
    [api, patientId, history, flashWarn],
  );

  return (
    <PrivatePage>
      <PageLoading
        active={!patient.isLoaded()}
        message="Loading patient information"
        fullSize
      >
        <>
          {patient.isLoaded() && (
            <PatientOverview
              patient={patient.singleValue()}
              updatePatient={setPatient}
            />
          )}
        </>
      </PageLoading>
    </PrivatePage>
  );
}
