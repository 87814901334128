import React, {useState} from 'react';
import {Grid, useTheme} from '@material-ui/core';
import {usePageContext} from '..';
import {LightHeading} from '@src/components/ui/layout/text/heading/LightHeading';
import {PdfViewer} from '@src/components/ui/organisms/PdfViewer';
import {ConfirmModal} from '@src/components/ui/layout/ConfirmModal';
import {AddableList} from '@src/components/ui/molecules/lists/AddableList';

export function Available() {
  const {
    editDocumentsModalResources: {
      addableSectionResources: {
        addableDocuments,
        onAddDocument,
        handleCancelAdd,
        documentIdToAdd,
        handleAddClicked,
      },
    },
  } = usePageContext();

  const aAddableDocuments = addableDocuments.getAllOptional().orElse([]);
  const mappable = aAddableDocuments.map(e => ({
    label: e.name,
    id: e.id,
    description: e.description,
  }));

  const theme = useTheme();

  const [documentToShowId, setDocumentToShowId] = useState<number | null>(null);

  return (
    <>
      <Grid item xs={12}>
        <LightHeading>Available</LightHeading>
      </Grid>
      <Grid item xs={12}>
        {documentToShowId !== null && (
          <PdfViewer
            document={
              aAddableDocuments.find(f => f.id === documentToShowId) ?? null
            }
            onClose={() => setDocumentToShowId(null)}
          />
        )}

        <AddableList
          mappable={mappable}
          emptyMessage="This Group currently has no Documents that can be added to it."
          secondaryActionProps={{
            handleActionClicked: setDocumentToShowId,
            iconColor: theme.palette.grey[500],
            icon: ['fas', 'eye'],
          }}
          handleAddClicked={handleAddClicked}
        />
      </Grid>

      <ConfirmModal
        onConfirm={onAddDocument}
        text="Confirming will add this Document to the Group."
        show={documentIdToAdd !== null}
        onHide={handleCancelAdd}
        onCancel={handleCancelAdd}
        cancelText="Cancel"
        confirmText="Add Document"
        titleText="Add Document"
      />
    </>
  );
}
