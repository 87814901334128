import React from 'react';
import {Button} from '../../ui/form';
import {Modal} from '../../ui/layout/Modal';

type Props = {
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onHide: () => void;
};

export function ConfirmSignModal({show, onConfirm, onCancel, onHide}: Props) {
  return (
    <Modal
      show={show}
      title="Are you sure?"
      modalActions={
        <>
          <Button
            bStyle={'primary'}
            onClick={() => {
              onHide();
              onConfirm();
            }}
            isInRow={true}
          >
            Confirm signing
          </Button>
          <Button
            onClick={() => {
              onHide();
              onCancel();
            }}
          >
            Cancel signing
          </Button>
        </>
      }
    >
      Once you sign this documentation, you will no longer be able to edit it.
      You will, however, be able to add addendums to the documentation, if
      necessary.
    </Modal>
  );
}
