export const reasons: Reason[] = [
  'visitNotFound',
  'roomFull',
  'visitLeft',
  'visitOver',
  'error',
  'deniedAdmission',
];

export type Reason =
  | 'visitNotFound'
  | 'roomFull'
  | 'visitLeft'
  | 'visitOver'
  | 'deniedAdmission'
  | 'error';

export const returnButtonReasons = ['roomFull', 'visitLeft', 'error'];

export type ReturnButtonReason = 'roomFull' | 'visitLeft' | 'error';
