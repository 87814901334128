import {useCallback} from 'react';
import {useApi} from '@src/api/useApi';
import {useRequestHandler} from '@src/hooks/useRequestHandler';
import {PayloadForProviderGroupDetails} from '@src/api/CallPoolAPI';

const useUpdateGroup = ({
  groupId,
  details,
}: {
  groupId: number;
  details: PayloadForProviderGroupDetails;
}) => {
  const api = useApi();

  return useCallback(async () => {
    return api.callPool(groupId).updateDetails(details);
  }, [api, details, groupId]);
};

export const useOnUpdate = ({
  asyncRunAfterRequest,
  details,
  groupId,
  runBeforeRequest,
  runFinally,
  runOnError,
}: {
  asyncRunAfterRequest: () => Promise<unknown>;
  details: PayloadForProviderGroupDetails;
  groupId: number;
  runBeforeRequest: () => void;
  runFinally: () => void;
  runOnError: () => void;
}) => {
  const update = useUpdateGroup({
    groupId,
    details,
  });

  return useRequestHandler({
    runBeforeRequest,
    asyncRequest: update,
    asyncRunAfterRequest,
    runFinally,
    runOnError,
  });
};
