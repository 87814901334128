import {withAuthenticationRequired} from '@auth0/auth0-react';
import React, {useEffect, useState} from 'react';
import {Redirect, Route, RouteComponentProps} from 'react-router-dom';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {useUser} from '@src/hooks/useUser';

type ProtectedComponent =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.ComponentType<RouteComponentProps<any>>;

type Props = {
  exact?: boolean;
  path: string | string[];
  component: ProtectedComponent;
  requiredPermission?: string;
  requiredUserType?: string | string[];
};

export function ProtectedRoute({
  component: ComponentToRender,
  requiredPermission = '',
  requiredUserType = '',
  path,
  exact,
  ...rest
}: Props) {
  const [, userType, , permissions] = useUser();
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);

  const AuthenticatedComponent = withAuthenticationRequired(ComponentToRender, {
    // Show a message while the user waits to be redirected to the login page.
    onRedirecting: () => (
      <PageLoading
        fullSize
        message="Redirecting you to the login page..."
        active={true}
      >
        <div style={{height: '100vh', width: '100vw'}} />
      </PageLoading>
    ),
  });

  const requiredTypes: string[] =
    requiredUserType.length > 0
      ? typeof requiredUserType === 'string'
        ? [requiredUserType]
        : requiredUserType
      : [];

  // We need these to create stable dependencies for the `useEffect`
  const typeList = requiredTypes.sort().join(':');
  const scopeList = permissions.sort().join(':');

  useEffect(() => {
    if (requiredPermission !== '') {
      setIsAuthorized(permissions.includes(requiredPermission));
    } else if (requiredTypes.length > 0) {
      setIsAuthorized(requiredTypes.includes(userType));
    } else {
      setIsAuthorized(true);
    }
    // We're using typeList and scopeList in lieu of the associated arrays
    // so it's OK to disabled ESLint on this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exact, path, requiredPermission, typeList, scopeList, userType]);

  if (isAuthorized === null) {
    return null;
  }

  if (isAuthorized) {
    return (
      <Route exact={exact} path={path} component={AuthenticatedComponent} />
    );
  }

  return <Redirect push to="/dashboard" />;
}
