import React, {useState} from 'react';
import {capitalize} from 'lodash';
import {Badge} from '@material-ui/core';
import {useHistory} from 'react-router';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {AssignProfessionalAction} from './AssignProfessionalAction';
import {CancelVisit} from './CancelVisit';
import {LonelyVisit, SimpleVisit} from '@src/models';
import {NAMES} from '@src/components/ui/layout/text/names';
import {
  ContextMenu,
  ContextMenuItem,
} from '@src/components/ui/molecules/menus/ContextMenu';

type Props = {
  onCancel: (v: SimpleVisit) => unknown;
  onShareLink: (visitId: number) => unknown;
  onUpdate: (v: LonelyVisit) => unknown;
  returnText?: string;
  visit: LonelyVisit;
};

export function ActionsContextMenu({
  onCancel,
  onShareLink,
  onUpdate,
  returnText,
  visit,
}: Props) {
  const [
    showAssignProfessionalModal,
    setShowAssignProfessionalModal,
  ] = useState(false);
  const [showCancelVisitModal, setShowCancelVisitModal] = useState(false);
  const history = useHistory();
  const showBadge = visit.assignedForms
    .orElse([])
    .some(v => !v.completedAt.isPresent());

  return (
    <>
      <Badge
        invisible={!showBadge}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        variant="dot"
        color="error"
      >
        <ContextMenu label={<MoreHorizIcon />} hAlign="right" vAlign="bottom">
          <ContextMenuItem onClick={() => onShareLink(visit.id)}>
            Get share link
          </ContextMenuItem>
          <ContextMenuItem onClick={() => setShowAssignProfessionalModal(true)}>
            Assign {NAMES.professional}
          </ContextMenuItem>
          <ContextMenuItem onClick={() => setShowCancelVisitModal(true)}>
            Cancel {NAMES.visit}
          </ContextMenuItem>
          <ContextMenuItem
            onClick={() => history.push(`/visits/${visit.id}/forms`)}
          >
            <Badge
              invisible={!showBadge}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              variant="dot"
              color="error"
            >
              Visit {capitalize(NAMES.document)}s
            </Badge>
          </ContextMenuItem>
        </ContextMenu>
      </Badge>

      <AssignProfessionalAction
        returnText={returnText}
        onUpdate={onUpdate}
        visit={visit}
        onClose={() => {
          setShowAssignProfessionalModal(false);
        }}
        showAssignModal={showAssignProfessionalModal}
      />
      <CancelVisit
        returnText={returnText}
        onCancel={onCancel}
        visit={visit}
        onClose={() => {
          setShowCancelVisitModal(false);
        }}
        showCancelModal={showCancelVisitModal}
      />
    </>
  );
}
