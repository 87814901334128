import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  tab: {
    fontWeight: 500,
    fontSize: 16,
    textTransform: 'none',
    color: theme.palette.primary.main,
    opacity: 1,

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },

    '&:before': {
      transition: '0.2s',
    },
    '&:not(:first-of-type)': {
      '&:before': {
        content: '" "',
        position: 'absolute',
        left: 0,
        display: 'block',
        height: 20,
        width: 1,
        zIndex: 1,
        backgroundColor: theme.palette.grey[300],
      },
    },
    '& + $selected:before': {
      opacity: 0,
    },
    '&:hover': {
      '&:not($selected)': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
      '&::before': {
        opacity: 0,
      },
      '& + $root:before': {
        opacity: 0,
      },
    },
  },
  root: {
    width: '100%',
    marginRight: '1rem',
    marginBottom: '2em',
    boxShadow: theme.shadows[2],
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    marginLeft: '1rem',
    marginTop: '2em',
  },
  hiddenTab: {
    display: 'none',
    '&:before': {
      transition: '0.2s',
    },
    '&:not(:first-of-type)': {
      '&:before': {
        content: '" "',
        position: 'absolute',
        left: 0,
        display: 'block',
        height: 20,
        width: 1,
        zIndex: 1,
        backgroundColor: theme.palette.grey[300],
      },
    },
    '& + $selected:before': {
      opacity: 0,
    },
    '&:hover': {
      '&:not($selected)': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
      '&::before': {
        opacity: 0,
      },
      '& + $root:before': {
        opacity: 0,
      },
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
    fontSize: 16,
    textTransform: 'none',

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },

    '& + $root': {
      zIndex: 1,
    },
    '& + $root:before': {
      opacity: 0,
    },
  },
  notificationBanner: {
    transform: 'scale(1) translate(90%, -55%)',
  },
}));
