import React from 'react';
import {List, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';
import {
  NamedIndividual,
  UserAvatar,
} from '@src/components/ui/layout/UserAvatar';

type Props = {
  namedIndividuals: {namedIndividual: NamedIndividual; description: string}[];
};

export function AvatarList({namedIndividuals}: Props) {
  return (
    <List>
      {namedIndividuals.map(p => {
        const {namedIndividual, description} = p;
        return (
          <ListItem
            style={{paddingLeft: 0, paddingTop: 0}}
            key={`${namedIndividual}${description}`}
          >
            {p && (
              <ListItemAvatar>
                <UserAvatar
                  style={{margin: 0}}
                  user={namedIndividual}
                  variant="small"
                />
              </ListItemAvatar>
            )}
            <ListItemText primary={description} />
          </ListItem>
        );
      })}
    </List>
  );
}
