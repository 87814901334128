import React, {useMemo, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {List} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {useStyles} from './layout';
import AssignedFormListItem from './AssignedFormListItem';
import {AssignedForm} from '@src/models';

interface AssignedFormListEditProps {
  forms: readonly AssignedForm[];
  onDelete: (formId: number) => unknown;
  showBadge: boolean;
}

export default function AssignedFormListEdit({
  forms,
  showBadge = false,
  onDelete,
}: AssignedFormListEditProps) {
  const classes = useStyles();

  const [sortAscending, setSortAscending] = useState<boolean>(false);

  const sortedForms = useMemo(
    () =>
      [...forms].sort((a, b) => (sortAscending ? a.id - b.id : b.id - a.id)),
    [forms, sortAscending],
  );

  const clickHandler = (e: React.MouseEvent) => {
    setSortAscending(!sortAscending);
    e.preventDefault();
  };

  return (
    <>
      <div className={classes.sortLink}>
        <Link onClick={e => clickHandler(e)} to={'#'}>
          <FontAwesomeIcon
            icon={['fas', 'sort']}
            fixedWidth
            size="sm"
            className={classes.actionItem}
          />{' '}
          Order {sortAscending ? 'Ascending' : 'Descending'}
        </Link>
      </div>
      <div>
        <List>
          {sortedForms.map(form => (
            <AssignedFormListItem
              key={form.id}
              form={form}
              showBadge={showBadge}
              onDelete={onDelete}
            />
          ))}
        </List>
      </div>
    </>
  );
}
