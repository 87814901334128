import React, {ReactNode} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {Mergeable} from '../../ExpandableSection/isMergeable/isSectionMergeable';
import {useStyles} from './styles';

const icons: Record<Mergeable, IconProp> = {
  CHECKING: ['fas', 'spinner'],
  PASS: ['fas', 'check'],
  RESOLVED: ['fas', 'check'],
  CONFLICT: ['fas', 'exclamation-triangle'],
  FAIL: ['fas', 'times-circle'],
};

export const MergeStateIcon = ({
  state,
  style,
}: {
  state: Mergeable;
  style: Object;
}): ReactNode => {
  const icon = icons[state];
  const classes = useStyles();

  const classNames = {
    PASS: classes.pass,
    RESOLVED: classes.pass,
    CONFLICT: classes.conflict,
    CHECKING: classes.checking,
    FAIL: classes.fail,
  };

  return (
    <FontAwesomeIcon
      style={style}
      icon={icon}
      spin={state === 'CHECKING'}
      size="2x"
      className={classNames[state]}
    />
  );
};
