import React from 'react';
import {
  AgeColumn,
  PatientColumn,
  WaitTimeColumn,
  ComplaintColumn,
  AttendingColumn,
  ProviderGroupColumn,
  TelemedicineEndpointColumn,
  ScheduledTimeColumn,
} from './columns';
import {LonelyVisit} from '@src/models';

export type ColumnOptions = {
  currentTime?: Date;
  guardian?: string;
};

export type VisitColumn = {
  id?: string;
  name: string;
  data: (visit: LonelyVisit, options?: ColumnOptions) => React.ReactNode;
};

export const OnCallColumns: VisitColumn[] = [
  PatientColumn,
  WaitTimeColumn,
  AgeColumn,
  ComplaintColumn,
  AttendingColumn,
  ProviderGroupColumn,
  TelemedicineEndpointColumn,
];

export const ScheduledColumns: VisitColumn[] = [
  PatientColumn,
  ScheduledTimeColumn,
  AgeColumn,
  ComplaintColumn,
  AttendingColumn,
  ProviderGroupColumn,
  TelemedicineEndpointColumn,
];

export const PreviousColumns: VisitColumn[] = [
  PatientColumn,
  ScheduledTimeColumn,
  AgeColumn,
  ComplaintColumn,
  AttendingColumn,
  ProviderGroupColumn,
  TelemedicineEndpointColumn,
];
