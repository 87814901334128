import {
  signupResponsiblePerson,
  linkPersonToPatient,
} from '../../../../creators';
import {ConnectedLogin} from '../../../stepFunctions';
import {LonelyPatient} from '@src/models';
import {AppAPI} from '@src/api/AppAPI';

export const signUpSelfAndLink = async ({
  api,
  selfConnectedLogin,
  patient,
}: {
  patient: LonelyPatient;
  selfConnectedLogin: ConnectedLogin;
  api: AppAPI;
}) => {
  const {firstName, lastName, dob, phone} = patient;

  signupResponsiblePerson(api)({
    firstName,
    lastName,
    dob,
    email: selfConnectedLogin.email,
    relationship: 'Self',
    phone,
  }).then(rp => {
    linkPersonToPatient(api)(rp.id, patient.id, {
      isSelf: true,
    });
  });
};
