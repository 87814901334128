import {useApi} from '@src/api/useApi';
import {Resources, useResources} from '@src/hooks';
import {CallPool} from '@src/models';
import {UserId} from '@src/models/User';

export const useManagedGroups = ({
  professionalId,
  includeVisitsUnsupported = false,
}: {
  professionalId: UserId;
  includeVisitsUnsupported?: boolean;
}): Resources<CallPool> => {
  const api = useApi();

  return useResources(
    () =>
      api
        .provider(professionalId)
        .getGroups({managedOnly: true, includeVisitsUnsupported}),
    [api, professionalId],
  );
};
