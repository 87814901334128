import React from 'react';
import {usePageContext} from '..';
import {SCPInfoBox} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox';
import {userCanEditProtectedField} from '@src/util/sharedCarePlan/permissions';
import {useUser} from '@src/hooks';

export function EmergencyCare() {
  const [user] = useUser();
  const {scp, selectedPanel} = usePageContext();
  const oScp = scp.getOptional();
  const canCurrentUserEdit = userCanEditProtectedField(
    user.getOptional(),
    oScp,
  );

  if (selectedPanel !== 'emergency') return null;

  return (
    <>
      <SCPInfoBox
        canEdit={canCurrentUserEdit}
        title="Summary"
        property="emergencySummary"
        propertyDescription={
          'Please provide 1-3 sentences that summarize the health complexity of the patient. This is typically language providers use to describe the patient at times of important handoffs.'
        }
      />
      <SCPInfoBox
        canEdit={canCurrentUserEdit}
        title="Active Diagnoses"
        property="diagnosis"
        propertyDescription={
          'Please list important active medical diagnosis that the patient has here.'
        }
      />
      <SCPInfoBox
        canEdit={canCurrentUserEdit}
        title="Acute Management Steps"
        property="acuteManagementSteps"
        propertyDescription={
          'Please provide important steps for a provider to consider in their early assessment and management of the patient.'
        }
      />
      <SCPInfoBox
        canEdit={canCurrentUserEdit}
        title="Labs / Imaging to consider"
        property="labsOrImaging"
        propertyDescription="Please include imaging or labs that need to be considered in the early evaluation of this patient in an emergency setting."
      />
      <SCPInfoBox
        canEdit={canCurrentUserEdit}
        title="Technology"
        property="technology"
        propertyDescription="Please provide important settings or information for technology dependent patients here. For example baseline ventilator settings or Gtube rate etc."
      />
    </>
  );
}
