import styled from 'styled-components';
import {lightGrey, sm, xxs} from '../ui/theme';

export const DashboardTable = styled.table`
  border-collapse: collapse;
  font-size: 0.8rem;
  width: 100%;

  tr th {
    padding-bottom: ${xxs};
  }

  tr td,
  tr th {
    vertical-align: middle;
  }

  thead {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: ${sm};
  }

  tbody {
    td:first-child {
    }

    tr:hover td:first-child {
    }

    tr:hover {
      background-color: ${lightGrey};
    }

    td {
      border-top: solid #000 1px;
      border-bottom: solid #000 1px;
      padding: ${sm} 0;
      text-align: center;
    }
  }
`;
