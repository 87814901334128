import styled from 'styled-components';
import {
  md,
  mobileMax,
  primary,
  primaryLight,
  sm,
  xs,
  xxs,
} from '@src/components/ui/theme';

export const DaysContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${md};

  @media screen and (max-width: ${mobileMax}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const DayInput = styled.div<{selected: boolean}>`
  background-color: ${props => (props.selected ? primaryLight : 'inherit')};
  border-color: ${primary};
  border-radius: ${xs};
  border-style: solid;
  border-width: ${xxs};
  cursor: pointer;
  padding: ${md};
  margin: ${sm};
  text-align: center;
  width: 4em;

  @media screen and (max-width: ${mobileMax}) {
    padding: ${sm};
    margin: ${xs};
    width: 90%;
  }
`;
