import React, {useState} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from '@material-ui/core';
import {useStyles} from '../../FinanciallyResponsibleComparer/styles';
import {StepperButtons} from '../../shared/Stepper/StepperButtons';
import {getStepContent, getSteps} from './stepperUtils';
import {Result} from './Result';
import {SelectPrimaryStep} from './SelectPrimaryStep';
import {SelectInsuranceStep} from './SelectInsuranceStep';
import {
  getEmptyMergeOptions,
  MergeOptions,
  PaymentInformationResult,
} from '@src/models/Patient';
import {isNothing} from '@src/util/typeTests';

type Props = {
  mergeOptions: AsyncData<MergeOptions>;
  paymentInformationResult: PaymentInformationResult;
  setPaymentInformationResult: (v: PaymentInformationResult) => unknown;
};

export function InsuranceStepper({
  mergeOptions,
  paymentInformationResult,
  setPaymentInformationResult,
}: Props) {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps();

  const {paymentInfos} = mergeOptions
    .getOptional()
    .orElse(getEmptyMergeOptions());

  const [checkboxesState, setCheckboxesState] = useState<
    Record<string, boolean>
  >(
    paymentInfos.reduce((acc, element) => {
      return {...acc, [`${element.id}`]: false};
    }, {}),
  );

  const isLastStep =
    !isNothing(paymentInformationResult.primary) &&
    activeStep > steps.length - 1;

  const aMergeOptions = mergeOptions
    .getOptional()
    .orElse(getEmptyMergeOptions());

  const selectionsLength = Object.values(checkboxesState).filter(v => v).length;
  const error = selectionsLength > 2 || selectionsLength < 1;

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((label, index) => {
        return (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              {activeStep === 0 && (
                <SelectInsuranceStep
                  mergeOptions={aMergeOptions}
                  checkboxesState={checkboxesState}
                  setCheckboxesState={setCheckboxesState}
                />
              )}

              {activeStep === 1 && (
                <SelectPrimaryStep
                  mergeOptions={aMergeOptions}
                  checkboxesState={checkboxesState}
                  setPaymentInformationResult={setPaymentInformationResult}
                  paymentInformationResult={paymentInformationResult}
                />
              )}

              <div className={classes.actionsContainer}>
                <StepperButtons
                  activeStep={activeStep}
                  steps={steps}
                  setActiveStep={setActiveStep}
                  disabled={activeStep === 0 && error}
                />
              </div>
            </StepContent>
          </Step>
        );
      })}

      {isLastStep && (
        <Result paymentInformationResult={paymentInformationResult} />
      )}

      {activeStep === steps.length + 1 && <div>Result</div>}
    </Stepper>
  );
}
