import React, {useEffect, useMemo, useState} from 'react';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {makeStyles} from '@material-ui/core';
import {FACTS} from './facts';

const useStyles = makeStyles(theme => ({
  content: {
    color: theme.palette.common.white,
    fontSize: 24,
    lineHeight: 2,
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
  fadeEnter: {
    opacity: 0,
    transform: 'translateX(100%)',
  },
  fadeEnterActive: {
    opacity: 1,
    transform: 'translateX(0%)',
    transition: 'opacity 500ms, transform 500ms',
  },
  fadeExit: {
    opacity: 1,
    transform: 'translateX(0%)',
  },
  fadeExitActive: {
    opacity: 0,
    transform: 'translateX(-100%)',
    transition: 'opacity 500ms, transform 500ms',
  },
}));

const FACT_CYCLE_TIME = 20_000;

interface Props {
  facts: Optional<string[]>;
}

export const FactsDisplay = ({facts}: Props) => {
  const classes = useStyles();
  const [factIndex, setFactIndex] = useState(0);
  const messages = useMemo(() => facts.orElse(FACTS), [facts]);

  useEffect(() => {
    const intervalId = window.setInterval(
      () => setFactIndex(i => (i + 1) % messages.length),
      FACT_CYCLE_TIME,
    );

    return () => {
      window.clearInterval(intervalId);
    };
  }, [messages]);

  return (
    <SwitchTransition>
      <CSSTransition
        key={factIndex}
        addEndListener={(node, done) =>
          node.addEventListener('transitionend', done, false)
        }
        classNames={{
          enter: classes.fadeEnter,
          enterActive: classes.fadeEnterActive,
          exit: classes.fadeExit,
          exitActive: classes.fadeExitActive,
        }}
      >
        <div className={classes.content}>{messages[factIndex]}</div>
      </CSSTransition>
    </SwitchTransition>
  );
};
