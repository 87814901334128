import {CallPool} from '@src/models';

export enum TableItem {
  DocumentInConnectedCare = 'Document in Connected Care',
  InsuranceInformation = 'Gather insurance information?',
  CreditCardInformation = 'Gather credit card information?',
  SupportVisits = 'Support visits?',
}

export function getTableItems(callPool: CallPool) {
  const tableItems: {name: string; data: () => string}[] = [
    {
      name: TableItem.DocumentInConnectedCare,
      data: () => (callPool.documentInAhana ? 'Yes' : 'No'),
    },
    {
      name: TableItem.InsuranceInformation,
      data: () => callPool.rules?.payments?.insurance ?? 'None',
    },
    {
      name: TableItem.CreditCardInformation,
      data: () => callPool.rules?.payments?.creditCard ?? 'None',
    },
    {
      name: TableItem.SupportVisits,
      data: () => (callPool.rules?.visitsUnsupported ? 'No' : 'Yes'),
    },
  ];

  return tableItems;
}
