import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 16,
    marginRight: 'auto',
    padding: 0,
  },
}));
