import React from 'react';
import {NavigationSkeleton} from './NavigationSkeleton';
import {PermissionsProps} from '.';
import {UserType} from '@src/models';

type Props = {
  setShowLogoutModal?: (v: boolean) => unknown;
  onLogout?: () => unknown;
  userType: UserType;
  permissions: PermissionsProps;
};

export function TabSkeleton({
  onLogout,
  setShowLogoutModal,
  userType,
  permissions,
}: Props) {
  return (
    <NavigationSkeleton
      type="horizontal"
      onLogout={onLogout}
      setShowLogoutModal={setShowLogoutModal}
      userType={userType}
      permissions={permissions}
    />
  );
}
