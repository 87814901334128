import {Optional} from '@ahanapediatrics/ahana-fp';
import {Badge, Grid, Typography} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import AlarmIcon from '@material-ui/icons/Alarm';
import {addDays, format} from 'date-fns';
import React, {useState} from 'react';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/styles';
import {ReapproveModal} from '@src/components/shared/PatientDashboard/SCP/panels/controls/OwnerControls/Review/ReapproveModal';
import {UpdateReviewDate} from '@src/components/shared/PatientDashboard/SCP/panels/controls/OwnerControls/Review/UpdateReviewDate';
import {Button} from '@src/components/ui/form/Button';
import {SCPHeading} from '@src/components/shared/PatientDashboard/SCP/SCPHeading';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {SCPStatus} from '@src/models';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';

export function Review() {
  const {scp} = usePageContext();

  const oScp = scp.getOptional();

  const currentExpirationDate = oScp.map(m => m.approvalExpiration).orNull();

  const [expirationDate, setExpirationDate] = useState<Optional<Date>>(
    Optional.of(currentExpirationDate),
  );

  const [editExpirationDate, setEditExpirationDate] = useState(false);
  const [showReapproveModal, setShowReapproveModal] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const status = oScp.map(m => m.status).orNothing();

  return (
    <Grid className={classes.rowContainer} container direction="row">
      <Grid
        item
        xs={12}
        className={classes.gridItem}
        style={{marginBottom: '1rem'}}
      >
        <SCPHeading>Plan Review Date</SCPHeading>
        <Typography variant="caption" style={{fontStyle: 'italic'}}>
          As a part of our goal to keep this plan's information accurate and
          updated, we will send you regular reminders to review the information
          and approve it.
        </Typography>
      </Grid>
      {currentExpirationDate && (
        <Grid
          item
          xs={12}
          className={classes.gridItem}
          style={{marginBottom: '1rem'}}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <AlarmIcon
              style={{
                color:
                  status === SCPStatus.ApprovalExpired
                    ? theme.palette.warning.main
                    : theme.palette.primary.main,
              }}
            />
            <ParagraphText
              style={{
                marginLeft: '0.5rem',
                color: theme.palette.primary.main,
                fontWeight: 500,
                marginBottom: 0,
              }}
            >
              {status === SCPStatus.ApprovalExpired
                ? 'Review date:'
                : 'Next review date:'}
            </ParagraphText>
            <ParagraphText
              style={{
                marginBottom: 0,
                marginLeft: '0.5rem',
              }}
            >
              {status === SCPStatus.ApprovalExpired
                ? 'Due now!'
                : `${format(currentExpirationDate, 'MM/dd/yyyy')}`}
            </ParagraphText>
          </div>
        </Grid>
      )}

      {editExpirationDate && (
        <>
          <UpdateReviewDate
            setShow={setEditExpirationDate}
            setExpirationDate={setExpirationDate}
            expirationDate={expirationDate}
          />
        </>
      )}

      {!editExpirationDate && status !== SCPStatus.ApprovalExpired && (
        <>
          <Button
            bSize="small"
            bStyle="secondary"
            onClick={() => setEditExpirationDate(true)}
            style={{marginBottom: '1em'}}
          >
            Change Reminder Date
          </Button>
        </>
      )}

      {!editExpirationDate && status === SCPStatus.ApprovalExpired && (
        <>
          <Button
            bSize="small"
            bStyle="secondary"
            onClick={() => {
              setShowReapproveModal(true);
              setExpirationDate(Optional.of(addDays(new Date(), 90)));
            }}
            style={{marginBottom: '1em'}}
          >
            Review Plan Now
          </Button>
          <Badge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            variant="dot"
            color="error"
          />
          <ReapproveModal
            show={showReapproveModal}
            setShow={setShowReapproveModal}
            expirationDate={expirationDate}
            setExpirationDate={setExpirationDate}
          />
        </>
      )}
    </Grid>
  );
}
