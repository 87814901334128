import {Optional} from '@ahanapediatrics/ahana-fp';
import {ProviderDetails} from '@src/models';
import {FormMode} from '@src/util/provider/forms/addOrEdit';

type ChangeInputModeProps = {
  setShowDetails: (v: boolean) => void;
  setEntryMode: (v: 'search' | 'type') => void;
  onChange: (pcpInfo: Optional<ProviderDetails>, type: FormMode) => void;
  setNoProviderSelected: (v: boolean) => void;
  setNoSCPOwner?: () => void;
};

export const handleStartSearch = ({
  setShowDetails,
  setEntryMode,
  onChange,
  setNoProviderSelected,
}: ChangeInputModeProps) => {
  setShowDetails(false);
  setEntryMode('search');
  onChange(Optional.empty(), FormMode.ProviderSearch);
  setNoProviderSelected(false);
};

export const handleEnterManually = ({
  setShowDetails,
  setEntryMode,
  onChange,
  setNoProviderSelected,
}: ChangeInputModeProps) => {
  setShowDetails(true);
  setEntryMode('type');
  onChange(Optional.empty(), FormMode.ManualEntry);
  setNoProviderSelected(false);
};

export const handleNoProviderSelected = ({
  setShowDetails,
  setEntryMode,
  onChange,
  setNoProviderSelected,
  setNoSCPOwner,
}: ChangeInputModeProps) => {
  setShowDetails(false);
  setEntryMode('type');
  onChange(Optional.empty(), FormMode.NoProviderSelected);
  setNoProviderSelected(true);
  if (setNoSCPOwner) {
    setNoSCPOwner();
  }
};
