import {format} from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import {error, lg, md, errorText} from '@src/components/ui/theme';
import {maintenanceMode} from '@src/components/ui/theme/z-indexes';
import ConfigService from '@src/ConfigService';

const StyledMaintenanceModeHeader = styled.div`
  background-color: ${error};
  color: ${errorText};
  top: ${md};
  height: ${lg};
  line-height: 1.5em;
  font-size: 1em;
  font-style: italic;
  pointer-events: none;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: ${maintenanceMode};
`;

export const MaintenanceModeHeader: React.FunctionComponent = () => {
  const releaseInfo = ConfigService.getReleaseInfo();
  const {maintenanceModeStart} = releaseInfo;
  if (!maintenanceModeStart) {
    return null;
  }
  return (
    <StyledMaintenanceModeHeader>
      Maintenance Mode begins at {format(maintenanceModeStart, 'hh:mm a')}.
      Please complete any tasks and log off.
    </StyledMaintenanceModeHeader>
  );
};
