import React, {ReactNode} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {Grid, Typography} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStyles as useGeneralStyles} from '../styles';
import {useStyles as selectOneStyles} from './selectOneStyles';
import {Patient} from '@src/models';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';
import {Button} from '@src/components/ui/form';

type Props = {
  patientOne: AsyncData<Patient>;
  patientTwo: AsyncData<Patient>;
  instructions: ReactNode[];
  optionOneSelected: boolean;
  optionTwoSelected: boolean;
  onSelectOptionOne: () => unknown;
  onSelectOptionTwo: () => unknown;
  cards: {
    optionOneCard: ReactNode;
    optionTwoCard: ReactNode;
  };
};

export function SelectOne({
  patientOne,
  patientTwo,
  instructions,
  optionTwoSelected,
  optionOneSelected,
  onSelectOptionOne,
  onSelectOptionTwo,
  cards,
}: Props) {
  const classes = {...useGeneralStyles(), ...selectOneStyles()};

  const {optionOneCard, optionTwoCard} = cards;

  return (
    <Grid className={classes.comparerMessage} container direction="column">
      {instructions.length > 0 && <Grid item>{instructions}</Grid>}

      <Grid
        container
        direction="row"
        spacing={8}
        className={classes.comparerMessage}
      >
        <Grid item xs={5}>
          <Grid container justify="center" direction="row">
            <Typography color="primary" variant="h6">
              <Bold>
                {patientOne
                  .getOptional()
                  .map(p => p.fullName)
                  .orElse('')}
                <Typography variant="subtitle1" className={classes.subtitle}>
                  (Record 1)
                </Typography>
              </Bold>
              {optionOneSelected && (
                <FontAwesomeIcon
                  icon={['fas', 'check-circle']}
                  className={classes.checkIcon}
                />
              )}
            </Typography>
          </Grid>

          {optionOneCard}
          <Grid container justify="center" direction="row">
            <Grid item xs={6}>
              <Button
                fullWidth
                bSize="small"
                disabled={optionOneSelected}
                bStyle="primary"
                onClick={onSelectOptionOne}
              >
                Select
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container justify="center" direction="row">
            <Typography color="primary" variant="h6">
              <Bold>
                {patientTwo
                  .getOptional()
                  .map(p => p.fullName)
                  .orElse('')}
              </Bold>
              {optionTwoSelected && (
                <FontAwesomeIcon
                  icon={['fas', 'check-circle']}
                  className={classes.checkIcon}
                />
              )}
            </Typography>
          </Grid>
          {optionTwoCard}
          <Grid container justify="center" direction="row">
            <Grid item xs={6}>
              <Button
                fullWidth
                bSize="small"
                disabled={optionTwoSelected}
                bStyle="primary"
                onClick={onSelectOptionTwo}
              >
                Select
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
