import React from 'react';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';

type Props = {
  disabled?: boolean;
  isLastStep: boolean;
  proceedToNextStep: () => unknown;
  saving: boolean;
};
export function StandardNext({
  disabled = false,
  isLastStep,
  proceedToNextStep,
  saving,
}: Props) {
  return (
    <MuiAsyncActionButton
      disabled={disabled}
      actionInProgress={saving}
      bStyle="primary"
      onClick={proceedToNextStep}
      actionWord={isLastStep ? 'Confirm' : 'Next'}
      actionInProgressWord="Processing"
    />
  );
}
