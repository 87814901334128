import {format} from 'date-fns';
import {Optional} from '@ahanapediatrics/ahana-fp';
import * as React from 'react';
import clsx from 'clsx';
import {
  codeToLabel,
  ExamCode,
  SystemCode,
} from '../VisitDocumentationForm/sections/systems';
import {useStyles, UIDInfo} from './layout';
import {UserId} from '@src/models/User';
import {
  Addendum,
  ImmunizationStatus,
  LonelyPatient,
  LonelyVisit,
  VisitDocumentation,
} from '@src/models';
import {providerAndQualification} from '@src/util/provider/providerDescription';
import {fullName} from '@src/util/users/getDemographics';
import {byId} from '@src/util/sorters/byId';
import {useApi} from '@src/api/useApi';
import {isNothing} from '@src/util/typeTests';
import {useResources} from '@src/hooks';

const PLACEHOLDER = '-';

type Props = {
  visit: LonelyVisit;
  docs: Optional<VisitDocumentation>;
  patient: LonelyPatient;
};

interface DocumentationItem {
  label: string;
  details?: string;
}

interface RenderableSystem {
  code: ExamCode | SystemCode;
  remarks: string;
}

export function VisitDocumentationView({visit, patient, docs}: Props) {
  const classes = useStyles();
  const api = useApi();
  const documentation = docs.orNull();
  const signature = docs.map(d => d.signature);
  const signerId = signature.map(s => s.signer).property('id', 0 as UserId);

  const [signerDemographics] = useResources(
    () => api.user(signerId).getProviderDetails(),
    [api, signerId],
    {requestGate: () => signerId !== 0},
  );

  if (isNothing(documentation)) {
    return null;
  }

  const renderSystems = (items: RenderableSystem[]) =>
    items.map((item: RenderableSystem) => (
      <div key={item.code} className={classes.item}>
        <span className={classes.label}>{codeToLabel(item.code)}: </span>
        {item.remarks}
      </div>
    ));

  const renderItems = (items: DocumentationItem[]) =>
    items.map(item => (
      <div key={item.label} className={classes.item}>
        <span className={classes.label}>{item.label}: </span>
        {item.details || PLACEHOLDER}
      </div>
    ));

  const renderAddendum = (addendum: Addendum) => (
    <div key={addendum.id} className={classes.addendum}>
      <div className={classes.addendumAuthor}>
        {fullName(addendum.author)} at {format(addendum.createdAt, 'PP p')}
      </div>
      <div dangerouslySetInnerHTML={{__html: addendum.content}} />
    </div>
  );

  const {addendums} = documentation;

  return (
    <div className="visit-documentation">
      {signerDemographics.isLoaded() && (
        <div className={classes.overallTitle}>
          {patient.preferredName} - {format(visit.start, 'PP p')} with{' '}
          {visit.providerDetails.property('fullName', '')}
        </div>
      )}
      {!signerDemographics.isLoaded() && (
        <div className={classes.overallTitle}>
          {patient.preferredName} - This documentation has not yet been signed
        </div>
      )}
      <UIDInfo>
        Refyne Connected Care Visit UID: {visit.uid ?? visit.id}
      </UIDInfo>
      <UIDInfo>
        Refyne Connected Care Patient UID:{' '}
        {visit.patient.uid ?? visit.patient.id}
      </UIDInfo>

      {documentation.red_flag && (
        <div className={classes.section}>
          <div className={clsx(classes.title, 'red-flag')}>
            The Provider added a red flag to this note
          </div>
          <div className={classes.item}>{documentation.red_flag_text}</div>
        </div>
      )}
      <div className={classes.section}>
        <div className={classes.title}>Visit Physician</div>
        <div className={classes.item}>
          {visit.providerDetails
            .map(providerAndQualification)
            .orElse('No Provider info available')}
        </div>
        <div className={classes.item}>
          {visit.providerDetails
            .flatMap(d => d.practice)
            .property('name', 'No Practice info available')}
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.title}>Reason for visit</div>
        {renderItems([
          {
            label: 'Chief Complaint',
            details: documentation.chief_complaint,
          },
          {
            label: 'History of present illness',
            details: documentation.hpi,
          },
        ])}
      </div>
      <div className={classes.section}>
        <div className={classes.title}>Review of systems</div>
        {renderSystems(documentation.reviewOfSystems)}
      </div>
      <div className={classes.section}>
        <div className={classes.title}>History</div>
        {renderItems([
          {
            label: 'Past or on-going health issues',
            details: documentation.past_health_issues,
          },
          {
            label: 'Past surgeries',
            details: documentation.past_surgeries,
          },
          {
            label: 'Family medical history',
            details: documentation.family_history,
          },
          {label: 'Other information', details: documentation.other},
        ])}
      </div>
      <div className={classes.item}>
        <div className={classes.title}>
          Medications, Allergies and Immunizations
        </div>
        {renderItems([
          {label: 'Medications', details: documentation.medications},
          {
            label: 'Patient allergies',
            details: documentation.allergies,
          },
        ])}
        <div className={classes.item}>
          <span className={classes.label}>Immunization status: </span>
          {documentation.immunizations_up_to_date === ImmunizationStatus.Yes &&
            'Up to date'}
          {documentation.immunizations_up_to_date === ImmunizationStatus.No &&
            'Out of date'}
          {documentation.immunizations_up_to_date ===
            ImmunizationStatus.NotSure && 'Unknown'}
        </div>
        {documentation.immunizations_up_to_date === ImmunizationStatus.No && (
          <div className={classes.item}>
            <span className={classes.label}>Reason: </span>
            {documentation.immunization_details || PLACEHOLDER}
          </div>
        )}
      </div>
      <div className={classes.section}>
        <div className={classes.title}>Exam</div>
        {renderSystems(documentation.exams)}
      </div>
      <div className={classes.section}>
        <div className={classes.title}>Assessment</div>
        <div className={classes.item}>
          <span className={classes.label}>General: </span>
          {documentation.general || PLACEHOLDER}
        </div>
        <div className={classes.item}>
          <ul>
            {(documentation.icd10_codes || []).map(idc10Code => (
              <li key={idc10Code.code}>{idc10Code.description}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.title}>Plan</div>
        {renderItems([
          {
            label: 'Medications to continue',
            details: documentation.plan_medications_continue,
          },
          {
            label: 'Medications to start',
            details: documentation.plan_medications_start,
          },
          {
            label: 'What to do now',
            details: documentation.plan_what_now,
          },
        ])}
        <div className={classes.item}>
          <span className={classes.label}>When to follow up: </span>
          {documentation.plan_follow_up !== 'Other'
            ? documentation.plan_follow_up
            : documentation.plan_followup_other}
        </div>
      </div>
      {addendums.isPresent() && addendums.get().length > 0 && (
        <div className={classes.addendums}>
          <div className={classes.addendumsTitle}>Addendums</div>
          {addendums
            .get()
            .sort(byId)
            .map(renderAddendum)}
        </div>
      )}
      <hr />
      {signature.isPresent() && (
        <div className={classes.signature}>
          Signed by{' '}
          {signerDemographics
            .getOptional()
            .map(providerAndQualification)
            .orElse('Loading...')}{' '}
          on {format(new Date(signature.get().signingDate), 'PP p')}
        </div>
      )}
      {!signature.isPresent() && (
        <div className={classes.signature}>
          This visit documentation has not yet been signed.
        </div>
      )}
    </div>
  );
}
