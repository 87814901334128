import {Grid, GridJustification} from '@material-ui/core';
import React from 'react';
import {useHistory} from 'react-router';
import {useStyles} from './layout';
import {useApi} from '@src/api/useApi';
import FormNotificationModal from '@src/components/guardianSide/FormNotificationModal';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';
import {ApplicationEvents, sendEvent} from '@src/events';
import {useResources, useUser} from '@src/hooks';
import {AssignedForm, Guardian, UserType} from '@src/models';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

type MenuOption = 'Profile' | 'Logout';
type Props = {
  justify: GridJustification;
  setShowLogoutModal?: (v: boolean) => unknown;
  onLogout?: () => unknown;
  onClick?: (options: MenuOption) => unknown;
};

export function MenuIcons({
  justify,
  onClick = () => {},
  onLogout,
  setShowLogoutModal,
}: Props) {
  const [user, userType] = useUser();
  const history = useHistory();
  const classes = useStyles();
  const api = useApi();

  const personId = user
    .getOptional()
    .cast<Guardian>(u => u.userType === UserType.Guardian)
    .map(g => g.responsiblePersonDetails)
    .property('id', 0 as NonProfessionalId);

  const [incompleteForms] = useResources<AssignedForm>(
    async () => await api.responsiblePerson(personId).getIncompleteForms(),
    [api, personId],
    {requestGate: () => personId !== 0},
  );

  return (
    <Grid className={classes.icons} container direction="row" justify={justify}>
      <Grid item className={classes.icon}>
        <FormNotificationModal
          size="2x"
          forms={incompleteForms.getAllOptional().orElse([])}
        />
      </Grid>
      <Grid item className={classes.icon}>
        {userType !== UserType.Admin && (
          <ClickableIcon
            size="2x"
            icon={['far', 'user-circle']}
            onClick={() => {
              onClick('Profile');
              sendEvent(ApplicationEvents.MenuItemClicked);
              history.push(
                `/${
                  userType === UserType.Guardian ? 'guardian' : 'provider'
                }-info`,
              );
            }}
          />
        )}
      </Grid>
      {(setShowLogoutModal || onLogout) && (
        <Grid item className={classes.icon}>
          <ClickableIcon
            size="2x"
            icon={['fas', 'sign-out']}
            onClick={() => {
              onClick('Logout');
              sendEvent(ApplicationEvents.MenuItemClicked);
              if (setShowLogoutModal) {
                setShowLogoutModal(true);
              } else if (onLogout) {
                onLogout();
              }
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}
