import React, {ReactNode} from 'react';
import {makeStyles, createStyles, Theme} from '@material-ui/core';
import {ParagraphText} from './ParagraphText';

type ParagraphTextVariant = 'body1' | 'body2';
type WarningVariant = 'warning' | 'error';

type Props = {
  children: ReactNode | null;
  style?: Object;
  hidden?: boolean;
  className?: string;
  variant?: ParagraphTextVariant;
  warningVariant: WarningVariant;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontWeight: 700,
      fontSize: 18,
    },
    warning: {
      color: theme.palette.warning.main,
    },
    error: {
      color: theme.palette.error.main,
    },
  }),
);

export function WarningText({
  children,
  style,
  hidden,
  variant,
  warningVariant,
}: Props) {
  const classes = useStyles();

  if (hidden) return null;

  return (
    <ParagraphText
      hidden={hidden}
      className={`${classes.text} ${classes[warningVariant]}`}
      style={style}
      variant={variant}
    >
      {children}
    </ParagraphText>
  );
}
