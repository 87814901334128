import {Optional} from '@ahanapediatrics/ahana-fp';
import {linkPersonToPatient} from '../../../../creators';
import {PatientDetailsValues} from '../../../../SharedPatient/patientDetailsUtils';
import {ResponsiblePerson, LonelyPatient} from '@src/models';
import {AppAPI} from '@src/api/AppAPI';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

type MaybeSetUpSelfRelationship = {
  userAsPerson: Optional<ResponsiblePerson>;
  patientDetails: PatientDetailsValues;
  patient: LonelyPatient;
  api: AppAPI;
};

export const maybeSetUpSelfRelationship = async ({
  userAsPerson,
  patientDetails,
  api,
  patient,
}: MaybeSetUpSelfRelationship) => {
  if (patientDetails.isSelf) {
    await linkPersonToPatient(api)(
      userAsPerson.property('id', 0 as NonProfessionalId),
      patient.id,
      {
        isSelf: true,
      },
    );
  }
};
