import React, {useState, useCallback} from 'react';
import {VisitActionButton} from '../layout';
import {SimpleVisit, LonelyVisit} from '@src/models';
import {useApi} from '@src/api/useApi';

type ReloadProps = {
  onUpdate: (v: LonelyVisit) => unknown;
  visit: SimpleVisit;
};
export function ReloadVisitAction({onUpdate, visit}: ReloadProps) {
  const api = useApi();
  const [visitsReloading, setVisitsReloading] = useState<{
    [id: number]: boolean;
  }>({});

  const reloadVisit = useCallback(() => {
    return api
      .visit(visit.id)
      .get()
      .then(onUpdate);
  }, [api, onUpdate, visit]);

  return (
    <VisitActionButton
      disabled={visitsReloading[visit.id]}
      icon="sync"
      spin={visitsReloading[visit.id]}
      onClick={() => {
        setVisitsReloading({
          ...visitsReloading,
          [visit.id]: true,
        });
        reloadVisit().finally(() => {
          setVisitsReloading({
            ...visitsReloading,
            [visit.id]: false,
          });
        });
      }}
    />
  );
}
