import styled from 'styled-components';
import {h2, lg, md, mobileMax, sm, xl, xs} from '../theme';
import {Instructions} from '.';

export const RequestBox = styled.div`
  background-color: #ffffff;
  border-radius: ${xs};
  box-shadow: 0 3px 10px rgba(20, 20, 20, 0.08);
  padding: 0 ${xl} ${xl};
  margin: ${lg} auto;
  width: 75%;

  @media (max-width: ${mobileMax}) {
    padding: 0 ${md} ${sm};
    margin: ${lg} auto;
    width: 95%;
  }
`;

export const RequestHeader = styled.h2`
  font-size: ${h2};
  font-weight: bold;
  padding-top: ${md};
  margin-bottom: ${sm};
`;

export const RequestInstructions = styled(Instructions)`
  align-items: flex-start;
  font-size: 1.1em;
  padding-top: ${md};
`;
