import {Optional} from '@ahanapediatrics/ahana-fp';
import {
  AssignedFormsStep,
  ConfigStep,
  ConsentStep,
  CreditCardStep,
  DetailsStep,
  InsuranceStep,
  ResponsiblePersonStep,
  UseInsuranceStep,
  StepProps,
  UseCreditCardStep,
} from './steps';
import {AssignedForm, CallPool, LegalDocument, Patient} from '@src/models';
import {InputValue} from '@src/hooks';

type NewVisitStep =
  | 'AssignedForms'
  | 'ConsentForms'
  | 'CreditCard'
  | 'Details'
  | 'Insurance'
  | 'ResponsiblePerson'
  | 'UseCreditCard'
  | 'UseInsurance'
  | 'VideoAndAudioPrep';

/**
 * This maps a step type to its name
 */
export const StepNames: Record<NewVisitStep, string> = {
  AssignedForms: 'Assigned Forms',
  ConsentForms: 'Consent Forms',
  CreditCard: 'Credit Card',
  Details: 'Details',
  Insurance: 'Insurance',
  ResponsiblePerson: 'Responsible Person',
  UseCreditCard: 'Use Credit Card?',
  UseInsurance: 'Use Insurance?',
  VideoAndAudioPrep: 'Video and Audio Prep',
};

/**
 * This is guaranteed to be a valid component
 */
type StepComponent = (props: StepProps) => JSX.Element | null;

/**
 * This maps a step type to its component
 */
export const StepComponents: Record<NewVisitStep, StepComponent> = {
  AssignedForms: AssignedFormsStep,
  Details: DetailsStep,
  ConsentForms: ConsentStep,
  ResponsiblePerson: ResponsiblePersonStep,
  UseInsurance: UseInsuranceStep,
  UseCreditCard: UseCreditCardStep,
  Insurance: InsuranceStep,
  CreditCard: CreditCardStep,
  VideoAndAudioPrep: ConfigStep,
};

export const buildFlow = (options: {
  assignedForms: readonly AssignedForm[];
  pool: CallPool;
  requiredDocs: readonly LegalDocument[];
  patient: Optional<Patient>;
  isNewVisit: boolean;
  dontUseInsurance: InputValue<boolean | null>;
  dontUseCreditCard: InputValue<boolean | null>;
  visitId: number;
}): NewVisitStep[] => {
  const {
    assignedForms,
    dontUseCreditCard,
    dontUseInsurance,
    isNewVisit,
    patient,
    pool,
    requiredDocs,
    visitId,
  } = options;

  const {rules} = pool;
  const {payments} = rules;
  const {insurance, creditCard} = payments ?? {
    insurance: 'None',
    creditCard: 'None',
  };

  const hasIncompleteFormsForVisit =
    assignedForms.filter(
      form =>
        !form.completedAt.isPresent() &&
        form.visitId.map(id => id === visitId).orElse(false),
    ).length > 0;

  const hasMedicaid = patient
    .map(p => p.paymentInformation)
    .map(pi => pi.hasMedicaid)
    .orElse(false);

  const stepPlan: NewVisitStep[] = [];

  if (isNewVisit) {
    stepPlan.push('Details');
  }

  if (requiredDocs.length > 0) {
    stepPlan.push('ConsentForms');
  }

  if (hasIncompleteFormsForVisit) {
    stepPlan.push('AssignedForms');
  }

  stepPlan.push('ResponsiblePerson');

  if (insurance === 'Required') {
    stepPlan.push('Insurance');
  }

  if (insurance === 'Optional') {
    stepPlan.push('UseInsurance');

    if (dontUseInsurance.value === false) {
      stepPlan.push('Insurance');
    }
  }

  // If someone has medicaid but does NOT want to use their insurance, then
  // we ask for credit card info.
  if (!hasMedicaid || dontUseInsurance.value) {
    if (creditCard === 'Required') {
      stepPlan.push('CreditCard');
    }

    if (creditCard === 'Optional') {
      stepPlan.push('UseCreditCard');
      if (creditCard === 'Optional' && dontUseCreditCard.value === false) {
        stepPlan.push('CreditCard');
      }
    }
  }

  stepPlan.push('VideoAndAudioPrep');

  return stepPlan;
};
