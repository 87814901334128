import {makeNonOptional, Optional} from '@ahanapediatrics/ahana-fp';
import React, {useCallback, useEffect, useReducer, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {AudioInConfigSection} from './AudioInConfigSection';
import {AudioOutConfigSection, AudioSampleState} from './AudioOutConfigSection';
import {bell} from './bell';
import {
  deviceReducer,
  loadDevices,
  returnToSafety,
  updateAudioOutputDevice,
  updateMediaFailure,
  useEventHandler,
  _updateDevice,
} from './functions';
import {SessionConfigModalContents, StyledSessionConfigForm} from './layout';
import {MediaDeviceState, useDevices} from './MediaDeviceManager';
import {NoAccessToDevicesModal} from './NoAccessToDevicesModal';
import {VideoConfigSection} from './VideoConfigSection';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {Banner} from '@src/components/ui/layout/Banner';
import {MediaFailure} from '@src/util/videoChat';
import {
  AudioInputInfo,
  AudioOutputInfo,
  VideoInputInfo,
} from '@src/store/reducers/media';
import * as actions from '@src/store/actions';
import {useUser} from '@src/hooks/useUser';

type Props = {
  selectedAudioOutput: Optional<AudioOutputInfo>;
  selectedAudioInput: Optional<AudioInputInfo>;
  selectedVideoInput: Optional<VideoInputInfo>;
  cancelText?: string;

  onSave: (devices: Omit<MediaDeviceState, 'devices'>) => unknown;

  setDevicesReady: (r: boolean) => unknown;
};

interface Working {
  videoIn: Optional<boolean>;
  audioIn: Optional<boolean>;
  audioOut: Optional<boolean>;
}

const allMediaWorking = (state: Working) =>
  state.audioIn.orElse(false) &&
  state.audioOut.orElse(false) &&
  state.videoIn.orElse(false);

const anyMediaBroken = (state: Working) =>
  !(
    state.audioIn.orElse(true) &&
    state.audioOut.orElse(true) &&
    state.videoIn.orElse(true)
  );

export function SessionConfigForm({
  onSave,
  setDevicesReady,
  selectedAudioOutput,
  selectedAudioInput,
  selectedVideoInput,
}: Props) {
  const [user] = useUser();
  const history = useHistory();
  const reduxDispatch = useDispatch();

  const setAudioOutput = useCallback(
    (output: Optional<AudioOutputInfo>) =>
      reduxDispatch(actions.setAudioOutput(output)),
    [reduxDispatch],
  );
  const setAudioInput = useCallback(
    (output: Optional<AudioInputInfo>) =>
      reduxDispatch(actions.setAudioInput(output)),
    [reduxDispatch],
  );
  const setVideoInput = useCallback(
    (output: Optional<VideoInputInfo>) =>
      reduxDispatch(actions.setVideoInput(output)),
    [reduxDispatch],
  );

  const [audioSampleState, setAudioPlaying] = useState(
    AudioSampleState.Stopped,
  );
  const [contextState, setContextState] = useState<string>('pending');

  const [mediaFailure, setMediaFailure] = useState(
    Optional.empty<MediaFailure>(),
  );

  const [devices, deviceState, dispatchers] = useDevices({
    audioOut: selectedAudioOutput,
    audioIn: selectedAudioInput,
    videoIn: selectedVideoInput,
  });

  const deviceSelector = useCallback(deviceReducer(deviceState, dispatchers), [
    deviceState,
  ]);

  type WorkingAction = {type: keyof Working; value: boolean};
  const [working, dispatch] = useReducer(
    (state: Working, action: WorkingAction) => {
      return {...state, [action.type]: Optional.of(action.value)};
    },
    {
      videoIn: Optional.empty<boolean>(),
      audioIn: Optional.empty<boolean>(),
      audioOut: Optional.empty<boolean>(),
    },
  );

  const saveSettings = useCallback(() => {
    const {audioOut, audioIn, videoIn} = deviceState;
    setAudioOutput(audioOut);
    setAudioInput(audioIn);
    setVideoInput(videoIn);
    onSave({audioOut, audioIn, videoIn});
  }, [deviceState, setAudioInput, setAudioOutput, setVideoInput, onSave]);

  const loadAndProcess = useCallback(() => {
    loadDevices().then(result =>
      result.apply(makeNonOptional(setMediaFailure), d => {
        dispatchers.devices(d);
        deviceSelector(d);
        setMediaFailure(Optional.of(MediaFailure.None));
      }),
    );
  }, [dispatchers, deviceSelector]);

  useEventHandler(navigator.mediaDevices, 'devicechange', loadAndProcess);

  useEffect(() => {
    loadAndProcess();
  }, [loadAndProcess]);

  useEffect(() => {
    devices.ifPresent(() => {
      updateAudioOutputDevice(
        bell,
        deviceState.audioOut.map(v => v.deviceId),
      ).then(updateResult =>
        updateResult.apply(updateMediaFailure(setMediaFailure), () => {}),
      );
    });
  }, [devices, deviceState.audioOut]);

  useEffect(() => {
    if (allMediaWorking(working) && contextState === 'running') {
      setDevicesReady(true);
      saveSettings();
    }
  }, [contextState, working, saveSettings, setDevicesReady]);

  const _updateVideoInputDevice = _updateDevice(
    dispatchers.videoIn,
    devices.map(d => d.videoInputs),
  );

  const _updateAudioInputDevice = _updateDevice(
    dispatchers.audioIn,
    devices.map(d => d.audioInputs),
  );

  const _updateAudioOutputDevice = _updateDevice(
    dispatchers.audioOut,
    devices.map(d => d.audioOutputs),
  );

  return (
    <StyledSessionConfigForm className="body-scroll-lock-ignore">
      <SessionConfigModalContents>
        <VideoConfigSection
          devices={devices}
          setMediaFailure={setMediaFailure}
          selectedVideoInput={deviceState.videoIn}
          selectVideoInput={_updateVideoInputDevice}
          onWorking={value => dispatch({type: 'videoIn', value})}
          isWorking={working.videoIn}
        />
        <AudioInConfigSection
          devices={devices}
          selectedAudioInput={deviceState.audioIn}
          selectAudioInput={_updateAudioInputDevice}
          setMediaFailure={setMediaFailure}
          onContextChangeState={setContextState}
          onWorking={value => dispatch({type: 'audioIn', value})}
          isWorking={working.audioIn}
        />
        <AudioOutConfigSection
          audio={bell}
          devices={devices}
          audioSampleState={audioSampleState}
          setAudioPlaying={setAudioPlaying}
          selectedAudioOutput={deviceState.audioOut}
          selectAudioOutput={_updateAudioOutputDevice}
          onWorking={value => dispatch({type: 'audioOut', value})}
          isWorking={working.audioOut}
        />
        {anyMediaBroken(working) && (
          <Banner type="info" title="Need help?" style={{marginBottom: '2rem'}}>
            <ParagraphText>
              If you need help, reach out to our friendly support team at{' '}
              <a href="tel:+18778642378">+1-877-864-2378</a>.
            </ParagraphText>
          </Banner>
        )}
        {mediaFailure
          .map(failure => (
            <NoAccessToDevicesModal
              failure={failure}
              returnToSafety={returnToSafety(history, user)}
            />
          ))
          .orNull()}
      </SessionConfigModalContents>
    </StyledSessionConfigForm>
  );
}
