import React from 'react';
import {CondensedCard} from '../CondensedCard';
import {ExpandedCard} from '../ExpandedCard';
import {Invitation, Patient, LonelyPatient} from '@src/models';

type Props = {
  currentInvitations: readonly Invitation[];
  isCollapsed: boolean;
  onUpdatePatient: (p: LonelyPatient) => void;
  patient: Patient;
  patients: readonly Patient[];
  setCollapsed: (c: boolean) => unknown;
  setPatients: (patients: Patient[]) => unknown;
};
export function PatientCard({
  currentInvitations,
  isCollapsed,
  onUpdatePatient,
  patient,
  patients,
  setCollapsed,
  setPatients,
}: Props) {
  return isCollapsed ? (
    <CondensedCard
      patient={patient}
      onExpandCard={() => {
        setCollapsed(false);
      }}
      onUpdatePatient={onUpdatePatient}
    />
  ) : (
    <ExpandedCard
      currentInvitations={currentInvitations}
      patient={patient}
      patients={patients}
      onCollapseCard={() => {
        setCollapsed(true);
      }}
      onUpdatePatient={onUpdatePatient}
      setPatients={setPatients}
    />
  );
}
