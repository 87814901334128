import React from 'react';
import {Paper, Grid} from '@material-ui/core';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {addBreadcrumb, Severity, captureException} from '@sentry/browser';
import {useStyles} from './styles';
import {VisitFiles} from './VisitFiles';
import {InfoTable} from '@src/components/ui/layout/InfoTable';
import {PatientFundamentals} from '@src/components/shared/PatientDashboard/PatientFundamentals';
import {MedicalHistory, Patient, LonelyVisit, VisitFile} from '@src/models';
import {getStartTime, getDate} from '@src/util/visits/getVisitDescriptions';
import {providerAndQualification} from '@src/util/provider';

type Props = {
  patient: Patient;
  visit: AsyncData<LonelyVisit>;
  currentMedicalHistory: AsyncData<MedicalHistory>;
  visitFiles: readonly VisitFile[];
  setFiles: (v: readonly VisitFile[]) => unknown;
};

export function Info({
  patient,
  visit,
  currentMedicalHistory,
  visitFiles,
  setFiles,
}: Props) {
  const classes = useStyles();

  const aVisit = visit.getOptional().orNull();

  if (aVisit === null) {
    addBreadcrumb({
      category: 'error',
      message: 'Application Error',
      level: Severity.Error,
    });
    captureException(new Error(`Visit not found`));
    return null;
  }

  const {
    healthcareNotes,
    chiefComplaint,
    fallbackNumber,
    start,
    providerDetails: provider,
  } = aVisit;

  const time = getStartTime(start);
  const date = getDate(start);

  const providerDescription = provider
    .map(providerAndQualification)
    .orElse('Not yet assigned');

  return (
    <Grid container direction="row">
      <Grid
        item
        md={6}
        xs={12}
        className={classes.detailsSection}
        style={{maxHeight: '100vh', overflow: 'auto'}}
      >
        <Paper className={classes.leftSection}>
          <div style={{height: '100%', overflow: 'auto'}}>
            <PatientFundamentals
              patient={patient}
              currentMedicalHistory={currentMedicalHistory}
            />
            <InfoTable
              items={[
                {label: 'Visit Provider', content: providerDescription},
                {label: 'Time', content: `${date} at ${time}`},
                {
                  label: 'Guardian Phone Number',
                  content: fallbackNumber || 'Not yet given',
                },
                {
                  label: 'Visit Notes',
                  content: healthcareNotes || 'None given',
                },
                {
                  label: 'Chief Complaint',
                  content: chiefComplaint || 'None given',
                },
              ]}
            />
          </div>
        </Paper>
      </Grid>
      <Grid
        item
        className={classes.detailsSection}
        xs={12}
        md={6}
        style={{maxHeight: '100vh', overflow: 'auto'}}
      >
        <Paper
          className={classes.rightSection}
          style={{maxHeight: '100%', overflow: 'auto'}}
        >
          <VisitFiles
            files={visitFiles}
            setFiles={setFiles}
            visitId={aVisit.id}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
