// Below are various contexts for when a provider would be added or edited
// in a form.

export enum Context {
  AddMedicalTeamMember = 'addMedicalTeamMember',
  EditMedicalTeamMember = 'editMedicalTeamMember',
  SharedCarePlanOwner = 'sharedCarePlanOwner',
  PrimaryCareProvider = 'primaryCareProvider',
}

export enum FormMode {
  ManualEntry = 'manualEntry',
  NoProviderSelected = 'noProviderSelected',
  ProviderSearch = 'providerSearch',
}
