import {useApi} from '@src/api/useApi';
import {Resources, useResources} from '@src/hooks';
import {ProviderDetails} from '@src/models';

export const useMembers = (callPoolId: number): Resources<ProviderDetails> => {
  const api = useApi();

  return useResources(() => api.callPool(callPoolId).getMembers(), [
    api,
    callPoolId,
  ]);
};
