import React, {ReactNode} from 'react';
import {Box} from '@material-ui/core';

export const HEADER = 'header';
export const MAIN_CONTENT = 'main';
export const FOOTER = 'footer';

const HEADER_HEIGHT = '64px';
const FOOTER_HEIGHT = '100px';

export function AppBox({
  children,
  collapseNavigation = false,
}: {
  children: ReactNode;
  collapseNavigation?: boolean;
}) {
  return (
    <Box
      style={{
        display: 'grid',
        gridTemplateRows: `${collapseNavigation ? '0px' : HEADER_HEIGHT} 1fr ${
          collapseNavigation ? '0px' : FOOTER_HEIGHT
        }`,
        gridTemplateAreas: `"${HEADER}" "${MAIN_CONTENT}" "${FOOTER}"`,
        minHeight: '100%',
        width: '100%',
      }}
    >
      {children}
    </Box>
  );
}
