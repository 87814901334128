import {Patient} from '@src/models';

type GenderedPerson = {
  genderAssignedAtBirth: Patient['genderAssignedAtBirth'];
  pronouns: Patient['pronouns'];
};

const genderAndPronouns = ({
  genderAssignedAtBirth,
  pronouns,
}: GenderedPerson) => {
  return (
    (genderAssignedAtBirth && ` ${genderAssignedAtBirth}`) +
    (pronouns && ` (${pronouns})`)
  );
};

export default genderAndPronouns;
