import React from 'react';
import styled from 'styled-components';
import {BasicTable} from '../../ui/layout/BasicTable';
import {md} from '../../ui/theme';
import {PatientVisit} from './PatientVisit';
import {Patient, SimpleVisit} from '@src/models';

const VisitsStyled = styled.div`
  width: 100%;
  margin-bottom: ${md};
`;

const renderVisitList = (patient: Patient) => (
  visits: ReadonlyArray<SimpleVisit>,
  emptyMessage: string = '',
) => {
  if (visits.length) {
    return (
      <tbody>
        {visits.map(visit => (
          <PatientVisit key={visit.id} visit={visit} patient={patient} />
        ))}
      </tbody>
    );
  }

  return (
    <tbody>
      <tr>
        <td colSpan={99}>{emptyMessage}</td>
      </tr>
    </tbody>
  );
};

type Props = {
  patient: Patient;
  visits?: ReadonlyArray<SimpleVisit>;
  className?: string;
  emptyMessage?: string;
};

export const Visits = ({
  patient,
  visits = [],
  emptyMessage = 'No upcoming visits',
  className = '',
}: Props) => (
  <VisitsStyled className={className}>
    <BasicTable align={'left'}>
      {renderVisitList(patient)(visits || [], emptyMessage)}
    </BasicTable>
  </VisitsStyled>
);

export default Visits;
