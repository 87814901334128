import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {makeStyles} from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {format, min} from 'date-fns';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {getLinkHref, Routes} from '../../../shared/general/AppRoutes';
import {ViewButton} from '../../DashCard';
import {
  LonelySCP,
  SCPChangeRequest,
  SCPChangeRequestStatus,
  SCPStatus,
  UserType,
} from '@src/models';
import {getAllPages} from '@src/util/apiHelpers/getAllPages';
import {useUser} from '@src/hooks/useUser';
import {useApi} from '@src/api/useApi';
import {UserId} from '@src/models/User';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.warning.main,
    fontSize: 20,
    marginRight: 'auto',
    padding: 0,
    cursor: 'default',
  },
  danger: {
    color: theme.palette.error.main,
  },
}));

type Props = {
  scp: LonelySCP;
  view: 'owner' | 'careTeam';
};

export function ProviderScpRow({scp, view}: Props) {
  const api = useApi();
  const history = useHistory();
  const classes = useStyles();
  const [user] = useUser();

  const [changeRequests, setChangeRequests] = useState<
    AsyncData<SCPChangeRequest>
  >(AsyncData.notAsked());

  const ownershipExpiration = scp?.ownershipExpiration || null;
  const approvalExpiration = scp?.approvalExpiration || null;
  let expirationDate;

  if (ownershipExpiration && approvalExpiration) {
    expirationDate = format(
      min([ownershipExpiration, approvalExpiration]),
      'MM/dd/yyyy',
    );
  } else {
    if (ownershipExpiration) {
      expirationDate = format(ownershipExpiration, 'MM/dd/yyyy');
    } else if (approvalExpiration) {
      expirationDate = format(approvalExpiration, 'MM/dd/yyyy');
    }
  }

  const scpOwner = scp?.providerDetails?.map(pd => pd.providerId);

  const oUserType = user
    .getOptional()
    .map(u => u.userType)
    .orNothing();

  const oUserId = user
    .getOptional()
    .map(u => u.id)
    .orElse(0 as UserId);

  const isOwner = scpOwner
    .map(i => oUserId === i && oUserType === UserType.Professional)
    .orElse(false);

  useEffect(() => {
    setChangeRequests(AsyncData.loading());
    try {
      getAllPages(options => {
        return api.scp(scp.id).getChangeRequestsLog(options);
      })
        .then(crs => {
          setChangeRequests(AsyncData.loaded(crs));
        })
        .catch(e => {
          console.error('e', e);
          setChangeRequests(AsyncData.errored(e));
        });
    } catch (e) {
      console.error(e);
    }
  }, [api, scp]);

  if (view === 'owner' && !isOwner) return null;
  if (view === 'careTeam' && isOwner) return null;

  const needsNotification = () => {
    if (!isOwner) return false;
    if (scp.status !== SCPStatus.Approved) {
      return true;
    }

    if (
      changeRequests
        ?.getAllOptional()
        .orElse([])
        .filter(cr => cr.status === SCPChangeRequestStatus.Pending)?.length > 0
    ) {
      return true;
    }

    return false;
  };

  return (
    <tr key={scp.id}>
      <ViewButton
        onClick={() => {
          const patientId = scp.patient.get().id;
          history.push(getLinkHref(Routes.SCP, {patientId}));
        }}
      />
      <td>{scp?.patient?.map(p => p.preferredName).orNothing()}</td>
      <td>{expirationDate || 'None'}</td>
      {view === 'owner' && (
        <td>
          {needsNotification() ? (
            <NotificationsIcon
              className={`${classes.icon} ${
                (isOwner && scp.status === SCPStatus.Retracted) ||
                scp.status === SCPStatus.ApprovalExpired
                  ? classes.danger
                  : ''
              }`}
            />
          ) : (
            ''
          )}
        </td>
      )}
    </tr>
  );
}
