import {createStyles, makeStyles, Theme} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sortLink: {
      color: theme.palette.primary.main,
      textAlign: 'right',
    },
    actionItem: {
      color: theme.palette.secondary.dark,
    },
  }),
);
