import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {useHistory} from 'react-router';
import {Modal as ExamRoomModal} from '@src/components/shared/VideoChat/ExamRoomModals';
import {Button} from '@src/components/ui/form';
import {Modal} from '@src/components/ui/layout/Modal';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {LonelyVisit, UserType} from '@src/models';
import {BailProps, useDestination} from '@src/util/videoChat';

type Props = {
  onReconnect: (v: Optional<LonelyVisit>) => void;
  bail: (args: BailProps) => void;
  visit: Optional<LonelyVisit>;
  shouldShowReloadingModal: boolean;
  showModal: (m: ExamRoomModal) => unknown;
  userType: UserType;
};

export function Reconnecting({
  bail,
  shouldShowReloadingModal,
  onReconnect,
  visit,
  userType,
  showModal,
}: Props) {
  const [secondsLeft, setSecondsLeft] = useState(15);
  const [getDestination] = useDestination(
    userType,
    visit.property('id'),
    visit.property('status'),
  );
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (secondsLeft > 1) {
        setSecondsLeft(secondsLeft - 1);
      }
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (secondsLeft === 1) {
      onReconnect(visit);
    }
  }, [onReconnect, secondsLeft, visit]);

  return (
    <Modal
      show={shouldShowReloadingModal}
      title="Connection lost"
      fullScreen
      modalActions={
        <Grid container>
          <Grid item xs={12} style={{marginBottom: '1rem'}}>
            <Button
              onClick={() => onReconnect(visit)}
              bStyle="primary"
              isInRow={true}
            >
              Reconnect now
            </Button>
            <Button
              onClick={() =>
                bail({
                  e: new Error('Leaving Room'),
                  message: 'Leaving room at your request',
                  getDestination,
                  showModal,
                  history,
                })
              }
              bStyle="primary"
            >
              Leave Room
            </Button>
          </Grid>
        </Grid>
      }
    >
      <ParagraphText style={{marginRight: '2rem'}}>
        You have been disconnected. You may want to check your network
        connection. Reconnecting in {secondsLeft} seconds.
      </ParagraphText>
    </Modal>
  );
}
