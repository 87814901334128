import React, {useState, useEffect} from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import {ErrorMessage} from '@src/components/ui/form/ErrorMessage';
import {Acknowledge} from '@src/components/ui/form/Acknowledge';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {Button} from '@src/components/ui/form/Button';
import {FormStates} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/index';

type Props = {
  patientName: string;
  onCancel: () => void;
  setFormState: (s: FormStates) => void;
};

export const OwnershipQuestion: React.FunctionComponent<Props> = ({
  patientName,
  onCancel,
  setFormState,
}) => {
  const [isSelfOwner, setIsSelfOwner] = useState<boolean | null>(null);
  const [errors, setErrors] = useState<{[field: string]: string}>({});

  useEffect(() => {
    setErrors({
      ...errors,
      isSelfOwner: '',
    });
  }, [isSelfOwner]);

  return (
    <Grid container direction="row">
      <ParagraphText>
        This patient/family centered shared plan of care is very helpful for
        future health care encounters, especially for those newly involved and
        unfamiliar with {patientName}’s healthcare needs and preferences.
      </ParagraphText>
      <ParagraphText style={{fontWeight: 700}}>
        Are you willing to create a shared care plan that accurately describes
        the health and preferences of {patientName}?
      </ParagraphText>

      <Grid item xs={12} style={{marginBottom: '1rem'}}>
        <Acknowledge
          setValue={v => {
            setIsSelfOwner(v);
          }}
          value={Optional.of(isSelfOwner)}
          message="Confirm"
        />
      </Grid>

      {errors.isSelfOwner && <ErrorMessage message={errors.isSelfOwner} />}

      <Grid item xs={12} style={{marginBottom: '1rem'}}>
        <Button
          bSize="small"
          bStyle="contained"
          onClick={onCancel}
          isInRow={true}
        >
          Cancel
        </Button>
        <Button
          bSize="small"
          bStyle="contained"
          disabled={isSelfOwner === null}
          onClick={() => {
            if (isSelfOwner === null) {
              return setErrors({
                ...errors,
                isSelfOwner:
                  'Please indicate whether or not you will be the owner.',
              });
            }

            if (isSelfOwner) {
              setFormState(FormStates.OwnershipExpirationStep);
            } else {
              setFormState(FormStates.OwnershipSelectionStep);
            }
          }}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};
