import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';

import {UserAvatar} from '@src/components/ui/layout/UserAvatar';
import {ResponsiblePerson} from '@src/models';
import {isNothing} from '@src/util/typeTests';

type Props = {
  person: ResponsiblePerson | null;
  relationshipResult?: string;
  containerStyles?: Object;
  cardStyles?: Object;
};

const useStyles = makeStyles(theme => ({
  cardHeader: {
    paddingBottom: 0,
  },
  cardTitle: {whiteSpace: 'normal', wordBreak: 'break-word'},
  avatar: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 16,
    color: 'rgba(255,255,255,0.5)',
  },
  cardContainer: {
    borderRadius: '3px',
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
    margin: '1rem auto',
  },
  detail: {
    fontSize: 16,
    fontWeight: 300,
    paddingLeft: 0,
  },
  name: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

export const PersonCard = ({
  person,
  relationshipResult = '',
  containerStyles = {},
  cardStyles = {},
}: Props) => {
  const classes = useStyles();

  if (isNothing(person)) {
    return null;
  }

  const name = `${person.firstName} ${person.lastName}`;
  const phone = person.phone || '—';

  const email = person.email.orElse('—');

  return (
    <Grid
      container
      direction="row"
      className={classes.cardContainer}
      style={containerStyles}
    >
      <Card
        style={{width: '100%', backgroundColor: 'transparent', ...cardStyles}}
        variant="outlined"
      >
        <CardContent>
          <CardHeader avatar={<UserAvatar user={person} />} title={name} />
          <Typography className={classes.detail}>Phone: {phone}</Typography>

          <Typography className={classes.detail}>Email: {email}</Typography>
          {relationshipResult.length > 0 && (
            <Typography className={classes.detail}>
              Relationship: {relationshipResult}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};
