import {Optional} from '@ahanapediatrics/ahana-fp';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useRef, useState} from 'react';
import {Grid} from '@material-ui/core';
import {MediaFailure} from '../../../../../util/videoChat';
import {SelectInput} from '../../../../ui/form';
import {ParagraphText} from '../../../../ui/layout/text/body/ParagraphText';
import {MuiHeading} from '../../../../ui/layout/MuiHeading';
import {
  connectStreamToVideoElement,
  updateMediaFailure,
  updateVideoInputDevice,
} from './functions';
import {Devices} from './MediaDeviceManager';
import {WorkingReporter} from './WorkingReporter';

type Props = {
  devices: Optional<Devices>;
  isWorking: Optional<boolean>;
  selectedVideoInput: Optional<MediaDeviceInfo>;
  selectVideoInput: (d: Optional<string>) => unknown;
  setMediaFailure: (f: Optional<MediaFailure>) => unknown;
  onWorking?: (b: boolean) => unknown;
};

export const VideoConfigSection = ({
  devices,
  isWorking,
  onWorking,
  selectedVideoInput,
  selectVideoInput,
  setMediaFailure,
}: Props) => {
  const videoRef = useRef(null);
  const [stream, setStream] = useState<Optional<MediaStream>>(Optional.empty());
  const [videoPlaying, setVideoPlaying] = useState(false);

  useEffect(() => {
    updateVideoInputDevice(stream)(
      selectedVideoInput.map(v => v.deviceId),
    ).then(st =>
      st.apply(updateMediaFailure(setMediaFailure), s => {
        setStream(Optional.of(s));
      }),
    );
  }, [selectedVideoInput]);

  useEffect(() => {
    connectStreamToVideoElement(stream, videoRef);
    setVideoPlaying(stream.map(() => videoRef.current !== null).orElse(false));

    const top = document.querySelectorAll(`.top`)[0];
    top.scrollIntoView();
  }, [videoRef.current, stream]);

  useEffect(() => {
    return () => {
      stream.ifPresent(s => s.getTracks().forEach(track => track.stop()));
    };
  }, [stream]);

  const options = devices
    .map(d => d.videoInputs)
    .orElse([])
    .map(a => ({
      value: a.deviceId,
      label: a.label || `Camera ${a.deviceId}`,
    }));

  return (
    <Grid container direction="row">
      <div className="top" style={{textAlign: 'center'}}>
        <MuiHeading
          variant="h6"
          color="primary"
          style={{marginBottom: '1rem', marginTop: '1rem', width: '100%'}}
        >
          Can you see yourself?
        </MuiHeading>
      </div>

      <ParagraphText>
        Make sure you can see yourself in the picture below. You may need to
        choose a different camera from the first dropdown if you cannot.
      </ParagraphText>
      <Grid item xs={12} style={{marginBottom: '3rem'}}>
        <Grid item style={{width: '200px', marginBottom: '2rem'}}>
          {!videoPlaying && (
            <div>
              Loading webcam <FontAwesomeIcon spin={true} icon={'spinner'} />
            </div>
          )}
          <video autoPlay playsInline ref={videoRef} style={{width: '100%'}} />
        </Grid>
        <SelectInput
          title="Video Input"
          name="videoInput"
          options={options}
          placeholder="Please select an video input"
          value={selectedVideoInput.map(i => i.deviceId).orElse('')}
          onChange={e => {
            selectVideoInput(Optional.of(e.target.value));
          }}
        />
        {onWorking && (
          <div style={{marginTop: '1rem'}}>
            <WorkingReporter isWorking={isWorking} reportWorking={onWorking} />
          </div>
        )}
      </Grid>
    </Grid>
  );
};
