import {partnerDetailsToProfessionalId} from './convertPartnerDetails';
import {inviterDetailsToProviderId} from './convertInviterDetails';
import {ProviderPartner} from '@src/models';

type IsPartnerProps = {
  inviterUserId?: number;
  maybePartnerUserId?: number;
  partnerships?: readonly ProviderPartner[];
};

export function isPartner({
  inviterUserId = 0,
  maybePartnerUserId = 0,
  partnerships = [],
}: IsPartnerProps) {
  const partnershipsForMaybePartner = filterPartnershipsByPartner({
    partnerships,
    partnerUserId: maybePartnerUserId,
  });

  return partnershipsForMaybePartner.some(
    partnership => inviterDetailsToProviderId({partnership}) === inviterUserId,
  );
}

type FilterPartnershipsByPartner = {
  partnerUserId?: number;
  partnerships?: readonly ProviderPartner[];
};

// Given an array of partnerships and the id of the partner corresponding to the User, return an
// array of partnerships where the user corresponding to partnerUserId is the partner.

// Note that we store partnerDetailsId in the database, but we are often going to be
// making this check on the front end with only the userId to work from, so that is why
// we need a utility function that accepts a User id instead of a ProviderDetails id.
export function filterPartnershipsByPartner({
  partnerUserId,
  partnerships = [],
}: FilterPartnershipsByPartner) {
  return partnerships.filter(
    partnership =>
      partnerDetailsToProfessionalId({partnership}) === partnerUserId,
  );
}
