import {format} from 'date-fns';
import React from 'react';

import EditIcon from '@material-ui/icons/Edit';
import {
  Typography,
  Grid,
  useMediaQuery,
  Card,
  CardContent,
} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import {getPhoneNumberString} from '@src/components/ui/layout/PhoneNumber';
import {useStyles as useGeneralStyles} from '@src/components/shared/PatientDashboard/SCP/styles';
import {useComponentStyles} from '@src/components/shared/PatientDashboard/SCP/panels/Contacts/styles';
import {Contact, ResponsiblePerson} from '@src/models';
import {toSentenceCase} from '@src/util/stringManipulation/toTitleCase';

const isContact = (c: Contact | ResponsiblePerson): c is Contact => 'role' in c;

type Props =
  | {
      contact: Contact;
      canEdit: boolean;
      onEdit: () => void;
      relationship?: never;
    }
  | {
      contact: ResponsiblePerson;
      canEdit: boolean;
      onEdit: () => void;
      relationship: string;
    };

export const ContactCard = ({
  canEdit,
  contact,
  onEdit,
  relationship,
}: Props) => {
  const componentClasses = useComponentStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useGeneralStyles();
  const name = isContact(contact)
    ? contact.name
    : `${contact.firstName} ${contact.lastName}`;
  const rel = isContact(contact) ? contact.role : relationship;
  const phone = contact.phone;
  const secondNumber = isContact(contact) ? contact.secondNumber : '';
  const dob = contact.dob;
  const address = contact.address;

  return (
    <Grid
      container
      direction={isMobile ? 'column' : 'row'}
      className={componentClasses.cardContainer}
    >
      <Card
        style={{width: '100%', backgroundColor: 'transparent'}}
        variant="outlined"
      >
        <CardContent>
          <Typography className={componentClasses.name} key={name}>
            {name} {rel && `(${toSentenceCase(rel)})`}
            {canEdit && (
              <EditIcon
                onClick={onEdit}
                className={classes.icon}
                style={{
                  marginBottom: 0,
                  marginLeft: '5px',
                  display: 'inline-block',
                  fontSize: 16,
                }}
              />
            )}
          </Typography>

          <Typography className={componentClasses.detail}>
            Primary phone: {getPhoneNumberString({no: phone || ''})}
          </Typography>

          {secondNumber && (
            <Typography className={componentClasses.detail}>
              Backup phone: {getPhoneNumberString({no: secondNumber})}
            </Typography>
          )}
          {dob
            .map(d => (
              <Typography className={componentClasses.detail}>
                DOB: {format(d, 'MM/dd/yyyy')}
              </Typography>
            ))
            .orNull()}
          {address && (
            <>
              <Typography className={componentClasses.detail}>
                {address.line1}
              </Typography>
              <Typography className={componentClasses.detail}>
                {(address.line2 ?? '').length > 0 && <>{address.line2}</>}
              </Typography>
              <Typography className={componentClasses.detail}>
                {address.city} {address.state}, {address.zipcode}
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};
