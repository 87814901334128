import {CallPool} from '@src/models';

const getCPOption = (
  pool: CallPool,
  available: boolean,
  pools: Map<number, CallPoolOption>,
) => {
  const option = pools.get(pool.id) ?? {
    id: pool.id,
    available,
    parent: pool.parentPool.map(p => p.id).orNothing(),
    children: pool.children.map(c => c.map(p => p.id)).orElse([]),
    nameForProviders: pool.nameForProviders,
    nameForGuardians: pool.nameForGuardians,
    description: pool.description,
  };

  pool.parentPool.ifPresent(parent => {
    option.parent = parent.id;
  });
  pool.children.ifPresent(children => {
    if (children.length > 0) {
      option.children = children.map(c => c.id);
    }
  });

  if (available) {
    option.available = true;
  }

  return option;
};

export type CallPoolOption = {
  available: boolean;
  parent?: number;
  children: number[];
  nameForProviders: string;
  nameForGuardians: string;
  id: number;
  description: string;
};

export const sortCallPools = (
  callPools: readonly CallPool[],
  preSorted = new Map<number, CallPoolOption>(),
): Map<number, CallPoolOption> => {
  callPools.forEach(pool => {
    const currentOption: CallPoolOption = getCPOption(pool, true, preSorted);
    preSorted.set(currentOption.id, currentOption);

    pool.parentPool.ifPresent(parent => {
      const parentOption: CallPoolOption = getCPOption(
        parent,
        false,
        preSorted,
      );

      if (!parentOption.children.includes(currentOption.id)) {
        parentOption.children = [...parentOption.children, currentOption.id];
      }

      preSorted.set(parentOption.id, parentOption);
    });

    pool.children.ifPresent(children => {
      children.forEach(child => {
        const childOption = getCPOption(child, false, preSorted);
        preSorted.set(childOption.id, childOption);
      });
    });
  });

  return preSorted;
};

const isOption = (o: CallPoolOption | null): o is CallPoolOption => o !== null;

export const getAvailableChildOptions = (
  option: CallPoolOption,
  groups: Map<number, CallPoolOption>,
) =>
  option.children
    .map(id => {
      const child = groups.get(id);
      if (!child || !child.available) {
        return null;
      }
      return child;
    })
    .filter(isOption);
