import {ReactNode} from 'react';
import {Routes} from '../general/AppRoutes';
import {PermissionsProps} from '.';
import {UserType} from '@src/models';
import {requestHelp} from '@src/util/applicationEvents/requestHelp';

export type MenuItem = {
  to?: string;
  label: ReactNode;
  action?: () => unknown;
};

const ProfessionalMenuItems: (permissions: PermissionsProps) => MenuItem[] = (
  permissions: PermissionsProps,
) => {
  const {canCreateGroups} = permissions;
  const base = [
    {to: '/past-visits', label: 'Past Visits'},
    {to: '/patient-search', label: 'Patient Search'},
    {to: '/provider-dashboard', label: 'Notes & Plans'},
    {to: '/shifts-and-groups', label: 'Shifts & Groups'},
    {to: Routes.StoredReports, label: 'Reports'},
  ];

  if (canCreateGroups) {
    base.push({label: 'Management Dashboard', to: '/management-dashboard'});
  }

  return [...base, {label: 'Help', action: requestHelp}];
};

const GuardianMenuItems: () => MenuItem[] = () => [
  {label: 'Help', action: requestHelp},
];

const AdminMenuItems: () => MenuItem[] = () => [];

export const MenuItems = (type: UserType, permissions: PermissionsProps) => {
  if (type === UserType.Professional) {
    return ProfessionalMenuItems(permissions);
  }

  if (type === UserType.Guardian) {
    return GuardianMenuItems();
  }

  if (type === UserType.Admin) {
    return AdminMenuItems();
  }

  return [];
};
