import {Grid, Paper} from '@material-ui/core';
import React, {useState} from 'react';
import {ConnectedLogin} from '../../stepFunctions';
import {AddLoginForm} from './AddLoginForm';
import {useConnectedLoginStyles} from './styles';
import {Connection} from '@src/components/shared/SimplePatientCard/AssociatedUsers/Connection';
import {
  ConnectionText,
  ConnectionTextContext,
} from '@src/components/shared/SimplePatientCard/AssociatedUsers/ConnectionText';
import {useStyles} from '@src/components/shared/SimplePatientCard/AssociatedUsers/layout';
import {NoConnectedLogins} from '@src/components/shared/SimplePatientCard/AssociatedUsers/NoConnectedLogins';
import {Button} from '@src/components/ui/form';

type Props = {
  connectedLogins: ConnectedLogin[];
  setConnectedLogins: (logins: ConnectedLogin[]) => unknown;
};

export function ConnectedLoginsList({
  connectedLogins,
  setConnectedLogins,
}: Props) {
  const classes = {...useStyles(), ...useConnectedLoginStyles()};
  const [showForm, setShowForm] = useState(false);

  return (
    <div>
      <Grid container direction="column" className={classes.rootComponent}>
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper} style={{padding: '1rem 2rem'}}>
            {connectedLogins.length > 0 ? (
              connectedLogins.map(cl => {
                return (
                  <Connection
                    icon="trash"
                    className={classes.userListRow}
                    edittable={true}
                    isSelf={cl.isSelf}
                    onClickConnectionAction={() => {
                      setConnectedLogins(
                        [...connectedLogins].filter(
                          login => login.email !== cl.email,
                        ),
                      );
                    }}
                  >
                    <ConnectionText
                      context={ConnectionTextContext.NewPatient}
                      isSelf={cl.isSelf}
                      email={cl.email}
                      relationshipName={cl.relationshipName}
                    />
                  </Connection>
                );
              })
            ) : (
              <NoConnectedLogins />
            )}
          </Paper>
        </Grid>
      </Grid>

      <Button
        bStyle="primary"
        onClick={() => {
          setShowForm(true);
        }}
        disabled={showForm}
      >
        Add Connected Login
      </Button>

      {showForm && (
        <AddLoginForm
          onHide={() => setShowForm(false)}
          show={showForm}
          connectedLogins={connectedLogins}
          setConnectedLogins={setConnectedLogins}
        />
      )}
    </div>
  );
}
