import React, {useCallback, useState} from 'react';
import _ from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconButton, List, ListSubheader} from '@material-ui/core';
import {useStyles} from '../../files/FilesOverview/util/styles';
import {UploadModal} from '@src/components/ui/organisms/uploaders/files/UploadModal';
import {Files} from '@src/components/ui/organisms/uploaders/files/Folder/Files';
import {NoFiles} from '@src/components/ui/organisms/uploaders/files/Folder/NoFiles';
import {convertToAppFile, AppFile, VisitFile} from '@src/models';
import {useApi} from '@src/api/useApi';
import {SigningResult} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';

export function VisitFiles({
  files,
  setFiles,
  visitId,
}: {
  files: readonly VisitFile[];
  setFiles: (v: readonly VisitFile[]) => unknown;
  visitId: number;
}) {
  const classes = useStyles();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const api = useApi();

  const onFileUploadedToS3 = useCallback(
    (result: SigningResult<AppFile>, file: File): Promise<AppFile> => {
      return api
        .visit(visitId)
        .addFile({...result, contentType: file.type})
        .then(m => {
          setFiles([...files, m]);
          return m;
        })
        .then(f => convertToAppFile(f));
    },
    [api, setFiles, files, visitId],
  );

  return (
    <>
      <List
        component="nav"
        subheader={
          <ListSubheader component="div">
            Visit Files
            <IconButton
              onClick={() => {
                setShowUploadModal(true);
              }}
            >
              <FontAwesomeIcon icon={['fas', 'plus-circle']} size="sm" />
            </IconButton>
          </ListSubheader>
        }
        className={classes.root}
      >
        {files.length <= 0 && (
          <NoFiles noFilesText="There are no files for this visit yet." />
        )}
        {files.length > 0 && <Files files={files} open={true} />}
      </List>
      {showUploadModal && (
        <UploadModal
          onFileUploadedToS3={onFileUploadedToS3}
          setShow={setShowUploadModal}
          show={showUploadModal}
        />
      )}
    </>
  );
}
