import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Grid, Typography} from '@material-ui/core';
import {InvitePerson} from '../../patient/relationships/InvitePerson';
import {useStyles} from './layout';
import {NoConnectedLogins} from './NoConnectedLogins';
import {CurrentRelationship} from './CurrentRelationship';
import {PendingRelationship} from './PendingRelationship';
import {PatientRelationship, Invitation, LonelyPatient} from '@src/models';
import {useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {allLoaded} from '@src/util/apiHelpers';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';

type ModalType = 'self' | 'guardian';

type Props = {
  patient: LonelyPatient;
};

export function AssociatedUsers({patient}: Props) {
  const api = useApi();
  const classes = useStyles();

  const [relationships, reloadRelationships] = useResources<
    PatientRelationship
  >(() => api.patient(patient.id).getGuardianships(), [api, patient.id]);

  const [
    selfRelationship,
    reloadSelf,
  ] = useResources<PatientRelationship | null>(
    () =>
      api
        .patient(patient.id)
        .findSelfRelationship()
        .then(r => r)
        .catch(() => null),
    [api, patient.id],
  );

  const [invitations, reloadInvitations] = useResources<Invitation>(
    () =>
      api
        .patient(patient.id)
        .get()
        .then(p => p.invitations),
    [api, patient.id],
  );
  const [modalType, setModalType] = useState<ModalType | null>(null);

  const invitationList = invitations.getAllOptional().orElse([]);
  const guardianInvitations = invitationList.filter(r => !r.isSelf);
  const selfInvitation = invitationList.find(i => i.isSelf);
  const selfRel = selfRelationship.getOptional().orNull();

  const needsPendingSection = selfInvitation || guardianInvitations.length > 0;

  const relationshipsLoaded = allLoaded(
    selfRelationship,
    relationships,
    invitations,
  );

  const nonSelfRelationships = relationships.getAllOptional().orElse([]);
  const currentRelationships = selfRel
    ? [selfRel, ...nonSelfRelationships]
    : nonSelfRelationships;

  return (
    <div className={classes.rootComponent}>
      <PageLoading
        className={classes.loadingLimiter}
        active={!relationshipsLoaded}
        message="Loading Connected Logins"
      >
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h3" className={classes.sectionTitle}>
              Connected Logins{' '}
              <FontAwesomeIcon
                icon={['fas', 'plus-circle']}
                size="sm"
                onClick={() => {
                  setModalType('guardian');
                }}
                className={classes.plusIcon}
              />
            </Typography>

            <Grid container className={classes.userList} direction="column">
              {currentRelationships.length === 0 ? (
                <NoConnectedLogins className={classes.userListRow} />
              ) : (
                currentRelationships.map(rel => (
                  <CurrentRelationship
                    key={rel.id}
                    currentInvitations={invitationList}
                    patient={patient}
                    relationship={rel}
                    className={classes.userListRow}
                    reloadRelationships={async () =>
                      Promise.all([reloadRelationships(), reloadSelf()])
                    }
                  />
                ))
              )}
            </Grid>
            {needsPendingSection && (
              <>
                <Typography variant="h3" className={classes.sectionTitle}>
                  Pending Connected Logins
                </Typography>
                <Grid container className={classes.userList} direction="column">
                  {selfInvitation && (
                    <PendingRelationship
                      invitation={selfInvitation}
                      onDelete={reloadInvitations}
                      patient={patient}
                      className={classes.userListRow}
                    />
                  )}
                  {guardianInvitations.map(i => (
                    <PendingRelationship
                      invitation={i}
                      onDelete={reloadInvitations}
                      patient={patient}
                      className={classes.userListRow}
                    />
                  ))}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </PageLoading>

      {modalType !== null && (
        <InvitePerson
          currentInvitations={invitationList}
          currentRelationships={currentRelationships}
          onInvitationCreated={reloadInvitations}
          patientId={patient.id}
          patientName=""
          onHide={() => setModalType(null)}
          show={modalType !== null}
          mode="modal"
          submitButtonText={{actionWord: 'Inviting', text: 'Invite'}}
        />
      )}
    </div>
  );
}
