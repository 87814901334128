import {useFormContext, Controller} from 'react-hook-form';
import React from 'react';
import * as yup from 'yup';
import {SelectInput} from '../../../ui/form';
import {FormSection} from './FormSection';
import {rqString, yString} from '@src/schema/types';

export const billingFieldsSchema = {
  billing_code: yup.string().when('$isSigning', {
    is: true,
    then: rqString('Please be sure to set the appropriate billing code'),
    otherwise: yString,
  }),
};

export type BillingFieldsValues = {
  billing_code: string;
};

export const defaultBillingFieldsValues = (): BillingFieldsValues => ({
  billing_code: '',
});

export const BillingFieldsLabels = {
  billing_code: 'Billing Code',
};

const billingCodes = [
  {label: '99212', value: '99212'},
  {label: '99213', value: '99213'},
  {label: '99214', value: '99214'},
  {label: '99215', value: '99215'},
  {label: '99214 and 99339 (<30 min SCP)', value: '99339'},
  {label: '99215 and 99340 (>30 min SCP)', value: '99340'},
  {label: 'No Charge', value: 'no-charge'},
];

type Props = {isSigning?: boolean};

export function BillingFieldsSection({isSigning = false}: Props) {
  const {errors, control} = useFormContext<BillingFieldsValues>();
  return (
    <FormSection title="Billing – E & M Codes">
      <Controller
        control={control}
        name="billing_code"
        render={({onChange, value, name}, {isTouched}) => (
          <SelectInput
            value={value}
            required={isSigning}
            options={billingCodes}
            name={name}
            placeholder="Choose a code"
            title={BillingFieldsLabels.billing_code}
            onChange={onChange}
            touched={isTouched || !!errors.billing_code}
            error={errors.billing_code?.message}
          />
        )}
      />
    </FormSection>
  );
}
