import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useCallback} from 'react';
import {Grid} from '@material-ui/core';
import {LastUpdated} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/LastUpdated';
import {UpdateMode} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/UpdateModal';
import {Value} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/CurrentView/Value';
import {RequestButtonMessageGroup} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/CurrentView/RequestButtonMessageGroup';
import {SharedCarePlan} from '@src/models';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {useLastUpdatedGetter} from '@src/hooks/scp/changeRequests/useLastUpdatedGetter';
import {useApprovedRequestForCurrent} from '@src/hooks/scp/changeRequests/useApprovedRequestForCurrent';

export function CurrentView({
  canEdit,
  property,
  setShowUpdateModal,
  userOwnsNewestChange,
}: {
  canEdit: boolean;
  property: keyof SharedCarePlan;
  setShowUpdateModal: (v: UpdateMode) => unknown;
  userOwnsNewestChange: boolean;
}) {
  const {scp, changeRequests} = usePageContext();

  const currentValue: string = scp
    .getOptional()
    .map(m => m[property])
    .cast<string>(v => typeof v === 'string')
    .orElse('');

  const approvedRequestForCurrentValue = useApprovedRequestForCurrent({
    scp,
    changeRequests,
    property,
  });

  const updatable = useLastUpdatedGetter(
    Optional.of(approvedRequestForCurrentValue),
  );

  const handleEditButtonClicked = useCallback(() => {
    setShowUpdateModal('create');
  }, [setShowUpdateModal]);

  return (
    <>
      <Grid item xs={12}>
        <Value
          lastUpdated={<LastUpdated updated={updatable} />}
          currentValue={currentValue}
        />
      </Grid>

      <RequestButtonMessageGroup
        userOwnsNewestChange={userOwnsNewestChange}
        handleEditButtonClicked={handleEditButtonClicked}
        canEdit={canEdit}
        currentValue={currentValue}
      />
    </>
  );
}
