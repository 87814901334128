import React, {useCallback} from 'react';
import EditView, {SignatureChangeHandler} from './EditView';
import ReadView from './ReadView';
import {isNothing} from '@src/util/typeTests';

interface Props {
  signedAt?: Date | null;
  name?: string | null;
  font?: string | null;
  imageUri?: string | null;
  onChange?: SignatureChangeHandler;
  readonly?: boolean;
}

export default function Signature({
  font = null,
  imageUri = null,
  name = null,
  onChange = () => {},
  readonly = false,
  signedAt = null,
}: Props) {
  const handleEdit = useCallback(
    () => onChange({font, name, signedAt: null, imageUri}),
    [onChange, font, name, imageUri],
  );

  if (isNothing(signedAt)) {
    if (readonly) {
      return null;
    }

    return (
      <EditView
        name={name ?? ''}
        font={font}
        imageUri={imageUri}
        onChange={onChange}
      />
    );
  } else {
    return (
      <ReadView
        readonly={readonly}
        name={name}
        font={font}
        imageUri={imageUri}
        onEdit={handleEdit}
      />
    );
  }
}

export {SignatureChangeHandler};
