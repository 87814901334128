import {useCallback, useMemo, useState} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useOnAddGroupClientConfiguration} from './useAddGroupClientConfiguration';
import {ClientConfiguration} from '@src/models';
import {useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {
  flashSuccess,
  flashError,
} from '@src/components/shared/notifications/flash';
import {NAMES} from '@src/components/ui/layout/text/names';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';

export type AddableSectionResources = {
  addableClientConfigurations: AsyncData<ClientConfiguration>;
  handleAddClicked: (v: number) => unknown;
  handleCancelAdd: () => unknown;
  onAddClientConfiguration: () => Promise<void>;
  clientConfigurationIdToAdd: number | null;
};

export function useAddableSectionResources({
  editClientConfigurationsForGroupId,
  reloadGroupClientConfigurations,
}: {
  editClientConfigurationsForGroupId: number | null;
  reloadGroupClientConfigurations: () => Promise<unknown>;
}): AddableSectionResources {
  const api = useApi();

  const [clientConfigurationIdToAdd, setClientConfigurationIdToAdd] = useState<
    number | null
  >(null);

  const [
    addableClientConfigurations,
    reloadAddableClientConfigurations,
  ] = useResources(
    () =>
      api
        .callPool(editClientConfigurationsForGroupId ?? 0)
        .getAddableClientConfigurations(),
    [api, editClientConfigurationsForGroupId],
    {
      requestGate: () => {
        return editClientConfigurationsForGroupId !== null;
      },
    },
  );

  const onAddClientConfiguration = useOnAddGroupClientConfiguration({
    runBeforeRequest: useCallback(() => {}, []),
    asyncRunAfterRequest: useCallback(async () => {
      await reloadGroupClientConfigurations();
      await reloadAddableClientConfigurations();
      flashSuccess('Success');
    }, [reloadGroupClientConfigurations, reloadAddableClientConfigurations]),
    runFinally: useCallback(() => {}, []),
    runOnError: useCallback(
      () =>
        flashError(
          `Something went wrong adding the ${toTitleCase(
            NAMES.customerCode,
          )} to this ${toTitleCase(NAMES.callPool)}`,
        ),
      [],
    ),
    groupId: editClientConfigurationsForGroupId,
    clientConfigurationId: clientConfigurationIdToAdd,
  });

  const handleAddClicked = useCallback(
    (id: number) => {
      setClientConfigurationIdToAdd(id);
    },
    [setClientConfigurationIdToAdd],
  );

  const handleCancelAdd = useCallback(() => {
    setClientConfigurationIdToAdd(null);
  }, [setClientConfigurationIdToAdd]);

  return useMemo(
    () => ({
      addableClientConfigurations,
      clientConfigurationIdToAdd,
      handleAddClicked,
      handleCancelAdd,
      onAddClientConfiguration,
    }),
    [
      addableClientConfigurations,
      clientConfigurationIdToAdd,
      handleAddClicked,
      handleCancelAdd,
      onAddClientConfiguration,
    ],
  );
}
