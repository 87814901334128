import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {AnonymousRoomControlProps} from './types';
import {requestHelp} from '@src/util/applicationEvents/requestHelp';

type GetControlsProps = {
  setShowShareModal: (v: boolean) => unknown;
  showShareModal: boolean;
} & Omit<AnonymousRoomControlProps, 'showButtonText' | 'userType'>;

type Control = {
  icon?: JSX.Element;
  label?: string;
  onClick?: () => unknown;
  divider?: boolean;
  include?: boolean;
  disabled?: boolean;
  badge?: number;
};

export function getControls({
  onReconnect,
  pauseVisitForCurrentUser,
  setShowShareModal,
  showShareModal,
}: GetControlsProps): Control[] {
  return [
    {
      icon: <FontAwesomeIcon icon={['fas', 'door-open']} />,
      label: 'Leave Visit',
      onClick: () => pauseVisitForCurrentUser(),
      include: true,
    },
    {
      divider: true,
      include: true,
    },
    {
      icon: <FontAwesomeIcon icon={['fas', 'share-alt']} />,
      label: 'Share Link',
      onClick: () => setShowShareModal(!showShareModal),
      include: true,
    },
    {
      divider: true,
      include: true,
    },
    {
      icon: <FontAwesomeIcon icon={['fas', 'redo']} />,
      label: 'Reconnect',
      onClick: onReconnect,
      include: true,
    },
    {
      divider: true,
      include: true,
    },
    {
      icon: <FontAwesomeIcon icon={['fas', 'question-circle']} />,
      label: 'Help',
      onClick: () => requestHelp(),
      include: true,
    },
  ];
}
