import React from 'react';
import SearchProvider from '../SearchProvider';
import {createGenericContext} from '@src/util/reactContext/createGenericContext';
import {PageHeader} from '@src/components/ui/layout';
import {PrivatePage} from '@src/components/PrivatePage';

import {
  ProfessionalSearchResources,
  useProfessionalSearch,
} from '@src/hooks/people/professionals/useProfessionalSearch';

const [usePageContext, PageContextProvider] = createGenericContext<
  ProfessionalSearchResources
>();

// eslint-disable-next-line import/no-unused-modules
export default function SearchProviderPage() {
  const resources = useProfessionalSearch({
    includeUnapprovedMembershipGroups: true,
  });

  return (
    <PageContextProvider value={resources}>
      <PrivatePage>
        <PageHeader>Search a provider</PageHeader>
        <SearchProvider professionalSearchResources={resources} />
      </PrivatePage>
    </PageContextProvider>
  );
}

export {usePageContext};
