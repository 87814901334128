import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {StepProps} from '../../functions';

import {approveDocument, useAcceptances} from './functions';
import {useStyles} from './layout';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {Button} from '@src/components/ui/form';
import {useApi} from '@src/api/useApi';
import {FlowProps} from '@src/hooks/useSteps';
import {getNumberKeys} from '@src/util/objectManipulation/getKeys';
import {PdfViewer} from '@src/components/ui/organisms/PdfViewer';

type Props = StepProps & FlowProps;

export function ConsentStep({
  moveGuard,
  requiredDocs,
  unsignedDocs,
  setUnsignedDocs,
  proceedToNextStep,
  setNextAvailable,
}: Props) {
  const classes = useStyles();
  const api = useApi();
  const [consentToSign, setConsentToSign] = useState(false);
  const [acceptances, {accept, unaccept, add}] = useAcceptances({});
  const [docToShow, setDocToShow] = useState(0);
  const [allConsented, setAllConsented] = useState(false);

  const requiredDocCount = requiredDocs.length;
  const initialUnsignedCount = unsignedDocs.length;

  moveGuard.current = async () => {
    return true;
  };

  const needsSigningInThisPass = (id: number) =>
    unsignedDocs.map(u => u.id).includes(id);

  useEffect(() => {
    const unsignedIds = unsignedDocs.map(u => u.id);
    requiredDocs.forEach(ld => {
      add(ld.id, !unsignedIds.includes(ld.id));
    });

    if (initialUnsignedCount === 0) {
      setConsentToSign(true);
      setAllConsented(true);
      setNextAvailable(true);
    } else {
      setNextAvailable(false);
    }
  }, [requiredDocs, unsignedDocs, add, initialUnsignedCount, setNextAvailable]);

  useEffect(() => {
    const consents = Object.values(acceptances);
    setAllConsented(consents.length > 0 && consents.every(Boolean));
  }, [acceptances]);

  return (
    <>
      <RequestHeader>Consent Documents</RequestHeader>
      <RequestInstructions>
        <p>
          Visits with this provider require you to sign{' '}
          {requiredDocCount === 1
            ? 'the following document'
            : 'the following documents'}
          .
        </p>
        {initialUnsignedCount > 0 ? (
          <>
            <p>
              Take a look at {requiredDocCount === 1 ? 'the' : 'each'} document.
            </p>
            <p>
              To sign a document, just click the checkbox. You will need to sign{' '}
              {requiredDocCount === 1 ? 'this' : 'every'} document before you
              can proceed.
            </p>
          </>
        ) : (
          <>
            <p>
              Since you have signed {requiredDocCount === 1 ? 'this' : 'every'}{' '}
              document, you can proceed. These are shown here just for your
              reference.
            </p>
          </>
        )}

        <p>
          If you have any questions about what you're signing, you'll need to
          contact your doctor's office.
        </p>
      </RequestInstructions>
      {initialUnsignedCount > 0 && (
        <FormControlLabel
          control={
            <Checkbox
              checked={consentToSign}
              onChange={() => {
                setConsentToSign(!consentToSign);
              }}
              name="consentToSign"
              color="primary"
            />
          }
          label="Please check this box to indicate your consent to sign these documents electronically"
        />
      )}

      <Divider className={classes.divider} />

      {requiredDocs.map(ld => (
        <Card key={ld.id}>
          <CardHeader title={ld.name} />
          <CardContent>
            <Button
              bStyle="outlined"
              className={classes.viewButton}
              onClick={() => {
                setDocToShow(ld.id);
              }}
              fullWidth
            >
              View document
            </Button>
            <Typography color="textSecondary">{ld.description}</Typography>

            {needsSigningInThisPass(ld.id) && (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!consentToSign}
                    checked={acceptances[ld.id] ?? false}
                    onChange={() => {
                      if (acceptances[ld.id]) {
                        unaccept(ld.id);
                      } else {
                        accept(ld.id);
                      }
                    }}
                    name={`document-${ld.id}-accept`}
                    color="primary"
                  />
                }
                label={
                  consentToSign
                    ? 'Accept'
                    : 'Please confirm your consent to sign electronically first'
                }
              />
            )}
          </CardContent>
          {docToShow === ld.id && (
            <PdfViewer document={ld} onClose={() => setDocToShow(0)} />
          )}
        </Card>
      ))}
      <Divider className={classes.divider} />
      {initialUnsignedCount > 0 && (
        <Button
          disabled={!(allConsented && consentToSign)}
          bStyle="contained"
          fullWidth
          onClick={() => {
            const docIds = getNumberKeys(acceptances).map(i => parseInt(i, 10));

            Promise.all(docIds.map(approveDocument(api)))
              .then(() => {
                const newUD = unsignedDocs.filter(d => !docIds.includes(d.id));
                setUnsignedDocs(newUD);
              })
              .then(proceedToNextStep);
          }}
        >
          {allConsented && consentToSign
            ? `Sign Document${requiredDocCount > 1 ? 's' : ''} and proceed`
            : `Please sign ${
                requiredDocCount > 1 ? 'all documents' : 'the document'
              } first`}
        </Button>
      )}
    </>
  );
}
