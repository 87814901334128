import {Optional} from '@ahanapediatrics/ahana-fp';
import {
  getEmptyUpdater,
  Updatable,
} from '@src/components/shared/PatientDashboard/identifyLastUpdater';
import {SCPChangeRequest} from '@src/models';
import {getQualification} from '@src/util/provider/getQualification';

/**
 *
 * This hook accepts an `SCPChangeRequest` and returns a succinct object with a few properties:
 * time, Person, and qualifications of the updater.
 *
 * Updater can be a Professional or a Non-Professional and this will handle both cases.
 *
 * @param changeRequest
 */
export const useLastUpdatedGetter = (
  changeRequest: Optional<SCPChangeRequest>,
): Updatable => {
  const empty = getEmptyUpdater();
  if (!changeRequest.isPresent()) {
    return empty;
  }

  const updatedAt = changeRequest.map(p => p.updatedAt);

  if (!updatedAt.isPresent()) {
    return empty;
  }

  const lastUpdatedBy = changeRequest.map(p => p.requester);

  const qualification = lastUpdatedBy.isPresent()
    ? getQualification(lastUpdatedBy.get())
    : '';

  return {
    updatedAt,
    lastUpdatedBy,
    qualification: Optional.of(qualification),
  };
};
