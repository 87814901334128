import {useCallback, useEffect, useRef, useState} from 'react';
import {getViewportHeight} from '@src/util/browserTools/viewport';

export const useViewportHeight = () => {
  const [viewportHeight, setViewportHeight] = useState(getViewportHeight());

  const timeoutId = useRef(0);

  const resizeHandler = useCallback(() => {
    const {current} = timeoutId;
    if (current !== 0) {
      clearTimeout(current);
    }

    timeoutId.current = window.setTimeout(() => {
      setViewportHeight(getViewportHeight());
    }, 300);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  return viewportHeight;
};
