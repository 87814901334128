import React from 'react';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';

type Props = {
  editMode: boolean;
};
export function CoverageInstructions({editMode}: Props) {
  return (
    <>
      <ParagraphText>
        Thank you for making yourself available to our patients.
      </ParagraphText>
      {editMode ? (
        <ParagraphText>
          Please fill in the details of when you can start your coverage and for
          how long
        </ParagraphText>
      ) : (
        <ParagraphText>Please update your coverage.</ParagraphText>
      )}
      <ParagraphText>
        {' '}
        You won't be able set the start time to before the current time. In
        addition, the end of the coverage period will have to be after the
        current time.
      </ParagraphText>
    </>
  );
}
