import {addHours, addMinutes, isAfter} from 'date-fns';
import {
  validate,
  Errors,
  PopulatedValues,
  isPopulatedValues,
} from '../../functions';
import {SinglePeriodValues} from '.';
import {AppAPI} from '@src/api/AppAPI';
import {
  CreatePopulatedPeriodRequest,
  UpdateOnCallPeriodRequest,
  CreateUnpopulatedPeriodRequest,
} from '@src/api/OnCallAPI';
import {OnCallPeriodType} from '@src/models';
import {mergeDateAndTime} from '@src/util/dateManipulation/mergeDateAndTime';

interface Setters {
  setErrors: (e: Errors<SinglePeriodValues>) => void;
  setErrorMessage: (message: string) => void;
}

export const onSave = (api: AppAPI) => (
  values: SinglePeriodValues,
  {setErrors, setErrorMessage}: Setters,
) => {
  const now = new Date();
  setErrorMessage('');
  const errors = validate(values, setErrorMessage);
  setErrors(errors);
  if (Object.keys(errors).length > 0) {
    return Promise.reject();
  }
  const start = mergeDateAndTime(values.startDate, values.startTime);
  const end = addMinutes(addHours(start, values.hours), values.minutes);
  if (isAfter(now, end)) {
    setErrors({
      hours: 'Coverage period cannot end before current time',
      minutes: 'Coverage period cannot end before current time',
    });
    setErrorMessage('Coverage period cannot end before current time');
    return Promise.reject();
  }

  if (values.periodId === 0) {
    if (isPopulatedValues(values)) {
      return savePopulated(api)(values, {start, end});
    } else {
      return saveUnpopulated(api)(values, {start, end});
    }
  } else {
    const request: UpdateOnCallPeriodRequest = {
      start,
      end,
      selectedCallPools: values.selectedCallPools,
    };

    return api
      .onCallPeriods(values.periodId)
      .update(request)
      .then();
  }
};

const savePopulated = (api: AppAPI) => (
  values: PopulatedValues<SinglePeriodValues>,
  {start, end}: {start: Date; end: Date},
) => {
  const request: CreatePopulatedPeriodRequest = {
    type: OnCallPeriodType.Voluntary,
    start,
    end,
    selectedCallPools: values.selectedCallPools,
    providerId: values.providerId,
  };

  return api
    .onCallPeriods()
    .createPopulated(request)
    .then();
};

const saveUnpopulated = (api: AppAPI) => (
  values: SinglePeriodValues,
  {start, end}: {start: Date; end: Date},
) => {
  const request: CreateUnpopulatedPeriodRequest = {
    type: OnCallPeriodType.Primary,
    start,
    end,
    selectedCallPools: values.selectedCallPools,
  };

  return api.onCallPeriods().createUnpopulated(request);
};
