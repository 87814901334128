import styled from 'styled-components';
import {Button} from '../../ui/form';
import {lg, md, primary, primaryLight, sm, xs, xxs} from '../../ui/theme';

export const NPSInstructions = styled.div`
  margin-top: ${sm};
  width: 100%;
`;

export const NPSSentiment = styled.div`
  padding: ${xs};
  margin-bottom: ${sm};
  margin-top: ${sm};
  width: 100%;
  background-color: ${primaryLight};
  border: solid thin ${primary};
  border-radius: ${xxs};
`;

export const CenteredColumnBlock = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const SurveyBody = styled.div`
  height: 100%;
  line-height: 1.5em;
  overflow-y: scroll;
  padding: 0 ${xxs};
  width: 100%;
`;

export const VisitContainer = styled(CenteredColumnBlock)`
  margin: ${sm} 0 ${md};
`;

export const StyledButton = styled(Button)`
  margin: 0 ${sm};
`;

export const YesNoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: ${sm};
  justify-content: space-between;
  width: 100%;
`;

export const NPSContainer = styled(CenteredColumnBlock)`
  margin: ${sm} 0 ${lg};
`;

export const SurveyBlock = styled.div<{show: boolean}>`
  display: ${props => (props.show ? 'block' : 'none')};
`;
