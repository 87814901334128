import {Optional} from '@ahanapediatrics/ahana-fp';
import * as React from 'react';
import {useState} from 'react';
import {UserType} from '../../../models';
import {AsyncActionButton, Button, TextInput, YesOrNo} from '../../ui/form';
import {NPSInput} from '../../ui/form/NPSInput';
import {SubLabel} from '../../ui/layout';
import {Modal} from '../../ui/layout/Modal';
import {
  NPSContainer,
  NPSInstructions,
  NPSSentiment,
  SurveyBlock,
  VisitContainer,
} from './layout';
import {useApi} from '@src/api/useApi';

type Props = {
  onLogout: (didSurvey: boolean) => unknown;
  show: boolean;
  userId: number;
  userType: UserType;
  setShowLogoutModal: (v: boolean) => void;
};

enum SurveyBlockState {
  Initial,
  Survey,
}

export const ExitSurveyModal: React.FunctionComponent<Props> = ({
  onLogout,
  show,
  userId,
  userType,
  setShowLogoutModal,
}) => {
  const api = useApi();
  const [npsScore, setNPS] = useState<number | undefined>();
  const [comments, setComments] = useState('');
  const [visitOccurred, setVisitOccurred] = useState(Optional.empty<boolean>());
  const [panel, setPanel] = useState(SurveyBlockState.Initial);
  const [submittingSurvey, setSubmittingSurvey] = useState(false);

  const modalActions = (
    <>
      {panel === SurveyBlockState.Initial && (
        <>
          <Button
            bStyle="outlined"
            onClick={() => {
              onLogout(false);
            }}
          >
            Logout
          </Button>
          <Button
            bStyle="contained"
            onClick={() => {
              setPanel(SurveyBlockState.Survey);
            }}
          >
            I'll answer the questions
          </Button>
        </>
      )}

      {panel === SurveyBlockState.Survey && (
        <>
          <Button onClick={() => onLogout(false)} bStyle="outlined">
            Logout
          </Button>
          <AsyncActionButton
            actionInProgress={submittingSurvey}
            actionWord={'Submit and Logout'}
            bStyle="contained"
            onClick={() => {
              setSubmittingSurvey(true);

              api
                .user(userId)
                .submitSurvey({
                  npsScore,
                  comments,
                  visitOccurred: visitOccurred.orNothing(),
                })
                .finally(() => {
                  setSubmittingSurvey(false);
                  onLogout(true);
                });
            }}
          />
        </>
      )}
    </>
  );

  return (
    <Modal
      show={show}
      onClose={() => {
        setShowLogoutModal(false);
        setPanel(SurveyBlockState.Initial);
      }}
      title="Thank you for using Refyne Connected Care today!"
      modalActions={modalActions}
    >
      <SurveyBlock show={panel === SurveyBlockState.Initial}>
        Before you log out, we have a couple of questions about how things went
        today. It should take less than a minute to answer them.
      </SurveyBlock>
      <SurveyBlock show={panel === SurveyBlockState.Survey}>
        <SubLabel>Just a few questions...</SubLabel>
        <VisitContainer>
          Were you in a visit with a{' '}
          {userType === UserType.Professional ? 'patient' : 'doctor'} during
          this time on Refyne Connected Care?
          <YesOrNo
            value={visitOccurred}
            setValue={v => {
              setVisitOccurred(Optional.of(v));
            }}
          />
        </VisitContainer>

        <NPSContainer>
          <NPSInstructions>
            On a scale from 0 to 10, how much do you agree with the following
            statement?
          </NPSInstructions>
          <NPSSentiment>
            I would recommend Refyne Connected Care to other{' '}
            {userType === UserType.Guardian ? 'patients' : 'providers'}!
          </NPSSentiment>
          <NPSInput name={'nps'} value={npsScore} onChange={setNPS} />
        </NPSContainer>
        <TextInput
          name={'comments'}
          value={comments}
          lines={3}
          title="Why or why not? Please tell us more!"
          onChange={e => setComments(e.target.value)}
          placeholder={'Let us know what you think here...'}
        />
      </SurveyBlock>
    </Modal>
  );
};
