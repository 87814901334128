import {Grid} from '@material-ui/core';
import * as React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import styled from 'styled-components';
import {SimplePatientCard} from '../SimplePatientCard';
import {PatientOverviewHeader, PatientOverviewStyled} from './layout';
import {ProviderOnlySections} from './ProviderOnlySections';
import {useApi} from '@src/api/useApi';
import {Button} from '@src/components/ui/form/Button/index';
import {sm} from '@src/components/ui/theme';
import {useResources, useUser} from '@src/hooks';
import {
  LonelyPatient,
  LonelyVisit,
  MedicalHistory,
  PatientRelationship,
  UserType,
} from '@src/models';
import {ReduxState} from '@src/store';
import {allLoaded} from '@src/util/apiHelpers/allLoaded';

const StyledButton = styled(Button)`
  margin-left: ${sm};
`;

type Props = {
  patient: LonelyPatient;
  updatePatient: (p: LonelyPatient) => unknown;
  inExam?: boolean;
};

export function PatientOverview({inExam, patient, updatePatient}: Props) {
  const api = useApi();
  const history = useHistory();
  const [, userType] = useUser();

  const [visits, , setVisits] = useResources<LonelyVisit>(
    () =>
      api
        .patient(patient.id)
        .getActiveVisits()
        .then(as => as.map(LonelyVisit.fromSimpleVisit(patient))),
    [api, patient],
  );

  const [guardianRelationships] = useResources<PatientRelationship>(
    () => api.patient(patient.id).getGuardianships(),
    [api, patient],
  );

  const hairChecked = useSelector(({media}: ReduxState) => media.hairChecked);

  const [selfRelationship] = useResources<PatientRelationship | null>(
    () =>
      api
        .patient(patient.id)
        .findSelfRelationship()
        .catch(e => {
          console.warn(e);
          return null;
        }),
    [api, patient],
  );
  const [currentMedicalHistory, reloadMedicalHistory] = useResources<
    MedicalHistory
  >(() => api.patient(patient.id).getCurrentMedicalHistory(), [api, patient]);

  const guardianRelationshipsReady = allLoaded(
    selfRelationship,
    guardianRelationships,
  );

  const needsGuardianOnlySection =
    userType === UserType.Guardian && !inExam && guardianRelationshipsReady;

  const needsProviderOnlySection =
    userType === UserType.Professional && !inExam;

  return (
    <PatientOverviewStyled>
      <SimplePatientCard
        patient={patient}
        currentMedicalHistory={currentMedicalHistory}
        inExam={!!inExam}
        updatePatient={p => {
          updatePatient(p);
          reloadMedicalHistory();
        }}
      />

      {needsGuardianOnlySection && (
        <PatientOverviewHeader>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <StyledButton
                disabled={inExam}
                bStyle="primary"
                onClick={() => {
                  const patientId = patient.id;
                  history.push(`/files-overview/${patientId}`);
                }}
              >
                View Files
              </StyledButton>
            </Grid>
          </Grid>
        </PatientOverviewHeader>
      )}

      {needsProviderOnlySection && (
        <ProviderOnlySections
          hairChecked={hairChecked}
          visits={visits}
          setVisits={setVisits}
          patient={patient}
        />
      )}
    </PatientOverviewStyled>
  );
}
