import {makeStyles} from '@material-ui/core/styles';
import {md} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  // Result Icon
  pass: {
    color: theme.palette.success.main,
  },
  checking: {
    color: theme.palette.primary.main,
  },
  fail: {
    color: theme.palette.error.main,
  },
  conflict: {
    color: theme.palette.warning.main,
  },
  // Table
  tableCell: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  iconCell: {
    height: md,
  },
  blockRadioLabel: {
    display: 'block',
  },
}));
