import {Field} from './resultFunctions';
import {MedicalHistory} from '@src/models';

export type MedicalHistoryUIElement = {
  label: string;
  name: Field;
  patientOne: string;
  patientTwo: string;
};

export function getMedicalHistoryRow({
  patientOneHistory,
  patientTwoHistory,
}: {
  patientOneHistory: MedicalHistory | null;
  patientTwoHistory: MedicalHistory | null;
}): MedicalHistoryUIElement[] {
  if (patientOneHistory === null || patientTwoHistory === null) {
    return [];
  }

  const patientOneDetails = patientOneHistory.details;
  const patientTwoDetails = patientTwoHistory.details;

  return [
    {
      label: 'Allergies',
      name: 'allergies',
      patientOne: patientOneDetails.allergies || '—',
      patientTwo: patientTwoDetails.allergies || '—',
    },
    {
      label: 'Immunizations up to date?',
      name: 'immunizations_up_to_date',
      patientOne:
        patientOneDetails.immunizations_up_to_date === 'not_sure'
          ? '—'
          : patientOneDetails.immunizations_up_to_date,
      patientTwo:
        patientTwoDetails.immunizations_up_to_date === 'not_sure'
          ? '—'
          : patientTwoDetails.immunizations_up_to_date,
    },
    {
      label: 'Immunization comments',
      name: 'immunization_comments',
      patientOne: patientOneDetails.immunization_comments || '—',
      patientTwo: patientTwoDetails.immunization_comments || '—',
    },
    {
      label: 'Environment',
      name: 'environment',
      patientOne: patientOneDetails.environment || '—',
      patientTwo: patientTwoDetails.environment || '—',
    },
    {
      label: 'Past health issues',
      name: 'past_or_ongoing_health_issues',
      patientOne: patientOneDetails.past_or_ongoing_health_issues || '—',
      patientTwo: patientTwoDetails.past_or_ongoing_health_issues || '—',
    },
    {
      label: 'Surgeries',
      name: 'surgeries',
      patientOne: patientOneDetails.surgeries || '—',
      patientTwo: patientTwoDetails.surgeries || '—',
    },
    {
      label: 'Medications',
      name: 'medications',
      patientOne: patientOneDetails.medications || '—',
      patientTwo: patientTwoDetails.medications || '—',
    },
    {
      label: 'Family history',
      name: 'medical_history',
      patientOne: patientOneDetails.medical_history || '—',
      patientTwo: patientTwoDetails.medical_history || '—',
    },

    {
      label: 'Other',
      name: 'other',
      patientOne: patientOneDetails.other || '—',
      patientTwo: patientTwoDetails.other || '—',
    },
  ];
}
