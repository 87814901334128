import React from 'react';
import {FormLabel, Paper} from '@material-ui/core';
import {DateField} from '@src/models';
import {DateInput} from '@src/components/ui/form';

interface Props {
  field: DateField;
  onChange?: (value: Date | null) => unknown;
  readonly?: boolean;
  value: Date | null;
}

export default function FormDateInput({
  field,
  onChange,
  readonly,
  value,
}: Props) {
  const id = `form-date-input-${field.id}`;

  return (
    <Paper style={{padding: '1em'}}>
      <FormLabel component="div" required={field.required} disabled={readonly}>
        {/*
         * Please note the subset of HTML tags considered valid within a <label> tag.
         * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label#technical_summary
         *
         * > Phrasing content, but no descendant label elements.
         * > No labelable elements other than the labeled control are allowed.
         *
         * https://developer.mozilla.org/en-US/docs/Web/HTML/Content_categories#phrasing_content
         */}
        <label
          className="inline-block"
          htmlFor={id}
          dangerouslySetInnerHTML={{__html: field.description}}
        />
      </FormLabel>
      <DateInput
        id={id}
        name={field.name}
        value={value}
        onChange={onChange}
        disabled={readonly}
      />
    </Paper>
  );
}
