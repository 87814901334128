import Alert from '@material-ui/lab/Alert';
import React from 'react';
import {Modal} from '../../../ui/layout/Modal';
import VisitFiles from '../RoomControls/VisitFiles';
import {useUser} from '@src/hooks/useUser';
import {AppFile, UserType} from '@src/models';

type Props = {
  showModal: boolean;
  setShowModal: (v: boolean) => unknown;
  files: AppFile[];
};
export function ViewFilesModal({showModal, setShowModal, files}: Props) {
  const [, userType] = useUser();
  return (
    <Modal
      show={showModal}
      onClose={() => setShowModal(false)}
      title="Visit Files"
    >
      {userType === UserType.Professional && (
        <Alert severity="warning">
          Files may contain personal health information (PHI). Healthcare
          Providers must treat all downloaded data as HIPAA-protected PHI,
          secure it appropriately during use, and dispose of it appropriately
          after use.
        </Alert>
      )}
      <VisitFiles files={files} />
    </Modal>
  );
}
