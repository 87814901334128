import React, {ReactNode} from 'react';
import {FormLabel} from '@material-ui/core';

type Props = {
  children: ReactNode | null;
};

export function LightParagraphText({children}: Props) {
  return <FormLabel component="div">{children}</FormLabel>;
}
