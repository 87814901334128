import React, {ReactNode} from 'react';
import {Paper} from '@material-ui/core';
import {useComparerStyles} from './comparerStyles';

type Props = {
  children: ReactNode;
};

export function ComparerMessage({children}: Props) {
  const classes = useComparerStyles();

  return (
    <Paper variant="outlined" className={classes.messageContainer}>
      {children}
    </Paper>
  );
}
