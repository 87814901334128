import React, {useState, ReactNode} from 'react';
import HelpIcon from '@material-ui/icons/Help';
import {ClickAwayListener, Tooltip, makeStyles, Grid} from '@material-ui/core';
import {Control, Controller, DeepMap, FieldError} from 'react-hook-form';
import {TextInput, SelectInput} from '@src/components/ui/form';
import {yObject, yString} from '@src/schema/types';
import {
  MedicalHistoryUIItem,
  getMedicalHistoryRenderList,
} from '@src/components/shared/forms/medicalHistoryFunctions';
import {reference} from '@src/util/stringManipulation/languageHelpers';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.main,
    cursor: 'default',
    fontSize: 'inherit',
  },
}));
export const medicalHistorySchema = yObject<MedicalHistoryValues>({
  medications: yString.default(''),
  allergies: yString.default(''),
  immunizations_up_to_date: yString.default(''),
  immunization_comments: yString.default(''),
  environment: yString.default(''),
  past_or_ongoing_health_issues: yString.default(''),
  surgeries: yString.default(''),
  medical_history: yString.default(''),
  other: yString.default(''),
});

export type MedicalHistoryValues = {
  medications: string;
  allergies: string;
  immunizations_up_to_date: string;
  immunization_comments: string;
  environment: string;
  past_or_ongoing_health_issues: string;
  surgeries: string;
  medical_history: string;
  other: string;
};

type Props = {
  control: Control<MedicalHistoryValues>;
  errors: DeepMap<MedicalHistoryValues, FieldError>;
  hasScp: boolean;
  patientName: string;
  patientIsUser: boolean;
};

const immunizationOptions = () => [
  {value: 'yes', label: 'Yes'},
  {value: 'not_sure', label: "I don't know"},
  {value: 'no', label: 'No'},
];

export function PatientMedicalHistoryForm({
  control,
  errors,
  hasScp,
  patientName,
  patientIsUser,
}: Props) {
  const classes = useStyles();
  const [tooltip, setTooltip] = useState<string | null>(null);
  const renderInput = ({
    name,
    node,
    title,
    instructions,
  }: MedicalHistoryUIItem): ReactNode | null => {
    return (
      <Controller
        key={name}
        control={control}
        name={name}
        render={(field, state) => {
          if (node) {
            return node(field, state);
          }
          const {value, name: cName, onChange, onBlur} = field;
          const {isTouched} = state;
          return (
            <Grid item xs={12}>
              <TextInput
                value={value}
                name={cName}
                autoExpand={true}
                title={title}
                onChange={onChange}
                onBlur={onBlur}
                error={errors[name]?.message}
                touched={isTouched}
                endAdornment={
                  <ClickAwayListener
                    onClickAway={() => {
                      if (tooltip === name) {
                        setTooltip(null);
                      }
                    }}
                  >
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      open={tooltip === name}
                      onOpen={() => setTooltip(name)}
                      onClose={() => setTooltip(null)}
                      title={instructions}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                    >
                      <HelpIcon
                        style={{cursor: 'pointer'}}
                        onClick={e => {
                          setTooltip(tooltip === name ? null : name);
                        }}
                        className={classes.icon}
                      />
                    </Tooltip>
                  </ClickAwayListener>
                }
              />
            </Grid>
          );
        }}
      />
    );
  };

  const rf = reference(patientName, patientIsUser);

  return (
    <Grid container direction="column">
      {getMedicalHistoryRenderList({
        patientName,
        excludeMeds: hasScp,
        patientIsUser,
        immunizationComponent: ({value, name: cName, onChange}) => (
          <SelectInput
            name={cName}
            value={value}
            placeholder="Immunizations up to date?"
            options={immunizationOptions()}
            title={`Are ${rf('poss')} immunizations up to date?`}
            onChange={onChange}
          />
        ),
      }).map(renderInput)}
    </Grid>
  );
}
