import React from 'react';
import {Grid} from '@material-ui/core';
import {CenteredText} from '../VideoChat/ExamRoomModals/layout';
import {Button} from '@src/components/ui/form';
import {Modal} from '@src/components/ui/layout/Modal';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';

type Props = {
  showModal: boolean;
  returnToPatient: () => void;
  onEndVisit: () => void;
};

export const EndSessionModal = ({
  onEndVisit,
  returnToPatient,
  showModal,
}: Props) => {
  return (
    <Modal show={showModal} title="You're about to complete this visit.">
      <CenteredText>
        <ParagraphText>Are you ready to complete this visit?</ParagraphText>
        <ParagraphText>
          If you End the visit, it will no longer appear in the Waiting Room or
          on a Patient's overview.
        </ParagraphText>
      </CenteredText>

      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Button block onClick={onEndVisit} bStyle="primary">
            End this visit
          </Button>
        </Grid>
        <Grid item>
          <Button block onClick={returnToPatient} bStyle="secondary">
            Return to patient
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
