import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {format, differenceInDays} from 'date-fns';
import React from 'react';
import {Skeleton} from '@material-ui/lab';
import {
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import {useHistory} from 'react-router';
import {useStyles} from '../layout';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';
import {useUser} from '@src/hooks/useUser';
import {OnCallPeriod, OnCallPeriodType} from '@src/models';
import {getCallPoolName} from '@src/util/callPools/getCallPoolName';
import {isNothing} from '@src/util/typeTests';
import {byStartAsc} from '@src/util/sorters/byStart';

type Props = {
  onCallPeriods: AsyncData<OnCallPeriod>;
  managePeriodsProps?: {
    setPeriodToEdit: (p: OnCallPeriod) => unknown;
    setShowOneOffModal: (v: boolean) => unknown;
    onDeleteOnCallPeriod: (period: OnCallPeriod) => unknown;
  };
};

const PeriodTypeLabels = {
  [OnCallPeriodType.Primary]: 'Scheduled',
  [OnCallPeriodType.Backup]: 'Scheduled',
  [OnCallPeriodType.Voluntary]: 'Ad Hoc',
};

export function OnCallTable({onCallPeriods, managePeriodsProps}: Props) {
  const classes = useStyles();
  const [, userType] = useUser();
  const history = useHistory();

  if (!onCallPeriods.isLoaded()) {
    return <Skeleton />;
  }

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Start</TableCell>
            <TableCell>End</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Provider Group(s)</TableCell>
            {!isNothing(managePeriodsProps) && (
              <>
                <TableCell />
                <TableCell />
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!onCallPeriods.isLoaded() && (
            <TableRow>
              <TableCell>
                Loading...
                <FontAwesomeIcon icon={'spinner'} spin />
              </TableCell>
            </TableRow>
          )}
          {onCallPeriods.isLoaded() && onCallPeriods.isEmpty() && (
            <TableRow>
              <TableCell colSpan={4}>No periods scheduled</TableCell>
            </TableRow>
          )}
          {onCallPeriods.isLoaded() &&
            !onCallPeriods.isEmpty() &&
            onCallPeriods.sort(byStartAsc).map(period => {
              const dayDifference = differenceInDays(period.end, period.start);
              return (
                <TableRow key={period.id}>
                  <TableCell>
                    {format(period.start, 'eee, MMM do, hh:mm aaaa')}
                  </TableCell>
                  <TableCell>
                    {format(period.end, 'hh:mm aaaa')}
                    {dayDifference > 0 && (
                      <>
                        {' '}
                        (+ {dayDifference} day{dayDifference === 1 ? '' : 's'})
                      </>
                    )}
                  </TableCell>
                  <TableCell>{PeriodTypeLabels[period.type]}</TableCell>
                  <TableCell>
                    {period.callPools.length === 0
                      ? '-'
                      : period.callPools.map(cp => (
                          <Chip
                            key={cp.id}
                            label={getCallPoolName(cp, userType)}
                            className={classes.chip}
                            onClick={() => {
                              history.push(`/provider-group/${cp.id}`);
                            }}
                          />
                        ))}
                  </TableCell>
                  {!isNothing(managePeriodsProps) && (
                    <TableCell className={classes.shiftsTableIcon}>
                      {period.type === OnCallPeriodType.Voluntary && (
                        <ClickableIcon
                          size="lg"
                          icon={'times'}
                          onClick={() => {
                            managePeriodsProps.onDeleteOnCallPeriod(period);
                          }}
                        />
                      )}
                    </TableCell>
                  )}
                  {!isNothing(managePeriodsProps) && (
                    <TableCell className={classes.shiftsTableIcon}>
                      {period.type === OnCallPeriodType.Voluntary && (
                        <ClickableIcon
                          size="lg"
                          icon={'pencil'}
                          onClick={() => {
                            const {
                              setPeriodToEdit,
                              setShowOneOffModal,
                            } = managePeriodsProps;

                            setPeriodToEdit(period);
                            setShowOneOffModal(true);
                          }}
                        />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
