import React from 'react';
import clsx from 'clsx';
import {uniqBy} from 'lodash';
import {Grid} from '@material-ui/core';
import {usePageContext} from '..';
import {DaysOfWeekHeader} from './DaysOfWeekHeader';
import {AddPeriods} from './AddPeriods';
import {Day} from './Day';
import {useStyles} from './layout';
import {isSomething} from '@src/util/typeTests';
import {SinglePeriodModal} from '@src/components/providerSide/OnCallPeriodModals';
import {PageLoadingIndeterminate} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoadingIndeterminate';

export function MonthTable() {
  const {
    rosterPageGroup,
    monthTable: {
      arrayOfWeeks,
      setShowSinglePeriodModal,
      showSinglePeriodModal,
      isDayInMonth,
      reloadPeriodsMappedToDaysOfSelectedMonth,
      periodsMappedToDaysOfSelectedMonth,
    },
    managedGroups,
    addPeriodModal: {date},
  } = usePageContext();

  const actualRosterPageGroup = rosterPageGroup.getOptional().orNothing();
  const actualManagedGroups = managedGroups.getAllOptional().orElse([]);

  const classes = useStyles();
  const loaded = periodsMappedToDaysOfSelectedMonth.isLoaded();

  if (!actualRosterPageGroup) {
    return null;
  }

  const {children} = actualRosterPageGroup;
  const groupsProviderCanAddToPeriods = uniqBy(
    [actualRosterPageGroup, ...children.orElse([]), ...actualManagedGroups],
    'id',
  );

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <DaysOfWeekHeader
          className={clsx(classes.dayDate, classes.dayHeader)}
        />
        <PageLoadingIndeterminate active={!loaded}>
          {arrayOfWeeks.map(week => (
            <Grid item xs={12} key={week[0]}>
              <Grid container direction="row" alignItems="stretch" spacing={1}>
                {week.map(day => (
                  <Grid item xs key={day}>
                    {isDayInMonth(day) ? <Day day={day} /> : null}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </PageLoadingIndeterminate>
      </Grid>
      {loaded && (
        <>
          {isSomething(actualRosterPageGroup) && (
            <AddPeriods
              groupsProviderCanAddToPeriods={groupsProviderCanAddToPeriods}
              providerGroup={actualRosterPageGroup}
              onCreateOnCallPeriods={reloadPeriodsMappedToDaysOfSelectedMonth}
            />
          )}
          {showSinglePeriodModal && (
            <SinglePeriodModal
              forDate={date}
              purpose="Shift"
              preselectedProviderGroups={[actualRosterPageGroup]}
              onCreateOnCallPeriods={reloadPeriodsMappedToDaysOfSelectedMonth}
              availableGroups={groupsProviderCanAddToPeriods}
              show
              onClose={() => setShowSinglePeriodModal(false)}
              requiredGroupsSelections={[actualRosterPageGroup]}
            />
          )}
        </>
      )}
    </>
  );
}
