import {Optional} from '@ahanapediatrics/ahana-fp';
import {Mergeable} from '.';
import {Patient} from '@src/models';
import {isNothing} from '@src/util/typeTests';

export const isPaymentInfoSectionMergeable = ({
  p1,
  p2,
}: {
  p1: Optional<Patient>;
  p2: Optional<Patient>;
}): Mergeable => {
  const p1PaymentInfo = p1.map(p => p.paymentInformation).orNull();
  const p2PaymentInfo = p2.map(p => p.paymentInformation).orNull();

  if (!isNothing(p1PaymentInfo) || !isNothing(p2PaymentInfo)) {
    return 'FAIL';
  } else {
    return 'PASS';
  }
};
