import {getDaysInMonth, startOfMonth, getDay} from 'date-fns';
import {useMemo} from 'react';

/*
 * A list of dates of a month mapping to days of the week, starting on Sunday.
 *
 * So, for a week at the start of a Month, starting on Tuesday, you'd have
 *   S  M  T  W  T  F  S
 * [-1, 0, 1, 2, 3, 4, 5]
 *
 * Numbers less than 1 mean that the days fall outside of the month.
 *
 * Similarly, numbers that exceed the number of days in the corresponding month
 * fall outside of that month
 */
export type Week = [number, number, number, number, number, number, number];

/**
 * Given a month, this will create an array of Weeks that represent
 * how each date of that month falls on a given day.
 *
 * e.g
 * [
 *   [-1,  0,  1,  2,  3,  4,  5],
 *   [ 6,  7,  8,  9, 10, 11, 12],
 *   [13, 14, 15, 16, 17, 18, 19],
 *   [20, 21, 22, 23, 24, 25, 26],
 *   [27, 28, 29, 30, 31, 32, 33]
 * ]
 *
 * @param month
 */

export const useArrayOfWeeksForMonthBuilder = (month: Date): Week[] => {
  const monthStart = startOfMonth(month);

  return useMemo(() => {
    const dayOfStart = getDay(monthStart);
    const days = getDaysInMonth(month);

    return Array(Math.ceil((days + dayOfStart) / 7))
      .fill(0)
      .map<Week>(
        (_, i) =>
          Array(7)
            .fill(0)
            .map((__, j) => i * 7 + j - dayOfStart + 1) as Week,
      );
  }, [monthStart]);
};
