import React, {
  createContext,
  useReducer,
  ReactNode,
  useCallback,
  useContext,
  Dispatch,
} from 'react';
import {not} from '@src/util/predicateHelpers/not';

type CardCondensedState = Record<number, boolean>;

type CardAction =
  | {
      type: 'INITIALIZE';
      state: CardCondensedState;
    }
  | {
      type: 'COLLAPSE';
      patientId: number;
    }
  | {
      type: 'EXPAND';
      patientId: number;
    }
  | {
      type: 'LOAD_PATIENTS';
      patientIds: number[];
    }
  | {type: 'COLLAPSE_ALL'}
  | {type: 'EXPAND_ALL'};

const cardContext = createContext<CardCondensedState>({});
const dispatchContext = createContext<React.Dispatch<CardAction>>(() => {});
const {Provider: CardStateProvider} = cardContext;
const {Provider: CardDispatchProvider} = dispatchContext;

const set = (b: boolean) => (st: CardCondensedState, id: number | string) => ({
  ...st,
  [id]: b,
});

const reducer = (
  ccState: CardCondensedState,
  action: CardAction,
): CardCondensedState => {
  switch (action.type) {
    case 'INITIALIZE': {
      return action.state;
    }
    case 'COLLAPSE': {
      return set(true)(ccState, action.patientId);
    }
    case 'EXPAND': {
      return set(false)(ccState, action.patientId);
    }
    case 'LOAD_PATIENTS': {
      return action.patientIds.reduce(set(true), {});
    }
    case 'COLLAPSE_ALL': {
      return Object.keys(ccState).reduce(set(true), {});
    }
    case 'EXPAND_ALL': {
      return Object.keys(ccState).reduce(set(false), {});
    }
    default:
      return ccState;
  }
};

type Props = {
  children: ReactNode;
};
/**
 * This is a React Provider that holds User state
 *
 */
export function CondensedCardProvider({children}: Props) {
  const [state, dispatch] = useReducer(reducer, {});

  return (
    <CardDispatchProvider value={dispatch}>
      <CardStateProvider value={state}>{children}</CardStateProvider>
    </CardDispatchProvider>
  );
}

const useCardActions = (cardDispatch: Dispatch<CardAction>) => {
  const initialize = useCallback(
    (state: CardCondensedState) => {
      cardDispatch({type: 'INITIALIZE', state});
    },
    [cardDispatch],
  );
  const collapse = useCallback(
    (patientId: number) => cardDispatch({type: 'COLLAPSE', patientId}),
    [cardDispatch],
  );
  const expand = useCallback(
    (patientId: number) => cardDispatch({type: 'EXPAND', patientId}),
    [cardDispatch],
  );
  const loadPatients = useCallback(
    (patientIds: number[]) => cardDispatch({type: 'LOAD_PATIENTS', patientIds}),
    [cardDispatch],
  );
  const collapseAll = useCallback(() => cardDispatch({type: 'COLLAPSE_ALL'}), [
    cardDispatch,
  ]);
  const expandAll = useCallback(() => cardDispatch({type: 'EXPAND_ALL'}), [
    cardDispatch,
  ]);

  return {initialize, collapse, expand, loadPatients, collapseAll, expandAll};
};

export const useCondensedCards = () => {
  const cardState = useContext(cardContext);
  const cardDispatch = useContext(dispatchContext);

  const {collapse, ...cardActions} = useCardActions(cardDispatch);

  const isCollapsed = useCallback(
    (patientId: number | string): boolean => {
      return cardState[+patientId] ?? true;
    },
    [cardState],
  );

  const allExpanded = useCallback(
    () => Object.keys(cardState).every(not(isCollapsed)),
    [cardState, isCollapsed],
  );

  const allCollapsed = useCallback(
    () => Object.keys(cardState).every(isCollapsed),
    [cardState, isCollapsed],
  );

  return {allExpanded, allCollapsed, isCollapsed, collapse, ...cardActions};
};
