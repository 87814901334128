import clsx from 'clsx';
import React, {useState, useCallback, useMemo} from 'react';
import {Grid} from '@material-ui/core';
import {useStyles} from './layout';
import {Button, SelectInput, SelectOption} from '@src/components/ui/form';
import {Modal} from '@src/components/ui/layout/Modal';
import {PromiseButton} from '@src/components/ui/form/PromiseButton';
import {SimpleVisit} from '@src/models';
import {providerAndQualification} from '@src/util/provider';
import {useUser, useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {UserId} from '@src/models/User';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {NAMES} from '@src/components/ui/layout/text/names';

type Props = {
  visit: SimpleVisit;
  onAssignProfessional: (id: ProviderDetailsId) => Promise<unknown>;
  returnToPatient: () => void;
  returnText?: string;
  showModal: boolean;
};

const NO_ASSIGNEE = 0 as ProviderDetailsId;

export const AssignProfessionalModal = ({
  onAssignProfessional,
  returnToPatient,
  returnText = 'Return to patient',
  showModal,
  visit,
}: Props) => {
  const api = useApi();
  const classes = useStyles();
  const {callPool, providerDetails} = visit;

  const [user] = useUser();
  const userId = user.getOptional().property('id', 0 as UserId);

  const [possibleAssignees] = useResources(
    () => api.callPool(callPool.property('id', 0)).getMembers(),
    [api, callPool],
  );

  const [assignee, setAssignee] = useState(
    providerDetails.property('id', NO_ASSIGNEE),
  );

  const [isAssigning, setIsAssigning] = useState(false);

  const assignedProfessional = providerDetails.map(p =>
    p.providerId === userId ? 'you' : providerAndQualification(p),
  );
  const currentAssigneeId = providerDetails.property('id', NO_ASSIGNEE);

  const currentUserProfessionalId = user
    .getOptional()
    .map(u => u.providerDetails)
    .property('id', NO_ASSIGNEE);

  const assignProfessional = useCallback(
    async (assigneeId: ProviderDetailsId) => {
      setIsAssigning(true);
      await onAssignProfessional(assigneeId);
      setIsAssigning(false);
    },
    [onAssignProfessional],
  );

  const assigneeOptions: SelectOption[] = useMemo(
    () => [
      {value: NO_ASSIGNEE, label: 'No assignee'},
      ...possibleAssignees
        .getAllOptional()
        .orElse([])
        .map(p => ({
          value: `${p.id}`,
          label: `${providerAndQualification(p)}${
            currentAssigneeId === p.id ? ' - currently assigned' : ''
          }`,
        })),
    ],
    [currentAssigneeId, possibleAssignees],
  );

  const assigneeIsInGroup = possibleAssignees
    .getAllOptional()
    .map(as => as.some(a => a.id === assignee))
    .orElse(false);

  const userIsCurrentAssignee = currentAssigneeId === currentUserProfessionalId;

  return (
    <Modal
      show={showModal}
      title="Who do you want to assign this Visit to?"
      onClose={returnToPatient}
      modalActions={
        <>
          <Button
            onClick={returnToPatient}
            bStyle="outlined"
            disabled={isAssigning}
          >
            {returnText}
          </Button>
          <PromiseButton
            disabled={assignee === currentAssigneeId || isAssigning}
            actionWord={
              assignee === currentAssigneeId
                ? 'No changes'
                : assignee !== 0
                ? 'Assign professional'
                : 'Remove assigned professional'
            }
            onClick={() => assignProfessional(assignee)}
          />
        </>
      }
    >
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          className={clsx(classes.modalRow, classes.modalInstructions)}
        >
          This visit is currently{' '}
          {assignedProfessional
            .map(p => `assigned to ${p}`)
            .orElse('unassigned')}
          .
        </Grid>
        <Grid
          item
          className={clsx(classes.modalRow, classes.modalInstructions)}
        >
          {userIsCurrentAssignee
            ? 'You are already assigned to this Visit, so you can close this modal'
            : 'You can choose to assign this to yourself'}{' '}
          or select a member of this Visit's {NAMES.group} from the list below.
        </Grid>
        <Grid
          item
          container
          spacing={1}
          className={clsx(classes.modalRow, classes.modalInstructions)}
        >
          <Grid item>
            <Button
              onClick={() => {
                setAssignee(currentUserProfessionalId);
                assignProfessional(currentUserProfessionalId);
              }}
              disabled={userIsCurrentAssignee}
            >
              I'll take this
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setAssignee(NO_ASSIGNEE);
                assignProfessional(NO_ASSIGNEE);
              }}
              disabled={assignee === 0}
            >
              Remove assignee
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          className={clsx(classes.modalRow, classes.modalInstructions)}
        >
          <SelectInput
            name="new-provider"
            value={
              assigneeIsInGroup || assignee === NO_ASSIGNEE
                ? `${assignee}`
                : undefined
            }
            placeholder=""
            title="Provider Group Members"
            options={assigneeOptions}
            onChange={e =>
              setAssignee(parseInt(e.target.value, 10) as ProviderDetailsId)
            }
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
