import {UserId} from '@src/models/User';
import {isSomething} from '@src/util/typeTests';

export interface PeriodToEdit {
  id: number;
  startDate: Date;
  startTime: Date;
  hours: number;
  minutes: number;
  selectedCallPools: number[];
}

export interface BasicRoster {
  providerId?: UserId;
  startDate: Date | null;
  startTime: string;
  hours: number;
  minutes: number;
  selectedCallPools: number[];
}

export type Errors<V> = {[field in keyof V]?: string};

export type PopulatedValues<V extends {providerId?: UserId}> = V & {
  providerId: UserId;
};

export const isPopulatedValues = <V extends {providerId?: UserId}>(
  v: V | PopulatedValues<V>,
): v is PopulatedValues<V> => isSomething(v.providerId) && v.providerId !== 0;
