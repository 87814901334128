import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid, Typography} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {CommunicationPreferencesQuestion} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/UpdateModal/CommunicationPreferencesQuestion';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {SCPChangeRequest, SharedCarePlan} from '@src/models';
import {AsyncActionButton, TextInput} from '@src/components/ui/form';
import {Modal} from '@src/components/ui/layout/Modal';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/styles';
import {formatProperty} from '@src/util/sharedCarePlan/changeRequest';
import {useApi} from '@src/api/useApi';
import {useOnUpdateChangeRequest} from '@src/hooks/scp/changeRequests/useOnUpdateChangeRequest';
import {flashSuccess} from '@src/components/shared/notifications/flash';

type Props = {
  propertyDescription?: string;
  property: keyof SharedCarePlan;
  onDone: () => void;
  show: boolean;
  changeRequest: Optional<SCPChangeRequest>;
  userOwnsNewestChange: boolean;
  updateMode: UpdateMode;
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export type UpdateMode = 'create' | 'edit' | null;

export const UpdateModal: React.FunctionComponent<Props> = ({
  onDone,
  show,
  propertyDescription = '',
  property,
  changeRequest,
  updateMode,
  userOwnsNewestChange,
}) => {
  const api = useApi();
  const classes = useStyles();
  const {reloadChangeRequests, scp, reloadScp} = usePageContext();

  const [valueUnderEdit, setValueUnderEdit] = useState(
    updateMode === 'edit'
      ? changeRequest.map(cr => cr.newValue).orElse('')
      : '',
  );

  const [saving, setSaving] = useState(false);
  const [showTextInput, setShowTextInput] = useState<boolean>(
    (property === 'communicationPreferences' && propertyDescription) ||
      property !== 'communicationPreferences'
      ? true
      : false,
  );

  const oScp = scp.getOptional();

  const runBeforeRequest = useCallback(() => {
    setSaving(true);
  }, []);

  const asyncRunAfterRequest = useCallback(async () => {
    return reloadScp()
      .then(reloadChangeRequests)
      .then(() => {
        flashSuccess(`${updateMode === 'edit' ? 'Updated' : 'Created'}`);
      });
  }, [reloadChangeRequests, reloadScp, updateMode]);

  const runFinally = useCallback(() => {
    setSaving(false);
    onDone();
  }, [onDone]);

  const runOnError = useCallback(() => {}, []);

  const onUpdateChangeRequest = useOnUpdateChangeRequest({
    scp: oScp,
    editedValue: valueUnderEdit,
    changeRequestId: changeRequest.property('id', 0),
    runBeforeRequest,
    asyncRunAfterRequest,
    runFinally,
    runOnError,
  });

  const onCreateChangeRequest = useCallback(() => {
    runBeforeRequest();

    api
      .scp(oScp.map(m => m.id).orElse(0))
      .updateScp({[property]: valueUnderEdit})
      .then(asyncRunAfterRequest)
      .finally(runFinally);
  }, [
    api,
    asyncRunAfterRequest,
    oScp,
    property,
    runBeforeRequest,
    runFinally,
    valueUnderEdit,
  ]);

  const onChangeTextInput = useCallback(
    e => setValueUnderEdit(e.target.value),
    [],
  );

  if (!show) return null;

  return (
    <Modal
      show={show}
      title={`Edit ${formatProperty(property)}`}
      onClose={onDone}
    >
      <Typography variant="caption" style={{fontStyle: 'italic'}}>
        When you save your edit, the doctor who manages this shared care plan
        will be notified so they can accept or deny the change.
      </Typography>

      {property === 'communicationPreferences' && (
        <CommunicationPreferencesQuestion
          showTextInput={showTextInput}
          setShowTextInput={setShowTextInput}
          setValueUnderEdit={setValueUnderEdit}
        />
      )}

      <Grid item xs={12} className={classes.gridItem}>
        {showTextInput && (
          <TextInput
            name="steps"
            value={valueUnderEdit}
            onChange={onChangeTextInput}
            lines={3}
            autoExpand={true}
            placeholder={propertyDescription}
          />
        )}

        <ButtonContainer>
          <AsyncActionButton
            actionInProgress={saving}
            bStyle="primary"
            bSize="small"
            actionWord="Save"
            onClick={
              updateMode === 'edit'
                ? onUpdateChangeRequest
                : onCreateChangeRequest
            }
          />
        </ButtonContainer>
      </Grid>
    </Modal>
  );
};
