import {Mergeable} from '../ExpandableSection/isMergeable';

type SectionsMergeableStateProperties =
  | 'basicDetailsMergeable'
  | 'medicalHistoryMergeable'
  | 'relationshipsMergeable'
  | 'primaryCareMergeable'
  | 'careTeamsMergeable'
  | 'filesMergeable'
  | 'visitsMergeable'
  | 'paymentInfoMergeable'
  | 'scpMergeable'
  | 'financiallyResponsibleMergeable';

type SectionsMergeableState = Record<
  SectionsMergeableStateProperties,
  Mergeable
>;

export const initialSectionsMergeableState: SectionsMergeableState = {
  basicDetailsMergeable: 'CHECKING',
  medicalHistoryMergeable: 'CHECKING',
  relationshipsMergeable: 'CHECKING',
  primaryCareMergeable: 'CHECKING',
  careTeamsMergeable: 'CHECKING',
  filesMergeable: 'CHECKING',
  visitsMergeable: 'CHECKING',
  paymentInfoMergeable: 'CHECKING',
  scpMergeable: 'CHECKING',
  financiallyResponsibleMergeable: 'CHECKING',
};

export function updatingSectionsMergeableReducer(
  state: SectionsMergeableState,
  action: {
    targetMergeable: Mergeable;
    property: SectionsMergeableStateProperties;
  },
) {
  const {property, targetMergeable} = action;
  return {...state, [property]: targetMergeable};
}
