import {FormikProps} from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import {TextInput} from '../ui/form';
import {ErrorMessage} from '../ui/form/ErrorMessage';
import {lg} from '../ui/theme';
import {rqString, yObject} from '@src/schema/types';

export type AddendumFormValues = {
  content: string;
};

export const schema = yObject({
  content: rqString('Please be sure to add something to this addendum'),
});

type Props = FormikProps<AddendumFormValues>;

const FormInstructions = styled.div`
  padding-bottom: ${lg};
`;

const AddendumForm: React.FunctionComponent<Props> = ({
  errors,
  handleChange,
  touched,
  values,
}) => (
  <div>
    <form>
      <FormInstructions>
        <p>
          This addendum will be attached to the notes from the visit you were
          just viewing.
        </p>
        <p>
          Once the expiration date has passed, we will keep a copy of this note
          for auditing purposes, but it will no longer be available to you or to
          other providers.
        </p>
      </FormInstructions>
      <div>
        <ErrorMessage
          message={touched.content && errors.content ? errors.content : ''}
        />
        <TextInput
          title="Addendum"
          name="content"
          autoExpand
          placeholder="Please add your additional notes here"
          value={values.content}
          onChange={handleChange}
        />
      </div>
    </form>
  </div>
);

export default AddendumForm;
