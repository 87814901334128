import {Box, makeStyles} from '@material-ui/core';
import {GridCellParams} from '@material-ui/data-grid';
import React from 'react';

const useStyles = makeStyles(theme => ({
  expandingBox: {
    lineHeight: 'initial',
    maxHeight: 'inherit',
    whiteSpace: 'initial',
    width: '100%',
    overflowX: 'hidden',
    overflow: 'auto',
  },
}));

export const ExpandingBox = ({value}: GridCellParams) => {
  const classes = useStyles();

  return <Box className={classes.expandingBox}>{value}</Box>;
};
