import styled from 'styled-components';
import {lg, md, primary, sm, xs} from '@src/components/ui/theme';

export const CCForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const CCFormInstructions = styled.div`
  margin-bottom: ${md};
  width: 100%;
`;

export const CCBox = styled.div`
  border-radius: ${xs};
  border: solid thin ${primary};
  margin: ${md};
  max-width: 90%;
  min-width: 300px;
  padding: ${md} ${md} ${lg};
`;

export const CardElementHolder = styled.div`
  border: solid thin #ccc;
  background-color: #fff;
  border-radius: ${xs};
  padding: ${sm};
`;
