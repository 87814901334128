import {yupResolver} from '@hookform/resolvers';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {FormProvider, useForm} from 'react-hook-form';
import React, {useState, useCallback, useEffect} from 'react';
import {EditCreditCard} from './EditCreditCard';
import {useApi} from '@src/api/useApi';
import {Modal} from '@src/components/ui/layout/Modal';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {Button} from '@src/components/ui/form';
import {CreditCard, Patient} from '@src/models';
import {yObject, rqString} from '@src/schema/types';

export type CreditCardForm = {
  cardholder: string;
};

const schema = yObject({
  cardholder: rqString('Please provide a cardholder name'),
});

type Props = {
  details: CreditCardForm;
  onClose: () => unknown;
  onUpdateCard: () => unknown;
  patient: Patient;
  show: boolean;
  creditCardForVisitCallPool: Optional<CreditCard>;
  callPoolId: number;
};
// eslint-disable-next-line import/no-unused-modules
export default function EditCreditCardModal({
  details,
  onClose,
  onUpdateCard,
  patient,
  show,
  callPoolId,
  creditCardForVisitCallPool,
}: Props) {
  const api = useApi();
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | undefined>();
  const formMethods = useForm<CreditCardForm>({
    defaultValues: details,
    resolver: yupResolver(schema),
  });
  const {handleSubmit, reset} = formMethods;

  useEffect(() => {
    reset(details);
  }, [details, reset]);

  const onSave = useCallback(
    async (data: CreditCardForm) => {
      const cardElement = elements?.getElement(CardElement) ?? null;
      if (cardElement === null || !stripe) {
        return;
      }

      const {cardholder} = data;
      setSubmitting(true);

      const {error, token} = await stripe.createToken(cardElement, {
        name: cardholder,
      });

      setSubmitError(error?.message);

      if (token) {
        await api
          .patient(patient.id)
          .updateCreditCardInformation({callPoolId, stripeToken: token.id})
          .then(onUpdateCard)
          .then(onClose);
      }
      setSubmitting(false);
    },
    [api, elements, onClose, onUpdateCard, patient.id, stripe],
  );

  return (
    <FormProvider {...formMethods}>
      <Modal
        show={show}
        title="Please tell us about your credit card"
        modalActions={
          <>
            <MuiAsyncActionButton
              bStyle={'primary'}
              disabled={submitting}
              actionInProgress={submitting}
              actionWord="Save"
              onClick={handleSubmit(onSave)}
            >
              Save
            </MuiAsyncActionButton>
            <Button bStyle="secondary" onClick={onClose}>
              Cancel
            </Button>
          </>
        }
      >
        <EditCreditCard
          patient={patient}
          submitError={submitError}
          creditCard={creditCardForVisitCallPool}
        />
      </Modal>
    </FormProvider>
  );
}
