import styled from 'styled-components';
import {sm} from '../../theme';

export const Instructions = styled.div<{hidden?: boolean}>`
  align-items: center;
  display: ${props => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
  font-size: 1.3em;
  margin-bottom: ${sm};

  p {
    line-height: 1.5em;
    padding-bottom: ${sm};
    width: 100%;
  }
`;
