import {ConnectedLoginsResult} from '../shared/types';
import {MergeOptionsGuardian} from '@src/models/Patient';

export function getMergeState({
  isLoading,
  mergeOptionsGuardian,
  connectedLogins,
}: {
  isLoading: boolean;
  mergeOptionsGuardian: MergeOptionsGuardian;
  connectedLogins: ConnectedLoginsResult;
}) {
  const {nameOptions, person} = mergeOptionsGuardian;

  const result = connectedLogins[person.id];

  const resolved = result.length > 0 && nameOptions.length > 1;
  const passed = result.length > 0 && nameOptions.length === 1;

  return isLoading
    ? 'CHECKING'
    : resolved
    ? 'RESOLVED'
    : passed
    ? 'PASS'
    : 'CONFLICT';
}
