import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Badge,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {AssignedForm} from '@src/models';
import {useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';

interface Props {
  form: AssignedForm;
  onDelete: (formId: number) => unknown;
  showBadge: boolean;
}

export default function AssignedFormListItem({
  form,
  onDelete,
  showBadge,
}: Props) {
  const api = useApi();
  // TODO: [RCC-885] Load visits with forms when we can without slowing down or crashing the server.
  const [visit] = useResources(
    () => api.visit(form.visitId.get()).get(),
    [api, form],
    {requestGate: () => form.visitId.isPresent()},
  );

  return (
    <ListItem>
      <ListItemText
        secondary={
          form.visitId.isPresent()
            ? visit
                .getOptional()
                .map<React.ReactNode>(v => `Visit: ${v.start.toLocaleString()}`)
                .orElse(
                  <CircularProgress style={{width: '15px', height: '15px'}} />,
                )
            : 'Not associated to a visit'
        }
      >
        <Link to={`/form/${form.id}`}>{form.blankForm.get().name}</Link>
        <Badge
          invisible={!showBadge || form.completedAt?.isPresent()}
          variant="dot"
          color="error"
          style={{marginInlineStart: '0.5em'}}
        />
      </ListItemText>
      <ListItemSecondaryAction>
        {!form.completedAt?.isPresent() && (
          <IconButton onClick={() => onDelete(form.id)}>
            <FontAwesomeIcon icon={['fas', 'trash']} size="sm" />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}
