import {makeStyles} from '@material-ui/core';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import jstz from 'jstz';
import React, {useState, useCallback} from 'react';
import {Button} from '../ui/form';
import {ClickableIcon} from '../ui/layout/ClickableIcon';
import {useApi} from '@src/api/useApi';
import {isSomething} from '@src/util/typeTests';

type Props = {
  disabled?: boolean;
  fileUrl: string | Promise<string>;
  text?: string;
  icon?: IconProp;
  className?: string;
  authorized?: boolean;
};

const useStyles = makeStyles({
  downloadButton: {
    cursor: 'pointer',
  },
});

export function DownloadButton({
  authorized,
  className = '',
  disabled = false,
  fileUrl,
  text,
  icon = ['fas', 'exclamation-triangle'],
}: Props) {
  const api = useApi();
  const classes = useStyles();

  const [downloadCount, setDownloadCount] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState('');

  const getDownloadUrl = useCallback(async () => {
    const timezone = jstz.determine();

    const baseUrl = await fileUrl;

    return `${baseUrl}?tz=${timezone.name()}&count=${downloadCount}${
      authorized ? `&jwt=${await api.access_token}` : ''
    }`;
  }, [api.access_token, authorized, downloadCount, fileUrl]);

  const onClick = useCallback(() => {
    getDownloadUrl().then(newDownloadUrl => {
      setDownloadUrl(newDownloadUrl);
      setDownloadCount(dc => dc + 1);
    });
  }, [getDownloadUrl]);

  const content = isSomething(text) ? (
    <Button
      bStyle="primary"
      bSize="small"
      className={classes.downloadButton}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </Button>
  ) : (
    <div className={className}>
      <ClickableIcon icon={icon} onClick={onClick} />
    </div>
  );

  return (
    <>
      {content}
      <iframe title={downloadUrl} src={downloadUrl} style={{display: 'none'}} />
    </>
  );
}
