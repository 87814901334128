import React, {useCallback, useEffect, useState} from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {Typography, useTheme} from '@material-ui/core';
import {usePageContext} from '..';
import SearchProvider from '@src/components/separateAdminApp/SearchProvider';
import {Modal} from '@src/components/ui/layout/Modal';
import {AsyncActionButton} from '@src/components/ui/form';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {CallPool, ProviderDetails} from '@src/models';
import {useApi} from '@src/api/useApi';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {useProfessionalSearch} from '@src/hooks/people/professionals/useProfessionalSearch';

export function AddProviderModal() {
  const {showAddProfessional, hideAddProfessionalModal} = usePageContext();

  const [details, setDetails] = useState<ProviderDetails | null>();
  const [providerCallPools, setProviderCallPools] = useState<CallPool[] | null>(
    null,
  );
  const [alreadyMember, setAlreadyMember] = useState(false);
  const theme = useTheme();
  const api = useApi();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const professionalSearchResources = useProfessionalSearch({
    onDetailsUpdated: setDetails,
    onCallPoolsUpdated: setProviderCallPools,
    includeUnapprovedMembershipGroups: true,
  });

  const onAddMember = useCallback(() => {
    if (!showAddProfessional) {
      return;
    }

    api
      .callPool(showAddProfessional)
      .addMember(Optional.of(details).property('id', 0 as ProviderDetailsId))
      .then(() => {
        flashSuccess('Professional added to Group');
      })
      .catch(() => {
        flashError(
          'Oops! Something went wrong adding the Professional to this Group',
        );
      })
      .finally(() => {
        setIsSubmitting(false);
        hideAddProfessionalModal();
      });
  }, [api, details, hideAddProfessionalModal, showAddProfessional]);

  useEffect(() => {
    if (
      (providerCallPools || []).filter(cp => cp.id === showAddProfessional)
        .length > 0
    ) {
      setAlreadyMember(true);
    } else {
      setAlreadyMember(false);
    }
  }, [providerCallPools, showAddProfessional]);

  if (!showAddProfessional) {
    return (
      <Typography
        style={{
          fontWeight: 700,
          fontSize: 18,
          color: theme.palette.warning.main,
        }}
      >
        Oops! That Call Pool doesn't have a valid ID.
      </Typography>
    );
  }

  return (
    <Modal
      show={showAddProfessional !== null}
      onClose={hideAddProfessionalModal}
      title="Add Professional To Group"
    >
      <AsyncActionButton
        actionInProgress={isSubmitting}
        actionWord={'Submit'}
        onClick={onAddMember}
        bStyle="primary"
        disabled={!details || alreadyMember}
      />

      {alreadyMember && (
        <Typography
          style={{
            fontWeight: 700,
            fontSize: 18,
            color: theme.palette.warning.main,
            margin: '1rem 0',
          }}
        >
          This Provider is already a member of the selected Call Pool.
        </Typography>
      )}

      <SearchProvider
        showOnlyProviderCard
        professionalSearchResources={professionalSearchResources}
      />
    </Modal>
  );
}
