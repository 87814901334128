import React from 'react';

import {format} from 'date-fns';
import {TableCell, TableRow} from '@material-ui/core';
import {SCPChangeRequest} from '@src/models';
import {
  formatProperty,
  formatRequester,
} from '@src/util/sharedCarePlan/changeRequest';

type Props = {
  changeRequest: SCPChangeRequest;
};

export function ChangeRow({changeRequest}: Props) {
  const {
    newValue,
    property,
    requester,
    updatedAt,
    qualification,
  } = changeRequest;

  return (
    <TableRow
      key={changeRequest.id}
      style={{maxHeight: '10px', overflowY: 'scroll'}}
    >
      <TableCell>{format(updatedAt, 'M.d.yyyy')}</TableCell>
      <TableCell align="left">
        {formatRequester(requester, qualification)}
      </TableCell>
      <TableCell align="left">{formatProperty(property)}</TableCell>
      <TableCell
        align="left"
        style={{
          wordBreak: 'break-word',
          whiteSpace: 'normal',
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        {newValue}
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
}
