import React, {useEffect} from 'react';
import {Grid, FormControlLabel, FormControl, Radio} from '@material-ui/core';
import {useStyles} from '../styles';
import {getLabel} from './SelectInsuranceStep';
import {MergeOptions, PaymentInformationResult} from '@src/models/Patient';
import {isSomething} from '@src/util/typeTests';
import {PaymentInformation} from '@src/models';

type Props = {
  checkboxesState: Record<string, boolean>;
  mergeOptions: MergeOptions;
  paymentInformationResult: PaymentInformationResult;
  setPaymentInformationResult: (v: PaymentInformationResult) => unknown;
};

export function SelectPrimaryStep({
  checkboxesState,
  mergeOptions,
  paymentInformationResult,
  setPaymentInformationResult,
}: Props) {
  const classes = useStyles();

  const {paymentInfos} = mergeOptions;

  const mappedPaymentInfos = paymentInfos.reduce((acc, info) => {
    return {...acc, [info.id]: info};
  }, {} as Record<number, PaymentInformation>);

  const mappedSelections = Object.keys(checkboxesState)
    .map(c =>
      checkboxesState[c] ? mappedPaymentInfos[parseInt(c)] : undefined,
    )
    .filter(isSomething);

  useEffect(() => {
    const newResult = {
      primary: mappedSelections[0],
      secondary: mappedSelections[1] ?? undefined,
    };

    setPaymentInformationResult(newResult);
  }, []);

  return (
    <Grid container direction="row">
      <FormControl required component="fieldset" style={{width: '100%'}}>
        <Grid
          container
          direction="row"
          xs={12}
          item
          className={classes.selectionsContainer}
        >
          {mappedSelections.map(s => (
            <FormControlLabel
              key={s.id}
              labelPlacement="end"
              value={s.id}
              name="primary"
              control={
                <Radio
                  onChange={() => {
                    setPaymentInformationResult({
                      ...paymentInformationResult,
                      primary: s,
                      secondary: mappedSelections.find(
                        selection => s.id !== selection.id,
                      ),
                    });
                  }}
                  checked={paymentInformationResult.primary?.id === s?.id}
                />
              }
              label={getLabel(s)}
            />
          ))}
        </Grid>
      </FormControl>
    </Grid>
  );
}
