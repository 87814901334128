import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import styled from 'styled-components';
import {Grid} from '@material-ui/core';
import {secondary} from '../../ui/theme';
import {ParagraphText} from '../../ui/layout/text/body/ParagraphText';
import {useUser} from '@src/hooks/useUser';
import {useApi} from '@src/api/useApi';
import {Banner} from '@src/components/ui/layout/Banner';
import {Button} from '@src/components/ui/form';

const AlertElement = styled.div<{show: boolean}>`
  align-items: center;
  color: ${secondary};
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: row;
  justify-content: flex-start;
`;

export function UnfinishedBusinessAlert() {
  const api = useApi();
  const history = useHistory();
  const [hasIncompletes, setHasIncompletes] = useState(false);

  const [user] = useUser();

  useEffect(() => {
    if (user.isLoaded()) {
      api
        .provider(user.singleValue().id)
        .getIncompleteVisits()
        .then(incompletes => {
          setHasIncompletes(incompletes.length > 0);
        });
    }
  });

  return (
    <AlertElement show={hasIncompletes}>
      <Grid container direction="row" xs={12} md={6}>
        <Banner type="info" title="Incomplete Documentation">
          <ParagraphText>Please complete your documentation.</ParagraphText>
          <div>
            <Button
              bSize="small"
              onClick={() => {
                history.push(`/past-visits?search=myVisits`);
              }}
            >
              View
            </Button>
          </div>
        </Banner>
      </Grid>
    </AlertElement>
  );
}
