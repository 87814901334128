import React, {useCallback} from 'react';
import {Controller, Control, FieldErrors, DeepMap} from 'react-hook-form';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {Switch, FormControlLabel} from '@material-ui/core';
import {toggleMyDetails} from './functions';
import {PatientDetailsValues} from '@src/components/shared/forms/SharedPatient/patientDetailsUtils';
import {Guardian, UserType, ResponsiblePerson} from '@src/models';
import {useUser} from '@src/hooks';

type Props = {
  disabled?: boolean;
  existingPersonForIndependentPatient: Optional<ResponsiblePerson>;
  setExistingPersonForIndependentPatient?: (
    v: Optional<ResponsiblePerson>,
  ) => unknown;
  control: Control<PatientDetailsValues>;
  isSubmitting: boolean;
  errors: DeepMap<PatientDetailsValues, FieldErrors>;
  touched: DeepMap<PatientDetailsValues, true>;

  setValue: (
    key: keyof PatientDetailsValues,
    v: PatientDetailsValues[keyof PatientDetailsValues],
  ) => unknown;
  setValues: (v: PatientDetailsValues) => unknown;
  getValues: () => PatientDetailsValues;
  clearErrors: () => unknown;
  defaultValues: PatientDetailsValues;
  setPatientIsCurrentUser?: (b: boolean) => unknown;
};

export function UseMyDetailsSwitch({
  disabled = false,
  existingPersonForIndependentPatient,
  setExistingPersonForIndependentPatient,
  setPatientIsCurrentUser,
  control,
  touched,
  defaultValues,
  isSubmitting,
  errors,
  setValue,
  setValues,
  getValues,
  clearErrors,
}: Props) {
  const [user] = useUser();

  const userAsPerson = user
    .getOptional()
    .cast<Guardian>(u => u.userType === UserType.Guardian)
    .map(g => g.responsiblePersonDetails);

  const memoizedToggleMyDetails = useCallback(toggleMyDetails, [
    userAsPerson,
    setValues,
    getValues,
    clearErrors,
  ]);

  return (
    <Controller
      defaultValue={false}
      control={control}
      disabled={disabled || isSubmitting}
      name="isSelf"
      title="This patient record is for me"
      required
      error={errors.isSelf?.message}
      touched={touched.isSelf}
      render={props => (
        <FormControlLabel
          label={'Use my details'}
          control={
            <Switch
              name="useMyDetails"
              checked={existingPersonForIndependentPatient.isPresent()}
              color="primary"
              inputProps={{'aria-label': 'checkbox'}}
              onChange={e => {
                setValue('isSelf', e.target.checked);
                if (setPatientIsCurrentUser) {
                  setPatientIsCurrentUser(e.target.checked);
                }
                memoizedToggleMyDetails({
                  shouldUseDetails: e.target.checked,
                  setExistingPersonForIndependentPatient,
                  clearErrors,
                  user,
                  getValues,
                  setValues,
                  defaultValues,
                });
              }}
            />
          }
        />
      )}
    />
  );
}
