import * as React from 'react';
import styled from 'styled-components';
import {LonelyVisit} from '../../models';
import {Button} from '../ui/form';
import {Modal} from '../ui/layout/Modal';
import {sm} from '../ui/theme';
import {AddAddendum} from './AddAddendum';
import {DownloadButton} from './DownloadButton';
import {VisitDocumentationView} from './VisitDocumentationView';
import {useApi} from '@src/api/useApi';

type Props = {
  note: LonelyVisit;
  show: boolean;
  canReview: boolean;
  onClose: () => void;
  removeNote: (a: LonelyVisit) => void;
  updateNote: (a: LonelyVisit) => void;
};

const VisitDocumentationModalBody = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    margin-left: ${sm};
  }
`;

export function VisitDocumentationModal({
  note,
  show,
  onClose,
  removeNote,
  updateNote,
  canReview,
}: Props) {
  const api = useApi();
  return (
    <Modal show={show} title="Visit Notes" onClose={onClose}>
      <VisitDocumentationModalBody>
        <VisitDocumentationView
          visit={note}
          docs={note.visitDocumentation}
          patient={note.patient}
        />
        <Buttons className="modal-footer">
          <AddAddendum visit={note} onUpdateNote={updateNote} />

          <DownloadButton
            fileUrl={api.visit(note.id).getDocumentationDownloadURL()}
            authorized
            text="Download"
          />
          {canReview && (
            <Button
              bStyle="primary"
              bSize="small"
              onClick={() => {
                api
                  .visit(note.id)
                  .markAsReviewed()
                  .then(visit => {
                    removeNote(visit);
                    onClose();
                  });
              }}
            >
              Mark as Reviewed
            </Button>
          )}
        </Buttons>
      </VisitDocumentationModalBody>
    </Modal>
  );
}
