import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid, Typography} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, {useEffect, useState} from 'react';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {ProviderSelector} from '@src/components/shared/ProviderSelector';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/styles';
import {Acknowledge} from '@src/components/ui/form/Acknowledge';
import {Button} from '@src/components/ui/form/Button';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {Modal} from '@src/components/ui/layout/Modal';
import {SCPHeading} from '@src/components/shared/PatientDashboard/SCP/SCPHeading';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {
  Context,
  FormMode,
} from '@src/components/../util/provider/forms/addOrEdit';
import {getScpOwnerAsProviderDetails} from '@src/util/sharedCarePlan/ownership';
import {nonRCCUserSharedCareOwnerDescription} from '@src/util/sharedCarePlan';
import {providerAndQualification, ProviderCategory} from '@src/util/provider';
import {ProviderDetails, UserType} from '@src/models';
import {useApi} from '@src/api/useApi';
import {convertDetailsToRequest} from '@src/api/ProviderDetailsAPI';
import {isNothing} from '@src/util/typeTests';
import {UserId} from '@src/models/User';
import {useUser} from '@src/hooks';

export function TransferOwnership() {
  const [, userType] = useUser();
  const {
    patient,
    setScp,
    scp,
    setPatient,
    selectPanel,
    reviewMode,
    ownerId,
  } = usePageContext();

  const oScp = scp.getOptional();

  const api = useApi();
  const classes = useStyles();
  const theme = useTheme();

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<{[field: string]: string}>({});

  const currentOwner = getScpOwnerAsProviderDetails(oScp);

  const [showOwnerModal, setShowOwnerModal] = useState(false);
  const [details, setDetails] = useState(Optional.empty<ProviderDetails>());

  const [confirmed, setConfirmed] = useState(false);
  const [type, setType] = useState<FormMode>(FormMode.ProviderSearch);

  const providerId = details.map(p => p.providerId).orElse(0 as UserId);

  function reset() {
    setShowOwnerModal(false);
    setErrors({});
    setDetails(Optional.empty());
    setType(FormMode.ProviderSearch);
    setConfirmed(false);
  }

  useEffect(() => {
    setErrors(oldErrors => ({
      ...oldErrors,
      ownerSelection: '',
    }));
  }, [submitting]);

  const onSubmit = () => {
    if (details === null)
      return setErrors({
        ...errors,
        ownerSelection:
          'Please select an owner or designate that you are unsure of who the owner should be',
      });

    if (ownerId === providerId) {
      return setErrors({
        ...errors,
        ownerSelection: "That doctor is already this plan's owner",
      });
    }

    const patientId = patient
      .getOptional()
      .map(p => p.id)
      .orElse(0);
    const patientApi = api.patient(patientId);

    setSubmitting(true);

    patientApi
      .getSCP()
      .then(newScp => {
        return api
          .scp(newScp.id)
          .updateOwner(convertDetailsToRequest(Optional.of(details)).get());
      })
      .then(updatedScp => {
        setScp(updatedScp);
      })
      .then(() => {
        patientApi.get().then(setPatient);
        flashSuccess('New owner set successfully!', {permanent: false});
        selectPanel('summaryView');
      })
      .catch(e => {
        console.log(e);

        flashError(
          'Something went wrong with setting the new owner. Please contact support or try again.',
        );
      })
      .finally(() => {
        setSubmitting(false);
        reset();
      });
  };

  function isSaveButtonDisabled() {
    if (!confirmed) {
      return true;
    }

    if (type === 'manualEntry') {
      const lastName = details.property('lastName', '');
      const firstName = details.property('firstName', '');

      if (!lastName || !firstName) {
        return true;
      }
    }

    if (type === 'providerSearch') {
      if (providerId === 0) {
        return true;
      }
    }

    return false;
  }

  const ownerDescription = !isNothing(currentOwner)
    ? providerAndQualification(currentOwner)
    : '';

  return (
    <Grid
      className={classes.rowContainer}
      style={{marginBottom: '2rem'}}
      container
      direction="row"
    >
      <Grid
        item
        xs={12}
        className={classes.gridItem}
        style={{marginBottom: '1rem'}}
      >
        <SCPHeading>Change Ownership</SCPHeading>
        <Typography variant="caption" style={{fontStyle: 'italic'}}>
          This is where you can change the owner of this shared care plan. The
          new owner will be responsible for regularly reviewing the accuracy of
          the information in the plan. They also will receive and manage all
          requests from guardians and other doctors to edit information in the
          plan.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.gridItem}
        style={{marginBottom: '1rem'}}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <AccountCircleIcon
            style={{
              color: theme.palette.primary.main,
            }}
          />
          <ParagraphText
            style={{
              marginLeft: '0.5rem',
              color: theme.palette.primary.main,
              fontWeight: 500,
              marginBottom: 0,
            }}
          >
            Current Owner:
          </ParagraphText>
          {currentOwner && ownerDescription && (
            <ParagraphText style={{marginBottom: 0, marginLeft: '0.5rem'}}>
              {currentOwner?.id === 0
                ? nonRCCUserSharedCareOwnerDescription(
                    currentOwner,
                    ProviderCategory.SharedCarePlanOwner,
                  )
                : ownerDescription}
            </ParagraphText>
          )}

          {(!ownerDescription || !currentOwner) && (
            <ParagraphText style={{marginBottom: 0, marginLeft: '0.5rem'}}>
              No owner
            </ParagraphText>
          )}
        </div>
      </Grid>

      {showOwnerModal && (
        <Modal
          title="Change shared care plan owner"
          show={showOwnerModal}
          onClose={() => {
            reset();
          }}
          modalActions={
            <div style={{marginTop: '1rem'}}>
              <Button
                bSize="small"
                bStyle="outlined"
                onClick={() => reset()}
                isInRow={true}
              >
                Cancel
              </Button>
              <MuiAsyncActionButton
                actionInProgress={submitting}
                actionWord={'Save'}
                bSize="small"
                bStyle="primary"
                onClick={onSubmit}
                disabled={isSaveButtonDisabled()}
              />
            </div>
          }
        >
          <ProviderSelector
            providerDescription="Owning provider"
            details={details.isPresent() ? details : Optional.of(currentOwner)}
            onChange={(i, entryType) => {
              setErrors({
                ...errors,
                ownerSelection: '',
              });

              setType(entryType);
              setDetails(Optional.of(i));
            }}
            userType={userType}
            sharedCarePlanContextOptions={{
              setNoSCPOwner: () => {
                setDetails(Optional.empty());
              },
            }}
            selectionOrEditContext={Context.SharedCarePlanOwner}
          />

          {errors.ownerSelection && (
            <Typography className={classes.warningText}>
              {errors.ownerSelection}
            </Typography>
          )}
          <Acknowledge
            setValue={v => {
              setConfirmed(v);
            }}
            value={Optional.of(confirmed)}
            message={`Please confirm that ${
              userType === UserType.Guardian ? 'the current owner' : 'you'
            } will no longer have access to this Shared Care Plan's owner permissions.`}
            style={{marginTop: '1rem'}}
          />
        </Modal>
      )}

      <Button
        bSize="small"
        bStyle="secondary"
        onClick={() => setShowOwnerModal(true)}
        style={{marginTop: '0.5rem'}}
        disabled={reviewMode}
      >
        {currentOwner ? 'Change Owner' : 'Add Owner'}
      </Button>
    </Grid>
  );
}
