import {RemoteDataStatus} from '@ahanapediatrics/ahana-fp';
import React, {useState, useCallback} from 'react';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from '@material-ui/core';
import {PrivatePage} from '@src/components/PrivatePage';
import {DownloadButton} from '@src/components/providerSide/DownloadButton';
import {PageHeader} from '@src/components/ui/layout';
import {Section, SectionTitle} from '@src/components/ui/layout/InfoPage';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {useResources, useUser, useAsync} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {CallPool} from '@src/models';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {UserId} from '@src/models/User';
import {ProviderGroupSelector} from '@src/components/shared/forms/ProviderGroupSelector';
import {AsyncActionButton} from '@src/components/ui/form';
import {ProviderGroupReportRow} from '@src/api/ReportAPI';

function ReportingOverview() {
  const api = useApi();
  const [user] = useUser();
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const [usersGroups] = useResources<CallPool>(
    () =>
      api
        .provider(user.getOptional().property('id', 0 as UserId))
        .getGroups({memberOnly: true}),
    [api, user],
    {requestGate: () => user.isLoaded()},
  );
  const [report, setReport] = useAsync<ProviderGroupReportRow>();

  const getReport = useCallback(async () => {
    setReport();
    api
      .reports()
      .getProviderGroupReport(selectedGroups)
      .then(setReport);
  }, [api, selectedGroups, setReport]);

  const getReportUrl = useCallback(async () => {
    return await api.reports().getProviderGroupReportUrl(selectedGroups);
  }, [api, selectedGroups]);

  return (
    <PrivatePage>
      <PageHeader>Reporting</PageHeader>
      <Section>
        <SectionTitle>Instructions</SectionTitle>
        <ParagraphText>
          Running reports is easy. Select the Groups that you're interested in,
          then click the link to download the data.
        </ParagraphText>
        <ParagraphText>
          Data will be downloaded in a CSV format. This can then be imported
          into Google Sheets, Excel or any data processing application you like.
        </ParagraphText>
      </Section>
      <Section>
        <SectionTitle>Groups to Report on</SectionTitle>
        <ParagraphText>These are the groups that you belong to.</ParagraphText>
        <PageLoading
          active={!usersGroups.isLoaded()}
          message="Loading Provider Groups..."
        >
          <ProviderGroupSelector
            groups={usersGroups.getAllOptional().orElse([])}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            selectAll
          />
        </PageLoading>
      </Section>
      <Section>
        <SectionTitle>Download Report</SectionTitle>
        <ParagraphText>
          You can generate the report and see it here or you can download it in
          CSV format; this can be opened with your preferred spreadsheet
          application for further analysis
        </ParagraphText>
        <ParagraphText>
          The report will show visits completed in the past 90 days.
        </ParagraphText>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <DownloadButton
              disabled={selectedGroups.length === 0}
              fileUrl={getReportUrl()}
              authorized
              text="Download Report"
            />
          </Grid>
          <Grid item>
            <AsyncActionButton
              bSize="small"
              bStyle="primary"
              disabled={selectedGroups.length === 0}
              onClick={getReport}
              actionInProgress={report.is(RemoteDataStatus.Loading)}
              actionWord="Generate Report"
            />
          </Grid>
        </Grid>
        {report.isLoaded() && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Visit UID</TableCell>
                  <TableCell>Patient UID</TableCell>
                  <TableCell>Visit Start</TableCell>
                  <TableCell>Provider Name</TableCell>
                  <TableCell>Provider Group</TableCell>
                  <TableCell>Telemedicine Endpoint</TableCell>
                  <TableCell>Insurance Provider</TableCell>
                  <TableCell>E &amp; M Code</TableCell>
                  <TableCell>Length</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {report.value().map(row => (
                  <TableRow>
                    <TableCell>{row.visitUid}</TableCell>
                    <TableCell>{row.patientUid}</TableCell>
                    <TableCell>{row.start}</TableCell>
                    <TableCell>{row.providerName}</TableCell>
                    <TableCell>{row.groupName}</TableCell>
                    <TableCell>{row.telemedEndpoint}</TableCell>
                    <TableCell>{row.insurance}</TableCell>
                    <TableCell>{row.emCode}</TableCell>
                    <TableCell>{row.visitLength}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Section>
    </PrivatePage>
  );
}

// eslint-disable-next-line import/no-unused-modules
export default ReportingOverview;
