import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {usePageContext} from '..';
import {Routes} from '@src/components/shared/general/AppRoutes';
import {CreateSCP} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/index';
import {Button} from '@src/components/ui/form';
import {MuiHeading} from '@src/components/ui/layout/MuiHeading';
import {useUser} from '@src/hooks';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    color: theme.palette.primary.main,
    padding: '2rem',
  },
  paragraph: {
    marginTop: '1rem',
  },
  buttonRow: {
    marginTop: '2rem',
    textAlign: 'center',
    width: '100%',
  },
}));

type Props = {
  onCancel?: () => unknown;
  onCreated?: () => unknown;
};

export function NoSCPDisplay({
  onCancel = () => {
    history.push(Routes.Dashboard);
  },
  onCreated,
}: Props) {
  const [user, userType] = useUser();
  const {patient, setScp, setPatient} = usePageContext();

  const classes = useStyles();
  const history = useHistory();
  const [showSCPModal, setShowSCPModal] = useState(false);

  return (
    <Grid container direction={'row'} className={classes.rowContainer}>
      <MuiHeading
        color="primary"
        variant="h2"
        style={{fontSize: '2.5rem', marginBottom: '1em'}}
      >
        Welcome to shared care plan!
      </MuiHeading>
      <Typography variant="body1">
        Shared care plans are a statewide initiative to create secure,
        cloud-based emergency care plans to improve communication and delivery
        of medical care for patients with medical complexity and special health
        care needs.
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        This is a shared care plan that is co-created between families/guardians
        and their chosen provider. The goal is to inform first responders and
        emergency care providers with the right information at the right time.
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        If you think this would be important for this patient, please proceed
        forward and we will help create a shared care plan.
      </Typography>
      <Grid item className={classes.buttonRow}>
        <Button
          style={{marginBottom: '2rem'}}
          bStyle="primary"
          onClick={() => setShowSCPModal(true)}
          disabled={!patient.isLoaded()}
        >
          Request shared care plan
        </Button>
      </Grid>

      <CreateSCP
        onCancel={onCancel}
        patient={patient.getOptional()}
        setScp={setScp}
        setPatient={setPatient}
        show={showSCPModal}
        user={user.getOptional()}
        userType={userType}
        onCreated={onCreated}
      />
    </Grid>
  );
}
