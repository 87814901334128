import {MenuItem} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import EditPatientMedicalHistoryModal from './EditPatientMedicalHistoryModal';
import {useAsync} from '@src/hooks/useAsync';
import {MedicalHistory, LonelyPatient} from '@src/models';
import {isNothing} from '@src/util/typeTests';
import {useApi} from '@src/api/useApi';

type Props = {
  patient: LonelyPatient;
  onUpdateHistory: (history: MedicalHistory) => void;
  handleClose: () => void;
};

const EditPatientMedicalHistory = ({
  patient,
  onUpdateHistory,
  handleClose,
}: Props) => {
  const api = useApi();
  const [showModal, setShowModal] = useState(false);
  const [currentMedicalHistory, setCurrentMedicalHistory] = useAsync<
    MedicalHistory
  >();

  useEffect(() => {
    api
      .patient(patient.id)
      .getCurrentMedicalHistory()
      .then(cmh => {
        setCurrentMedicalHistory(cmh);
      });
  }, [api, patient, setCurrentMedicalHistory]);

  const updateHistory = (updatedHistory?: MedicalHistory) => {
    if (updatedHistory) {
      onUpdateHistory(updatedHistory);
    }
    setShowModal(false);
  };

  return (
    <>
      <MenuItem
        onClick={e => {
          setShowModal(true);
          handleClose();
        }}
      >
        {patient.medicalHistoryVersion === 1 ? 'Add' : 'Edit'} Medical History
      </MenuItem>
      <EditPatientMedicalHistoryModal
        currentMedicalHistory={currentMedicalHistory}
        patient={patient}
        showModal={showModal}
        onCancel={() => setShowModal(false)}
        onSubmitComplete={mh => {
          if (!isNothing(mh)) {
            setCurrentMedicalHistory(mh);
          }
          updateHistory(mh);
        }}
      />
    </>
  );
};

export default EditPatientMedicalHistory;
