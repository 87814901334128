import React from 'react';
import {StepProps} from '..';
import {CreditCardView} from './CreditCardView';
import {RequestHeader} from '@src/components/ui/layout/NewThingRequest';
import {defaultPaymentRules} from '@src/models';

type Props = StepProps;

export function CreditCardStep({
  onUpdatePatient,
  patient,
  setNextAvailable,
  rules,
  paymentProcessorInfo,
  callPoolId,
}: Props) {
  const {payments = defaultPaymentRules} = rules;
  return (
    <>
      <RequestHeader>Credit Card</RequestHeader>

      <CreditCardView
        patient={patient}
        setNextAvailable={setNextAvailable}
        onAddOrEdit={onUpdatePatient}
        creditCardControl={payments.creditCard}
        paymentProcessorInfo={paymentProcessorInfo}
        callPoolId={callPoolId}
      />
    </>
  );
}
