import React, {useState} from 'react';
import {AddToCallPoolModal} from './AddToCallPoolModal';
import {Button} from '@src/components/ui/form';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {CallPool} from '@src/models';

type AddToCallPoolButtonType = {
  detailsId: ProviderDetailsId;
  callPools?: CallPool[];
  onUpdated: () => unknown;
  buttonLabel: string;
};

export const AddToCallPoolButton = ({
  buttonLabel,
  callPools,
  detailsId,
  onUpdated,
}: AddToCallPoolButtonType) => {
  const [showAddModal, setShowAddModal] = useState(false);
  return (
    <>
      <Button
        onClick={() => {
          setShowAddModal(true);
        }}
      >
        {buttonLabel}
      </Button>

      <AddToCallPoolModal
        detailsId={detailsId}
        callPoolList={callPools}
        show={showAddModal}
        onClose={() => setShowAddModal(false)}
        onUpdated={() => {
          setShowAddModal(false);
          onUpdated();
        }}
      />
    </>
  );
};
