import React from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useStyles} from '../../shared/Tables/styles';
import {ResultIconCell} from '../../shared/Tables/ResultIconCell';
import {getMergeStateForRow} from '../../shared/Tables/getMergeStateForRow';
import {MedicalHistoryResult} from './utils/resultFunctions';
import {ExistingDataCell} from './ExistingDataCell';
import {MedicalHistoryUIElement} from './utils/getMedicalHistoryRow';
import {OverrideCell} from './OverrideCell';
import {ResultCell} from './ResultCell';
import {Patient} from '@src/models';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';

type Props = {
  patientOne: AsyncData<Patient>;
  patientTwo: AsyncData<Patient>;
  setResult: (r: MedicalHistoryResult) => unknown;
  result: MedicalHistoryResult;
  rows: MedicalHistoryUIElement[];
};

export function Rows({patientOne, patientTwo, result, setResult, rows}: Props) {
  const classes = useStyles();

  return (
    <>
      {rows.map(r => {
        const mergeState = getMergeStateForRow({
          patientOnePreexistingValue: r.patientOne,
          patientTwoPreexistingValue: r.patientTwo,
          result: result[r.name].text,
          radioSelection: result[r.name].radioSelection,
          isLoading: !patientOne.isLoaded() || !patientTwo.isLoaded(),
        });

        return (
          <TableRow key={r.name}>
            <TableCell className={classes.tableCell}>
              <Bold>{r.label}</Bold>
            </TableCell>
            <ExistingDataCell
              radioLabel={'patientOne'}
              result={result}
              setResult={setResult}
              row={r}
            />
            <ExistingDataCell
              radioLabel={'patientTwo'}
              result={result}
              setResult={setResult}
              row={r}
            />
            <OverrideCell result={result} setResult={setResult} row={r} />
            <ResultCell result={result} row={r} />
            <ResultIconCell mergeState={mergeState} />
          </TableRow>
        );
      })}
    </>
  );
}
