import {
  providerAndQualification,
  providerUserStatus,
  ProviderCategory,
} from '../provider';
import {ProviderDetails} from '@src/models';

/**
 * Provides a string to describe a Non-Refyne Connected Care user.
 * The user may be a Non-Refyne Connected Care user but have some information in their
 * Provider Details (first name, last name).
 * If the Provider Details are completely empty, this can be understood
 * to be an intentionally unknown provider selected by a user.
 *
 * @example
 *
 * - Unknown (please contact Ahana for help!)
 * -
 *
 * @param p
 * @param providerCategory
 */

export const nonRCCUserSharedCareOwnerDescription = (
  p: ProviderDetails,
  providerCategory: ProviderCategory,
) => {
  if (providerCategory !== ProviderCategory.SharedCarePlanOwner) {
    console.error(
      'Tried to use nonRCCUserSharedCareOwnerDescription for a provider whose category is not SharedCarePlanOwner',
    );
  }

  const descriptionWithQualification = providerAndQualification(p);
  const userStatus = `(${providerUserStatus(p)})`;

  if (descriptionWithQualification) {
    return `${descriptionWithQualification} ${userStatus}`;
  }

  if (!descriptionWithQualification) {
    return 'No Owner Selected';
  }
};
