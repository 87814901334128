import styled from 'styled-components';
import {h3, md, sm, xxs} from '../../ui/theme';

export const ProviderRecordTitle = styled.span`
  font-size: ${h3};
  line-height: ${h3};
  display: block;
  margin: ${sm} 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${md};

  button {
    margin: 0 ${xxs};
  }
`;
