import React, {useState} from 'react';
import {useStyles} from '../../styles';
import {
  RepeatingPeriodModal,
  SinglePeriodModal,
} from '@src/components/providerSide/OnCallPeriodModals';
import {ButtonContainer} from '@src/components/providerSide/ShiftsAndGroupsPage/layout';
import {CallPool, OnCallPeriod} from '@src/models';
import {Button} from '@src/components/ui/form/Button';

type Props = {
  disabled?: boolean;
  onCreateOnCallPeriods: (period: OnCallPeriod[]) => unknown;
  providerGroup: CallPool;
  groupsProviderCanAddToPeriods: CallPool[];
};

export const AddPeriods = ({
  disabled = false,
  onCreateOnCallPeriods,
  providerGroup,
  groupsProviderCanAddToPeriods,
}: Props) => {
  const [showOneOffModal, setShowOneOffModal] = useState(false);
  const [showRepeatingModal, setShowRepeatingModal] = useState(false);

  const classes = useStyles();

  return (
    <>
      <ButtonContainer className={classes.buttonContainer}>
        <Button
          bSize="small"
          bStyle={'primary'}
          disabled={disabled}
          onClick={() => setShowOneOffModal(true)}
          isInRow={true}
        >
          Add single shift
        </Button>
        <Button
          isInRow={true}
          bSize="small"
          bStyle={'primary'}
          disabled={disabled}
          onClick={() => setShowRepeatingModal(true)}
        >
          Add repeating shifts
        </Button>
      </ButtonContainer>

      {showOneOffModal && (
        <SinglePeriodModal
          requiredGroupsSelections={[providerGroup]}
          purpose="Shift"
          preselectedProviderGroups={[providerGroup]}
          onCreateOnCallPeriods={onCreateOnCallPeriods}
          availableGroups={groupsProviderCanAddToPeriods}
          show
          onClose={() => setShowOneOffModal(false)}
        />
      )}
      {showRepeatingModal && (
        <RepeatingPeriodModal
          onCreateOnCallPeriods={onCreateOnCallPeriods}
          availableGroups={groupsProviderCanAddToPeriods}
          purpose="Shift"
          show
          onClose={() => setShowRepeatingModal(false)}
          requiredGroupsSelections={[providerGroup]}
          preselectedProviderGroups={[providerGroup]}
        />
      )}
    </>
  );
};
