import React, {useRef, useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useAuth0} from '@auth0/auth0-react';
import {FlowPage} from '../forms/FlowPage';
import {StandardNext} from '../forms/FlowPage/StandardNext';
import {
  StepComponents,
  StepNames,
  StepProps,
  FirstTimeStep,
  UserBasics,
} from './functions';
import {ProfessionalBasics} from './steps/ProfessionalInfoStep/ProviderInfoForm';
import {useUser} from '@src/hooks/useUser';
import {LegalDocument, UserType, Professional} from '@src/models';
import {ReduxState} from '@src/store';
import {useAsync} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {firstName, lastName} from '@src/util/users/getDemographics';
import {allLoaded} from '@src/util/apiHelpers';

// eslint-disable-next-line import/no-unused-modules
export default function FirstTime() {
  const api = useApi();
  const {getIdTokenClaims} = useAuth0();
  const [user, userType, setUser] = useUser();
  const configuration = useSelector((state: ReduxState) => state.configuration);

  const [userBasics, setUserBasics] = useState<UserBasics>({
    firstName: '',
    lastName: '',
    phone: '',
  });
  const [requiredDocs, setRequiredDocs] = useAsync<LegalDocument>();
  const [unsignedDocs, setUnsignedDocs] = useAsync<LegalDocument>();
  const [email, setEmail] = useAsync<string>();

  useEffect(() => {
    if (!email.isAsked()) {
      setEmail();
      getIdTokenClaims().then(claims => {
        setEmail(claims.email ?? '');
      });
    }
  }, [email, getIdTokenClaims, setEmail]);

  useEffect(() => {
    if (requiredDocs.isAsked() || requiredDocs.isLoaded()) {
      return;
    }
    if (!configuration.isLoaded()) {
      return;
    }

    const consentDocs = configuration
      .getOptional()
      .property(
        userType === UserType.Guardian
          ? 'guardianConsentDocs'
          : 'providerConsentDocs',
        [],
      );

    setRequiredDocs();
    setUnsignedDocs();
    api
      .legalDocument()
      .query(consentDocs)
      .then(docs => {
        setRequiredDocs(docs);
        const currentSignatures = user
          .getOptional()
          .map(u => u.signatures)
          .orElse([]);
        setUnsignedDocs(
          docs.filter(d => {
            return !currentSignatures.some(sig => sig.document.id === d.id);
          }),
        );
      });
  }, [
    api,
    userType,
    configuration,
    setRequiredDocs,
    setUnsignedDocs,
    requiredDocs,
    user,
  ]);

  const setInitialFormValues = useCallback(() => {
    user.getOptional().ifPresent(u => {
      if (userType === UserType.Guardian) {
        setUserBasics({
          firstName: firstName(u) ?? '',
          lastName: lastName(u) ?? '',
          phone: u.phone.orElse(''),
        });
      }
      if (userType === UserType.Professional) {
        const professional = Professional.fromUser(u);
        const providerDetails = professional.providerDetails;
        setUserBasics({
          firstName: firstName(u) ?? '',
          lastName: lastName(u) ?? '',
          phone: u.phone.orElse(''),
          seesPatients: providerDetails.property('seesPatients', false),
          qualification: providerDetails.property('qualification', ''),
          speciality: providerDetails.property('speciality', ''),
        });
      }
    });
  }, [user, userType]);

  useEffect(() => {
    setInitialFormValues();
  }, [setInitialFormValues]);

  const dataReady = allLoaded(
    user,
    configuration,
    requiredDocs,
    unsignedDocs,
    email,
  );

  const getPlan = useCallback(() => {
    const steps: FirstTimeStep[] = [];
    if (userType === UserType.Professional) {
      steps.push('ProfessionalInformation');
    } else if (userType === UserType.Guardian) {
      steps.push('NonProfessionalInformation');
    }
    return steps.concat(['LegalDocumentation', 'Confirm']);
  }, [userType]);

  const moveGuard = useRef<(dir: 'back' | 'next') => Promise<boolean>>(() =>
    Promise.resolve(true),
  );

  const stepProperties: StepProps = {
    userBasics,
    setUserBasics,
    requiredDocs: requiredDocs.getAllOptional().orElse([]),
    unsignedDocs: unsignedDocs.getAllOptional().orElse([]),
    setUnsignedDocs,
    email: email.getOptional(),
  };

  const updateUser = useCallback(async () => {
    const u = user.singleValue();

    if (userType === UserType.Professional) {
      const {
        seesPatients,
        qualification,
        speciality,
      } = userBasics as ProfessionalBasics;

      await api.provider(u.id).updateDetails({
        firstName: userBasics.firstName,
        lastName: userBasics.lastName,
        email: email.getOptional().orElse(''),
        seesPatients,
        qualification: seesPatients ? qualification : '',
        speciality: seesPatients ? speciality : '',
      });
    }
    if (userType === UserType.Guardian) {
      await api.guardian(u.id).updateDetails({
        firstName: userBasics.firstName,
        lastName: userBasics.lastName,
        email: email.getOptional().orElse(''),
        phone: userBasics.phone,
      });
    }
  }, [api, email, user, userBasics, userType]);

  const getForwardNavigationButtons = ({
    isLastStep,
    proceedToNextStep,
    saving,
    nextAvailable,
  }: {
    isLastStep: boolean;
    proceedToNextStep: () => unknown;
    saving: boolean;
    nextAvailable: boolean;
  }) => {
    return [
      <StandardNext
        disabled={!nextAvailable}
        isLastStep={isLastStep}
        proceedToNextStep={proceedToNextStep}
        saving={saving}
      />,
    ];
  };

  return (
    <FlowPage
      dataReady={dataReady}
      title="We need just a little more information"
      StepComponents={StepComponents}
      StepNames={StepNames}
      getPlan={getPlan}
      moveGuard={moveGuard}
      onPerformTask={updateUser}
      onTaskPerformed={async () => {
        await api.getUser().then(setUser);
        window.location.pathname = '/dashboard';
        return false;
      }}
      stepProperties={stepProperties}
      resetState={setInitialFormValues}
      getForwardNavigationButtons={getForwardNavigationButtons}
    />
  );
}
