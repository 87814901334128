import {makeStyles} from '@material-ui/core/styles';
import {md, xs} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => {
  return {
    plusIcon: {
      color: theme.palette.success.main,
    },
    tableContainer: {
      maxHeight: '50vh',
      overflow: 'auto',
      width: '100%',
      padding: `0 ${md}`,
    },
    actionCell: {
      textAlign: 'right',
      padding: 0,
    },
    textCell: {
      padding: `${xs} 0 ${xs} 0`,
    },
  };
});
