import {Optional} from '@ahanapediatrics/ahana-fp';
import {isFuture} from 'date-fns';
import React, {useEffect, useState} from 'react';
import {Button} from '@src/components/ui/form/Button';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {
  flashError,
  flashNotify,
} from '@src/components/shared/notifications/flash';
import {Modal} from '@src/components/ui/layout/Modal';
import {Acknowledge} from '@src/components/ui/form/Acknowledge';
import {DateInput} from '@src/components/ui/form';
import {useApi} from '@src/api/useApi';

type Props = {
  setShow: (v: boolean) => void;
  show: boolean;
  expirationDate: Optional<Date>;
  setExpirationDate: (d: Optional<Date>) => unknown;
};

export function ReapproveModal({
  setShow,
  show,
  expirationDate,
  setExpirationDate,
}: Props) {
  const {patient, setScp, scp, setPatient} = usePageContext();

  const oPatient = patient.getOptional();
  const oScp = scp.getOptional();

  const api = useApi();

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<{[field: string]: string}>({});
  const [confirmed, setConfirmed] = useState(false);

  function reset() {
    setShow(false);
    setSubmitting(false);
    setConfirmed(false);
    setExpirationDate(oScp.map(m => m.approvalExpiration));
  }

  const patientName = patient
    .map(p => p.preferredFirstName)
    .orElse('this patient');

  useEffect(() => {
    setErrors({
      ...errors,
      expirationDate: '',
    });
  }, [submitting]);

  const onSubmit = () => {
    if (!expirationDate?.orNothing()) {
      setErrors({
        ...errors,
        expirationDate: 'Please enter a reminder date before submitting',
      });
    }

    if (expirationDate?.orNothing()) {
      const patientId = oPatient.map(p => p.id).orElse(0);
      const patientApi = api.patient(patientId);

      setSubmitting(true);

      patientApi
        .getSCP()
        .then(newScp => {
          return api
            .scp(newScp.id)
            .updateApprovalExpiration(expirationDate.orElse(new Date()));
        })
        .then(updatedScp => {
          setScp(updatedScp);
        })
        .then(() => {
          patientApi.get().then(setPatient);
          flashNotify(
            'Plan reapproved and new reminder date set successfully!',
          );
        })
        .catch(e => {
          console.log(e);

          flashError(
            'Something went wrong with reapproving this plan. Please contact support or try again.',
          );
        })
        .finally(() => {
          setShow(false);
          setSubmitting(false);
        });
    }
  };
  return (
    <Modal
      show={show}
      title="Review Shared Care Plan"
      onClose={reset}
      modalActions={
        <div style={{marginTop: '1rem'}}>
          <Button
            bSize="small"
            bStyle="outlined"
            onClick={reset}
            isInRow={true}
          >
            Cancel
          </Button>
          <MuiAsyncActionButton
            actionInProgress={submitting}
            actionWord={'Save'}
            bSize="small"
            bStyle="primary"
            onClick={onSubmit}
            disabled={
              !confirmed || !isFuture(expirationDate.orElse(new Date()))
            }
          />
        </div>
      }
    >
      <ParagraphText>
        It's time to review {patientName}'s Shared Care Plan to make sure that
        all the information is current!
      </ParagraphText>
      <ParagraphText>
        Please take some time to review the following for accuracy:
        <li>Medications</li>
        <li>Medical History</li>
        <li>Emergency Care</li>
        <li>Preferences</li>
        <li>Uploads</li>
        <li>Contacts</li>
      </ParagraphText>
      <Acknowledge
        setValue={v => {
          setConfirmed(v);
        }}
        value={Optional.of(confirmed)}
        message={`I have reviewed all of ${patientName}'s information in this plan and have confirmed that it is accurate and updated.`}
        style={{margin: '0.5rem 0 1rem 0', fontStyle: 'italic'}}
      />

      <ParagraphText>
        By default, we send out reminders after 90 days. However, for some
        patients it is important for the information to be double-checked more
        frequently.
      </ParagraphText>

      <ParagraphText style={{fontWeight: 700}}>
        If you would like to change the interval for reminders, you can select a
        custom date below, or click "next" to keep the default 90-day reminder.
      </ParagraphText>

      <DateInput
        error={
          !!errors.ownershipExpiration
            ? `${errors.ownershipExpiration}`
            : undefined
        }
        label="Shared care plan review reminder"
        name="expiration"
        onChange={d => setExpirationDate(Optional.of(d))}
        value={expirationDate?.orNothing()}
        minDate={new Date()}
      />
    </Modal>
  );
}
