import {ProviderPartner} from '@src/models';

export function partnerDetailsToProfessionalId({
  partnership,
}: {
  partnership: ProviderPartner;
}): number | undefined {
  return partnership.partnerDetails
    .map(details => details.providerId)
    .orNothing();
}
