import {
  addDays,
  format,
  getDay,
  isBefore,
  addMinutes,
  addHours,
  differenceInDays,
} from 'date-fns';
import {BasicRoster, PopulatedValues, Errors} from '../functions';
import {
  CreateUnpopulatedPeriodRequest,
  CreatePopulatedPeriodRequest,
} from '@src/api/OnCallAPI';
import {mergeDateAndTime} from '@src/util/dateManipulation/mergeDateAndTime';
import {OnCallPeriodType} from '@src/models';
import {UserId} from '@src/models/User';
import {isSomething} from '@src/util/typeTests';

export const WEEK_ARRAY = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export interface RepeatingPeriodValues extends BasicRoster {
  selectedDays: string[];
  endDate: Date | null;
}

export const validate = (
  values: RepeatingPeriodValues,
): Errors<RepeatingPeriodValues> => {
  const errors: Errors<RepeatingPeriodValues> = {};

  if (values.selectedDays.length === 0) {
    errors.selectedDays = 'You must select at least one day to cover';
  }

  const startTime = mergeDateAndTime(values.startDate, values.startTime);
  const endTime = addMinutes(addHours(startTime, values.hours), values.minutes);

  if (!isBefore(startTime, endTime)) {
    errors.startTime = '';
    errors.hours = 'Coverage period cannot end before current time';
    errors.minutes = 'Coverage period cannot end before current time';
  }

  const startDate = values.startDate ?? new Date();
  const endDate = values.endDate ?? new Date();
  if (!isBefore(startDate, endDate)) {
    errors.endDate = 'End date must be after start date';
  }

  return errors;
};

type PopulatedPeriodProps = {
  start: Date;
  end: Date;
  selectedCallPools: number[];
  providerId?: UserId;
};
type UnpopulatedPeriodProps = {
  start: Date;
  end: Date;
  selectedCallPools: number[];
  providerId?: UserId;
};

function generateRequest(
  type: OnCallPeriodType.Primary,
): (props: UnpopulatedPeriodProps) => CreateUnpopulatedPeriodRequest;
function generateRequest(
  type: OnCallPeriodType.Voluntary,
): (props: PopulatedPeriodProps) => CreatePopulatedPeriodRequest;
function generateRequest(
  type: OnCallPeriodType,
):
  | ((props: PopulatedPeriodProps) => CreatePopulatedPeriodRequest)
  | ((props: UnpopulatedPeriodProps) => CreateUnpopulatedPeriodRequest) {
  return function(properties: {
    start: Date;
    end: Date;
    selectedCallPools: number[];
    providerId?: UserId;
  }) {
    if (type === OnCallPeriodType.Voluntary) {
      return {
        type: OnCallPeriodType.Voluntary,
        start: properties.start,
        end: properties.end,
        selectedCallPools: properties.selectedCallPools,
        providerId: properties.providerId,
      };
    }
    return {
      type: OnCallPeriodType.Primary,
      start: properties.start,
      end: properties.end,
      selectedCallPools: properties.selectedCallPools,
    };
  };
}

const generateRequests = ({
  numberOfTermDays,
  termStartDate,
  values,
}: {
  numberOfTermDays: number;
  termStartDate: Date;
  values: RepeatingPeriodValues;
}) => {
  const {startDate, startTime, hours, minutes} = values;

  if (!startTime) {
    return [];
  }

  const anchorStartTime = mergeDateAndTime(startDate, startTime);

  const termDaysArray = new Array(numberOfTermDays).fill(0);

  return termDaysArray.reduce((periods, _, dayIndex) => {
    const termDay = addDays(termStartDate, dayIndex);
    const termDayStartStamp = mergeDateAndTime(
      termDay,
      format(anchorStartTime, 'HH:mm'),
    );
    const termDayDayOfWeekIndex = getDay(termDayStartStamp);

    const termDayNeedsShift = values.selectedDays.includes(
      WEEK_ARRAY[termDayDayOfWeekIndex],
    );

    if (termDayNeedsShift) {
      const termDayEndStamp = addMinutes(
        addHours(termDayStartStamp, hours),
        minutes,
      );

      let request;
      if (isSomething(values.providerId)) {
        request = generateRequest(OnCallPeriodType.Voluntary)({
          start: termDayStartStamp,
          end: termDayEndStamp,
          selectedCallPools: values.selectedCallPools,
          providerId: values.providerId,
        });
      } else {
        request = generateRequest(OnCallPeriodType.Primary)({
          start: termDayStartStamp,
          end: termDayEndStamp,
          selectedCallPools: values.selectedCallPools,
        });
      }
      return [...periods, request];
    }
    return periods;
  }, []);
};

export const getPopulatedRequests = (
  termStartDate: Date,
  endDate: Date,
  values: PopulatedValues<RepeatingPeriodValues>,
): CreatePopulatedPeriodRequest[] => {
  const numberOfTermDays = differenceInDays(endDate, termStartDate);
  return generateRequests({numberOfTermDays, termStartDate, values});
};

// eslint-disable-next-line import/no-unused-modules
export const getUnpopulatedRequests = (
  termStartDate: Date,
  endDate: Date,
  values: RepeatingPeriodValues,
): CreateUnpopulatedPeriodRequest[] => {
  const numberOfTermDays = differenceInDays(endDate, termStartDate);

  return generateRequests({numberOfTermDays, termStartDate, values});
};
