import React from 'react';
import {List, Collapse} from '@material-ui/core';
import {File} from './File';
import {AppFile} from '@src/models';

export function Files({
  files,
  open,
  omitActionButtons,
}: {
  open: boolean;
  files: readonly AppFile[];
  // TODO
  // `omitActionButtons` is a bit of a hack so I can get a hotfix out
  // without having to do a bunch of refactoring.
  // See PR for https://nea-fast.atlassian.net/browse/RCC-472.
  // In the future, all three buttons (download, edit, delete)
  // should go into this generic component.

  omitActionButtons?: boolean;
}) {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {files.map(file => (
          <File
            file={file}
            key={file.key}
            omitActionButtons={omitActionButtons}
          />
        ))}
      </List>
    </Collapse>
  );
}
