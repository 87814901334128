import {useTheme} from '@material-ui/core/styles';
import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useUser} from '@src/hooks/useUser';
import {email} from '@src/util/users/getDemographics';
import ConfigService from '@src/ConfigService';

const inProduction = () =>
  window.location.hostname === 'app.ahana.health' ||
  window.location.hostname === 'app.refyneconnectedcare.vynemedical.com';

const getHeightAndWidth = () => {
  const dE = document.documentElement;
  const width = Math.max(dE ? dE.clientWidth : 0, window.innerWidth || 0);
  const height = Math.max(dE ? dE.clientHeight : 0, window.innerHeight || 0);

  return {height, width};
};

const StyledUserInfoFooter = styled.div`
  font-size: smaller;
  font-style: italic;
  pointer-events: none;
  text-align: center;
  width: 100%;
  padding: 4px;
`;

export function UserInfoFooter() {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const theme = useTheme();
  const [user, userType] = useUser();

  const updateDimensions = useCallback(() => {
    const {height: h, width: w} = getHeightAndWidth();
    setHeight(h);
    setWidth(w);
  }, []);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [updateDimensions]);

  const releaseInfo = ConfigService.getReleaseInfo();
  const {RELEASE, RAVEN_ENVIRONMENT} = releaseInfo;

  const release = RELEASE.length > 15 ? RELEASE.slice(0, 8) : RELEASE;

  const releaseDetails = `release: ${release} | environment: ${RAVEN_ENVIRONMENT} | ${width}x${height}`;

  if (!inProduction()) {
    if (user.isLoaded()) {
      const userInfo = user.getOptional();
      return (
        <StyledUserInfoFooter
          style={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          }}
        >
          {userInfo.map(email).orElse('')} [{userType}(
          {userInfo.map(u => `${u.id}`).orElse('')}
          )] | {releaseDetails}
        </StyledUserInfoFooter>
      );
    }
    return <StyledUserInfoFooter>{releaseDetails}</StyledUserInfoFooter>;
  }

  return null;
}
