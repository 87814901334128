import {makeStyles} from '@material-ui/core/styles';
import {md} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  // Containers
  submitButtonContainer: {
    margin: `${md} 0`,
  },
  // Instructions
  comparerMessage: {
    padding: md,
    marginBottom: md,
  },
}));
