import {makeStyles} from '@material-ui/core/styles';
import {sm} from '@src/components/ui/theme';

export const useConnectedLoginStyles = makeStyles(theme => ({
  rootComponent: {
    margin: `${sm} 0`,
    color: theme.palette.common.white,
  },
  rowContainer: {
    width: '100%',
    paddingLeft: '1em',
    paddingRight: '1em',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    marginTop: '2em',
    marginBottom: '2em',
  },
  sectionTitle: {
    color: theme.palette.primary.contrastText,
  },
  paper: {
    backgroundColor: theme.palette.secondary.main,
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
    borderRadius: theme.spacing(0.5),
    color: theme.palette.secondary.contrastText,
  },
}));
