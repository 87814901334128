import React, {ReactNode} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Grid, useMediaQuery} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import {useStyles} from './layout';
import {LonelyPatient} from '@src/models';
import {UserAvatar} from '@src/components/ui/layout/UserAvatar';
import {isNothing} from '@src/util/typeTests';

type Props = {
  className?: string;
  edittable: boolean;
  isSelf: boolean;
  patient?: LonelyPatient;
  children: ReactNode;
  onClickConnectionAction: () => unknown;
  icon: 'pencil' | 'trash';
};
export function Connection({
  className = '',
  edittable,
  isSelf,
  patient,
  children,
  onClickConnectionAction,
  icon,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid
      container
      direction="row"
      className={className}
      spacing={0}
      alignItems="center"
    >
      {isSelf && matchesMedium && !isNothing(patient) && (
        <Grid item xs={1} className={classes.userAvatarGrid}>
          <UserAvatar user={patient} />
        </Grid>
      )}

      <Grid item className={classes.messageText} xs={10}>
        {children}
      </Grid>

      {edittable && (
        <Grid
          item
          xs={isSelf && matchesMedium ? 1 : 2}
          className={classes.actionGrid}
        >
          <FontAwesomeIcon
            icon={['fas', icon]}
            fixedWidth
            size="sm"
            className={classes.actionItem}
            onClick={onClickConnectionAction}
          />
        </Grid>
      )}
    </Grid>
  );
}
