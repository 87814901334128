import React, {useCallback, useMemo, useState} from 'react';
import SignaturePad, {PointGroup} from 'signature_pad';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Tab,
} from '@material-ui/core';
import {TabContext, TabList, TabPanel} from '@material-ui/lab';
import DrawInput from './DrawInput';
import TextInput from './TextInput';

export type SignatureChangeHandler = (event: {
  name: string | null;
  font: string | null;
  imageUri: string | null;
  signedAt: Date | null;
}) => void;

interface Props {
  name?: string | null;
  font?: string | null;
  imageUri?: string | null;
  onChange?: SignatureChangeHandler;
}

export default function EditView({
  name: defaultName,
  font: defaultFont = null,
  imageUri = null,
  onChange = () => {},
}: Props) {
  const [tab, setTab] = useState<'type' | 'draw'>(
    typeof imageUri === 'string' ? 'draw' : 'type',
  );

  const [{name, font}, setTypeInState] = useState<{
    name: string;
    font: string | null;
  }>({name: defaultName ?? '', font: defaultFont});
  const [pointGroups, setPointGroups] = useState<PointGroup[]>([]);

  const handleClear = useCallback(() => {
    if (tab === 'type') {
      setTypeInState({name, font: null});
    } else {
      setPointGroups([]);
    }

    onChange({name, imageUri, font: null, signedAt: null});
  }, [tab, name, imageUri, onChange]);

  const handleSign = useCallback(() => {
    if (tab === 'type') {
      onChange({name, font, imageUri: null, signedAt: new Date()});
    } else {
      const canvas = document.createElement('canvas');
      const signaturePad = new SignaturePad(canvas);
      signaturePad.fromData(pointGroups);

      onChange({
        name,
        font,
        imageUri: signaturePad.toDataURL('image/png'),
        signedAt: new Date(),
      });
    }
  }, [tab, name, font, pointGroups, onChange]);

  const complete = useMemo(() => {
    if (tab === 'type') {
      return name.length > 0 && font !== null && font.length > 0;
    } else {
      return pointGroups.length > 0;
    }
  }, [tab, name, font, pointGroups]);

  return (
    <Card style={{display: 'inline-block'}}>
      <CardContent>
        <TabContext value={tab}>
          <Box>
            <TabList onChange={(_, value) => setTab(value)}>
              <Tab label="Type" value="type" />
              <Tab label="Draw" value="draw" />
            </TabList>
          </Box>
          <TabPanel value="type">
            <TextInput name={name} font={font} onChange={setTypeInState} />
          </TabPanel>
          <TabPanel value="draw">
            <DrawInput data={pointGroups} onChange={setPointGroups} />
          </TabPanel>
        </TabContext>
      </CardContent>
      <CardActions>
        <Button onClick={handleClear}>Clear</Button>
        <Button variant="contained" disabled={!complete} onClick={handleSign}>
          Sign
        </Button>
      </CardActions>
    </Card>
  );
}
