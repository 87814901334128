import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {SCPStatus, UserType} from '@src/models';
import {sm} from '@src/components/ui/theme';

type Message = (isOwner?: boolean) => ReactNode;

const ActionList = styled.ul`
  margin-top: ${sm};
  list-style: circle inside;
`;

const StillLoading = {
  [SCPStatus.Draft]: () => 'This Shared Care Plan is still loading...',
  [SCPStatus.ApprovalExpired]: () =>
    'This Shared Care Plan is still loading...',
  [SCPStatus.OwnershipExpired]: () =>
    'This Shared Care Plan is still loading...',
  [SCPStatus.Approved]: () => 'This Shared Care Plan is still loading...',
  [SCPStatus.Retracted]: () => 'This Shared Care Plan is still loading...',
};

export const messages: Record<UserType, Record<SCPStatus, Message>> = {
  [UserType.Guardian]: {
    [SCPStatus.Draft]: () => (
      <>
        <div style={{marginBottom: '.5em'}}>
          This draft has not yet been reviewed and signed by a healthcare
          professional. Refyne Connected Care has been notified and will be
          reaching out to the provider who has been designated to own this plan
          or will help find a provider for this plan.
        </div>
        <div>Please fill in all the relevant details.</div>
        <ActionList>
          <li>Preferences</li>
          <li>Contacts</li>
          <li>Uploads (if appropriate)</li>
          <li>Medical History (if appropriate)</li>
        </ActionList>
      </>
    ),

    [SCPStatus.Approved]: () =>
      'This Shared Care Plan is active and accessible.',

    [SCPStatus.ApprovalExpired]: () =>
      'This Shared Care Plan is active and accessible but requires the owner to review and reapprove it.',

    [SCPStatus.OwnershipExpired]: () =>
      "This Shared Care Plan's ownership expired and is therefore currently inactive. Refyne Connected Care has been notified and will be in touch to connect a provider to This Shared Care Plan.",
    [SCPStatus.Retracted]: () =>
      'This Shared Care Plan has been retracted because a doctor was concerned that the plan had misleading information in it that could place this patient at risk. The plan will not be available to any user until it is reactivated. The owner has been notified.',
  },
  [UserType.Professional]: {
    [SCPStatus.Draft]: isOwner =>
      `${
        isOwner
          ? 'Please go to the Owner Controls tab to accept ownership for this Shared Care Plan.'
          : 'This draft has not yet been reviewed and signed by a healthcare professional. Please contact Refyne Connected Care if you want to become the Shared Care Plan owner.'
      }`,

    [SCPStatus.Approved]: () =>
      'This Shared Care Plan is active and accessible.',

    [SCPStatus.ApprovalExpired]: (isOwner = false) =>
      isOwner
        ? 'This Shared Care Plan is active and accessible but requires you to review and reapprove it.'
        : 'This Shared Care Plan is active and accessible but requires the owner to review and reapprove it.',

    [SCPStatus.OwnershipExpired]: () =>
      "This Shared Care Plan's ownership has expired and it is therefore currently inactive. Refyne Connected Care has been notified and is making arrangements to connect a provider to This Shared Care Plan.",
    [SCPStatus.Retracted]: (isOwner = false) =>
      `This Shared Care Plan has been retracted because a medical professional was concerned that the plan had misleading information in it that could place this patient at risk. The plan will not be available to any user until ${
        isOwner ? 'you reactive it' : 'it is reactivated by the owner'
      }. ${!isOwner ? 'The owner has been notified.' : ''} `,
  },
  [UserType.Admin]: {
    ...StillLoading,
  },
  [UserType.Unknown]: {
    ...StillLoading,
  },
  [UserType.System]: {
    ...StillLoading,
  },
};
