import React from 'react';
import {ClientConfigurationForm} from './ClientConfigurationForm';
import {PrivatePage} from '@src/components/PrivatePage';
import {PageHeader} from '@src/components/ui/layout';

// eslint-disable-next-line import/no-unused-modules
export default function CreateCustomerPage() {
  return (
    <PrivatePage>
      <PageHeader>Create a Customer</PageHeader>

      <ClientConfigurationForm />
    </PrivatePage>
  );
}
