import {Optional} from '@ahanapediatrics/ahana-fp';
import {useCallback} from 'react';
import {useApi} from '@src/api/useApi';
import {SharedCarePlan} from '@src/models';
import {useRequestHandler} from '@src/hooks/useRequestHandler';

const useDelete = ({
  scp,
  changeRequestId,
}: {
  scp: Optional<SharedCarePlan>;
  changeRequestId: number;
}) => {
  const api = useApi();

  return useCallback(() => {
    return api
      .scp(scp.map(m => m.id).orElse(0))
      .deleteChangeRequest(changeRequestId);
  }, [api, scp, changeRequestId]);
};

export const useOnDelete = ({
  scp,
  runBeforeRequest,
  asyncRunAfterRequest,
  runFinally,
  changeRequestId,
  runOnError,
}: {
  scp: Optional<SharedCarePlan>;
  runBeforeRequest: () => void;
  asyncRunAfterRequest: (scp: SharedCarePlan) => Promise<unknown>;
  runFinally: () => void;
  runOnError: () => void;
  changeRequestId: number;
}) => {
  const deleteChangeRequest = useDelete({
    scp,
    changeRequestId,
  });

  return useRequestHandler({
    runBeforeRequest,
    asyncRequest: deleteChangeRequest,
    asyncRunAfterRequest,
    runFinally,
    runOnError,
  });
};
