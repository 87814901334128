import React, {ReactNode} from 'react';
import {makeStyles, createStyles, Theme} from '@material-ui/core';

type BoldVariant = 'lighter' | 'bold';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 700,
    },
    lighter: {
      fontWeight: 400,
    },
  }),
);

type Props = {
  children: ReactNode | null;
  variant?: BoldVariant;
};

export function Bold({children, variant = 'bold'}: Props) {
  const classes = useStyles();

  return <span className={classes[variant]}>{children}</span>;
}
