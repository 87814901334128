import React from 'react';
import {DocumentForm} from '@src/components/separateAdminApp/CreateDocumentPage/DocumentForm';
import {createGenericContext} from '@src/util/reactContext/createGenericContext';
import {PageHeader} from '@src/components/ui/layout';
import {PrivatePage} from '@src/components/PrivatePage';
import {
  CreateDocumentPageResources,
  useCreateDocumentPageResources,
} from '@src/hooks/legalDocuments';

const [usePageContext, PageContextProvider] = createGenericContext<
  CreateDocumentPageResources
>();

// eslint-disable-next-line import/no-unused-modules
export default function CreateDocumentPage() {
  const resources = useCreateDocumentPageResources();

  return (
    <PageContextProvider value={resources}>
      <PrivatePage>
        <PageHeader>Create a Form</PageHeader>
        <DocumentForm />
      </PrivatePage>
    </PageContextProvider>
  );
}

export {usePageContext};
