import React, {useMemo} from 'react';
import {useApi} from '@src/api/useApi';
import {SelectInput} from '@src/components/ui/form/SelectInput';
import {useResources} from '@src/hooks';
import {AssignedForm, BlankForm} from '@src/models';

interface Props {
  forms: readonly AssignedForm[];
  newBlankFormId: number | null;
  setNewBlankFormId: (formId: number) => unknown;
}

export default function SelectBlankForm({
  forms,
  newBlankFormId,
  setNewBlankFormId,
}: Props) {
  const api = useApi();
  const [blankFormsPromise] = useResources<BlankForm>(
    () => api.blankForms().getAll(),
    [api],
  );

  const availableForms = useMemo(() => {
    const assignedFormIds = forms
      .filter(af => !af.completedAt.isPresent())
      .map(af => af.blankFormId);
    return blankFormsPromise
      .getAllOptional()
      .orElse([])
      .filter(f => !assignedFormIds.includes(f.id));
  }, [blankFormsPromise, forms]);
  return (
    <>
      <SelectInput
        value={newBlankFormId !== null ? `${newBlankFormId}` : undefined}
        name="form-select"
        placeholder="Select the form you want to assign"
        title={'Form'}
        options={availableForms.map(f => ({
          label: f.name,
          value: `${f.id}`,
        }))}
        onChange={e => setNewBlankFormId(Number(e.target.value))}
      />
    </>
  );
}
