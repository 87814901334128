import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useState} from 'react';
import {StepProps} from '../../EditPatient/functions';
import {EntryMode, ProviderSelector} from './ProviderSelector';
import {useUser} from '@src/hooks';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {ProviderDetails} from '@src/models';
import {FlowProps} from '@src/hooks/useSteps';
import {reference} from '@src/util/stringManipulation/languageHelpers';

/*
 * Adding `StepProps` here from either New or Edit Patient
 * causes a conflict in the other flow's `StepComponents`
 * definition, so we just add `patientIsCurrentUser`
 * explicitly
 */
export type Props = FlowProps & {
  details: Optional<ProviderDetails>;
  setDetails: (info: Optional<ProviderDetails>) => unknown;
  providerNotKnown: boolean;
  setProviderNotKnown: (b: boolean) => unknown;
  patientIsCurrentUser: boolean;
};

export function ProviderDetailsStep({
  moveGuard,
  details,
  setDetails,
  providerNotKnown,
  setProviderNotKnown,
  patientIsCurrentUser,
}: Props) {
  const [, userType] = useUser();
  const [error, setError] = useState('');
  const [entryMode, setEntryMode] = useState<EntryMode>(
    providerNotKnown
      ? 'unknown'
      : details
          .map<EntryMode>(p => (p.searchable ? 'search' : 'type'))
          .orElse(''),
  );

  const pr = reference('this patient', patientIsCurrentUser);

  const onChange = (p: StepProps['details']) => {
    setError('');
    setDetails(p);
  };

  return (
    <>
      <RequestHeader>Provider Details</RequestHeader>
      <RequestInstructions>
        <p>Now, please tell us about {pr('poss')} primary care provider.</p>
      </RequestInstructions>
      <ProviderSelector
        error={error}
        isPcp={true}
        details={details}
        onChange={onChange}
        providerDescription="Primary Care Provider"
        userType={userType}
        entryMode={entryMode}
        setEntryMode={setEntryMode}
        setIntentionallyAbsentProvider={setProviderNotKnown}
        intentionallyAbsentProvider={providerNotKnown}
        moveGuard={moveGuard}
        patientIsCurrentUser={patientIsCurrentUser}
      />
    </>
  );
}
