import React, {useCallback} from 'react';
import {useHistory} from 'react-router';
import styled from 'styled-components';
import {Link} from './layout';
import {SimpleVisit, VisitState} from '@src/models';
import {getVisitUrl} from '@src/util/visits/getVisitUrl';
import {Button} from '@src/components/ui/form/Button/index';

type UserProps = {
  directToRoom: boolean;
  hairChecked: boolean;
  visit: SimpleVisit;
};

type Props = UserProps;

export const StyledLink = styled(Link)``;

/**
 * This is a link for Providers to click to see a patient. It checks the state
 * of the visit and shows appropriate modals if the visit is not unclaimed
 * @constructor
 */
export function SeePatientLink({directToRoom, visit, hairChecked}: Props) {
  const history = useHistory();

  const enterRoom = useCallback(() => {
    const canConnect = !(
      visit.status === VisitState.DOCUMENTING ||
      visit.status === VisitState.COMPLETED
    );
    if (directToRoom) {
      return history.push(getVisitUrl(visit, hairChecked, canConnect));
    }
    return history.push(`/call-out/${visit.id}`);
  }, [directToRoom, hairChecked, history, visit]);

  return (
    <Button style={{fontSize: '12px'}} onClick={enterRoom}>
      Join Visit
    </Button>
  );
}
