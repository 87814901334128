import React, {useState} from 'react';
import {DrawerSkeleton} from './DrawerSkeleton';
import {PermissionsProps} from '.';
import {UserType} from '@src/models';
import {ApplicationEvents} from '@src/events';
import {useWindowEvent} from '@src/hooks';

type Props = {
  setShowLogoutModal?: (v: boolean) => unknown;
  onLogout?: () => unknown;
  userType: UserType;
  permissions: PermissionsProps;
};

export function Drawer({
  setShowLogoutModal,
  onLogout,
  permissions,
  userType,
}: Props) {
  const [openDrawer, setOpenDrawer] = useState(false);
  useWindowEvent(ApplicationEvents.MenuItemClicked, () => setOpenDrawer(false));

  return (
    <DrawerSkeleton
      openDrawer={openDrawer}
      setOpenDrawer={setOpenDrawer}
      setShowLogoutModal={setShowLogoutModal}
      onLogout={onLogout}
      userType={userType}
      permissions={permissions}
    />
  );
}
