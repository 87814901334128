import {makeStyles} from '@material-ui/core/styles';
import {md} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  rowContainer: {
    width: '100%',
    paddingLeft: '1em',
    paddingRight: '1em',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    marginTop: '2em',
    marginBottom: '2em',
  },
  leftSection: {
    backgroundColor: theme.palette.secondary.main,
    padding: '2rem',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      borderTopRightRadius: theme.spacing(0.5),
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  rightSection: {
    backgroundColor: theme.palette.secondary.main,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      borderBottomLeftRadius: theme.spacing(0.5),
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: theme.spacing(0.5),
    },
  },
  regularCell: {
    color: theme.palette.secondary.contrastText,
    fontSize: 16,
    fontWeight: 300,
    paddingLeft: 0,
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  title: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 700,
  },
  icon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 16,
    marginRight: 'auto',
    padding: 0,
  },
  button: {
    fontSize: '12px',
    textTransform: 'none',
    '&:hover': {
      borderColor: theme.palette.common.white,
    },
    padding: 0,
    marginBottom: '4px',
    marginLeft: '4px',
  },
  detailsSection: {
    marginBottom: md,
  },
  sectionTitle: {
    color: theme.palette.secondary.contrastText,
  },
  patientMenu: {
    color: theme.palette.secondary.contrastText,
  },
}));
