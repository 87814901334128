import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {isBefore, subHours} from 'date-fns';
import {SimpleVisit, VisitState} from '../../../../models';

export const getCurrentOrImpendingVisits = (visits: AsyncData<SimpleVisit>) =>
  visits.filter(visitIsOpen);

/**
 *
 * @param visit
 */
export const visitIsOpen = (visit: SimpleVisit): boolean =>
  (visit.status === VisitState.CREATED && !visit.isSessionComplete) ||
  (visit.status === VisitState.IN_PROGRESS &&
    !visit.isSessionComplete &&
    !isBefore(visit.lastTransition, subHours(new Date(), 24)));
