import React from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {Context, FormMode} from '../../../../util/provider/forms/addOrEdit';
import {ManualEntryOption} from './ManualEntryOption';
import {IntentionallyAbsentOption} from './IntentionallyAbsentOption';
import {SearchOption} from './SearchOption';
import {ProviderDetails, UserType} from '@src/models';
import {ProviderDetailsId} from '@src/models/ProviderDetails';

type Props = {
  onChange: (details: Optional<ProviderDetails>, type: FormMode) => void;
  details: Optional<ProviderDetails>;
  userType: UserType;
  selectionOrEditContext: Context;
  noProviderSelected: boolean;
  showDetails: boolean;
  entryMode: 'search' | 'type';
  setShowDetails: (v: boolean) => void;
  setEntryMode: (v: 'search' | 'type') => void;
  setNoProviderSelected: (v: boolean) => void;
  sharedCarePlanContextOptions?: {
    hideManualOption?: boolean;
    setNoSCPOwner?: () => void;
  };
};

export function ChooseEntryType({
  details,
  onChange,
  userType,
  selectionOrEditContext,
  noProviderSelected,
  showDetails,
  entryMode,
  setShowDetails,
  setEntryMode,
  setNoProviderSelected,
  sharedCarePlanContextOptions = {},
}: Props) {
  const {hideManualOption, setNoSCPOwner} = sharedCarePlanContextOptions;

  const searchable = details.map(p => p.searchable).orElse(false);

  const searchForProvider = !noProviderSelected && !showDetails;
  const editingAppUserTeamMember =
    details.map(p => p.id).orElse(0 as ProviderDetailsId) !== 0 &&
    selectionOrEditContext === Context.EditMedicalTeamMember;
  const enterProvider =
    !noProviderSelected && (showDetails || entryMode === 'type') && !searchable;
  const knownProvider = (showDetails || entryMode === 'type') && searchable;

  return (
    <div>
      {!searchForProvider &&
        selectionOrEditContext !== Context.EditMedicalTeamMember && (
          <SearchOption
            knownProvider={knownProvider}
            onChange={onChange}
            setEntryMode={setEntryMode}
            setShowDetails={setShowDetails}
            setNoProviderSelected={setNoProviderSelected}
          />
        )}

      {!enterProvider && !hideManualOption && !editingAppUserTeamMember && (
        <ManualEntryOption
          onChange={onChange}
          setEntryMode={setEntryMode}
          setShowDetails={setShowDetails}
          setNoProviderSelected={setNoProviderSelected}
        />
      )}

      {!noProviderSelected &&
        selectionOrEditContext !== Context.AddMedicalTeamMember &&
        selectionOrEditContext !== Context.EditMedicalTeamMember && (
          <IntentionallyAbsentOption
            onChange={onChange}
            setEntryMode={setEntryMode}
            setShowDetails={setShowDetails}
            setNoProviderSelected={setNoProviderSelected}
            userType={userType}
            selectionOrEditContext={selectionOrEditContext}
            setNoSCPOwner={setNoSCPOwner}
          />
        )}
    </div>
  );
}
