import {ApplicationException} from '@src/api/exceptions';
import {
  DateResponse,
  AssignedForm,
  MultipleChoiceResponse,
  TextResponse,
  NumericResponse,
  StaticContent,
} from '@src/models';

// Add new element types to this union type.
type Element =
  | DateResponse
  | MultipleChoiceResponse
  | TextResponse
  | NumericResponse
  | StaticContent;

function getFieldFromResponse(response: Element) {
  // Add new element types to this if-else structure.
  if (response instanceof DateResponse) {
    return response.dateField.get();
  }
  if (response instanceof MultipleChoiceResponse) {
    return response.multipleChoiceField.get();
  }
  if (response instanceof TextResponse) {
    return response.textField.get();
  }
  if (response instanceof NumericResponse) {
    return response.numericField.get();
  }
  // StaticContent can be passed directly through
  if (response instanceof StaticContent) {
    return response;
  }

  throw new ApplicationException(
    `Unknown response type: ${JSON.stringify(response)}`,
  );
}

/**
 * Takes an assigned form and returns a heterogeneous array of field responses
 * sorted first by ordinal value, then by createdAt date as a fallback.
 */
export default function getSortedFormElements(
  assignedForm: AssignedForm,
): Element[] {
  // Add new response fields to this array.
  return [
    ...assignedForm.dateResponses.get(),
    ...assignedForm.multipleChoiceResponses.get(),
    ...assignedForm.textResponses.get(),
    ...assignedForm.numericResponses.get(),
    ...assignedForm.blankForm.map(bf => bf.staticContent).get(),
  ].sort((a: Element, b: Element) => {
    const aField = getFieldFromResponse(a);
    const bField = getFieldFromResponse(b);

    const ordinalDelta = aField.ordinal - bField.ordinal;

    if (ordinalDelta === 0) {
      return aField.createdAt.getTime() - bField.createdAt.getTime();
    }

    return ordinalDelta;
  });
}
