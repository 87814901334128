import {Typography} from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import {md} from '../../../ui/theme';

const FormSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${md};
`;

type Props = {
  title: string;
  className?: string;
  children?: React.ReactNode;
};
export function FormSection({children, title, className = ''}: Props) {
  return (
    <FormSectionStyled className={className}>
      <Typography variant="h5">{title}</Typography>
      {children}
    </FormSectionStyled>
  );
}

export default FormSection;
