import {useEffect, useRef} from 'react';
import {Handler, MessageType, useSocketContext} from '@src/contexts';
import {Patient} from '@src/models';

export const usePatientUpdateEvents = (
  patientIds: readonly number[],
  listener: Handler<MessageType.PatientUpdate>,
) => {
  const socket = useSocketContext();

  const patientIdsRef = useRef(patientIds);
  const listenerRef = useRef(listener);

  useEffect(() => {
    patientIdsRef.current = patientIds;
  }, [patientIds]);

  useEffect(() => {
    listenerRef.current = listener;
  }, [listener]);

  useEffect(() => {
    const handlePatientUpdate = (patient: Patient) => {
      if (patientIdsRef.current.includes(patient.id)) {
        listenerRef.current(patient);
      }
    };

    socket.on(MessageType.PatientUpdate, handlePatientUpdate);

    return () => {
      socket.off(MessageType.PatientUpdate, handlePatientUpdate);
    };
  }, [socket]);
};
