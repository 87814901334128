import React from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Grid, Typography} from '@material-ui/core';
import {useStyles} from './layout';
import {AssignedForm} from '@src/models';

type Props = {
  forms: readonly AssignedForm[];
  isCompleted?: boolean;
};

export function FormRow({forms, isCompleted = false}: Props) {
  const formLabel = isCompleted ? 'Completed Forms' : 'Pending Assigned Forms';
  const formIcon = isCompleted ? 'file' : 'pencil';
  const classes = useStyles();

  if (forms.length === 0) {
    return null;
  }

  return (
    <div className={classes.rootComponent}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h3" className={classes.sectionTitle}>
            {formLabel}
          </Typography>
          <Grid container className={classes.formList} direction="column">
            {forms.map(form => (
              <Grid
                key={form.id}
                container
                className={classes.formListRow}
                direction="row"
                spacing={0}
                alignItems="center"
              >
                <Grid item className={classes.messageText} xs={10}>
                  <b>{form.blankForm.get().name}</b>
                </Grid>
                <Grid item xs={2} className={classes.actionGrid}>
                  <Link to={`/form/${form.id}`}>
                    <FontAwesomeIcon
                      icon={['fas', formIcon]}
                      fixedWidth
                      size="sm"
                      className={classes.actionItem}
                    />
                  </Link>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
