import {yupResolver} from '@hookform/resolvers';
import {FormControlLabel, Switch} from '@material-ui/core';
import * as React from 'react';
import {useState, useCallback} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {Controller, useForm} from 'react-hook-form';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {AsyncActionButton, TextInput} from '../../../ui/form';
import {AddressInput} from '../../../ui/form/AddressInput';
import {DateInput} from '../../../ui/form/DateInput';
import {PhoneInput} from '../../../ui/form/PhoneInput';
import {Modal} from '../../../ui/layout/Modal';
import {flashError} from '../../notifications/flash';
import {
  responsiblePersonToValues,
  FinanciallyResponsiblePersonValues,
  valuesToResponsiblePerson,
} from './functions';
import {ContactInformation, PersonInformation} from './layout';
import {ConflictingPersonModal} from './ConflictingPersonModal';
import {getKeys} from '@src/util/objectManipulation/getKeys';
import {ResourceConflictException} from '@src/api/exceptions';
import {
  Guardian,
  responsiblePersonSchema,
  UserType,
  PatientRelationship,
  Patient,
  ResponsiblePerson,
} from '@src/models';
import {useApi} from '@src/api/useApi';
import {useUser} from '@src/hooks/useUser';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

type OwnProps = {
  onDone: () => unknown;
  onUpdatePatient: () => Promise<unknown>;
  patient: Patient;
  relationship: Optional<PatientRelationship>;
  userRelationshipWithPatient: Optional<PatientRelationship>;
  show: boolean;
};

type Props = OwnProps;

export function FinanciallyResponsiblePersonFormModal({
  onDone,
  onUpdatePatient,
  patient,
  relationship,
  userRelationshipWithPatient,
  show,
}: Props) {
  const api = useApi();
  const [user] = useUser();
  const {
    clearErrors,
    control,
    handleSubmit,
    formState,
    errors,
    getValues,
    setValue,
  } = useForm<FinanciallyResponsiblePersonValues>({
    defaultValues: responsiblePersonToValues(
      relationship.map(r => r.person),
      relationship.property('relationship', ''),
    ),
    resolver: yupResolver(responsiblePersonSchema),
  });
  const [personId, setPersonId] = useState<NonProfessionalId>(
    relationship.property('personId', 0 as NonProfessionalId),
  );
  const [conflictingPerson, setConflictingPerson] = useState(
    Optional.empty<ResponsiblePerson>(),
  );
  const [conflictingRelationship, setConflictingRelationship] = useState('');
  const {touched, isSubmitting} = formState;

  const setValues = useCallback(
    (values: FinanciallyResponsiblePersonValues) => {
      getKeys(values).forEach(
        (key: keyof FinanciallyResponsiblePersonValues) => {
          setValue(key, values[key], {shouldDirty: true});
        },
      );
    },
    [setValue],
  );

  const userAsPerson = user
    .getOptional()
    .cast<Guardian>(u => u.userType === UserType.Guardian)
    .map(g => g.responsiblePersonDetails);

  const userId = userAsPerson.property('id', 0 as NonProfessionalId);
  const personUserId = relationship
    .map(r => r.person)
    .map(p => p.guardianId)
    .orElse(0);

  const personIsDifferentUser = personId !== 0 && personUserId !== userId;

  const onSubmit = async () => {
    const {relationship: relName} = getValues();
    const rpData = valuesToResponsiblePerson(getValues());
    try {
      let newRP;
      if (personId === 0) {
        newRP = await api.responsiblePerson().create(rpData);
      } else {
        newRP = await api
          .responsiblePerson(personId)
          .update({...rpData, id: personId});
      }

      await api
        .patient(patient.id)
        .setFinanciallyResponsiblePerson(newRP.id, relName);
      await onUpdatePatient();
      onDone();
    } catch (e) {
      if (e instanceof ResourceConflictException) {
        const {location} = e.details ?? {};
        const [, id] = location.match(/\/responsiblePeople\/(\d+)/) ?? [];
        if (id) {
          const rpId = +id as NonProfessionalId;
          const [preExisting, preExistingRel] = await Promise.all([
            api.responsiblePerson(rpId).get(),
            api.responsiblePerson(rpId).getRelationshipName(patient.id),
          ]);
          setConflictingPerson(Optional.of(preExisting));
          setConflictingRelationship(preExistingRel);
        }
      } else {
        flashError(e.message);
      }
    }
  };

  const toggleMyDetails = useCallback(() => {
    clearErrors();
    let values: FinanciallyResponsiblePersonValues;

    if (personId === userId) {
      setPersonId(0 as NonProfessionalId);
      setConflictingPerson(Optional.empty());
      setConflictingRelationship('');
      values = responsiblePersonToValues(Optional.empty());
      values.relationship = '';
    } else {
      setPersonId(userId);
      setConflictingPerson(Optional.empty());
      setConflictingRelationship('');
      values = responsiblePersonToValues(userAsPerson);
      values.relationship = userRelationshipWithPatient.property(
        'relationship',
        '',
      );
    }
    setValues(values);
  }, [
    clearErrors,
    setPersonId,
    setConflictingPerson,
    setConflictingRelationship,
    setValues,
    userAsPerson,
    userId,
    personId,
    userRelationshipWithPatient,
  ]);

  return (
    <>
      <Modal
        show={show}
        title="Responsible person"
        onClose={() => {
          onDone();
        }}
        modalActions={
          <>
            <AsyncActionButton
              actionInProgress={isSubmitting}
              actionWord="Save"
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
            />
          </>
        }
      >
        <PersonInformation>
          <div>
            {userAsPerson.isPresent() && (
              <FormControlLabel
                control={
                  <Switch
                    name="useMyDetails"
                    checked={personId === userId}
                    color="primary"
                    inputProps={{'aria-label': 'checkbox'}}
                    onChange={toggleMyDetails}
                  />
                }
                label="Use my details"
              />
            )}
          </div>

          <div>
            <Controller
              control={control}
              as={TextInput}
              name="firstName"
              title="First Name"
              required
              error={errors.firstName?.message}
              touched={touched.firstName}
            />
            <Controller
              control={control}
              as={TextInput}
              name="lastName"
              title="Last Name"
              required
              error={errors.lastName?.message}
              touched={touched.lastName}
            />
            <Controller
              control={control}
              as={TextInput}
              name="relationship"
              title="Relationship with patient"
              required
              error={errors.relationship?.message}
              touched={touched.relationship}
            />
            <Controller
              control={control}
              required
              name="dob"
              error={errors.dob?.message}
              touched={touched.dob}
              render={({onChange, value}) => (
                <DateInput
                  required
                  name="dob"
                  label="Responsible Person's date of birth"
                  value={value}
                  onChange={onChange}
                  error={errors.dob?.message}
                />
              )}
            />
          </div>
        </PersonInformation>

        <ContactInformation>
          <Controller
            control={control}
            style={{display: personIsDifferentUser ? 'none' : ''}}
            as={TextInput}
            name="email"
            title="Responsible Person's email address"
            required
            error={errors.email?.message}
            touched={touched.email}
          />
          <Controller
            control={control}
            as={PhoneInput}
            name="phone"
            title="Responsible Person's phone number"
            required
            error={errors.phone?.message}
            touched={touched.phone}
          />
          <Controller
            required
            name="address"
            title="Responsible Person's date of birth"
            control={control}
            render={({onChange, value}) => (
              <AddressInput
                required
                value={value}
                onChange={onChange}
                error={errors.address?.line1?.message}
                touched={touched.address ? touched.address.line1 : false}
              />
            )}
          />
        </ContactInformation>
      </Modal>
      <ConflictingPersonModal
        conflictingPerson={conflictingPerson}
        setConflictingPerson={setConflictingPerson}
        setPersonId={setPersonId}
        conflictingRelationship={conflictingRelationship}
        setConflictingRelationship={setConflictingRelationship}
        setValues={setValues}
      />
    </>
  );
}
