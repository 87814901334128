import {AsyncData} from '@ahanapediatrics/ahana-fp';
import React from 'react';
import {Grid} from '@material-ui/core';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {CareTeam} from '@src/components/shared/PatientDashboard/SCP/panels/Contacts/CareTeam';
import {ContactInfo} from '@src/components/shared/PatientDashboard/SCP/panels/Contacts/ContactInfo';
import {UserType, PatientRelationship} from '@src/models';
import {useUser} from '@src/hooks';

type Props = {
  guardians: AsyncData<PatientRelationship>;
};

export function Contacts({guardians}: Props) {
  const [user] = useUser();
  const {
    scp,
    patient,
    setScp,
    setPatient,
    reviewMode,
    selectedPanel,
  } = usePageContext();

  const oScp = scp.getOptional();

  const isProfessional = user
    .getOptional()
    .filter(u => u.userType === UserType.Professional)
    .isPresent();

  const canCurrentUserEdit = isProfessional || !reviewMode;

  if (selectedPanel !== 'contacts') return null;

  return (
    <Grid container direction="row">
      <ContactInfo
        instructions="Please list important family, friends or caregivers to contact in the case of an emergency. (Doctors and Medical Personnel should be listed within the Medical Team."
        onUpdateScp={m => setScp(m)}
        patient={patient}
        updatePatient={setPatient}
        canEdit={canCurrentUserEdit}
        user={user}
        guardians={guardians}
      />
      <CareTeam
        scp={oScp}
        instructions="Please include important providers involved in the patient's care. This can include providers in the community or even specialists out of state."
        patient={patient}
        canEdit={canCurrentUserEdit}
        onUpdatePatient={setPatient}
        viewerType={user
          .getOptional()
          .map(u => u.userType)
          .orElse(UserType.Unknown)}
      />
    </Grid>
  );
}
