import {AsyncData} from '@ahanapediatrics/ahana-fp';
import React, {useState} from 'react';
import {
  PatientInfoHead,
  ProviderVisitData,
  ProviderVisitHead,
  ProviderVisitInfo,
  ProviderVisitTable,
} from './layout';
import {MedicalHistory, LonelyVisit} from '@src/models';
import {Button} from '@src/components/ui/form';
import {PatientFundamentals} from '@src/components/shared/PatientDashboard/PatientFundamentals';
import {PatientOverview} from '@src/components/shared/PatientOverview';
import {Modal} from '@src/components/ui/layout/Modal';
import {providerAndQualification} from '@src/util/provider';

type Props = {
  currentMedicalHistory: AsyncData<MedicalHistory>;
  isMA?: boolean;
  visit: LonelyVisit;
};

export function VisitInformation({currentMedicalHistory, isMA, visit}: Props) {
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);

  const {healthcareNotes, chiefComplaint, fallbackNumber, patient} = visit;
  return (
    <div className={'visit-information'}>
      <PatientInfoHead>
        <PatientFundamentals
          currentMedicalHistory={currentMedicalHistory}
          patient={patient}
          color="dark"
        />
      </PatientInfoHead>
      <ProviderVisitTable
        className="provider-visit--table"
        style={{width: '100%'}}
      >
        <tbody>
          <ProviderVisitInfo>
            <ProviderVisitHead>Guardian Phone Number:</ProviderVisitHead>
            <ProviderVisitData>
              {fallbackNumber || 'Not yet given'}
            </ProviderVisitData>
          </ProviderVisitInfo>

          <ProviderVisitInfo>
            <ProviderVisitHead>Visit notes:</ProviderVisitHead>
            <ProviderVisitData>
              {healthcareNotes || 'None given'}
            </ProviderVisitData>
          </ProviderVisitInfo>

          <ProviderVisitInfo>
            <ProviderVisitHead>Chief Complaint:</ProviderVisitHead>
            <ProviderVisitData>
              {chiefComplaint || 'None given'}
            </ProviderVisitData>
          </ProviderVisitInfo>

          {isMA && (
            <ProviderVisitInfo>
              <ProviderVisitHead>Visit Provider:</ProviderVisitHead>
              <ProviderVisitData>
                {visit.providerDetails
                  .map(p => `Dr. ${p.fullName}`)
                  .orElse('None given')}
              </ProviderVisitData>
            </ProviderVisitInfo>
          )}

          <ProviderVisitInfo>
            <ProviderVisitHead>PCP:</ProviderVisitHead>
            <ProviderVisitData>
              {patient.pcp.map(providerAndQualification).orElse('None given')}
            </ProviderVisitData>
          </ProviderVisitInfo>
        </tbody>
      </ProviderVisitTable>
      <Button bStyle="outlined" onClick={() => setShowMoreInfoModal(true)}>
        More Info
      </Button>
      <Modal
        show={showMoreInfoModal}
        title="Patient Info"
        onClose={() => setShowMoreInfoModal(false)}
        fullScreen
      >
        <PatientOverview
          patient={patient}
          inExam={true}
          updatePatient={() => {
            console.warn(
              'Attempted to update Patient while in an Exam which is not supported',
            );
          }}
        />
      </Modal>
    </div>
  );
}
