import {BasicDetailsUIElement} from './getBasicDetailsRows';
import {BasicDetailsResult} from './resultFunctions';

export const defaultBasicDetailsResult = {
  firstName: {
    text: '',
    radioSelection: null,
  },
  lastName: {text: '', radioSelection: null},
  genderAssignedAtBirth: {text: '', radioSelection: null},
  dob: {text: '', radioSelection: null},
  pronouns: {text: '', radioSelection: null},
  phone: {text: '', radioSelection: null},
  nickName: {text: '', radioSelection: null},
};

export function populateResultWithLoadedData({
  rows,
  result,
  setResult,
}: {
  rows: BasicDetailsUIElement[];
  setResult: (r: BasicDetailsResult) => unknown;
  result: BasicDetailsResult;
}): void {
  const newResult = rows.reduce((oldResult, row) => {
    const match = row.patientOne === row.patientTwo;
    return {
      ...oldResult,
      [row.name]: {
        text: match ? row.patientOne : '',
        radioSelection: match ? 'patientOne' : null,
      },
    };
  }, result);

  setResult(newResult);
}
