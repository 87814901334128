import {Optional} from '@ahanapediatrics/ahana-fp';
import {useCallback, useState, useMemo} from 'react';
import {useApi} from '@src/api/useApi';
import {SCPChangeRequest, SharedCarePlan} from '@src/models';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';

export const useOnDeleteChangeRequest = ({
  changeRequest,
  scp,
  reloadChangeRequests,
  handleReloaded = () => {},
}: {
  changeRequest: SCPChangeRequest;
  scp: Optional<SharedCarePlan>;
  reloadChangeRequests: () => Promise<unknown>;
  handleReloaded?: () => unknown;
}): {
  onDelete: () => Promise<unknown>;
  deleting: boolean;
  setDeleting: (v: boolean) => unknown;
} => {
  const [deleting, setDeleting] = useState(false);

  const api = useApi();
  const onDelete = useCallback(() => {
    setDeleting(true);

    return api
      .scp(scp.map(m => m.id).orElse(0))
      .deleteChangeRequest(changeRequest.id)
      .then(reloadChangeRequests)
      .then(() => {
        flashSuccess('Change Request deleted');
      })
      .catch(() => {
        flashError(
          'Something went wrong deleting this Change Request. Please refresh and try again or contact Support.',
        );
      })
      .finally(() => {
        setDeleting(false);
        handleReloaded();
      });
  }, [
    scp,
    changeRequest,
    setDeleting,
    api,
    reloadChangeRequests,
    handleReloaded,
  ]);

  return useMemo(() => ({onDelete, deleting, setDeleting}), [
    setDeleting,
    onDelete,
    deleting,
  ]);
};
