import React, {useState} from 'react';
import {Professional} from '../../../models';
import {Button, PhoneInput} from '../../ui/form';
import {Modal} from '../../ui/layout/Modal';
import {canonicalizeNumber} from '@src/util/numberManipulation/phone/canonicalizeNumber';
import {useApi} from '@src/api/useApi';
import {BadUserInput} from '@src/api/exceptions';
import {UserId} from '@src/models/User';

const PHONE_REGEX = /^(?:\+1)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

type Props = {
  onCallNumber: string;
  onUpdate: (provider: Professional) => void;
  onClose: () => void;
  providerId: UserId;
  show: boolean;
};

export const CallbackNumberModal = ({
  onCallNumber,
  onUpdate,
  onClose,
  providerId,
  show,
}: Props) => {
  const api = useApi();
  const [number, setNumber] = useState(canonicalizeNumber(onCallNumber));
  const [isSubmitting, setSubmitting] = useState(false);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = () => {
    setTouched(true);
    if (!PHONE_REGEX.test(number)) {
      setError('Please provide a valid 10 digit phone number');
      return;
    }
    setSubmitting(true);
    api
      .provider(providerId)
      .updateOnCallNumber(number)
      .then((provider: Professional) => {
        onUpdate(provider);
        setSubmitting(false);
        onClose();
      })
      .catch(e => {
        if (e instanceof BadUserInput) {
          setError(
            'Please make sure the phone number is valid and can receive SMS texts',
          );
        } else {
          setError('There was a problem. Please contact support for help.');
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal
      show={show}
      title="On Call Number"
      onClose={onClose}
      modalActions={
        <Button
          disabled={isSubmitting}
          bSize="small"
          bStyle="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      }
    >
      <div>
        <p>Please provide a number that can receive SMS text messages.</p>
        <PhoneInput
          required
          title="On Call Number"
          instructions="This is the number that you will receive alerts on when there is an out of hours call that required your attention"
          placeholder="Please enter your full ten-digit cell phone number"
          disabled={isSubmitting}
          name="onCallNumber"
          value={number}
          onChange={phone => {
            setTouched(true);
            setNumber(phone);
          }}
          touched={touched}
          error={error}
        />
      </div>
    </Modal>
  );
};
