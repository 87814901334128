import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router';
import {NetworkQualityStats} from 'twilio-video';
import {
  AudioVideoIndicator,
  NetworkIndicator,
} from '../../../VideoChat/VideoGallery/ParticipantVideo/indicators';
import {LocalParticipantControls} from './LocalParticipantControls';
import {AnonymousParticipantVideoProps} from './types';
import {NetworkQualityLevel} from '@src/util/videoChat/twilio/shared/participants/utils/networkQuality/networkQuality';
import {attachParticipantEventListeners} from '@src/util/videoChat/twilio/anonymousVisitor';
import {
  attachAudioTrackEventListeners,
  attachVideoTrackEventListeners,
  doesParticipantRequirePermission,
  onToggleLocalAudio,
  onToggleLocalVideo,
  preventUnnecessaryStreaming,
  syncComponentTrackStateWithTwilio,
  syncTwilioTrackWithComponentState,
  trackpubsToTracks,
  Tracks,
} from '@src/util/videoChat';
import {useVideoStyles} from '@src/components/ui/layout/VideoStyles';

function AnonymousParticipantVideo({
  participant,
  predicates: {
    isLocal,
    cutRoomAudio,
    isAnyoneScreensharing,
    isMainScreenElement,
    isLocalScreensharing,
    doNotTransmit,
    selfWaitingForAdmittance,
  },
  room,
  onVideoTracksStateChange,
  onAdmittedProps: {audioIn, audioOut, videoIn, onMediaFailure},
  setSelfWaitingForAdmittance,
  visitId,
}: AnonymousParticipantVideoProps) {
  const videoClasses = useVideoStyles();

  const history = useHistory();
  const [videoTracks, setVideoTracks] = useState<Tracks>([]);
  const [audioTracks, setAudioTracks] = useState<Tracks>([]);

  const [isMuted, setIsMuted] = useState(false);
  const [isVideoDisabled, setIsVideoDisabled] = useState(false);

  const [networkQualityLevel, setNetworkQualityLevel] = useState<
    NetworkQualityLevel
  >(0);
  const [
    networkQualityStats,
    setNetworkQualityStats,
  ] = useState<NetworkQualityStats | null>(null);

  const videoRef = useRef() as React.MutableRefObject<HTMLVideoElement>;
  const audioRef = useRef() as React.MutableRefObject<HTMLAudioElement>;

  // If video is disabled, force a black square to render rather than
  // the last frame from the end of the stream.
  const blackSquareTag = (
    <video
      style={{background: 'black'}}
      hidden={
        doNotTransmit ||
        !isVideoDisabled ||
        doesParticipantRequirePermission({participant})
      }
    />
  );

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    attachParticipantEventListeners({
      participant,
      setAudioTracks,
      setVideoTracks,
      room,
      onAdmittedProps: {audioIn, audioOut, videoIn, onMediaFailure},
      history,
      setSelfWaitingForAdmittance,
      visitId,
      networkQualityDataSetters: {
        setNetworkQualityStats,
        setNetworkQualityLevel,
      },
      isLocal,
    });

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
    };
  }, [participant]);

  useEffect(() => {
    onVideoTracksStateChange();

    const videoTrack = videoTracks[0];

    videoTrack?.attach(videoRef.current);

    if (videoTrack && !isLocal) {
      syncComponentTrackStateWithTwilio({
        setComponentTrackState: setIsVideoDisabled,
        componentTrackState: isVideoDisabled,
        track: videoTrack,
      });
    }

    attachVideoTrackEventListeners({
      videoTracks,
      videoTrack,
      setIsVideoDisabled,
      isLocal,
    });

    return () => {
      videoTracks.forEach(t => {
        t?.detach(videoRef.current);
      });
    };
  }, [videoTracks, selfWaitingForAdmittance]);

  useEffect(() => {
    const audioTrack = audioTracks[0];

    if (audioTrack && !isLocal) {
      audioTrack?.attach(audioRef.current);

      syncComponentTrackStateWithTwilio({
        setComponentTrackState: setIsMuted,
        componentTrackState: isMuted,
        track: audioTrack,
      });

      attachAudioTrackEventListeners({
        audioTracks,
        audioTrack,
        setIsMuted,
        isLocal,
      });

      return () => {
        audioTracks.forEach(t => {
          t?.detach(audioRef.current);
        });
      };
    }
  }, [audioTracks, selfWaitingForAdmittance]);

  useEffect(() => {
    if (isLocal) {
      onToggleLocalAudio({audioTracks, shouldAudioBeMuted: isMuted});
    }
  }, [isMuted]);

  useEffect(() => {
    if (isLocal) {
      onToggleLocalVideo({videoTracks, shouldVideoBeDisabled: isVideoDisabled});
    }
  }, [isVideoDisabled]);

  useEffect(() => {
    if (!isLocal) {
      return;
    }

    if (doNotTransmit) {
      preventUnnecessaryStreaming({room});
    } else {
      syncTwilioTrackWithComponentState({isMuted, room, isVideoDisabled});
    }
  }, [doNotTransmit]);

  return (
    <>
      <div
        className={
          !isMainScreenElement
            ? videoClasses.videoContainer
            : videoClasses.mainScreenElement
        }
        style={{display: doNotTransmit ? 'none' : 'block'}}
      >
        {!doNotTransmit && (
          <>
            <AudioVideoIndicator
              isVideoDisabled={isVideoDisabled}
              isMuted={isMuted}
            />
            <NetworkIndicator
              networkQualityDataSetters={{
                setNetworkQualityStats,
                setNetworkQualityLevel,
              }}
              isLocal={isLocal}
              networkQualityData={{networkQualityLevel, networkQualityStats}}
              visitId={visitId}
              room={room}
            />
          </>
        )}

        <video
          hidden={
            doNotTransmit ||
            isVideoDisabled ||
            doesParticipantRequirePermission({participant})
          }
          ref={videoRef}
          autoPlay={true}
          style={{background: 'black'}}
          className="participantVideo"
        />

        {blackSquareTag}

        {!isLocal && (
          <audio
            muted={cutRoomAudio || doNotTransmit}
            ref={audioRef}
            autoPlay={true}
          />
        )}
      </div>

      {isLocal && !doNotTransmit && (
        <LocalParticipantControls
          isVideoDisabled={isVideoDisabled}
          isMuted={isMuted}
          setIsVideoDisabled={setIsVideoDisabled}
          setIsMuted={setIsMuted}
          room={room}
          isAnyoneScreensharing={isAnyoneScreensharing}
          setVideoTracks={setVideoTracks}
          isLocalScreensharing={isLocalScreensharing}
        />
      )}
    </>
  );
}

export default AnonymousParticipantVideo;
