import React from 'react';
import {Controller} from 'react-hook-form';
import {usePageContext} from '..';
import {useStyles} from '@src/components/separateAdminApp/CreateProviderGroupPage/styles';
import {TextInput} from '@src/components/ui/form';
import {HelpToolTip} from '@src/components/ui/atoms/buttonsAndIcons/HelpToolTip';
import {isNothing} from '@src/util/typeTests';

export function TextInputs() {
  const classes = useStyles();
  const {
    formResources: {control, errors, emailTooltipText, professionalAlreadyUser},
  } = usePageContext();

  return (
    <Controller
      name="email"
      control={control}
      render={({onChange, onBlur, value, name}) => (
        <TextInput
          value={value}
          name={name}
          disabled={professionalAlreadyUser}
          required
          placeholder="Professional's Email"
          title="Email"
          onChange={onChange}
          onBlur={onBlur}
          error={errors.email?.message}
          className={classes.inputField}
          endAdornment={
            !isNothing(emailTooltipText) && (
              <HelpToolTip text={emailTooltipText} />
            )
          }
        />
      )}
    />
  );
}
