import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    createText: {
      fontSize: 18,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      marginTop: '1rem',
      '&:hover': {
        color: theme.palette.secondary.light,
      },
    },
    guardianInfo: {
      marginTop: '8px',
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
      fontSize: 16,
      color: 'rgba(255,255,255,0.5)',
      margin: '0 auto 1rem auto',
    },
    name: {
      fontSize: 24,
      fontWeight: 400,
    },
    selectedUser: {
      marginBottom: '2rem',
      textAlign: 'center',
    },
    error: {
      color: theme.palette.warning.main,
      fontSize: 16,
      fontWeight: 700,
      margin: '1rem 0',
    },
    input: {
      marginBottom: '2rem',
    },
    inputGrid: {
      margin: '8px 0',
    },
  }),
);
