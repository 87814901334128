import styled from 'styled-components';
import {makeStyles} from '@material-ui/styles';
import {PrivatePage} from '../../PrivatePage';
import {md} from '../../ui/theme';

export const StyledGuardianDashboard = styled(PrivatePage)`
  height: 100%;
`;

export const useStyles = makeStyles(() => ({
  dashboardGrid: {
    marginBottom: md,
  },
}));
