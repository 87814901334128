import {differenceInMinutes} from 'date-fns';
import {SimpleVisit} from '@src/models';

export const getWaitTime = (visit: SimpleVisit, currentTime: Date) =>
  differenceInMinutes(currentTime, visit.start);

export const getComplaint = (visit: SimpleVisit) => {
  const {chiefComplaint, healthcareNotes} = visit;
  return chiefComplaint || healthcareNotes || 'None given';
};
