import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {format} from 'date-fns';
import {useStyles} from './styles';
import {providerAndQualification} from '@src/util/provider';
import {LonelyVisit} from '@src/models';

type Props = {visits: LonelyVisit[]};

export function VisitsTable({visits}: Props) {
  const classes = useStyles();

  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      className={classes.root}
    >
      <Table className={classes.root} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Visit Date</TableCell>
            <TableCell align="left">Provider</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visits.map(visit => (
            <TableRow key={visit.id}>
              <TableCell component="th" scope="row">
                {format(visit.start, 'PPpp')}
              </TableCell>
              <TableCell align="left">
                {visit.providerDetails
                  .map(providerAndQualification)
                  .orElse('—')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
