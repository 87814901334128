import {TableCell, TableRow} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {format} from 'date-fns';
import React from 'react';
import {useHistory} from 'react-router';
import {Button} from '../../ui/form/Button';
import {Patient, SimpleVisit} from '@src/models';

const useStyles = makeStyles(theme => ({
  regularCell: {
    padding: '0.5rem 0',
    width: '30%',
  },
  timeCell: {
    padding: '0.5rem 0',
    width: '40%',
  },
}));

export const getStartInfo = ({start}: {start: Date}) => {
  return format(start, 'EEEE MMMM d, h:mm a');
};

export const getProvider = ({providerDetails, onDemand}: SimpleVisit) =>
  onDemand
    ? 'The next available doctor'
    : providerDetails
        .map(p => p.lastName)
        .map(name => `Dr. ${name}`)
        .orElse('Your scheduled doctor');

type Props = {
  visit: SimpleVisit;
  patient: Patient;
};

export function PatientVisit({patient, visit}: Props) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCell align="left" className={classes.timeCell}>
          {getStartInfo({start: visit.start})}
        </TableCell>
        <TableCell align="left" className={classes.regularCell}>
          {getProvider(visit)}
        </TableCell>
        <TableCell align="left" className={classes.regularCell}>
          <Button
            bStyle="primary"
            onClick={() =>
              history.push(
                `/scheduled-visit/${visit.callPoolId}/${visit.id}/${patient.id}`,
              )
            }
          >
            Join Visit
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}
