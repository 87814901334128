import {IconButton, SwipeableDrawer} from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import React from 'react';
import {NavigationSkeleton} from './NavigationSkeleton';
import {useStyles} from './styles';
import {PermissionsProps} from '.';
import {UserType} from '@src/models';

type Props = {
  openDrawer: boolean;
  setOpenDrawer: (v: boolean) => unknown;
  setShowLogoutModal?: (v: boolean) => unknown;
  onLogout?: () => unknown;
  userType: UserType;
  permissions: PermissionsProps;
};

export function DrawerSkeleton({
  openDrawer,
  setOpenDrawer,
  setShowLogoutModal,
  onLogout,
  userType,
  permissions,
}: Props) {
  const classes = useStyles();

  return (
    <>
      <SwipeableDrawer
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        onOpen={() => {
          setOpenDrawer(true);
        }}
        classes={{paper: classes.drawer}}
      >
        <NavigationSkeleton
          type="vertical"
          onLogout={onLogout}
          setShowLogoutModal={setShowLogoutModal}
          userType={userType}
          permissions={permissions}
        />
      </SwipeableDrawer>
      <IconButton
        onClick={() => {
          setOpenDrawer(!openDrawer);
        }}
        disableRipple
        className={classes.drawerIconContainer}
      >
        <Menu className={classes.drawerIcon} />
      </IconButton>
    </>
  );
}
