import React from 'react';
import {Grid} from '@material-ui/core';
import {PersonCard} from '../../shared/PersonCard';
import {ResponsiblePerson} from '@src/models';

type Props = {
  financiallyResponsibleResult: ResponsiblePerson;
};

export function ReviewRecordStep({financiallyResponsibleResult}: Props) {
  return (
    <Grid container direction="row">
      <PersonCard person={financiallyResponsibleResult} />
    </Grid>
  );
}
