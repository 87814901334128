import {LonelyVisit, Patient, SafeVisit} from '@src/models';

export enum MessageType {
  VisitCreate = 'visitCreate',
  VisitUpdate = 'visitUpdate',
  SafeVisitUpdate = 'safeVisitUpdate',
  PatientUpdate = 'patientUpdate',
}

export type Handler<T extends MessageType = MessageType> = (
  entity: T extends MessageType.VisitCreate
    ? LonelyVisit
    : T extends MessageType.VisitUpdate
    ? LonelyVisit
    : T extends MessageType.SafeVisitUpdate
    ? SafeVisit
    : T extends MessageType.PatientUpdate
    ? Patient
    : never,
) => unknown;
