import {LonelyVisit} from '@src/models';
import {EndpointType} from '@src/models/Endpoint';
import {getEndpoint, getDisplayNameByType} from '@src/util/visits/endpoints';

export const TelemedicineEndpointColumn = {
  name: 'Telemedicine Endpoint',
  data: (visit: LonelyVisit) => {
    const {name, type} = getEndpoint(visit);

    if (type === EndpointType.Ahana) {
      return name;
    } else {
      return `${getDisplayNameByType(type)} at ${name}`;
    }
  },
};
