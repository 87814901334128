import React from 'react';
import {Grid} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';

export function NoFiles({
  noFilesText,
  omitUploadInstructions,
}: {
  noFilesText: string;
  omitUploadInstructions?: boolean;
}) {
  const instructions = (
    <>
      <ParagraphText>{noFilesText}</ParagraphText>
      {!omitUploadInstructions && (
        <ParagraphText>
          If you would like to upload a file, please click the{' '}
          <span>
            <FontAwesomeIcon icon={['fas', 'plus-circle']} size="sm" />
          </span>{' '}
          button.
        </ParagraphText>
      )}
    </>
  );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          textAlign: 'center',
          maxWidth: '600px',
          margin: '3rem auto',
        }}
      >
        {instructions}
      </Grid>
    </Grid>
  );
}
