import {
  Button,
  Grid,
  IconButton,
  Paper,
  useMediaQuery,
} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import clsx from 'clsx';
import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {getCurrentOrImpendingVisits, visitIsOpen} from './functions';
import {useStyles} from './layout';
import {SCPExplainerModal} from './SCPExplainerModal';
import {VisitsList} from './VisitsList';
import {
  Invitation,
  LonelyPatient,
  LonelyVisit,
  MedicalHistory,
  Patient,
} from '@src/models';
import {getLinkHref, Routes} from '@src/components/shared/general/AppRoutes';
import {PatientActionMenu} from '@src/components/shared/PatientActionMenu';
import {PatientFundamentals} from '@src/components/shared/PatientDashboard/PatientFundamentals';
import {useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';
import {isNothing} from '@src/util/typeTests';
import {byStartDesc} from '@src/util/sorters/byStart';

type Props = {
  currentInvitations: readonly Invitation[];
  onCollapseCard: () => unknown;
  onUpdatePatient: (p: LonelyPatient) => void;
  patient: Patient;
  setPatients: (p: Patient[]) => unknown;
  patients: readonly Patient[];
};

export function ExpandedCard({
  currentInvitations,
  patient,
  onCollapseCard,
  onUpdatePatient,
  setPatients,
  patients,
}: Props) {
  const api = useApi();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const matchesMedium = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const patientId = patient.id;

  const [patientVisits] = useResources<LonelyVisit>(
    () =>
      api
        .patient(patientId)
        .getActiveVisits()
        .then(vs =>
          vs
            .filter(visitIsOpen)
            .sort(byStartDesc)
            .map(LonelyVisit.fromSimpleVisit(patient)),
        ),
    [api, patientId, patient],
  );

  const [currentMedicalHistory, reloadMedicalHistory] = useResources<
    MedicalHistory
  >(() => api.patient(patientId).getCurrentMedicalHistory(), [api, patientId]);

  const [explainSharedCarePlan, setExplainSharedCarePlan] = useState(false);

  const selfRelationship = patient.selfRelationship.orNothing();
  const currentVisits = getCurrentOrImpendingVisits(patientVisits);

  const readyForRequests = currentVisits.isLoaded();

  return (
    <>
      <Grid
        className={classes.rowContainer}
        container
        direction={matchesMedium ? 'row' : 'column'}
      >
        {/* Left Side */}
        <Grid item md={5}>
          <Paper className={classes.leftSection}>
            {/* View Record Row */}
            <Grid item container direction="row">
              <Grid item container direction="row" xs>
                <Grid item className={classes.collapseIcon}>
                  <ClickableIcon icon="angle-down" onClick={onCollapseCard} />
                </Grid>
                <Grid item>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    onClick={() => {
                      history.push(
                        getLinkHref(Routes.SCP, {patientId: patient.id}),
                      );
                    }}
                  >
                    Shared Care Plan
                  </Button>
                </Grid>
                <Grid item>
                  <ClickableIcon
                    icon={['fad', 'info-circle']}
                    fixedWidth
                    swapOpacity
                    className={clsx(classes.infoButton)}
                    onClick={() => {
                      setExplainSharedCarePlan(true);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  disableRipple
                  className={classes.moreButtonContainer}
                >
                  <PatientActionMenu
                    patient={patient}
                    updatePatient={p => {
                      onUpdatePatient(p);
                      reloadMedicalHistory();
                    }}
                    unlinkDetails={
                      isNothing(selfRelationship)
                        ? undefined
                        : {
                            currentInvitations,
                            selfRelationship,
                            onRemovePatient: id => {
                              setPatients(patients.filter(p => p.id !== id));
                            },
                          }
                    }
                  />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              style={{
                marginTop: '4em',
                justifyContent: 'center',
              }}
            >
              <PatientFundamentals
                currentMedicalHistory={currentMedicalHistory}
                patient={patient}
              />
            </Grid>
          </Paper>
        </Grid>

        {/* Right Side */}
        <Grid item md={7}>
          <Paper className={classes.rightSection}>
            <VisitsList
              patientId={patient.id}
              readyForRequests={readyForRequests}
              currentVisits={currentVisits}
            />
          </Paper>
        </Grid>
      </Grid>
      <SCPExplainerModal
        show={explainSharedCarePlan}
        onClose={() => {
          setExplainSharedCarePlan(false);
        }}
      />
    </>
  );
}
