import React from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import {ExpandableSection} from '../ExpandableSection';
import {Mergeable} from '../ExpandableSection/isMergeable/isSectionMergeable';
import {useStyles as useGeneralStyles} from '../shared/styles';
import {FailText} from '../shared/ExplanationText/FailText';
import {Result} from './Stepper/Result';
import {InsuranceStepper} from './Stepper';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {MergeOptions, PaymentInformationResult} from '@src/models/Patient';

type Props = {
  mergeable: Mergeable;
  mergeOptions: AsyncData<MergeOptions>;
  paymentInformationResult: PaymentInformationResult;
  setPaymentInformationResult: (v: PaymentInformationResult) => unknown;
};

export function InsuranceInfoComparer({
  mergeable,
  mergeOptions,
  paymentInformationResult,
  setPaymentInformationResult,
}: Props) {
  const classes = useGeneralStyles();

  return (
    <ExpandableSection
      title="Insurance Information"
      mergeable={mergeable}
      mergeOptions={mergeOptions}
    >
      <Grid container direction="column">
        {mergeable === 'FAIL' && <FailText />}

        {mergeable === 'PASS' && (
          <Grid item className={classes.comparerMessage}>
            <ParagraphText style={{marginBottom: 0}}>
              The Insurance Information can be merged automatically.
            </ParagraphText>
            <ParagraphText style={{marginBottom: 0}}>
              Please review the Insurance Information below that will be
              included in the merge.
            </ParagraphText>
            <Result
              paymentInformationResult={paymentInformationResult}
              excludeText
            />
          </Grid>
        )}

        {(mergeable === 'CONFLICT' || mergeable === 'RESOLVED') && (
          <InsuranceStepper
            mergeOptions={mergeOptions}
            paymentInformationResult={paymentInformationResult}
            setPaymentInformationResult={setPaymentInformationResult}
          />
        )}
      </Grid>
    </ExpandableSection>
  );
}
