import {useAuth0} from '@auth0/auth0-react';
import Container from '@material-ui/core/Container';
import {useDecision} from '@optimizely/react-sdk';
import React, {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import {AppBox, MAIN_CONTENT} from '../AppBox';
import {AdminRoutes} from './AdminRoutes';
import {ProviderSideRoutes} from './ProviderSideRoutes';
import {Routes} from '@src/components/shared/general/AppRoutes';
import {NotFoundPage} from '@src/components/shared/general/NotFoundPage';
import {ProtectedRoute} from '@src/components/shared/general/ProtectedRoute';
import Navigation from '@src/components/shared/navigation';
import {VisitOverview} from '@src/components/shared/patient/visits/VisitOverview';
import {ScpPage} from '@src/components/shared/PatientDashboard/SCP';
import {GuardianSurveyPage} from '@src/components/shared/surveys/GuardianSurveyPage';
import VideoChat from '@src/components/shared/VideoChat';
import CheckHairRoom from '@src/components/shared/VideoChat/CheckHairRoom';
import {ChatDeadEnd} from '@src/components/shared/VideoChat/pages/ChatDeadEnd';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {MuiFooter} from '@src/components/ui/layout/MuiFooter';
import {md} from '@src/components/ui/theme';
import {Features} from '@src/features';
import {useCollapse} from '@src/hooks';
import {useUser} from '@src/hooks/useUser';
import {UserType} from '@src/models';
import {sharingSCPInVisit} from '@src/util/sharedCarePlan/isSharedCarePlanPath';

const GuardianInfoPage = lazy(() =>
  import('@src/components/guardianSide/GuardianInfoPage'),
);
const HereForAVisit = lazy(() =>
  import('@src/components/guardianSide/HereForAVisit'),
);
const InsuranceOverview = lazy(() =>
  import('@src/components/guardianSide/GuardianDashboard/InsuranceOverview'),
);
const CreateOrJoinVisit = lazy(() =>
  import('@src/components/guardianSide/CreateOrJoinVisit'),
);
const VisitRequest = lazy(() =>
  import('@src/components/guardianSide/VisitRequest'),
);
const FilesOverview = lazy(() =>
  import('@src/components/shared/patient/files/FilesOverview'),
);

const FormsOverview = lazy(() =>
  import('@src/components/shared/patient/forms/FormsOverview'),
);
const PatientPage = lazy(() => import('@src/components/shared/PatientPage'));
const ExternalVisitPage = lazy(() =>
  import('@src/components/shared/ExternalVisitPage'),
);
const Dashboard = lazy(() => import('@src/components/shared/Dashboard'));
const FirstTime = lazy(() => import('@src/components/shared/FirstTime'));
const EditPatientPage = lazy(() =>
  import('@src/components/shared/forms/EditPatient'),
);
const NewPatient = lazy(() =>
  import('@src/components/shared/forms/NewPatient'),
);
const AssignedFormPage = lazy(() =>
  import('@src/components/shared/AssignedFormPage'),
);
const AssignedFormsPage = lazy(() =>
  import('@src/components/shared/AssignedFormsPage'),
);

// eslint-disable-next-line import/no-unused-modules
export default function RoutedApp() {
  const [, userType, , permissions] = useUser();
  const {isAuthenticated, isLoading} = useAuth0();

  const [mergePatients] = useDecision(Features.MergePatients);

  const canCreateGroups = permissions.includes('callPool:create');
  const anonymousAccess = !isAuthenticated && !isLoading;
  const isCollapseNavigationPathname = useCollapse();
  const collapseNavigation =
    anonymousAccess || sharingSCPInVisit() || isCollapseNavigationPathname;
  /*
   * NB - Be careful with routes that are protected by Feature Flags
   *
   * For some reason, you cannot do:
   *
   * {decision.enabled && (
   *   <>
   *    <Route1/>
   *    <Route2/>
   *   </>
   * )}
   *
   * You have to do
   *
   * {decision.enabled && (<Route1/>)}
   * {decision.enabled && (<Route2/>)}
   */

  return (
    <AppBox collapseNavigation={collapseNavigation}>
      {!collapseNavigation && (
        <Navigation
          userType={anonymousAccess ? undefined : userType}
          permissions={{
            canCreateGroups,
          }}
        />
      )}

      <Container
        disableGutters={true}
        maxWidth={false}
        style={{
          gridArea: MAIN_CONTENT,
          marginTop: md,
          width: '100vw',
          maxWidth: '100vw',
          overflowX: 'scroll',
          height: '100%',
        }}
      >
        <Suspense
          fallback={<PageLoading active message="Loading page..." fullSize />}
        >
          <Switch>
            {AdminRoutes()}
            {ProviderSideRoutes({mergePatients})}
            <ProtectedRoute
              path="/first-time"
              component={FirstTime}
              requiredUserType={[UserType.Professional, UserType.Guardian]}
            />
            <ProtectedRoute
              path="/external-visit/:visitId"
              component={ExternalVisitPage}
              requiredUserType={[UserType.Professional, UserType.Guardian]}
            />

            <ProtectedRoute
              path="/new-patient"
              component={NewPatient}
              requiredUserType={[UserType.Professional, UserType.Guardian]}
            />

            <ProtectedRoute
              path="/edit-patient/:patientId"
              component={EditPatientPage}
              requiredUserType={[UserType.Professional, UserType.Guardian]}
            />
            <ProtectedRoute
              path="/guardian-info"
              component={GuardianInfoPage}
              requiredUserType={UserType.Guardian}
            />
            <Route path={Routes.CheckHairRoom} component={CheckHairRoom} />
            <ProtectedRoute
              path="/guardianSurvey/:visitId"
              component={GuardianSurveyPage}
              requiredUserType={[UserType.Guardian]}
            />

            <ProtectedRoute
              path="/files-overview/:patientId"
              component={FilesOverview}
              requiredUserType={[UserType.Guardian, UserType.Professional]}
            />

            <ProtectedRoute
              path="/insurance-overview/:patientId"
              component={InsuranceOverview}
              requiredUserType={[UserType.Guardian, UserType.Professional]}
            />

            <ProtectedRoute
              path={Routes.SCP}
              component={ScpPage}
              requiredUserType={[UserType.Guardian, UserType.Professional]}
            />

            <Route
              path={'/dead-end/:reason/:visitId'}
              component={ChatDeadEnd}
            />

            <ProtectedRoute
              path={'/visit-overview/:visitId'}
              component={VisitOverview}
              requiredUserType={[UserType.Professional, UserType.Guardian]}
            />

            <Route path={Routes.ExamRoom} component={VideoChat} />

            <ProtectedRoute
              path="/patient/:patientId"
              component={PatientPage}
              requiredUserType={[UserType.Professional, UserType.Guardian]}
            />

            <ProtectedRoute
              path="/here-for-a-visit"
              component={HereForAVisit}
              requiredUserType={[UserType.Guardian]}
            />
            <ProtectedRoute
              path="/visit-request/:patientId"
              component={VisitRequest}
              requiredUserType={[UserType.Guardian]}
            />

            <ProtectedRoute
              path="/new-visit/:callPoolId/:patientId"
              component={CreateOrJoinVisit}
              requiredUserType={[UserType.Guardian]}
            />

            <ProtectedRoute
              path="/scheduled-visit/:callPoolId/:visitId/:patientId"
              component={CreateOrJoinVisit}
              requiredUserType={[UserType.Guardian]}
            />

            <ProtectedRoute
              path="/patients/:patientId/forms"
              component={FormsOverview}
              requiredUserType={[UserType.Guardian, UserType.Professional]}
            />

            <ProtectedRoute
              path="/form/:formId"
              component={AssignedFormPage}
              requiredUserType={[UserType.Professional, UserType.Guardian]}
            />

            <ProtectedRoute
              path="/visits/:visitId/forms"
              component={AssignedFormsPage}
              requiredUserType={[UserType.Professional, UserType.Guardian]}
            />

            <ProtectedRoute path={['/', '/dashboard']} component={Dashboard} />
            <ProtectedRoute path="*" component={NotFoundPage} />
          </Switch>
        </Suspense>
      </Container>

      <MuiFooter />
    </AppBox>
  );
}
