import React, {useEffect, useState, ChangeEvent} from 'react';

import {AsyncData} from '@ahanapediatrics/ahana-fp';

import {Tab, Tabs} from '@material-ui/core';
import {Modal} from '../../../../ui/layout/Modal';
import {useAsync} from '../../../../../hooks/useAsync';
import {AddPartner} from './AddPartner';
import {PartnersList} from './PartnersList';
import {ProviderPartner, User} from '@src/models';
import {AppAPI} from '@src/api/AppAPI';

const api = AppAPI.getInstance();

type Props = {
  setViewModal: (v: boolean) => unknown;
  viewModal: boolean;
  user: AsyncData<User>;
};

export const SCPsPartnersModal: React.FunctionComponent<Props> = ({
  setViewModal,
  user,
  viewModal,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [partners, setPartners] = useAsync<ProviderPartner>();

  useEffect(() => {
    if (user.isLoaded()) {
      const providerAPI = api.provider(user.singleValue().id);
      providerAPI.getPartners().then(ps => setPartners(ps));
    }
  }, [user]);

  return (
    <>
      {viewModal && (
        <Modal
          show={viewModal}
          title={`My Shared Care Plan Partners`}
          onClose={() => {
            setViewModal(false);
            setSelectedTab(0);
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={(e: ChangeEvent<{}>, v: number) => {
              setSelectedTab(v);
            }}
            style={{marginBottom: '1.5rem'}}
          >
            <Tab label="My Partners">My Partners</Tab>
            <Tab label="Add">Add</Tab>
          </Tabs>

          {selectedTab === 0 && (
            <PartnersList
              user={user}
              setPartners={setPartners}
              partners={partners}
            />
          )}

          {selectedTab === 1 && (
            <AddPartner
              user={user}
              setPartners={setPartners}
              onSubmitted={() => setSelectedTab(0)}
            />
          )}
        </Modal>
      )}
    </>
  );
};
