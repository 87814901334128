import React, {useCallback, useState} from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import {EditModal} from './EditModal';
import {getTableItems} from './functions';
import {CallPool} from '@src/models';
import {EditIcon} from '@src/components/ui/atoms/buttonsAndIcons/EditIcon';

type Props = {
  providerGroup?: CallPool;
  resetGroup: () => void;
};

export function Rules({providerGroup, resetGroup}: Props) {
  const [editRules, setEditRules] = useState(false);

  const onClose = useCallback(() => {
    setEditRules(false);
  }, [setEditRules]);

  if (!providerGroup) {
    return null;
  }

  const tableItems = getTableItems(providerGroup);

  return (
    <>
      <TableContainer
        style={{maxHeight: '50vh', overflow: 'auto'}}
        component={Paper}
      >
        <Table>
          <TableHead>
            <TableRow>
              {tableItems.map(column => (
                <TableCell key={column.name}>{column.name}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {tableItems.map(column => (
                <TableCell key={column.name}>{column.data()}</TableCell>
              ))}
              <TableCell>
                <EditIcon
                  onClick={() => {
                    setEditRules(true);
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {editRules && (
        <EditModal
          providerGroup={providerGroup}
          resetGroup={resetGroup}
          onClose={onClose}
        />
      )}
    </>
  );
}
