import React from 'react';
import {Room, RemoteParticipant} from 'twilio-video';
import {Modal} from '../../../../ui/layout/Modal';

import {DecideAllRow} from './DecideAllRow';
import {WaitingVisitorRow} from './WaitingVisitorRow';
import {SetParticipants} from '@src/util/videoChat';

type Props = {
  room: Room | null;
  waitingParticipants: RemoteParticipant[];
  setWaitingParticipants: SetParticipants;
};

export function AdmitVisitorsModal({
  waitingParticipants,
  setWaitingParticipants,
  room,
}: Props) {
  const modalTitle =
    waitingParticipants.length === 1
      ? 'Someone wants to join this call'
      : 'Multiple people want to join this call';

  return (
    <Modal show={waitingParticipants.length > 0} title={modalTitle}>
      {waitingParticipants.map(p => {
        return (
          <WaitingVisitorRow
            key={p.sid}
            setWaitingParticipants={setWaitingParticipants}
            room={room}
            waitingParticipant={p}
          />
        );
      })}

      <DecideAllRow
        waitingParticipants={waitingParticipants}
        room={room}
        setWaitingParticipants={setWaitingParticipants}
      />
    </Modal>
  );
}
