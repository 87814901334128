import {RemoteDataStatus} from '@ahanapediatrics/ahana-fp';
import React, {useEffect} from 'react';
import {useHistory, useParams} from 'react-router';
import {Patient} from '../../../models';
import {PrivatePage} from '../../PrivatePage';
import {DashboardContent} from '../../providerSide/PastVisitsDashboard/layout';
import {Button} from '../../ui/form';
import {PageHeader} from '../../ui/layout';
import {PageLoading} from '../../ui/atoms/progressBarsAndIndicators/PageLoading';
import {Header} from './layout';
import {VisitType} from './VisitType';
import {useAsync} from '@src/hooks';
import {useApi} from '@src/api/useApi';

// eslint-disable-next-line import/no-unused-modules
export default function VisitRequest() {
  const api = useApi();
  const history = useHistory();
  const [patient, setPatient] = useAsync<Patient>();
  const params = useParams<{patientId: string}>();

  useEffect(() => {
    if (patient.is(RemoteDataStatus.NotAsked)) {
      setPatient();
      api
        .patient(+params.patientId)
        .get()
        .then(setPatient);
    }
  }, [api, patient, params, setPatient]);

  return (
    <PrivatePage>
      <PageLoading
        active={!patient.isLoaded()}
        message="Loading patient information"
        fullSize
      >
        <DashboardContent>
          <Header>
            <PageHeader>Request Visit</PageHeader>
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
          </Header>
          <VisitType patient={patient.getOptional()} />
        </DashboardContent>
      </PageLoading>
    </PrivatePage>
  );
}
