import React, {useEffect} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {Mergeable} from '../ExpandableSection/isMergeable/isSectionMergeable';
import {ExpandableSection} from '../ExpandableSection';
import {RelationshipStepper} from './Stepper';
import {AutoMergeDisplay} from './AutoMergeDisplay';
import {Patient, ResponsiblePerson} from '@src/models';
import {InputValue} from '@src/hooks';
import {MergeOptions} from '@src/models/Patient';
import {isNothing} from '@src/util/typeTests';

type Props = {
  patientOne: AsyncData<Patient>;
  patientTwo: AsyncData<Patient>;
  setIsSectionMergeable: (mergeable: Mergeable) => unknown;
  isSectionMergeable: Mergeable;
  setFinanciallyResponsibleResult: (p: ResponsiblePerson | null) => unknown;
  financiallyResponsibleResult: ResponsiblePerson | null;
  financiallyResponsibleName: InputValue<string | null>;
  mergeOptions: AsyncData<MergeOptions>;
};

export function FinanciallyResponsibleComparer({
  patientOne,
  patientTwo,
  isSectionMergeable,
  setIsSectionMergeable,
  financiallyResponsibleResult,
  setFinanciallyResponsibleResult,
  financiallyResponsibleName,
  mergeOptions,
}: Props) {
  const aMergeOptions = mergeOptions.getOptional().orNull();

  useEffect(() => {
    if (!mergeOptions.isLoaded()) {
      return;
    }

    if (aMergeOptions === null) {
      setIsSectionMergeable('FAIL');
    } else {
      const {
        financiallyResponsible: {nameOptions, personOptions},
      } = aMergeOptions;
      const autoMergePerson = personOptions.length < 2;
      const autoMergeName = nameOptions.length < 2;

      if (autoMergePerson) {
        setFinanciallyResponsibleResult(personOptions[0] ?? null);
      }

      if (autoMergeName) {
        financiallyResponsibleName.set(nameOptions[0] ?? null);
      }

      if (autoMergePerson && autoMergeName) {
        setIsSectionMergeable('PASS');
      } else {
        setIsSectionMergeable('CONFLICT');
      }
    }
  }, [aMergeOptions]);

  useEffect(() => {
    if (
      financiallyResponsibleName.value !== null &&
      financiallyResponsibleResult !== null &&
      isSectionMergeable === 'CONFLICT'
    ) {
      setIsSectionMergeable('RESOLVED');
    }
  }, [financiallyResponsibleName.value, financiallyResponsibleResult]);

  return (
    <ExpandableSection
      title="Financially Responsible Person"
      mergeable={isSectionMergeable}
      mergeOptions={mergeOptions}
    >
      {isSectionMergeable === 'PASS' && (
        <AutoMergeDisplay
          financiallyResponsibleResult={financiallyResponsibleResult}
          financiallyResponsibleName={
            financiallyResponsibleName.value || undefined
          }
        />
      )}
      {(isSectionMergeable === 'CONFLICT' ||
        isSectionMergeable === 'RESOLVED') &&
        !isNothing(aMergeOptions) && (
          <RelationshipStepper
            patientOne={patientOne}
            patientTwo={patientTwo}
            financiallyResponsibleResult={financiallyResponsibleResult}
            setFinanciallyResponsibleResult={setFinanciallyResponsibleResult}
            selectedRelationshipName={financiallyResponsibleName.value ?? ''}
            setSelectedRelationshipName={v => financiallyResponsibleName.set(v)}
            financiallyResponsibleMergeOptions={
              aMergeOptions.financiallyResponsible
            }
          />
        )}
    </ExpandableSection>
  );
}
