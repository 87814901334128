import * as React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import * as yup from 'yup';
import {LonelyPatient} from '../../../../models';
import {TextInput} from '../../../ui/form';
import {FormSection} from './FormSection';
import {yString} from '@src/schema/types';

export const historySchema = {
  past_health_issues: yString.when(
    '$isSigning',
    (isSigning: boolean, s: yup.StringSchema) =>
      isSigning ? s.required('Past health issues must be documented') : s,
  ),
  past_surgeries: yString,
  family_history: yString,
  other: yString,
};

export type HistoryValues = {
  past_health_issues: string;
  past_surgeries: string;
  family_history: string;
  other: string;
};

export const defaultHistoryValues = (): HistoryValues => ({
  past_health_issues: '',
  past_surgeries: '',
  family_history: '',
  other: '',
});

export type HistorySectionType = {
  patient: LonelyPatient;
};

export const HistoryLabels = {
  past_health_issues: 'Past health issues',
  past_surgeries: 'Past surgeries',
  family_history: 'Family history',
  other: 'Anything else?',
};

export function HistorySection({patient}: HistorySectionType) {
  const {errors, control} = useFormContext<HistoryValues>();
  return (
    <FormSection title="History">
      <Controller
        control={control}
        name="past_health_issues"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            value={value}
            name={name}
            required
            autoExpand
            placeholder={`What are ${patient.preferredName}'s past or on-going health issues?`}
            title={HistoryLabels.past_health_issues}
            onChange={onChange}
            onBlur={onBlur}
            touched={isTouched}
            error={errors.past_health_issues?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="past_surgeries"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            value={value}
            name={name}
            autoExpand
            placeholder={`Please list any surgeries ${patient.preferredName} has had`}
            title={HistoryLabels.past_surgeries}
            onChange={onChange}
            onBlur={onBlur}
            touched={isTouched}
            error={errors.past_surgeries?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="family_history"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            value={value}
            name={name}
            autoExpand
            placeholder={`Please tell us about ${patient.preferredName}'s family medical history`}
            title={HistoryLabels.family_history}
            onChange={onChange}
            onBlur={onBlur}
            touched={isTouched}
            error={errors.family_history?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="other"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            value={value}
            name={name}
            autoExpand
            placeholder="Any other details of relevance?"
            title={HistoryLabels.other}
            onChange={onChange}
            onBlur={onBlur}
            touched={isTouched}
            error={errors.other?.message}
          />
        )}
      />
    </FormSection>
  );
}
