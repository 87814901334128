import React from 'react';
import {
  Grid,
  TableCell,
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core';
import {debounce} from 'lodash';
import {useStyles} from '../../shared/Tables/styles';
import {BasicDetailsResult} from './utils/resultFunctions';
import {BasicDetailsUIElement} from './utils/getBasicDetailsRows';

import {handleRadioButtonClick} from './utils/handlers/handleRadioButtonClick';
import {
  handleTextInputChange,
  handleDateInputChange,
  handlePhoneInputChange,
} from './utils/handlers/overrideInputHandlers';
import {DateInput, PhoneInput, SelectInput} from '@src/components/ui/form';
import {useInput} from '@src/hooks';

type Props = {
  setResult: (r: BasicDetailsResult) => unknown;
  result: BasicDetailsResult;
  row: BasicDetailsUIElement;
};

export function OverrideCell({result, setResult, row}: Props) {
  const classes = useStyles();
  const isOverrideSelected = result[row.name].radioSelection === 'override';

  const textInput = useInput(result[row.name].text);

  const debouncedHandleTextInputChange = debounce(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      handleTextInputChange({
        event: e,
        fieldName: row.name,
        radioSelection: 'override',
        result,
        setResult,
      });
    },
    100,
  );

  return (
    <TableCell className={classes.tableCell}>
      <div>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <FormControlLabel
              name={row.name}
              control={
                <Radio
                  onChange={event =>
                    handleRadioButtonClick({
                      event,
                      radioSelection: 'override',
                      result,
                      setResult,
                    })
                  }
                  checked={isOverrideSelected}
                />
              }
              label={''}
            />
          </Grid>
          <Grid item xs={10}>
            {row.name === 'dob' && (
              <DateInput
                inputVariant="standard"
                name="dob"
                label=""
                value={isOverrideSelected ? result[row.name].text : null}
                onChange={date => {
                  handleDateInputChange({
                    date,
                    fieldName: row.name,
                    result,
                    setResult,
                  });
                }}
              />
            )}

            {row.name === 'phone' && (
              <PhoneInput
                name="phone"
                onChange={value =>
                  handlePhoneInputChange({
                    value,
                    fieldName: row.name,
                    radioSelection: 'override',
                    result,
                    setResult,
                  })
                }
                value={isOverrideSelected ? result[row.name].text : ''}
                placeholder="Phone"
              />
            )}

            {row.name === 'genderAssignedAtBirth' && (
              <SelectInput
                placeholder="Please select the patient's gender assigned at birth"
                name="genderAssignedAtBirth"
                options={[
                  {label: 'Male', value: 'Male'},
                  {label: 'Female', value: 'Female'},
                ]}
                title="Gender"
                value={isOverrideSelected ? result[row.name].text : ''}
                onChange={event => {
                  setResult({
                    ...result,
                    [row.name]: {
                      text: event.target.value,
                      radioSelection: 'override',
                    },
                  });
                }}
              />
            )}
            {row.name !== 'dob' &&
              row.name !== 'genderAssignedAtBirth' &&
              row.name !== 'phone' && (
                <TextField
                  onClick={() => {
                    setResult({
                      ...result,
                      [row.name]: {
                        text: isOverrideSelected ? textInput.value : '',
                        radioSelection: 'override',
                      },
                    });
                  }}
                  placeholder="Please type an override value to resolve the conflict"
                  multiline
                  value={isOverrideSelected ? textInput.value : ''}
                  onChange={e => {
                    textInput.set(e.target.value);
                    debouncedHandleTextInputChange(e);
                  }}
                />
              )}
          </Grid>
        </Grid>
      </div>
    </TableCell>
  );
}
