import {ProviderDetails} from '@src/models';
import {providerAndQualification} from '@src/util/provider';

export type ManagerRow = {
  nameAndQualification: string;
  id: string;
};

export const COLUMNS: Record<keyof ManagerRow, string> = {
  nameAndQualification: 'Full Name',
  id: 'id',
};

export function getManagerRow(manager: ProviderDetails): ManagerRow {
  return {
    nameAndQualification: providerAndQualification(manager) ?? '',
    id: `${manager.id}`,
  };
}
