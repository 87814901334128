import React, {useEffect} from 'react';
import {isSupported, RemoteParticipant} from 'twilio-video';
import {AppBar, IconButton, Toolbar, Typography} from '@material-ui/core';
import clsx from 'clsx';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {flashWarn, clearFlashes} from '../notifications/flash';
import {useStyles, DOCUMENTATION_WIDTH, SIDEBAR_WIDTH} from './layout';

type VideoMessages = {
  disabled?: string;
  unsupported?: string;
  noPermission?: string;
  awaitingVideo?: string;
};

const defaultMessages: VideoMessages = {
  disabled: 'Video is currently disabled',
  unsupported:
    'Video is not supported on this browser. Please use Chrome or Firefox',
  noPermission: 'Your browser has denied access to the camera and microphone.',
  awaitingVideo:
    'Your video will start once someone else arrives to this visit',
};

type HeaderProps = {
  showDocumentation: boolean;
  sidebarOpen: boolean;
  setSidebarOpen: (v: boolean) => void;
  notification: JSX.Element | null;
  remoteParticipants: RemoteParticipant[];
};

export function Header({
  sidebarOpen,
  showDocumentation,
  setSidebarOpen,
  notification,
  remoteParticipants,
}: HeaderProps) {
  const classes = useStyles({
    width: showDocumentation ? DOCUMENTATION_WIDTH : SIDEBAR_WIDTH,
  });

  useEffect(() => {
    const videoErrorMessage =
      remoteParticipants.length <= 0
        ? defaultMessages.awaitingVideo
        : !isSupported
        ? defaultMessages.unsupported
        : null;

    const needError = remoteParticipants.length <= 0 || !isSupported;

    if (needError && videoErrorMessage) {
      flashWarn(videoErrorMessage, {permanent: true});
    } else {
      clearFlashes();
    }
  }, [remoteParticipants]);

  return (
    <div id="header">
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: sidebarOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              setSidebarOpen(true);
            }}
            edge="start"
            className={clsx(classes.expandMenuButton, {
              [classes.hide]: sidebarOpen,
            })}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
          <Typography variant="h6">{notification}</Typography>
        </Toolbar>
      </AppBar>

      <div className={classes.toolbar} />
    </div>
  );
}
