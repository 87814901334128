import React, {ReactNode} from 'react';
import {makeStyles, createStyles, Typography, Theme} from '@material-ui/core';

type Props = {
  children: ReactNode | null;
  style?: Object;
  hidden?: boolean;
  className?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    splashText: {
      color: theme.palette.grey[700],
      fontWeight: 400,
      width: '70vw',
      margin: '0 auto',
      fontSize: '1.25rem',
    },
  }),
);

export function SplashText({children, style, hidden, className}: Props) {
  const classes = useStyles();

  if (hidden) return null;

  return (
    <Typography
      variant="body1"
      paragraph
      className={`${classes.splashText} ${className}`}
      style={style}
    >
      {children}
    </Typography>
  );
}
