import {makeStyles} from '@material-ui/core';
import {md, sm} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  icons: {
    color: theme.palette.secondary.contrastText,
  },
  icon: {
    margin: `0 ${sm}`,
  },
  incompleteFormIcon: {
    fontSize: '2em',
  },
  switchContainer: {
    margin: md,
  },
  switchOption: {
    cursor: 'pointer',
    '& img': {
      objectFit: 'scale-down',
    },
  },
}));
