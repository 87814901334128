import {AsyncData} from '@ahanapediatrics/ahana-fp';
import React, {useCallback, useState} from 'react';
import {Skeleton} from '@material-ui/lab';
import {
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {COLUMNS, getManagerRow, ManagerRow} from './functions';
import {useStyles} from '@src/components/ui/molecules/lists/ActionList/styles';
import {
  getComparator,
  stableSort,
  Order,
} from '@src/util/ui/sortableTableUtils';
import {useApi} from '@src/api/useApi';
import {ConfirmModal} from '@src/components/ui/layout/ConfirmModal';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {getKeys} from '@src/util/objectManipulation/getKeys';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {isNothing} from '@src/util/typeTests';
import {ProviderDetails} from '@src/models';
import {flashError} from '@src/components/shared/notifications/flash';
import {useIconStyles} from '@src/components/ui/atoms/buttonsAndIcons/styles';

type Props = {
  callPoolId: number;
  managers: AsyncData<ProviderDetails>;
  onRemoved: () => Promise<unknown>;
  isUserManager: boolean;
};

export function ManagersTable({
  callPoolId,
  managers,
  onRemoved,
  isUserManager,
}: Props) {
  const api = useApi();
  const classes = useStyles();
  const iconStyles = useIconStyles();

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ManagerRow>(
    'nameAndQualification',
  );
  const [
    managerToRemoveId,
    setManagerToRemoveId,
  ] = useState<ProviderDetailsId | null>(null);

  const [showCannotRemoveModal, setShowCannotRemoveModal] = useState(false);

  const aManagers = managers.getAllOptional().orElse([]);

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: keyof ManagerRow) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [setOrder, setOrderBy, orderBy, order],
  );

  const createSortHandler = useCallback(
    (property: keyof ManagerRow) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    },
    [handleRequestSort],
  );

  const closeConfirmModal = useCallback(() => {
    setManagerToRemoveId(null);
  }, [setManagerToRemoveId]);

  const removeMember = useCallback(async () => {
    if (isNothing(managerToRemoveId)) {
      return;
    }
    return api
      .callPool(callPoolId)
      .removeManager(managerToRemoveId)
      .then(onRemoved)
      .catch(() => {
        flashError(
          'Oops! Something went wrong. Please contact Support or try again.',
        );
      });
  }, [api, callPoolId, managerToRemoveId, onRemoved]);

  if (!managers.isLoaded()) {
    return <Skeleton />;
  }

  if (managers.isLoaded() && aManagers.length === 0) {
    return (
      <ParagraphText>
        This Provider Group currently has no Managers.
      </ParagraphText>
    );
  }

  const rows = managers
    .getAllOptional()
    .orElse([])
    .map(getManagerRow);

  const deleteButtonCell = <TableCell />;

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {getKeys(COLUMNS)
              .filter(c => c !== 'id')
              .map(columnName => (
                <TableCell
                  key={columnName}
                  sortDirection={orderBy === columnName ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === columnName}
                    direction={orderBy === columnName ? order : 'asc'}
                    onClick={createSortHandler(columnName)}
                  >
                    {COLUMNS[columnName]}
                  </TableSortLabel>
                </TableCell>
              ))}
            {isUserManager && deleteButtonCell}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy)).map(r => {
            return (
              <TableRow key={r.id}>
                <TableCell>{r.nameAndQualification}</TableCell>
                {isUserManager && (
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        if (managers.getAllOptional().orElse([]).length === 1) {
                          setShowCannotRemoveModal(true);
                        } else {
                          setManagerToRemoveId(
                            parseInt(r.id) as ProviderDetailsId,
                          );
                        }
                      }}
                      className={iconStyles.clickableIcon}
                    >
                      <DeleteIcon className={iconStyles.clickableIcon} />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {managerToRemoveId !== null && (
        <ConfirmModal
          onConfirm={removeMember}
          text="Confirming will remove this Professional as a Manager."
          show={managerToRemoveId !== null}
          onHide={closeConfirmModal}
          onCancel={closeConfirmModal}
          cancelText="Cancel"
          confirmText="Remove Manager"
          titleText="Remove Manager"
        />
      )}

      {showCannotRemoveModal && (
        <ConfirmModal
          onConfirm={async () => {
            setShowCannotRemoveModal(false);
          }}
          text="The last Manager cannot be removed."
          show={showCannotRemoveModal}
          onHide={() => {
            setShowCannotRemoveModal(false);
          }}
          onCancel={() => {}}
          confirmText="OK"
          titleText="Oops!"
        />
      )}
    </TableContainer>
  );
}
