import styled from 'styled-components';
import {backdrop, md, primary, xs} from '../theme';

export const SinglePageLayout = styled.div`
  display: table;
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const Box = styled.div`
  border: solid thin ${backdrop};
  display: table-cell;
  text-align: center;
  vertical-align: middle;
`;

export const Content = styled.div`
  background-color: #ffffff;
  border: solid thin ${primary};
  border-radius: ${xs};
  display: inline-block;
  line-height: 1.5em;
  padding: ${md};
  width: 75%;
`;
