export type TabLabel =
  | 'Summary View'
  | 'Emergency Care'
  | 'Medications'
  | 'Owner Controls'
  | 'Change Log'
  | 'Contacts'
  | 'Preferences'
  | 'Uploads'
  | 'History'
  | 'Guardian Controls'
  | 'Remote Monitoring';

export type Panels =
  | 'summaryView'
  | 'emergency'
  | 'medications'
  | 'ownerControls'
  | 'changeLog'
  | 'contacts'
  | 'preferences'
  | 'uploads'
  | 'medicalHistory'
  | 'guardianControls'
  | 'monitoring';

export type TabButton = {
  label: TabLabel;
  panel: Panels;
  requireScp: boolean;
  requireIsOwner?: boolean;
  requireIsGuardian?: boolean;
  disabled?: boolean;
};

export const BUTTONS: TabButton[] = [
  {label: 'Summary View', panel: 'summaryView', requireScp: false},
  {label: 'Emergency Care', panel: 'emergency', requireScp: true},
  {label: 'Medications', panel: 'medications', requireScp: false},

  {
    label: 'Owner Controls',
    panel: 'ownerControls',
    requireScp: true,
    requireIsOwner: true,
  },
  {label: 'Change Log', panel: 'changeLog', requireScp: true},
  {label: 'Contacts', panel: 'contacts', requireScp: true},

  {label: 'Preferences', panel: 'preferences', requireScp: true},
  {label: 'Uploads', panel: 'uploads', requireScp: true},
  {label: 'History', panel: 'medicalHistory', requireScp: true},

  {
    label: 'Guardian Controls',
    panel: 'guardianControls',
    requireScp: true,
    requireIsOwner: false,
    requireIsGuardian: true,
  },
  {
    label: 'Remote Monitoring',
    panel: 'monitoring',
    disabled: true,
    requireScp: true,
  },
];
