import {Room, connect, LocalTrack} from 'twilio-video';
import ConfigService from '@src/ConfigService';

export type ConnectToRoomProps = {
  visitId: number;
  token: string;
  localTracks: LocalTrack[];
};

export async function connectToRoom({
  visitId,
  token,
  localTracks,
}: ConnectToRoomProps): Promise<Room> {
  const environment = ConfigService.getReleaseInfo().RAVEN_ENVIRONMENT;

  return connect(token, {
    name: `${environment}-exam-room-${visitId}`,
    tracks: localTracks,
    networkQuality: {
      local: 3,
      remote: 3,
    },
  });
}
