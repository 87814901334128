import Container from '@material-ui/core/Container';
import React, {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import {ProtectedRoute} from '../shared/general/ProtectedRoute';
import {AdminNavigation} from '../shared/navigation/AdminNavigation';
import {PageLoading} from '../ui/atoms/progressBarsAndIndicators/PageLoading';
import {AppBox, MAIN_CONTENT} from './AppBox';
import {useUser} from '@src/hooks';

const CreateProviderPage = lazy(() =>
  import('../separateAdminApp/CreateProvider'),
);

const AdminDashboard = lazy(() => import('../separateAdminApp/AdminDashboard'));
const ListCallPoolsPage = lazy(() =>
  import('../separateAdminApp/ListCallPools'),
);

const SearchProviderPage = lazy(() =>
  import('../separateAdminApp/SearchProviderPage'),
);

const CreateOnCallPeriodForm = lazy(() =>
  import('../separateAdminApp/ManageOnCallPeriods/CreateOnCallPeriodForm'),
);
const CreateProviderGroupPage = lazy(() =>
  import('../separateAdminApp/CreateProviderGroupPage'),
);
const VisitReports = lazy(() => import('../separateAdminApp/VisitReports'));
const CreateEndpointPage = lazy(() =>
  import('../separateAdminApp/CreateEndpointPage'),
);
const CreateCustomerPage = lazy(() =>
  import('../separateAdminApp/CreateCustomer'),
);
const CreateClientConfiguration = lazy(() =>
  import('../separateAdminApp/CreateClientConfiguration'),
);
const PageNotFound = lazy(() => import('../separateAdminApp/PageNotFound'));

const CreateDocumentPage = lazy(() =>
  import('../separateAdminApp/CreateDocumentPage'),
);

const ConvertToUserPage = lazy(() =>
  import('../separateAdminApp/ConvertToUserPage'),
);

export const enum Routes {
  CreateProvider = '/admin/create-provider',
  ListCallPools = '/admin/list-call-pools',
  CreateMedicalHome = '/admin/create-medical-home',
  SearchForProvider = '/admin/search-for-provider',
  CreateDocument = '/admin/create-document',
  CreatePeriodForProvider = '/admin/create-on-call-period/:providerId',
  CreatePeriod = '/admin/create-on-call-period',
  CreateGroup = '/admin/create-group',
  ListVisitReports = '/admin/list-visit-reports',
  CreateEndpoint = '/admin/create-endpoint',
  CreateCustomer = '/admin/create-customer',
  CreateCustomerCode = '/admin/create-customer-code',
  ConvertToUser = '/admin/convert-to-user',
}

// eslint-disable-next-line import/no-unused-modules
export default function SeparateAdminApp() {
  const [, , , permissions] = useUser();
  const canCreateGroups = permissions.includes('callPool:create');

  return (
    <AppBox>
      <AdminNavigation permissions={{canCreateGroups}} />
      <Container
        disableGutters={true}
        maxWidth={false}
        style={{
          gridArea: MAIN_CONTENT,
        }}
      >
        <Suspense
          fallback={<PageLoading active message="Loading page..." fullSize />}
        >
          <Switch>
            <ProtectedRoute
              exact
              path={['/', '/dashboard', '/admin', '/admin/dashboard']}
              component={AdminDashboard}
            />

            <ProtectedRoute
              exact
              path={Routes.CreateProvider}
              component={CreateProviderPage}
            />

            <ProtectedRoute
              exact
              path={Routes.ListCallPools}
              component={ListCallPoolsPage}
            />

            <ProtectedRoute
              exact
              path={Routes.SearchForProvider}
              component={SearchProviderPage}
            />

            <ProtectedRoute
              exact
              path={Routes.ConvertToUser}
              component={ConvertToUserPage}
            />

            <ProtectedRoute
              exact
              path={Routes.CreateDocument}
              component={CreateDocumentPage}
            />

            <ProtectedRoute
              exact
              path={[Routes.CreatePeriodForProvider, Routes.CreatePeriod]}
              component={CreateOnCallPeriodForm}
            />

            <ProtectedRoute
              exact
              path={Routes.CreateGroup}
              component={CreateProviderGroupPage}
            />

            <ProtectedRoute
              exact
              path={Routes.ListVisitReports}
              component={VisitReports}
            />

            <ProtectedRoute
              exact
              path={Routes.CreateEndpoint}
              component={CreateEndpointPage}
            />

            <ProtectedRoute
              exact
              path={Routes.CreateCustomer}
              component={CreateCustomerPage}
            />

            <ProtectedRoute
              exact
              path={Routes.CreateCustomerCode}
              component={CreateClientConfiguration}
            />

            <Route component={PageNotFound} />
          </Switch>
        </Suspense>
      </Container>
    </AppBox>
  );
}
