import React from 'react';
import {addBreadcrumb, Severity} from '@sentry/browser';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {RemoteParticipant, Room} from 'twilio-video';
import {Drawer, IconButton} from '@material-ui/core';
import clsx from 'clsx';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {SIDEBAR_WIDTH} from '../VideoChat/layout';
import {useStyles} from './layout';
import {AnonymousRoomControls} from './AnonymousRoomControls';
import {Modal} from '@src/components/shared/VideoChat/ExamRoomModals';
import {
  disconnectFromTwilioRoom,
  reconnectToTwilioRoom,
} from '@src/util/videoChat';

type AnonymousSidebarProps = {
  showModal: (m: Modal) => unknown;
  sidebarOpen: boolean;
  room: Room | null;
  participants: RemoteParticipant[];
  setSidebarOpen: (v: boolean) => void;
  visitId: number;
};

export function AnonymousSidebar({
  showModal,
  sidebarOpen,
  room,
  participants,
  setSidebarOpen,
  visitId,
}: AnonymousSidebarProps) {
  const classes = useStyles({
    width: SIDEBAR_WIDTH,
  });

  return (
    <>
      <Drawer
        id="side-drawer"
        open={sidebarOpen}
        anchor="left"
        variant="permanent"
        className={clsx(classes.sideDrawer, classes.drawer, {
          [classes.drawerOpen]: sidebarOpen,
          [classes.drawerClose]: !sidebarOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: sidebarOpen,
            [classes.drawerClose]: !sidebarOpen,
          }),
        }}
        SlideProps={{
          onEnter: () => {
            console.log('entered');
          },
          onEntering: () => {
            console.log('entered');
          },
          onEntered: () => {
            console.log('entered');
          },
        }}
      >
        <div className={classes.toolbar}>
          {sidebarOpen && (
            <IconButton
              onClick={() => {
                setSidebarOpen(false);
              }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          )}

          {!sidebarOpen && (
            <IconButton
              onClick={() => {
                setSidebarOpen(true);
              }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          )}
        </div>
        <AnonymousRoomControls
          showButtonText={sidebarOpen}
          onReconnect={() => {
            addBreadcrumb({
              category: 'exam-room',
              message: 'Clicked Reconnect Button',
              level: Severity.Info,
            });

            if (room) {
              disconnectFromTwilioRoom({
                room,
              });
            }
            reconnectToTwilioRoom({
              visit: Optional.empty(),
              room,
              participants,
            });
          }}
          completeVisit={() => showModal('documentationChoiceModal')}
          pauseVisitForCurrentUser={() =>
            showModal('nonProfessionalLeaveConfirmationModal')
          }
          visitId={visitId}
        />
      </Drawer>
    </>
  );
}
