import React from 'react';
import {Grid} from '@material-ui/core';
import {usePageContext} from '..';
import {Current} from '@src/components/separateAdminApp/ListCallPools/editDocuments/Current';
import {Available} from '@src/components/separateAdminApp/ListCallPools/editDocuments/Available';
import {useStyles} from '@src/components/providerSide/providerGroups/ProviderGroupRosterPage/MonthTable/Day/Shifts/styles';
import {Modal} from '@src/components/ui/layout/Modal';
import {Button} from '@src/components/ui/form';
import {LoadingIndeterminate} from '@src/components/ui/atoms/progressBarsAndIndicators/LoadingIndeterminate';
import {HorizontalSection} from '@src/components/ui/atoms/section/HorizontalSection';
import {allLoaded} from '@src/util/apiHelpers';

export function DocumentsModal() {
  const {
    editDocumentsModalResources: {
      editDocumentsForGroupId,
      groupDocuments,
      hideDocumentsModal,
    },
  } = usePageContext();

  const loading = !allLoaded(groupDocuments);

  const classes = useStyles({loading});

  return (
    <Modal
      show={editDocumentsForGroupId !== null}
      title="Edit Forms"
      onClose={hideDocumentsModal}
      modalActions={
        <Button bStyle="outlined" onClick={hideDocumentsModal}>
          Cancel
        </Button>
      }
    >
      <Grid className={classes.modalListContainer}>
        <LoadingIndeterminate active={loading}>
          <HorizontalSection>
            <Current
              groupDocuments={groupDocuments.getAllOptional().orElse([])}
            />
          </HorizontalSection>
          <HorizontalSection lastSection>
            <Available />
          </HorizontalSection>
        </LoadingIndeterminate>
      </Grid>
    </Modal>
  );
}
