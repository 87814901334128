import React from 'react';
import {TableCell, FormControlLabel, Radio} from '@material-ui/core';
import {useStyles} from '../../shared/Tables/styles';
import {RadioSelection, MedicalHistoryResult} from './utils/resultFunctions';
import {MedicalHistoryUIElement} from './utils/getMedicalHistoryRow';
import {handleRadioButtonClick} from './utils/handlers/handleRadioButtonClick';

type Props = {
  radioLabel: RadioSelection;
  setResult: (r: MedicalHistoryResult) => unknown;
  result: MedicalHistoryResult;
  row: MedicalHistoryUIElement;
};

export function ExistingDataCell({radioLabel, result, setResult, row}: Props) {
  const classes = useStyles();

  return (
    <TableCell className={classes.tableCell}>
      <FormControlLabel
        labelPlacement="end"
        value={
          radioLabel !== null && radioLabel !== 'override'
            ? row[radioLabel]
            : 'override'
        }
        name={row.name}
        control={
          <Radio
            onChange={event =>
              handleRadioButtonClick({
                event,
                radioSelection: radioLabel,
                result,
                setResult,
              })
            }
            checked={result[row.name].radioSelection === radioLabel}
          />
        }
        label={
          radioLabel !== null && radioLabel !== 'override'
            ? row[radioLabel]
            : 'override'
        }
      />
    </TableCell>
  );
}
