import {AsyncData} from '@ahanapediatrics/ahana-fp';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {RoomControlProps} from './types';
import {AppFile, SharedCarePlan} from '@src/models';
import {detectIsAnyoneScreensharing} from '@src/util/videoChat';
import {VisitDocumentationValues} from '@src/components/providerSide/VisitDocumentationForm/sections';
import {requestHelp} from '@src/util/applicationEvents/requestHelp';

type GetControlsProps = {
  files: AppFile[];
  initialValues: AsyncData<VisitDocumentationValues>;
  seesPatients: boolean;
  scp: AsyncData<SharedCarePlan>;
  setShowAssignModal: (v: boolean) => unknown;
  setShowFilesList: (v: boolean) => unknown;
  setShowShareModal: (v: boolean) => unknown;
  setShowVisitInfo: (v: boolean) => unknown;
  setShowUploadModal: (v: boolean) => unknown;
  showFilesList: boolean;
  showShareModal: boolean;
  showVisitInfo: boolean;
  viewDocumentation: () => unknown;
} & Omit<
  RoomControlProps,
  | 'documentation'
  | 'currentMedicalHistory'
  | 'showButtonText'
  | 'userType'
  | 'toggleDocumentation'
  | 'showDocumentation'
  | 'onDocumentationSave'
>;

export type Control = {
  icon?: JSX.Element;
  label?: string;
  onClick?: () => unknown;
  divider?: boolean;
  include?: boolean;
  disabled?: boolean;
  badge?: number;
};

export function getControls({
  completeVisit,
  files,
  initialValues,
  seesPatients,
  scp,
  setShowUploadModal,
  onReconnect,
  pauseVisitForCurrentUser,
  setShowAssignModal,
  setShowFilesList,
  setShowShareModal,
  setShowVisitInfo,
  showFilesList,
  showShareModal,
  showVisitInfo,
  viewDocumentation,
  onPresentSCP,
  disablePresentSCP,
  room,
}: GetControlsProps): Control[] {
  const isAnyoneScreensharing = detectIsAnyoneScreensharing({room});

  return [
    {
      icon: <FontAwesomeIcon icon={['fas', 'door-open']} />,
      label: 'Leave Visit',
      onClick: () =>
        seesPatients ? completeVisit() : pauseVisitForCurrentUser(),
      include: true,
    },
    {
      divider: true,
      include: true,
    },
    {
      icon: <FontAwesomeIcon icon={['fas', 'share-alt']} />,
      label: 'Share Link',
      onClick: () => setShowShareModal(!showShareModal),
      include: true,
    },
    {
      divider: true,
      include: true,
    },
    {
      icon: <FontAwesomeIcon icon={['fas', 'redo']} />,
      label: 'Reconnect',
      onClick: onReconnect,
      include: true,
    },
    {
      divider: true,
      include: true,
    },
    {
      icon: <FontAwesomeIcon icon={['fas', 'info-circle']} />,
      label: `Visit Info`,
      onClick: () => setShowVisitInfo(!showVisitInfo),
      include: true,
    },
    {
      divider: true,
      include: true,
    },
    {
      icon: <FontAwesomeIcon icon={['fad', 'users-medical']} />,
      label: `Assign`,
      onClick: () => setShowAssignModal(!showVisitInfo),
      include: seesPatients,
    },
    {
      divider: true,
      include: seesPatients,
    },
    {
      icon: <FontAwesomeIcon icon={['fas', 'notes-medical']} />,
      label: initialValues.isLoaded()
        ? 'Documentation'
        : 'Loading Documentation...',
      onClick: viewDocumentation,
      disabled: !initialValues.isLoaded(),
      include: seesPatients,
    },
    {
      divider: true,
      include: seesPatients,
    },
    {
      icon: <FontAwesomeIcon icon={['fas', 'hand-holding-medical']} />,
      label: scp ? 'Share Plan' : 'Create Plan',
      onClick: () => onPresentSCP(),
      include: seesPatients,
      disabled: disablePresentSCP || isAnyoneScreensharing,
    },
    {
      divider: true,
      include: seesPatients,
    },
    {
      icon: <FontAwesomeIcon icon={['fas', 'folders']} />,
      label: `${showFilesList ? 'Hide' : 'Show'} Files`,
      onClick: () => setShowFilesList(!showFilesList),
      include: true,
      badge: files.length,
    },
    {
      divider: true,
      include: true,
    },
    {
      icon: <FontAwesomeIcon icon={['fas', 'cloud-upload']} />,
      label: 'Upload',
      onClick: () => setShowUploadModal(true),
      include: true,
    },
    {
      divider: true,
      include: !seesPatients,
    },
    {
      icon: <FontAwesomeIcon icon={['fas', 'question-circle']} />,
      label: 'Help',
      onClick: () => requestHelp(),
      include: true,
    },
  ];
}
