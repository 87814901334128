import React, {ReactNode} from 'react';
import {Grid} from '@material-ui/core';
import {sm} from '@src/components/ui/theme';
import {isNothing} from '@src/util/typeTests';

export function ShiftSection({
  title,
  items,
}: {
  title: string;
  items: {
    description: string;
    icon?: ReactNode;
  }[];
}) {
  return (
    <Grid
      item
      container
      xs={12}
      style={{fontWeight: 'normal'}}
      direction="row"
      justify="space-between"
    >
      <Grid item style={{fontWeight: 'bold', marginBottom: sm}}>
        {title}
      </Grid>

      {items.length === 0 && (
        <Grid item xs={12}>
          No {title}
        </Grid>
      )}

      {items.map((item, id) => {
        const {description} = item;

        const icon = item.icon;

        return (
          <Grid
            key={id}
            item
            container
            xs={12}
            style={{fontWeight: 'normal'}}
            direction="row"
            justify="space-between"
          >
            <Grid item>{description}</Grid>
            {!isNothing(icon) && <Grid item>{icon}</Grid>}
          </Grid>
        );
      })}
    </Grid>
  );
}
