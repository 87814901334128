import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import React, {useState} from 'react';
import styled from 'styled-components';
import {AsyncActionButton} from '@src/components/ui/form';
import {MuiHeading} from '@src/components/ui/layout/MuiHeading';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {setNewSCP} from '@src/util/sharedCarePlan/create';
import {SharedCarePlan, LonelyPatient} from '@src/models';
import {useApi} from '@src/api/useApi';

type Props = {
  patient: Optional<LonelyPatient>;
  setScp: (scp: SharedCarePlan) => void;
  setPatient: (p: LonelyPatient) => void;
  noOwnership: boolean;
};

const ButtonContainer = styled.div`
  text-align: center;
`;

export const Success = ({noOwnership, patient, setScp, setPatient}: Props) => {
  const api = useApi();
  const [creating, setCreating] = useState(false);
  const patientName = patient
    ?.map(p => p.preferredFirstName)
    .orElse('this patient');

  return (
    <Grid container direction="row">
      <MuiHeading variant="h2">You're ready to get started!</MuiHeading>
      <ParagraphText>
        You can view {patientName}'s plan now and fill out their medical
        history, contacts, preferences, and upload documents and images.
      </ParagraphText>
      {noOwnership && (
        <>
          <ParagraphText>
            Because there is currently no owner for this plan, we have been
            notified and will be in touch with you and the provider who you
            would like to own this plan.
          </ParagraphText>
          <ParagraphText>
            The plan is visible to other providers, but until someone is
            selected as the owner, other providers will not be able to add
            information to it, and the information cannot be approved for
            medical accuracy.
          </ParagraphText>
        </>
      )}
      <ButtonContainer>
        <AsyncActionButton
          actionInProgress={creating}
          actionWord={'Get started'}
          bSize="small"
          bStyle="primary"
          onClick={() => {
            setCreating(true);
            setNewSCP(api)({
              patientId: patient.map(p => p.id).orElse(0),
              setCreating,
              setPatient,
              setScp,
            });
          }}
        />
      </ButtonContainer>
    </Grid>
  );
};
