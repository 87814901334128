import {LocalDataTrack, Room} from 'twilio-video';
import {connectToRoom} from './connect';

export type SetUpRoomProps = {
  visitId: number;
  token: string;
  setRoom: (v: Room) => unknown;
};

export async function setUpRoom({visitId, token, setRoom}: SetUpRoomProps) {
  setRoom(
    await connectToRoom({
      visitId,
      token,
      localTracks: [new LocalDataTrack()],
    }),
  );
}
