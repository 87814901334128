import React from 'react';
import {useTheme} from '@material-ui/core';
import {ActionList, Props} from './ActionList';

export function RemovableList({
  handleActionClicked,
  mappable,
  emptyMessage,
}: Omit<Props, 'actionProps'> & {handleActionClicked: (id: number) => void}) {
  const theme = useTheme();

  return (
    <ActionList
      mappable={mappable}
      emptyMessage={emptyMessage}
      primaryActionProps={{
        icon: ['fas', 'trash'],
        iconColor: theme.palette.error.main,
        handleActionClicked,
      }}
    />
  );
}
