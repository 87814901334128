import {makeStyles} from '@material-ui/core';
import styled from 'styled-components';
import {md, lg} from '@src/components/ui/theme';

export const useStyles = makeStyles({
  noTopPad: {
    paddingTop: 0,
  },
  poolName: {
    fontWeight: 'bold',
  },
  root: {
    flexGrow: 1,
  },
});

export const StyledCallPoolSelector = styled.div`
  margin-top: ${md};
`;

export const CallPoolContainer = styled.div`
  margin-top: ${lg};
  margin-bottom: ${md};
`;
