import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  pending: {
    color: theme.palette.warning.dark,
    fontWeight: 700,
    paddingLeft: 0,
    paddingRight: 0,
  },
  icon: {
    fontSize: '16px',
  },
  iconButton: {
    color: theme.palette.warning.main,
    cursor: 'default',
  },
}));
