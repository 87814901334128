import {differenceInMonths, differenceInYears} from 'date-fns';

/**
 * Takes a date time and returns a string listing the years or months since that
 * datetime
 * @param datetime <string|Date> Anything that momentjs can turn into a moment in time
 * @param shortened If true, 'years' and 'months' are reduced to 'y' and 'm'
 * @returns {string}
 */
type Options = {
  shortened?: boolean;
  upTo?: number;
  yearStr?: string;
  monthStr?: string;
};
const asYearsOrMonths = (
  datetime: Date,
  {shortened = false, upTo = 12, yearStr, monthStr}: Options,
) => {
  const years = differenceInYears(new Date(), datetime);
  const months = differenceInMonths(new Date(), datetime);
  const Y = yearStr ?? shortened ? 'y' : ' years';
  const M = monthStr ?? shortened ? 'm' : ' months';
  if (months <= upTo) {
    return `${months}${M}`;
  }

  return `${years}${Y}`;
};

export default asYearsOrMonths;
