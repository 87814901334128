import {UserType} from '@src/models';

export function getCallPoolName(
  {
    nameForProviders,
    nameForGuardians,
  }: {nameForProviders: string; nameForGuardians: string},
  userType: UserType,
) {
  return userType === UserType.Professional
    ? nameForProviders
    : nameForGuardians;
}
