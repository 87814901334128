import {Errors} from '../functions';
import {BasicRoster} from './types';

export const validate = <V extends BasicRoster>(
  {hours, minutes}: V,
  setErrorMessage: (message: string) => void,
): Errors<V> => {
  const errors: Errors<V> = {};
  const length = hours + minutes;

  if (length === 0 || isNaN(length)) {
    errors.hours =
      hours === 0
        ? 'On Call Period must last for at least one minute'
        : 'Please put in a valid amount for hours';
    errors.minutes =
      minutes === 0
        ? 'On Call Period must last for at least one minute'
        : 'Please put in a valid amount for minutes';
    setErrorMessage(
      'Please make sure to tell us how long you will be available',
    );
  }

  return errors;
};
