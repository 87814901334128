import {Optional} from '@ahanapediatrics/ahana-fp';
import React from 'react';
import {StepProps} from '..';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {YesOrNo} from '@src/components/ui/form';

type Props = StepProps;

export function UseInsuranceStep({
  dontUseInsurance,
  patient,
  proceedToNextStep,
  setNextAvailable,
}: Props) {
  const {paymentInformation} = patient;

  const hasInsurance = paymentInformation.property('hasHealthInsurance', false);

  setNextAvailable(dontUseInsurance.value !== null);

  return (
    <>
      <RequestHeader>Do you want to use Insurance?</RequestHeader>
      <RequestInstructions>
        {hasInsurance ? (
          <p>
            Your insurance may be able to to cover some or all of the costs
            associated with this visit.
          </p>
        ) : (
          <p>
            If you have insurance, you may be able to use it to cover some or
            all of the costs associated with this visit.
          </p>
        )}

        <p>
          If you have any questions about coverage, please contact your
          insurance provider or healthcare provider's office.
        </p>

        <p>Do you want to use insurance for this visit?</p>
        <YesOrNo
          invert
          value={Optional.of(dontUseInsurance.value)}
          setValue={v => {
            dontUseInsurance.set(v);
            proceedToNextStep();
          }}
        />
      </RequestInstructions>
    </>
  );
}
