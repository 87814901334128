import React from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {MedicalHistory, LonelyVisit} from '../../../../models';
import {Modal} from '../../../ui/layout/Modal';
import {VisitInformation} from '../../../providerSide/VisitInformation';

type Props = {
  showModal: boolean;
  setShowModal: (v: boolean) => unknown;
  currentMedicalHistory: AsyncData<MedicalHistory>;
  visit: LonelyVisit;
};

export function VisitInfoModal({
  showModal,
  setShowModal,
  currentMedicalHistory,
  visit,
}: Props) {
  return (
    <Modal
      show={showModal}
      onClose={() => setShowModal(false)}
      title="Visit Information"
    >
      <VisitInformation
        visit={visit}
        isMA={false}
        currentMedicalHistory={currentMedicalHistory}
      />
    </Modal>
  );
}
