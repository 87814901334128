import {CreateProvider} from '.';
import {AppAPI} from '@src/api/AppAPI';
import {ResourceConflictException} from '@src/api/exceptions';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';
import {NAMES} from '@src/components/ui/layout/text/names';

type OnSaveProps = {
  setSubmitting: (v: boolean) => unknown;
  api: AppAPI;
  callPoolIds: number[];
  onSuccess: () => unknown;
};

export const onSave = ({
  setSubmitting,
  api,
  callPoolIds,
  onSuccess,
}: OnSaveProps) => async (data: CreateProvider) => {
  setSubmitting(true);

  const {
    firstName,
    lastName,
    email,
    phone,
    speciality,
    qualification,
    seesPatients,
  } = data;

  api
    .providerDetails()
    .create({
      firstName,
      lastName,
      email,
      speciality,
      practicePhone: phone,
      qualification,
      seesPatients,
      searchable: true,
    })
    .then(details => {
      const {id} = details;
      return Promise.all(
        callPoolIds.map(cpid => api.callPool(cpid).addMember(id)),
      );
    })
    .then(() => {
      onSuccess();
      flashSuccess(`${toTitleCase(NAMES.professional)} created successfully!`);
    })
    .catch(e => {
      let message = `Oops, there was an error: ${e.message}`;
      if (e instanceof ResourceConflictException) {
        message = `There is already a ${toTitleCase(
          NAMES.professional,
        )} with this email address`;
      }

      flashError(`${message}`);
    })
    .finally(() => {
      setSubmitting(false);
    });
};
