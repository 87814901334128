import {isEmpty, isNothing} from '../typeTests';
import {ProviderDetails} from '@src/models';

type Options = {skipFirstName: boolean};
/**
 * Given ProviderInfo, returns a string containing a provider's name and qualification
 *
 * @example
 *  - Dr Who, MD
 *  - John Doe, DO
 *  - Sally Mae, PhD
 *  - Provider Jones, NP
 *  - Doe, DO
 *
 * @param p
 * @param [options]
 * @param [options.skipFirstName]
 */
export const providerAndQualification = (
  p: ProviderDetails,
  options: Options = {skipFirstName: false},
): string => {
  if (!p.lastName && !p.firstName) {
    return 'Not given';
  }

  const {skipFirstName} = options;
  let description: string;
  if (isEmpty(p.lastName)) {
    return 'Not given';
  } else if (skipFirstName) {
    description = p.lastName ?? '';
  } else if (!isEmpty(p.firstName)) {
    description = p.firstName + ` ${p.lastName}`;
  } else if (p.qualification === 'DO' || p.qualification === 'MD') {
    description = `Dr. ${p.lastName}`;
  } else {
    description = `Provider ${p.lastName}`;
  }
  if (!isEmpty(p.qualification)) {
    description = `${description}, ${p.qualification}`;
  }

  return description;
};

/**
 * Provides a full description of a provider
 *
 * @example
 *
 * - Dr Who, MD - Primary care doctor
 * - John Doe, DO - Pediatrics
 * - Sally Mae, PhD
 * - Provider Jones, NP - NICU Nursing
 *
 * @param p
 * @param isPcp
 */
export const getProviderDescription = (p: ProviderDetails, isPcp?: boolean) => {
  let description = providerAndQualification(p);

  if (isPcp) {
    description = `${description} - Primary care doctor`;
  } else if (!isEmpty(p.speciality)) {
    description = `${description} - ${p.speciality}`;
  }

  return description;
};

/**
 * Providers a status string for a Provider
 *
 * @example
 *
 * - Refyne Connected Care User
 * - Non Refyne Connected Care User
 *
 * @param p
 */
export const providerUserStatus = (p: ProviderDetails) => {
  return isNothing(p.providerId)
    ? 'Non Refyne Connected Care User'
    : 'Refyne Connected Care User';
};

/**
 * Providers a status string for a Provider
 *
 * @example
 *
 * - Primary care doctor (Ahana User)
 * - Pediatrics - Primary care doctor (Non User)
 * - Cardiology (Non User)
 *
 * @param p
 * @param isPcp
 */
export const providerUserStatusWithSpeciality = (
  p: ProviderDetails,
  isPcp?: boolean,
) => {
  let speciality = p.speciality ?? '';
  const userStatus = providerUserStatus(p);
  if (isPcp) {
    if (speciality.length > 0) {
      speciality = `${speciality} - `;
    }
    speciality = `${speciality}Primary care doctor`;
  }
  return `${speciality} (${userStatus})`;
};
