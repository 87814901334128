import React, {useCallback} from 'react';
import {FormLabel} from '@material-ui/core';
import {MultipleChoiceField, MultipleChoiceOption} from '@src/models';
import {SelectInput} from '@src/components/ui/form';

interface Props {
  field: MultipleChoiceField;
  onChange?: (multipleChoiceOptionsId: number[]) => unknown;
  options: MultipleChoiceOption[];
  disabled: boolean;
  value: number[] | null;
}

export default function SingleResponse({
  field,
  onChange = () => {},
  options,
  disabled,
  value,
}: Props) {
  const id = `form-multiple-choice-input-${field.id}`;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value === 'null' ? [] : [Number(e.target.value)]);
    },
    [onChange],
  );

  return (
    <>
      <FormLabel component="div" required={field.required} disabled={disabled}>
        {/*
         * Please note the subset of HTML tags considered valid within a <label> tag.
         * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label#technical_summary
         *
         * > Phrasing content, but no descendant label elements.
         * > No labelable elements other than the labeled control are allowed.
         *
         * https://developer.mozilla.org/en-US/docs/Web/HTML/Content_categories#phrasing_content
         */}
        <label
          className="inline-block"
          htmlFor={id}
          dangerouslySetInnerHTML={{__html: field.description}}
        />
      </FormLabel>
      <SelectInput
        id={id}
        name={field.name}
        value={value && value.length > 0 ? String(value[0]) : 'null'}
        options={[
          // Non-breaking space label required to prevent MUI's MenuItem from collapsing
          {value: 'null', label: '\u00A0'},
          ...options.map(option => ({
            value: option.id,
            label: option.description,
          })),
        ]}
        onChange={handleChange}
        disabled={disabled}
      />
    </>
  );
}
