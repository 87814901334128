import React, {useEffect, useReducer, useState} from 'react';
import {Redirect, useHistory, useParams} from 'react-router';
import {Grid} from '@material-ui/core';
import {BasicDetailsComparer} from './BasicDetailsComparer';
import {PrimaryCareComparer} from './PrimaryCareComparer';
import {RelationshipsComparer} from './RelationshipsComparer';
import {InsuranceInfoComparer} from './InsuranceInfoComparer';
import {CareTeamsComparer} from './CareTeamsComparer';
import {VisitsComparer} from './VisitsComparer';
import {ScpComparer} from './ScpComparer';
import {MedicalHistoryComparer} from './MedicalHistoryComparer';
import {arePatientsMergeable, onSubmit} from './finalMergeUtils';
import {FinanciallyResponsibleComparer} from './FinanciallyResponsibleComparer';
import {useStyles} from './shared/styles';
import {
  useInitialResultsState,
  updatingResultReducer,
} from './reducers/mergeResultsReducer';
import {Mergeable} from './ExpandableSection/isMergeable';
import {BasicDetailsResult} from './BasicDetailsComparer/Rows/utils/resultFunctions';
import {MedicalHistoryResult} from './MedicalHistoryComparer/Rows/utils/resultFunctions';
import {FilesComparer} from './FilesComparer';
import {
  updatingSectionsMergeableReducer,
  initialSectionsMergeableState,
} from './reducers/mergeStateReducer';
import {ConnectedLoginsResult} from './shared/types';
import {useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {PageContainer} from '@src/components/ui/layout/PageContainer';
import {MuiHeading} from '@src/components/ui/layout/MuiHeading';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {WarningText} from '@src/components/ui/layout/text/body/WarningText';
import {isNothing} from '@src/util/typeTests';
import {
  getEmptyMergeOptions,
  PaymentInformationResult,
} from '@src/models/Patient';
import {ProviderDetails, ResponsiblePerson} from '@src/models';

// eslint-disable-next-line import/no-unused-modules
export default function MergePatients() {
  const classes = useStyles();
  const api = useApi();
  const params = useParams<{firstId: string; secondId: string}>();

  const history = useHistory();

  const firstId = +params.firstId;
  const secondId = +params.secondId;

  const [firstPt] = useResources(
    () =>
      api
        .patient(firstId)
        .get()
        .catch(() => {
          history.push('/merge-patients/dead-end?reason=not-found');
          return [];
        }),
    [api, firstId],
  );
  const [secondPt] = useResources(
    () =>
      api
        .patient(secondId)
        .get()
        .catch(() => {
          history.push('/merge-patients/dead-end?reason=not-found');
          return [];
        }),
    [api, secondId],
  );

  const [mergeOptions] = useResources(
    () => api.patient(firstId).getMergeOptions(secondId),
    [api, firstId, secondId],
  );

  const [submitting, setSubmitting] = useState(false);

  const [mergeableState, mergeableStateDispatch] = useReducer(
    updatingSectionsMergeableReducer,
    initialSectionsMergeableState,
  );

  const [mergeResults, mergeResultsDispatch] = useReducer(
    updatingResultReducer,
    useInitialResultsState(),
  );

  const {
    basicDetailsResult,
    paymentInformationResult,
    pcpResult,
    financiallyResponsibleResult,
    financiallyResponsibleName,
    connectedLogins,
    medicalHistoryResult,
  } = mergeResults;

  const {
    basicDetailsMergeable,
    medicalHistoryMergeable,
    relationshipsMergeable,
    careTeamsMergeable,
    visitsMergeable,
    scpMergeable,
    paymentInfoMergeable,
    primaryCareMergeable,
    financiallyResponsibleMergeable,
    filesMergeable,
  } = mergeableState;

  const allSectionsMergeable = arePatientsMergeable({
    sectionResults: [
      basicDetailsMergeable,
      medicalHistoryMergeable,
      relationshipsMergeable,
      careTeamsMergeable,
      visitsMergeable,
      scpMergeable,
      paymentInfoMergeable,
      primaryCareMergeable,
      financiallyResponsibleMergeable,
    ],
  });

  useEffect(() => {
    if (
      mergeOptions.isLoaded() &&
      !isNothing(mergeOptions.getOptional().orNull())
    ) {
      mergeableStateDispatch({
        targetMergeable: 'PASS',
        property: 'visitsMergeable',
      });
      mergeableStateDispatch({
        targetMergeable: 'PASS',
        property: 'careTeamsMergeable',
      });
      mergeableStateDispatch({
        targetMergeable: 'PASS',
        property: 'filesMergeable',
      });

      const {paymentInfos} = mergeOptions
        .getOptional()
        .orElse(getEmptyMergeOptions());

      if (paymentInfos.length < 2) {
        mergeableStateDispatch({
          targetMergeable: 'PASS',
          property: 'paymentInfoMergeable',
        });
        mergeResultsDispatch({
          type: 'SET_PAYMENT_INFO',
          paymentInfo: {primary: paymentInfos[0]},
        });
      } else {
        mergeableStateDispatch({
          targetMergeable: 'CONFLICT',
          property: 'paymentInfoMergeable',
        });
      }
    }
  }, [mergeOptions]);

  useEffect(() => {
    const {primary} = paymentInformationResult;

    if (!isNothing(primary) && paymentInfoMergeable === 'CONFLICT') {
      mergeableStateDispatch({
        targetMergeable: 'RESOLVED',
        property: 'paymentInfoMergeable',
      });
    }
  }, [paymentInformationResult, paymentInfoMergeable]);

  if (firstId === secondId) {
    return <Redirect to="/merge-patients/dead-end?reason=patients-match" />;
  }

  return (
    <PageContainer>
      <Grid container direction="column">
        <MuiHeading>Merge Patients</MuiHeading>

        <Grid item>
          <InsuranceInfoComparer
            mergeOptions={mergeOptions}
            mergeable={paymentInfoMergeable}
            paymentInformationResult={paymentInformationResult}
            setPaymentInformationResult={(v: PaymentInformationResult) => {
              mergeResultsDispatch({type: 'SET_PAYMENT_INFO', paymentInfo: v});
            }}
          />
          <BasicDetailsComparer
            patientOne={firstPt}
            patientTwo={secondPt}
            setMergeable={(mergeable: Mergeable) => {
              mergeableStateDispatch({
                targetMergeable: mergeable,
                property: 'basicDetailsMergeable',
              });
            }}
            mergeable={basicDetailsMergeable}
            basicDetailsResult={basicDetailsResult}
            setBasicDetailsResult={(v: BasicDetailsResult) => {
              mergeResultsDispatch({
                type: 'SET_BASIC_DETAILS',
                basicDetails: v,
              });
            }}
          />
          <MedicalHistoryComparer
            patientOne={firstPt}
            patientTwo={secondPt}
            firstId={firstId}
            secondId={secondId}
            setMergeable={(mergeable: Mergeable) => {
              mergeableStateDispatch({
                targetMergeable: mergeable,
                property: 'medicalHistoryMergeable',
              });
            }}
            mergeable={medicalHistoryMergeable}
            medicalHistoryResult={medicalHistoryResult}
            setMedicalHistoryResult={(v: MedicalHistoryResult) => {
              mergeResultsDispatch({
                type: 'SET_MEDICAL_HISTORY',
                medicalHistory: v,
              });
            }}
          />
          <RelationshipsComparer
            setIsSectionMergeable={(mergeable: Mergeable) => {
              mergeableStateDispatch({
                targetMergeable: mergeable,
                property: 'relationshipsMergeable',
              });
            }}
            isSectionMergeable={relationshipsMergeable}
            mergeOptions={mergeOptions}
            connectedLogins={connectedLogins}
            setConnectedLogins={(v: ConnectedLoginsResult) => {
              mergeResultsDispatch({
                type: 'SET_CONNECTED_LOGINS',
                connectedLogins: v,
              });
            }}
          />
          <FinanciallyResponsibleComparer
            patientOne={firstPt}
            patientTwo={secondPt}
            setIsSectionMergeable={(mergeable: Mergeable) => {
              mergeableStateDispatch({
                targetMergeable: mergeable,
                property: 'financiallyResponsibleMergeable',
              });
            }}
            isSectionMergeable={financiallyResponsibleMergeable}
            financiallyResponsibleResult={financiallyResponsibleResult}
            setFinanciallyResponsibleResult={(v: ResponsiblePerson | null) => {
              mergeResultsDispatch({
                type: 'SET_FINANCIALLY_RESPONSIBLE_RESULT',
                financiallyResponsiblePerson: v,
              });
            }}
            mergeOptions={mergeOptions}
            financiallyResponsibleName={financiallyResponsibleName}
          />
          <PrimaryCareComparer
            patientOne={firstPt}
            patientTwo={secondPt}
            setMergeable={(mergeable: Mergeable) => {
              mergeableStateDispatch({
                targetMergeable: mergeable,
                property: 'primaryCareMergeable',
              });
            }}
            mergeable={primaryCareMergeable}
            pcpResult={pcpResult}
            setPcpResult={(v: ProviderDetails | null) => {
              mergeResultsDispatch({
                type: 'SET_PCP',
                providerDetails: v,
              });
            }}
          />
          <CareTeamsComparer
            mergeable={careTeamsMergeable}
            mergeOptions={mergeOptions}
          />
          <VisitsComparer
            mergeable={visitsMergeable}
            mergeOptions={mergeOptions}
          />
          <FilesComparer
            mergeable={filesMergeable}
            mergeOptions={mergeOptions}
          />
          <ScpComparer
            patientOne={firstPt}
            patientTwo={secondPt}
            setMergeable={(mergeable: Mergeable) => {
              mergeableStateDispatch({
                targetMergeable: mergeable,
                property: 'scpMergeable',
              });
            }}
            mergeable={scpMergeable}
          />
        </Grid>

        <Grid item xs={6}>
          <MuiAsyncActionButton
            className={classes.submitButtonContainer}
            bStyle={allSectionsMergeable === 'CONFLICT' ? 'warning' : 'primary'}
            disabled={allSectionsMergeable !== 'PASS'}
            actionInProgress={submitting}
            actionWord="Submit"
            onClick={() => {
              onSubmit({
                api,
                setSubmitting,
                basicDetailsResult,
                history,
                firstId,
                secondId,
                pcpDetailsId: pcpResult?.id ?? 0,
                financiallyResponsiblePersonResult: {
                  personId: financiallyResponsibleResult?.id ?? 0,
                  relationshipName: financiallyResponsibleName.value ?? '',
                },
                connectedLogins,
                paymentInformationResult,
              });
            }}
          />
          {allSectionsMergeable === 'FAIL' && (
            <WarningText warningVariant="error">
              <div>
                Unfortunately, the selected Patients cannot be merged through
                this process at this time because there are unmergeable
                differences.
              </div>
              <div>Please contact support for further assistance.</div>{' '}
            </WarningText>
          )}

          {allSectionsMergeable === 'CONFLICT' && (
            <WarningText warningVariant="warning">
              Unfortunately, the selected Patients cannot be merged until the
              conflicts are resolved. Please edit the sections with the yellow
              indicators to resolve the conflicts before merging.
            </WarningText>
          )}
        </Grid>
      </Grid>
    </PageContainer>
  );
}
