/* eslint-disable import/no-unused-modules */
import {MedicalHistoryUIElement} from './getMedicalHistoryRow';
import {MedicalHistoryResult} from './resultFunctions';
import {ImmunizationStatus} from '@src/models';

export const defaultMedicalHistoryResult = {
  surgeries: {
    text: '',
    radioSelection: null,
  },
  environment: {text: '', radioSelection: null},
  allergies: {text: '', radioSelection: null},
  past_or_ongoing_health_issues: {text: '', radioSelection: null},
  medical_history: {text: '', radioSelection: null},
  immunizations_up_to_date: {
    text: ImmunizationStatus.NotSure,
    radioSelection: null,
  },
  immunization_comments: {text: '', radioSelection: null},
  medications: {text: '', radioSelection: null},
  other: {text: '', radioSelection: null},
};

export function populateResultWithLoadedData({
  rows,
  result,
}: {
  rows: MedicalHistoryUIElement[];
  result: MedicalHistoryResult;
}): MedicalHistoryResult {
  const newResult = rows.reduce((oldResult, row) => {
    const match = row.patientOne === row.patientTwo;

    return {
      ...oldResult,
      [row.name]: {
        text: match ? row.patientOne : '',
        radioSelection: match ? 'patientOne' : null,
      },
    };
  }, result);

  return newResult;
}
