import React from 'react';
import {Grid} from '@material-ui/core';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {CallPool, ProviderDetails} from '@src/models';
import {getProviderDescription} from '@src/util/provider';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {HorizontalSection} from '@src/components/ui/atoms/section/HorizontalSection';
import {Modal} from '@src/components/ui/layout/Modal';
import {isSomething} from '@src/util/typeTests';
import {AvatarList} from '@src/components/ui/molecules/lists/AvatarList';

type Props = {
  group: CallPool;
  unauthorizedProviderDetails: Optional<ProviderDetails>[];
  reset: () => unknown;
};

export function UnaddableGroupModal({
  group,
  unauthorizedProviderDetails,
  reset,
}: Props) {
  const aUnauthorizedProviderDetails = unauthorizedProviderDetails
    .map(pd => pd.orNull())
    .filter(isSomething);

  const namedIndividuals = aUnauthorizedProviderDetails.map(pd => {
    return {namedIndividual: pd, description: getProviderDescription(pd)};
  });

  return (
    <Modal show={true} title="Why can't I add this Group?" onClose={reset}>
      <Grid container direction="row">
        <HorizontalSection>
          <Grid item xs={12}>
            <ParagraphText>
              The following Professionals assigned to this Shift do not belong
              to the Group ({group.nameForProviders}) you are trying to add:
            </ParagraphText>
          </Grid>
        </HorizontalSection>
        <HorizontalSection equalTopBottomMargins>
          <AvatarList namedIndividuals={namedIndividuals} />
        </HorizontalSection>
      </Grid>
      <HorizontalSection lastSection>
        <ParagraphText>
          In order to add this Group to the Shift,{' '}
          <Bold>you must remove the above Professionals from the Shift</Bold>.
        </ParagraphText>
      </HorizontalSection>
    </Modal>
  );
}
