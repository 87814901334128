import React, {useCallback} from 'react';
import {FormControlLabel, FormGroup, Switch} from '@material-ui/core';
import {SCPChangeRequest} from '@src/models';
import {isNothing} from '@src/util/typeTests';

export function Toggle({
  requestToDisplay,
  pendingChangeToggled,
  setPendingChangeToggled,
}: {
  requestToDisplay: SCPChangeRequest | undefined;
  pendingChangeToggled: boolean;
  setPendingChangeToggled: (v: boolean) => unknown;
}) {
  const handleToggled = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPendingChangeToggled(e.target.checked);
    },
    [setPendingChangeToggled],
  );

  if (isNothing(requestToDisplay)) {
    return null;
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            name=""
            checked={pendingChangeToggled}
            color="primary"
            inputProps={{'aria-label': 'checkbox'}}
            onChange={handleToggled}
          />
        }
        label="Pending"
      />
    </FormGroup>
  );
}
