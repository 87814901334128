import styled from 'styled-components';
import {TableCell, createStyles, makeStyles} from '@material-ui/core';
import {StyledLink} from '../oncall/SeePatientLink';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';
import {
  grey,
  h3,
  md,
  primary,
  lg,
  primaryLight,
} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme =>
  createStyles({
    mobileTableContainer: {
      backgroundColor: '#ffffff',
      marginBottom: lg,
    },
    desktopTableContainer: {
      backgroundColor: '#ffffff',
    },
    complaintRoot: {
      '&.overflowing $viewMore': {
        display: 'block',
      },
    },
    viewMore: {
      display: 'none',
      color: theme.palette.primary.main,
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
    contentContainer: {
      maxWidth: '15em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '&.showMore': {
        whiteSpace: 'normal',
      },
    },
  }),
);

export const MobileLinkCell = styled(TableCell)`
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  vertical-align: middle;

  ${StyledLink} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const LoadingMessage = styled(TableCell)`
  font-size: ${h3};
  padding: ${md};
  text-align: center;
`;

export const VisitActionButton = styled(ClickableIcon)<{disabled?: boolean}>`
  color: ${props => (props.disabled ? grey : primary)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 1.5em;

  &:hover {
    color: ${props => (props.disabled ? grey : primaryLight)};
  }
`;

export const ActionCell = styled(TableCell)`
  ${VisitActionButton} {
    margin: 0 auto;
  }
`;
