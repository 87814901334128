import React, {useMemo} from 'react';
import {Paper} from '@material-ui/core';
import MultipleResponse from './MultipleResponse';
import SingleResponse from './SingleResponse';
import {MultipleChoiceField} from '@src/models';

interface Props {
  field: MultipleChoiceField;
  onChange?: (multipleChoiceOptionsId: number[]) => unknown;
  readonly?: boolean;
  value: number[] | null;
}

export default function FormMultipleChoiceInput({
  field,
  onChange = () => {},
  readonly = true,
  value,
}: Props) {
  const options = field.multipleChoiceOptions.get();
  const sortedOptions = useMemo(
    () =>
      [...options].sort((a, b) => {
        const ordinalDelta = a.ordinal - b.ordinal;

        if (ordinalDelta === 0) {
          return a.createdAt.getTime() - b.createdAt.getTime();
        }

        return ordinalDelta;
      }),
    [options],
  );

  return (
    <Paper style={{padding: '1em'}}>
      {field.allowMultipleResponses ? (
        <MultipleResponse
          disabled={readonly}
          field={field}
          onChange={onChange}
          options={sortedOptions}
        />
      ) : (
        <SingleResponse
          disabled={readonly}
          field={field}
          onChange={onChange}
          options={sortedOptions}
          value={value}
        />
      )}
    </Paper>
  );
}
