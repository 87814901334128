import styled from 'styled-components';
import {PageHeader} from '@src/components/ui/layout';

export const Header = styled(PageHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonDiv = styled.div`
  font-size: 16px;
`;
