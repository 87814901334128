import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import update from 'immutability-helper';
import React, {useState, useCallback, useRef} from 'react';
import AutoSuggest from '../AutoSuggest';
import {ICD10Modal} from './ICD10Modal';
import {NIHRequester} from './NIHRequester';
import {useStyles} from './layout';
import {useAsync} from '@src/hooks';

export type ICD10Type = {
  id: string;
  code: string;
  description: string;
};

const renderSuggestion = (suggestion: ICD10Type) => (
  <div>{suggestion && `${suggestion.code}: ${suggestion.description}`}</div>
);

type Props = {
  maxCodes?: number;
  error?: string;
  instructions: string;
  name: string;
  onChange: (icd10s: Array<ICD10Type>) => void;
  required: boolean;
  touched?: boolean;
  title: string;
  value: Array<ICD10Type>;
};

export function ICD10Input({
  maxCodes = 3,
  instructions = '',
  name = '',
  onChange = () => {},
  required = false,
  title = '',
  value = [],
  error,
  touched,
}: Props) {
  const classes = useStyles();

  const [inputContent, setInputContent] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [suggestedCodes, setSuggestedCodes] = useAsync<ICD10Type>();

  const requester = useRef(new NIHRequester());

  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestedCodes([]);
  }, [setSuggestedCodes]);

  const onSelect = useCallback(
    (icd10Code: ICD10Type | null) => {
      setInputContent('');
      onSuggestionsClearRequested();
      if (icd10Code === null) {
        onChange([...value]);
      } else {
        onChange([...value, icd10Code]);
      }
    },
    [onChange, onSuggestionsClearRequested, value],
  );

  const removeSelected = useCallback(
    (type: ICD10Type): boolean => {
      const selected = value.map(c => c.code);
      return !selected.includes(type.code);
    },
    [value],
  );

  const handleSuggestionResults = useCallback(
    (details: ICD10Type[]) => {
      const mappedDetails = details
        .filter(removeSelected)
        .sort((a, b) => a.code.localeCompare(b.code));

      setSuggestedCodes(mappedDetails);
    },
    [removeSelected, setSuggestedCodes],
  );

  const getSuggestions = useCallback(
    (val: string) => {
      setInputContent(val);
      setSuggestedCodes();
      const results = requester.current.get(val);

      return results.then(handleSuggestionResults);
    },
    [handleSuggestionResults, setInputContent, setSuggestedCodes],
  );

  const renderSelection = useCallback(
    (selection: ICD10Type, index: number) => {
      return (
        <div className={classes.selection} key={selection.code}>
          <div>
            {selection && `${selection.code}: ${selection.description}`}
          </div>
          <div
            onClick={() => {
              onChange(update(value, {$splice: [[index, 1]]}));
            }}
          >
            <FontAwesomeIcon className={classes.deleteButton} icon={'times'} />
          </div>
        </div>
      );
    },
    [classes, onChange, value],
  );

  return (
    <>
      <div className={classes.icd10Input}>
        <AutoSuggest
          // This forces a re-instantiation of this component to help
          // clear its internal state
          key={value.length}
          disabled={value.length >= maxCodes}
          onChange={onSelect}
          name={name}
          error={error}
          required={required}
          instructions={
            value.length >= maxCodes
              ? 'No more codes can be selected'
              : instructions
          }
          touched={touched}
          title={title}
          value={inputContent}
          className="icd10-input--suggest"
          suggestions={suggestedCodes}
          getSuggestionKey={s => s.code}
          getSuggestionValue={suggestion => {
            return suggestion?.id === '-1'
              ? suggestion.description
              : `${suggestion.code}: ${suggestion.description}`;
          }}
          finalNode={{id: '-1', code: '', description: 'See more'}}
          onClickFinalNode={() => {
            setShowModal(true);
          }}
          getSuggestions={getSuggestions}
          renderSuggestion={renderSuggestion}
        />
        {value.map(renderSelection)}
      </div>
      {showModal && (
        <ICD10Modal
          show={showModal}
          onSelectCode={onSelect}
          onClose={() => {
            setInputContent('');
            setSuggestedCodes([]);
            setShowModal(false);
          }}
          initialValue={inputContent}
        />
      )}
    </>
  );
}
