import {useEffect, useRef} from 'react';
import {Handler, MessageType, useSocketContext} from '@src/contexts';
import {LonelyVisit} from '@src/models';

export const useVisitCreateEvents = (
  listener: Handler<MessageType.VisitCreate>,
) => {
  const socket = useSocketContext();

  const listenerRef = useRef(listener);

  useEffect(() => {
    listenerRef.current = listener;
  }, [listener]);

  useEffect(() => {
    const handleVisitCreate = (visit: LonelyVisit) =>
      listenerRef.current(visit);

    socket.on(MessageType.VisitCreate, handleVisitCreate);

    return () => {
      socket.off(MessageType.VisitCreate, handleVisitCreate);
    };
  }, [socket]);
};
