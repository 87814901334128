import React from 'react';
import {IconButton} from '@material-ui/core';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useIconStyles} from '@src/components/ui/atoms/buttonsAndIcons/styles';

export function EditIcon({onClick}: {onClick: () => void}) {
  const classes = useIconStyles();

  return (
    <IconButton className={clsx(classes.clickableIcon)}>
      <FontAwesomeIcon
        icon={['fas', 'pencil']}
        size="sm"
        onClick={onClick}
        className={clsx(classes.clickableIcon)}
      />
    </IconButton>
  );
}
