import styled from 'styled-components';
import {secondary, xs, xxs} from '../../ui/theme';

export const Link = styled.span`
  border-radius: ${xxs};
  color: ${secondary};
  cursor: pointer;
  padding: ${xs};
`;

export const ButtonHolder = styled.div`
  text-align: center;
`;
