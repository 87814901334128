import React, {ReactNode} from 'react';
import {Grid} from '@material-ui/core';
import {MuiHeading} from '@src/components/ui/layout/MuiHeading.tsx';
import {sm} from '@src/components/ui/theme/spacing';

type Props = {
  content: ReactNode;
  title: string;
};

export function FormSection({content, title}: Props) {
  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <MuiHeading
          color="primary"
          variant="h6"
          weight="light"
          style={{marginBottom: `${sm}`}}
        >
          {title}
        </MuiHeading>
      </Grid>
      <Grid item xs={12}>
        {content}
      </Grid>
    </Grid>
  );
}
