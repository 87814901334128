import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {SCPHeading} from '@src/components/shared/PatientDashboard/SCP/SCPHeading';
import {
  flashError,
  flashNotify,
} from '@src/components/shared/notifications/flash';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/styles';
import {SharedCarePlan, LonelyPatient} from '@src/models';
import {useApi} from '@src/api/useApi';

type Props = {
  patient: Optional<LonelyPatient>;
  setScp: (scp: SharedCarePlan) => void;
  setPatient: (p: LonelyPatient) => void;
};

export const OwnerRequest: React.FunctionComponent<Props> = ({
  patient,
  setScp: setScp,
  setPatient,
}) => {
  const api = useApi();
  const [submitting, setSubmitting] = useState(false);

  const classes = useStyles();

  const onSubmit = () => {
    setSubmitting(true);

    const patientId = patient.map(p => p.id).orElse(0);
    const patientApi = api.patient(patientId);

    patientApi
      .getSCP()
      .then(newScp => {
        return api.scp(newScp.id).acceptOwnership();
      })
      .then(updatedScp => {
        setScp(updatedScp);
      })
      .then(() => {
        patientApi.get().then(setPatient);
        flashNotify("You are now this plan's owner!");
      })
      .catch(e => {
        console.log(e);

        flashError(
          'Something went wrong with accepting the invitation. Please contact support or try again.',
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Grid className={classes.rowContainer} container direction="row">
      <Grid
        item
        xs={12}
        className={classes.gridItem}
        style={{marginBottom: '1rem'}}
      >
        <SCPHeading>Ownership Request</SCPHeading>
        <Typography variant="caption" style={{fontStyle: 'italic'}}>
          You have been invited to be the owner for this shared care plan. By
          accepting, you agree to keep the information updated and manage all
          requests to edit the information on this plan.
        </Typography>
      </Grid>

      <MuiAsyncActionButton
        bSize="small"
        bStyle="secondary"
        onClick={onSubmit}
        style={{marginBottom: '1em'}}
        actionInProgress={submitting}
        actionWord="Accept Invitation"
      />
    </Grid>
  );
};
