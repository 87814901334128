import React, {useCallback} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useHistory} from 'react-router';
import {LayoutTitle, VisitSection, ButtonFooter} from '../layout';
import {Button} from '@src/components/ui/form/Button/index';
import {not} from '@src/util/predicateHelpers/not';
import {VisitTable} from '@src/components/providerSide/VisitTable';
import {LonelyVisit} from '@src/models';
import {visitIsOnDemand} from '@src/util/visits';

import {
  OnCallColumns,
  ScheduledColumns,
} from '@src/components/providerSide/VisitTable/ColumnSets';

type Props = {
  hairChecked: Record<number, boolean>;
  visits: AsyncData<LonelyVisit>;
  setVisits: (v: LonelyVisit | readonly LonelyVisit[]) => unknown;
  inExam?: boolean;
};

export function VisitsSection({
  hairChecked,
  visits,
  setVisits,
  inExam = false,
}: Props) {
  const history = useHistory();

  const removeVisit = useCallback(
    v => setVisits(visits.filter(viz => viz.id !== v.id).orElse([])),
    [setVisits, visits],
  );

  const updateVisit = useCallback(
    v =>
      setVisits(
        visits
          .map(viz => {
            if (v.id === viz.id) {
              return v;
            }
            return viz;
          })
          .orElse([]),
      ),
    [setVisits, visits],
  );

  return (
    <>
      <VisitSection>
        <LayoutTitle>On Demand Visits</LayoutTitle>
        <VisitTable
          directToRoom={true}
          hairChecked={hairChecked}
          visits={visits.filter(visitIsOnDemand)}
          columns={OnCallColumns}
          onCancel={removeVisit}
          onUpdate={updateVisit}
        />
      </VisitSection>
      <VisitSection>
        <LayoutTitle>Scheduled Visits</LayoutTitle>
        <VisitTable
          directToRoom={true}
          hairChecked={hairChecked}
          visits={visits.filter(not(visitIsOnDemand))}
          columns={ScheduledColumns}
          onCancel={removeVisit}
          onUpdate={updateVisit}
        />
      </VisitSection>
      <ButtonFooter>
        {!inExam && (
          <Button
            onClick={() => {
              history.push('/on-call');
            }}
          >
            Waiting Room
          </Button>
        )}
      </ButtonFooter>
    </>
  );
}
