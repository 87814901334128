import React from 'react';
import {CancelSessionModal} from '@src/components/shared/VideoChat/ExamRoomModals/CancelSessionModal';
import {SimpleVisit} from '@src/models';
import {useApi} from '@src/api/useApi';

type CancelProps = {
  onCancel: (v: SimpleVisit) => unknown;
  returnText?: string;
  visit: SimpleVisit;
  onClose: () => void;
  showCancelModal: boolean;
};

export function CancelVisit({
  onCancel,
  returnText,
  visit,
  onClose,
  showCancelModal,
}: CancelProps) {
  const api = useApi();

  const cancelVisit = (reason: string) => {
    if (!visit) {
      console.error('Tried to cancel a visit that did not exist');
      return Promise.resolve(false);
    }

    return api
      .visit(visit.id)
      .cancel(reason)
      .then(v => {
        onCancel(visit);
        onClose();
      })
      .then(() => true);
  };

  return (
    <>
      {showCancelModal && (
        <CancelSessionModal
          showModal={showCancelModal}
          returnToPatient={onClose}
          onCancelVisit={cancelVisit}
          returnText={returnText}
        />
      )}
    </>
  );
}
