import React from 'react';
import {CallPool} from '@src/models';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {Modal} from '@src/components/ui/layout/Modal';

type Props = {
  group: CallPool;
  reset: () => unknown;
};

export function UnremovableGroupModal({group, reset}: Props) {
  return (
    <Modal show={true} title="Why can't I remove this Group?" onClose={reset}>
      <ParagraphText>
        Because you aren't a Manager for the Group ({group.nameForProviders}),
        you cannot remove it.
      </ParagraphText>
    </Modal>
  );
}
