import React, {ReactNode} from 'react';
import {makeStyles, createStyles, Grid, Theme} from '@material-ui/core';

type Props = {
  children: ReactNode | null;
  style?: Object;
  direction?: 'row' | 'column';
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '2rem',
    },
  }),
);

export function PageContainer({children, style, direction = 'row'}: Props) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction={direction}
      className={classes.container}
      style={style}
    >
      {children}
    </Grid>
  );
}
