import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';
import {PatientDetailsValues} from '../../SharedPatient/patientDetailsUtils';
import {ConnectedLogin} from '../stepFunctions';
import {MedicalHistoryValues} from '../steps/MedicalHistoryStep/PatientMedicalHistoryForm';
import {saveForGuardian} from './saveForGuardian';
import {saveForProvider} from './saveForProvider';
import {handleErrors} from './errorHandling';
import {
  MedicalHistoryDetails,
  UserType,
  LonelyPatient,
  User,
  ProviderDetails,
} from '@src/models';
import {AppAPI} from '@src/api/AppAPI';

type SavePatientProps = {
  user: AsyncData<User>;
  patientDetails: PatientDetailsValues;
  providerDetails: Optional<ProviderDetails>;
  api: AppAPI;
  userType: UserType;
  medicalHistory: MedicalHistoryValues;
  connectedLogins: ConnectedLogin[];
};

export const savePatient = async ({
  user,
  userType,
  patientDetails,
  providerDetails,
  api,
  connectedLogins,
  medicalHistory,
}: SavePatientProps): Promise<LonelyPatient | null> => {
  try {
    const isUserGuardian = userType === UserType.Guardian;
    let patient: LonelyPatient;

    if (isUserGuardian) {
      patient = await saveForGuardian({
        user,
        patientDetails,
        providerDetails,
        api,
      });
    } else {
      patient = await saveForProvider({
        patientDetails,
        providerDetails,
        api,
        connectedLogins,
      });
    }

    await api
      .patient(patient.id)
      .updateMedicalHistory(medicalHistory as MedicalHistoryDetails);

    return patient;
  } catch (e) {
    return handleErrors(e);
  }
};
