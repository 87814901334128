import React from 'react';
import {Tab, Tabs} from '@material-ui/core';
import {SCPFile} from '@src/models';

type Props = {
  scpFiles: readonly SCPFile[];
  tab: number;
  setTab: (v: number) => unknown;
};

export function TabLabels({scpFiles, tab, setTab}: Props) {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Tabs
      value={tab}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
    >
      <Tab label="General" />
      <Tab label="Visits" />
      <Tab label="Shared Care Plan" disabled={scpFiles.length <= 0} />
    </Tabs>
  );
}
