import {PeriodToEdit} from '.';

export const getNewPeriod = (forDate = new Date()): PeriodToEdit => ({
  id: 0,
  startDate: forDate,
  startTime: forDate,
  hours: 0,
  minutes: 0,
  selectedCallPools: [] as number[],
});
