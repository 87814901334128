import React from 'react';
import {Grid} from '@material-ui/core';
import {SCPHeading} from '@src/components/shared/PatientDashboard/SCP/SCPHeading';
import {isEmpty} from '@src/util/typeTests';
import {HelpToolTip} from '@src/components/ui/atoms/buttonsAndIcons/HelpToolTip';
import {sm} from '@src/components/ui/theme';

export function Heading({
  propertyDescription,
  title,
}: {
  propertyDescription: string;
  title: string;
}) {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item>
        <SCPHeading>{title}</SCPHeading>
      </Grid>

      <Grid item style={{marginLeft: sm}}>
        {!isEmpty(propertyDescription) && (
          <HelpToolTip text={propertyDescription} />
        )}
      </Grid>
    </Grid>
  );
}
