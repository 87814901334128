import {makeStyles} from '@material-ui/core';
import styled from 'styled-components';
import {CardContent} from './Card';
import {md} from '@src/components/ui/theme';

export const PatientContent = styled(CardContent)`
  position: relative;
`;

export const VisitButtons = styled.div`
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: bottom;
  width: 100%;
`;

export const useStyles = makeStyles(theme => ({
  badge: {
    right: -8,
    top: 4,
  },
  collapseIcon: {
    color: theme.palette.secondary.contrastText,
    marginLeft: `-${md}`,
    marginRight: `${md}`,
  },
  rowContainer: {
    width: '100%',
    paddingLeft: '5em',
    paddingRight: '5em',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    marginTop: '2em',
    marginBottom: '2em',
    [theme.breakpoints.up('md')]: {
      minHeight: '300px',
    },
  },
  skeletonContainer: {
    width: '85vw',
    paddingLeft: '5em',
    paddingRight: '5em',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    marginTop: '2em',
    margin: '2em auto',
    marginBottom: '2em',
    [theme.breakpoints.up('md')]: {
      minHeight: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
    },
  },

  leftSection: {
    backgroundColor: theme.palette.secondary.main,
    padding: '2rem',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      borderTopRightRadius: theme.spacing(0.5),
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  rightSection: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      borderBottomLeftRadius: theme.spacing(0.5),
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: theme.spacing(0.5),
    },
  },
  rightSectionTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginBottom: '2rem',
    marginTop: '2rem',
  },
  button: {
    fontSize: 18,
    fontWeight: 500,
    textTransform: 'none',
    borderRadius: theme.spacing(0.5),
    borderWidth: '2px',
    marginRight: 'auto',
    color: theme.palette.secondary.contrastText,
    borderColor: theme.palette.grey[500],
    '&:hover': {
      borderColor: theme.palette.common.white,
    },
  },
  infoButton: {
    color: theme.palette.secondary.contrastText,
    marginLeft: theme.spacing(1),
  },
  moreButtonContainer: {
    color: theme.palette.secondary.contrastText,
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 16,
    color: 'rgba(255,255,255,0.5)',
    marginBottom: '1rem',
  },
  name: {
    color: theme.palette.common.white,
    width: '100%',
    textAlign: 'center',
    fontWeight: 700,
  },
  age: {
    color: theme.palette.common.white,
    width: '100%',
    textAlign: 'center',
  },
  tab: {
    fontWeight: 500,
    textTransform: 'none',
    color: theme.palette.secondary.main,
    opacity: 1,
    borderRadius: '4px',
    [theme.breakpoints.up('md')]: {
      minWidth: 12,
      fontSize: 18,
      padding: '0 25px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      fontSize: 16,
    },
  },
  hiddenTab: {
    display: 'none',
  },
  tabContainer: {
    marginRight: 'auto',
    marginBottom: '2em',
    boxShadow: theme.shadows[2],
    borderRadius: '5px',
    cursor: 'pointer',
  },
  regularCell: {
    fontSize: 16,
    fontWeight: 300,
    paddingLeft: 0,
  },
}));
