import React, {ReactNode} from 'react';
import {Grid, Typography, useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {md, sm, lg} from '@src/components/ui/theme';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    width: '100%',
    padding: `0 ${md}`,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: sm,
    },
    marginTop: md,
    marginBottom: lg,
  },
  title: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 700,
  },
}));

type Props = {
  fullWidth?: boolean;
  title: string;
  description?: string;
  children: ReactNode;
};
export function PageSection({
  fullWidth = false,
  title,
  children,
  description,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid
      className={classes.rowContainer}
      style={{marginBottom: '0'}}
      container
      direction={matchesMedium ? 'row' : 'column'}
    >
      <Grid item md={fullWidth ? 12 : 8} xs={12}>
        <Grid style={{padding: '1rem 1rem 1rem 0'}}>
          <Typography variant="h2" style={{fontWeight: 'bold'}}>
            {title}
          </Typography>
          {description && (
            <Typography variant="caption" style={{fontStyle: 'italic'}}>
              {description}
            </Typography>
          )}
        </Grid>
        {children}
      </Grid>
    </Grid>
  );
}
