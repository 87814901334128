import React, {useCallback, useState} from 'react';
import {Modal} from '../../ui/layout/Modal';
import {flashSuccess} from '../notifications/flash';
import {GuardianContactIcon, GuardianContactRow, GuardianInfo} from './layout';
import {Invitation} from '@src/models';
import {useApi} from '@src/api/useApi';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';

type Props = {
  patientId: number;
  invitation: Invitation;
  onDelete: () => unknown;
  onClose: () => void;
  show?: boolean;
};

export function EditPendingLoginModal({
  patientId,
  invitation,
  onDelete,
  show = false,
  onClose,
}: Props) {
  const [deleting, setDeleting] = useState(false);
  const [resending, setResending] = useState(false);

  const {email, relationshipName, isSelf} = invitation;
  const api = useApi();

  const deleteInvitation = useCallback(() => {
    setDeleting(true);
    api
      .patient(patientId)
      .deleteInvitation(email)
      .then(onDelete)
      .then(() => setDeleting(false));
  }, [api, email, onDelete, patientId]);

  const resendInvitation = useCallback(() => {
    setResending(true);
    api
      .patient(patientId)
      .invitePerson({email, isSelf, relationshipName})
      .then(() => {
        flashSuccess(`Invitation email sent to ${email}`);
        setResending(false);
      });
  }, [api, email, isSelf, patientId, relationshipName]);

  return (
    <Modal
      show={show}
      title="Pending Connected Login Details"
      onClose={onClose}
    >
      <GuardianInfo>
        <GuardianContactRow>
          <GuardianContactIcon icon={'users'} fixedWidth />
          <div>Relationship: {invitation.relationshipName}</div>
        </GuardianContactRow>
        <GuardianContactRow>
          <GuardianContactIcon icon={['fal', 'envelope']} fixedWidth />
          <div>{invitation.email}</div>
        </GuardianContactRow>
      </GuardianInfo>
      <GuardianContactRow>
        <MuiAsyncActionButton
          bStyle="primary"
          bSize="small"
          onClick={resendInvitation}
          disabled={deleting}
          actionInProgress={resending}
          actionWord="Resend Invitation"
          isInRow={true}
        />
        <MuiAsyncActionButton
          bStyle="warning"
          bSize="small"
          onClick={deleteInvitation}
          disabled={resending}
          actionInProgress={deleting}
          actionWord="Delete Invitation"
          isInRow={true}
        />
      </GuardianContactRow>
    </Modal>
  );
}
