import React from 'react';
import styled from 'styled-components';
import File from './File';

const CompletedFile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

type Props = {
  file: {name: string};
  onClickEyeIcon?: () => void;
};

export const CompletedUpload = ({file, onClickEyeIcon}: Props) => {
  return (
    <CompletedFile>
      <File
        file={file}
        progress={100}
        completed
        onClickEyeIcon={onClickEyeIcon}
      />
    </CompletedFile>
  );
};
