import {Optional} from '@ahanapediatrics/ahana-fp';
import {signupNewPatient} from '../../../creators';
import {ConnectedLogin} from '../../stepFunctions';
import {PatientDetailsValues} from '../../../SharedPatient/patientDetailsUtils';
import {addConnectedLogins} from './connectedLogins/addConnectedLogins';
import {maybeSetUpSelfRelationship} from './selfRelationship/maybeSetUpSelfRelationship';
import {ProviderDetails} from '@src/models';
import {AppAPI} from '@src/api/AppAPI';
import {convertDetailsToRequest} from '@src/api/ProviderDetailsAPI';

type SaveForProviderProps = {
  patientDetails: PatientDetailsValues;
  providerDetails: Optional<ProviderDetails>;
  api: AppAPI;
  connectedLogins: ConnectedLogin[];
};

export const saveForProvider = async ({
  patientDetails,
  providerDetails,
  api,
  connectedLogins,
}: SaveForProviderProps) => {
  const patient = await signupNewPatient(api)({
    ...patientDetails,
    details: Optional.of(convertDetailsToRequest(providerDetails)),
  });

  await maybeSetUpSelfRelationship({api, connectedLogins, patient});
  await addConnectedLogins({api, connectedLogins, patient});

  return patient;
};
