import {Typography, Grid} from '@material-ui/core';
import React from 'react';
import {ConnectedLogin} from '../../NewPatient/stepFunctions';
import {useStyles} from './styles';

type Props = {
  connectedLogins: ConnectedLogin[];
};

export function ConnectedLoginsSummary({connectedLogins}: Props) {
  const classes = useStyles();
  const renderItem = (login: ConnectedLogin) => {
    return (
      <Grid item key={login.email}>
        <b>{login.relationshipName}</b>: {login.email}
      </Grid>
    );
  };

  if (connectedLogins.length === 0) {
    return null;
  }

  return (
    <div className={classes.section}>
      <Typography variant="h2" className={classes.heading}>
        Connected Logins
      </Typography>
      <Grid container direction="column">
        {connectedLogins.map(renderItem)}
      </Grid>
    </div>
  );
}
