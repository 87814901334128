import {InProgressFile} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';

type UploadAction =
  | {
      type: 'add';
      payload: {
        file: File;
      };
    }
  | {
      type: 'update';
      payload: {
        file: File;
        progress: number;
      };
    }
  | {
      type: 'complete';
      payload: {
        file: File;
      };
    }
  | {
      type: 'error';
      payload: {
        file: File;
        error: string;
      };
    };

export const uploadingReducer = (
  onUpdate: (files: InProgressFile[]) => void,
) => (state: InProgressFile[], action: UploadAction) => {
  let newList: typeof state = [];

  if (action.type === 'add') {
    const {file} = action.payload;
    newList = [
      ...state,
      {file, progress: 0, completed: false, contentType: file.type},
    ];
  } else if (action.type === 'update') {
    const {file, progress} = action.payload;
    const listedFileIndex = state.findIndex(f => f.file.name === file.name);
    if (listedFileIndex < 0 && progress > 0) {
      throw new Error('File not found in list');
    } else {
      newList = state.map(item => {
        if (item.file.name !== file.name) {
          return item;
        }
        return {
          ...item,
          file,
          progress,
        };
      });
    }
  } else if (action.type === 'complete') {
    const {file} = action.payload;
    newList = state.filter(item => item.file.name !== file.name);
  } else if (action.type === 'error') {
    const {file, error} = action.payload;
    const listedFileIndex = state.findIndex(f => f.file.name === file.name);
    if (listedFileIndex < 0) {
      throw new Error('File not found in list');
    } else {
      newList = state.map(item => {
        if (item.file.name !== file.name) {
          return item;
        }
        return {
          ...item,
          file,
          error,
        };
      });
    }
  } else {
    throw new Error(`Unknown upload action type received: ${action}`);
  }

  onUpdate(newList);
  return newList;
};
