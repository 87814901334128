import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {Grid, Paper, useMediaQuery} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import React from 'react';
import {PatientFundamentals} from '../PatientDashboard/PatientFundamentals';
import {SimplePatientDetails} from './SimplePatientDetails';
import {useStyles} from './styles';
import {LonelyPatient, MedicalHistory} from '@src/models';
import {PatientActionMenu} from '@src/components/shared/PatientActionMenu';

type Props = {
  patient?: LonelyPatient;
  currentMedicalHistory: AsyncData<MedicalHistory>;
  inExam: boolean;
  updatePatient: (patient: LonelyPatient) => void;
};

export function SimplePatientCard({
  currentMedicalHistory,
  patient,
  inExam,
  updatePatient,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMedium = useMediaQuery(theme.breakpoints.up('md'));

  if (!patient) {
    return null;
  }

  return (
    <Grid
      className={classes.rowContainer}
      style={{marginBottom: '0'}}
      container
      direction={matchesMedium ? 'row' : 'column'}
    >
      {/* Left Side */}
      <Grid item md={5} xs={12}>
        <Paper className={classes.leftSection} style={{padding: '1rem 2rem'}}>
          <Grid item container direction="row" justify="flex-start">
            {!inExam && (
              <PatientActionMenu
                className={classes.patientMenu}
                patient={patient}
                updatePatient={updatePatient}
              />
            )}
            <PatientFundamentals
              currentMedicalHistory={currentMedicalHistory}
              patient={patient}
            />
          </Grid>
        </Paper>
      </Grid>

      {/* Right Side */}
      <Grid item md={7} xs={12}>
        <Paper className={classes.rightSection} style={{padding: '1rem 2rem'}}>
          <Grid item container direction="row">
            <SimplePatientDetails
              currentMedicalHistory={currentMedicalHistory}
              patient={patient}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
