import {makeStyles} from '@material-ui/core/styles';
import {md, sm} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  // Containers
  expandableSectionContainer: {
    marginTop: md,
  },
  // Accordion
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
  },
  arrowIcon: {
    height: 'inherit',
    marginRight: sm,
  },
}));
