import classnames from 'classnames';
import * as React from 'react';
import {useState, useCallback} from 'react';
import {MuiAsyncActionButton} from './MuiAsyncActionButton';
import {ButtonProps} from './Button/types';

type Props = Omit<ButtonProps, 'onClick'> & {
  actionWord: string;
  actionInProgressWord?: string;
  onClick: () => Promise<unknown>;
  onInProgess?: (inProgress: boolean) => unknown;
};

export const PromiseButton = ({
  bSize = 'normal',
  bStyle = 'primary',
  actionInProgressWord,
  actionWord,
  active = false,
  block = false,
  className = '',
  disabled = false,
  onClick,
  isInRow,
  onInProgess = () => {},
}: Props) => {
  const [inProgress, setInProgress] = useState(false);

  const toggleProgress = useCallback(() => {
    onInProgess(!inProgress);
    setInProgress(!inProgress);
  }, [setInProgress, inProgress, onInProgess]);

  return (
    <MuiAsyncActionButton
      className={classnames([className])}
      active={active}
      bSize={bSize}
      bStyle={bStyle}
      block={block}
      isInRow={isInRow}
      onClick={() => {
        toggleProgress();
        onClick().finally(toggleProgress);
      }}
      disabled={disabled || inProgress}
      actionInProgress={inProgress}
      actionInProgressWord={actionInProgressWord}
      actionWord={actionWord}
    />
  );
};
