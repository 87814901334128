import {ref as yRef} from 'yup';
import {getRequiredMessage} from '../getRequiredMessage';
import {rqString, yString} from '@src/schema/types';
import {canonicalizeNumber} from '@src/util/numberManipulation/phone/canonicalizeNumber';

export function getRequiredPhoneValidator() {
  return rqString(getRequiredMessage('a', 'complete phone number'))
    .transform(canonicalizeNumber)
    .length(10);
}

export function getMatchingEmailsValidator() {
  return {
    email: rqString(getRequiredMessage('an', 'email')).email(
      'Email must be valid',
    ),
    confirmEmail: yString.oneOf([yRef('email'), null], 'Emails must match'),
  };
}
