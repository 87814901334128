import React from 'react';
import {ShareLinkModal} from '../../ExamRoomModals/ShareLinkModal';

type Props = {
  setShowShareModal: (v: boolean) => unknown;
  showShareModal: boolean;
  visitId: number;
};

export function Modals({showShareModal, setShowShareModal, visitId}: Props) {
  return (
    <>
      {showShareModal && (
        <ShareLinkModal
          visitId={visitId}
          showModal={showShareModal}
          setShowModal={setShowShareModal}
        />
      )}
    </>
  );
}
