import {darken} from '@material-ui/core';
import styled from 'styled-components';
import {TextInput} from '..';
import {mobilePortraitWidth, primary} from '@src/components/ui/theme';

export const AddressDetails = styled.div``;

export const HouseAndStreet = styled.div`
  display: flex;
  flex-direction: row;

  .input-container {
    margin-bottom: 0;
  }

  @media (max-width: ${mobilePortraitWidth}) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`;

export const City = styled.div`
  display: flex;
  flex-direction: row;
  .input-container {
    margin-bottom: 0;
  }

  @media (max-width: ${mobilePortraitWidth}) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`;

export const StateAndZip = styled.div`
  display: flex;
  flex-direction: row;
  > div {
    width: 100%;
  }
`;

export const SearchBoxContainer = styled.div<{show: boolean}>`
  display: ${props => (props.show ? 'block' : 'none')};
  margin: 1rem 0;
`;

export const AddressWideInput = styled(TextInput)`
  margin-right: 0.5rem;
  width: 75%;
`;

export const AddressLine2Input = styled(TextInput)`
  width: 25%;
`;

export const EnterManually = styled.div`
  color: ${primary};
  text-align: right;
  cursor: pointer;
  margin: 0.5rem 0;
  &:hover {
    color: ${darken(primary, 0.1)};
  }
`;
