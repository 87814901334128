import React from 'react';
import {useUser} from '@src/hooks/useUser';
import {CallPool} from '@src/models';
import {InfoTable} from '@src/components/ui/layout/InfoTable';

type Props = {
  providerGroup: CallPool;
};

export function ProviderGroupDetails({providerGroup}: Props) {
  const [user] = useUser();

  if (!providerGroup || !user.isLoaded()) return null;

  const tableItems = [
    {label: 'Name for Patients', content: providerGroup.nameForGuardians},
    {label: 'Description', content: providerGroup.description},
  ];

  return <InfoTable items={tableItems} />;
}
