import React from 'react';
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(label: string, selections: string[], theme: Theme) {
  return {
    fontWeight:
      selections.indexOf(label) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function CustomerCodesMultiselect({
  handleChange,
  options,
  selections,
  disabled = false,
}: {
  handleChange: (event: React.ChangeEvent<{value: unknown}>) => unknown;
  options: {label: string; value: number}[];
  selections: {label: string; value: number}[];
  disabled?: boolean;
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Customer Codes</InputLabel>
      <Select
        multiple
        value={selections.map(s => s.label)}
        onChange={handleChange}
        input={<Input />}
        renderValue={selected => (
          <div className={classes.chips}>
            {(selected as string[]).map(value => (
              <Chip key={value} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
        disabled={disabled}
      >
        {options.map(option => (
          <MenuItem
            key={option.label}
            value={option.label}
            style={getStyles(
              option.label,
              selections.map(s => s.label),
              theme,
            )}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
