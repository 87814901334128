import React, {ReactNode, useState} from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
} from '@material-ui/core';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {MergeStateIcon} from '../shared/Tables/getMergeStateIcon';
import {useStyles} from './styles';
import {Mergeable} from './isMergeable/isSectionMergeable';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {md} from '@src/components/ui/theme';
import {MergeOptions} from '@src/models/Patient';
import {isNothing} from '@src/util/typeTests';

type Props = {
  title: string;
  children: ReactNode;
  mergeable: Mergeable;
  mergeOptions?: AsyncData<MergeOptions>;
};

export function ExpandableSection({
  title,
  children,
  mergeable,
  mergeOptions,
}: Props) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const loading = isNothing(mergeOptions)
    ? mergeable === 'CHECKING'
    : !mergeOptions?.isLoaded() || mergeable === 'CHECKING';

  return (
    <Grid
      container
      direction="column"
      className={classes.expandableSectionContainer}
    >
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary>
          {expanded ? (
            <ExpandMoreIcon className={classes.arrowIcon} />
          ) : (
            <ExpandLessIcon className={classes.arrowIcon} />
          )}
          <Typography color="primary" variant="h2">
            {title}
          </Typography>
          {MergeStateIcon({
            state: mergeable,
            style: {
              marginLeft: md,
              height: 'inherit',
            },
          })}
        </AccordionSummary>
        <AccordionDetails>
          <PageLoading active={loading} message={`Checking ${title}s`}>
            {children}
          </PageLoading>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
