import React from 'react';
import {List, ListSubheader} from '@material-ui/core';
import {useStyles} from './util/styles';
import {Files} from '@src/components/ui/organisms/uploaders/files/Folder/Files';
import {SCPFile} from '@src/models';

export function ScpFolders({files}: {files: readonly SCPFile[]}) {
  const classes = useStyles();

  return (
    <List
      component="nav"
      subheader={
        <ListSubheader component="div">
          Files From Shared Care Plan
        </ListSubheader>
      }
      className={classes.root}
    >
      <Files files={files} open={true} />{' '}
    </List>
  );
}
