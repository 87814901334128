import {useMemo, useState} from 'react';

export type AddPeriodModalsResources = {
  date: Date;
  setDate: (v: Date) => unknown;
};

export const useAddPeriodModalsResources = (): AddPeriodModalsResources => {
  const [singlePeriodModalDate, setSinglePeriodModalDate] = useState(
    new Date(),
  );

  return useMemo(() => {
    return {
      date: singlePeriodModalDate,
      setDate: setSinglePeriodModalDate,
    };
  }, [singlePeriodModalDate, setSinglePeriodModalDate]);
};
