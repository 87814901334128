import {RemoteDataStatus} from '@ahanapediatrics/ahana-fp';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router';
import {StepComponents, StepNames, buildFlow} from './functions';
import {Header, StepperContainer} from './layout';
import Step from './Step';
import {useApi} from '@src/api/useApi';
import {PrivatePage} from '@src/components/PrivatePage';
import {DashboardContent} from '@src/components/providerSide/PastVisitsDashboard/layout';
import {NavigationContainer} from '@src/components/shared/forms/FlowPage/layout';
import {
  flashError,
  flashNotify,
} from '@src/components/shared/notifications/flash';
import {Button} from '@src/components/ui/form';
import {PageHeader} from '@src/components/ui/layout';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {RequestBox} from '@src/components/ui/layout/NewThingRequest';
import {TwoGroupsOfButtons} from '@src/components/ui/molecules/buttons/TwoGroupsOfButtons';
import {useAsync, useResources} from '@src/hooks';
import {useInput} from '@src/hooks/useInput';
import {useQuery} from '@src/hooks/useQuery';
import {useSteps} from '@src/hooks/useSteps';
import {
  $NewVisit,
  AssignedForm,
  CallPool,
  LegalDocument,
  LonelyVisit,
  Patient,
  VisitState,
} from '@src/models';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {ReduxState} from '@src/store';
import {isNewVisitPath} from '@src/util/visits';

type PageParameters = {
  patientId: string;
  callPoolId: string;
  visitId?: string;
};

// eslint-disable-next-line import/no-unused-modules
export default function CreateOrJoinVisit() {
  const api = useApi();
  const history = useHistory();
  const query = useQuery();
  const params = useParams<PageParameters>();
  const {
    selectedAudioOutput,
    selectedAudioInput,
    selectedVideoInput,
  } = useSelector((state: ReduxState) => ({
    ...state.media,
  }));

  const visitId = +(params.visitId ?? '0');
  const callPoolId = +(params.callPoolId ?? '0');
  const patientId = +(params.patientId ?? '0');
  const providerDetailsId = query.get('providerDetailsId') ?? null;

  const [nextAvailable, setNextAvailable] = useState(false);
  const [patient, reloadPatient] = useResources<Patient>(
    () => api.patient(patientId).get(),
    [api, patientId],
  );
  const [callPool] = useResources<CallPool>(
    () => api.callPool(callPoolId).get(),
    [api, callPoolId],
  );
  const [unsignedDocs] = useResources<LegalDocument>(
    () => api.patient(patientId).getUnsignedCallPoolDocuments(callPoolId),
    [api, callPoolId, patientId],
  );
  const [requiredDocs] = useResources<LegalDocument>(
    () => api.callPool(callPoolId).getDocuments(),
    [api, callPoolId],
  );
  const [assignedForms] = useResources<AssignedForm>(
    async () => api.patient(patientId).getForms(),
    [api],
  );
  const isNewVisit = isNewVisitPath(history.location.pathname);

  const [visit, setVisit] = useAsync<LonelyVisit>();
  const [
    ready,
    currentStep,
    isLastStep,
    StepComponent,
    {forward, backward},
    stepNames,
    setFlow,
  ] = useSteps(StepComponents, StepNames);

  const moveGuard = useRef(async () => true);

  const primaryReason = useInput('');
  const fallbackNumber = useInput('');
  const location = useInput('');
  const dontUseInsurance = useInput<boolean | null>(null);
  const dontUseCreditCard = useInput<boolean | null>(null);

  const proceedToNextStep = useCallback(() => {
    if (isLastStep) {
      const id = isNewVisit
        ? visit
            .getOptional()
            .map(v => v.id)
            .orElse(0)
        : visitId ?? 0;

      history.push(`/exam-room/${id}`);
    } else {
      forward();
    }
  }, [forward, history, isLastStep, isNewVisit, visitId, visit]);

  useEffect(() => {
    if (!isNewVisit && visit.is(RemoteDataStatus.NotAsked)) {
      setVisit();
      const id = visitId;
      api
        .visit(id)
        .get()
        .then(setVisit);
    }
  }, [api, visit, setVisit, isNewVisit, visitId]);

  useEffect(() => {
    if (callPool.isLoaded() && requiredDocs.isLoaded()) {
      const stepPlan = buildFlow({
        assignedForms: assignedForms.getAllOptional().orElse([]),
        pool: callPool.singleValue(),
        patient: patient.getOptional(),
        requiredDocs: requiredDocs.getAllOptional().orElse([]),
        isNewVisit,
        dontUseCreditCard,
        dontUseInsurance,
        visitId,
      });
      setFlow(stepPlan);
    }
  }, [
    assignedForms,
    callPool,
    dontUseInsurance,
    dontUseCreditCard,
    isNewVisit,
    patient,
    requiredDocs,
    setFlow,
    visit,
  ]);

  useEffect(() => {
    if (isLastStep) {
      if (isNewVisit) {
        if (visit.is(RemoteDataStatus.NotAsked)) {
          setVisit();
          const visitData: $NewVisit = {
            chiefComplaint: primaryReason.value,
            start: new Date(),
            status: VisitState.CREATED,
            healthcareNotes: '',
            fallbackNumber: fallbackNumber.value,
            callPoolId: callPool
              .getOptional()
              .map(c => c.id)
              .orElse(0),
            onDemand: providerDetailsId === null,
            dontUseInsurance: dontUseInsurance.value ?? false,
            dontUseCreditCard: dontUseCreditCard.value ?? false,
          };
          api
            .patient(
              patient
                .getOptional()
                .map(p => p.id)
                .orElse(0),
            )
            .createVisit(visitData)
            .then(async (v: LonelyVisit) => {
              if (providerDetailsId !== null && +providerDetailsId !== 0) {
                await api
                  .visit(v.id)
                  .setProvider(+providerDetailsId as ProviderDetailsId);
              }
              return v;
            })
            .then(setVisit)
            .then(() => {
              flashNotify(
                <>
                  We've sent an alert to the medical team and they'll be with
                  you as soon as they are able.
                </>,
              );
            });
        }
      } else {
        api
          .visit(visitId)
          .update({
            dontUseInsurance: dontUseInsurance.value ?? false,
            dontUseCreditCard: dontUseCreditCard.value ?? false,
          })
          .catch(() => {
            flashError(
              <>
                Something went wrong setting up this visit. Please contact
                Support or try again.
              </>,
            );
          });
      }
    }
  }, [
    api,
    isLastStep,
    callPool,
    currentStep,
    dontUseInsurance,
    dontUseCreditCard,
    fallbackNumber,
    patient,
    primaryReason,
    providerDetailsId,
    setVisit,
    visit,
    visitId,
    isNewVisit,
  ]);

  const readyForFlow =
    ready &&
    patient.isLoaded() &&
    callPool.isLoaded() &&
    unsignedDocs.isLoaded() &&
    requiredDocs.isLoaded() &&
    assignedForms.isLoaded();

  const backNavigationButtons = [
    <Button
      bStyle="secondary"
      onClick={() => {
        if (currentStep === 0) {
          history.goBack();
        } else {
          backward();
        }
      }}
    >
      Back
    </Button>,
    <Button
      bStyle="outlined"
      onClick={() => {
        history.goBack();
      }}
    >
      Start Over
    </Button>,
  ];

  const forwardNavigationButtons = [
    <Button
      disabled={!nextAvailable}
      bStyle="primary"
      onClick={proceedToNextStep}
    >
      {isLastStep ? 'Enter Exam Room' : 'Next'}
    </Button>,
  ];

  return (
    <PrivatePage>
      <PageLoading active={!readyForFlow} message="Loading patient information">
        <DashboardContent>
          <Header>
            <PageHeader>
              {isNewVisit ? 'Request Visit' : 'Visit Prep'}
            </PageHeader>
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </Header>
          <StepperContainer>
            {stepNames.map((name, stepNumber) => (
              <Step
                key={stepNumber}
                step={stepNumber}
                name={name}
                complete={currentStep > stepNumber}
                upcoming={currentStep < stepNumber}
              />
            ))}
          </StepperContainer>
          <RequestBox>
            {readyForFlow && StepComponent && (
              <StepComponent
                paymentProcessorInfo={
                  callPool.singleValue().paymentProcessorInfo
                }
                callPoolId={callPoolId}
                moveGuard={moveGuard}
                primaryReason={primaryReason}
                fallbackNumber={fallbackNumber}
                location={location}
                dontUseInsurance={dontUseInsurance}
                dontUseCreditCard={dontUseCreditCard}
                patient={patient.singleValue()}
                financialRelationship={patient
                  .getOptional()
                  .map(p => p.financialRelationship)}
                setNextAvailable={setNextAvailable}
                onUpdatePatient={reloadPatient}
                proceedToNextStep={proceedToNextStep}
                requiredDocs={requiredDocs.getAllOptional()}
                unsignedDocs={unsignedDocs.getAllOptional()}
                rules={callPool.singleValue().rules}
                visitId={visitId}
                {...{
                  selectedAudioOutput,
                  selectedAudioInput,
                  selectedVideoInput,
                }}
              />
            )}
          </RequestBox>
          <NavigationContainer>
            <TwoGroupsOfButtons
              rightOrTopGroup={forwardNavigationButtons}
              leftOrBottomGroup={backNavigationButtons}
            />
          </NavigationContainer>
        </DashboardContent>
      </PageLoading>
    </PrivatePage>
  );
}
