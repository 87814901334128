import React, {useEffect, useRef} from 'react';
import {usePageContext} from '.';
import {useApi} from '@src/api/useApi';
import {NoSCPDisplay} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/NoSCPDisplay';
import {NotificationBanner} from '@src/components/shared/PatientDashboard/SCP/NotificationBanner';
import {Contacts} from '@src/components/shared/PatientDashboard/SCP/panels/Contacts';
import {GuardianControls} from '@src/components/shared/PatientDashboard/SCP/panels/controls/GuardianControls';
import {OwnerControls} from '@src/components/shared/PatientDashboard/SCP/panels/controls/OwnerControls';
import {EmergencyCare} from '@src/components/shared/PatientDashboard/SCP/panels/EmergencyCare';
import {MedicalHistoryPanel} from '@src/components/shared/PatientDashboard/SCP/panels/MedicalHistoryPanel';
import {MedicationOverview} from '@src/components/shared/PatientDashboard/SCP/panels/MedicationOverview';
import {Preferences} from '@src/components/shared/PatientDashboard/SCP/panels/Preferences';
import {SCPChangeLog} from '@src/components/shared/PatientDashboard/SCP/panels/SCPChangeLog';
import {SummaryView} from '@src/components/shared/PatientDashboard/SCP/panels/SummaryView';
import {Uploads} from '@src/components/shared/PatientDashboard/SCP/panels/Uploads';
import {SCPPrintout} from '@src/components/shared/PatientDashboard/SCP/SCPPrintout';
import {SCPTabs} from '@src/components/shared/PatientDashboard/SCP/SCPTabs';
import {SimplePatientCard} from '@src/components/shared/SimplePatientCard';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {useAsync} from '@src/hooks/useAsync';
import {PatientRelationship} from '@src/models';

const notSelf = (g: PatientRelationship) => !g.isSelf;
const excludeSelfFromGuardians = (gs: PatientRelationship[]) =>
  gs.filter(notSelf);

export function SCPReview() {
  const {
    scp,
    patient,
    setPatient,
    currentMedicalHistory,
    files,
    setFiles,
    hasScp,
    reviewMode,
  } = usePageContext();

  const api = useApi();

  const [guardianships, setGuardianships] = useAsync<PatientRelationship>();

  const printRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    patient.getOptional().ifPresent(p => {
      api
        .patient(p.id)
        .getGuardianships()
        .then(excludeSelfFromGuardians)
        .then(setGuardianships, setGuardianships);
    });
  }, [api, patient, setGuardianships]);

  return (
    <>
      <div style={{display: 'none'}}>
        <SCPPrintout ref={printRef} guardianships={guardianships} />
      </div>
      <PageLoading
        active={!(scp.isLoaded() || (patient.isLoaded() && !hasScp))}
        message={'Checking for Shared Care Plan...'}
      >
        <SimplePatientCard
          patient={patient.getOptional().orNothing()}
          currentMedicalHistory={currentMedicalHistory}
          inExam={reviewMode}
          updatePatient={setPatient}
        />

        {!hasScp && <NoSCPDisplay />}

        {hasScp && (
          <>
            <SCPTabs printRef={printRef} />
            <NotificationBanner />
            <SummaryView guardians={guardianships} />
            <Preferences guardians={guardianships} />
            <MedicationOverview />
            <MedicalHistoryPanel />
            <EmergencyCare />
            <Contacts guardians={guardianships} />
            <Uploads files={files} setFiles={setFiles} />
            <SCPChangeLog />
            <OwnerControls />
            <GuardianControls />
          </>
        )}
      </PageLoading>
    </>
  );
}
