/**
 * This allows us to 'tap' into a series of `Promise.then`s and do something
 * with the value at the point of the tapping and then proceed.
 *
 * @param fn The function that does something with the tapped value
 *           Note that `tap` really should not try to mutate the value...
 *           just use a normal `then` for that.
 */
export const tap = <T>(fn: (t: T) => unknown) => async (val: T) => {
  fn(val);
  return val;
};
