import clsx from 'clsx';
import {format} from 'date-fns';
import React, {useCallback, useState} from 'react';
import {Grid, Card, CardContent, CardHeader} from '@material-ui/core';
import {useStyles} from '../../../../layout';
import {ShiftSection} from './ShiftSection';
import {ShiftMenu} from './ShiftMenu';
import {usePageContext} from '@src/components/providerSide/providerGroups/ProviderGroupRosterPage';
import {OnCallPeriod, Professional} from '@src/models';
import {ConfirmModal} from '@src/components/ui/layout/ConfirmModal';
import {useApi} from '@src/api/useApi';
import {UserId} from '@src/models/User';
import {flashError} from '@src/components/shared/notifications/flash';
import {providerAndQualification} from '@src/util/provider';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';
import {md} from '@src/components/ui/theme';

type Props = {
  period: OnCallPeriod;
};

export function Shift({period}: Props) {
  const {
    monthTable: {reloadPeriodsMappedToDaysOfSelectedMonth},
  } = usePageContext();

  const classes = useStyles();
  const api = useApi();

  const [providerToRemoveId, setProviderToRemoveId] = useState<UserId>(
    0 as UserId,
  );

  const onRemoveProvider = useCallback(
    (p: Professional) => {
      return () => {
        setProviderToRemoveId(p.id);
      };
    },
    [setProviderToRemoveId],
  );

  const onConfirmRemoveProvider = useCallback(() => {
    if (providerToRemoveId === 0) {
      flashError(
        'Something went wrong trying to remove the Provider from this Shift. Please contact Support.',
      );
      return Promise.reject();
    }

    return api
      .onCallPeriods(period.id)
      .removeProvider(providerToRemoveId)
      .then(() => {
        reloadPeriodsMappedToDaysOfSelectedMonth();
        setProviderToRemoveId(0 as UserId);
      });
  }, [providerToRemoveId, api, period]);

  return (
    <Grid item>
      <Card
        className={clsx(
          period.providers.length > 0
            ? classes.providerItem
            : classes.noProviderItem,
          classes.periodCard,
        )}
      >
        <CardHeader
          className={classes.periodCardHeader}
          title={`${format(period.start, 'HH:mm')} - ${format(
            period.end,
            'HH:mm',
          )}`}
          titleTypographyProps={{variant: 'body2'}}
          action={<ShiftMenu period={period} />}
        />
        <CardContent classes={{root: classes.shiftContent}}>
          <Grid container direction="column" spacing={1}>
            <ShiftSection
              title="Professionals"
              items={period.providers.map(p => {
                const description = p.providerDetails
                  .map(providerAndQualification)
                  .orElse('');
                const icon = (
                  <ClickableIcon
                    icon={['fas', 'trash']}
                    onClick={onRemoveProvider(p)}
                  />
                );

                return {description, icon};
              })}
            />
          </Grid>
          <Grid
            container
            direction="column"
            spacing={1}
            style={{marginTop: md}}
          >
            <ShiftSection
              title="Groups"
              items={period.callPools.map(g => ({
                description: g.nameForProviders,
              }))}
            />
          </Grid>
        </CardContent>
      </Card>

      {providerToRemoveId !== 0 && (
        <ConfirmModal
          onConfirm={onConfirmRemoveProvider}
          text="Confirming will remove the Professional from this Shift."
          show={Boolean(providerToRemoveId)}
          onHide={() => {
            setProviderToRemoveId(0 as UserId);
          }}
          onCancel={() => {
            setProviderToRemoveId(0 as UserId);
          }}
          cancelText="Cancel"
          confirmText="Remove Professional"
          titleText="Are you sure?"
        />
      )}
    </Grid>
  );
}
