import React, {useEffect} from 'react';
import {
  FormControlLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {ExpandableSection} from '../ExpandableSection';
import {Mergeable} from '../ExpandableSection/isMergeable/isSectionMergeable';
import {useStyles as useGeneralStyles} from '../shared/styles';
import {useStyles} from '../shared/Tables/styles';
import {FailText} from '../shared/ExplanationText/FailText';
import {getRelationshipsHeadings} from '../shared/Tables/TableHeader/relationshipsComparerHeaderUtils';
import {ConnectedLoginsResult} from '../shared/types';
import {TableHeader} from '../shared/Tables/TableHeader';
import {ResultIconCell} from '../shared/Tables/ResultIconCell';
import {getMergeState} from './utils';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {MergeOptions} from '@src/models/Patient';

type Props = {
  setIsSectionMergeable: (mergeable: Mergeable) => unknown;
  isSectionMergeable: Mergeable;
  mergeOptions: AsyncData<MergeOptions>;
  connectedLogins: ConnectedLoginsResult;
  setConnectedLogins: (v: ConnectedLoginsResult) => unknown;
};

export function RelationshipsComparer({
  setIsSectionMergeable,
  isSectionMergeable,
  mergeOptions,
  connectedLogins,
  setConnectedLogins,
}: Props) {
  const aMergeOptions = mergeOptions.getOptional().orNull();
  const classes = {...useStyles(), ...useGeneralStyles()};

  useEffect(() => {
    if (!mergeOptions.isLoaded()) {
      return;
    }

    if (aMergeOptions === null) {
      setIsSectionMergeable('FAIL');
    } else {
      const {guardianships} = aMergeOptions;

      const result = guardianships.reduce((acc, element) => {
        if (element.nameOptions.length < 2) {
          return {...acc, [element.person.id]: element.nameOptions[0]};
        } else {
          return {...acc, [element.person.id]: ''};
        }
      }, {});

      setConnectedLogins(result);

      const autoMerge =
        guardianships.filter(g => g.nameOptions.length > 1).length === 0;

      if (autoMerge) {
        setIsSectionMergeable('PASS');
      } else {
        setIsSectionMergeable('CONFLICT');
      }
    }
  }, [aMergeOptions]);

  useEffect(() => {
    if (
      Object.values(connectedLogins).every(l => l.length > 0) &&
      isSectionMergeable === 'CONFLICT'
    ) {
      setIsSectionMergeable('RESOLVED');
    }
  }, [connectedLogins]);

  const headings = getRelationshipsHeadings();

  return (
    <ExpandableSection title="Connected Logins" mergeable={isSectionMergeable}>
      <TableContainer>
        <div className={classes.comparerMessage}>
          {isSectionMergeable === 'FAIL' && <FailText />}
          {isSectionMergeable === 'PASS' && (
            <ParagraphText>
              All Connected Logins can be merged automatically.
            </ParagraphText>
          )}

          {isSectionMergeable === 'RESOLVED' && (
            <ParagraphText>
              This Patient's Connected Logins can now be merged.
            </ParagraphText>
          )}

          {isSectionMergeable === 'CONFLICT' && (
            <div className={classes.comparerMessage}>
              <ParagraphText>
                The Patient's Connected Logins cannot be merged in their current
                state because there are some Relationship conflicts.
              </ParagraphText>{' '}
              <ParagraphText>
                For each conflict, please choose which Relationship to keep in
                the final merge.
              </ParagraphText>
            </div>
          )}
        </div>

        {(isSectionMergeable === 'RESOLVED' ||
          isSectionMergeable === 'CONFLICT') && (
          <Table size="small">
            <TableHeader headings={headings} />
            <TableBody>
              {aMergeOptions?.guardianships.map(g => {
                const fullName = g.person.fullName;
                const isAutoMerge = g.nameOptions.length < 2;
                const result = connectedLogins[g.person.id] || '—';

                return (
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="body1">{fullName}</Typography>
                    </TableCell>
                    {!isAutoMerge && (
                      <TableCell className={classes.tableCell}>
                        <FormControlLabel
                          className={classes.blockRadioLabel}
                          name={g.nameOptions[0]}
                          control={
                            <Radio
                              onChange={() => {
                                setConnectedLogins({
                                  ...connectedLogins,
                                  [g.person.id]: g.nameOptions[0],
                                });
                              }}
                              checked={
                                connectedLogins[g.person.id] ===
                                g.nameOptions[0]
                              }
                            />
                          }
                          label={g.nameOptions[0]}
                        />
                        <FormControlLabel
                          className={classes.blockRadioLabel}
                          name={g.nameOptions[1]}
                          control={
                            <Radio
                              onChange={() => {
                                setConnectedLogins({
                                  ...connectedLogins,
                                  [g.person.id]: g.nameOptions[1],
                                });
                              }}
                              checked={
                                connectedLogins[g.person.id] ===
                                g.nameOptions[1]
                              }
                            />
                          }
                          label={g.nameOptions[1]}
                        />
                      </TableCell>
                    )}

                    {g.nameOptions.length < 2 && (
                      <TableCell className={classes.tableCell}>
                        <Typography variant="body1">
                          {g.nameOptions[0] || '—'}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell>
                      <Typography variant="body1">{result}</Typography>
                    </TableCell>
                    <ResultIconCell
                      mergeState={getMergeState({
                        connectedLogins,
                        mergeOptionsGuardian: g,
                        isLoading: !mergeOptions.isLoaded(),
                      })}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </ExpandableSection>
  );
}
