import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useEffect, useMemo} from 'react';
import {useAsync, useUser} from '@src/hooks';
import {ProviderDetails} from '@src/models';
import {allLoaded} from '@src/util/apiHelpers';

type PageAuthorizationState = [
  AsyncData<boolean>,
  (v?: boolean | readonly boolean[] | Error) => void,
];

export const usePageAuthorizationState = (
  members: AsyncData<ProviderDetails>,
): PageAuthorizationState => {
  const [user] = useUser();

  const [
    userAuthorizedToAccessRoster,
    setUserAuthorizedToAccessRoster,
  ] = useAsync<boolean>();

  useEffect(() => {
    if (!allLoaded(members, user)) {
      return;
    }

    setUserAuthorizedToAccessRoster(
      members.value().some(m => {
        return user.singleValue().providerDetails.property('id') === m.id;
      }),
    );
  }, [members, setUserAuthorizedToAccessRoster, user]);

  return useMemo(() => {
    return [userAuthorizedToAccessRoster, setUserAuthorizedToAccessRoster];
  }, [userAuthorizedToAccessRoster, setUserAuthorizedToAccessRoster]);
};
