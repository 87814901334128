import React from 'react';
import {Grid} from '@material-ui/core';
import {PersonCard} from '../shared/PersonCard';
import {useStyles} from './styles';
import {ResponsiblePerson} from '@src/models';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {isNothing} from '@src/util/typeTests';

type Props = {
  financiallyResponsibleResult: ResponsiblePerson | null;
  financiallyResponsibleName?: string;
};

export function AutoMergeDisplay({
  financiallyResponsibleResult,
  financiallyResponsibleName,
}: Props) {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <ParagraphText className={classes.messageContainer}>
        The Financially Responsible Person can be merged automatically.
      </ParagraphText>
      <Grid item xs={3}>
        {!isNothing(financiallyResponsibleResult) && (
          <PersonCard
            person={financiallyResponsibleResult}
            relationshipResult={financiallyResponsibleName}
          />
        )}
      </Grid>
    </Grid>
  );
}
