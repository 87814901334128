import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {format} from 'date-fns';
import React, {useCallback} from 'react';
import {
  Buttons,
  DownloadCell,
  MessageTableCell,
  MobileVisitTable,
  VisitTableBody,
  VisitTableCell,
  VisitTableHeadCell,
  VisitTableRow,
} from './layout';
import {ReportLink} from './ReportLink';
import {getFlag, getSelectionStatus} from './functions';
import {AssignProfessional} from '@src/components/providerSide/VisitTable/VisitActions';
import {SelectionStatus} from '@src/components/providerSide/TriStateCheckbox';
import {Button} from '@src/components/ui/form';
import {VisitLink} from '@src/components/shared/VisitLink';
import {LonelyVisit} from '@src/models';
import getTotalDiagnosis from '@src/util/visits/getTotalDiagnosis';
import {visitIsSigned} from '@src/util/visits';

type Props = {
  visits: AsyncData<LonelyVisit>;
  callPoolId: number;
  selection: Record<string, boolean>;
  updateSelection: (selection: Record<string, boolean>) => unknown;
  onCancelVisit: (visitId: number) => unknown;
  onUpdateVisit: (visit: LonelyVisit) => unknown;
};

export const MobileTable: React.FunctionComponent<Props> = ({
  visits,
  selection,
  updateSelection,
  onUpdateVisit,
  callPoolId,
}) => {
  const getSelectAllToggler = useCallback(
    (isSelect: boolean) => () => {
      const newSelection = visits
        .filter(v => !!v.visitDocumentation)
        .mapValue(v => v.id)
        .reduce((sel, id) => {
          return {...sel, [id]: isSelect};
        }, selection);
      updateSelection(newSelection);
    },
    [selection, updateSelection, visits],
  );

  const selectionStatus = getSelectionStatus(visits, selection, callPoolId);

  return (
    <>
      {!visits.containsData() && (
        <MobileVisitTable>
          <VisitTableBody>
            <VisitTableRow>
              <MessageTableCell>Loading...</MessageTableCell>
            </VisitTableRow>
          </VisitTableBody>
        </MobileVisitTable>
      )}
      {visits.containsData() && visits.isEmpty() && (
        <MobileVisitTable>
          <VisitTableBody>
            <VisitTableRow>
              <MessageTableCell>No visits found</MessageTableCell>
            </VisitTableRow>
          </VisitTableBody>
        </MobileVisitTable>
      )}
      {visits.containsData() && (
        <>
          <Buttons>
            <Button
              bSize={'small'}
              block
              onClick={getSelectAllToggler(
                getSelectionStatus(visits, selection, callPoolId) ===
                  SelectionStatus.None,
              )}
            >
              {selectionStatus === SelectionStatus.None
                ? 'Add all'
                : 'Remove all'}
            </Button>
          </Buttons>
          {visits
            .mapValue(visit => (
              <MobileVisitTable key={visit.id} selected={selection[visit.id]}>
                <VisitTableBody>
                  <VisitTableRow>
                    <VisitTableHeadCell>DATE</VisitTableHeadCell>

                    <VisitTableCell>
                      {format(visit.start, 'PPpp')}
                    </VisitTableCell>
                  </VisitTableRow>
                  <VisitTableRow>
                    <VisitTableHeadCell>PATIENT</VisitTableHeadCell>
                    <VisitTableCell>
                      {visit.patient.preferredName}
                    </VisitTableCell>
                  </VisitTableRow>
                  <VisitTableRow>
                    <VisitTableHeadCell>PROVIDER</VisitTableHeadCell>
                    <VisitTableCell>
                      {visit.providerDetails.property(
                        'fullName',
                        'No Provider',
                      )}
                    </VisitTableCell>
                  </VisitTableRow>
                  <VisitTableRow>
                    <VisitTableHeadCell>GROUP</VisitTableHeadCell>
                    <VisitTableCell>
                      {visit.callPool.property('nameForProviders', '-')}
                    </VisitTableCell>
                  </VisitTableRow>
                  <VisitTableRow>
                    <VisitTableHeadCell>DIAGNOSIS</VisitTableHeadCell>
                    <VisitTableCell>{getTotalDiagnosis(visit)}</VisitTableCell>
                  </VisitTableRow>
                  <VisitTableRow>
                    <VisitTableHeadCell>PCP</VisitTableHeadCell>
                    <VisitTableCell>
                      {visit.patient.pcp
                        .map(p => `${p.firstName} ${p.lastName}`)
                        .orElse('No PCP given')}
                    </VisitTableCell>
                  </VisitTableRow>
                  <VisitTableRow>
                    <VisitTableHeadCell>NOTES</VisitTableHeadCell>
                    <VisitTableCell>
                      <VisitLink visit={visit} onUpdateVisit={onUpdateVisit} />
                    </VisitTableCell>
                  </VisitTableRow>
                  <VisitTableRow>
                    <VisitTableHeadCell>REPORT</VisitTableHeadCell>
                    <VisitTableCell>
                      <ReportLink visit={visit} />
                    </VisitTableCell>
                  </VisitTableRow>
                  <VisitTableRow>
                    <VisitTableHeadCell>ASSIGN</VisitTableHeadCell>
                    <VisitTableCell>
                      <AssignProfessional
                        visit={visit}
                        returnText="Return to Visits"
                        onUpdate={onUpdateVisit}
                      />
                    </VisitTableCell>
                  </VisitTableRow>
                  <VisitTableRow>
                    <VisitTableHeadCell>Red Flag?</VisitTableHeadCell>
                    <VisitTableCell> {getFlag(visit)}</VisitTableCell>
                  </VisitTableRow>
                  <VisitTableRow>
                    <VisitTableHeadCell>DOWNLOADED?</VisitTableHeadCell>
                    <VisitTableCell>
                      {visit.notesDownloaded ? (
                        <FontAwesomeIcon icon={'check'} />
                      ) : (
                        ''
                      )}
                    </VisitTableCell>
                  </VisitTableRow>
                  <VisitTableRow>
                    {visitIsSigned(visit) && (
                      <DownloadCell colSpan={2}>
                        {visit.visitDocumentation && (
                          <span
                            onClick={() =>
                              updateSelection({
                                ...selection,
                                [visit.id]: !selection[visit.id],
                              })
                            }
                          >
                            {selection[visit.id] ? 'Remove from' : 'Add to'}{' '}
                            download
                          </span>
                        )}
                      </DownloadCell>
                    )}
                  </VisitTableRow>
                </VisitTableBody>
              </MobileVisitTable>
            ))
            .map(x => (
              <>
                {x}
                <hr />
              </>
            ))}
        </>
      )}
    </>
  );
};
