import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import {addDays} from 'date-fns';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {FormStates} from '.';
import {DateInput} from '@src/components/ui/form';
import {ErrorMessage} from '@src/components/ui/form/ErrorMessage';
import {YesOrNo} from '@src/components/ui/form/YesOrNo';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {ProviderSelector} from '@src/components/shared/ProviderSelector';
import {Context} from '@src/util/provider/forms/addOrEdit';
import {
  StyledAsyncActionButton,
  StyledButton,
  StyledButtonRowContainer,
} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/layout';
import {createSCPWithOwner} from '@src/util/sharedCarePlan';
import {LonelyPatient, ProviderDetails, UserType} from '@src/models';
import {useApi} from '@src/api/useApi';
import {UserId} from '@src/models/User';

const DEFAULT_EXPIRATION_DATE = addDays(new Date(), 90);

type Props = {
  patient: Optional<LonelyPatient>;
  providerId: UserId;
  onCancel: () => void;
  setFormState: (s: FormStates | null) => void;

  userType: UserType;
};

const ButtonRowContainer = styled(StyledButtonRowContainer)`
  order: 1;
`;

export function OwnershipExpiration({
  patient,
  providerId,
  onCancel,
  setFormState,
  userType,
}: Props) {
  const api = useApi();
  const [showDatePicker, setShowDatePicker] = useState<boolean | null>(null);
  const [expirationDate, setExpirationDate] = useState<Optional<Date>>();

  const [creating, setCreating] = useState(false);
  const [errors, setErrors] = useState<{[field: string]: string}>({});

  const [nextDetails, setNextDetails] = useState<ProviderDetails | null>(null);
  const patientName = patient
    ?.map(p => p.preferredFirstName)
    .orElse('this patient');

  useEffect(() => {
    setErrors(oldErrors => ({
      ...oldErrors,
      createSCP: creating ? '' : oldErrors.createSCP,
      expirationDate: '',
    }));
  }, [creating]);

  const onSubmit = () => {
    const patientId = patient.map(p => p.id).orElse(0);

    return createSCPWithOwner(api)({
      patientId,
      providerId,
      setCreating,
      ownershipExpiration: expirationDate?.orNothing(),
      setErrors,
      errors,
      onCreated: () => {
        setFormState(FormStates.SCPCreatedStep);
      },
      nextDetails: nextDetails || undefined,
    });
  };

  return (
    <Grid container direction="row">
      <ParagraphText>
        As the plan’s creator, any edits that other providers or families make
        to this information will appear as an edit request that you will be
        responsible for approving or rejecting.
      </ParagraphText>
      <ParagraphText>
        This ensures that the shared care plan stays as accurate and updated as
        possible. Upon creation of the plan, you will be the plan's owner.
      </ParagraphText>
      <ParagraphText>
        If your relationship with {patientName} is such that your ownership
        should be temporary, you can select a date upon which your ownership
        will expire.
      </ParagraphText>

      <ParagraphText>
        When this date approaches, all parties will be notified to make sure
        another owner is designated for this plan.
      </ParagraphText>

      <ParagraphText style={{fontWeight: 700}}>
        Would you like to select an end date for your ownership?
      </ParagraphText>

      <Grid item xs={12}>
        <YesOrNo
          setValue={v => {
            setShowDatePicker(v);
            if (!v) {
              setExpirationDate(Optional.empty());
            } else {
              setExpirationDate(Optional.of(DEFAULT_EXPIRATION_DATE));
            }
          }}
          value={Optional.of(showDatePicker)}
          yesMessage="Yes"
          noMessage="No"
        />
      </Grid>

      {showDatePicker && (
        <>
          <ParagraphText style={{fontWeight: 700, marginTop: '2rem'}}>
            Please set the ownership expiration date for this Shared Care Plan.
          </ParagraphText>

          <DateInput
            error={
              !!errors.ownershipExpiration
                ? `${errors.ownershipExpiration}`
                : undefined
            }
            label="Ownership expiration"
            name="expiration"
            onChange={d => setExpirationDate(Optional.of(d))}
            value={expirationDate?.orNothing() || DEFAULT_EXPIRATION_DATE}
            minDate={new Date()}
          />

          <ParagraphText style={{fontWeight: 700, marginTop: '2rem'}}>
            Who do you think should assume ownership of this care plan after
            this date?
          </ParagraphText>
          <ProviderSelector
            providerDescription="Owning Provider"
            details={Optional.of(nextDetails)}
            onChange={i => {
              setNextDetails(i?.map(info => info).orNull());
            }}
            userType={userType}
            sharedCarePlanContextOptions={{
              setNoSCPOwner: () => {
                setNextDetails(null);
              },
            }}
            selectionOrEditContext={Context.SharedCarePlanOwner}
          />
        </>
      )}

      {errors.createSCP && <ErrorMessage message={errors.createSCP} />}
      {errors.expirationDate && (
        <ErrorMessage message={errors.expirationDate} />
      )}

      <ButtonRowContainer>
        <StyledButton bSize="small" bStyle="secondary" onClick={onCancel}>
          Cancel
        </StyledButton>
        <StyledAsyncActionButton
          disabled={
            (nextDetails === null && showDatePicker) || showDatePicker === null
          }
          actionInProgress={creating}
          actionWord={'Next'}
          bSize="small"
          bStyle="primary"
          onClick={onSubmit}
        >
          Next
        </StyledAsyncActionButton>
      </ButtonRowContainer>
    </Grid>
  );
}
