import React, {useCallback, useState} from 'react';
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {RemoveGroup} from '../RemoveGroupModal';
import {UnremovableGroupModal} from './UnremovableGroupModal';
import {usePageContext} from '@src/components/providerSide/providerGroups/ProviderGroupRosterPage';
import {useStyles} from '@src/components/ui/molecules/lists/ActionList/styles';
import {useStyles as useShiftsStyles} from '@src/components/providerSide/providerGroups/ProviderGroupRosterPage/MonthTable/Day/Shifts/styles';
import {CallPool, OnCallPeriod} from '@src/models';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {useIdToGroupMap} from '@src/hooks/providerGroups/maps/useIdToGroupMap';
import {HorizontalSection} from '@src/components/ui/atoms/section/HorizontalSection';
import {LightHeading} from '@src/components/ui/layout/text/heading/LightHeading';
import {primary} from '@src/components/ui/theme';
import {useIconStyles} from '@src/components/ui/atoms/buttonsAndIcons/styles';

type Props = {
  period: OnCallPeriod;
};

function ActionIcon({
  group,
  setUnremovableGroup,
  setGroupToRemove,
}: {
  group: CallPool;
  setUnremovableGroup: (v: CallPool | null) => unknown;
  setGroupToRemove: (v: CallPool | null) => unknown;
}) {
  const shiftsClasses = useShiftsStyles({});
  const iconStyles = useIconStyles();

  const {managedGroups} = usePageContext();
  const aManagedGroups = managedGroups.getAllOptional().orElse([]);
  const managedGroupsMap = useIdToGroupMap(aManagedGroups);

  const disabled = !managedGroupsMap[group.id];

  return (
    <IconButton className={iconStyles.clickableIcon}>
      {disabled ? (
        <FontAwesomeIcon
          icon={['fas', 'question']}
          size="sm"
          color={primary}
          className={iconStyles.clickableIcon}
          onClick={() => {
            setUnremovableGroup(group);
          }}
        />
      ) : (
        <FontAwesomeIcon
          icon={['fas', 'trash']}
          size="sm"
          className={clsx(iconStyles.clickableIcon, shiftsClasses.deleteIcon)}
          onClick={() => {
            setGroupToRemove(group);
          }}
        />
      )}
    </IconButton>
  );
}

export function CurrentGroups({period}: Props) {
  const classes = useStyles();

  const currentGroups = period.callPools;

  const [groupToRemove, setGroupToRemove] = useState<CallPool | null>(null);
  const [unremovableGroup, setUnremovableGroup] = useState<CallPool | null>(
    null,
  );

  const resetUnremovableGroup = useCallback(() => {
    setUnremovableGroup(null);
  }, [setUnremovableGroup]);

  const isLastGroup = currentGroups.length === 1;

  return (
    <HorizontalSection>
      <Grid item xs={12}>
        <LightHeading>Current</LightHeading>
      </Grid>
      <Grid item xs={12}>
        {currentGroups.length > 0 ? (
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table>
              <TableBody>
                {currentGroups.map(g => {
                  return (
                    <TableRow>
                      <TableCell>{g.nameForProviders}</TableCell>
                      {!isLastGroup && (
                        <TableCell>
                          <ActionIcon
                            group={g}
                            setUnremovableGroup={setUnremovableGroup}
                            setGroupToRemove={setGroupToRemove}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <ParagraphText>This shift currently has no groups.</ParagraphText>
        )}
      </Grid>
      {groupToRemove !== null && (
        <RemoveGroup
          groupToRemove={groupToRemove}
          period={period}
          setGroupToRemove={setGroupToRemove}
        />
      )}
      {unremovableGroup !== null && (
        <UnremovableGroupModal
          group={unremovableGroup}
          reset={resetUnremovableGroup}
        />
      )}
    </HorizontalSection>
  );
}
