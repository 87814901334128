import {
  Controller,
  Control,
  FieldError,
  DeepMap,
  FormState,
  FieldName,
  SetFieldValue,
} from 'react-hook-form';
import React from 'react';
import {TextInput, PhoneInput} from '@src/components/ui/form';
import {yObject, rqString, yString, rqBoolean} from '@src/schema/types';
import {MuiCheckBoxInput} from '@src/components/ui/form/MuiCheckBoxInput';
import {getRequiredPhoneValidator} from '@src/util/forms/validation';

export type ProfessionalBasics = {
  firstName: string;
  lastName: string;
  phone: string;
  seesPatients: boolean;
  qualification: string;
  speciality: string;
  email: string;
};

type Props = {
  control: Control<ProfessionalBasics>;
  errors: DeepMap<ProfessionalBasics, FieldError>;
  formState: FormState<ProfessionalBasics>;
  seesPatientsState: boolean;
  setValue: (
    name: FieldName<ProfessionalBasics>,
    value: SetFieldValue<ProfessionalBasics>,
  ) => void;
};

export const schema = yObject({
  firstName: rqString('Please tell us your first name'),
  lastName: rqString('Please tell us your last name'),
  qualification: yString.when('seesPatients', {
    is: true,
    then: rqString('Please provide us with your medical qualification'),
  }),
  speciality: yString,
  seesPatients: rqBoolean('Please confirm whether or not you treat patients'),
  phone: yString.when('seesPatients', {
    is: true,
    then: getRequiredPhoneValidator(),
  }),
});

export function ProviderInfoForm({
  control,
  errors,
  formState,
  seesPatientsState,
  setValue,
}: Props) {
  const {touched} = formState;

  return (
    <div>
      <Controller
        control={control}
        as={TextInput}
        name="firstName"
        title="First Name"
        required
        error={errors.firstName?.message}
        touched={touched.firstName}
      />
      <Controller
        control={control}
        as={TextInput}
        name="lastName"
        title="Last Name"
        required
        error={errors.lastName?.message}
        touched={touched.lastName}
      />
      <Controller
        control={control}
        as={MuiCheckBoxInput}
        onChange={(value: boolean) => {
          setValue('seesPatients', value);
        }}
        name="seesPatients"
        error={errors.seesPatients?.message}
        required
        title="I am certified to treat patients"
      />

      <div style={{display: seesPatientsState ? 'block' : 'none'}}>
        <Controller
          control={control}
          as={TextInput}
          name="qualification"
          title="Qualification"
          required={seesPatientsState}
          error={errors.qualification?.message}
          touched={touched.qualification}
        />
        <Controller
          control={control}
          as={TextInput}
          name="speciality"
          title="Speciality"
          error={errors.speciality?.message}
          touched={touched.speciality}
        />
        <Controller
          control={control}
          as={PhoneInput}
          name="phone"
          title="Phone Number"
          error={errors.phone?.message}
          touched={touched.phone}
        />
      </div>
    </div>
  );
}
