import classnames from 'classnames';
import React, {ReactElement} from 'react';
import AutoSuggest from '../AutoSuggest';
import {useSuggestionGetter} from './useSuggestionGetter';
import {useApi} from '@src/api/useApi';
import {LonelyPatient} from '@src/models';

type Props = {
  name: string;
  className?: string;
  instructions?: string;
  selectedUser: string;
  filterPatient?: (patient: LonelyPatient) => boolean;
  selectPatient: (patient: LonelyPatient | null) => void;
  renderPatient: (patient: LonelyPatient) => ReactElement;
  title?: string;
  placeholder?: string;
  required?: boolean;
  slimline?: boolean;
  disabled?: boolean;
  touched?: boolean;
  error?: string | boolean;
};

export function SearchByPatient({
  className,
  name,
  slimline,
  placeholder,
  touched,
  filterPatient = () => true,
  renderPatient,
  selectPatient,
  error,
  selectedUser = '',
  instructions = '',
  title = '',
  disabled = false,
  required = false,
}: Props) {
  const api = useApi();
  const [userSuggestions, getSuggestions] = useSuggestionGetter({
    api: api.patient(),
    filter: filterPatient,
  });

  return (
    <div className={classnames(['search-by-user', className, {disabled}])}>
      <AutoSuggest
        required={required}
        className="search-by-user--input"
        disabled={disabled}
        slimline={slimline}
        error={error}
        placeholder={placeholder || ''}
        getSuggestionKey={(user: LonelyPatient) => {
          if (typeof user === 'string') {
            return user;
          }
          if (user.id === -1) {
            return '-1';
          }
          return `${user.id}`;
        }}
        getSuggestions={getSuggestions}
        getSuggestionValue={(pt: LonelyPatient) => {
          if (typeof pt === 'string') {
            return pt;
          }

          // Allows to match either email or full name.
          return pt.fullName;
        }}
        instructions={instructions}
        name={name}
        onChange={selectPatient}
        renderSuggestion={renderPatient}
        suggestions={userSuggestions}
        title={title}
        touched={touched}
        value={selectedUser}
      />
    </div>
  );
}
