export const EXAM_CODES = [
  'environment',
  'general',
  'skin',
  'head',
  'eyes',
  'enmt',
  'neck',
  'cardiovascular',
  'respiratory',
  'gastrointestinal',
  'musculoskeletal',
  'neuro_psych',
] as const;

export type ExamCode = typeof EXAM_CODES[number];

export const SYSTEM_CODES = [
  'general',
  'skin',
  'heent',
  'genitourinary',
  'cardiovascular',
  'respiratory',
  'gastrointestinal',
  'musculoskeletal',
  'hematological',
  'neuro_psych',
] as const;

export type SystemCode = typeof SYSTEM_CODES[number];

type Code = SystemCode | ExamCode;

export const CodeNames: Record<Code, string> = {
  environment: 'Location',
  general: 'General',
  skin: 'Skin',
  head: 'Head',
  eyes: 'Eyes',
  enmt: 'Ear, Nose, Mouth and Throat',
  neck: 'Neck',
  heent: 'HEENT',
  genitourinary: 'Genitourinary',
  cardiovascular: 'Cardiovascular',
  respiratory: 'Respiratory',
  gastrointestinal: 'Gastrointestinal',
  musculoskeletal: 'Musculoskeletal',
  hematological: 'Hematological',
  neuro_psych: 'Neuro/Psych',
};

export const codeToLabel = (code: Code): string => CodeNames[code];
