import {Mergeable} from '../../ExpandableSection/isMergeable/isSectionMergeable';
import {RadioSelection} from '../../BasicDetailsComparer/Rows/utils/resultFunctions';

export function getMergeStateForRow({
  patientOnePreexistingValue,
  patientTwoPreexistingValue,
  result,
  radioSelection,
  isLoading,
}: {
  patientOnePreexistingValue: string;
  patientTwoPreexistingValue: string;
  result: string;
  radioSelection: RadioSelection;
  isLoading: boolean;
}): Mergeable {
  if (isLoading) {
    return 'CHECKING';
  }

  // Definitions:
  // Pre-existing data: data that is present in the database pre-merge.

  // Pass Cases
  //  1) Override: `radioSelection` is in "override" mode and a non-empty string has been set for `result`
  //  2) Radio Selection: `radioSelection` is not in "override" mode AND the preexisting strings do not match, requiring a `radioSelection` that is not null,
  //      which is a condition that has been satisfied.
  //  3) Auto Merge: preexisting values match. Before this function runs, patientOne's radio selection should be auto-selected as the default
  //      and `patientOnePreexistingValue` will be set to `result`.
  //      However, if both Patients have empty strings for their pre-existing values, then the `result` will have a length of 0.
  //      This is the one case where an empty `result` does not represent a "CONFLICT" merge state.

  const isOverrideMode = radioSelection === 'override';
  const emptyString = result.length === 0;
  const hasRadioSelection = radioSelection !== null;
  const preexistingValuesMatch =
    patientOnePreexistingValue === patientTwoPreexistingValue;
  const radioSelectionRequired = !preexistingValuesMatch && !isOverrideMode;

  // Pass Cases
  const isOverrideModeAndPasses = isOverrideMode && !emptyString;
  const radioSelectionRequiredAndPasses =
    radioSelectionRequired && !emptyString && hasRadioSelection;
  const isAutoMerge = !isOverrideMode && preexistingValuesMatch;

  const isPass =
    isOverrideModeAndPasses ?? radioSelectionRequiredAndPasses ?? isAutoMerge;

  return isPass ? 'PASS' : 'CONFLICT';
}
