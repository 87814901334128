import * as React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import * as yup from 'yup';
import {ImmunizationStatus, LonelyPatient} from '../../../../models';
import {SelectInput, TextInput} from '../../../ui/form';
import {FormSection} from './FormSection';
import {yString} from '@src/schema/types';

export const immunizationOptions = () => [
  {value: 'yes', label: 'Yes'},
  {value: 'not_sure', label: 'Unknown'},
  {value: 'no', label: 'No'},
];

export const maiSchema = {
  medications: yup
    .string()
    .when('$isSigning', (isSigning: boolean, s: yup.StringSchema) =>
      isSigning
        ? s.required("Details about the patient's medications must be provided")
        : s,
    ),

  allergies: yup
    .string()
    .when('$isSigning', (isSigning: boolean, s: yup.StringSchema) =>
      isSigning
        ? s.required("Details about the patient's allergies must be provided")
        : s,
    ),
  immunizations_up_to_date: yup
    .mixed<ImmunizationStatus>()
    .when(
      '$isSigning',
      (isSigning: boolean, s: yup.MixedSchema<ImmunizationStatus>) =>
        isSigning
          ? s.required(
              "Details about the patient's immunizations must be provided",
            )
          : s,
    ),
  immunization_details: yup.string().when(['immunizations_up_to_date'], {
    is: 'no',
    then: yup
      .string()
      .when('$isSigning', (isSigning: boolean, s: yup.StringSchema) =>
        isSigning
          ? s.required(
              'Please include an explanation for why immunizations are out of date',
            )
          : s,
      ),
    otherwise: yString,
  }),
};

export type MAIValues = {
  medications: string;
  allergies: string;
  immunizations_up_to_date: string;
  immunization_details: string;
};

export const defaultMAIValues = (): MAIValues => ({
  medications: '',
  allergies: '',
  immunization_details: '',
  immunizations_up_to_date: '',
});

export type MAISectionType = {
  isSigning: boolean;
  patient: LonelyPatient;
};

export const MAILabels = {
  medications: 'Medications',
  allergies: 'Allergies',
  immunizations_up_to_date: 'Immunization up to date?',
  immunization_details: 'Immunization Details',
};

export function MAISection({isSigning, patient}: MAISectionType) {
  const {errors, control, watch} = useFormContext<MAIValues>();
  const vaxUpToDate = watch('immunizations_up_to_date');
  return (
    <FormSection title="Medications, Allergies and Immunizations">
      <Controller
        control={control}
        name="medications"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            required
            autoExpand
            placeholder={`What medications are ${patient.preferredName} currently taking?`}
            title={MAILabels.medications}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            touched={isTouched}
            error={errors.medications?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="allergies"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            required
            autoExpand
            placeholder={`Is ${patient.preferredName} allergic to any food or medications?`}
            title={MAILabels.allergies}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            touched={isTouched}
            error={errors.allergies?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="immunizations_up_to_date"
        render={({onChange, value, name}, {isTouched}) => (
          <SelectInput
            required
            title={MAILabels.immunizations_up_to_date}
            options={immunizationOptions()}
            placeholder={`Are ${patient.preferredName}'s immunizations up to date?`}
            name={name}
            value={value}
            onChange={onChange}
            touched={isTouched}
            error={errors.immunizations_up_to_date?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="immunization_details"
        render={({onChange, value, name}, {isTouched}) => (
          <TextInput
            required={vaxUpToDate === 'no'}
            autoExpand
            placeholder="Any further details on immunizations?"
            title={MAILabels.immunization_details}
            name={name}
            value={value}
            onChange={onChange}
            touched={isTouched}
            error={errors.immunization_details?.message}
          />
        )}
      />
    </FormSection>
  );
}
