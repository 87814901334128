import {addBreadcrumb, captureException, Severity} from '@sentry/browser';
import React, {Component, ErrorInfo} from 'react';
import {APIEvents, ApplicationEvents} from '../../../events';
import {GlobalStyle} from '../../ui/theme/ResetCss';
import {Box, Content, SinglePageLayout} from '../../ui/layout/SinglePageLayout';
import {ParagraphText} from '../../ui/layout/text/body/ParagraphText';
import {MuiHeading} from '../../ui/layout/MuiHeading';

type Props = {};
type State = {
  hasError: boolean;
  errorEvent: CustomEvent | null;
};

type ErrorComponent = (props: {detail?: {}}) => JSX.Element;
const DefaultMessage = () => (
  <>
    <ParagraphText>
      Refyne Connected Care hit a problem that it couldn't recover from on its
      own.
    </ParagraphText>
    <ParagraphText>
      If you <a href="/">click here</a>, you'll be taken back to your Refyne
      Connected Care home page.
    </ParagraphText>
  </>
);

const messages: Record<string, ErrorComponent> = {
  StorageAccessError: () => (
    <>
      <ParagraphText>
        In order to keep your session safe, Refyne Connected Care needs to be
        able to store a tiny piece of data in your browser while you are using
        it.
      </ParagraphText>

      <ParagraphText>
        We don't track you with this data; it is cleared when you close your
        browser.
      </ParagraphText>
      <ParagraphText>
        Please enable Cookies or Data Storage for the site to continue.
      </ParagraphText>
    </>
  ),
};

export class ApplicationErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
    errorEvent: null,
  };

  static getDerivedStateFromError(error: Error) {
    console.warn('Error caught by boundary');
    console.warn(error);
    return {hasError: true};
  }

  setErrorState = (errorEvent: CustomEvent) => {
    console.error('errorEvent');
    console.error(errorEvent);
    this.setState({hasError: true, errorEvent});
  };

  componentDidMount() {
    window.addEventListener(
      APIEvents.ApiFailed,
      this.setErrorState as EventListener,
    );
    window.addEventListener(
      ApplicationEvents.StorageAccessError,
      this.setErrorState as EventListener,
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      APIEvents.ApiFailed,
      this.setErrorState as EventListener,
    );
    window.removeEventListener(
      ApplicationEvents.StorageAccessError,
      this.setErrorState as EventListener,
    );
    window.removeEventListener(
      ApplicationEvents.GetAccessTokenFailed,
      this.setErrorState as EventListener,
    );
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error caught at boundary');
    console.error({error, errorInfo});
    addBreadcrumb({
      category: 'error',
      message: 'Application Error',
      data: {
        errorInfo,
      },
      level: Severity.Info,
    });
    captureException(error);
  }

  render() {
    const {hasError, errorEvent} = this.state;
    const EC =
      messages[errorEvent !== null ? errorEvent.type : 'NONE'] ||
      DefaultMessage;
    if (hasError) {
      return (
        <>
          <GlobalStyle />
          <SinglePageLayout>
            <Box>
              <Content>
                <MuiHeading>Something went wrong</MuiHeading>
                <EC detail={errorEvent?.detail} />
                <ParagraphText>
                  If you need immediate assistance, please contact Refyne
                  Connected Care Support at{' '}
                  <a href="tel:+18778642378">+1-877-864-2378</a> and we'll help
                  any way we can.
                </ParagraphText>
              </Content>
            </Box>
          </SinglePageLayout>
        </>
      );
    }

    return this.props.children;
  }
}
