import {
  yObject,
  rqString,
  yString,
  rqBoolean,
  yArray,
  yNumber,
} from '@src/schema/types';
import {getRequiredMessage} from '@src/util/forms/getRequiredMessage';
import {
  getMatchingEmailsValidator,
  getRequiredPhoneValidator,
} from '@src/util/forms/validation';

export const schema = yObject({
  firstName: rqString(getRequiredMessage('a', 'first name')),
  lastName: rqString(getRequiredMessage('a', 'last name')),
  phone: yString.when('seesPatients', {
    is: true,
    then: getRequiredPhoneValidator(),
  }),
  seesPatients: rqBoolean(''),
  qualification: yString.when('seesPatients', {
    is: true,
    then: rqString(getRequiredMessage('a', 'qualification')),
  }),

  speciality: yString.when('seesPatients', {
    is: true,
    then: rqString(getRequiredMessage('a', 'speciality')),
  }),
  ...getMatchingEmailsValidator(),
  callPoolIds: yArray(yNumber),
});

export const defaultValues = {
  firstName: '',
  lastName: '',
  phone: '',
  seesPatients: false,
  qualification: '',
  speciality: '',
  email: '',
  confirmEmail: '',
  callPoolIds: [],
};
