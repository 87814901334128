import {intervalToDuration} from 'date-fns';
import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {useParams} from 'react-router';
import {Grid} from '@material-ui/core';
import {CancelSessionModal} from '../VideoChat/ExamRoomModals/CancelSessionModal';
import {flashWarn} from '../notifications/flash';
import {EndSessionModal} from './EndSessionModal';
import {useStyles} from './layout';
import {PrivatePage} from '@src/components/PrivatePage';
import {Button, AsyncActionButton} from '@src/components/ui/form';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {
  useReturnPath,
  useResources,
  useWindowEvent,
  useInterval,
  useUser,
} from '@src/hooks';
import {UserType, LonelyVisit, VisitAction} from '@src/models';
import {useApi} from '@src/api/useApi';
import {getCallPoolName} from '@src/util/callPools/getCallPoolName';
import {isIOS} from '@src/util/browserTools';
import {
  getEndpoint,
  getDisplayNameByType,
  getEndpointUrl,
} from '@src/util/visits/endpoints';
import {PageHeader} from '@src/components/ui/layout';
import {EndpointType} from '@src/models/Endpoint';

// eslint-disable-next-line import/no-unused-modules
export default function ExternalVisitPage() {
  const api = useApi();
  const classes = useStyles();
  const [, userType] = useUser();
  const params = useParams<{visitId: string}>();

  const visitId = Number(params.visitId);
  const [visit] = useResources<LonelyVisit>(() => api.visit(visitId).get(), [
    api,
    visitId,
  ]);
  const [externalWindow, setExternalWindow] = useState<WindowProxy | null>(
    null,
  );
  const [openingWindow, setOpeningWindow] = useState(false);
  const [modalToShow, setModalToShow] = useState<
    'none' | 'cancel' | 'complete'
  >('none');

  const [connectionStart, setConnectionState] = useState(new Date());
  const [timeDisplay, setTimeDisplay] = useState('00:00');

  const oVisit = visit.getOptional();
  const endpoint = oVisit.map(getEndpoint);
  const displayName = getDisplayNameByType(
    endpoint.property('type', EndpointType.Ahana),
  );

  const goBack = useReturnPath();

  const patientName = useMemo(
    () => oVisit.map(v => v.patient.preferredName).orElse('a patient'),
    [oVisit],
  );

  const visitLocation = useMemo(() => {
    const cpName = oVisit
      .map(v => v.callPool)
      .map(c => getCallPoolName(c, userType))
      .orNull();

    const endpointName = endpoint.property('name', '');

    if (cpName === null && endpointName === '') {
      return 'a Fort Peck location';
    } else if (cpName === null) {
      return endpointName;
    } else {
      return `${cpName} (${endpointName})`;
    }
  }, [endpoint, oVisit, userType]);

  const closeModal = useCallback(() => setModalToShow('none'), []);

  const cancelVisit = useCallback(
    (reason: string) =>
      api
        .visit(visitId)
        .cancel(reason)
        .then(closeModal)
        .then(goBack),
    [api, closeModal, goBack, visitId],
  );

  const postExternalVisitLog = useCallback(
    () => api.visit(visitId).updateExternalVisitLog(),
    [api, visitId],
  );

  const openWindow = useCallback(() => {
    setOpeningWindow(true);
    setConnectionState(new Date());
    api
      .visit(visitId)
      .act(VisitAction.BEGIN)
      .then(() =>
        setExternalWindow(
          window.open(
            'https://' + oVisit.map(getEndpointUrl).orElse('0.0.0.0'),
            '_blank',
          ),
        ),
      )
      .then(postExternalVisitLog)
      .catch(() => {
        flashWarn(
          `It looks like there is already a provider on this visit.
               Please ensure that only the attending physician is using this link and that the clinic is
               connecting via the telemedicine software`,
        );
      })
      .finally(() => setOpeningWindow(false));
  }, [api, postExternalVisitLog, oVisit, visitId]);

  const closeWindow = useCallback(() => {
    if (externalWindow !== null) {
      externalWindow.close();
      setExternalWindow(null);
      postExternalVisitLog();
    }
  }, [externalWindow, postExternalVisitLog]);

  /*
   * Make sure external window is closed if we leave the page
   */
  useWindowEvent(isIOS() ? 'pagehide' : 'beforeunload', closeWindow);
  useEffect(() => closeWindow, [closeWindow]);

  useEffect(() => {
    if (externalWindow !== null) {
      const intervalId = setInterval(postExternalVisitLog, 10_000);
      return () => clearInterval(intervalId);
    }

    return () => {};
  }, [externalWindow, postExternalVisitLog]);

  const pollWindowState = useCallback(() => {
    if (externalWindow !== null && externalWindow.closed) {
      setExternalWindow(null);
    } else {
      const duration = intervalToDuration({
        start: connectionStart,
        end: new Date(),
      });
      setTimeDisplay(
        `${String(duration.minutes).padStart(2, '0')}:${String(
          duration.seconds,
        ).padStart(2, '0')}`,
      );
    }
  }, [externalWindow, connectionStart]);

  useInterval(pollWindowState, 1000);

  return (
    <PrivatePage>
      <PageLoading
        active={!visit.isLoaded()}
        message="Loading visit information"
      >
        <div className={classes.root}>
          <PageHeader>{displayName} Visit</PageHeader>

          <ParagraphText>
            This visit will be conducted via the {displayName} telemedicine
            platform.
          </ParagraphText>
          {userType === UserType.Guardian && (
            <ParagraphText>
              A local nurse will guide you through the telemedicine visit at
              your healthcare location.
            </ParagraphText>
          )}
          {userType === UserType.Professional && (
            <>
              <ParagraphText>
                The visit is with {patientName} at {visitLocation}.
              </ParagraphText>
              <ParagraphText>
                The telemedicine cart can be reached by clicking the button
                below. This will open {displayName} in a new tab.
              </ParagraphText>
              <ParagraphText>
                It is important to keep this tab open during the whole visit so
                that you can get credit for the time spent in the visit. If you
                close this tab, the {displayName} tab will automatically close.
              </ParagraphText>
              <ParagraphText>
                When you have completed the visit, please click the "Complete"
                button below. You can cancel the visit by clicking "Cancel"
              </ParagraphText>
            </>
          )}

          <Grid
            container
            direction="column"
            alignItems="center"
            spacing={1}
            className={classes.buttonContainer}
          >
            {userType === UserType.Professional && (
              <Grid
                container
                item
                direction="column"
                alignItems="center"
                spacing={1}
              >
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  justify="center"
                  spacing={1}
                >
                  <Grid item xs={12} spacing={1}>
                    {externalWindow === null ? (
                      <AsyncActionButton
                        block
                        bStyle="primary"
                        actionInProgress={openingWindow}
                        actionWord={`Connect to ${displayName}`}
                        onClick={openWindow}
                      />
                    ) : (
                      <div className={classes.connected}>
                        Connected to {displayName}: {timeDisplay}
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  justify="center"
                  spacing={1}
                >
                  <Grid item xs={6}>
                    <Button block onClick={() => setModalToShow('complete')}>
                      Complete Visit
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button block onClick={() => setModalToShow('cancel')}>
                      Cancel Visit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              direction="row"
              justify="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <Button block onClick={goBack}>
                  Go Back
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <CancelSessionModal
            showModal={modalToShow === 'cancel'}
            returnToPatient={closeModal}
            onCancelVisit={cancelVisit}
            returnText="Don't cancel yet"
          />
          <EndSessionModal
            showModal={modalToShow === 'complete'}
            returnToPatient={closeModal}
            onEndVisit={() =>
              api
                .visit(visitId)
                .complete()
                .then(closeModal)
                .then(goBack)
            }
          />
        </div>
      </PageLoading>
    </PrivatePage>
  );
}
