import React from 'react';
import {MakeIndeterminate} from './MakeIndeterminate';
import {PageLoading, PageLoadingProps} from './PageLoading';

export function PageLoadingIndeterminate(
  props: PageLoadingProps & {customDelayMs?: number},
) {
  return (
    <>
      {MakeIndeterminate({
        ...props,
        loadingIndicator: <PageLoading {...props} active />,
        customDelayMs: props.customDelayMs,
      })}
    </>
  );
}
