import {Button, ButtonGroup, useTheme, useMediaQuery} from '@material-ui/core';
import * as React from 'react';
import {range} from '../../../util/numberManipulation/range';
import {InputProps} from './Input';

type Props = InputProps<number> & {
  onChange: (score: number) => void;
};

export const NPSInput = ({value, onChange}: Props) => {
  const theme = useTheme();

  const matchesSmall = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  return (
    <ButtonGroup
      orientation={matchesSmall ? 'horizontal' : 'vertical'}
      fullWidth={!matchesSmall ?? true}
      size="small"
      style={{marginBottom: '1rem'}}
    >
      {range(11).map(s => (
        <Button
          key={`option-${s}`}
          onClick={() => {
            onChange(s);
          }}
          color={value === s ? 'primary' : 'default'}
          variant={value === s ? 'contained' : 'outlined'}
        >
          {s}
        </Button>
      ))}
    </ButtonGroup>
  );
};
