import React from 'react';
import {Modal} from '../../VideoChat/ExamRoomModals';
import {PauseVisitModal} from './PauseVisitModal';

type Props = {
  modal: Modal;
  showModal: (m: Modal) => void;
  onLeave: () => void;
  onCancelPauseOrComplete: () => void;
};

export function ExamRoomModals({
  modal,
  showModal,
  onLeave,
  onCancelPauseOrComplete,
}: Props) {
  if (modal === 'nonProfessionalLeaveConfirmationModal') {
    return (
      <PauseVisitModal
        showModal={true}
        onReturnToRoom={() => {
          onCancelPauseOrComplete();
          showModal('none');
        }}
        onLeave={onLeave}
      />
    );
  }

  return null;
}
