import {Optional} from '@ahanapediatrics/ahana-fp';
import {Professional, User} from '@src/models';

/**
 *
 * Accepts any `User` and returns their qualification.
 *
 * If `User` is not a `Professional` or has no `qualification`, it returns an empty string.
 *
 * @param user
 */
export const getQualification = (user: User): string => {
  return Optional.of(user)
    .cast<Professional>(us => 'providerDetails' in us)
    .flatMap(us => us.providerDetails)
    .map(pd => pd.qualification)
    .orElse('');
};
