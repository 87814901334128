import React from 'react';
import {RouteComponentProps} from 'react-router';
import {ParagraphText} from '../../../../../ui/layout/text/body/ParagraphText';
import {DeadEndPage} from '../../../../../ui/layout/pages/deadEnd/DeadEndPage';
import {Bold} from '../../../../../ui/layout/text/decoration/Bold';

type Props = RouteComponentProps<{
  patientId: string;
}>;

// eslint-disable-next-line import/no-unused-modules
export default function NewPatientDeadEnd({match}: Props) {
  const patientId = +match.params.patientId;

  return (
    <DeadEndPage
      title="This patient is already in our system"
      buttonText="View Patient"
      buttonTarget={`/patient/${patientId}`}
      className="patient-already-exists"
      severity="info"
    >
      <ParagraphText>
        The email you entered is already associated with an existing patient
        record.
      </ParagraphText>
      <ParagraphText>
        To view this patient's information, please click{' '}
        <Bold>View Patient</Bold>.
      </ParagraphText>
    </DeadEndPage>
  );
}
