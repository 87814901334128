import styled from 'styled-components';
import {md, mobileMax, primary, primaryText, sm} from '../theme';

export const PageFooter = styled.div<{inExamRoom: boolean}>`
  background-color: ${primary};
  color: ${primaryText};
  display: ${props => (props.inExamRoom ? 'none' : 'block')};
  left: 0;
  padding: ${sm} ${md};
  width: 100%;

  @media (max-width: ${mobileMax}) {
    font-size: 0.75em;
  }
`;
