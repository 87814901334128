import React, {ReactNode} from 'react';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import clsx from 'clsx';
import {md, mdLg} from '@src/components/ui/theme';

const useStyles = makeStyles(() =>
  createStyles({
    lastSection: {marginTop: md, marginBottom: 0},
    margin: {marginTop: md, marginBottom: mdLg},
    equalTopBottomMargins: {marginTop: md, marginBottom: md},
  }),
);

/**
 *
 * Returns a basic horizontal container with top/bottom margin and top/bottom divider.
 * Uniformly breaks up page into rows.
 * To customize width/height, wrap it in another `Grid` element.
 */

export function HorizontalSection({
  children,
  lastSection = false,
  equalTopBottomMargins = false,
}: {
  children: ReactNode;
  lastSection?: boolean;
  equalTopBottomMargins?: boolean;
}) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      xs={12}
      className={clsx({
        [classes.margin]: !lastSection,
        [classes.lastSection]: lastSection,
        [classes.equalTopBottomMargins]: equalTopBottomMargins,
      })}
    >
      {children}
    </Grid>
  );
}
