import {Optional} from '@ahanapediatrics/ahana-fp';
import {StreetAddress} from '@src/components/ui/form/AddressInput';
import {ResponsiblePerson, ResponsiblePersonRequest} from '@src/models';

export type FinanciallyResponsiblePersonValues = {
  guardianId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dob: Date | null;
  relationship: string;
  address: StreetAddress;
};

export const valuesToResponsiblePerson = (
  values: FinanciallyResponsiblePersonValues,
): ResponsiblePersonRequest => {
  const {relationship, dob, ...rpr} = values;
  return {id: 0, ...rpr, dob: dob?.toISOString()};
};

export const responsiblePersonToValues = (
  rp: Optional<ResponsiblePerson>,
  relationship: string = '',
): FinanciallyResponsiblePersonValues => {
  const responsiblePerson = rp.orElse(ResponsiblePerson.default());
  return {
    firstName: responsiblePerson.firstName || '',
    lastName: responsiblePerson.lastName || '',
    address: responsiblePerson.address,
    phone: responsiblePerson.phone || '',
    dob: responsiblePerson.dob.orNull(),
    email: responsiblePerson.email.orElse(''),
    relationship,
    guardianId: responsiblePerson.guardianId || 0,
  };
};
