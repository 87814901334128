import styled from 'styled-components';
import {h2, lg, md, sm, xs, lgXl} from '../theme';

export const Section = styled.div`
  margin: ${lgXl} 0 ${lg} 0;
`;
export const SectionTitle = styled.div`
  font-size: ${h2};
  margin: ${sm} 0 ${xs} 0;
`;

export const DataPoint = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: ${md};

  & > div {
    margin-right: ${xs};
  }
`;

export const DetailsActions = styled.div`
  margin: ${sm} 0;
`;
