import {makeStyles, Theme, createStyles} from '@material-ui/core';
import {instruction, md, sm} from '@src/components/ui/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    day: {
      height: '100%',
    },
    dayGrid: {
      height: '100%',
    },
    noShifts: {
      height: '100%',
    },
    dayDate: {
      textAlign: 'center',
      fontWeight: 'bold',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    dayHeader: {
      padding: sm,
    },
    dayContent: {paddingBottom: sm, flexGrow: 1},
    periodsContainer: {
      minHeight: '75px',
    },
    periodCard: {
      fontWeight: 'bold',
      margin: sm,
    },
    periodCardHeader: {
      padding: sm,
    },
    periodCardContent: {
      padding: `0 ${md}`,
    },
    noProviderItem: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      fontSize: instruction,
    },
    providerItem: {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.primary.contrastText,
      fontSize: instruction,
    },
    shiftContent: {
      padding: sm,
    },
  }),
);

export const useShiftMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuIcon: {
      cursor: 'pointer',
    },
  }),
);
