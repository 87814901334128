import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {format} from 'date-fns';
import {BasicDetailsResult} from '../resultFunctions';

/**
 *
 * This file is for the various inputs (DateInput, SelectInput, TextInput) used for the Override
 * column
 */

export const handleTextInputChange = ({
  event,
  fieldName,
  radioSelection,
  result,
  setResult,
}: {
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  fieldName: string;
  radioSelection: string;
  setResult: (r: BasicDetailsResult) => unknown;
  result: BasicDetailsResult;
}) => {
  setResult({
    ...result,
    [fieldName]: {
      text: event.target.value,
      radioSelection,
    },
  });
};

export const handlePhoneInputChange = ({
  value,
  fieldName,
  radioSelection,
  result,
  setResult,
}: {
  value: string;
  fieldName: string;
  radioSelection: string;
  setResult: (r: BasicDetailsResult) => unknown;
  result: BasicDetailsResult;
}) => {
  setResult({
    ...result,
    [fieldName]: {
      text: value,
      radioSelection,
    },
  });
};

export const handleDateInputChange = ({
  fieldName,
  result,
  setResult,
  date,
}: {
  fieldName: string;
  setResult: (r: BasicDetailsResult) => unknown;
  result: BasicDetailsResult;
  date: MaterialUiPickersDate;
}) => {
  setResult({
    ...result,
    [fieldName]: {
      text:
        date instanceof Date && !isNaN(date?.getTime())
          ? format(date, 'MMMM d yyyy')
          : '',
      radioSelection: 'override',
    },
  });
};
