import {yupResolver} from '@hookform/resolvers';
import {Grid} from '@material-ui/core';
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useStyles} from '@src/components/separateAdminApp/CreateProviderGroupPage/styles';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {getRequiredMessage} from '@src/util/forms/getRequiredMessage';
import {useApi} from '@src/api/useApi';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {TextInput} from '@src/components/ui/form';

const schema = yup.object().shape({
  name: yup.string().required(getRequiredMessage('a', 'name for the endpoint')),
  nonProfessionalInstructions: yup.string(),
  professionalInstructions: yup.string(),
  url: yup.string(),
});

export function EndpointForm() {
  const classes = useStyles();
  const api = useApi();

  const [submitting, setSubmitting] = useState(false);

  const {control, errors, handleSubmit, reset} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      nonProfessionalInstructions: '',
      professionalInstructions: '',
      url: '',
    },
  });

  const formHandler = handleSubmit(data => {
    const {
      name,
      nonProfessionalInstructions,
      professionalInstructions,
      url,
    } = data;

    setSubmitting(true);

    return api
      .endpoint()
      .create({
        name,
        nonProfessionalInstructions,
        professionalInstructions,
        url,
      })
      .then(() => {
        reset();
        flashSuccess('Success');
      })
      .catch(() => {
        flashError('Something went wrong');
      })
      .finally(() => {
        setSubmitting(false);
      });
  });

  return (
    <>
      <Grid container direction="row">
        <Grid item>
          <Controller
            name="name"
            control={control}
            render={({onChange, onBlur, value, name}) => (
              <TextInput
                value={value}
                name={name}
                required
                placeholder="Name of the Endpoint"
                title="Endpoint Name"
                onChange={onChange}
                onBlur={onBlur}
                error={errors.name?.message}
                className={classes.inputField}
              />
            )}
          />

          <Controller
            name="nonProfessionalInstructions"
            control={control}
            render={({onChange, onBlur, value, name}) => (
              <TextInput
                value={value}
                name={name}
                required
                placeholder="Instructions viewable by Non-Professionals"
                title="Non-Professional Instructions"
                onChange={onChange}
                onBlur={onBlur}
                error={errors.nonProfessionalInstructions?.message}
                className={classes.inputField}
              />
            )}
          />

          <Controller
            name="professionalInstructions"
            control={control}
            render={({onChange, onBlur, value, name}) => (
              <TextInput
                value={value}
                name={name}
                required
                placeholder="Instructions viewable by Professionals"
                title="Professional Instructions"
                onChange={onChange}
                onBlur={onBlur}
                error={errors.professionalInstructions?.message}
                className={classes.inputField}
              />
            )}
          />

          <Controller
            name="url"
            control={control}
            render={({onChange, onBlur, value, name}) => (
              <TextInput
                value={value}
                name={name}
                placeholder="URL for the Endpoint"
                title="URL"
                onChange={onChange}
                onBlur={onBlur}
                error={errors.url?.message}
                className={classes.inputField}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid>
        <MuiAsyncActionButton
          bStyle="primary"
          bSize="normal"
          onClick={formHandler}
          actionInProgress={submitting}
          actionWord="Submit"
          disabled={submitting}
        >
          Submit
        </MuiAsyncActionButton>
      </Grid>
    </>
  );
}
