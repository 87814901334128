import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  rowContainer: {
    marginBottom: '1em',
    color: theme.palette.secondary.main,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
}));
