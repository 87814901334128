import React, {ReactNode} from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import {useStyles} from './layout';

export interface InfoTableItem {
  label: string;
  content: ReactNode;
}

type Props = {
  items: InfoTableItem[];
};

export function InfoTable({items}: Props) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {items.map((item, index) => (
            <TableRow
              key={item.label}
              className={index === items.length - 1 ? classes.lastRow : ''}
            >
              <TableCell align="left" className={classes.labelCell}>
                {item.label}
              </TableCell>
              <TableCell align="right" className={classes.contentCell}>
                {item.content}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
