import React, {ReactNode} from 'react';
import {MuiHeading} from '@src/components/ui/layout/MuiHeading';

type Props = {
  children: ReactNode;
};
export function SCPHeading({children}: Props) {
  return (
    <MuiHeading style={{marginBottom: '0.5rem'}} color="primary">
      {children}
    </MuiHeading>
  );
}
