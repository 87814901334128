import React, {useEffect, useCallback, useState} from 'react';
import _ from 'lodash';
import {FormControl, Select, MenuItem, InputLabel} from '@material-ui/core';
import {useStyles} from '../util/styles';
import {handleChange} from './uploadFunctions';
import {LonelyVisit} from '@src/models';
import {
  getDate,
  getStartTime,
  getVisitProviderDescription,
} from '@src/util/visits/getVisitDescriptions';

export function VisitSelector({visits}: {visits: readonly LonelyVisit[]}) {
  const classes = useStyles();

  const [visitIdForUpload, setVisitIdForUpload] = useState<number>(0);

  const handleSelectorChange = useCallback(
    handleChange({setVisitIdForUpload}),
    [setVisitIdForUpload],
  );

  useEffect(() => {
    const mostRecentVisit = visits[0];
    setVisitIdForUpload(mostRecentVisit.id);
  }, []);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Select Visit For Upload:</InputLabel>
      <Select value={visitIdForUpload} onChange={handleSelectorChange}>
        {visits.map(v => {
          return (
            <MenuItem value={v.id} key={v.id}>
              {getDate(v.start)} at {getStartTime(v.start)} -{' '}
              {getVisitProviderDescription(v)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
