import React, {useCallback, useState} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {getSelectableIds} from './functions';
import {
  DesktopVisitTable,
  MessageTableCell,
  VisitTableBody,
  VisitTableHeadCell,
  VisitTableHeader,
  VisitTableRow,
} from './layout';
import {MobileTable} from './MobileTable';
import {LonelyVisit} from '@src/models';
import {TriStateCheckbox} from '@src/components/providerSide/TriStateCheckbox';
import {VisitRow} from '@src/components/providerSide/PastVisitsDashboard/VisitRow';
import {visitIsSigned} from '@src/util/visits';

interface PastVisitsTableProps {
  callPoolId?: number;
  isSelectable?: boolean;
  getVisitToggler: (id: number) => (selected: boolean) => () => void;
  mode: 'desktop' | 'mobile';
  selectedIds: number[];
  visits: AsyncData<LonelyVisit>;
  updateVisit: (v: LonelyVisit) => void;
  updateVisits: (v: LonelyVisit[]) => void;
}

export const PastVisitsTable = (props: PastVisitsTableProps) => {
  const {
    callPoolId = 0,
    getVisitToggler,
    mode,
    selectedIds,
    visits,
    updateVisit,
    updateVisits,
  } = props;

  // If this id is 0, show provider's patient notes.
  // Otherwise show notes for the selected call pool.
  const [selection, setSelection] = useState<Record<string, boolean>>({});

  const cancelVisit = (visitId: number) => {
    if (!visits.isLoaded()) {
      return updateVisits(visits.value().filter(v => v.id !== visitId));
    }
  };

  const multiSelect = useCallback(
    (isSelect: boolean) => {
      setSelection(
        visits
          .filter(visitIsSigned)
          .filter(v => {
            if (callPoolId > 0) {
              return callPoolId === v.callPoolId;
            } else {
              return true;
            }
          })
          .mapValue(v => v.id)
          .reduce((stateSpec, id) => ({...stateSpec, [id]: isSelect}), {}),
      );
    },
    [callPoolId, visits],
  );

  return (
    <>
      {mode === 'desktop' ? (
        <DesktopVisitTable>
          <VisitTableHeader>
            <VisitTableRow>
              {props.isSelectable && (
                <VisitTableHeadCell center>
                  <TriStateCheckbox
                    possible={getSelectableIds(visits, callPoolId)}
                    selection={selectedIds}
                    onClick={multiSelect}
                  />
                </VisitTableHeadCell>
              )}
              <VisitTableHeadCell />
              <VisitTableHeadCell>Date</VisitTableHeadCell>
              <VisitTableHeadCell>Patient</VisitTableHeadCell>
              <VisitTableHeadCell>Provider</VisitTableHeadCell>
              <VisitTableHeadCell>Group</VisitTableHeadCell>
              <VisitTableHeadCell>Diagnosis</VisitTableHeadCell>
              <VisitTableHeadCell>PCP</VisitTableHeadCell>
              <VisitTableHeadCell>Visit Notes</VisitTableHeadCell>
              <VisitTableHeadCell>Report</VisitTableHeadCell>
              <VisitTableHeadCell center>Assign</VisitTableHeadCell>
              <VisitTableHeadCell center>Downloaded?</VisitTableHeadCell>
            </VisitTableRow>
          </VisitTableHeader>
          <VisitTableBody>
            {visits.containsData() &&
              !visits.isEmpty() &&
              visits.mapValue(visit => {
                if (callPoolId > 0) {
                  if (visit.callPoolId !== callPoolId) {
                    return null;
                  }
                }

                return (
                  <VisitRow
                    key={visit.id}
                    isSelected={selection[visit.id]}
                    isSelectable={props.isSelectable}
                    onCancelVisit={cancelVisit}
                    onUpdateVisit={updateVisit}
                    visit={visit}
                    visitToggler={getVisitToggler(visit.id)}
                  />
                );
              })}
            {visits.containsData() && visits.isEmpty() && (
              <VisitTableRow>
                <MessageTableCell colSpan={9}>No visits found</MessageTableCell>
              </VisitTableRow>
            )}
            {!visits.containsData() && (
              <VisitTableRow>
                <MessageTableCell colSpan={9}>Loading...</MessageTableCell>
              </VisitTableRow>
            )}
          </VisitTableBody>
        </DesktopVisitTable>
      ) : (
        <MobileTable
          visits={visits}
          selection={selection}
          onCancelVisit={cancelVisit}
          onUpdateVisit={updateVisit}
          updateSelection={setSelection}
          callPoolId={callPoolId}
        />
      )}
    </>
  );
};
