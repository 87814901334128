import React, {useState} from 'react';
import {Controls} from '../../VideoChat/RoomControls/Controls';
import {Modals} from './Modals';
import {getControls} from './functions/getControls';
import {AnonymousRoomControlProps} from './functions/types';

export const AnonymousRoomControls = ({
  completeVisit,
  pauseVisitForCurrentUser,
  onReconnect,
  showButtonText,
  visitId,
}: AnonymousRoomControlProps) => {
  const [showShareModal, setShowShareModal] = useState(false);

  const controls = getControls({
    completeVisit,
    onReconnect,
    pauseVisitForCurrentUser,
    setShowShareModal,
    showShareModal,
    visitId,
  });

  return (
    <>
      <Controls controls={controls} showButtonText={showButtonText} />

      <Modals
        visitId={visitId}
        showShareModal={showShareModal}
        setShowShareModal={setShowShareModal}
      />
    </>
  );
};
