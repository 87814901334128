import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {UserInfoFooter} from '@src/components/shared/UserInfoFooter';
import {FOOTER} from '@src/components/apps/AppBox';
import {Logo} from '@src/components/shared/navigation/Logo';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    maxWidth: '100vw',
    gridArea: FOOTER,
    overflow: 'hidden',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(3, 2),
  },

  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  copyright: {
    marginTop: theme.spacing(1),
    fontSize: 14,
  },
}));

export function MuiFooter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <footer className={classes.footer}>
        <Container className={classes.flexContainer}>
          <Logo />

          <Typography className={classes.copyright}>
            © 2017 - {new Date().getFullYear()} The White Stone Group, Inc.
          </Typography>
        </Container>
      </footer>
      <UserInfoFooter />
    </div>
  );
}
