import {Optional} from '@ahanapediatrics/ahana-fp';

/**
 * Simple function to map an array of Optional objects to the value of
 * a specified property.
 * @param array Array The array of Optional objects.
 * @param prop string The prop whose values we are mapping.
 * @returns {object}
 */
export function mapObjectsToPropertyValues<T, K extends keyof T>(
  array: Optional<T>[],
  prop: K,
): (T[K] | undefined)[] {
  return array.map((mapObj: Optional<T>) => {
    return mapObj.property(prop);
  });
}
