import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';
import Add from '@material-ui/icons/Add';
import {Grid, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {LastUpdated} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/LastUpdated';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/styles';
import {ContactCard} from '@src/components/shared/PatientDashboard/SCP/panels/Contacts/ContactCard';
import {GuardianInfoModal} from '@src/components/guardianSide/GuardianInfoPage/GuardianInfoModal';
import {
  Contact,
  LonelyPatient,
  SharedCarePlan,
  Patient,
  User,
  PatientRelationship,
} from '@src/models';
import {useApi} from '@src/api/useApi';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {SCPHeading} from '@src/components/shared/PatientDashboard/SCP/SCPHeading';
import {UserId} from '@src/models/User';
import {ContactModal} from '@src/components/shared/PatientDashboard/ContactModal';
import {identifyLastUpdater} from '@src/components/shared/PatientDashboard/identifyLastUpdater';

type Props = {
  onUpdateScp: (scp: SharedCarePlan) => void;
  patient: AsyncData<LonelyPatient>;
  canEdit?: boolean;
  updatePatient: (p: Patient) => void;
  user: AsyncData<User>;
  instructions?: string;
  guardians: AsyncData<PatientRelationship>;
};

export const ContactInfo = ({
  instructions,
  onUpdateScp,
  patient,
  canEdit = false,
  updatePatient,
  user,
  guardians,
}: Props) => {
  const api = useApi();
  const classes = useStyles();

  const oPatient = patient.getOptional();
  const loading = !oPatient.isPresent() || !guardians.isLoaded();

  const [contactToEdit, setContactToEdit] = useState(Optional.empty<Contact>());
  const [showModal, setShowModal] = useState(false);
  const [showSelfModal, setShowSelfModal] = useState(false);

  const userId = user
    .getOptional()
    .map(u => u.id)
    .orElse(0 as UserId);

  const {scp} = usePageContext();
  const oScp = scp.getOptional();

  const updated = identifyLastUpdater(
    oPatient,
    oScp
      .map(m => m.contacts)
      .map(cs =>
        cs.map(c => ({
          updatedAt: Optional.of(c.updatedAt),
          lastUpdatedBy: c.lastUpdatedBy,
          qualification: Optional.empty<string>(),
        })),
      ),
  );

  return (
    <Grid
      container
      direction="row"
      style={{
        marginBottom: 0,
      }}
      className={classes.rowContainer}
    >
      <Grid item>
        <SCPHeading>Personal Emergency Contacts</SCPHeading>
      </Grid>
      {canEdit && (
        <Grid item>
          <Add
            onClick={() => {
              setContactToEdit(Optional.empty());
              setShowModal(true);
            }}
            className={classes.icon}
          />
        </Grid>
      )}
      {instructions && (
        <Grid item xs={12} className={classes.gridItem}>
          <Typography variant="caption" style={{fontStyle: 'italic'}}>
            {instructions}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} className={classes.gridItem}>
        <LastUpdated updated={updated} />
      </Grid>
      <PageLoading active={loading} message="Loading contacts...">
        <Grid item xs={12} md={6}>
          {guardians
            .getOptional()
            .map(g => (
              <React.Fragment key={g.id}>
                <ContactCard
                  contact={g.person}
                  canEdit={!canEdit}
                  relationship={g.relationship ?? ''}
                  onEdit={() => {
                    setShowSelfModal(true);
                  }}
                />

                {g.id === userId && (
                  <GuardianInfoModal
                    show={showSelfModal}
                    responsiblePerson={g.person}
                    onHide={() => setShowSelfModal(false)}
                    onClose={() => setShowSelfModal(false)}
                    onChangeResponsiblePerson={() =>
                      api
                        .patient(patient.singleValue().id)
                        .get()
                        .then(updatePatient)
                    }
                  />
                )}
              </React.Fragment>
            ))
            .orNull()}
          {oScp
            .map(m => m.contacts)
            .orElse([])
            .map((c: Contact) => (
              <ContactCard
                key={c.id}
                contact={c}
                canEdit={canEdit}
                onEdit={() => {
                  setContactToEdit(Optional.of(c));
                  setShowModal(true);
                }}
              />
            ))}
        </Grid>
        {showModal && (
          <ContactModal
            contact={contactToEdit}
            scp={oScp}
            show={true}
            onHide={() => setShowModal(false)}
            onUpdate={contact => {
              const updatedScp = oScp.get();
              const {contacts} = updatedScp;
              const contactIdx = contacts.findIndex(c => c.id === contact.id);
              if (contactIdx < 0) {
                updatedScp.contacts = [...contacts, contact];
              } else {
                updatedScp.contacts = Object.assign([...contacts], {
                  [contactIdx]: contact,
                });
              }
              onUpdateScp(updatedScp);
              setShowModal(false);
            }}
            onDelete={deletedId => {
              const updatedScp = oScp.get();
              const {contacts} = updatedScp;
              updatedScp.contacts = contacts.filter(c => c.id !== deletedId);
              onUpdateScp(updatedScp);
              setShowModal(false);
            }}
          />
        )}
      </PageLoading>
    </Grid>
  );
};
