import React from 'react';
import styled from 'styled-components';
import {xxl} from '../ui/theme';
import {PageTitle} from '../ui/layout';
import {
  PageTitleContainer,
  SinglePageContainer,
  Message,
} from './CreateProvider/layout';

const StyledPageTitleContainer = styled(PageTitleContainer)`
  margin-top: ${xxl};
`;

// eslint-disable-next-line import/no-unused-modules
export default function PageNotFound() {
  return (
    <SinglePageContainer>
      <StyledPageTitleContainer>
        <PageTitle>Refyne Connected Care Admin Portal</PageTitle>
      </StyledPageTitleContainer>
      <Message>Oops, page not found.</Message>
    </SinglePageContainer>
  );
}
