import React, {MouseEventHandler} from 'react';
import styled from 'styled-components';
import {ClickableIcon} from '../ui/layout/ClickableIcon';
import {h3, lg, primary, secondary, xs, primaryText} from '../ui/theme';

export const DashCard = styled.div`
  background-color: #fff;
`;

export const DashCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: ${lg};
  color: ${primary};

  div {
    font-size: ${h3};
  }
`;

export const ActionCell = styled.td`
  color: ${secondary};
  font-weight: bold;
`;

export const ActionsView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  min-height: ${lg};

  background-color: ${primary};
  color: ${primaryText};
  padding: 0 ${xs};

  div {
    padding: 0 ${xs};
  }
`;

const ViewButtonStyled = styled.td`
  color: ${primary};
  cursor: pointer;
  font-size: 1.5em;
  width: ${lg};
`;

export const ViewButton = ({onClick}: {onClick: MouseEventHandler}) => (
  <ViewButtonStyled>
    <ClickableIcon icon={'eye'} onClick={onClick} />
  </ViewButtonStyled>
);
