import {useCallback, useMemo, useState} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useAllGroups} from '../useAllGroups';
import {
  EditClientConfigurationsModal,
  useEditClientConfigurationsModal,
} from './clientConfigurations/useEditClientConfigurationsModal';
import {
  EditDocumentsModal,
  useEditDocumentsModal,
} from './documents/useEditDocumentsModal';
import {CallPool, Endpoint} from '@src/models';
import {Resources} from '@src/hooks';
import {useEndpoints} from '@src/hooks/endpoints/useAllEndpoints';

export type ListCallPoolsResources = {
  callPools: AsyncData<CallPool>;
  reloadCallPools: () => Promise<unknown>;
  showAddProfessional: number | null;
  showEditEndpoints: number | null;
  setShowEditEndpoints: (callPoolId: number | null) => unknown;
  setShowAddProfessional: (callPoolId: number | null) => unknown;
  hideEndpointsModal: () => unknown;
  endpointsResources: Resources<Endpoint>;
  hideAddProfessionalModal: () => unknown;
  editDocumentsModalResources: EditDocumentsModal;
  clientConfigurationsResources: EditClientConfigurationsModal;
};

export function useListCallPoolsResources() {
  const [callPools, reloadCallPools] = useAllGroups({
    includeVisitsUnsupported: true,
    includeUnapproved: true,
  });

  const endpointsResources = useEndpoints();

  const [showAddProfessional, setShowAddProfessional] = useState<number | null>(
    null,
  );
  const [showEditEndpoints, setShowEditEndpoints] = useState<number | null>(
    null,
  );

  const editDocumentsModalResources = useEditDocumentsModal();
  const clientConfigurationsResources = useEditClientConfigurationsModal(
    reloadCallPools,
  );

  const hideEndpointsModal = useCallback(() => {
    setShowEditEndpoints(null);
  }, [setShowEditEndpoints]);

  const hideAddProfessionalModal = useCallback(() => {
    setShowAddProfessional(null);
  }, [setShowAddProfessional]);

  return useMemo(
    () => ({
      hideAddProfessionalModal,
      callPools,
      reloadCallPools,
      showAddProfessional,
      setShowAddProfessional,
      showEditEndpoints,
      setShowEditEndpoints,
      hideEndpointsModal,
      endpointsResources,
      editDocumentsModalResources,
      clientConfigurationsResources,
    }),
    [
      callPools,
      editDocumentsModalResources,
      endpointsResources,
      hideAddProfessionalModal,
      hideEndpointsModal,
      reloadCallPools,
      showAddProfessional,
      showEditEndpoints,
      clientConfigurationsResources,
    ],
  );
}
