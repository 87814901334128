import {Grid} from '@material-ui/core';
import React from 'react';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {
  StyledButton,
  StyledButtonRowContainer,
} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/layout';
import {FormStates} from '@src/components/shared/PatientDashboard/SCP/CreateSCP/index';

type Props = {
  patientName: string;
  formState: FormStates;
  setFormState: (s: FormStates | null) => void;
  onCancel: () => void;
};

export const GuardianMessage = ({
  patientName,
  setFormState,
  onCancel,
}: Props) => {
  return (
    <Grid container direction="row">
      <ParagraphText>
        You are about to create a new shared care plan for {patientName}. You
        will need to choose a provider to manage it.
      </ParagraphText>

      <ParagraphText>
        The provider is responsible for making sure all the information in your
        plan is up-to-date. They will receive all of the important edits
        pertaining to this patient's medical information. Please proceed to the
        next step to select an owner for {patientName}'s care plan.
      </ParagraphText>
      <StyledButtonRowContainer>
        <StyledButton bSize="small" bStyle="secondary" onClick={onCancel}>
          Cancel
        </StyledButton>
        <StyledButton
          bSize="small"
          bStyle="primary"
          onClick={() => {
            setFormState(FormStates.OwnershipSelectionStep);
          }}
        >
          Next: select owner
        </StyledButton>
      </StyledButtonRowContainer>
    </Grid>
  );
};
