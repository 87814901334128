import {format} from 'date-fns';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import styled from 'styled-components';
import {Grid} from '@material-ui/core';
import {useApi} from '@src/api/useApi';
import {PrivatePage} from '@src/components/PrivatePage';
import {PatientOverview} from '@src/components/shared/PatientOverview';
import {SearchByPatient} from '@src/components/ui/form/search/SearchByPatient';
import {PageHeader} from '@src/components/ui/layout/PageHeader';
import {Button} from '@src/components/ui/form';
import {LonelyPatient} from '@src/models';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

// eslint-disable-next-line import/no-unused-modules
export default function PatientSearchPage() {
  const api = useApi();
  const history = useHistory();
  const [patient, setPatient] = useState<LonelyPatient>();
  const {id} = useParams();

  const patientId = id ? +id : undefined;

  useEffect(() => {
    if (patientId) {
      api
        .patient(patientId)
        .get()
        .then(setPatient);
    } else {
      setPatient(undefined);
    }
  }, [api, patientId]);

  return (
    <PrivatePage>
      <Header>
        <PageHeader>Search for a patient</PageHeader>
        <Button
          onClick={() => {
            history.push('/new-patient');
          }}
          bStyle="contained"
        >
          Add new patient
        </Button>
      </Header>
      <Grid container direction="row">
        <Grid item xs={8}>
          {patient ? (
            <Button
              onClick={() => {
                history.replace(`/patient-search`);
              }}
              bStyle="contained"
            >
              Search again
            </Button>
          ) : (
            <SearchByPatient
              name="patient"
              instructions="Start typing to search for a patient"
              selectedUser={''}
              selectPatient={u => {
                if (u === null) {
                  return;
                }
                history.replace(`/patient-search/${u.id}`);
              }}
              renderPatient={u => (
                <div>
                  {u.firstName} {u.lastName} - {format(u.dob, 'P')}
                </div>
              )}
            />
          )}
        </Grid>
      </Grid>

      <div>
        {patient && (
          <PatientOverview
            key={patient.id}
            patient={patient}
            updatePatient={setPatient}
          />
        )}
      </div>
    </PrivatePage>
  );
}
