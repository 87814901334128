import {format} from 'date-fns';
import React, {useState, useCallback} from 'react';
import {useHistory} from 'react-router';
import {Modal} from '@src/components/ui/layout/Modal';
import {Button} from '@src/components/ui/form';
import {SearchByPatient} from '@src/components/ui/form/search/SearchByPatient';
import {LonelyPatient} from '@src/models';

type Props = {
  currentPatientId: number;
  onClose: () => unknown;
  show: boolean;
};

// eslint-disable-next-line import/no-unused-modules
export default function SelectMergePatientModal({
  currentPatientId,
  onClose,
  show,
}: Props) {
  const history = useHistory();
  const [chosenPatientId, setChosenPatientId] = useState(0);
  const isNotCurrentPatient = useCallback(p => p.id !== currentPatientId, [
    currentPatientId,
  ]);

  const closeModal = useCallback(() => {
    setChosenPatientId(0);
    onClose();
  }, [onClose]);
  return (
    <Modal
      show={show}
      title="Please choose the patient that you wish to merge into this one"
      onClose={closeModal}
      modalActions={
        <>
          <Button
            disabled={chosenPatientId === 0}
            bStyle="contained"
            bSize="small"
            onClick={() => {
              history.push(
                `/merge-patients/${currentPatientId}/${chosenPatientId}`,
              );
            }}
          >
            Proceed
          </Button>
          <Button bStyle="contained" bSize="small" onClick={closeModal}>
            Cancel
          </Button>
        </>
      }
    >
      <div>
        <SearchByPatient
          name="patient"
          instructions="Start typing to search for a patient"
          selectedUser={''}
          selectPatient={(u: LonelyPatient | null) => {
            setChosenPatientId(u?.id ?? 0);
          }}
          filterPatient={isNotCurrentPatient}
          renderPatient={(u: LonelyPatient) => (
            <div>
              {u.firstName} {u.lastName} - {format(u.dob, 'P')}
            </div>
          )}
        />
      </div>
    </Modal>
  );
}
