import React from 'react';
import {Button} from '../../ui/form';
import {Modal} from '../../ui/layout/Modal';

type Props = {
  errorFieldLabels: string[];
  onCancel: () => void;
  onConfirm: () => void;
  onHide: () => void;
  show: boolean;
};

export default function SkipValidationModal({
  errorFieldLabels,
  onCancel,
  onConfirm,
  onHide,
  show,
}: Props) {
  return (
    <Modal
      title="Missing Required Fields"
      show={show}
      modalActions={
        <>
          <Button
            bStyle={'primary'}
            onClick={() => {
              onHide();
              onCancel();
            }}
            isInRow={true}
          >
            Continue Editing
          </Button>
          <Button
            onClick={() => {
              onHide();
              onConfirm();
            }}
          >
            Sign Anyway
          </Button>
        </>
      }
    >
      <p style={{marginBottom: '1.5em'}}>
        There are{' '}
        <span style={{color: 'red', fontWeight: 'bold'}}>required</span> fields
        that are blank in this documentation. Are you sure you want to submit
        this documentation with these empty fields?
      </p>
      <ul>
        {errorFieldLabels.map(label => (
          <li key={label}>{label}</li>
        ))}
      </ul>
    </Modal>
  );
}
