import {Optional} from '@ahanapediatrics/ahana-fp';
import {Mergeable} from '.';
import {Patient} from '@src/models';

export const isCareTeamsSectionMergeable = ({
  p1,
  p2,
}: {
  p1: Optional<Patient>;
  p2: Optional<Patient>;
}): Mergeable => {
  const p1Length = p1.map(p => p.careTeam).orElse([]).length;
  const p2Length = p2.map(p => p.careTeam).orElse([]).length;

  if (p1Length > 0 || p2Length > 0) {
    return 'FAIL';
  } else {
    return 'PASS';
  }
};
