import React, {useCallback} from 'react';
import {Grid} from '@material-ui/core';
import {AvailableGroups} from './AvailableGroups';
import {CurrentGroups} from './CurrentGroups';
import {Modal} from '@src/components/ui/layout/Modal';
import {OnCallPeriod} from '@src/models';
import {Button} from '@src/components/ui/form';

type Props = {
  show: boolean;
  onCancel: () => void;
  onHide: () => void;
  period: OnCallPeriod;
};

export function GroupsModal({period, show, onHide, onCancel}: Props) {
  const reset = useCallback(() => {
    onHide();
    onCancel();
  }, [onHide, onCancel]);

  return (
    <Modal
      show={show}
      title={'Edit Groups'}
      onClose={reset}
      modalActions={
        <Button bStyle="outlined" onClick={reset}>
          Cancel
        </Button>
      }
    >
      <Grid>
        <CurrentGroups period={period} />
        <AvailableGroups period={period} />
      </Grid>
    </Modal>
  );
}
