import {NestedValue, useFormContext, Controller} from 'react-hook-form';
import React from 'react';
import * as yup from 'yup';
import {TextInput} from '../../../ui/form';
import {ICD10Input, ICD10Type} from '../../../ui/form/ICD10Input';
import {FormSection} from './FormSection';
import {yObject, yString, yArray} from '@src/schema/types';

export const assessmentSchema = {
  general: yString,
  icd10_codes: yArray(
    yObject({
      id: yString,
      code: yString,
      description: yString,
    }),
  ).when('$isSigning', (isSigning: boolean, s: yup.ArraySchema<{}>) =>
    isSigning
      ? s.min(1, 'At least one completed diagnosis code is required')
      : s,
  ),
};

export type AssessmentValues = {
  general: string;
  icd10_codes: NestedValue<ICD10Type[]>;
};

export const defaultAssessmentValues = (): AssessmentValues => ({
  general: '',
  icd10_codes: ([] as unknown) as NestedValue<ICD10Type[]>,
});

export const AssessmentLabels = {
  general: 'General Assessment',
  icd10_codes: 'ICD-10 Code',
};

type Props = {
  isSigning?: boolean;
};

export function AssessmentSection({isSigning = false}: Props) {
  const {errors, control} = useFormContext<AssessmentValues>();
  return (
    <FormSection title="Assessment/ICD-10 Codes">
      <Controller
        control={control}
        name="general"
        render={({onChange, value, name}, {isTouched}) => (
          <TextInput
            autoExpand={true}
            title={AssessmentLabels.general}
            placeholder="What is your general assessment of the patient?"
            name={name}
            value={value}
            touched={isTouched}
            error={errors.general?.message}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="icd10_codes"
        render={({onChange, value, name}, {isTouched}) => (
          <ICD10Input
            value={value}
            required={isSigning}
            name={name}
            instructions="Type in the ICD-10 code or diagnosis"
            title={AssessmentLabels.icd10_codes}
            onChange={onChange}
            touched={isTouched}
            error={errors.icd10_codes?.message}
          />
        )}
      />
    </FormSection>
  );
}
