import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {BottomNavigation, BottomNavigationAction} from '@material-ui/core';

import {Room} from 'twilio-video';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import {useStyles, SIDEBAR_WIDTH} from '../../../VideoChat/layout';
import {Button} from '../../../../ui/form/Button';
import {
  setUpScreensharingTrack,
  Tracks,
  stopLocalVideoTracks,
} from '@src/util/videoChat';
import {isMobileOperatingSystem} from '@src/util/browserTools';

// Layout

// Icons

type Props = {
  isVideoDisabled: boolean;
  isMuted: boolean;
  setIsVideoDisabled: (v: boolean) => unknown;
  setIsMuted: (v: boolean) => unknown;
  room: Room | null;
  isAnyoneScreensharing: boolean;
  setVideoTracks: (fn: (vts: Tracks) => Tracks) => void;
  isLocalScreensharing?: boolean;
};

export const LocalParticipantControls = ({
  isVideoDisabled,
  isMuted,
  setIsVideoDisabled,
  setIsMuted,
  room,
  isAnyoneScreensharing,
  isLocalScreensharing,
  setVideoTracks,
}: Props) => {
  const classes = useStyles({
    width: SIDEBAR_WIDTH,
  });

  if (!room) return null;

  const showScreensharing =
    !isAnyoneScreensharing && !isMobileOperatingSystem();

  const showStopScreensharing =
    isAnyoneScreensharing && isLocalScreensharing && !isMobileOperatingSystem();

  return (
    <>
      <BottomNavigation
        className={classes.videoControls}
        id="bottom-navigation"
      >
        {isVideoDisabled && (
          <BottomNavigationAction
            className={classes.videoControlAction}
            icon={
              <FontAwesomeIcon
                className={classes.controlButtonDisabled}
                icon={'video-slash'}
                size="lg"
                fixedWidth
                onClick={() => setIsVideoDisabled(false)}
              />
            }
          />
        )}

        {!isVideoDisabled && (
          <BottomNavigationAction
            className={classes.videoControlAction}
            icon={
              <FontAwesomeIcon
                className={classes.controlButtonActive}
                icon={'video'}
                size="lg"
                fixedWidth
                onClick={() => setIsVideoDisabled(true)}
              />
            }
          />
        )}

        {isMuted && (
          <BottomNavigationAction
            className={classes.videoControlAction}
            icon={
              <FontAwesomeIcon
                className={classes.controlButtonDisabled}
                icon={'microphone-slash'}
                size="lg"
                fixedWidth
                onClick={() => setIsMuted(false)}
              />
            }
          />
        )}

        {!isMuted && (
          <BottomNavigationAction
            className={classes.videoControlAction}
            icon={
              <FontAwesomeIcon
                className={classes.controlButtonActive}
                icon={'microphone'}
                size="lg"
                fixedWidth
                onClick={() => setIsMuted(true)}
              />
            }
          />
        )}

        {showScreensharing && (
          <BottomNavigationAction
            className={classes.videoControlAction}
            icon={
              <ScreenShareIcon
                onClick={() =>
                  setUpScreensharingTrack({
                    room,
                    setVideoTracks,
                  })
                }
              />
            }
          />
        )}

        {showStopScreensharing && (
          <BottomNavigationAction
            className={classes.videoControlAction}
            icon={
              <Button
                bStyle="danger"
                bSize="small"
                onClick={() => stopLocalVideoTracks({room})}
              >
                Stop Presenting
              </Button>
            }
          />
        )}
      </BottomNavigation>
    </>
  );
};
