import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles({
  noTopPad: {
    paddingTop: 0,
  },
  poolName: {
    fontWeight: 'bold',
  },
  root: {
    flexGrow: 1,
  },
});
