import React from 'react';
import {TabSkeleton} from './TabSkeleton';
import {PermissionsProps} from '.';
import {UserType} from '@src/models';

type Props = {
  setShowLogoutModal?: (v: boolean) => unknown;
  onLogout?: () => unknown;
  userType: UserType;
  permissions: PermissionsProps;
};

export function Tabs({
  setShowLogoutModal,
  onLogout,
  userType,
  permissions,
}: Props) {
  return (
    <TabSkeleton
      setShowLogoutModal={setShowLogoutModal}
      onLogout={onLogout}
      userType={userType}
      permissions={permissions}
    />
  );
}
