import React, {useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {Button, Grid} from '@material-ui/core';
import AssignedFormListEdit from './AssignedFormListEdit';
import SelectBlankForm from './SelectBlankForm';
import {BackButton} from './BackButton';
import {useApi} from '@src/api/useApi';
import {PrivatePage} from '@src/components/PrivatePage';
import PageLoading from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {PageHeader} from '@src/components/ui/layout';
import {useResources} from '@src/hooks';

type Props = RouteComponentProps<{visitId: string}>;

// eslint-disable-next-line import/no-unused-modules
export default function AssignedFormsPage({match}: Props) {
  const visitId = Number(match.params.visitId);
  const [newBlankFormId, setNewBlankFormId] = useState<number | null>(null);
  const api = useApi();

  const [asyncVisit, refreshVisit] = useResources(
    () => api.visit(visitId).get(),
    [api, visitId],
  );

  const deleteHandler = (formId: number) => {
    api
      .assignedForms(formId)
      .delete()
      .then(refreshVisit);
  };

  const saveHandler = () => {
    if (!newBlankFormId) return;
    api
      .visit(visitId)
      .addAssignedForm(newBlankFormId)
      .then(async v => {
        await refreshVisit();
      });
  };

  return (
    <PageLoading active={!asyncVisit.isLoaded()} message="Loading Forms">
      <PrivatePage>
        <Grid container justify="space-between" alignItems="center">
          <PageHeader>Visit Forms</PageHeader>
          <div>
            <BackButton />
          </div>
        </Grid>
        <SelectBlankForm
          forms={asyncVisit
            .getOptional()
            .map(v => v.assignedForms)
            .orElse([])}
          newBlankFormId={newBlankFormId}
          setNewBlankFormId={setNewBlankFormId}
        />
        <Button color="primary" variant="contained" onClick={saveHandler}>
          Assign form
        </Button>
        <AssignedFormListEdit
          forms={asyncVisit
            .getOptional()
            .map(v => v.assignedForms)
            .orElse([])}
          showBadge
          onDelete={deleteHandler}
        />
      </PrivatePage>
    </PageLoading>
  );
}
