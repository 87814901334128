import React from 'react';
import {Control, Controller, DeepMap, FieldError} from 'react-hook-form';
import {useStyles} from '../CreateProviderGroupPage/styles';
import {TextInput} from '@src/components/ui/form';
import {CreateCustomerCodeValues} from '@src/hooks/clientConfigurations/useCreateClientConfigurationForm';
import {MuiCheckBoxInput} from '@src/components/ui/form/MuiCheckBoxInput';
import {NAMES} from '@src/components/ui/layout/text/names';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';

type Props = {
  control: Control<CreateCustomerCodeValues>;
  errors: DeepMap<CreateCustomerCodeValues, FieldError>;
};

/**
 *
 * Components in common between `CreateCustomerCodeModal` and `ClientConfigurationForm`
 */

export function Common({control, errors}: Props) {
  const classes = useStyles();

  return (
    <>
      <Controller
        control={control}
        name="logoutDestination"
        render={({onChange, onBlur, value, name}) => (
          <TextInput
            value={value}
            name={name}
            placeholder="The URL that will be navigated to after logout"
            onChange={onChange}
            onBlur={onBlur}
            title="Logout Destination"
            className={classes.inputField}
            error={errors.logoutDestination?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="customerCode"
        render={({onChange, onBlur, value, name}) => (
          <TextInput
            value={value}
            name={name}
            placeholder={toTitleCase(NAMES.customerCode)}
            onChange={onChange}
            onBlur={onBlur}
            title={toTitleCase(NAMES.customerCode)}
            className={classes.inputField}
            error={errors.customerCode?.message}
          />
        )}
      />
      <ParagraphText>Please select at least one:</ParagraphText>
      <Controller
        control={control}
        name="scheduled"
        render={({onChange, value, name}) => (
          <MuiCheckBoxInput
            value={value}
            name={name}
            title="Scheduled"
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="onDemand"
        render={({onChange, value, name}) => (
          <MuiCheckBoxInput
            value={value}
            name={name}
            title="On-Demand"
            onChange={onChange}
          />
        )}
      />
    </>
  );
}
