import * as React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {FormControlLabel, Checkbox} from '@material-ui/core';
import {TextInput} from '../../../ui/form';
import {FormSection} from './FormSection';
import {yString, rqString, yBoolean} from '@src/schema/types';
import {WarningText} from '@src/components/ui/layout/text/body/WarningText';

export const redFlagSchema = {
  red_flag_text: yString.when(['$isSigning', 'red_flag'], {
    is: true,
    then: rqString('Please include an explanation for the Red Flag'),
    otherwise: yString,
  }),
  red_flag: yBoolean,
};

export type RedFlagValues = {
  red_flag_text: string;
  red_flag: boolean;
};

export const defaultRedFlagValues = (): RedFlagValues => ({
  red_flag: false,
  red_flag_text: '',
});

export const RedFlagLabels = {
  red_flag: 'Include Red Flag',
  red_flag_text: 'Red Flag information',
};

type Props = {
  isSigning: boolean;
};

export function RedFlagSection({isSigning}: Props) {
  const {errors, control, watch, register} = useFormContext<RedFlagValues>();
  const isFlagged = watch('red_flag');

  return (
    <FormSection title="Red Flag">
      <FormControlLabel
        control={<Checkbox inputRef={register} name="red_flag" />}
        label={RedFlagLabels.red_flag}
      />

      {isFlagged && (
        <WarningText warningVariant="error">
          Please do not include any HIPAA-protected PHI in the following
          section.
        </WarningText>
      )}

      <Controller
        control={control}
        name="red_flag_text"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            value={value}
            required={isSigning}
            autoExpand
            hidden={!isFlagged}
            name={name}
            placeholder="The reason for your red flag will be emailed directly to the PCP - so please remember, do not include name, date of birth or any PHI"
            title={RedFlagLabels.red_flag_text}
            lines={3}
            onChange={onChange}
            onBlur={onBlur}
            error={errors.red_flag_text?.message}
            touched={isTouched}
          />
        )}
      />
    </FormSection>
  );
}
