import React, {useState} from 'react';
import {
  AgreeAndDownloadTitle,
  DownloadIcon,
  VisitFile,
  VisitFilesStyled,
} from './layout';
import {AppFile, UserType} from '@src/models';
import {useUser} from '@src/hooks';
import {useApi} from '@src/api/useApi';

type Props = {
  files: AppFile[];
};

const VisitFiles = ({files}: Props) => {
  const api = useApi();
  const [, userType] = useUser();
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

  const onDownload = (fileToDownload: AppFile) => {
    api
      .file(fileToDownload.id)
      .getDownload()
      .then(download => {
        setDownloadUrl(download.signedUrl);
        setTimeout(() => {
          setDownloadUrl(null);
        }, 1000);
      });
  };
  const downloadsTitle =
    userType === UserType.Professional ? 'Agree and download' : 'Download';
  return (
    <VisitFilesStyled>
      <div>
        {files.length === 0 ? (
          <div>No files uploaded yet</div>
        ) : (
          <div>
            <AgreeAndDownloadTitle>{downloadsTitle}</AgreeAndDownloadTitle>{' '}
            {files.map(f => (
              <VisitFile
                disabled={downloadUrl !== null}
                key={f.id}
                onClick={() => {
                  if (downloadUrl === null) {
                    onDownload(f);
                  }
                }}
              >
                <DownloadIcon icon={['fas', 'download']} />
                <div>{f.filename}</div>
              </VisitFile>
            ))}
          </div>
        )}
      </div>
      {downloadUrl && (
        <iframe
          title={downloadUrl}
          src={downloadUrl}
          style={{display: 'none'}}
        />
      )}
    </VisitFilesStyled>
  );
};

export default VisitFiles;
