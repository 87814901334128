import clsx from 'clsx';
import React, {FunctionComponent, useState} from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import {useStyles} from './layout';
import {LonelyVisit} from '@src/models';
import {Button} from '@src/components/ui/form';
import {Modal} from '@src/components/ui/layout/Modal';
import {PromiseButton} from '@src/components/ui/form/PromiseButton';

type Props = {
  showModal: boolean;
  returnToPatient: () => void;
  onCompleteVisit: () => Promise<unknown>;
  onDocumentNow: () => Promise<unknown>;
  onDocumentLater: () => Promise<unknown>;
  visit: Optional<LonelyVisit>;
};

export const DocumentationChoiceModal: FunctionComponent<Props> = ({
  onCompleteVisit,
  onDocumentLater,
  onDocumentNow,
  returnToPatient,
  showModal,
  visit,
}) => {
  const classes = useStyles();
  const [stateTransitionInProgress, setInProgress] = useState(false);
  const callPool = visit.map(v => v.callPool);
  const documentationRequired = callPool
    .map(cp => cp.documentInAhana)
    .orElse(false);

  return (
    <Modal
      show={showModal}
      title="One more thing before you end this Visit"
      onClose={returnToPatient}
      modalActions={
        <Button
          disabled={stateTransitionInProgress}
          onClick={returnToPatient}
          bStyle="secondary"
        >
          Return to patient
        </Button>
      }
    >
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          className={clsx(classes.modalRow, classes.modalInstructions)}
        >
          Before you complete this visit,{' '}
          {documentationRequired ? 'you need to ' : 'you have the option to '}{' '}
          provide documentation in Refyne Connected Care.
        </Grid>
        {!documentationRequired && (
          <>
            <Grid
              item
              className={clsx(classes.modalRow, classes.modalInstructions)}
            >
              If you don't want to document this Visit in Refyne Connected Care,
              please press the button below.
            </Grid>
            <Grid item className={classes.modalRow}>
              <PromiseButton
                disabled={stateTransitionInProgress}
                actionWord="I'll document somewhere else"
                onClick={onCompleteVisit}
                bStyle="primary"
                onInProgess={setInProgress}
              />
            </Grid>
          </>
        )}
        <Grid
          item
          className={clsx(classes.modalRow, classes.modalInstructions)}
        >
          {documentationRequired ? 'When' : 'Otherwise, when'} do you want to
          complete this Visit's documentation?
        </Grid>
        <Grid item className={classes.modalRow}>
          <Grid container direction="row" justify="flex-start">
            <Grid item>
              <PromiseButton
                disabled={stateTransitionInProgress}
                onClick={onDocumentNow}
                actionWord={'Now'}
                bStyle="primary"
                onInProgess={setInProgress}
                isInRow={true}
              />
            </Grid>
            <Grid item>
              <PromiseButton
                disabled={stateTransitionInProgress}
                actionWord="Later"
                onClick={onDocumentLater}
                bStyle="primary"
                onInProgess={setInProgress}
                isInRow={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
