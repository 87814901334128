import React, {useCallback, useState} from 'react';
import {yupResolver} from '@hookform/resolvers';
import {useForm} from 'react-hook-form';
import {Grid} from '@material-ui/core';
import SharedForm from './SharedForm';
import {useStyles} from './styles';
import {schema, defaultValues} from './formSchema';
import {onSave} from './onSave';
import SeesPatientsForm from './SeesPatientsForm';
import {PrivatePage} from '@src/components/PrivatePage';
import {PageHeader} from '@src/components/ui/layout';
import {useApi} from '@src/api/useApi';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';
import {ProfessionalBasics} from '@src/components/shared/FirstTime/steps/ProfessionalInfoStep/ProviderInfoForm';
import {NAMES} from '@src/components/ui/layout/text/names';

export type CreateProvider = ProfessionalBasics & {
  confirmEmail: string;
};

// eslint-disable-next-line import/no-unused-modules
export default function CreateProviderPage() {
  const api = useApi();
  const classes = useStyles();

  const [submitting, setSubmitting] = useState(false);
  const [callPoolIds, setCallPoolIds] = useState<number[]>([]);

  const {control, errors, handleSubmit, setValue, watch, reset} = useForm<
    ProfessionalBasics & {confirmEmail: string; practiceId: string}
  >({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const allFields = watch();
  const seesPatientsState = allFields.seesPatients;

  const memoizedOnSave = useCallback(
    onSave({
      callPoolIds,
      setSubmitting,
      api,
      onSuccess: () => {
        reset();
        setCallPoolIds([]);
      },
    }),
    [api, setSubmitting, callPoolIds],
  );

  const resetSeesPatientsForm = useCallback(() => {
    setValue('qualification', '');
    setValue('speciality', '');
    setValue('phone', '');
    setCallPoolIds([]);
  }, [setValue, setCallPoolIds]);

  const setSeesPatientsValue = useCallback(
    (v: boolean) => {
      setValue('seesPatients', v);
    },
    [setValue],
  );

  return (
    <PrivatePage>
      <PageHeader>Create a {toTitleCase(NAMES.provider)}</PageHeader>

      <Grid container direction="row" className={classes.formContainer}>
        <Grid item xs={12} md={8}>
          <SharedForm
            control={control}
            errors={errors}
            setSeesPatientsValue={setSeesPatientsValue}
            resetSeesPatientsForm={resetSeesPatientsForm}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          style={{display: seesPatientsState ? 'block' : 'none'}}
          className={classes.seesPatientsFormContainer}
        >
          <SeesPatientsForm
            callPoolIds={callPoolIds}
            setCallPoolIds={setCallPoolIds}
            control={control}
            errors={errors}
          />
        </Grid>

        <Grid item xs={12} md={8} className={classes.buttonContainer}>
          <MuiAsyncActionButton
            bStyle="primary"
            bSize="normal"
            onClick={handleSubmit(memoizedOnSave)}
            actionInProgress={submitting}
            actionWord="Submit"
            disabled={submitting}
          >
            Create {NAMES.provider.toUpperCase()}
          </MuiAsyncActionButton>
        </Grid>
      </Grid>
    </PrivatePage>
  );
}
