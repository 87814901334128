import {Optional} from '@ahanapediatrics/ahana-fp';
import * as yup from 'yup';
import {
  $NewPatient,
  Patient,
  ProviderDetails,
  User,
  PatientRelationship,
  ResponsiblePerson,
} from '@src/models';
import {rqString, yDate, yObject, yString, rqBoolean} from '@src/schema/types';
import {convertDetailsToRequest} from '@src/api/ProviderDetailsAPI';

export type PatientDetailsValues = {
  isSelf: boolean;
  email?: string;
  firstName: string;
  lastName: string;
  nickName: string;
  pronouns: string;
  genderAssignedAtBirth: string;
  phone: string;
  dob: Date | null;
  details: ProviderDetails | null;
  authorized: boolean;
  guardianRelationship: string;
};

const patientDetailsByNonGuardianSchema = yObject({
  firstName: rqString("Please provide this patient's first name"),
  lastName: rqString("Please provide this patient's last name"),
  nickName: yString,
  pronouns: yString,
  genderAssignedAtBirth: yString,
  phone: rqString(
    'Please provide a number we can call to ask about this patient',
  ),
  dob: yDate('Please provide a valid date of birth').required(
    'Please provide a valid date of birth',
  ),
  isSelf: rqBoolean(
    'Please indicate whether or not the patient will have their own account rather than having a guardian',
  ),
  email: yup.string().when('isSelf', {
    is: true,
    then: yup.string().required("Please provide this patient's email address"),
  }),
});

export const isPatientIndependentSchema = yObject({
  isSelf: rqBoolean(
    'Please indicate whether or not the patient is acting as their own guardian',
  ),
  email: yup.string().when('isSelf', {
    is: true,
    then: yup.string().required("Please provide this patient's email address"),
  }),
});

const patientDetailsByGuardianSchema = patientDetailsByNonGuardianSchema.concat(
  yObject({
    guardianRelationship: yup
      .string()
      .trim()
      .required("Please tell us how you're related to this patient"),
  }),
);

export const patientDetailsSchema = (
  byGuardian: boolean,
  mode: 'create' | 'update',
) => {
  const schema = byGuardian
    ? patientDetailsByGuardianSchema
    : patientDetailsByNonGuardianSchema;

  if (byGuardian && mode === 'create') {
    return schema.concat(
      yObject({
        authorized: yup
          .boolean()
          .oneOf(
            [true],
            'Please confirm that you are authorized to seek care for this patient',
          )
          .required(),
      }),
    );
  }

  return schema;
};

export const valuesToPatient = (
  values: PatientDetailsValues,
  creator: Optional<User>,
): $NewPatient => {
  const patient: $NewPatient = {
    firstName: values.firstName,
    lastName: values.lastName,
    nickName: values.nickName,
    pronouns: values.pronouns,
    genderAssignedAtBirth: values.genderAssignedAtBirth,
    dob: values.dob,
    phone: values.phone,
    details: Optional.of(convertDetailsToRequest(Optional.of(values.details))),
  };

  return patient;
};

const defaultPatientDetails: PatientDetailsValues = {
  isSelf: false,
  firstName: '',
  lastName: '',
  nickName: '',
  pronouns: '',
  genderAssignedAtBirth: '',
  authorized: false,
  dob: null,
  phone: '',
  email: '',
  details: null,
  guardianRelationship: '',
};

export const patientToValues = (
  pt: Optional<Patient>,
  currentUserRelationship: Optional<PatientRelationship>,
): PatientDetailsValues => {
  if (!pt.isPresent()) {
    const details = defaultPatientDetails;
    return details;
  }

  const patient = pt.get();

  return Object.assign<
    PatientDetailsValues,
    PatientDetailsValues,
    Partial<PatientDetailsValues>
  >({} as PatientDetailsValues, defaultPatientDetails, {
    firstName: patient.firstName,
    lastName: patient.lastName,
    nickName: patient.nickName,
    dob: patient.dob,
    phone: patient.phone,
    pronouns: patient.pronouns,
    genderAssignedAtBirth: patient.genderAssignedAtBirth,
    details: patient.pcp.orNull(),
    guardianRelationship: currentUserRelationship.property('relationship'),
  });
};

export const personToPatientDetailsValues = (
  rp: Optional<ResponsiblePerson>,
  values: PatientDetailsValues,
): PatientDetailsValues => {
  const responsiblePerson = rp.orElse(ResponsiblePerson.default());

  return Object.assign<
    PatientDetailsValues,
    PatientDetailsValues,
    Partial<PatientDetailsValues>
  >({} as PatientDetailsValues, defaultPatientDetails, {
    ...values,
    firstName: responsiblePerson.firstName,
    lastName: responsiblePerson.lastName,
    phone: responsiblePerson.phone || '',
    dob: responsiblePerson.dob.orNull(),
    email: responsiblePerson.email.orElse(''),
    guardianRelationship: 'Self',
    isSelf: true,
    authorized: true,
  });
};
