import {makeStyles} from '@material-ui/core';
import styled from 'styled-components';

export const UIDInfo = styled.div`
  font-size: 0.75em;
`;

export const useStyles = makeStyles(theme => ({
  overallTitle: {
    fontSize: theme.typography.h3.fontSize,
    fontFamily: theme.typography.h3.fontFamily,
    lineHeight: theme.typography.h3.lineHeight,
    display: 'block',
    margin: '8px 0',
  },
  addendumsTitle: {
    fontSize: theme.typography.h3.fontSize,
    fontFamily: theme.typography.h3.fontFamily,
    lineHeight: theme.typography.h3.lineHeight,
    display: 'block',
    margin: '8px 0',
  },

  section: {
    margin: '4px 0',
  },

  label: {
    display: 'inline-block',
    minWidth: '20em',
  },

  item: {
    marginBottom: '0.25em',
  },

  title: {
    fontSize: theme.typography.h3.fontSize,
    fontFamily: theme.typography.h3.fontFamily,
    lineHeight: theme.typography.h3.lineHeight,
    color: theme.palette.grey[500],
    display: 'block',
    margin: '1em 0 0.25em',
    width: '100%',

    '&.red-flag': {
      color: '#f00',
    },
  },

  addendums: {
    marginBottom: '8px',
  },

  addendum: {
    marginBottom: '4px',
  },

  addendumAuthor: {
    fontStyle: 'italic',
    fontSize: 'smaller',
    marginBottom: '2px',
  },

  signature: {
    fontStyle: 'italic',
  },
}));
