import {useEffect} from 'react';
import {useAsync} from '@src/hooks';
import {CallPool} from '@src/models';
import {isSomething} from '@src/util/typeTests';
import {useApi} from '@src/api/useApi';
import {UserId} from '@src/models/User';

/* eslint-disable import/no-unused-modules */
export function useProviderGroups(
  availableGroups?: CallPool[],
  providerId?: UserId,
) {
  const api = useApi();
  const [providerGroups, setProviderGroups] = useAsync<CallPool>();
  useEffect(() => {
    if (providerGroups.isAsked()) {
      return;
    }
    if (isSomething(availableGroups)) {
      setProviderGroups(availableGroups);
    } else {
      api
        .provider(providerId)
        .getGroups({memberOnly: true})
        .then(setProviderGroups);
    }
  }, [api, availableGroups, providerGroups, setProviderGroups, providerId]);

  return providerGroups;
}
