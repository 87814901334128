import React from 'react';
import {Grid} from '@material-ui/core';
import {usePageContext} from '..';
import {Current} from './Current';
import {Available} from './Available';
import {useStyles} from '@src/components/providerSide/providerGroups/ProviderGroupRosterPage/MonthTable/Day/Shifts/styles';
import {Modal} from '@src/components/ui/layout/Modal';
import {Button} from '@src/components/ui/form';
import {LoadingIndeterminate} from '@src/components/ui/atoms/progressBarsAndIndicators/LoadingIndeterminate';
import {HorizontalSection} from '@src/components/ui/atoms/section/HorizontalSection';
import {allLoaded} from '@src/util/apiHelpers';
import {NAMES} from '@src/components/ui/layout/text/names';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';

export function CustomerCodesModal() {
  const {
    clientConfigurationsResources: {
      editClientConfigurationsForGroupId,
      hideClientConfigurationsModal,
    },
    callPools,
  } = usePageContext();

  const loading = !allLoaded(callPools);

  const classes = useStyles({loading});

  return (
    <Modal
      show={editClientConfigurationsForGroupId !== null}
      title={`Edit ${toTitleCase(NAMES.customerCode)}s`}
      onClose={hideClientConfigurationsModal}
      modalActions={
        <Button bStyle="outlined" onClick={hideClientConfigurationsModal}>
          Cancel
        </Button>
      }
    >
      <Grid className={classes.modalListContainer}>
        <LoadingIndeterminate active={loading}>
          <HorizontalSection>
            <Current />
          </HorizontalSection>
          <HorizontalSection lastSection>
            <Available />
          </HorizontalSection>
        </LoadingIndeterminate>
      </Grid>
    </Modal>
  );
}
