import React, {useCallback, useReducer} from 'react';
import {Grid} from '@material-ui/core';
import {UploadsList} from '../UploadsList';
import {S3UploaderContainer} from '@src/components/ui/organisms/uploaders/S3UploaderContainer';
import {usePageContext} from '@src/components/separateAdminApp/CreateDocumentPage';
import {uploadingReducer} from '@src/components/shared/PatientDashboard/uploadingReducer';
import {useStyles} from '@src/components/ui/organisms/uploaders/documents/FileUploader/styles';
import {SigningResult} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';
import {useGetDocumentSignature} from '@src/hooks/s3Uploading/useGetDocumentSignature';
import {LegalDocument} from '@src/models';
import {isNothing} from '@src/util/typeTests';
import {useStyles as useInputStyles} from '@src/components/separateAdminApp/CreateProviderGroupPage/styles';
import {useIconStyles} from '@src/components/ui/atoms/buttonsAndIcons/styles';

export function FileUploader({
  onFileUploadedToS3,
  onClickEyeIcon,
  isInline,
}: {
  onFileUploadedToS3: (
    result: SigningResult<LegalDocument>,
    file: File,
  ) => Promise<LegalDocument>;
  onClickEyeIcon?: () => void;
  isInline?: boolean;
}) {
  const {
    formResources: {setUploadedFile, uploadedFile},
  } = usePageContext();

  const onUpdate = () => {};
  const [uploadingFiles, dispatch] = useReducer(uploadingReducer(onUpdate), []);

  const getDocumentSignature = useGetDocumentSignature();

  const classes = {...useStyles(), ...useInputStyles(), ...useIconStyles()};

  const onAttachFileToResource = useCallback(
    async (result: SigningResult<LegalDocument>, file: File) => {
      onFileUploadedToS3(result, file)
        .then(m => {
          dispatch({
            type: 'complete',
            payload: {file},
          });

          setUploadedFile({name: result.name, url: result.name});
        })
        .catch(() => {
          dispatch({
            type: 'error',
            payload: {
              file,
              error:
                'There was a problem adding this file. Please contact support for assistance',
            },
          });
        });
    },
    [onFileUploadedToS3, setUploadedFile],
  );

  return (
    <>
      <Grid className={classes.inputField} container direction="row">
        <S3UploaderContainer
          isInline={isInline}
          buttonText={
            !isNothing(uploadedFile)
              ? 'Choose a different file . . .'
              : undefined
          }
          getSignature={getDocumentSignature}
          onAttachFileToResource={onAttachFileToResource}
          onAddNewFile={file => {
            setUploadedFile(null);
            dispatch({
              type: 'add',
              payload: {file},
            });
          }}
          onUploadFail={(error, file) => {
            dispatch({
              type: 'error',
              payload: {
                file,
                error:
                  'There was a problem uploading this file. Please contact support for assistance',
              },
            });
          }}
          onCompleteFile={file => {}}
          onUpdateFile={(file, progress) => {
            dispatch({
              type: 'update',
              payload: {file, progress},
            });
          }}
        />
      </Grid>
      {(uploadingFiles.length > 0 || !isNothing(uploadedFile)) && (
        <Grid className={classes.inputField} container direction="row">
          <UploadsList
            uploadingFiles={uploadingFiles}
            onClickEyeIcon={onClickEyeIcon}
          />
        </Grid>
      )}
    </>
  );
}
