import {MedicalHistoryResult} from '../resultFunctions';

/**
 *
 * This file is for the various inputs (DateInput, SelectInput, TextInput) used for the Override
 * column
 */

export const handleTextInputChange = ({
  event,
  fieldName,
  radioSelection,
  result,
  setResult,
}: {
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  fieldName: string;
  radioSelection: string;
  setResult: (r: MedicalHistoryResult) => unknown;
  result: MedicalHistoryResult;
}) => {
  setResult({
    ...result,
    [fieldName]: {
      text: event.target.value,
      radioSelection,
    },
  });
};
