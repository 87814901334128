import clsx from 'clsx';
import {Grid} from '@material-ui/core';
import React, {useState} from 'react';
import {useStyles} from './layout';
import {Button} from '@src/components/ui/form';
import {Modal} from '@src/components/ui/layout/Modal';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';

type Props = {
  showModal: boolean;
  returnToPatient: () => void;
  onEndVisit: () => Promise<void>;
  onPlanToReturn: () => void;
  onCancelVisit: () => void;
  documentationSigned: boolean;
};

export function ProviderEndVisitModal({
  onCancelVisit,
  onEndVisit,
  onPlanToReturn,
  returnToPatient,
  showModal,
  documentationSigned,
}: Props) {
  const classes = useStyles();
  const [completingVisit, setCompletingVisit] = useState(false);

  return (
    <Modal
      show={showModal}
      onClose={returnToPatient}
      title="You're about to leave this visit"
      modalActions={
        <Button
          onClick={returnToPatient}
          bStyle="primary"
          disabled={completingVisit}
        >
          Return to patient
        </Button>
      }
    >
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          className={clsx(classes.modalRow, classes.modalInstructions)}
        >
          If you or a colleague is planning to come back to this visit, please
          click the button below.
        </Grid>
        <Grid item className={classes.modalRow}>
          <Button
            onClick={onPlanToReturn}
            bStyle="warning"
            disabled={completingVisit}
          >
            Leave the visit, but do not end it
          </Button>
        </Grid>
        <Grid
          item
          className={clsx(classes.modalRow, classes.modalInstructions)}
        >
          If you wish to complete this visit, please press the button below.{' '}
          {!documentationSigned &&
            'You will have the opportunity to complete visit documentation, if you wish.'}
        </Grid>
        <Grid item className={classes.modalRow}>
          <MuiAsyncActionButton
            bStyle={'primary'}
            disabled={completingVisit}
            actionInProgress={completingVisit}
            actionWord="End this visit"
            onClick={() => {
              setCompletingVisit(true);
              onEndVisit().finally(() => {
                setCompletingVisit(false);
              });
            }}
          />
        </Grid>
        <Grid
          item
          className={clsx(classes.modalRow, classes.modalInstructions)}
        >
          If you wish to cancel this visit, please press the button below. You
          will have the opportunity to provide a reason for the cancellation.
        </Grid>
        <Grid item className={classes.modalRow}>
          <Button
            onClick={onCancelVisit}
            bStyle="danger"
            disabled={completingVisit}
          >
            Cancel this visit
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}
