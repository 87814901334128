import React from 'react';
import {RemoteParticipant, Room} from 'twilio-video';
import AnonymousParticipantVideo from './ParticipantVideo';
import {AdmittanceProps} from '@src/util/videoChat/twilio/anonymousVisitor/tracks/types';
import {
  detectIsLocalScreensharing,
  findScreenSharingParticipant,
} from '@src/util/videoChat';
import {debouncedRecalculateVideoLayout} from '@src/hooks/videoChat/useRecalculateVideoLayout';

type GetScreensharingElement = {
  room: Room;
  onVideoTracksStateChange: () => void;
  setSelfWaitingForAdmittance: (v: boolean) => unknown;
  visitId: number;
  onAdmittedProps: AdmittanceProps;
  predicates: {
    isLocalScreensharing: boolean;
    selfWaitingForAdmittance: boolean;
    isAnyoneScreensharing: boolean;
    doNotTransmit: boolean;
    cutRoomAudio: boolean;
  };
};

export function getScreensharingElement({
  room,
  predicates: {
    doNotTransmit,
    isAnyoneScreensharing,
    selfWaitingForAdmittance,
    cutRoomAudio,
    isLocalScreensharing,
  },
  onVideoTracksStateChange,
  onAdmittedProps: {audioIn, audioOut, videoIn, onMediaFailure},
  setSelfWaitingForAdmittance,
  visitId,
}: GetScreensharingElement) {
  const [participant, , participantType] = findScreenSharingParticipant({
    room,
  });

  if (participant) {
    return (
      <AnonymousParticipantVideo
        participant={participant}
        predicates={{
          isLocal: participantType === 'local',
          isLocalScreensharing,
          isMainScreenElement: true,
          isAnyoneScreensharing,
          selfWaitingForAdmittance,
          doNotTransmit,
          cutRoomAudio,
        }}
        room={room}
        onVideoTracksStateChange={onVideoTracksStateChange}
        onAdmittedProps={{audioIn, audioOut, videoIn, onMediaFailure}}
        visitId={visitId}
        setSelfWaitingForAdmittance={setSelfWaitingForAdmittance}
      />
    );
  }

  return null;
}

export function handleNotScreensharing({
  setIsAnyoneScreensharing,
  setNotification,
  setIsLocalScreensharing,
  participants,
}: {
  setNotification: (v: JSX.Element | null) => unknown;
  setIsAnyoneScreensharing: (v: boolean) => unknown;
  setIsLocalScreensharing: (v: boolean) => unknown;
  participants: RemoteParticipant[];
}) {
  setIsAnyoneScreensharing(false);
  setIsLocalScreensharing(false);

  if (participants.length === 0) {
    setNotification(<>Waiting for participants...</>);
  } else {
    setNotification(null);
  }

  debouncedRecalculateVideoLayout();
}

export function handleIsScreensharing({
  setIsAnyoneScreensharing,
  setNotification,
  setIsLocalScreensharing,
  room,
}: {
  setNotification: (v: JSX.Element | null) => unknown;
  setIsAnyoneScreensharing: (v: boolean) => unknown;
  setIsLocalScreensharing: (v: boolean) => unknown;
  room: Room | null;
}) {
  setIsAnyoneScreensharing(true);
  if (detectIsLocalScreensharing({room})) {
    setIsLocalScreensharing(true);
    setNotification(<>You're presenting to everyone</>);
  }

  debouncedRecalculateVideoLayout();
}
