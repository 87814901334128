import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {addBreadcrumb} from '@sentry/browser';
import {Severity} from '@sentry/types';
import {Link} from '@material-ui/core';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import React, {ReactNode, useEffect, useState, useCallback} from 'react';
import {Button} from '../../../ui/form/Button';
import {ButtonColumnContainer} from '../../../ui/layout/ButtonContainers';
import {PageLoading} from '../../../ui/atoms/progressBarsAndIndicators/PageLoading';
import {
  RequestHeader,
  RequestInstructions,
} from '../../../ui/layout/NewThingRequest';
import {CallPoolLabel} from './CallPoolLabel';
import {CallPoolContainer, StyledCallPoolSelector, useStyles} from './layout';
import {useUser} from '@src/hooks';
import {isNothing} from '@src/util/typeTests';
import {CallPoolOption, sortCallPools} from '@src/util/callPools/sortCallPools';
import {CallPool, UserType} from '@src/models';

type Props = {
  availablePools: AsyncData<CallPool>;
  header: string;
  instructions: ReactNode;
  onContinue?: (id: number) => unknown;
  onSelect?: (id: number) => unknown;
  selectedCallPool: CallPool | null;
  patientId: number;
};

/**
 * TODO - this should be moved over to the shared directory
 * but this is a hotfix, so trying to keep the scope of change small
 */
export function CallPoolSelector({
  availablePools,
  header,
  instructions,
  onContinue,
  onSelect,
  selectedCallPool,
  patientId,
}: Props) {
  const classes = useStyles();
  const [, userType] = useUser();

  const [selectedCallPoolId, selectCallPoolId] = useState(
    selectedCallPool?.id ?? 0,
  );
  const [sortedCallPools, setSortedCallPools] = useState<
    Map<number, CallPoolOption>
  >();

  useEffect(() => {
    if (availablePools.isLoaded()) {
      /**
       * For Professionals, we should show all Provider Groups that were returned
       * For non-Professionals, we only show Provider Groups that are NOT marked `professionalOnly`
       */
      setSortedCallPools(
        sortCallPools(
          availablePools
            .value()
            .filter(
              cp => userType === UserType.Professional || !cp.professionalOnly,
            ),
        ),
      );
    }
  }, [availablePools, setSortedCallPools, userType]);

  useEffect(() => {
    if ((sortedCallPools?.size ?? 0) === 0) {
      addBreadcrumb({
        category: 'visit-rqeuest',
        level: Severity.Error,
        message: 'Call pool selection in Visit Request has no open call pools',
        data: {patientId},
      });
    }
  }, [sortedCallPools, patientId]);

  const handleSelect = useCallback(
    (id: number) => {
      if (onSelect) {
        onSelect(id);
      }
      selectCallPoolId(id);
    },
    [onSelect],
  );

  if (!availablePools.isLoaded()) {
    return <PageLoading active message="Checking provider groups..." />;
  }

  const poolCount = sortedCallPools?.size ?? 0;

  if (poolCount === 0) {
    return (
      <StyledCallPoolSelector>
        <RequestHeader>{header}</RequestHeader>
        <RequestInstructions>
          {userType === UserType.Guardian
            ? 'Unfortunately, no physicians are currently available.'
            : 'Unfortunately, no Provider Groups are currently available.'}
        </RequestInstructions>

        <RequestInstructions className={classes.noTopPad}>
          <span>
            {' '}
            If you think this is a mistake or need help requesting a visit,
            please call our support team at{' '}
            <Link href="tel:+18778642378">+1-877-864-2378</Link>.
          </span>
        </RequestInstructions>
      </StyledCallPoolSelector>
    );
  }

  return (
    <StyledCallPoolSelector>
      <RequestHeader>{header}</RequestHeader>
      <RequestInstructions>{instructions}</RequestInstructions>
      <RequestInstructions className={classes.noTopPad}>
        If an option has a number next to it, click on it to see some more
        specific options that are available to you.
      </RequestInstructions>
      <CallPoolContainer>
        <TreeView className={classes.root} disableSelection={true}>
          {[...sortedCallPools?.values()].map(v => {
            if (
              (!v.available && v.children.length === 0) ||
              !isNothing(v.parent)
            ) {
              return null;
            }
            const children = v.children.map(i => sortedCallPools?.get(i));
            return (
              <TreeItem
                key={`${v.id}`}
                nodeId={`${v.id}`}
                label={
                  <CallPoolLabel
                    callPoolOption={v}
                    selected={selectedCallPoolId === v.id}
                    onClick={handleSelect}
                  />
                }
              >
                {children.map(c => {
                  if (!c?.available) {
                    return null;
                  }
                  return (
                    <TreeItem
                      key={`${c.id}`}
                      nodeId={`${c.id}`}
                      label={
                        <CallPoolLabel
                          callPoolOption={c}
                          selected={selectedCallPoolId === c.id}
                          onClick={handleSelect}
                        />
                      }
                    />
                  );
                })}
              </TreeItem>
            );
          })}
        </TreeView>
      </CallPoolContainer>
      {onContinue && (
        <ButtonColumnContainer>
          <Button
            disabled={sortedCallPools?.size === 0 || selectedCallPoolId === 0}
            bStyle="secondary"
            onClick={() => onContinue(selectedCallPoolId)}
          >
            Continue
          </Button>
        </ButtonColumnContainer>
      )}
    </StyledCallPoolSelector>
  );
}
