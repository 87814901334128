import React, {useState, useCallback} from 'react';
import {MuiAsyncActionButton} from '../../../ui/form/MuiAsyncActionButton';
import {Modal} from '../../../ui/layout/Modal';
import {LastGuardianText} from './LastGuardianText';
import {IndependentPatientText} from './IndependentPatientText';
import {GuardianText} from './GuardianText';
import {useUser} from '@src/hooks/useUser';
import {
  Invitation,
  LonelyPatient,
  Guardian,
  PatientRelationship,
  UserType,
} from '@src/models';
import {
  isUserGuardian,
  isUserIndependentPatient,
} from '@src/util/relationships/guardianOrIndependent/unlink';
import {Button} from '@src/components/ui/form';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

type Props = {
  show: boolean;
  currentInvitations: readonly Invitation[];
  onConfirm: () => void;
  onCancel: () => void;
  onHide: () => void;
  unlinkingInProgress: boolean;
  relationship: PatientRelationship;
  guardiansCount: number;
  patient: LonelyPatient;
  updatePatient: (p: LonelyPatient) => void;
};

// eslint-disable-next-line import/no-unused-modules
export default function ConfirmUnlinkModal({
  currentInvitations,
  show,
  onConfirm,
  onCancel,
  onHide,
  unlinkingInProgress,
  relationship,
  guardiansCount,
  patient,
  updatePatient,
}: Props) {
  const [user] = useUser();
  const [inviteNewGuardian, setInviteNewGuardian] = useState(false);

  const userPersonId = user
    .getOptional()
    .cast<Guardian>(us => us.userType === UserType.Guardian)
    .map(g => g.responsiblePersonDetails)
    .map(r => r.id)
    .orElse(0 as NonProfessionalId);

  // See function definitions for truth tables.
  const userIsConnected = isUserGuardian({userPersonId, relationship});
  const userIsIndependentPatient = isUserIndependentPatient({
    userPersonId,
    relationship,
  });

  const cancelUnlink = useCallback(() => {
    onHide();
    onCancel();
  }, [onCancel, onHide]);

  const shouldShowConfirmButton =
    guardiansCount > 1 || relationship.isSelf || !inviteNewGuardian;

  return (
    <Modal
      show={show}
      title="Are you sure?"
      onClose={cancelUnlink}
      modalActions={
        <>
          {shouldShowConfirmButton && (
            <MuiAsyncActionButton
              bStyle="contained"
              bSize="small"
              actionWord="Unlink Now"
              onClick={onConfirm}
              actionInProgress={unlinkingInProgress}
              isInRow
              disabled={inviteNewGuardian}
            />
          )}
          <Button bStyle="contained" bSize="small" onClick={cancelUnlink}>
            Cancel
          </Button>
        </>
      }
    >
      {!userIsIndependentPatient && (
        <GuardianText userIsConnected={userIsConnected} />
      )}

      {guardiansCount === 1 && (
        <LastGuardianText
          currentInvitations={currentInvitations}
          userIsConnected={userIsConnected}
          onConfirm={onConfirm}
          onCancel={onCancel}
          onHide={onHide}
          patient={patient}
          inviteNewGuardian={inviteNewGuardian}
          setInviteNewGuardian={setInviteNewGuardian}
          updatePatient={updatePatient}
        />
      )}

      {userIsIndependentPatient && <IndependentPatientText />}
    </Modal>
  );
}
