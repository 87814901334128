import React, {
  ReactNode,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react';
import {io, Socket} from 'socket.io-client';
import {Optional, partial} from '@ahanapediatrics/ahana-fp';
import {
  addBreadcrumb,
  captureException,
  captureMessage,
  Severity,
} from '@sentry/browser';
import {Handler, MessageType} from './socket-types';
import ConfigService from '@src/ConfigService';
import {useApi} from '@src/api/useApi';
import {NotAuthorizedError} from '@src/api/exceptions';
import {createGenericContext} from '@src/util/reactContext/createGenericContext';

type HandlerMap<T extends MessageType = MessageType> = Map<T, Set<Handler<T>>>;

interface EventMessage {
  id: number;
  messageId: string;
}

interface Props {
  children: ReactNode;
}

const configService = ConfigService.getEnvironmentInstance();

const [useSocketContext, BaseSocketContextProvider] = createGenericContext<{
  on: <T extends MessageType = MessageType>(
    messageType: T,
    handler: Handler<T>,
  ) => void;
  off: <T extends MessageType = MessageType>(
    messageType: T,
    handler: Handler<T>,
  ) => void;
}>();

export {useSocketContext};

export const SocketContextProvider = ({children}: Props) => {
  const api = useApi();
  const handlers = useRef<HandlerMap>(new Map());
  const failures = useRef(0);
  const timeout = useRef<Optional<NodeJS.Timeout>>(Optional.empty());
  const [socket, setSocket] = useState<Optional<Socket>>(Optional.empty());

  const on = useCallback(
    <T extends MessageType = MessageType>(
      messageType: T,
      handler: Handler<T>,
    ) => {
      if (!handlers.current.has(messageType)) {
        handlers.current.set(messageType, new Set());
      }

      handlers.current.get(messageType)!.add(handler);
    },
    [],
  );

  const off = useCallback(
    <T extends MessageType = MessageType>(
      messageType: T,
      handler: Handler<T>,
    ) => {
      if (handlers.current.has(messageType)) {
        handlers.current.get(messageType)!.delete(handler);
      }
    },
    [],
  );

  const applyHandlers = useCallback(
    <T extends MessageType = MessageType>(
      messageType: T,
      ...args: Parameters<Handler<T>>
    ) => {
      if (handlers.current.has(messageType)) {
        for (const handler of handlers.current.get(messageType)!.values()) {
          handler(...args);
        }
      }
    },
    [],
  );

  const hasHandlers = useCallback(
    (messageType: MessageType) =>
      handlers.current.has(messageType) &&
      handlers.current.get(messageType)!.size > 0,
    [],
  );

  const handleVisitEvent = useCallback(
    (
      messageType: MessageType.VisitCreate | MessageType.VisitUpdate,
      visitId: number,
    ) => {
      if (hasHandlers(messageType)) {
        api
          .visit(visitId)
          .get()
          .then(visit => {
            applyHandlers(messageType, visit);

            return api.patient(visit.patient.id).get();
          })
          .then(patient => applyHandlers(MessageType.PatientUpdate, patient))
          .catch(e => {
            if (e instanceof NotAuthorizedError) {
              // do nothing
            } else {
              console.error('Got an error trying to update Visit');
              console.error(e);
            }
          });
      }
    },
    [api, applyHandlers, hasHandlers],
  );

  const handleSafeVisitUpdate = useCallback(
    (visitId: number) => {
      if (hasHandlers(MessageType.SafeVisitUpdate)) {
        api
          .visit(visitId)
          .getSafe()
          .then(visit => applyHandlers(MessageType.SafeVisitUpdate, visit))
          .catch(e => {
            if (e instanceof NotAuthorizedError) {
              // do nothing
            } else {
              console.error('Got an error trying to update Visit');
              console.error(e);
            }
          });
      }
    },
    [api, applyHandlers, hasHandlers],
  );

  const handlePatientUpdate = useCallback(
    (patientId: number) => {
      if (hasHandlers(MessageType.PatientUpdate)) {
        api
          .patient(patientId)
          .get()
          .then(patient => applyHandlers(MessageType.PatientUpdate, patient))
          .catch(e => {
            if (e instanceof NotAuthorizedError) {
              // do nothing
            } else {
              console.error('Got an error trying to update Patient');
              console.error(e);
            }
          });
      }
    },
    [api, applyHandlers, hasHandlers],
  );

  const handleError = useCallback((reason: string | Error) => {
    if (typeof reason === 'string') {
      captureMessage(reason, Severity.Error);
    } else {
      captureException(reason);
    }

    failures.current += 1;
    // Exponential backoff using intervals of 500 ms, 1 s, 2 s, 4 s, 4 s, 4 s, etc.
    // Timing chosen arbitrarily because that's the default for the SIP protocol.
    const waitMs = 2 ** Math.min(failures.current, 4) * 250;

    console.log(
      `Failed to connect Socket.IO ${failures.current} times, waiting ${waitMs}ms to try again...`,
    );

    /*
     * Trigger a rerender by setting state, causing this effect to
     * run again and attempt another connection.
     */
    timeout.current = Optional.of(
      setTimeout(() => setSocket(Optional.empty()), waitMs),
    );
  }, []);

  useEffect(() => {
    // Try to connect if there's no current connection.
    if (!socket.isPresent()) {
      /*
       * The jwt value obtained by api.access_token expires, which is why
       * SocketIO's built-in reconnection mechanism cannot be used. SocketIO
       * doesn't allow you to inject new query parameters on each new attempt.
       * This algorthm attempts reconnection infinitely with no backoff timing.
       */
      Promise.all([configService.getEventsHostname(), api.access_token]).then(
        ([eventServerHostname, jwt]) => {
          if (!eventServerHostname) {
            // Will not try to reconnect again until context remount/page reload.
            console.warn('No event server URL is available');

            return;
          }

          if (!jwt) {
            // Will not try to reconnect again until context remount/page reload.
            console.warn('Cannot connect to event server without API token');

            return;
          }

          const s = io(eventServerHostname, {
            query: {jwt},
            withCredentials: true,
            reconnection: false, // Don't use SocketIO's reconnect mechanism.
          });

          s.on('connect', () => {
            failures.current = 0;
          });

          s.on('disconnect', (reason: string) => {
            /*
             * Don't reconnect if the client chose to disconnect, for example
             * on unmount of this component.
             */
            if (reason !== 'io client disconnect') {
              handleError(reason);
            }
          });

          s.on('connect_error', handleError);

          const handlerTypeMap: Record<
            MessageType,
            (entityId: number) => void
          > = {
            [MessageType.VisitCreate]: partial(
              handleVisitEvent,
              MessageType.VisitCreate,
            ),
            [MessageType.VisitUpdate]: partial(
              handleVisitEvent,
              MessageType.VisitUpdate,
            ),
            [MessageType.SafeVisitUpdate]: handleSafeVisitUpdate,
            [MessageType.PatientUpdate]: handlePatientUpdate,
          };

          for (const [messageType, handler] of Object.entries(handlerTypeMap)) {
            s.on(messageType, ({id, messageId}: EventMessage) => {
              addBreadcrumb({
                category: 'socket',
                level: Severity.Info,
                message: messageType,
                data: {id},
              });
              handler(id);
              s.emit('ACK', messageId);
            });
          }

          setSocket(Optional.of(s));
        },
      );
    }

    return () => {
      timeout.current.ifPresent(t => clearTimeout(t));
      socket.ifPresent(s => s.disconnect());
    };
  }, [
    api,
    handleError,
    handlePatientUpdate,
    handleSafeVisitUpdate,
    handleVisitEvent,
    socket,
  ]);

  return (
    <BaseSocketContextProvider value={{on, off}}>
      {children}
    </BaseSocketContextProvider>
  );
};
