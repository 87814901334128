import {AsyncData, RemoteDataStatus} from '@ahanapediatrics/ahana-fp';
import {CircularProgress, TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, {ReactElement, ReactNode, useState} from 'react';
import {renderToString} from 'react-dom/server';
import {InputProps} from './Input';

type AutoSuggestProps<Suggestion> = InputProps<string, HTMLInputElement> & {
  /**
   * @function
   * @param value <string> The current value inside the input
   */
  onChange: (suggestion: Suggestion | null) => void;
  slimline?: boolean;
  /**
   * This is displayed if no results are returned by the search
   */
  finalNode?: Suggestion;
  onClickFinalNode?: () => unknown;
  noSuggestionsNode?: ReactNode;
  getSuggestionValue: (suggestion: Suggestion) => string;
  getSuggestionKey: (suggestion: Suggestion) => string;
  renderSuggestion: (suggestion: Suggestion) => ReactElement;
  suggestions: AsyncData<Suggestion>;
  getSuggestions: (searchString: string) => unknown;
};

const AutoSuggest = <Suggestion extends {readonly id: number | string}>({
  className,
  disabled = false,
  error = '',
  finalNode,
  getSuggestionKey,
  getSuggestionValue,
  getSuggestions,
  onChange,
  onClickFinalNode,
  placeholder = '',
  renderSuggestion,
  required,
  suggestions,
  title,
}: AutoSuggestProps<Suggestion>) => {
  const [value, setValue] = useState<Suggestion | null>(null);

  const bySuggestion = (a: Suggestion, b: Suggestion) => {
    return renderToString(renderSuggestion(a)).localeCompare(
      renderToString(renderSuggestion(b)),
    );
  };

  const errorText =
    typeof error === 'string'
      ? error
      : error
      ? 'Please complete your search'
      : '';

  //@ts-ignore
  const renderInputComponent = props => {
    return (
      <TextField
        {...props}
        error={!!error}
        helperText={errorText}
        required={required}
        label={title}
        placeholder={placeholder}
        variant="outlined"
        InputProps={{
          ...(props.InputProps ?? {}),
          endAdornment: (
            <>
              {suggestions.is(RemoteDataStatus.Loading) ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
              {props.InputProps.endAdornment}
            </>
          ),
        }}
      />
    );
  };

  return (
    <Autocomplete
      className={className}
      disabled={disabled}
      options={[...suggestions.getAllOptional().orElse([])].sort(bySuggestion)}
      onInputChange={(__, newInputValue) => {
        getSuggestions(newInputValue);
      }}
      value={value}
      renderOption={renderSuggestion}
      onChange={(e, suggestion) => {
        if (suggestion?.id === '-1' || suggestion?.id === -1) {
          if (onClickFinalNode) {
            onClickFinalNode();
          }
        } else {
          setValue(suggestion);
          onChange(suggestion);
        }
      }}
      noOptionsText="No results found"
      loading={suggestions.is(RemoteDataStatus.Loading)}
      renderInput={renderInputComponent}
      getOptionLabel={getSuggestionValue}
      getOptionSelected={(option, val) =>
        getSuggestionKey(option) === getSuggestionKey(val)
      }
      filterOptions={(options, params) => {
        const filtered = [...options];

        // Suggest the creation of a new value
        if (params.inputValue !== '' && finalNode) {
          filtered.push(finalNode);
        }

        return filtered;
      }}
    />
  );
};

export default AutoSuggest;
