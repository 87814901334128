import styled from 'styled-components';
import {sm, xs, primaryLight, primaryText} from '@src/components/ui/theme';

export const LabelContainer = styled.div<{selected?: boolean}>`
  background-color: ${props => (props.selected ? primaryLight : 'inherit')};
  color: ${props => (props.selected ? primaryText : 'inherit')};
  border: 1px #000 solid;
  border-radius: ${xs};
  margin: ${xs} 0;
  padding: ${sm} ${xs};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const LabelContents = styled.div`
  flex-grow: 1;
`;

export const LabelDescription = styled.div``;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
`;
