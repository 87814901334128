import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {
  Card,
  CardContent,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import {Professional, ProviderDetails} from '@src/models';
import {not} from '@src/util/predicateHelpers/not';
import {isEmpty, isNothing} from '@src/util/typeTests';

type Props = {
  provider?: AsyncData<Professional>;
  details: AsyncData<ProviderDetails>;
  emailLabel?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      color: theme.palette.primary.main,
      display: 'inline-block',
      minWidth: '7em',
    },
    value: {},
  }),
);

export const ProviderCard = ({details, provider, emailLabel}: Props) => {
  const classes = useStyles();

  const fieldAndValue = <T extends Professional | ProviderDetails>(
    source: AsyncData<T>,
  ) => (label: string, ...fieldNames: (keyof T)[]) => {
    const data = source.getOptional();
    const fieldValues = fieldNames
      .map(fieldName => data.property(fieldName))
      .filter(not(isEmpty))
      .map(s => `${s}`.trim())
      .join(' ');
    return (
      <>
        <Typography className={classes.field} component="span">
          {label}:
        </Typography>
        <Typography className={classes.value} component="span">
          {fieldValues.length > 0 ? fieldValues : 'Unknown'}
        </Typography>
      </>
    );
  };

  return (
    <>
      {details.isLoaded() && (
        <Card>
          <CardContent>
            {!isNothing(provider) &&
              fieldAndValue(provider)('Refyne Connected Care ID', 'id')}
            {fieldAndValue(details)('Name', 'firstName', 'lastName')}
            <Divider />
            {fieldAndValue(details)('Qualification', 'qualification')}
            <Divider />
            {fieldAndValue(details)('Speciality', 'speciality')}
            <Divider />
            {fieldAndValue(details)('Searchable', 'searchable')}
            <Divider />
            {fieldAndValue(details)('Sees Patients', 'seesPatients')}
            <Divider />
            {fieldAndValue(details)(emailLabel ?? 'Email', 'email')}
            {!isNothing(provider) && <Divider />}
            {!isNothing(provider) && fieldAndValue(provider)('Phone', 'phone')}
          </CardContent>
        </Card>
      )}
    </>
  );
};
