/**
 * Creates a sorter for an array of objects
 *
 * The sorter will sort objects according to the value of the property given
 *
 * @param property property to sort on
 */
export const by = <T extends {}>(property: keyof T) => (a: T, b: T) => {
  const aVal = a[property];
  const bVal = b[property];

  if (typeof aVal === 'string' && typeof bVal === 'string') {
    return aVal.localeCompare(bVal);
  }

  if (typeof aVal === 'number' && typeof bVal === 'number') {
    return aVal - bVal;
  }

  throw new Error(
    `Property ${property} is a non-sortable type: ${typeof aVal}`,
  );
};
