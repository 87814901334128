import {makeStyles} from '@material-ui/core/styles';
import {md, mdLg} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  callPoolRulesContainer: {
    marginTop: `${mdLg}`,
  },
  inputField: {
    margin: `${md} 0`,
  },
}));
