import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useState, useMemo, useCallback} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import EditCreditCardModal from './EditCreditCardModal';
import {
  ButtonColumnLeftContainer,
  FinancialSummaryBox,
  SummaryList,
  SummarySectionContent,
  SummarySectionTitle,
  SummaryTitle,
  SummaryItem,
} from '@src/components/ui/layout/FinancialSummaryBox';
import {Button} from '@src/components/ui/form';
import {CreditCard, Patient} from '@src/models';
import {PaymentProcessorInfo} from '@src/models/CallPool';

type Props = {
  editable?: boolean;
  required?: boolean;
  creditCardForVisitCallPool: Optional<CreditCard>;
  patient: Optional<Patient>;
  onConfirmCreditCard?: (value: boolean) => unknown;
  onAddOrEdit: () => unknown;
  paymentProcessorInfo: PaymentProcessorInfo;
  callPoolId: number;
};

const CreditCardSummary = ({
  creditCardForVisitCallPool,
  editable = false,
  onConfirmCreditCard,
  onAddOrEdit,
  patient,
  required = false,
  paymentProcessorInfo,
  callPoolId,
}: Props) => {
  const [ccReady, setCCReady] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const content = useMemo(
    () =>
      creditCardForVisitCallPool
        .map(cc => [
          'We have a credit card on file for covering out of pocket costs.',
          `The last 4 digits are ${cc.last4 ?? '<unknown>'}`,
        ])
        .orElse([
          "We don't currently have a credit card on file for covering out of pocket costs.",
          'You will need to provide this information before you can join a visit that requires it.',
        ])
        .map(i => <SummaryItem>{i}</SummaryItem>),
    [creditCardForVisitCallPool],
  );

  const stripePromise = loadStripe(
    'publicKey' in paymentProcessorInfo ? paymentProcessorInfo.publicKey : '',
  );

  return (
    <FinancialSummaryBox>
      <SummaryTitle>Payment Summary</SummaryTitle>
      <SummarySectionTitle>Credit Card</SummarySectionTitle>
      <SummarySectionContent>
        <SummaryList>{content}</SummaryList>
        <ButtonColumnLeftContainer>
          {editable && (
            <Button
              bSize="small"
              onClick={() => {
                setShowModal(true);
              }}
            >
              {creditCardForVisitCallPool.isPresent()
                ? 'Edit Credit Card'
                : 'Add Credit Card'}
            </Button>
          )}
          {onConfirmCreditCard && (
            <Button
              bSize="small"
              disabled={!creditCardForVisitCallPool.isPresent() && required}
              bStyle={ccReady ? 'primary' : 'secondary'}
              onClick={() => {
                setCCReady(!ccReady);
                onConfirmCreditCard(!ccReady);
              }}
            >
              {!creditCardForVisitCallPool.isPresent() && required
                ? 'Please provide credit card information'
                : 'This information is correct'}
            </Button>
          )}
        </ButtonColumnLeftContainer>
      </SummarySectionContent>
      <Elements stripe={stripePromise}>
        <EditCreditCardModal
          show={showModal}
          onClose={closeModal}
          patient={patient.get()}
          details={{cardholder: ''}}
          onUpdateCard={onAddOrEdit}
          callPoolId={callPoolId}
          creditCardForVisitCallPool={creditCardForVisitCallPool}
        />
      </Elements>
    </FinancialSummaryBox>
  );
};

export default CreditCardSummary;
