import React, {useState} from 'react';
import {Grid, useTheme} from '@material-ui/core';
import {LightHeading} from '@src/components/ui/layout/text/heading/LightHeading';
import {LegalDocument} from '@src/models';
import {ActionList} from '@src/components/ui/molecules/lists/ActionList';
import {PdfViewer} from '@src/components/ui/organisms/PdfViewer';

export function Current({
  groupDocuments,
}: {
  groupDocuments: readonly LegalDocument[];
}) {
  const theme = useTheme();
  const mappable = groupDocuments.map(e => ({
    label: e.name,
    id: e.id,
    description: e.description,
  }));

  const [documentToShowId, setDocumentToShowId] = useState<number | null>(null);

  return (
    <>
      <Grid item xs={12}>
        <LightHeading>Current</LightHeading>
      </Grid>
      <Grid item xs={12}>
        {documentToShowId !== null && (
          <PdfViewer
            document={
              groupDocuments.find(f => f.id === documentToShowId) ?? null
            }
            onClose={() => setDocumentToShowId(null)}
          />
        )}
        <ActionList
          mappable={mappable}
          emptyMessage="This Group currently has no Forms."
          primaryActionProps={{
            handleActionClicked: (id: number) => {
              setDocumentToShowId(id);
            },
            iconColor: theme.palette.grey[500],
            icon: ['fas', 'eye'],
          }}
        />
      </Grid>
    </>
  );
}
