import React, {useEffect, useCallback, useState} from 'react';
import _ from 'lodash';
import {IconButton, List, ListSubheader} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStyles} from '../util/styles';
import {getNoFilesText, getNoResourceText} from '../util/text';
import {onUploadFile} from './uploadFunctions';
import {VisitSelector} from './VisitSelector';
import {OverviewPageInstructions} from '@src/components/ui/organisms/uploaders/files/FileUploader/instructionsTemplates/OverviewPageInstructions';
import {UploadModal} from '@src/components/ui/organisms/uploaders/files/UploadModal';
import {Folder} from '@src/components/ui/organisms/uploaders/files/Folder';
import {NoFiles} from '@src/components/ui/organisms/uploaders/files/Folder/NoFiles';
import {VisitFile, LonelyVisit, Patient, SimpleVisit} from '@src/models';
import {useApi} from '@src/api/useApi';
import {useResources} from '@src/hooks';
import {
  getDate,
  getStartTime,
  getVisitProviderDescription,
} from '@src/util/visits/getVisitDescriptions';
import {NoResource} from '@src/components/ui/organisms/uploaders/files/Folder/NoResource';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';

const byVisitStart = (v1: SimpleVisit, v2: SimpleVisit) =>
  v2.start.getTime() - v1.start.getTime();

export function VisitsFolders({
  files,
  patientIsCurrentUser,
  patient,
  setFiles,
}: {
  files: readonly VisitFile[];
  patientIsCurrentUser: boolean;
  patient: Patient;
  setFiles: (v: readonly VisitFile[]) => unknown;
}) {
  const api = useApi();
  const classes = useStyles();

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [visitIdForUpload, setVisitIdForUpload] = useState<number>(0);
  const [visits] = useResources<LonelyVisit>(
    () =>
      api
        .patient(patient.id)
        .getActiveVisits()
        .then(as =>
          as.map(LonelyVisit.fromSimpleVisit(patient)).sort(byVisitStart),
        ),
    [api, patient],
  );

  const groupedByVisit = Object.values(_.groupBy(files, f => f.visit.id));

  const groupedAndChronological = groupedByVisit.sort((group1, group2) =>
    byVisitStart(group1[0].visit, group2[0].visit),
  );

  const onFileUploadedToS3 = useCallback(
    onUploadFile({api, visitIdForUpload, files, setFiles}),
    [api, setFiles, files, visitIdForUpload],
  );

  useEffect(() => {
    if (visits.isLoaded()) {
      const mostRecentVisitId = visits
        .getAllOptional()
        .map(v => v[0])
        .property('id', 0);
      setVisitIdForUpload(mostRecentVisitId);
    }
  }, [visits, setVisitIdForUpload]);

  const visitFiles = groupedAndChronological.map(group => {
    const folderVisit = group[0].visit;

    const labels = {
      mainText: {
        primary: <>{getDate(folderVisit.start)}</>,
        secondary: (
          <>
            at {getStartTime(folderVisit.start)} with{' '}
            {getVisitProviderDescription(folderVisit)}
          </>
        ),
      },
    };

    return <Folder files={group} labels={labels} key={folderVisit.id} />;
  });

  const uploadModalInstructions = (
    <OverviewPageInstructions
      fileCategory="visits"
      preferredName={patient.preferredName}
      patientIsCurrentUser={patientIsCurrentUser}
    />
  );

  const noVisits =
    visits.isLoaded() && visits.getAllOptional().orElse([]).length === 0;

  return (
    <PageLoading active={!visits.isLoaded()} message="Loading Visit Files">
      <List
        component="nav"
        subheader={
          <ListSubheader component="div">
            Files From All Visits
            {!noVisits && (
              <IconButton
                onClick={() => {
                  setShowUploadModal(true);
                }}
                disabled={noVisits}
              >
                <FontAwesomeIcon icon={['fas', 'plus-circle']} size="sm" />
              </IconButton>
            )}
          </ListSubheader>
        }
        className={classes.root}
      >
        {noVisits && files.length === 0 && (
          <NoResource noResourceText={getNoResourceText('visits') ?? ''} />
        )}

        {files.length > 0 && visitFiles}
        {!noVisits && files.length === 0 && (
          <NoFiles noFilesText={getNoFilesText('visits')} />
        )}

        {showUploadModal && (
          <UploadModal
            onFileUploadedToS3={onFileUploadedToS3}
            setShow={setShowUploadModal}
            show={showUploadModal}
            selector={
              <VisitSelector visits={visits.getAllOptional().orElse([])} />
            }
            instructions={uploadModalInstructions}
          />
        )}
      </List>
    </PageLoading>
  );
}
