import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useCallback, useEffect, useState} from 'react';
import {
  CircularProgress,
  MenuItem,
  FormControl,
  Select,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  downloadSelection,
  getFilterFromUrl,
  getSortedAndUniqueCallPools,
  VisitFilter,
  isVisitFilter,
  useGetters,
} from './functions';
import {Buttons, DashboardContent} from './layout';
import {useApi} from '@src/api/useApi';
import {PrivatePage} from '@src/components/PrivatePage';
import {AsyncActionButton} from '@src/components/ui/form';
import {PageHeader} from '@src/components/ui/layout/PageHeader';
import {mobileMaxAsNumber} from '@src/components/ui/theme';
import {usePagedResources, useUser, useWindowEvent} from '@src/hooks';
import {CallPool, LonelyVisit} from '@src/models';
import {getCallPoolName} from '@src/util/callPools/getCallPoolName';
import {useVisitUpdateEvents} from '@src/hooks/socket';
import {PastVisitsTable} from '@src/components/shared/PastVisitsTable';

// eslint-disable-next-line import/no-unused-modules
export default function PastVisitsDashboard() {
  const api = useApi();
  const [user, userType] = useUser();

  const [filter, setFilter] = useState<VisitFilter>('myPanel');
  const [callPools, setCallPools] = useState<Optional<CallPool>[]>([]);

  // If this id is 0, show provider's patient notes.
  // Otherwise show notes for the selected call pool.
  const [callPoolId, setCallPoolId] = useState<number>(0);
  const [gettingDownload, setGettingDownload] = useState(false);
  const [selection, setSelection] = useState<Record<string, boolean>>({});
  const theme = useTheme();
  const matchesMedium = useMediaQuery(theme.breakpoints.up('md'));

  const [mode, setMode] = useState<'desktop' | 'mobile'>(
    matchesMedium ? 'desktop' : 'mobile',
  );

  const getters = useGetters();

  const [visits, next, moreAvailable, updateVisits] = usePagedResources<
    LonelyVisit
  >(o => getters[filter](o), [filter, getters]);

  const updateSize = useCallback(() => {
    if (window.innerWidth < mobileMaxAsNumber) {
      setMode('mobile');
    } else {
      setMode('desktop');
    }
  }, [setMode]);

  const updateVisit = useCallback(
    (visit: LonelyVisit) => {
      const visitIndex = visits.findIndex(v => v.id === visit.id);
      if (visitIndex >= 0) {
        updateVisits(
          visits
            .update(visitIndex, visit)
            .getAllOptional()
            .orElse([]),
        );
      }
    },
    [updateVisits, visits],
  );

  const getSelectedIds = useCallback(() => {
    const ids = visits
      .getAllOptional()
      .orElse([])
      .map(v => v.id);
    return ids.filter(id => selection[id]);
  }, [selection, visits]);

  const getVisitToggler = (id: number) => (selected: boolean) => () =>
    setSelection({...selection, [id]: selected});

  const selectFilter = useCallback((e: React.ChangeEvent<{value: unknown}>) => {
    const {value} = e.target;
    if (typeof value === 'string' && isVisitFilter(value)) {
      setFilter(value);
      setCallPoolId(0);
      setSelection({});
    }
  }, []);

  useEffect(() => {
    setCallPools(
      getSortedAndUniqueCallPools(visits.getAllOptional().orElse([])),
    );
  }, [visits]);

  useEffect(() => {
    if (user.isLoaded()) {
      setFilter(getFilterFromUrl());
    }

    updateSize();
  }, [setFilter, updateSize, user]);

  useWindowEvent('resize', updateSize);

  useVisitUpdateEvents(
    visits
      .getAllOptional()
      .orElse([])
      .map(a => a.id),
    updateVisit,
  );

  return (
    <PrivatePage>
      <DashboardContent>
        <PageHeader>Past Visits</PageHeader>

        {!visits.containsData() && <CircularProgress color="secondary" />}

        {visits.containsData() && (
          <div style={{marginBottom: '1rem'}}>
            <FormControl style={{marginRight: '1rem'}}>
              <Select
                style={{fontSize: '1rem'}}
                value={filter}
                onChange={selectFilter}
              >
                <MenuItem value={'myPanel'}>My Patients</MenuItem>
                <MenuItem value={'myVisits'}>
                  My Refyne Connected Care Visits
                </MenuItem>
                <MenuItem value={'myManagedGroupVisits'}>
                  My Managed Provider Groups
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <Select
                value={callPoolId}
                onChange={(e: React.ChangeEvent<{value: unknown}>) => {
                  setCallPoolId(parseInt(e.target.value as string));
                  setSelection({});
                }}
                style={{fontSize: '1rem'}}
              >
                <MenuItem value={0}>All</MenuItem>

                {callPools.map(cp =>
                  cp
                    .map(oCp => (
                      <MenuItem key={oCp.id} value={oCp.id}>
                        {getCallPoolName(oCp, userType)}
                      </MenuItem>
                    ))
                    .orNull(),
                )}
              </Select>
            </FormControl>

            <PastVisitsTable
              callPoolId={callPoolId}
              getVisitToggler={getVisitToggler}
              isSelectable
              mode={mode}
              selectedIds={getSelectedIds()}
              updateVisit={updateVisit}
              updateVisits={updateVisits}
              visits={visits}
            />
          </div>
        )}

        <Buttons>
          <AsyncActionButton
            bSize="small"
            bStyle="primary"
            block={mode === 'mobile'}
            onClick={next}
            actionInProgress={visits.isLoading()}
            disabled={!moreAvailable}
            actionWord="Load more"
          />
          <AsyncActionButton
            bSize="small"
            bStyle="primary"
            block={mode === 'mobile'}
            disabled={
              !visits.isLoaded() ||
              visits.isEmpty() ||
              getSelectedIds().length === 0
            }
            onClick={() =>
              downloadSelection(api)(getSelectedIds(), setGettingDownload)
            }
            actionInProgress={gettingDownload}
            actionWord="Download Selected"
          />
        </Buttons>
      </DashboardContent>
    </PrivatePage>
  );
}
