import {CircularProgress, Grid} from '@material-ui/core';
import React from 'react';

export function Loading({centered = true}: {centered?: boolean}) {
  return (
    <Grid container xs={12} justify={centered ? 'center' : 'flex-start'}>
      <CircularProgress />
    </Grid>
  );
}
