import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles({
  divider: {
    marginTop: '1em',
    marginBottom: '2em',
  },
  docFrame: {
    width: '95%',
    height: '100%',
  },
  viewButton: {
    marginBottom: '1em',
  },
});
