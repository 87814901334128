import clsx from 'clsx';
import {makeStyles, createStyles} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import {getComplaint} from '../oncall/OnCallDashboard/utilities';
import {useStyles} from './layout';
import {SimpleVisit} from '@src/models';

/**
 * This component seems complicated.
 *
 * The main reason is that we're responding to layout stuff and, frankly, that's complicated!
 * We're responding to layout and whether text is flowing outside of its content box and we can only
 * do that _after_ the component is rendered... this is tricky to do in a purely Reacty way
 */

type Props = {
  visit: SimpleVisit;
};

const COMPLAINT_EFFECT = 'complaintEffect';

/*
 * We're using CSS animations to detect the creation of a DOM node
 *
 * credit: https://davidwalsh.name/detect-node-insertion
 */
export const useComplaintStyles = makeStyles(() =>
  createStyles({
    [`@keyframes ${COMPLAINT_EFFECT}`]: {
      from: {opacity: '0.99'},
      to: {opacity: '1'},
    },
    complaintDetector: {
      animation: `$${COMPLAINT_EFFECT} 0.001s`,
    },
  }),
);

/*
 * This hook sets up the event handler to listen for animation events to
 * tell us that a new DOM node has been inserted
 */
const insertListener = (event: AnimationEvent) => {
  const {animationName} = event;
  if (animationName.includes(COMPLAINT_EFFECT)) {
    const {target} = event;
    if (target === null) {
      return;
    }
    const n = target as HTMLElement;

    if (n.scrollWidth > n.offsetWidth) {
      const parentNode = n.parentNode as Element;
      if (parentNode && !parentNode.classList.contains('overflowing')) {
        parentNode.classList.add('overflowing');
      }
    }
  }
};

export const useChiefComplaint = () => {
  useEffect(() => {
    document.addEventListener('animationstart', insertListener, false);
    return () =>
      document.removeEventListener('animationstart', insertListener, false);
  });
};

export const ChiefComplaint: React.FunctionComponent<Props> = ({visit}) => {
  const [showMore, setShowMore] = useState(false);
  const classes = useStyles();
  const complaintClasses = useComplaintStyles();
  return (
    <div className={clsx(classes.complaintRoot)}>
      <div
        className={clsx(
          classes.contentContainer,
          complaintClasses.complaintDetector,
          {showMore},
        )}
      >
        {getComplaint(visit) || 'No details given'}
      </div>
      <div
        className={classes.viewMore}
        onClick={() => {
          setShowMore(!showMore);
        }}
      >
        {showMore ? 'View Less' : 'View More'}
      </div>
    </div>
  );
};
