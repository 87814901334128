import React, {useCallback, useEffect, useMemo, useReducer} from 'react';
import {Grid} from '@material-ui/core';
import {flashError, flashSuccess} from '../notifications/flash';
import FormDateInput from './FormDateInput';
import FormMultipleChoiceInput from './FormMultipleChoiceInput';
import Signature, {SignatureChangeHandler} from './Signature';
import FormNumericInput from './FormNumericInput';
import FormTextInput from './FormTextInput';
import FormStaticContent from './FormStaticContent';
import {ApplicationException} from '@src/api/exceptions';
import {useApi} from '@src/api/useApi';
import {Button} from '@src/components/ui/form';
import {PageHeader} from '@src/components/ui/layout';
import {useResources, useUser} from '@src/hooks';
import {
  AssignedForm,
  DateResponse,
  MultipleChoiceResponse,
  NumericResponse,
  StaticContent,
  TextResponse,
  UserType,
} from '@src/models';
import getSortedFormElements from '@src/util/assignedForms/getSortedFormElements';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';

type Action =
  | {type: 'HYDRATE'; assignedForm: AssignedForm}
  | {type: 'SAVING'}
  | {
      type: 'SAVED';
      assignedForm: AssignedForm;
      dirty: number; // This is the dirty timestamp when saving started.
    }
  | {
      type: 'UPDATE_DATE_RESPONSE';
      responseId: number;
      value: Date | null;
    }
  | {
      type: 'UPDATE_MULTIPLE_CHOICE_RESPONSE';
      responseId: number;
      value: number[];
    }
  | {
      type: 'UPDATE_TEXT_RESPONSE';
      responseId: number;
      value: string | null;
    }
  | {
      type: 'UPDATE_NUMERIC_RESPONSE';
      responseId: number;
      value: number | null;
    }
  | {
      type: 'UPDATE_SIGNATURE';
      signedAt: Date | null;
      signatureName: string | null;
      signatureFont: string | null;
      signatureImageUri: string | null;
    };

interface State {
  dirty: number | null; // This is a timestamp of when dirtiness occurred.
  saving: boolean;
  signedAt: Date | null;
  signatureName: string | null;
  signatureFont: string | null;
  signatureImageUri: string | null;
  dateResponses: Map<number, Date | null>;
  multipleChoiceResponses: Map<number, number[]>;
  textResponses: Map<number, string | null>;
  numericResponses: Map<number, number | null>;
}

function assignedFormToState(
  assignedForm: AssignedForm,
): Omit<State, 'dirty' | 'saving'> {
  return {
    signedAt: assignedForm.signedAt.orNull(),
    signatureName: assignedForm.signatureName.orNull(),
    signatureFont: assignedForm.signatureFont.orNull(),
    signatureImageUri: assignedForm.signatureImageUri.orNull(),
    dateResponses: assignedForm.dateResponses
      .orElse([])
      .reduce(
        (answers, response) =>
          answers.set(response.id, response.value.orNull()),
        new Map<number, Date | null>(),
      ),
    multipleChoiceResponses: assignedForm.multipleChoiceResponses
      .orElse([])
      .reduce(
        (answers, response) =>
          answers.set(
            response.id,
            response.formMultipleChoiceOptionIds.orElse([]),
          ),
        new Map<number, number[]>(),
      ),
    textResponses: assignedForm.textResponses
      .orElse([])
      .reduce(
        (answers, response) =>
          answers.set(response.id, response.value.orNull()),
        new Map<number, string | null>(),
      ),
    numericResponses: assignedForm.numericResponses
      .orElse([])
      .reduce(
        (answers, response) =>
          answers.set(response.id, response.value.orNull()),
        new Map<number, number | null>(),
      ),
  };
}

function multipleChoiceResponsesMapToArray(responses: Map<number, number[]>) {
  return [...responses.entries()].map(([id, formMultipleChoiceOptionIds]) => ({
    id,
    formMultipleChoiceOptionIds,
  }));
}

function responsesMapToArray<T>(responses: Map<number, T | null>) {
  return [...responses.entries()].map(([id, value]) => ({
    id,
    value,
  }));
}

function stateToUpdateBody({
  dirty,
  dateResponses,
  multipleChoiceResponses,
  textResponses,
  numericResponses,
  ...data
}: State) {
  return {
    ...data,
    dateResponses: responsesMapToArray(dateResponses),
    multipleChoiceResponses: multipleChoiceResponsesMapToArray(
      multipleChoiceResponses,
    ),
    textResponses: responsesMapToArray(textResponses),
    numericResponses: responsesMapToArray(numericResponses),
  };
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'HYDRATE':
      return {
        ...assignedFormToState(action.assignedForm),
        dirty: null,
        saving: false,
      };

    case 'SAVING':
      return {
        ...state,
        saving: true,
      };

    case 'SAVED': {
      // Only declare not dirty if the dirty timestamp hasn't changed since saving started
      // since the user can change things while saving is in-progress!
      return state.dirty === action.dirty
        ? {...state, dirty: null, saving: false}
        : {...state, saving: false};
    }

    case 'UPDATE_SIGNATURE': {
      let {type, ...data} = action;

      return {
        ...state,
        ...data,
        dirty: Date.now(),
      };
    }

    case 'UPDATE_DATE_RESPONSE': {
      let {responseId, value} = action;

      return {
        ...state,
        dateResponses: state.dateResponses.set(responseId, value),
        dirty: Date.now(),
      };
    }

    case 'UPDATE_MULTIPLE_CHOICE_RESPONSE': {
      let {responseId, value} = action;

      return {
        ...state,
        multipleChoiceResponses: state.multipleChoiceResponses.set(
          responseId,
          value,
        ),
        dirty: Date.now(),
      };
    }

    case 'UPDATE_TEXT_RESPONSE': {
      let {responseId, value} = action;

      return {
        ...state,
        textResponses: state.textResponses.set(responseId, value),
        dirty: Date.now(),
      };
    }

    case 'UPDATE_NUMERIC_RESPONSE': {
      let {responseId, value} = action;

      return {
        ...state,
        numericResponses: state.numericResponses.set(responseId, value),
        dirty: Date.now(),
      };
    }

    default:
      throw new ApplicationException(
        `Unknown action type ${JSON.stringify(action)}`,
      );
  }
}

function isValid(assignedForm: AssignedForm | null, state: State) {
  if (assignedForm === null) {
    return false;
  }

  if (
    assignedForm.blankForm.get().signatureRequired &&
    state.signedAt === null
  ) {
    return false;
  }

  if (
    assignedForm.dateResponses
      .get()
      .some(
        dr =>
          dr.dateField.get().required &&
          (state.dateResponses.get(dr.id) ?? null) === null,
      )
  ) {
    return false;
  }

  if (
    assignedForm.multipleChoiceResponses
      .get()
      .some(
        mcr =>
          mcr.multipleChoiceField.get().required &&
          state.multipleChoiceResponses.get(mcr.id)?.length === 0,
      )
  ) {
    return false;
  }

  if (
    assignedForm.textResponses
      .get()
      .some(
        tr =>
          tr.textField.get().required &&
          state.textResponses.get(tr.id) === null,
      )
  ) {
    return false;
  }

  if (
    assignedForm.numericResponses.get().some(nr => {
      const isRequiredAndNull =
        nr.numericField.get().required &&
        state.numericResponses.get(nr.id) === null;
      const isValueProvided =
        state.numericResponses.get(nr.id) !== null &&
        state.numericResponses.get(nr.id) !== undefined;
      const isValueLessThanMin =
        state.numericResponses.get(nr.id)! < nr.numericField.get().minValue;
      const isValueMoreThanMax =
        state.numericResponses.get(nr.id)! > nr.numericField.get().maxValue;

      if (isRequiredAndNull) {
        return true;
      }
      if (isValueProvided) {
        if (isValueLessThanMin || isValueMoreThanMax) {
          return true;
        }
      }
      return false;
    })
  ) {
    return false;
  }
  return true;
}

const initialState: State = {
  dirty: null,
  saving: false,
  signedAt: null,
  signatureName: null,
  signatureFont: null,
  signatureImageUri: null,
  dateResponses: new Map(),
  multipleChoiceResponses: new Map(),
  textResponses: new Map(),
  numericResponses: new Map(),
};

interface FieldProps {
  dispatch: (action: Action) => void;
  readonly: boolean;
  element:
    | DateResponse
    | MultipleChoiceResponse
    | TextResponse
    | NumericResponse
    | StaticContent;
  state: State;
}

function Field({element, readonly, state, dispatch}: FieldProps) {
  if (element instanceof DateResponse) {
    return (
      <FormDateInput
        field={element.dateField.get()}
        readonly={readonly}
        value={state.dateResponses.get(element.id) ?? null}
        onChange={value =>
          dispatch({
            type: 'UPDATE_DATE_RESPONSE',
            responseId: element.id,
            value,
          })
        }
      />
    );
  }

  if (element instanceof MultipleChoiceResponse) {
    return (
      <FormMultipleChoiceInput
        field={element.multipleChoiceField.get()}
        readonly={readonly}
        value={state.multipleChoiceResponses.get(element.id) ?? null}
        onChange={value =>
          dispatch({
            type: 'UPDATE_MULTIPLE_CHOICE_RESPONSE',
            responseId: element.id,
            value,
          })
        }
      />
    );
  }

  if (element instanceof TextResponse) {
    return (
      <FormTextInput
        field={element.textField.get()}
        readonly={readonly}
        value={state.textResponses.get(element.id) ?? ''}
        onChange={value =>
          dispatch({
            type: 'UPDATE_TEXT_RESPONSE',
            responseId: element.id,
            value,
          })
        }
      />
    );
  }

  if (element instanceof NumericResponse) {
    return (
      <FormNumericInput
        field={element.numericField.get()}
        readonly={readonly}
        value={state.numericResponses.get(element.id) ?? null}
        onChange={value =>
          dispatch({
            type: 'UPDATE_NUMERIC_RESPONSE',
            responseId: element.id,
            value,
          })
        }
      />
    );
  }

  if (element instanceof StaticContent) {
    return <FormStaticContent innerHTML={element.content} />;
  }

  throw new ApplicationException(
    `Unknown element type ${JSON.stringify(element)}`,
  );
}

type FormProps = {
  formId: number;
  onSubmit: (v: boolean) => unknown;
};

// eslint-disable-next-line import/no-unused-modules
export default function Form({formId, onSubmit}: FormProps) {
  const [user, userType] = useUser();
  const api = useApi();

  const [state, dispatch] = useReducer(reducer, initialState);

  const [formResource, , setForm] = useResources(
    () =>
      api
        .assignedForms(formId)
        .get()
        .then(assignedForm => {
          dispatch({type: 'HYDRATE', assignedForm});

          return assignedForm;
        }),
    [api, formId],
  );

  const form = formResource.getOptional().orNull();
  const isComplete = form?.completedAt.isPresent();
  const signatureRequired = form?.blankForm.get().signatureRequired;
  const readonly = isComplete || userType !== UserType.Guardian;

  const nameOrDefaultName = useMemo(
    () =>
      state.signatureName ??
      user
        .getOptional()
        .map(u =>
          u.responsiblePersonDetails
            .map(rp => rp.fullName)
            .orElse(u.providerDetails.map(pd => pd.fullName).orElse('')),
        )
        .orElse(''),

    [state.signatureName, user],
  );

  const sortedResponses = useMemo(
    () =>
      formResource
        .getOptional()
        .map(getSortedFormElements)
        .orElse([]),
    [formResource],
  );

  const handleSignatureChange = useCallback<SignatureChangeHandler>(
    ({name, font, imageUri, signedAt}) => {
      dispatch({
        type: 'UPDATE_SIGNATURE',
        signatureName: name,
        signatureFont: font,
        signatureImageUri: imageUri,
        signedAt,
      });
    },
    [],
  );

  const handleSubmit = useCallback(() => {
    api
      .assignedForms(formId)
      .submit(stateToUpdateBody(state))
      .then(() => {
        flashSuccess('Form submitted!');
        onSubmit(true);
      })
      .catch(e =>
        flashError('There was an error submitting the form, please try again.'),
      );
  }, [api, formId, onSubmit, state]);

  useEffect(() => {
    if (state.dirty === null || state.saving) {
      return;
    }

    dispatch({type: 'SAVING'});

    api
      .assignedForms(formId)
      .update(stateToUpdateBody(state))
      .then(assignedForm => {
        setForm(assignedForm);
        dispatch({type: 'SAVED', assignedForm, dirty: state.dirty!});
      });
  }, [api, formId, setForm, state]);

  return (
    <PageLoading active={!formResource.isLoaded()} message="Loading Form">
      <PageHeader>{form?.blankForm.get().name}</PageHeader>
      <Grid container spacing={1}>
        {sortedResponses.map(response => (
          <Grid
            item
            xs={12}
            key={`${response.constructor.name}_${response.id}`}
          >
            <Field
              dispatch={dispatch}
              readonly={readonly}
              element={response}
              state={state}
            />
          </Grid>
        ))}
        {signatureRequired && (
          <Grid item xs={12}>
            <Signature
              font={state.signatureFont}
              imageUri={state.signatureImageUri}
              name={nameOrDefaultName}
              onChange={handleSignatureChange}
              signedAt={state.signedAt}
              readonly={readonly}
            />
          </Grid>
        )}
      </Grid>
      <Button
        bStyle="primary"
        disabled={
          // should be disabled if form is completed or when requirements aren't met
          isComplete || !isValid(form, state)
        }
        fullWidth
        onClick={handleSubmit}
        style={{marginTop: '20px'}}
      >
        Submit
      </Button>
    </PageLoading>
  );
}
