import React from 'react';
import {Typography, Paper, Grid} from '@material-ui/core';
import {useStyles} from '../styles';
import {PersonCard} from '../../shared/PersonCard';
import {ResponsiblePerson} from '@src/models';
import {md} from '@src/components/ui/theme';

type Props = {
  financiallyResponsibleResult: ResponsiblePerson;
  selectedRelationshipName: string;
};

export function Result({
  financiallyResponsibleResult,
  selectedRelationshipName,
}: Props) {
  const classes = useStyles();

  return (
    <Paper square elevation={0} className={classes.resetContainer}>
      <Typography style={{marginBottom: md}}>
        The Financially Responsible Person section is ready to merge! Please
        review your selection below:
      </Typography>

      <Grid style={{width: 'fit-content'}}>
        <PersonCard
          person={financiallyResponsibleResult}
          relationshipResult={selectedRelationshipName}
        />
      </Grid>
    </Paper>
  );
}
