import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ShareIcon from '@material-ui/icons/Share';
import React, {useState} from 'react';
import {useHistory} from 'react-router';
import FormNotificationModal from '@src/components/guardianSide/FormNotificationModal';
import {
  getProvider,
  getStartInfo,
} from '@src/components/guardianSide/VisitRequest/PatientVisit';
import {ShareLinkModal} from '@src/components/shared/ExamRoomModals/ShareLinkModal';
import {Button} from '@src/components/ui/form/Button';
import {SimpleVisit} from '@src/models';

type Props = {
  visits: readonly SimpleVisit[];
  patientId: number;
};

const useStyles = makeStyles(theme => ({
  regularCell: {
    width: '25%',
  },
  timeCell: {
    width: '30%',
  },
  iconCell: {
    width: '15%',
  },
  cellPadding: {
    padding: '0.5rem 0',
  },
  formIcon: {
    color: theme.palette.secondary.dark,
  },
}));

export function CurrentVisits({visits, patientId}: Props) {
  const classes = useStyles();
  const history = useHistory();
  const [shareLinkVisitId, setShareLinkVisitId] = useState(0);

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            {visits.length > 0 &&
              visits.map(visit => (
                <TableRow key={visit.uid}>
                  <TableCell
                    align="left"
                    className={`${classes.timeCell} ${classes.cellPadding}`}
                  >
                    <div style={{marginRight: '1rem'}}>
                      {getStartInfo({
                        start: visit.start,
                      })}
                    </div>
                  </TableCell>
                  <TableCell
                    align="left"
                    className={`${classes.regularCell} ${classes.cellPadding}`}
                  >
                    {getProvider(visit)}
                  </TableCell>
                  <TableCell align="right">
                    <FormNotificationModal
                      size="2x"
                      forms={visit.assignedForms
                        .orElse([])
                        .filter(as => !as.completedAt.isPresent())}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    className={`${classes.regularCell} ${classes.cellPadding}`}
                  >
                    <Button
                      onClick={() =>
                        history.push(
                          `/scheduled-visit/${visit.callPoolId}/${visit.id}/${patientId}`,
                        )
                      }
                    >
                      Join visit
                    </Button>
                  </TableCell>

                  <TableCell
                    align="left"
                    className={`${classes.iconCell} ${classes.cellPadding} `}
                  >
                    <IconButton
                      onClick={() => {
                        setShareLinkVisitId(visit.id);
                      }}
                    >
                      <ShareIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {visits.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>
                  There are no visits currently scheduled in the Refyne
                  Connected Care System. If you have an appointment scheduled
                  with your doctor's office, please press "I'm here for a Visit"
                  to proceed.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {shareLinkVisitId > 0 && (
        <ShareLinkModal
          visitId={shareLinkVisitId}
          showModal={shareLinkVisitId > 0}
          setShowModal={show => {
            if (!show) {
              setShareLinkVisitId(0);
            }
          }}
        />
      )}
    </>
  );
}
