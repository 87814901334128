import {RouteComponentProps} from 'react-router';
import {RemoteParticipant, RemoteTrack, Room} from 'twilio-video';
import {attachDataTrackEventListener} from './eventListeners';
import {AdmittanceProps} from './types';
import {SetParticipants, Tracks} from '@src/util/videoChat';

export type OnTrackSubscribedProps = {
  track: RemoteTrack;
  room: Room | null;
  setVideoTracks?: (fn: (vts: Tracks) => Tracks) => void;
  setAudioTracks?: (fn: (ats: Tracks) => Tracks) => void;
  setWaitingParticipants?: SetParticipants;
  waitingParticipants?: RemoteParticipant[];
  history: RouteComponentProps<{}>['history'];
  setSelfWaitingForAdmittance: (v: boolean) => unknown;
  onAdmittedProps: AdmittanceProps;
  visitId: number;
};

export function onTrackSubscribedOrPublished({
  track,
  setVideoTracks,
  setAudioTracks,
  room,
  onAdmittedProps: {audioIn, audioOut, videoIn, onMediaFailure},
  history,
  setSelfWaitingForAdmittance,
  visitId,
}: OnTrackSubscribedProps) {
  if (track?.kind === 'video' && setVideoTracks) {
    setVideoTracks(vts => [track, ...vts].filter(t => !!t));
  } else if (track?.kind === 'audio' && setAudioTracks) {
    setAudioTracks(ats => [track, ...ats].filter(t => !!t));
  } else if (track?.kind === 'data') {
    attachDataTrackEventListener({
      dataTrack: track,
      room,
      onAdmittedProps: {audioIn, audioOut, videoIn, onMediaFailure},
      history,
      setSelfWaitingForAdmittance,
      visitId,
    });
  }
}

type TrackUnsubscribedProps = OnTrackSubscribedProps;

export function onTrackUnsubscribedOrUnpublished({
  track,
  setVideoTracks,
  setAudioTracks,
}: TrackUnsubscribedProps) {
  if (track?.kind === 'video' && setVideoTracks) {
    setVideoTracks(vts => vts.filter(v => v !== track && !!v));
  } else if (track?.kind === 'audio' && setAudioTracks) {
    setAudioTracks(ats => ats.filter(a => a !== track && !!a));
  }
}
