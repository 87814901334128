import {uniqBy} from 'lodash';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useEffect, useMemo, useState} from 'react';
import {FormResources, useStoredReportsForm} from './useForm';
import {useReportsForProfessional} from './useAll';
import {CallPool} from '@src/models';
import {StoredReport} from '@src/models/StoredReport';

export type StoredReportsResources = {
  poolsFromReportInvoiceTargets: CallPool[];
  storedReports: AsyncData<StoredReport>;
  formResources: FormResources;
};

export function useStoredReportsPage() {
  const [storedReports] = useReportsForProfessional();

  const [
    poolsFromReportInvoiceTargets,
    setPoolsFromReportInvoiceTargets,
  ] = useState<CallPool[]>([]);

  const formResources = useStoredReportsForm();

  useEffect(() => {
    const newPools = storedReports
      .getAllOptional()
      .orElse([])
      .reduce((acc, current) => {
        const pools = current.invoiceTarget.callPools.orElse([]);

        return [...pools, ...acc];
      }, [] as CallPool[]);

    setPoolsFromReportInvoiceTargets(uniqBy(newPools, 'id'));
  }, [storedReports]);

  return useMemo(
    () => ({poolsFromReportInvoiceTargets, storedReports, formResources}),
    [poolsFromReportInvoiceTargets, storedReports, formResources],
  );
}
