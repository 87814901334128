import {Optional} from '@ahanapediatrics/ahana-fp';
import {addBreadcrumb, Severity} from '@sentry/browser';
import React, {useEffect, useState, useCallback} from 'react';
import {connect as reduxConnect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {useHistory} from 'react-router';
import {RemoteParticipant, Room} from 'twilio-video';
import {ExamRoomModals, Modal} from './ExamRoomModals';
import {AdmitVisitorsModal} from './ExamRoomModals/AdmitVisitorsModal';
import {Reconnecting} from './ExamRoomModals/Reconnecting';
import {Header} from './Header';
import {
  DeadEndError,
  DOCUMENTATION_WIDTH,
  SIDEBAR_WIDTH,
  useStyles,
} from './layout';
import {Sidebar} from './Sidebar';
import {VideoGallery} from './VideoGallery';
import {EJECT_STATES, loadDocumentation} from '@src/util/visits';
import {
  bail,
  disconnectFromTwilioRoom,
  doNotTransmit,
  ejectFromRoom,
  isRoomConnected,
  MediaFailure,
  onRoomConnected,
  reconnectToTwilioRoom,
  setUpRoom,
  stopTracks,
  useDestination,
} from '@src/util/videoChat';
import {
  AudioInputInfo,
  AudioOutputInfo,
  VideoInputInfo,
} from '@src/store/reducers/media';
import {useApi} from '@src/api/useApi';
import * as actions from '@src/store/actions';
import {ReduxState} from '@src/store';
import {
  LonelyPatient,
  LonelyVisit,
  MedicalHistory,
  SharedCarePlan,
  VisitAction,
  VisitDocumentation,
  VisitState,
} from '@src/models';
import {useViewportHeight} from '@src/hooks/useViewportHeight';
import {useAsync, useUser} from '@src/hooks';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {useOnPageLeave} from '@src/hooks/useOnPageLeave';
import {useVideoStyles} from '@src/components/ui/layout/VideoStyles';
import {useRecalculateVideoLayout} from '@src/hooks/videoChat/useRecalculateVideoLayout';
import {usePatientUpdateEvents, useVisitUpdateEvents} from '@src/hooks/socket';

type StateProps = {
  audioIn: Optional<AudioInputInfo>;
  videoIn: Optional<VideoInputInfo>;
  audioOut: Optional<AudioOutputInfo>;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  audioIn: state.media.selectedAudioInput,
  videoIn: state.media.selectedVideoInput,
  audioOut: state.media.selectedAudioOutput,
});

type DispatchProps = {
  setVisitOccurred: (v: boolean) => void;
  setHairChecked: (id: number) => void;
};

const mapDispatchToProps: DispatchProps = {
  setVisitOccurred: actions.setVisitOccurred,
  setHairChecked: actions.setHairChecked,
};

type OwnProps = {
  roomName: string;
  token: string;
  visitId: number;
};

type Props = StateProps & DispatchProps & OwnProps;

const _ExamRoom = ({
  roomName,
  token,
  visitId,
  setHairChecked,
  setVisitOccurred,
  videoIn,
  audioIn,
  audioOut,
}: Props) => {
  const [room, setRoom] = useState<Room | null>(null);
  const [participants, setParticipants] = useState<RemoteParticipant[]>([]);
  const [waitingParticipants, setWaitingParticipants] = useState<
    RemoteParticipant[]
  >([]);

  const [, userType] = useUser();
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [cutRoomAudio, setCutRoomAudio] = useState(false);
  const [mediaFailure, setMediaFailure] = useState(
    Optional.empty<MediaFailure>(),
  );

  const [aVisit, setVisit] = useAsync<LonelyVisit>();
  const [medicalHistory, setMedicalHistory] = useAsync<MedicalHistory>();
  const [scp, setScp] = useAsync<SharedCarePlan>();
  const [patient, setPatient] = useAsync<LonelyPatient>();
  const [documentation, setDocumentation] = useAsync<VisitDocumentation>();

  // UI
  const [notification, setNotification] = useState<JSX.Element | null>(
    <>Waiting for participants...</>,
  );
  const [showDocumentation, setShowDocumentation] = useState(false);
  const [modal, showModal] = useState<Modal>('none');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showReloadingModal, setShowReloadingModal] = useState(false);

  const [getDestination] = useDestination(
    userType,
    visitId,
    aVisit.getOptional().property('status'),
  );

  const api = useApi();
  const viewportHeight = useViewportHeight();
  const classes = useStyles({
    width: showDocumentation ? DOCUMENTATION_WIDTH : SIDEBAR_WIDTH,
  });
  const history = useHistory();
  const visitState = aVisit
    .getOptional()
    .map(v => v.status)
    .orElse(VisitState.UNKNOWN);

  const waitingRoomMessages = aVisit
    .getOptional()
    .map(v => v.callPool)
    .map(cp => cp.rules)
    .map(r => Optional.of(r.waitingRoomMessages as string[]));

  const videoStyles = useVideoStyles();

  const eject = useCallback(
    ({reason, returnPath}: {reason: string; returnPath: string}) => {
      ejectFromRoom({
        reason,
        room,
        showModal,
        history,
        returnPath,
      });
    },
    [room, showModal, history],
  );

  const handlePatientUpdate = useCallback(
    (p: LonelyPatient) => {
      const patientApi = api.patient(p.id);

      setPatient(p);

      setMedicalHistory();
      patientApi.getCurrentMedicalHistory().then(setMedicalHistory);

      patientApi.getSCP().then(setScp);
    },
    [api, setMedicalHistory, setPatient, setScp],
  );

  useVisitUpdateEvents(
    aVisit
      .getOptional()
      .map(v => [v.id])
      .orElse([]),
    visit => {
      console.log(`Update from visit ${visit.id}`);
      setVisit(visit);
    },
  );

  usePatientUpdateEvents(
    aVisit
      .getOptional()
      .map(v => [v.patient.id])
      .orElse([]),
    handlePatientUpdate,
  );

  useEffect(() => {
    async function getVisit() {
      addBreadcrumb({
        category: 'exam-room',
        message: 'Loading visit',
        data: {visitId},
        level: Severity.Info,
      });

      setHairChecked(visitId);

      const v = await api.visit(visitId).get();

      api
        .visit(visitId)
        .act(VisitAction.BEGIN)
        .catch(e => {
          console.error('Transition error: could not begin visit');
        });

      setVisit(v);

      if (v) {
        handlePatientUpdate(v.patient);
      }
    }

    getVisit().catch(e => {
      console.error('Startup Error');
      console.error(e);
      bail({
        e,
        message:
          'We hit an error getting the room set up. Please contact support for more assistance.',
        isSerious: true,
        getDestination,
        showModal,
        history,
      });
    });
  }, []);

  useEffect(() => {
    loadDocumentation(api)(visitId, setDocumentation).catch(e => {
      console.error('Startup Error');
      console.error(e);
      bail({
        e,
        message:
          'We hit an error getting the room set up. Please contact support for more assistance.',
        isSerious: true,
        getDestination,
        showModal,
        history,
      });
    });
  }, []);

  useEffect(() => {
    if (participants.length === 0) {
      setNotification(<>Waiting for participants...</>);
    } else {
      setNotification(null);
    }
  }, [participants]);

  useRecalculateVideoLayout();

  useEffect(() => {
    setUpRoom({
      visitId,
      token,
      audioIn,
      videoIn,
      audioOut,
      onMediaFailure: failure => setMediaFailure(failure),
      setRoom,
    });

    return () => {
      setRoom(currentRoom => {
        if (isRoomConnected({room: currentRoom})) {
          stopTracks({room: currentRoom});
          disconnectFromTwilioRoom({room: currentRoom});
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  useEffect(() => {
    if (isRoomConnected({room})) {
      onRoomConnected({
        room,
        setParticipants,
        setWaitingParticipants,
        setNotification,
        setShowReloadingModal,
        setIsDisconnecting,
      });
    }
  }, [room]);

  function handlePageLeave() {
    setIsDisconnecting(true);
    stopTracks({room});
    disconnectFromTwilioRoom({room});
    window.localStorage.removeItem('redirect');
  }

  useOnPageLeave(handlePageLeave);

  useEffect(() => {
    console.debug(`STATE CHANGE: ${visitState}`);
    if (visitState === VisitState.IN_PROGRESS) {
      setVisitOccurred(true);
    }

    if (EJECT_STATES.includes(visitState)) {
      eject({
        reason: `Ejecting ${userType} due to ${visitState} state`,
        returnPath: getDestination('permanent'),
      });
    }
  }, [eject, getDestination, setVisitOccurred, userType, visitState]);

  const error = DeadEndError(aVisit);

  if (error) {
    return error;
  }

  if (mediaFailure.orNull()) {
    return <Redirect to={`/waiting-room/${visitId}`} />;
  }

  return (
    <div
      className={videoStyles.examRoomContainer}
      style={{
        height: viewportHeight,
        backgroundColor: '#3a3a3e',
      }}
    >
      {showReloadingModal && (
        <Reconnecting
          visit={aVisit.getOptional()}
          bail={bail}
          onReconnect={() => {
            if (room) {
              disconnectFromTwilioRoom({
                room,
              });
            }

            reconnectToTwilioRoom({
              visit: aVisit.getOptional(),
              room,
              participants,
            });
          }}
          shouldShowReloadingModal={showReloadingModal}
          showModal={showModal}
          userType={userType}
        />
      )}

      <PageLoading
        fullSize={true}
        active={!room}
        className={classes.loading}
        message="Guiding you to the Exam Room"
      >
        <Header
          sidebarOpen={sidebarOpen}
          showDocumentation={showDocumentation}
          setSidebarOpen={setSidebarOpen}
          notification={notification}
          remoteParticipants={participants}
        />
        <Sidebar
          showModal={showModal}
          showDocumentation={showDocumentation}
          sidebarOpen={sidebarOpen}
          room={room}
          setDocumentation={setDocumentation}
          setShowDocumentation={setShowDocumentation}
          updateVisit={setVisit}
          visit={aVisit}
          participants={participants}
          medicalHistory={medicalHistory}
          setSidebarOpen={setSidebarOpen}
          scp={scp}
          documentation={documentation}
          disablePresentSCP={doNotTransmit({isDisconnecting, participants})}
        />

        <div
          id={sidebarOpen ? 'content-drawer-open' : 'content-drawer-closed'}
          className={
            sidebarOpen
              ? classes.contentDrawerOpen
              : classes.contentDrawerClosed
          }
        >
          <VideoGallery
            visitId={visitId}
            participants={participants}
            room={room}
            isDisconnecting={isDisconnecting}
            showDocumentation={showDocumentation}
            patient={patient}
            cutRoomAudio={cutRoomAudio}
            setNotification={setNotification}
            setParticipants={setParticipants}
            setWaitingParticipants={setWaitingParticipants}
            waitingParticipants={waitingParticipants}
            waitingRoomMessages={waitingRoomMessages}
          />
        </div>

        <ExamRoomModals
          onCancelPauseOrComplete={() => {
            setCutRoomAudio(false);
          }}
          modal={modal}
          showModal={showModal}
          visit={aVisit}
          exitToDocumentation={() => {
            eject({
              reason: `Ejecting ${userType} because they are progressing to documentation`,
              returnPath: `/documentation/${visitId}${window.location.search}`,
            });
          }}
          onLeavePermanently={() => {
            eject({
              reason: `Ejecting ${userType} because they manually left the visit permanently`,
              returnPath: getDestination('permanent'),
            });
          }}
          onPlanToReturn={() => {
            eject({
              reason: `Ejecting ${userType} because they manually paused the visit temporarily`,
              returnPath: getDestination('temporary'),
            });
          }}
          documentation={documentation}
        />
        <AdmitVisitorsModal
          room={room}
          waitingParticipants={waitingParticipants}
          setWaitingParticipants={setWaitingParticipants}
        />
      </PageLoading>
    </div>
  );
};

export const ExamRoom = reduxConnect(
  mapStateToProps,
  mapDispatchToProps,
)(_ExamRoom);
