import React from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import {ExpandableSection} from '../ExpandableSection';
import {Mergeable} from '../ExpandableSection/isMergeable/isSectionMergeable';
import {useStyles as useGeneralStyles} from '../shared/styles';
import {FailText} from '../shared/ExplanationText/FailText';
import {useStyles} from './styles';
import {VisitsTable} from './VisitsTable';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {getEmptyMergeOptions, MergeOptions} from '@src/models/Patient';

type Props = {
  mergeable: Mergeable;
  mergeOptions: AsyncData<MergeOptions>;
};

export function VisitsComparer({mergeable, mergeOptions}: Props) {
  const {visits} = mergeOptions.getOptional().orElse(getEmptyMergeOptions());
  const classes = {...useStyles(), ...useGeneralStyles()};

  return (
    <ExpandableSection
      title="Visits"
      mergeable={mergeable}
      mergeOptions={mergeOptions}
    >
      <Grid container direction="column">
        {mergeable === 'FAIL' && <FailText />}
        {mergeable === 'PASS' && (
          <Grid item className={classes.comparerMessage}>
            <ParagraphText style={{marginBottom: 0}}>
              All Visits can be merged automatically.
            </ParagraphText>
            {visits.length > 0 && (
              <ParagraphText style={{marginBottom: 0}}>
                Please review the list of visits below that will be included in
                the merge.
              </ParagraphText>
            )}
          </Grid>
        )}

        {visits.length > 0 && (
          <Grid item style={{width: 'fit-content'}}>
            <VisitsTable visits={visits} />
          </Grid>
        )}
      </Grid>
    </ExpandableSection>
  );
}
