import styled from 'styled-components';
import {sm, md, danger, grey} from '@src/components/ui/theme';

export const Form = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 75%;
  margin: 0 auto;
`;

export const TextInput = styled.input`
  margin: ${sm} 0;
  padding: ${sm};
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
`;

export const TextArea = styled.textarea`
  margin: ${sm} 0;
  padding: ${sm};
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
`;

export const Label = styled.label`
  width: 45%;
  display: block;
  margin-bottom: ${sm};
`;

export const ErrorMessage = styled.h1<{
  small?: boolean;
  danger?: boolean;
}>`
  font-size: ${md};
  margin: 0;
  color: ${props => (props.danger ? `${danger}` : `${grey}`)};
  font-weight: 300;
  text-align: center;
  line-height: 1.5em;
`;

export const RequiredWrapper = styled.span`
  position: relative;
  &::after {
    content: '*';
    padding-left: '1px';
    color: ${danger};
  }
`;
