import React from 'react';
import {Grid} from '@material-ui/core';
import {useStyles} from '../styles';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';

export function FailText() {
  const classes = useStyles();

  return (
    <Grid item className={classes.comparerMessage}>
      <ParagraphText>
        Unfortunately, the records cannot be merged through this process.
      </ParagraphText>{' '}
      <ParagraphText>
        Please contact support to resolve this issue.
      </ParagraphText>
    </Grid>
  );
}
