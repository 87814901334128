import {Divider, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import {StepProps} from '../../functions';
import {ProfessionalBasics} from '../ProfessionalInfoStep/ProviderInfoForm';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {FlowProps} from '@src/hooks/useSteps';
import {PhoneNumber} from '@src/components/ui/layout/PhoneNumber';
import {canonicalizeNumber} from '@src/util/numberManipulation/phone/canonicalizeNumber';
import {useUser} from '@src/hooks';
import {UserType, ProviderDetails} from '@src/models';
import {getProviderDescription} from '@src/util/provider';
import {
  getEmptyProviderDetails,
  ProviderDetailsId,
} from '@src/models/ProviderDetails';

const useStyles = makeStyles({
  contactCard: {
    marginTop: '.25rem',
  },
  divider: {
    marginTop: '1rem',
    marginBottom: '.5rem',
  },
  heading: {
    marginBottom: '.25rem',
  },
});

type Props = StepProps & FlowProps;

export function ConfirmStep({
  moveGuard,
  userBasics,
  setNextAvailable,
  email,
}: Props) {
  const classes = useStyles();
  const [, userType] = useUser();

  moveGuard.current = async () => true;
  setNextAvailable(true);

  const {firstName, lastName, phone} = userBasics;

  let name = `${firstName} ${lastName}`;
  if (userType === UserType.Professional) {
    const profBasics = userBasics as ProfessionalBasics;
    if (profBasics.seesPatients) {
      const info = new ProviderDetails({
        ...getEmptyProviderDetails(),
        ...profBasics,
        email,
        id: 0 as ProviderDetailsId,
      });
      name = getProviderDescription(info);
    }
  }

  return (
    <>
      <RequestHeader>Confirm</RequestHeader>
      <RequestInstructions>
        <p>Please review the details below.</p>
        <p>If everything looks right, click the Confirm button below.</p>
        <p>
          If you need to make any changes, you can do so by clicking Back and
          making the necessary changes.
        </p>
      </RequestInstructions>
      <Divider className={classes.divider} />

      <Typography
        variant="h2"
        className={classes.heading}
        style={{marginBottom: '1rem'}}
      >
        About You
      </Typography>
      <div>Name: {name}</div>
      <div>Email: {email.orElse('-')}</div>

      <div>
        Phone:{' '}
        <PhoneNumber no={canonicalizeNumber(phone)} blankFiller="Not given" />
      </div>
    </>
  );
}
