import React, {useCallback} from 'react';
import {Controller} from 'react-hook-form';
import {Common} from './Common';
import {SelectInput} from '@src/components/ui/form';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {useCreateClientConfigurationForm} from '@src/hooks/clientConfigurations/useCreateClientConfigurationForm';
import {useAll} from '@src/hooks/invoiceTargets/useAll';
import {FormContainer} from '@src/components/ui/molecules/layouts/FormContainer';

export function ClientConfigurationForm() {
  const [invoiceTargets, reloadInvoiceTargets] = useAll();

  const onCustomerCodeCreated = useCallback(async () => {
    await reloadInvoiceTargets();
  }, [reloadInvoiceTargets]);

  const {
    control,
    errors,
    formHandler,
    submitting,
  } = useCreateClientConfigurationForm(onCustomerCodeCreated);

  const submitButton = (
    <MuiAsyncActionButton
      bStyle="primary"
      bSize="normal"
      onClick={formHandler}
      actionInProgress={submitting}
      actionWord="Submit"
      disabled={submitting}
    >
      Submit
    </MuiAsyncActionButton>
  );

  return (
    <FormContainer submitButton={submitButton}>
      <Controller
        control={control}
        name="invoiceTargetId"
        render={({onChange, value, name}) => (
          <SelectInput
            required
            value={value}
            name={name}
            title="Customer"
            onChange={onChange}
            placeholder="Customer"
            options={invoiceTargets
              .getAllOptional()
              .orElse([])
              .map(target => ({
                label: target.customerName,
                value: `${target.id}`,
              }))}
            error={errors.invoiceTargetId?.message}
          />
        )}
      />

      <Common control={control} errors={errors} />
    </FormContainer>
  );
}
