import React, {lazy} from 'react';
import {ProtectedRoute} from '@src/components/shared/general/ProtectedRoute';
import {UserType} from '@src/models';

const AdminDashboard = lazy(() => import('../../adminSide/AdminDashboard'));

export function AdminRoutes() {
  return [
    <ProtectedRoute
      key="/management-dashboard"
      path="/management-dashboard"
      component={AdminDashboard}
      requiredUserType={[UserType.Admin, UserType.Professional]}
    />,
  ];
}
