import React, {useEffect, useState} from 'react';
import {format} from 'date-fns';
import {List, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';
import {useAsync} from '@src/hooks/useAsync';
import {SharedCarePlan, SCPRetraction} from '@src/models';
import {Modal} from '@src/components/ui/layout/Modal';
import {getProviderDescription} from '@src/util/provider/providerDescription';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {AsyncActionButton} from '@src/components/ui/form/AsyncActionButton';
import {UserAvatar} from '@src/components/ui/layout/UserAvatar';
import {MuiHeading} from '@src/components/ui/layout/MuiHeading';
import {AppAPI} from '@src/api/AppAPI';

const api = AppAPI.getInstance();

type Props = {
  scp: SharedCarePlan;
  patientName: string;
  setScp: (scp: SharedCarePlan) => unknown;
  setShowModal: (v: boolean) => unknown;
  show: boolean;
};

export const ReactivateModal = ({
  scp,
  patientName,
  setScp,
  show,
  setShowModal,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [retraction, setRetraction] = useAsync<SCPRetraction>();

  useEffect(() => {
    api
      .scp(scp.id)
      .getActiveRetraction()
      .then(r => {
        setRetraction(r);
      });
  }, [setRetraction, scp]);

  if (!retraction.isLoaded()) return null;

  const oRetraction = retraction.getOptional();

  const requesterInfo = retraction
    .getOptional()
    .map(r => r.requesterDetails)
    .orNothing();

  // "None provided" shouldn't ever end up being the value since reasoning is required.
  const reasoning = oRetraction.map(r => r.reasoning).orElse('None provided');
  const dateRetracted = oRetraction
    .map(r => format(r.createdAt, 'MM/dd/yyyy'))
    .orNothing();

  return (
    <>
      <Modal
        show={show}
        title="Are you sure you want to reactivate this Shared Care Plan?"
        onClose={() => {
          setShowModal(false);
        }}
        modalActions={
          <AsyncActionButton
            actionInProgress={isSubmitting}
            actionWord={'Reactivate'}
            disabled={!reasoning}
            bStyle="primary"
            onClick={() => {
              setIsSubmitting(true);

              api
                .scp(scp.id)
                .unretractSCP(oRetraction.map(r => r.id).orElse(0))
                .then(newScp => {
                  setScp(newScp);
                  setShowModal(false);
                })
                .finally(() => {
                  setIsSubmitting(false);
                });
            }}
          />
        }
      >
        <ParagraphText>
          Another medical professional has retracted this Shared Care Plan
          because they saw incorrect information that they thought would place{' '}
          {patientName} in a position of significant risk were another provider
          to act on that information while caring for {patientName}.
        </ParagraphText>
        <ParagraphText style={{fontWeight: 700}}>
          Please address the issue below before reactivating the Shared Care
          Plan.
        </ParagraphText>
        <List>
          <ListItem style={{paddingLeft: 0, paddingTop: 0}}>
            <MuiHeading variant="subtitle2" style={{marginBottom: 0}}>
              Requester:
            </MuiHeading>
          </ListItem>
          <ListItem style={{paddingLeft: 0, paddingTop: 0}}>
            {requesterInfo && (
              <ListItemAvatar>
                <UserAvatar style={{margin: 0}} user={requesterInfo} />
              </ListItemAvatar>
            )}
            <ListItemText
              secondary={
                requesterInfo ? getProviderDescription(requesterInfo) : ''
              }
            />
          </ListItem>
          <ListItem style={{paddingLeft: 0, paddingTop: 0}}>
            <ListItemText
              style={{whiteSpace: 'normal', wordWrap: 'break-word'}}
              primary={
                <MuiHeading variant="subtitle2" style={{marginBottom: 0}}>
                  Reasoning:
                </MuiHeading>
              }
              secondary={reasoning}
            />
          </ListItem>

          {dateRetracted && (
            <ListItem style={{paddingLeft: 0, paddingTop: 0}}>
              <ListItemText
                primary={
                  <MuiHeading variant="subtitle2" style={{marginBottom: 0}}>
                    Date Retracted:
                  </MuiHeading>
                }
                secondary={dateRetracted}
              />
            </ListItem>
          )}
        </List>
      </Modal>
    </>
  );
};
