import {flatten, uniqBy} from 'lodash';
import {CallPool, OnCallPeriod, ProviderDetails} from '@src/models';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {isSomething} from '@src/util/typeTests';

export function getProvidersNotAuthorizedForGroups(
  callPools: CallPool[],
  period: OnCallPeriod,
) {
  const periodProviderDetails = period.providers.map(m => m.providerDetails);

  const callPoolMembers = uniqBy(
    flatten(callPools.map(cp => cp.providerMemberships.orElse([]))),
    'id',
  );

  const callPoolMemberIds = callPoolMembers.map(m => m.providerDetailsId);

  return periodProviderDetails
    .map(pd => {
      const isInOnePool = callPoolMemberIds.some(
        id => id === pd.property('id', 0 as ProviderDetailsId),
      );
      if (!isInOnePool) {
        return pd;
      } else {
        return null;
      }
    })
    .filter(isSomething);
}

export function isProviderAuthorizedForPeriod(
  providerDetails: ProviderDetails,
  period: OnCallPeriod,
) {
  return period.callPools.every(group => {
    return group.providerMemberships
      .orElse([])
      .some(m => m.providerDetailsId === providerDetails.id);
  });
}

export function isMemberOfSomeGroups(
  providerDetails: ProviderDetails,
  callPools: readonly CallPool[],
) {
  const memberships = uniqBy(
    flatten(callPools.map(group => group.providerMemberships.orElse([]))),
    'id',
  );

  return memberships.some(g => g.providerDetailsId === providerDetails.id);
}
