import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  deleteIcon: {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
  partnershipsIcon: {
    color: theme.palette.primary.main,
  },
}));
