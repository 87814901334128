import clsx from 'clsx';
import {Menu, MenuItem} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {useDecision} from '@optimizely/react-sdk';
import React, {useState, useCallback, lazy, Suspense} from 'react';
import {useHistory} from 'react-router';
import EditPatientMedicalHistory from './EditPatientMedicalHistory';
import {useStyles} from './layout';
import {
  Invitation,
  Guardian,
  PatientRelationship,
  UserType,
  LonelyPatient,
} from '@src/models';
import {isNothing} from '@src/util/typeTests';
import {unlinkGuardianOrSelf} from '@src/util/relationships/guardianOrIndependent';
import {useApi} from '@src/api/useApi';
import {useUser} from '@src/hooks/useUser';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';
import {Features} from '@src/features';

const ConfirmUnlinkModal = lazy(() =>
  import('@src/components/shared/SimplePatientCard/ConfirmUnlinkModal'),
);
const SelectMergePatientModal = lazy(() => import('./SelectMergePatientModal'));

type UnlinkDetails = {
  currentInvitations: readonly Invitation[];
  onRemovePatient: (i: number) => void;
  selfRelationship: PatientRelationship;
};

type Props = {
  className?: string;
  patient: LonelyPatient;
  updatePatient: (patient: LonelyPatient) => void;
  unlinkDetails?: UnlinkDetails;
};

export function PatientActionMenu({
  className,
  patient,
  updatePatient,
  unlinkDetails,
}: Props) {
  const api = useApi();
  const [user, userType] = useUser();
  const classes = useStyles();

  const history = useHistory();
  const [mergePatients] = useDecision(Features.MergePatients);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [unlinkingInProgress, setUnlinkingInProgress] = useState(false);

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setShowConfirmModal(false);
  }, []);

  const userPersonId = user
    .getOptional()
    .cast<Guardian>(us => us.userType === UserType.Guardian)
    .map(g => g.responsiblePersonDetails)
    .map(r => r.id)
    .orElse(0 as NonProfessionalId);

  return (
    <>
      <MoreHorizIcon
        onClick={handleClick}
        className={clsx(className, classes.menuIcon)}
      />

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {userType === UserType.Guardian && (
          <MenuItem
            onClick={() => {
              history.push(`/patient/${patient.id}`);
            }}
          >
            View Details
          </MenuItem>
        )}
        {userType === UserType.Guardian && (
          <MenuItem
            onClick={() => {
              history.push(`/files-overview/${patient.id}`);
            }}
          >
            View Files
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            history.push(`/edit-patient/${patient.id}`);
          }}
        >
          Edit Patient
        </MenuItem>

        <MenuItem
          onClick={() => {
            history.push(`/insurance-overview/${patient.id}`);
          }}
        >
          Edit Insurance
        </MenuItem>

        <EditPatientMedicalHistory
          {...{patient}}
          onUpdateHistory={mh => {
            if (!isNothing(mh)) {
              patient.medicalHistoryVersion = mh.versionNumber;
              updatePatient(patient);
            }
          }}
          handleClose={handleClose}
        />
        {mergePatients.enabled && (
          <MenuItem
            onClick={() => {
              setShowMergeModal(true);
              handleClose();
            }}
          >
            Merge with another record
          </MenuItem>
        )}
        {!isNothing(unlinkDetails) && (
          <MenuItem
            onClick={() => {
              setShowConfirmModal(true);
            }}
          >
            Deactivate
          </MenuItem>
        )}
      </Menu>

      {mergePatients.enabled && (
        <Suspense fallback={<div>Loading...</div>}>
          <SelectMergePatientModal
            show={showMergeModal}
            onClose={() => setShowMergeModal(false)}
            currentPatientId={patient.id}
          />
        </Suspense>
      )}

      {!isNothing(unlinkDetails) && showConfirmModal && (
        <Suspense fallback={<div>Loading...</div>}>
          <ConfirmUnlinkModal
            currentInvitations={unlinkDetails.currentInvitations}
            updatePatient={p => {}}
            show={showConfirmModal}
            onHide={handleClose}
            onCancel={handleClose}
            onConfirm={() => {
              unlinkGuardianOrSelf({
                api,
                setUnlinkingInProgress,
                relationship: unlinkDetails.selfRelationship,
                onRemovePatient: i => unlinkDetails.onRemovePatient(i),
                patient,
                userPersonId,
              }).then(handleClose);
            }}
            unlinkingInProgress={unlinkingInProgress}
            relationship={unlinkDetails.selfRelationship}
            guardiansCount={0}
            patient={patient}
          />
        </Suspense>
      )}
    </>
  );
}
