import {RemoteDataStatus} from '@ahanapediatrics/ahana-fp';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import React, {useCallback} from 'react';
import {SeePatientLink} from '../oncall/SeePatientLink';
import {ReloadVisitAction} from './VisitActions';
import {ActionsContextMenu} from './VisitActions/ActionsContextMenu';
import {VisitColumn} from './ColumnSets';
import {ActionCell, LoadingMessage, useStyles} from './layout';
import {DesktopAndMobileTableProps} from '.';
import {useCurrentTime} from '@src/hooks/useCurrentTime';

export function DesktopTable({
  columns,
  directToRoom,
  hairChecked,
  onCancel,
  onShareLink,
  onUpdate,
  returnText,
  visits,
}: DesktopAndMobileTableProps) {
  const classes = useStyles();

  const currentTime = useCurrentTime();

  const columnMapper = useCallback(
    visit => (column: VisitColumn) => (
      <TableCell key={column.id || column.name}>
        {column.data(visit, {currentTime})}
      </TableCell>
    ),
    [currentTime],
  );

  return (
    <TableContainer className={classes.desktopTableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {columns.map(column => (
              <TableCell key={column.name}>{column.name}</TableCell>
            ))}
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {!visits.is(RemoteDataStatus.Success) && (
            <TableRow>
              <LoadingMessage colSpan={columns.length + 1}>
                Loading visits <FontAwesomeIcon icon={'spinner'} spin />
              </LoadingMessage>
            </TableRow>
          )}
          {visits.is(RemoteDataStatus.Success) && visits.value().length === 0 && (
            <TableRow>
              <TableCell colSpan={100}>No visits</TableCell>
            </TableRow>
          )}
          {visits.is(RemoteDataStatus.Success) &&
            visits.value().length > 0 &&
            visits
              .sort((a, b) => +a.start - +b.start)
              .map(visit => {
                return (
                  <TableRow key={`${visit.id}-details`}>
                    <TableCell>
                      <SeePatientLink
                        directToRoom={directToRoom}
                        hairChecked={hairChecked[visit.id]}
                        visit={visit}
                      />
                    </TableCell>
                    {columns.map(columnMapper(visit))}
                    <ActionCell>
                      <ReloadVisitAction onUpdate={onUpdate} visit={visit} />
                    </ActionCell>
                    <TableCell>
                      <ActionsContextMenu
                        onCancel={onCancel}
                        onShareLink={onShareLink}
                        onUpdate={onUpdate}
                        returnText={returnText}
                        visit={visit}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
