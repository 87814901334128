import {makeStyles} from '@material-ui/core';
import {sm} from '@src/components/ui/theme';

export const useStyles = makeStyles(() => ({
  visitDetail: {
    paddingBottom: sm,
  },
  gridCell: {
    maxHeight: 'none',
  },
}));
