import React, {useState, useCallback} from 'react';
import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';
import {Typography} from '@material-ui/core';
import {isEmpty} from '@src/util/typeTests';
import {SearchByProvider} from '@src/components/ui/form/search/SearchByProvider';
import {CareTeamCard} from '@src/components/ui/molecules/cards/professionals/CareTeamCard';
import {getProviderDescription} from '@src/util/provider/providerDescription';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {ProviderDetails, ProviderPartner, User, UserType} from '@src/models';
import {ResourceConflictException} from '@src/api/exceptions';
import {useApi} from '@src/api/useApi';
import {UserId} from '@src/models/User';

type Props = {
  setPartners: (ps: ProviderPartner[]) => unknown;
  user: AsyncData<User>;
  onSubmitted: () => unknown;
};

export function AddPartner({setPartners, user, onSubmitted}: Props) {
  const api = useApi();
  const [partnerDetails, setDetails] = useState<Optional<ProviderDetails>>(
    Optional.empty(),
  );
  const searchable = partnerDetails?.map(p => p.searchable).orElse(false);

  const [showDetails, setShowDetails] = useState(
    !isEmpty(partnerDetails.property('lastName', '')),
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const knownProvider = showDetails && searchable;
  const selectedUser = partnerDetails.property('fullName', '');

  const onAddPartner = useCallback(() => {
    const partnerId = partnerDetails
      .map(info => info.providerId)
      .orElse(0 as UserId);

    if (user.singleValue().id === partnerId) {
      flashError(
        'You cannot add yourself as a partner. Please choose another user and try again.',
      );

      return;
    }

    if (user.isLoaded()) {
      setIsSubmitting(true);
      const providerAPI = api.provider(user.singleValue().id);
      providerAPI
        .addPartner(partnerId)
        .then(ps => {
          setPartners(ps);
          onSubmitted();
          flashSuccess('Partner added!');
        })
        .catch(e => {
          const message =
            e instanceof ResourceConflictException
              ? e.message
              : 'There was a problem adding the partner. Please try again or contact support.';

          flashError(message);
        })
        .finally(() => setIsSubmitting(false));
    }
  }, [api, onSubmitted, partnerDetails, setPartners, user]);

  return (
    <>
      <Typography variant="caption" style={{fontStyle: 'italic'}}>
        You can allow other Refyne Connected Care providers to accept edits on
        your behalf for all of the Shared Care Plans that you own.
      </Typography>

      <div style={{marginTop: '1rem'}}>
        <SearchByProvider
          name="provider"
          userType="Provider"
          title="Search for a medical professional who will act on your behalf"
          placeholder="Search..."
          disabled={false}
          selectedUser={selectedUser}
          selectUser={p => {
            setDetails(Optional.of(p));
            setShowDetails(true);
          }}
          renderUser={u =>
            u ? <div>{getProviderDescription(u)}</div> : <div />
          }
          touched={false}
        />
        <div hidden={!knownProvider}>
          <CareTeamCard
            canEdit={false}
            includeMissing={false}
            details={partnerDetails.orNull()}
            onEdit={() => {}}
            viewerType={UserType.Professional}
          />
        </div>
      </div>

      <MuiAsyncActionButton
        bStyle="primary"
        bSize="small"
        onClick={onAddPartner}
        actionInProgress={isSubmitting}
        actionWord="Save"
      >
        Add Partner
      </MuiAsyncActionButton>
    </>
  );
}
