import styled from 'styled-components';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';
import {
  danger,
  dangerDark,
  dangerLightest,
  grey,
  lightGrey,
  primary,
  primaryDark,
  primaryLight,
  xs,
} from '@src/components/ui/theme';

export const SystemsInputStyled = styled.div`
  margin: 0;
  padding: ${xs};
  width: 100%;
  textarea {
    width: 100%;
    margin: 0;
  }
  display: flex;
  flex-direction: column;
`;

export const Selection = styled.div`
  padding-left: ${xs};
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

export const Selector = styled(ClickableIcon)``;

export const SystemState = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${Selector} {
    padding: ${xs};
  }
`;

export const NegativeReview = styled(Selector)`
  color: ${primary};
  &:hover,
  &.selected {
    color: ${primaryDark};
  }
  &.not-selected {
    color: ${primaryLight};
    &:hover {
      color: ${primaryDark};
    }
  }
`;

export const PositiveReview = styled(Selector)`
  color: ${danger};
  &:hover,
  &.selected {
    color: ${dangerDark};
  }
  &.not-selected {
    color: ${dangerLightest};
    &:hover {
      color: ${dangerDark};
    }
  }
`;

export const ClearReview = styled(Selector)`
  visibility: hidden;
  color: ${lightGrey};
  &:hover {
    color: ${grey};
  }

  &.visible {
    visibility: visible;
  }
`;
