import React from 'react';
import {SCPReview} from '@src/components/shared/PatientDashboard/SCP/SCPReview';
import {ScpResources, useScpResources} from '@src/hooks/scp/useScpResources';
import {createGenericContext} from '@src/util/reactContext/createGenericContext';

const [usePageContext, PageContextProvider] = createGenericContext<
  ScpResources
>();

export function ScpPage() {
  const scpResources = useScpResources();

  return (
    <PageContextProvider value={scpResources}>
      <SCPReview />
    </PageContextProvider>
  );
}

export {usePageContext};
