export function setDefaultTab(
  hasCategory: boolean[],
  setTab: (tabIndex: number) => unknown,
) {
  const idx = hasCategory.indexOf(true);

  if (idx >= 0) {
    setTab(idx);
  } else {
    setTab(0);
  }
}
