import React, {useEffect} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useTheme} from '@material-ui/core/styles';
import {ExpandableSection} from '../ExpandableSection';
import {
  isSectionMergeable,
  Mergeable,
} from '../ExpandableSection/isMergeable/isSectionMergeable';
import {ComparerMessage} from '../shared/ComparerMessage';
import {SelectOne} from '../shared/SelectOne';
import {autoSelectPcp} from './utils/autoSelectPcp';
import {Patient, ProviderDetails, UserType} from '@src/models';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {isNothing} from '@src/util/typeTests';
import {CareTeamCard} from '@src/components/ui/molecules/cards/professionals/CareTeamCard';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';

type Props = {
  patientOne: AsyncData<Patient>;
  patientTwo: AsyncData<Patient>;
  setMergeable: (mergeable: Mergeable) => unknown;
  mergeable: Mergeable;
  setPcpResult: (r: ProviderDetails | null) => unknown;
  pcpResult: ProviderDetails | null;
};

export function PrimaryCareComparer({
  patientOne,
  patientTwo,
  setMergeable,
  mergeable,
  setPcpResult,
  pcpResult,
}: Props) {
  const theme = useTheme();

  const pcp1 = patientOne
    .getOptional()
    .map(p1 => p1.pcp)
    .orNull();

  const pcp2 = patientTwo
    .getOptional()
    .map(p2 => p2.pcp)
    .orNull();

  useEffect(() => {
    setMergeable(
      isSectionMergeable({
        p1: patientOne,
        p2: patientTwo,
        sectionName: 'primaryCare',
      }),
    );
  }, [patientOne, patientTwo, setMergeable]);

  useEffect(() => {
    const shouldAutoSelectPcp = mergeable === 'PASS' && pcpResult === null;

    if (shouldAutoSelectPcp) {
      const newResult = autoSelectPcp({
        pcp1,
        pcp2,
      });

      if (newResult !== pcpResult) {
        setPcpResult(newResult);
      }
    }
  }, [mergeable]);

  const optionOneCard = (
    <CareTeamCard
      isLoading={false}
      canEdit={false}
      includeMissing={false}
      details={pcp1}
      onEdit={() => {}}
      viewerType={UserType.Professional}
      containerStyles={{padding: 0}}
      cardStyles={
        !isNothing(pcpResult) && pcpResult.id === pcp1?.id
          ? {
              borderColor: theme.palette.success.main,
              borderWidth: 'thick',
            }
          : {}
      }
    />
  );

  const optionTwoCard = (
    <CareTeamCard
      isLoading={false}
      canEdit={false}
      includeMissing={false}
      details={pcp2}
      onEdit={() => {}}
      viewerType={UserType.Professional}
      containerStyles={{padding: 0}}
      cardStyles={
        !isNothing(pcpResult) && pcpResult.id === pcp2?.id
          ? {
              borderColor: theme.palette.success.main,
              borderWidth: 'thick',
            }
          : {}
      }
    />
  );

  return (
    <ExpandableSection title="Primary Care Provider" mergeable={mergeable}>
      {(mergeable === 'CONFLICT' || mergeable === 'RESOLVED') && (
        <SelectOne
          patientOne={patientOne}
          patientTwo={patientTwo}
          cards={{
            optionOneCard,
            optionTwoCard,
          }}
          optionOneSelected={!isNothing(pcpResult) && pcpResult.id === pcp1?.id}
          optionTwoSelected={!isNothing(pcpResult) && pcpResult.id === pcp2?.id}
          onSelectOptionOne={() => {
            setPcpResult(pcp1);
            setMergeable('RESOLVED');
          }}
          onSelectOptionTwo={() => {
            setPcpResult(pcp2);
            setMergeable('RESOLVED');
          }}
          instructions={[
            <ParagraphText>
              The current Patient records have different Primary Care Providers.
            </ParagraphText>,
            <ParagraphText>
              Please select which Primary Care Provider the <Bold>merged</Bold>{' '}
              Patient should have.
            </ParagraphText>,
          ]}
        />
      )}
      {mergeable === 'PASS' && (
        <ComparerMessage>
          <ParagraphText style={{marginBottom: 0}}>
            The Primary Care Provider can be merged automatically.
          </ParagraphText>
        </ComparerMessage>
      )}
    </ExpandableSection>
  );
}
