import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import {StepProps} from '../../functions';
import {UserInfoForm, NonProfessionalBasics, schema} from './UserInfoForm';
import {FlowProps} from '@src/hooks/useSteps';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {canonicalizeNumber} from '@src/util/numberManipulation/phone/canonicalizeNumber';

type Props = FlowProps & StepProps;

export function NonProfessionalInfoStep({
  moveGuard,
  userBasics,
  setUserBasics,
  setNextAvailable,
}: Props) {
  const {formState, control, errors, handleSubmit, watch} = useForm<
    NonProfessionalBasics
  >({
    defaultValues: userBasics,
    resolver: yupResolver(schema),
  });

  const allFields = watch();

  useEffect(() => {
    setNextAvailable(
      allFields.firstName.trim().length > 0 &&
        canonicalizeNumber(allFields.phone.trim()).length === 10 &&
        allFields.lastName.trim().length > 0,
    );
  }, [allFields, setNextAvailable]);

  moveGuard.current = () =>
    new Promise(res => {
      handleSubmit(
        data => {
          setUserBasics(data);
          res(true);
        },
        () => {
          res(false);
        },
      )();
    });

  return (
    <>
      <RequestHeader>Your Details</RequestHeader>
      <RequestInstructions>
        <p>Please let us know your name and phone number.</p>
        <p>
          This will let us address you correctly and provide a backup form of
          communication.
        </p>
      </RequestInstructions>
      <UserInfoForm formState={formState} errors={errors} control={control} />
    </>
  );
}
