import {Grid} from '@material-ui/core';
import React from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {YesOrNo} from '../../../ui/form';
import {ParagraphText} from '../../../ui/layout/text/body/ParagraphText';
import {InvitePerson} from '../../patient/relationships/InvitePerson';
import {conjugate} from '../../../../util/stringManipulation/languageHelpers';
import {Invitation, LonelyPatient} from '@src/models';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  onHide: () => void;
  patient: LonelyPatient;
  inviteNewGuardian: boolean;
  setInviteNewGuardian: (v: boolean) => unknown;
  userIsConnected: boolean;
  updatePatient: (p: LonelyPatient) => void;
  currentInvitations: readonly Invitation[];
};

export function LastGuardianText({
  currentInvitations,
  onConfirm,
  onCancel,
  onHide,
  patient,
  inviteNewGuardian,
  setInviteNewGuardian,
  userIsConnected,
  updatePatient,
}: Props) {
  return (
    <Grid container direction="row" style={{marginTop: '1rem'}}>
      <>
        <ParagraphText>
          <b>
            {userIsConnected ? 'You' : 'This'}{' '}
            {conjugate(userIsConnected)('be')} the only Connected Login.
          </b>{' '}
          Before disconnecting, would you like to invite another guardian to
          seek healthcare for this patient?
        </ParagraphText>
        <YesOrNo
          value={Optional.of(inviteNewGuardian)}
          setValue={v => {
            setInviteNewGuardian(v);
          }}
        />
      </>

      {inviteNewGuardian && (
        <Grid container direction="row" style={{marginTop: '1rem'}}>
          <InvitePerson
            currentInvitations={currentInvitations}
            currentRelationships={[]}
            patientId={patient.id}
            patientName={patient.preferredFirstName}
            onHide={() => {
              onConfirm();
              onHide();
              onCancel();
            }}
            onInvitationCreated={updatePatient}
            show={true}
            mode="form"
            submitButtonText={{
              actionWord: 'Inviting and Unlinking',
              text: 'Invite and Unlink',
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}
