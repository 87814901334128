import {useMemo, useState} from 'react';
import * as yup from 'yup';
import {Control, DeepMap, FieldError, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import {useApi} from '@src/api/useApi';
import {FormHandler} from '@src/hooks/forms';
import {yBoolean, yString} from '@src/schema/types';
import {NAMES} from '@src/components/ui/layout/text/names';
import {getRequiredMessage} from '@src/util/forms/getRequiredMessage';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {ResourceConflictException} from '@src/api/exceptions';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';

type FormResources = {
  submitting: boolean;
  formHandler: FormHandler;
  control: Control<CreateCustomerCodeValues>;
  errors: DeepMap<CreateCustomerCodeValues, FieldError>;
  allFields: CreateCustomerCodeValues;
};

export type CreateCustomerCodeValues = {
  logoutDestination?: string;
  onDemand?: boolean;
  scheduled?: boolean;
  customerCode: string;
  invoiceTargetId: string;
};

function getSchema() {
  return yup.object().shape({
    logoutDestination: yString,
    onDemand: yBoolean,
    scheduled: yBoolean,
    customerCode: yString.required(
      getRequiredMessage('a', `${toTitleCase(NAMES.customerCode)}`),
    ),
    invoiceTargetId: yString.required(
      getRequiredMessage('a', `${toTitleCase(NAMES.customer)}`),
    ),
  });
}

export function useCreateClientConfigurationForm(
  onCreated: () => Promise<unknown>,
  invoiceTargetId?: number,
): FormResources {
  const api = useApi();

  const schema = getSchema();

  const [submitting, setSubmitting] = useState(false);

  const {control, handleSubmit, watch, errors, reset} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      logoutDestination: '',
      onDemand: false,
      scheduled: false,
      // This is a string because of some edge cases in the way
      // the SelectInput displays the placeholder and error messages
      // if null, 0, or NaN is provided.
      invoiceTargetId: invoiceTargetId ? `${invoiceTargetId}` : '',
      customerCode: '',
    },
  });

  const allFields = watch();

  const formHandler = handleSubmit(data => {
    const {
      logoutDestination,
      onDemand = false,
      scheduled = false,
      customerCode,
      invoiceTargetId: selectedInvoiceTargetId,
    } = data;

    setSubmitting(true);

    api
      .clientConfigurations()
      .create({
        logoutDestination,
        onDemand,
        scheduled,
        invoiceTargetId: invoiceTargetId ?? parseInt(selectedInvoiceTargetId),
        customerCode,
      })
      .then(() => {
        reset();
        setSubmitting(false);
        onCreated();
        flashSuccess('Success');
      })
      .catch(e => {
        let errorMessage = 'Something went wrong';
        if (e instanceof ResourceConflictException) {
          errorMessage = `That ${toTitleCase(
            NAMES.customerCode,
          )} already exists. Each  ${toTitleCase(
            NAMES.customerCode,
          )} must be unique. Please change it and try again.`;
        }

        flashError(errorMessage, {permanent: true});
      })
      .finally(() => {
        setSubmitting(false);
      });
  });

  return useMemo(
    () => ({
      submitting,
      formHandler,
      control,
      errors,
      allFields,
    }),
    [submitting, formHandler, control, errors, allFields],
  );
}
