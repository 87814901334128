import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {format} from 'date-fns';
import {Field} from './resultFunctions';
import {Patient} from '@src/models';

export type BasicDetailsUIElement = {
  label: string;
  name: Field;
  patientOne: string;
  patientTwo: string;
};

export function getBasicDetailsRows({
  patientOne,
  patientTwo,
}: {
  patientOne: AsyncData<Patient>;
  patientTwo: AsyncData<Patient>;
}): BasicDetailsUIElement[] {
  const oPatientOne = patientOne.getOptional();
  const oPatientTwo = patientTwo.getOptional();

  return [
    {
      label: 'First Name',
      name: 'firstName',
      patientOne: oPatientOne.map(p => p.firstName).orElse('—') || '—',
      patientTwo: oPatientTwo.map(p => p.firstName).orElse('—') || '—',
    },
    {
      label: 'Last Name',
      name: 'lastName',
      patientOne: oPatientOne.map(p => p.lastName).orElse('—') || '—',
      patientTwo: oPatientTwo.map(p => p.lastName).orElse('—') || '—',
    },
    {
      label: 'Preferred Name',
      name: 'nickName',
      patientOne: oPatientOne.map(p => p.nickName).orElse('—') || '—',
      patientTwo: oPatientTwo.map(p => p.nickName).orElse('—') || '—',
    },
    {
      label: 'Date of Birth',
      name: 'dob',
      patientOne:
        oPatientOne.map(p => format(p.dob, 'MMMM dd yyyy')).orElse('—') || '—',
      patientTwo:
        oPatientTwo.map(p => format(p.dob, 'MMMM dd yyyy')).orElse('—') || '—',
    },
    {
      label: 'Phone',
      name: 'phone',
      patientOne: oPatientOne.map(p => p.phone).orElse('—') || '—',
      patientTwo: oPatientTwo.map(p => p.phone).orElse('—') || '—',
    },
    {
      label: 'Gender Assigned at Birth',
      name: 'genderAssignedAtBirth',
      patientOne:
        oPatientOne.map(p => p.genderAssignedAtBirth || null).orElse('—') ||
        '—',
      patientTwo:
        oPatientTwo.map(p => p.genderAssignedAtBirth || null).orElse('—') ||
        '—',
    },
    {
      label: 'Pronouns',
      name: 'pronouns',
      patientOne: oPatientOne.map(p => p.pronouns).orElse('—') || '—',
      patientTwo: oPatientTwo.map(p => p.pronouns).orElse('—') || '—',
    },
  ];
}
