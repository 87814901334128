import {Grid, Divider, IconButton} from '@material-ui/core';
import {TreeItem, TreeView} from '@material-ui/lab';
import React, {useCallback, useMemo} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CallPool} from '@src/models';
import {
  CallPoolOption,
  getAvailableChildOptions,
  sortCallPools,
} from '@src/util/callPools/sortCallPools';
import {isNothing} from '@src/util/typeTests';
import {getCallPoolName} from '@src/util/callPools/getCallPoolName';
import {useUser} from '@src/hooks/useUser';
import {WarningText} from '@src/components/ui/layout/text/body/WarningText';
import {TriStateCheckbox} from '@src/components/providerSide/TriStateCheckbox';

type Props = {
  groups: readonly CallPool[];
  selectedGroups: number[];
  setSelectedGroups: (cp: number[]) => unknown;
  selectAll?: boolean;
  requiredGroupsSelections?: number[];
};

export function ProviderGroupSelector({
  groups,
  selectedGroups,
  setSelectedGroups,
  selectAll = false,
  requiredGroupsSelections = [],
}: Props) {
  const [, userType] = useUser();

  const sortedProviderGroups = useMemo(() => sortCallPools(groups), [groups]);
  const optionList = [...sortedProviderGroups.values()];

  const selectOption = useCallback(
    (option: CallPoolOption) => {
      if (selectedGroups.includes(option.id)) {
        setSelectedGroups(selectedGroups.filter(s => s !== option.id));
      } else {
        setSelectedGroups([...selectedGroups, option.id]);
      }
    },
    [selectedGroups, setSelectedGroups],
  );

  const selectChildren = useCallback(
    (option: CallPoolOption) => {
      const children = getAvailableChildOptions(option, sortedProviderGroups);
      if (children.length === 0) {
        return;
      }
      const childIds = children.map(c => c.id);
      const allChildrenSelected = childIds.every(i =>
        selectedGroups.includes(i),
      );

      if (allChildrenSelected) {
        setSelectedGroups(selectedGroups.filter(s => !childIds.includes(s)));
      } else {
        setSelectedGroups([...selectedGroups, ...childIds]);
      }
    },
    [sortedProviderGroups, selectedGroups, setSelectedGroups],
  );

  if (optionList.length === 0) {
    return (
      <WarningText warningVariant="warning">
        You don't belong to any provider groups, so you can't create an on call
        period.
      </WarningText>
    );
  }

  return (
    <>
      {selectAll && (
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <TriStateCheckbox
              possible={groups.map(g => g.id)}
              selection={selectedGroups}
              onClick={isSelect => {
                if (isSelect) {
                  setSelectedGroups(groups.map(g => g.id));
                } else {
                  setSelectedGroups([]);
                }
              }}
            />
          </Grid>
          <Grid item>Select all</Grid>
        </Grid>
      )}
      {selectAll && <Divider />}
      <TreeView selected={selectedGroups.map(String)} multiSelect={true}>
        {optionList
          .filter(
            value =>
              (value.available || value.children.length !== 0) &&
              isNothing(value.parent),
          )
          .map(value => {
            const children = getAvailableChildOptions(
              value,
              sortedProviderGroups,
            );

            const allChildrenSelected = children.every(c =>
              selectedGroups.includes(c.id),
            );
            const someChildrenSelected = children.some(c =>
              selectedGroups.includes(c.id),
            );

            const groupSelectionRequired = requiredGroupsSelections.includes(
              value.id,
            );

            return (
              <TreeItem
                key={value.id}
                nodeId={`${value.id}`}
                label={`${getCallPoolName(value, userType)}${
                  children.length > 0 ? ' (click for more)' : ''
                }`}
                icon={
                  <IconButton disabled={groupSelectionRequired}>
                    <FontAwesomeIcon
                      icon={[
                        'far',
                        selectedGroups.includes(value.id) ||
                        (children.length > 0 && allChildrenSelected)
                          ? 'check-square'
                          : someChildrenSelected
                          ? 'minus-square'
                          : 'square',
                      ]}
                      color="primary"
                    />{' '}
                  </IconButton>
                }
                onLabelClick={() => {
                  if (value.available && !groupSelectionRequired) {
                    selectOption(value);
                  }
                }}
                onIconClick={e => {
                  e.preventDefault();
                  if (value.available && !groupSelectionRequired) {
                    selectOption(value);
                  } else {
                    selectChildren(value);
                  }
                }}
              >
                {children.map(child => {
                  return (
                    <TreeItem
                      key={child.id}
                      nodeId={`${child.id}`}
                      label={getCallPoolName(child, userType)}
                      icon={
                        <FontAwesomeIcon
                          icon={[
                            'far',
                            selectedGroups.includes(child.id)
                              ? 'check-square'
                              : 'square',
                          ]}
                          color="primary"
                        />
                      }
                      onIconClick={e => {
                        e.preventDefault();
                        selectOption(child);
                      }}
                    />
                  );
                })}
              </TreeItem>
            );
          })}
      </TreeView>
    </>
  );
}
