import React, {ChangeEventHandler} from 'react';
import {SelectInput} from '../../form';
import {TIME_ZONES} from '@src/util/constants';

type Props = {
  handleChangeTimeZone: ChangeEventHandler;
  timeZone: string;
  required?: boolean;
  errorMessage?: string;
};

export function TimeZoneDropdown({
  handleChangeTimeZone,
  timeZone,
  required,
  errorMessage,
}: Props) {
  return (
    <SelectInput
      required={required}
      value={timeZone}
      name={'Time Zone'}
      title="Time Zone"
      onChange={handleChangeTimeZone}
      placeholder="Time Zone for this Group"
      options={TIME_ZONES.map(t => ({label: t, value: t}))}
      error={errorMessage}
    />
  );
}
