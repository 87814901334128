import React, {useState, useCallback} from 'react';
import {VisitActionButton} from '../layout';
import {useAssignProfessional} from './useAssignProfessional';
import {LonelyVisit} from '@src/models';

import {AssignProfessionalModal} from '@src/components/shared/VideoChat/ExamRoomModals/AssignProfessionalModal';

type AssignProfessionalProps = {
  onUpdate: (v: LonelyVisit) => unknown;
  returnText?: string;
  visit: LonelyVisit;
};
export function AssignProfessional({
  onUpdate,
  returnText,
  visit,
}: AssignProfessionalProps) {
  const [showAssignModal, setShowAssignModal] = useState(false);

  const onAssign = useCallback(
    (v: LonelyVisit) => {
      setShowAssignModal(false);
      onUpdate(v);
    },
    [onUpdate],
  );

  const assignProfessional = useAssignProfessional(visit, onAssign);

  return (
    <>
      <VisitActionButton
        icon={['fas', 'user-plus']}
        onClick={() => {
          setShowAssignModal(true);
        }}
      />
      {showAssignModal && (
        <AssignProfessionalModal
          onAssignProfessional={assignProfessional}
          visit={visit}
          showModal={showAssignModal}
          returnToPatient={() => {
            setShowAssignModal(false);
          }}
          returnText={returnText}
        />
      )}
    </>
  );
}
