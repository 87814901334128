import React, {useState} from 'react';
import {Professional} from '../../../models';
import {Button, OptionInput, OtherValue} from '../../ui/form';
import {Modal} from '../../ui/layout/Modal';
import {useApi} from '@src/api/useApi';
import {UserId} from '@src/models/User';
const QUALIFICATIONS = [
  {
    label: 'MD',
    value: 'MD',
  },
  {
    label: 'DO',
    value: 'DO',
  },
  {
    label: 'NP',
    value: 'NP',
  },
  {
    label: 'Not given',
    value: '',
  },
  {
    label: 'Other',
    value: OtherValue,
  },
];

const isKnownQualification = (qual: string) =>
  QUALIFICATIONS.some(q => q.value === qual);

type Props = {
  qualification: string;
  onUpdate: (provider: Professional) => void;
  onClose: () => void;
  providerId: UserId;
  show: boolean;
};

export const QualificationModal: React.FunctionComponent<Props> = ({
  qualification,
  onUpdate,
  onClose,
  providerId,
  show,
}) => {
  const api = useApi();
  const [qual, setQualification] = useState(
    isKnownQualification(qualification) ? qualification : OtherValue,
  );
  const [alternateQualification, setAlternateQualification] = useState(
    qualification,
  );
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = () => {
    setSubmitting(true);
    api
      .provider(providerId)
      .updateDetails({
        qualification: qual !== 'other' ? qual : alternateQualification,
      })
      .then((provider: Professional) => {
        onUpdate(provider);
        setSubmitting(false);
        onClose();
      });
  };

  return (
    <Modal
      show={show}
      title="Qualification"
      onClose={onClose}
      modalActions={
        <Button
          disabled={isSubmitting}
          bSize="small"
          bStyle="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      }
    >
      <div>
        <p>Please select your medical qualification.</p>
        <OptionInput
          options={QUALIFICATIONS}
          selection={qual}
          makeSelection={setQualification}
          otherSelection={alternateQualification}
          makeOtherSelection={setAlternateQualification}
          otherText={'Please enter your qualification here'}
        />
      </div>
    </Modal>
  );
};
