import React from 'react';
import styled from 'styled-components';

// Undo the CSS reset at `src/components/ui/theme/ResetCss.ts`
const Typography = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul,
  li,
  a,
  div {
    margin: revert;
    padding: revert;
    border: revert;
    font-size: revert;
    vertical-align: revert;
  }

  ol,
  ul {
    list-style: revert;
  }

  a {
    text-decoration: revert;
    cursor: revert;

    &:hover {
      text-decoration: revert;
    }
  }
`;

export default function FormStaticContent({innerHTML}: {innerHTML: string}) {
  return <Typography dangerouslySetInnerHTML={{__html: innerHTML}} />;
}
