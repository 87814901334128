import styled from 'styled-components';
import {backdrop, h3, md, mobileMax, primary, sm, xl, xs} from '../../ui/theme';

export const LayoutTitle = styled.div`
  color: ${primary};
  display: flex;
  flex-direction: row;
  font-size: ${h3};
  justify-content: flex-start;
  margin-top: ${sm};
  margin-bottom: ${xs};
`;

export const ButtonFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${sm};
`;

export const PatientOverviewStyled = styled.div`
  background-color: ${backdrop};
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 70vw;
  padding: ${xl} 0;
`;

export const PatientOverviewHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  margin-top: 1em;
  padding: 0 ${md};
  width: 100%;

  @media (max-width: ${mobileMax}) {
    flex-direction: column;
  }
`;

export const VisitSection = styled.div`
  padding: ${sm} ${md};
  @media (max-width: ${mobileMax}) {
    padding: ${sm} 0;
  }
`;
