import {ConfirmationStep} from './ConfirmationStep';
import {ProviderGroupStep} from './ProviderGroupStep';
import {ProviderSelectionStep} from './ProviderSelectionStep';
import {VisitDetailsStep} from './VisitDetailsStep';
import {VisitTypeStep} from './VisitTypeStep';
import {PatientFormsStep} from './PatientFormsStep';
import {StepProps} from '.';
import {FlowProps} from '@src/hooks/useSteps';

export type CreateVisitStep =
  | 'VisitType'
  | 'ProviderSelection'
  | 'VisitDetails'
  | 'ProviderGroup'
  | 'PatientForms'
  | 'Confirmation';

/**
 * This maps a step type to its name
 */
export const StepNames: Record<CreateVisitStep, string> = {
  VisitType: 'Visit Type',
  ProviderSelection: 'Provider',
  VisitDetails: 'Visit Details',
  ProviderGroup: 'Provider Group',
  PatientForms: 'Patient Forms',
  Confirmation: 'Confirm',
};

/**
 * This is guaranteed to be a valid component
 */
type StepComponent = (props: StepProps & FlowProps) => JSX.Element | null;

/**
 * This maps a step type to its component
 */
export const StepComponents: Record<CreateVisitStep, StepComponent> = {
  VisitType: VisitTypeStep,
  ProviderSelection: ProviderSelectionStep,
  VisitDetails: VisitDetailsStep,
  ProviderGroup: ProviderGroupStep,
  PatientForms: PatientFormsStep,
  Confirmation: ConfirmationStep,
};
