import {makeStyles} from '@material-ui/core/styles';
import {lg} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  // List
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    padding: lg,
  },

  providerText: {
    color: theme.palette.grey[700],
  },
}));
