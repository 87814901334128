import {Grid} from '@material-ui/core';
import React, {useEffect, lazy, useState, Suspense} from 'react';
import {StepProps} from '.';
import {useApi} from '@src/api/useApi';
import {CallPoolSelector} from '@src/components/guardianSide/VisitRequest/CallPoolSelector';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {useAsync, useUser} from '@src/hooks';
import {FlowProps} from '@src/hooks/useSteps';
import {CallPool, Endpoint} from '@src/models';
import {UserId} from '@src/models/User';

type Props = StepProps & FlowProps;

const EndpointSelection = lazy(() =>
  import('@src/components/shared/EndpointSelection'),
);

export function ProviderGroupStep({
  callPool,
  patient,
  details,
  endpointId,
  setNextAvailable,
  setCallPool,
}: Props) {
  const api = useApi();
  const [availableGroups, setAvailableGroups] = useAsync<CallPool>();
  const [endpoints, setEndpoints] = useState<Endpoint[]>([]);
  const [user] = useUser();

  const currentUserId = user
    .getOptional()
    .map(u => u.id)
    .orElse(0 as UserId);

  useEffect(() => {
    setNextAvailable(
      callPool !== null &&
        (endpoints.length === 0 || endpointId.value !== null),
    );
  }, [setNextAvailable, callPool, endpoints, endpointId]);

  useEffect(() => {
    setEndpoints(callPool?.endpoints ?? []);
  }, [callPool]);

  useEffect(() => {
    const d = details.orNull();
    if (d === null) {
      api
        .patient(patient?.id ?? 0)
        .getOnDemandCallPools()
        .then(setAvailableGroups);
    } else {
      api
        .provider(details.property('providerId', 0 as UserId))
        .getGroups({memberOnly: true})
        .then(setAvailableGroups);
    }
  }, [api, patient, details, setAvailableGroups, currentUserId]);

  return (
    <>
      <RequestHeader>Provider Group</RequestHeader>
      <RequestInstructions>
        <p>Which Provider Group should this Visit be placed into?</p>
      </RequestInstructions>
      <Grid container>
        <Grid item xs={12}>
          <CallPoolSelector
            patientId={patient?.id ?? 0}
            selectedCallPool={callPool}
            availablePools={availableGroups}
            header={'Provider Group'}
            instructions={
              <>Please choose the most appropriate Group for this visit</>
            }
            onSelect={cpId => {
              endpointId.reset();
              setCallPool(
                availableGroups
                  .getAllOptional()
                  .map(gs => gs.find(g => g.id === cpId))
                  .orNull(),
              );
            }}
          />
        </Grid>
        {endpoints.length > 0 && (
          <Grid item xs={12}>
            <Suspense fallback={<div>Loading...</div>}>
              <EndpointSelection
                endpoints={endpoints}
                selectedEndpoint={endpointId.value}
                selectEndpoint={endpointId.set}
              />
            </Suspense>
          </Grid>
        )}
      </Grid>
    </>
  );
}
