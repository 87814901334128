import {yupResolver} from '@hookform/resolvers';
import {Grid} from '@material-ui/core';
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useStyles} from '../CreateProviderGroupPage/styles';
import {TextInput} from '@src/components/ui/form/TextInput';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {getRequiredMessage} from '@src/util/forms/getRequiredMessage';
import {useApi} from '@src/api/useApi';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';

const schema = yup.object().shape({
  customerName: yup
    .string()
    .required(getRequiredMessage('a', 'name for the customer')),
  salesforceId: yup.string().required(getRequiredMessage('a', 'Salesforce Id')),
});

export function CustomerForm() {
  const classes = useStyles();
  const api = useApi();

  const [submitting, setSubmitting] = useState(false);

  const {control, errors, handleSubmit, reset} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      customerName: '',
      salesforceId: '',
    },
  });

  const formHandler = handleSubmit(
    (data: {customerName: string; salesforceId: string}) => {
      const {customerName, salesforceId} = data;

      setSubmitting(true);

      api
        .invoiceTarget()
        .create({customerName, salesforceId})
        .then(() => {
          reset();
          flashSuccess('Customer created successfully!');
        })
        .catch(() => {
          flashError('Something went wrong creating a Customer');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  );

  return (
    <>
      <Grid container direction="row">
        <Grid item>
          <Controller
            name="customerName"
            control={control}
            render={({onChange, onBlur, value, name}) => (
              <TextInput
                value={value}
                name={name}
                required
                placeholder="Name of the Customer on the invoices"
                title="Customer Name"
                onChange={onChange}
                onBlur={onBlur}
                error={errors.customerName?.message}
                className={classes.inputField}
              />
            )}
          />

          <Controller
            name="salesforceId"
            control={control}
            render={({onChange, onBlur, value, name}) => (
              <TextInput
                value={value}
                name={name}
                required
                placeholder="Customer's Salesforce ID"
                title="Salesforce ID"
                onChange={onChange}
                onBlur={onBlur}
                error={errors.salesforceId?.message}
                className={classes.inputField}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid>
        <MuiAsyncActionButton
          bStyle="primary"
          bSize="normal"
          onClick={formHandler}
          actionInProgress={submitting}
          actionWord="Submit"
          disabled={submitting}
        >
          Submit
        </MuiAsyncActionButton>
      </Grid>
    </>
  );
}
