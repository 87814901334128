import {useCallback} from 'react';

/**
 *
 * This handles a common pattern where we:
 * - Run something before an async request
 * - Run the asynchronous request
 * - Run an asynchronous function after the request successfully completes
 * - Run a synchronous function in the `finally` block
 * - Handle errors
 * @returns
 */
export const useRequestHandler = <T>({
  runBeforeRequest,
  asyncRequest,
  asyncRunAfterRequest,
  runFinally,
  runOnError,
}: {
  runBeforeRequest: () => void;
  asyncRequest: () => Promise<T>;
  asyncRunAfterRequest: (value: T) => Promise<unknown>;
  runFinally: () => void;
  runOnError: () => void;
}) => {
  return useCallback(async () => {
    runBeforeRequest();

    await asyncRequest()
      .then(asyncRunAfterRequest)
      .catch(runOnError)
      .finally(runFinally);
  }, [
    runBeforeRequest,
    asyncRequest,
    asyncRunAfterRequest,
    runOnError,
    runFinally,
  ]);
};
