import {Grid} from '@material-ui/core';
import React, {useState} from 'react';
import {useParams} from 'react-router';
import InsuranceSummary from '../../InsuranceSummary';
import {ButtonDiv, Header} from './layout';
import {EditFinanciallyResponsiblePerson} from './EditFinanciallyResponsiblePerson';
import {useApi} from '@src/api/useApi';
import {BackButton} from '@src/components/shared/BackButton';
import {FinanciallyResponsiblePersonFormModal} from '@src/components/shared/forms/FinanciallyResponsiblePersonFormModal';
import {ResponsiblePersonCard} from '@src/components/shared/forms/FinanciallyResponsiblePersonFormModal/ResponsiblePersonCard';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {MuiHeading} from '@src/components/ui/layout/MuiHeading';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {PageContainer} from '@src/components/ui/layout/PageContainer';
import {useUser, useResources} from '@src/hooks';
import {Patient, Guardian, UserType} from '@src/models';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

// eslint-disable-next-line import/no-unused-modules
export default function InsuranceOverview() {
  const api = useApi();
  const params = useParams<{patientId: string}>();
  const patientId = +params.patientId;
  const [user] = useUser();

  const [patient, refreshPatient] = useResources<Patient>(
    () => api.patient(patientId).get(),
    [api, patientId],
  );
  const [rpMode, setRPMode] = useState<'view' | 'edit'>('view');

  const personId = user
    .getOptional()
    .cast<Guardian>(u => u.userType === UserType.Guardian)
    .map(g => g.responsiblePersonDetails)
    .property('id', 0 as NonProfessionalId);

  const oPatient = patient.getOptional();
  const financialRelationship = oPatient.flatMap(o => o.financialRelationship);
  const paymentInformation = oPatient.flatMap(o => o.paymentInformation);
  const secondaryPayment = oPatient.flatMap(o => o.secondaryPayment);
  const userRelationshipWithPatient = oPatient
    .map(o => o.relationships)
    .map(rs => rs.find(r => (r.personId = personId)));

  return (
    <PageContainer>
      <PageLoading
        active={!patient.isLoaded()}
        message="Loading Insurance info"
      >
        <Grid item xs={12}>
          <Header>
            <div>
              {oPatient.map(p => p.preferredName).orElse('Loading...')} -
              Insurance
            </div>
            <ButtonDiv>
              <BackButton />
            </ButtonDiv>
          </Header>
        </Grid>

        <Grid item xs={12} style={{marginBottom: '1rem'}}>
          <ParagraphText>
            You can edit{' '}
            {oPatient.map(p => p.preferredFirstName).orElse('this patient')}'s{' '}
            insurance information here.
          </ParagraphText>
        </Grid>
        <Grid item xs={12} style={{marginBottom: '2rem'}}>
          <MuiHeading variant="h2" style={{marginBottom: '0'}} color="primary">
            Responsible Person
          </MuiHeading>
          <ParagraphText style={{marginTop: '0.5rem'}}>
            This is the person who is financially responsible for{' '}
            {oPatient.map(p => p.preferredFirstName).orElse('this patient')}
            's out of pocket costs.
          </ParagraphText>
          {!financialRelationship.isPresent() && (
            <>
              <ParagraphText>
                This patient doesn't have a responsible person set up yet.
              </ParagraphText>
              {oPatient.isPresent() && (
                <>
                  {rpMode === 'view' && (
                    <EditFinanciallyResponsiblePerson
                      patient={oPatient.get()}
                      onClick={() => {
                        setRPMode('edit');
                      }}
                    />
                  )}
                  <FinanciallyResponsiblePersonFormModal
                    patient={oPatient.get()}
                    userRelationshipWithPatient={userRelationshipWithPatient}
                    relationship={financialRelationship}
                    onUpdatePatient={refreshPatient}
                    onDone={() => {
                      setRPMode('view');
                    }}
                    show={rpMode === 'edit'}
                  />
                </>
              )}
            </>
          )}
          {financialRelationship.isPresent() && oPatient.isPresent() && (
            <>
              {rpMode === 'view' && (
                <>
                  <ResponsiblePersonCard
                    relationship={financialRelationship.property(
                      'relationship',
                      '',
                    )}
                    person={financialRelationship.property('person')!}
                  />
                  <div style={{marginTop: '1rem'}}>
                    <EditFinanciallyResponsiblePerson
                      patient={oPatient.get()}
                      onClick={() => {
                        setRPMode('edit');
                      }}
                    />
                  </div>
                </>
              )}
              {rpMode === 'edit' && (
                <FinanciallyResponsiblePersonFormModal
                  userRelationshipWithPatient={userRelationshipWithPatient}
                  relationship={financialRelationship}
                  patient={patient.singleValue()}
                  onUpdatePatient={refreshPatient}
                  onDone={() => {
                    setRPMode('view');
                  }}
                  show={true}
                />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} style={{marginBottom: '2rem'}}>
          <MuiHeading variant="h2" color="primary">
            Insurance
          </MuiHeading>
          <InsuranceSummary
            editable={true}
            primary={paymentInformation}
            secondary={secondaryPayment}
            patient={oPatient}
            onInsuranceUpdated={refreshPatient}
          />

          {!financialRelationship.isPresent() && (
            <ParagraphText>
              Please add a Responsible Person for{' '}
              {oPatient.map(p => p.preferredFirstName).orElse('this patient')}{' '}
              before adding Insurance information.
            </ParagraphText>
          )}
        </Grid>
      </PageLoading>
    </PageContainer>
  );
}
