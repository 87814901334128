import React, {useCallback, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
  outer: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '24pt',
    height: '55px',
  },
  inner: {
    whiteSpace: 'nowrap',
  },
});

interface Props {
  fontFamily: string;
  text: string;
  maxWidth: string | number;
}

export default function AutoWidth({fontFamily, text, maxWidth}: Props) {
  const classes = useStyles();
  const inner = useRef<HTMLDivElement | null>(null);

  const resizeText = useCallback(() => {
    if (!inner.current) {
      return;
    }

    inner.current.style.fontSize = '100%';

    const newPercent =
      Math.min(
        1,
        (inner.current.parentElement?.clientWidth ?? 0) /
          inner.current.clientWidth,
      ) * 100;

    inner.current.style.fontSize = newPercent + '%';
  }, []);

  useEffect(() => {
    document.fonts.ready.then(resizeText);
  }, [resizeText]);

  useEffect(resizeText);

  return (
    <div className={classes.outer} style={{maxWidth}}>
      <div ref={inner} className={classes.inner} style={{fontFamily}}>
        {text}
      </div>
    </div>
  );
}
