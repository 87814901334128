import React from 'react';
import {Button} from '../../../ui/form';

type GetBackNavigationButtons = {
  currentStep: number;
  goBackAStep: () => void;
  saving: boolean;
  resetForm: () => void;
};

export function getBackNavigationButtons({
  currentStep,
  goBackAStep,
  saving,
  resetForm,
}: GetBackNavigationButtons) {
  return [
    <Button
      bStyle="secondary"
      disabled={currentStep === 0 || saving}
      onClick={goBackAStep}
    >
      Back
    </Button>,
    <Button bStyle="outlined" disabled={saving} onClick={resetForm}>
      Start Over
    </Button>,
  ];
}
