import {ConnectedLogin} from '../../../stepFunctions';
import {LonelyPatient} from '@src/models';
import {AppAPI} from '@src/api/AppAPI';
import {flashWarn} from '@src/components/shared/notifications/flash';

export const addConnectedLogins = async ({
  api,
  connectedLogins,
  patient,
}: {
  patient: LonelyPatient;
  connectedLogins: ConnectedLogin[];
  api: AppAPI;
}) => {
  const nonSelfLogins = connectedLogins.filter(login => !login.isSelf);

  nonSelfLogins.forEach(async login => {
    const {email, isSelf, relationshipName} = login;

    try {
      api.patient(patient.id).invitePerson({email, isSelf, relationshipName});
    } catch (e) {
      flashWarn(
        `Something went wrong setting up link between ${patient.firstName} and ${login.email}`,
      );
    }
  });
};
