export function getSteps() {
  return ['Select Insurance Information', 'Select Primary'];
}

export function getStepContent(step: number) {
  switch (step) {
    case 0:
      return 'The following Insurance records were found for the Patient records. Please select at least one and up to two.';
    case 1:
      return 'Which Insurance record should be Primary? If there is only one option, it will be selected as Primary.';
    default:
      return 'Unknown step';
  }
}
