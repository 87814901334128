import {Link} from '@material-ui/core';
import {useHistory} from 'react-router';
import React, {useState} from 'react';
import {PencilIcon} from './layout';
import {CallbackNumberModal} from './CallbackNumberModal';
import {QualificationModal} from './QualificationModal';
import {SpecialityModal} from './SpecialityModal';
import {toProfessional} from '@src/models';
import {isEmpty} from '@src/util/typeTests';
import {PrivatePage} from '@src/components/PrivatePage';
import {
  DataPoint,
  Section,
  SectionTitle,
} from '@src/components/ui/layout/InfoPage';
import {PageHeader} from '@src/components/ui/layout/PageHeader';
import {useUser} from '@src/hooks/useUser';
import {UserId} from '@src/models/User';
import {not} from '@src/util/predicateHelpers/not';
import {email} from '@src/util/users/getDemographics';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';

// eslint-disable-next-line import/no-unused-modules
export default function ProviderInfoPage() {
  const [user, , updateUser] = useUser();
  const history = useHistory();
  const [showOnCallNumberModal, setShowOnCallNumberModal] = useState(false);
  const [showQualificationModal, setShowQualificationModal] = useState(false);
  const [showSpecialityModal, setShowSpecialityModal] = useState(false);

  const professional = user.getOptional().cast(toProfessional);
  if (!professional.isPresent()) {
    return null;
  }

  const onCallNumber = professional.flatMap(u => u.phone);
  const details = professional.map(i => i.providerDetails);
  const qualification = details.map(i => i.qualification);
  const speciality = details.map(i => i.speciality);

  const seesPatients = details.property('seesPatients', false);

  return (
    <PrivatePage>
      <PageHeader>About me</PageHeader>
      <Section>
        <SectionTitle>My Details</SectionTitle>
        <DataPoint>
          <div>
            Your email address is {email(professional).orElse('UNKNOWN')}
          </div>
        </DataPoint>
        {seesPatients && (
          <DataPoint>
            <div>
              {qualification
                .filter(q => !isEmpty(q))
                .map(q => `You are currently registered as a ${q}`)
                .orElse('Your qualifications are not currently registered')}
            </div>
            <PencilIcon
              icon={'pencil'}
              onClick={() => setShowQualificationModal(true)}
            />
            <QualificationModal
              show={showQualificationModal}
              onClose={() => setShowQualificationModal(false)}
              qualification={qualification.orElse('')}
              providerId={professional.map(p => p.id).orElse(0 as UserId)}
              onUpdate={updateUser}
            />
          </DataPoint>
        )}
        {seesPatients && (
          <DataPoint>
            <div>
              {speciality
                .filter(not(isEmpty))
                .map(s => `Your speciality is listed as ${s}`)
                .orElse('Your speciality is not currently registered')}
            </div>
            <PencilIcon
              icon={'pencil'}
              onClick={() => setShowSpecialityModal(true)}
            />
            <SpecialityModal
              show={showSpecialityModal}
              onClose={() => setShowSpecialityModal(false)}
              speciality={speciality.orElse('')}
              providerId={professional.map(p => p.id).orElse(0 as UserId)}
              onUpdate={updateUser}
            />
          </DataPoint>
        )}
        {seesPatients && (
          <DataPoint>
            {!onCallNumber.isPresent() && (
              <div>
                You do not have an on call number registered with us right now
              </div>
            )}
            {onCallNumber.isPresent() && (
              <div>
                You are set up to receive notifications on{' '}
                <b>{onCallNumber.get()}</b>
              </div>
            )}
            <PencilIcon
              icon={'pencil'}
              onClick={() => setShowOnCallNumberModal(true)}
            />
            <CallbackNumberModal
              show={showOnCallNumberModal}
              onClose={() => setShowOnCallNumberModal(false)}
              onCallNumber={onCallNumber.orElse('')}
              providerId={professional.map(p => p.id).orElse(0 as UserId)}
              onUpdate={updateUser}
            />
          </DataPoint>
        )}
      </Section>
      <Section>
        <SectionTitle>My Shifts and Groups</SectionTitle>
        <ParagraphText>
          Your shifts and Provider Groups have moved to{' '}
          <Link onClick={() => history.push('/shifts-and-groups')}>
            this page
          </Link>
          .
        </ParagraphText>
      </Section>
    </PrivatePage>
  );
}
