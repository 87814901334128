import React from 'react';
import {usePageContext} from '..';
import {FileUploader} from '@src/components/ui/organisms/uploaders/documents/FileUploader';
import {SigningResult} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';
import {LegalDocument} from '@src/models';
import {ErrorMessage} from '@src/components/ui/form/ErrorMessage';
import {PdfViewer} from '@src/components/ui/organisms/PdfViewer';
import {NAMES} from '@src/components/ui/layout/text/names';

export function UploadSection() {
  const {
    formResources: {
      setUploadedFile,
      showNeedsUploadError,
      uploadedFile,
      viewingUploadedFile,
      setViewingUploadedFile,
    },
  } = usePageContext();

  return (
    <>
      <FileUploader
        onClickEyeIcon={() => setViewingUploadedFile(true)}
        onFileUploadedToS3={async (result: SigningResult<LegalDocument>) => {
          setUploadedFile({name: result.name, url: result.name});
          return result;
        }}
        isInline
      />
      {viewingUploadedFile && (
        <PdfViewer
          document={uploadedFile}
          onClose={() => setViewingUploadedFile(false)}
        />
      )}
      {showNeedsUploadError && (
        <ErrorMessage
          message={`Please upload a ${NAMES.file} before continuing`}
        />
      )}
    </>
  );
}
