import {useCallback} from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {useApi} from '@src/api/useApi';
import {LonelyVisit} from '@src/models';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {ResourceConflictException} from '@src/api/exceptions';
import {flashError} from '@src/components/shared/notifications/flash';

export const useAssignProfessional = (
  visit: LonelyVisit | Optional<LonelyVisit>,
  onUpdate: (v: LonelyVisit) => unknown,
) => {
  const api = useApi();
  return useCallback(
    async (professionalId: ProviderDetailsId) => {
      const v = Optional.of(visit);
      if (!v.isPresent()) {
        return false;
      }
      const visitApi = api.visit(v.property('id'));
      const action =
        professionalId === 0
          ? visitApi.unassignProfessional()
          : visitApi.assignProfessional(professionalId);

      return action
        .then(onUpdate)
        .then(() => true)
        .catch(e => {
          if (e instanceof ResourceConflictException) {
            flashError(
              'This professional cannot be assigned to this Visit because they are not in the associated Provider Group',
            );
          }
        });
    },
    [api, onUpdate, visit],
  );
};
