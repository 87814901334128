import React, {useEffect} from 'react';
import {isValid} from 'date-fns';
import {Grid} from '@material-ui/core';
import {StepProps} from '.';
import {Button, DateInput, TextInput, TimeInput} from '@src/components/ui/form';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {FlowProps} from '@src/hooks/useSteps';

type Props = StepProps & FlowProps;

export function VisitDetailsStep({
  visitType,
  setNextAvailable,
  startDate,
  startTime,
  visitNotes,
}: Props) {
  useEffect(() => {
    setNextAvailable(
      (startDate.value !== null && startTime.value !== null) ||
        visitType.value === 'OnDemand',
    );
  }, [setNextAvailable, startDate, startTime, visitType]);

  return (
    <>
      <RequestHeader>Visit Details</RequestHeader>
      <RequestInstructions>
        {visitType.value === 'OnDemand' ? (
          <p>Is there any information that you want to add to this visit?</p>
        ) : (
          <p>When will this visit take place?</p>
        )}
      </RequestInstructions>
      <Grid container direction="column">
        {visitType.value === 'Scheduled' && (
          <>
            <Grid item container justify="space-between">
              <Grid item xs={12} md={6}>
                <DateInput
                  required
                  name="visitDate"
                  label="Date"
                  value={startDate.value}
                  onChange={value => {
                    startDate.setTouched(true);
                    startDate.set(value ? value : null);
                  }}
                  error={
                    typeof startDate.error === 'string' ? startDate.error : ''
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TimeInput
                  required
                  name="visitTime"
                  title="Time"
                  value={startTime.value}
                  onChange={value => {
                    startTime.setTouched(true);
                    startTime.set(isValid(value) ? value : null);
                  }}
                  error={
                    typeof startTime.error === 'string' ? startTime.error : ''
                  }
                />
              </Grid>
            </Grid>
            <Grid item>
              <Button
                bStyle="text"
                onClick={() => {
                  startDate.set(new Date());
                  startTime.set(new Date());
                }}
              >
                The visit is today
              </Button>
            </Grid>
          </>
        )}
        <Grid item>
          <TextInput
            title="Notes"
            autoExpand
            name="healthcareNotes"
            placeholder="Any visit information to add?"
            inputProps={{maxLength: 512}}
            value={visitNotes.value}
            onChange={e => visitNotes.set(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container></Grid>
    </>
  );
}
