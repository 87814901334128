import React from 'react';
import {FormControlLabel, Radio} from '@material-ui/core';
import {isNothing} from '@src/util/typeTests';

type Props = {
  optionOne: string;
  optionTwo: string;
  selectedRelationshipName: string;
  setSelectedRelationshipName: (v: string) => unknown;
};

export function SelectRelationshipNameStep({
  optionOne,
  optionTwo,
  selectedRelationshipName,
  setSelectedRelationshipName,
}: Props) {
  return (
    <div>
      {[optionOne, optionTwo].map(o => {
        if (!isNothing(o)) {
          return (
            <FormControlLabel
              labelPlacement="end"
              value={o}
              name="relationship"
              control={
                <Radio
                  onChange={event => {
                    setSelectedRelationshipName(event.target.value);
                  }}
                  checked={o === selectedRelationshipName}
                />
              }
              label={o}
            />
          );
        }

        return null;
      })}
    </div>
  );
}
