import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';
import React, {useMemo} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SelectionStatus} from '@src/components/providerSide/TriStateCheckbox';
import {CallPool, LonelyVisit, VisitState} from '@src/models';
import {RedFlag} from '@src/components/ui/layout/RedFlag';
import {removeDuplicateObjsByProperty} from '@src/util/objectManipulation/optionalObjects/removeDuplicateObjsByProperty';
import {AppAPI, PaginationOptions, Paged} from '@src/api/AppAPI';
import {visitIsSigned} from '@src/util/visits';
import {useApi} from '@src/api/useApi';
import {useUser} from '@src/hooks';
import {UserId} from '@src/models/User';

export const getFlag = (a: LonelyVisit) => {
  if (a.wasAbandoned()) {
    return <FontAwesomeIcon icon={'exclamation-triangle'} />;
  }

  if (a.visitDocumentation.filter(v => v.red_flag).isPresent()) {
    return <RedFlag />;
  }

  return '';
};

export type VisitFilter = 'myPanel' | 'myVisits' | 'myManagedGroupVisits';

export const isVisitFilter = (t: string): t is VisitFilter =>
  ['myPanel', 'myVisits', 'myManagedGroupVisits'].includes(t);

export const useGetters = (): Record<
  VisitFilter,
  (options: PaginationOptions) => Promise<Paged<LonelyVisit>>
> => {
  const api = useApi();
  const [user] = useUser();

  const providerApi = useMemo(
    () => api.provider(user.getOptional().property('id', 0 as UserId)),
    [api, user],
  );
  const filter = useMemo(
    () =>
      Object.freeze({
        state: [VisitState.DOCUMENTING, VisitState.COMPLETED],
      }),
    [],
  );

  return useMemo(
    () => ({
      myPanel: ({start, pageSize}) =>
        providerApi.getPanelVisits({start, pageSize, filter}),

      myVisits: ({start, pageSize}) =>
        providerApi.getVisits({start, pageSize, filter}),

      myManagedGroupVisits: ({start, pageSize}) =>
        providerApi.getManagedGroupVisits({start, pageSize, filter}),
    }),
    [filter, providerApi],
  );
};

export const getSelectableIds = (
  visits: AsyncData<LonelyVisit>,
  callPoolId: number,
): number[] =>
  visits
    .filter(visitIsSigned)
    .filter(v => {
      if (callPoolId > 0) {
        return callPoolId === v.callPoolId;
      } else {
        return true;
      }
    })
    .getAllOptional()
    .orElse([])
    .map(v => v.id);

export const getSelectionStatus = (
  visits: AsyncData<LonelyVisit>,
  selection: Record<string, boolean>,
  callPoolId: number,
) => {
  if (!visits.isLoaded() || visits.isEmpty()) {
    return SelectionStatus.None;
  }
  const ids = visits
    .filter(visitIsSigned)
    .filter(v => {
      if (callPoolId > 0) {
        return callPoolId === v.callPoolId;
      } else {
        return true;
      }
    })
    .mapValue(v => v.id);

  const all = ids.every(id => selection[id]);
  const some = ids.some(id => selection[id]);

  return all
    ? SelectionStatus.All
    : some
    ? SelectionStatus.Some
    : SelectionStatus.None;
};

export const getFilterFromUrl = (): VisitFilter => {
  const searchParams = new URLSearchParams(window.location.search);
  const filter = searchParams.get('search') || '';
  if (isVisitFilter(filter)) {
    return filter;
  }
  return 'myPanel';
};

export const downloadSelection = (api: AppAPI) => (
  selected: number[],
  setGettingDownload: (b: boolean) => void,
) => {
  setGettingDownload(true);
  api
    .visit()
    .getDocumentationPackage(selected)
    .then((zipFile: Blob) => {
      const url: string = (URL.createObjectURL(zipFile) as unknown) as string;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'documentation.zip');
      // dispatchEvent is necessary for Firefox
      link.dispatchEvent(new MouseEvent(`click`));
      setGettingDownload(false);
    });
};

export function getSortedAndUniqueCallPools(visits: readonly LonelyVisit[]) {
  const sortedCallPools: Optional<CallPool>[] = visits
    .map(viz => viz.callPool)
    .sort((a, b) => {
      const oA = a.property('nameForProviders', '');
      const oB = b.property('nameForProviders', '');
      return oA.localeCompare(oB);
    });

  const sortedAndUniqueCallPools = removeDuplicateObjsByProperty(
    sortedCallPools,
    'id',
  );

  return sortedAndUniqueCallPools;
}
