import styled from 'styled-components';
import {md, sm, xs, xxs} from '../../../ui/theme';

export const PatientGuardianList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: ${md};

  li {
    padding: ${xs};
  }

  li:hover {
    background: #f6f6f6;
  }
`;

export const DateAndTime = styled.div`
  display: flex;
  flex-direction: row;

  div:first-child {
    margin-right: ${sm};
  }

  div:last-child {
    margin-left: ${sm};
  }

  width: 100%;
`;

export const VisitDateTimeText = styled.div`
  margin-top: ${md};
  margin-bottom: ${sm};
`;

export const VisitDateTimeTitle = styled(VisitDateTimeText)`
  font-weight: bold;
`;

export const ProviderInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${md};
  padding-left: ${xxs};
`;

export const PatientTitle = styled.div`
  font-size: 1.5em;
  margin-bottom: ${sm};
`;

export const PatientHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const VisitTypeIndicator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${md};
`;
