import React from 'react';
import {format} from 'date-fns';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {Avatar, Grid, Typography} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {useStyles} from './layout';
import {PatientFundamental} from './PatientFundamental';
import {UserAvatar} from '@src/components/ui/layout/UserAvatar';
import {LonelyPatient, MedicalHistory} from '@src/models';
import asYearsOrMonths from '@src/util/dateManipulation/asYearsOrMonths';
import {providerAndQualification} from '@src/util/provider';

type Props = {
  patient?: LonelyPatient;
  currentMedicalHistory: AsyncData<MedicalHistory>;
  color?: 'dark' | 'light';
  noIcon?: boolean;
};

export function PatientFundamentals({
  currentMedicalHistory,
  patient,
  color,
  noIcon = false,
}: Props) {
  const classes = useStyles();

  const allergies =
    currentMedicalHistory
      .getOptional()
      .map(mh => mh.details?.allergies)
      .orElse('') || 'Unknown';

  const skeletalPt = currentMedicalHistory.isLoaded() ? patient : undefined;

  return (
    <Grid className={classes.rowContainer} container justify="center">
      {!noIcon && (
        <>
          {skeletalPt ? (
            <UserAvatar user={patient!} />
          ) : (
            <Skeleton variant="circle">
              <Avatar />
            </Skeleton>
          )}
        </>
      )}

      <PatientFundamental
        variant="h2"
        color={color}
        patient={skeletalPt}
        renderer={pt =>
          `${pt.preferredFirstName} ${pt.lastName}, ${asYearsOrMonths(pt.dob, {
            shortened: true,
          })}`
        }
      />

      <PatientFundamental
        color={color}
        patient={skeletalPt}
        renderer={pt =>
          `${pt.genderAssignedAtBirth ? `${pt.genderAssignedAtBirth}` : ''}${
            pt.pronouns ? ` (${pt.pronouns})` : ''
          }`
        }
      />

      <PatientFundamental
        color={color}
        patient={skeletalPt}
        renderer={pt => `DOB: ${format(pt.dob, 'MM/dd/yyyy')}`}
      />

      <PatientFundamental
        color={color}
        patient={skeletalPt}
        renderer={pt =>
          `PCP:
            ${pt.pcp.map(providerAndQualification).orNothing() ||
              'None selected'}`
        }
      />

      <Typography
        variant="subtitle1"
        className={`${classes.text} ${color === 'dark' ? classes.dark : ''}`}
      >
        {currentMedicalHistory.isLoaded() ? (
          <>Allergies: {allergies}</>
        ) : (
          <Skeleton />
        )}
      </Typography>
      <PatientFundamental
        color={color}
        patient={skeletalPt}
        renderer={pt =>
          `Patient Id: 
            ${pt.id}`
        }
      />
    </Grid>
  );
}
