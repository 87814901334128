import {ResponsiblePerson, ResponsiblePersonRequest} from '../../../../models';
import {FinanciallyResponsiblePersonValues} from '../FinanciallyResponsiblePersonFormModal/functions';

export const valuesToGuardianInfo = (
  values: FinanciallyResponsiblePersonValues,
): ResponsiblePersonRequest => {
  const {relationship, ...rp} = values;
  return {id: 0, ...rp, dob: values.dob?.toISOString()};
};

export const responsiblePersonToValues = (
  responsiblePerson?: ResponsiblePerson,
): FinanciallyResponsiblePersonValues => {
  if (!responsiblePerson) {
    return {
      firstName: '',
      lastName: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipcode: '',
      },
      phone: '',
      dob: new Date(0),
      email: '',
      guardianId: 0,
      relationship: '',
    };
  }

  return {
    firstName: responsiblePerson.firstName || '',
    lastName: responsiblePerson.lastName || '',
    address: responsiblePerson.address,
    phone: responsiblePerson.phone || '',
    dob: responsiblePerson.dob.orNull(),
    email: responsiblePerson.email.orElse(''),
    guardianId: responsiblePerson.guardianId || 0,
    relationship: '',
  };
};
