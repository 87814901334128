import {useAuth0} from '@auth0/auth0-react';
import React, {useEffect, lazy, Suspense, useState, useCallback} from 'react';
import {ApplicationErrorBoundary} from '../shared/general/ApplicationErrorBoundary';
import {MaintenanceModeHeader} from '../shared/general/MaintenanceModeHeader';
import {LoadingApp} from './LoadingApp';
import {PrivateApp} from './PrivateApp';
import {isVideoChatPath} from '@src/util/videoChat';
import {useApi} from '@src/api/useApi';
import ConfigService from '@src/ConfigService';
import {useQuery} from '@src/hooks/useQuery';
import {isSomething} from '@src/util/typeTests';
import {getVisitLink} from '@src/util/visits';

const redirectionPaths = ['login', 'signup', 'reset-password'];

const GetHelpModal = lazy(() => import('../shared/GetHelpModal'));

export function App() {
  const {loginWithRedirect, isAuthenticated, isLoading} = useAuth0();
  const api = useApi();
  const query = useQuery();
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    const customerCode = ConfigService.getCustomerCode();
    if (customerCode) {
      api.setCustomerCode(customerCode);
    }
  }, [api]);

  const redirectTo = useCallback(
    (location: string) => {
      console.debug(`Redirecting to ${location}`);
      window.location.replace(location);
      setRedirecting(true);
    },
    [setRedirecting],
  );

  useEffect(() => {
    if (redirecting) {
      return;
    }
    if (window.location.pathname === `/logout`) {
      const visitId = query.get('switchToVisit');
      if (isSomething(visitId)) {
        const visitLink = `${getVisitLink(+visitId)}?${query.toString()}`;

        redirectTo(visitLink);
      } else {
        const logoutDestination = ConfigService.getLogoutDestination();
        ConfigService.clearLogoutDestination();

        redirectTo(logoutDestination ?? ConfigService.getDomain());
      }
    }
  }, [query, redirecting, redirectTo]);

  const redirectionPath = redirectionPaths.find(path => {
    return window.location.pathname === `/${path}`;
  });

  if (redirectionPath && !isVideoChatPath()) {
    loginWithRedirect({screen_hint: redirectionPath});
    return <LoadingApp message="Directing to login..." />;
  }

  if (window.location.pathname === `/logout`) {
    return <LoadingApp message="Logged out. Redirecting you now..." />;
  }

  if (!isLoading && !isAuthenticated && !isVideoChatPath()) {
    loginWithRedirect();
    return <LoadingApp message="Directing to login..." />;
  }

  if (isLoading && !isAuthenticated) {
    return <LoadingApp message="Loading..." />;
  }

  return (
    <Suspense fallback={<LoadingApp message="Loading..." />}>
      <MaintenanceModeHeader />
      <ApplicationErrorBoundary>
        <PrivateApp />
      </ApplicationErrorBoundary>
      <GetHelpModal />
    </Suspense>
  );
}
