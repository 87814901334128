import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useMemo} from 'react';
import {fromPaymentInformation} from '../PaymentDetails';
import {useLogic} from './functions';
import {InsuranceType, Patient, PaymentInformation} from '@src/models';
import {
  SummaryItem,
  SummaryList,
} from '@src/components/ui/layout/FinancialSummaryBox';

type Props = {
  patient: Optional<Patient>;
  info: Optional<PaymentInformation>;
  type: InsuranceType;
};

export const PaymentInfoDescription = ({info, patient, type}: Props) => {
  const logic = useLogic(patient);
  const list = useMemo(
    () =>
      logic(fromPaymentInformation(info), type).map((item, i) => (
        <SummaryItem key={`${item.toString()}-${i}`}>
          {item.map((phrase, j) => (
            <span key={`${i}-${j}-${phrase}`}>{phrase}</span>
          ))}
        </SummaryItem>
      )),
    [logic, info, type],
  );
  return <SummaryList>{list}</SummaryList>;
};
