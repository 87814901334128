/**
 * Used for Relationships section table.
 */

import {getColumn} from './tableHeaderUtils';

export function getRelationshipsHeadings() {
  const fullNameHeading = getColumn({
    width: '33%',
    content: 'Name',
  });

  const relationshipNameHeading = getColumn({
    width: '33%',
    content: 'Relationship',
  });

  const resultHeading = getColumn({
    width: '20%',
    content: 'Result',
  });

  const resultIconHeading = getColumn({width: '5%'});

  return [
    fullNameHeading,
    relationshipNameHeading,
    resultHeading,
    resultIconHeading,
  ];
}
