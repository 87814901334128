import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {primary} from '@src/components/ui/theme';

type Props = {
  active: boolean;
  icon: IconProp;
  onClick: () => unknown;
};

export function IconButton({active, icon, onClick}: Props) {
  return (
    <span
      className="fa-stack fa-2x"
      style={{cursor: 'pointer'}}
      onClick={onClick}
    >
      <FontAwesomeIcon
        color={primary}
        inverse={!active}
        icon={['fas', 'square']}
        className="fa-stack-2x"
      />
      <FontAwesomeIcon
        color={primary}
        inverse={active}
        icon={icon}
        className="fa-stack-1x"
      />
    </span>
  );
}
