import React, {useCallback, useState} from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {SharedCarePlan, SCPChangeRequest, SCPStatus} from '@src/models';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {flashError} from '@src/components/shared/notifications/flash';
import {useOnReRequest} from '@src/hooks/scp/changeRequests/statusActions';

export function ReRequestButton({
  scp,
  changeRequest,
  asyncRunAfterReRequested,
  isInRow = false,
}: {
  scp: Optional<SharedCarePlan>;
  changeRequest: SCPChangeRequest;
  asyncRunAfterReRequested: (scp: SharedCarePlan) => Promise<unknown>;
  isInRow?: boolean;
}) {
  const scpStatus = scp.map(m => m.status).orNothing();
  const [isReRequesting, setIsReRequesting] = useState(false);

  const onReRequest = useOnReRequest({
    runBeforeRequest: useCallback(() => setIsReRequesting(true), []),
    asyncRunAfterRequest: useCallback(asyncRunAfterReRequested, [
      asyncRunAfterReRequested,
    ]),
    runFinally: useCallback(() => {
      setIsReRequesting(false);
    }, []),
    runOnError: useCallback(
      () =>
        flashError('Something went wrong re-requesting this Change Request'),
      [],
    ),
    scp,
    changeRequestId: changeRequest.id,
  });

  return (
    <MuiAsyncActionButton
      bSize="small"
      disabled={
        scpStatus === SCPStatus.Draft ||
        scpStatus === SCPStatus.OwnershipExpired
      }
      onClick={onReRequest}
      actionWord="Re-request"
      actionInProgress={isReRequesting}
      isInRow={isInRow}
    />
  );
}
