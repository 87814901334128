import {ReactNode, ReactElement} from 'react';
import {ControllerRenderProps, InputState} from 'react-hook-form';
import {MedicalHistoryValues} from './NewPatient/steps/MedicalHistoryStep/PatientMedicalHistoryForm';
import {
  reference,
  conjugate,
} from '@src/util/stringManipulation/languageHelpers';

export type MedicalHistoryUIItem = {
  name: keyof MedicalHistoryValues;
  instructions: string;
  title: string;
  placeholder?: string;
  node?: (
    field: ControllerRenderProps<MedicalHistoryValues>,
    state: InputState,
  ) => ReactElement;
};

type RenderMedicalHistorySections = {
  excludeMeds?: boolean;
  patientName: string;
  patientIsUser: boolean;
  immunizationComponent?: (
    field: ControllerRenderProps<MedicalHistoryValues>,
    state: InputState,
  ) => ReactElement;
};

export const getMedicalHistoryRenderList = ({
  excludeMeds = false,
  patientName,
  immunizationComponent,
  patientIsUser,
}: RenderMedicalHistorySections) => {
  const r = reference(patientName, patientIsUser);
  const c = conjugate(patientIsUser);

  const items: MedicalHistoryUIItem[] = [
    {
      name: 'allergies',
      instructions: `${c('Be')} ${r('nom')} allergic to anything?`,
      title: 'Allergies',
      placeholder: 'Unknown',
    },
    {
      name: 'immunizations_up_to_date',
      instructions: `Are ${r('poss')} immunizations up to date?`,
      title: 'Immunizations up to date?',
      node: immunizationComponent,
    },
    {
      name: 'immunization_comments',
      instructions: `Are there any further details about ${r(
        'poss',
      )} immunizations?`,
      title: 'Immunization comments',
    },
    {
      name: 'environment',
      instructions: `Is there anything about ${r(
        'poss',
      )} environment that the doctor should know about?`,
      title: 'Environment',
    },
    {
      name: 'past_or_ongoing_health_issues',
      instructions: `What are ${r('poss')} past or on-going health issues?`,
      title: 'Past health issues',
    },
    {
      name: 'surgeries',
      instructions: `Please list any surgeries ${r('nom')} ${c('have')} had`,
      title: 'Past surgeries',
    },
    {
      name: 'medical_history',
      instructions: `Please tell us about ${r('poss')} family medical history`,
      title: 'Family history',
    },
    {
      name: 'other',
      instructions: `Is there anything else you think the doctor should know about ${r(
        'nom',
      )}?`,
      title: 'Anything else?',
    },
  ];

  if (!excludeMeds) {
    items.unshift({
      name: 'medications',
      instructions: `What medications ${c('do')} ${r('nom')} take?`,
      title: 'Regularly used medications',
    });
  }

  return items;
};

/**
 *
 * This function is shared by MedicalHistoryPanel and PatientMedicalHistoryForm.
 * The only difference between the two is the immunization component and the type of function used for
 * rendering the inputs/labels.
 */

export function renderMedicalHistorySections(
  options: RenderMedicalHistorySections,
  renderSection: (info: MedicalHistoryUIItem) => ReactNode | null,
) {
  const sections = getMedicalHistoryRenderList(options);

  return sections.map(renderSection);
}
