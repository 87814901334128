import {debounce} from 'lodash';
import {useEffect} from 'react';
import {recalculateVideoLayout} from '@src/util/videoChat';

// Debouncing gives the reshuffling a smoother look and minimizes
// flashes.

// Must be exported outside of the hook because it is called from multiple components
// and debouncing won't work if we use multiple debounced functions.
export const debouncedRecalculateVideoLayout = debounce(
  recalculateVideoLayout,
  100,
);

const recalculateOnOrientationChange = debounce(recalculateVideoLayout, 300);

export function useRecalculateVideoLayout(): void {
  useEffect(() => {
    window.addEventListener('resize', debouncedRecalculateVideoLayout);
    window.addEventListener(
      'orientationchange',
      function() {
        // Changing the orientation requires a longer delay than resizing
        // in order to get consistently good results.
        setTimeout(recalculateOnOrientationChange, 300);
      },
      false,
    );

    debouncedRecalculateVideoLayout();
  }, []);
}
