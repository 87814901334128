import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTheme} from '@material-ui/core/styles';

type Props = {isVideoDisabled: boolean; isMuted: boolean};

export function AudioVideoIndicator({isVideoDisabled, isMuted}: Props) {
  const theme = useTheme();

  return (
    <div
      style={{
        position: 'absolute',
        display: 'inline-block',
        textAlign: 'center',
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      }}
    >
      <FontAwesomeIcon
        icon={isVideoDisabled ? 'video-slash' : 'video'}
        size="lg"
        fixedWidth
        style={{
          padding: '0.25rem',
          color: isVideoDisabled
            ? theme.palette.error.main
            : theme.palette.common.white,
        }}
      />

      <FontAwesomeIcon
        icon={isMuted ? 'microphone-slash' : 'microphone'}
        size="lg"
        fixedWidth
        style={{
          padding: '0.25rem',
          color: isMuted
            ? theme.palette.error.main
            : theme.palette.common.white,
        }}
      />
    </div>
  );
}
