import {makeStyles} from '@material-ui/core';
import {md, sm, xs} from '@src/components/ui/theme';

export const useStyles = makeStyles(() => ({
  endpointOption: {
    border: 'solid thin black',
    borderRadius: xs,
    cursor: 'pointer',
    margin: sm,
    padding: md,
  },
  poolName: {
    fontWeight: 'bold',
  },
}));
