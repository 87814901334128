import React from 'react';
import {AppBox, MAIN_CONTENT} from './AppBox';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {LoadingNavigation} from '@src/components/shared/navigation/LoadingNavigation';
import {MuiFooter} from '@src/components/ui/layout/MuiFooter';

type Props = {
  message: string;
};

export function LoadingApp({message}: Props) {
  return (
    <AppBox>
      <LoadingNavigation />
      <PageLoading
        active={true}
        message={message}
        fullSize={true}
        style={{gridArea: MAIN_CONTENT}}
      />
      <MuiFooter />
    </AppBox>
  );
}
