import React from 'react';
import {useHistory} from 'react-router';
import {Grid, Paper} from '@material-ui/core';
import {useStyles} from '../layout';
import {CallPool, UserType} from '@src/models';
import {Section, SectionTitle} from '@src/components/ui/layout/InfoPage';
import {getCallPoolName} from '@src/util/callPools/getCallPoolName';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';

export function GroupSection({
  groups,
  userType,
  heading,
}: {
  groups: readonly CallPool[];
  userType: UserType;
  heading: string;
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Section>
      <SectionTitle>{heading}</SectionTitle>

      <Grid container direction="column">
        <Grid container direction="column" item xs={12} md={6}>
          {[...groups]
            .sort((a, b) => {
              return a.nameForProviders.localeCompare(b.nameForProviders);
            })
            .map(group => (
              <Paper className={classes.poolEntry} key={group.id}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs>
                    <Bold>{getCallPoolName(group, userType)}</Bold>
                    <span className={classes.description}>
                      {group.description ?? 'No description given'}
                    </span>
                  </Grid>
                  <Grid item xs={1}>
                    <ClickableIcon
                      icon={['fas', 'angle-double-right']}
                      size="lg"
                      onClick={() => {
                        history.push(`/provider-group/${group.id}`);
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            ))}
        </Grid>
      </Grid>
    </Section>
  );
}
