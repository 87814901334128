import styled from 'styled-components';
import {lightGrey, sm, xs} from '@src/components/ui/theme';

export const ForgotPasswordForm = styled.form``;

export const InputIcon = `
  color: ${lightGrey};
`;

export const InputAndIconContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const InputInstructions = styled.div`
  margin-bottom: ${sm};
  margin-top: ${xs};
  font-size: 0.75em;
`;
