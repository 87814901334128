import {AssessmentValues, defaultAssessmentValues} from './AssessmentSection';
import {
  BillingFieldsValues,
  defaultBillingFieldsValues,
} from './BillingFieldsSection';
import {ExamValues, defaultExamValues} from './ExamSection';
import {HistoryValues, defaultHistoryValues} from './HistorySection';
import {MAIValues, defaultMAIValues} from './MAISection';
import {PlanValues, defaultPlanValues} from './PlanSection';
import {
  ReasonForVisitValues,
  defaultReasonForVisitValues,
} from './ReasonForVisitSection';
import {RedFlagValues, defaultRedFlagValues} from './RedFlagSection';
import {
  ReviewOfSystemsValues,
  defaultReviewOfSystemsValues,
} from './ReviewOfSystemsSection';

export * from './AssessmentSection';
export * from './BillingFieldsSection';
export * from './ExamSection';
export * from './HistorySection';
export * from './MAISection';
export * from './PlanSection';
export * from './ReasonForVisitSection';
export * from './RedFlagSection';
export * from './ReviewOfSystemsSection';

export type VisitDocumentationValues = AssessmentValues &
  BillingFieldsValues &
  MAIValues &
  ExamValues &
  HistoryValues &
  PlanValues &
  ReasonForVisitValues &
  RedFlagValues &
  ReviewOfSystemsValues;

export const defaultVisitDocumentationValues = (): VisitDocumentationValues => ({
  ...defaultAssessmentValues(),
  ...defaultBillingFieldsValues(),
  ...defaultMAIValues(),
  ...defaultExamValues(),
  ...defaultHistoryValues(),
  ...defaultPlanValues(),
  ...defaultReasonForVisitValues(),
  ...defaultRedFlagValues(),
  ...defaultReviewOfSystemsValues(),
});
