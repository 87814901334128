import {useMemo} from 'react';
import {CallPool} from '@src/models';
import {
  buildIdToGroupMap,
  IdToGroupMap,
} from '@src/util/callPools/mapBuilders/idToGroupMap';

export const useIdToGroupMap = (groups: readonly CallPool[]): IdToGroupMap => {
  const map = buildIdToGroupMap(groups);
  return useMemo(() => {
    return map;
  }, [map]);
};
