import {AsyncData} from '@ahanapediatrics/ahana-fp';
import React, {useCallback, useState} from 'react';
import {Skeleton} from '@material-ui/lab';
import {
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {COLUMNS, getProviderRow, ProviderRow} from './functions';
import {useStyles} from './styles';
import {
  getComparator,
  stableSort,
  Order,
} from '@src/util/ui/sortableTableUtils';
import {useApi} from '@src/api/useApi';
import {ConfirmModal} from '@src/components/ui/layout/ConfirmModal';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {getKeys} from '@src/util/objectManipulation/getKeys';
import {ProviderDetailsId, ProviderDetails} from '@src/models/ProviderDetails';
import {isNothing} from '@src/util/typeTests';

type Props = {
  callPoolId: number;
  members: AsyncData<ProviderDetails>;
  onRemoved: () => Promise<unknown>;
};

export function MembersTable({callPoolId, members, onRemoved}: Props) {
  const api = useApi();
  const classes = useStyles();

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ProviderRow>('lastName');
  const [
    memberToDeleteId,
    setMemberToDeleteId,
  ] = useState<ProviderDetailsId | null>(null);

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: keyof ProviderRow) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [setOrder, setOrderBy, orderBy, order],
  );

  const createSortHandler = useCallback(
    (property: keyof ProviderRow) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    },
    [handleRequestSort],
  );

  const closeConfirmModal = useCallback(() => {
    setMemberToDeleteId(null);
  }, [setMemberToDeleteId]);

  const removeMember = useCallback(async () => {
    if (isNothing(memberToDeleteId)) {
      return;
    }
    return api
      .callPool(callPoolId)
      .removeMember(memberToDeleteId)
      .then(onRemoved);
  }, [api, callPoolId, memberToDeleteId, onRemoved]);

  if (!members.isLoaded()) {
    return <Skeleton />;
  }

  if (members.isLoaded() && members.getAllOptional().orElse([]).length === 0) {
    return (
      <ParagraphText>
        This Provider Group currently has no members.
      </ParagraphText>
    );
  }

  const rows = members
    .getAllOptional()
    .orElse([])
    .map(getProviderRow);

  return (
    <TableContainer
      style={{maxHeight: '50vh', overflow: 'auto'}}
      component={Paper}
    >
      <Table>
        <TableHead>
          <TableRow>
            {getKeys(COLUMNS)
              .filter(c => c !== 'id')
              .map(columnName => (
                <TableCell
                  key={columnName}
                  sortDirection={orderBy === columnName ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === columnName}
                    direction={orderBy === columnName ? order : 'asc'}
                    onClick={createSortHandler(columnName)}
                  >
                    {COLUMNS[columnName]}
                  </TableSortLabel>
                </TableCell>
              ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy)).map(r => {
            return (
              <TableRow key={r.id}>
                <TableCell>{r.firstName}</TableCell>
                <TableCell>{r.lastName}</TableCell>
                <TableCell>{r.qualification}</TableCell>
                <TableCell>{r.speciality}</TableCell>
                <TableCell>{r.seesPatients}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      setMemberToDeleteId(parseInt(r.id) as ProviderDetailsId);
                    }}
                    className={classes.icon}
                  >
                    <DeleteIcon className={classes.icon} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {memberToDeleteId !== null && (
        <ConfirmModal
          onConfirm={removeMember}
          text="Confirming will remove this Provider from the Provider Group."
          show={memberToDeleteId !== null}
          onHide={closeConfirmModal}
          onCancel={closeConfirmModal}
          cancelText="Cancel"
          confirmText="Remove Member"
          titleText="Remove Member"
        />
      )}
    </TableContainer>
  );
}
