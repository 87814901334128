import React from 'react';
import {makeStyles, createStyles} from '@material-ui/core';
import {ParagraphText} from '../layout/text/body/ParagraphText';
import {xs, md} from '@src/components/ui/theme';
import {isEmpty} from '@src/util/typeTests';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'block',
      fontSize: md,
      color: theme.palette.error.main,
      marginBottom: md,
      padding: xs,
      borderBottomLeftRadius: xs,
      borderBottomRightRadius: xs,
    },
  }),
);

type ErrorMessageProps = {
  message?: string;
};

export const ErrorMessage = ({message = ''}: ErrorMessageProps) => {
  const classes = useStyles();
  return !isEmpty(message) ? (
    <ParagraphText className={classes.root}>{message}</ParagraphText>
  ) : null;
};
