/**
 * We check if specific fields and sections are mergeable throughout the children of MergePatients.
 * This function collects all the data sent from the children components and determines if
 * we can make the merge request.
 */

import {Mergeable} from '../ExpandableSection/isMergeable';

export function arePatientsMergeable({
  sectionResults,
}: {
  sectionResults: Mergeable[];
}) {
  return sectionResults.includes('CHECKING')
    ? 'CHECKING'
    : sectionResults.includes('FAIL')
    ? 'FAIL'
    : sectionResults.includes('CONFLICT')
    ? 'CONFLICT'
    : 'PASS';
}
