import {useMemo, useState} from 'react';
import * as yup from 'yup';
import {Control, DeepMap, FieldError, useForm} from 'react-hook-form';
import jstz from 'jstz';
import {yupResolver} from '@hookform/resolvers';
import {useApi} from '@src/api/useApi';
import {FormHandler} from '@src/hooks/forms';
import {flashError} from '@src/components/shared/notifications/flash';

export type FormResources = {
  submitting: boolean;
  formHandler: FormHandler;
  control: Control<CreateStoredReportValues>;
  errors: DeepMap<CreateStoredReportValues, FieldError>;
  allFields: CreateStoredReportValues;
  showCallPoolsError: boolean;
  selectedCallPoolIds: number[];
  setSelectedCallPoolIds: (v: number[]) => unknown;
  fileUrl: string | null;
};

type CreateStoredReportValues = {
  storedReportId: string;
  start: Date;
  end: Date;
};

function getSchema() {
  return yup.object().shape({
    storedReportId: yup
      .number()
      .typeError('Please select a report to run')
      .required('Please select a report to run'),
    start: yup.date().required(),
    end: yup.date().required(),
  });
}

export function useStoredReportsForm(): FormResources {
  const api = useApi();

  const schema = getSchema();

  const [submitting, setSubmitting] = useState(false);

  const [selectedCallPoolIds, setSelectedCallPoolIds] = useState<number[]>([]);

  const [showCallPoolsError, setShowCallPoolsError] = useState(false);

  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const {control, handleSubmit, watch, errors, reset} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // This is a string because of some edge cases in the way
      // the SelectInput displays the placeholder and error messages
      // if null, 0, or NaN is provided.
      storedReportId: '',
      start: new Date(),
      end: new Date(),
    },
  });

  const allFields = watch();

  const formHandler = handleSubmit(
    data => {
      const {storedReportId, start, end} = data;

      if (selectedCallPoolIds.length === 0) {
        setShowCallPoolsError(true);
        return;
      }

      setShowCallPoolsError(false);
      setSubmitting(true);

      const reportApi = api.storedReports(parseInt(storedReportId));

      reportApi
        .validate()
        .then(() =>
          reportApi.getReportUrl({
            start,
            end,
            callPoolIds: selectedCallPoolIds,
          }),
        )
        .then(async url => {
          const timezone = jstz.determine();

          setFileUrl(
            `${url}&timeZone=${timezone.name()}&jwt=${await api.access_token}`,
          );
        })
        .catch(e => {
          flashError(
            'Please contact support - we could not find the report definition in our database',
            {permanent: true},
          );
          throw e;
        })
        .finally(() => {
          reset();
          setSelectedCallPoolIds([]);
          setShowCallPoolsError(false);
          setSubmitting(false);
        });
    },
    e => {
      console.error('Form validation error:', e);
    },
  );

  return useMemo(
    () => ({
      submitting,
      formHandler,
      control,
      errors,
      allFields,
      showCallPoolsError,
      selectedCallPoolIds,
      setSelectedCallPoolIds,
      fileUrl,
    }),
    [
      submitting,
      formHandler,
      control,
      errors,
      allFields,
      showCallPoolsError,
      selectedCallPoolIds,
      setSelectedCallPoolIds,
      fileUrl,
    ],
  );
}
