import React from 'react';
import {ListCallPools} from './ListCallPools';
import {createGenericContext} from '@src/util/reactContext/createGenericContext';
import {
  ListCallPoolsResources,
  useListCallPoolsResources,
} from '@src/hooks/providerGroups/listCallPoolsPage/useListCallPoolsResources';

const [usePageContext, PageContextProvider] = createGenericContext<
  ListCallPoolsResources
>();

// eslint-disable-next-line import/no-unused-modules
export default function ListCallPoolsPage() {
  const resources = useListCallPoolsResources();

  return (
    <PageContextProvider value={resources}>
      <ListCallPools />
    </PageContextProvider>
  );
}

export {usePageContext};
