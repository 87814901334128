import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useMediaQuery,
  Avatar,
} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import React from 'react';
import {Skeleton} from '@material-ui/lab';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/styles';
import {useComponentStyles} from '@src/components/shared/PatientDashboard/SCP/panels/Contacts/styles';
import {getPhoneNumberString} from '@src/components/ui/layout/PhoneNumber';
import {UserAvatar} from '@src/components/ui/layout/UserAvatar';
import {UserType, ProviderDetails} from '@src/models';
import {
  providerAndQualification,
  providerUserStatusWithSpeciality,
} from '@src/util/provider/providerDescription';
import {isEmpty, isNothing} from '@src/util/typeTests';

type Props = {
  className?: string;
  details: ProviderDetails | null;
  isLoading?: boolean;
  includeMissing?: boolean;
  canEdit: boolean;
  onEdit: () => void;
  role?: string;
  viewerType: UserType;
  containerStyles?: Object;
  cardStyles?: Object;
};

export function CareTeamCard({
  className,
  canEdit,
  includeMissing = false,
  isLoading = false,
  details,
  onEdit,
  role,
  viewerType,
  containerStyles = {},
  cardStyles = {},
}: Props) {
  const classes = useStyles();

  const componentClasses = useComponentStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isNothing(details)) {
    return null;
  }

  const {practice, practicePhone, practiceFax} = details;

  const phone = practice.property('phone', practicePhone ?? '').trim();
  const fax = practice.property('fax', practiceFax ?? '').trim();

  return (
    <Grid
      container
      direction={isMobile ? 'column' : 'row'}
      className={clsx(componentClasses.cardContainer, className)}
      style={containerStyles}
      key={details?.id}
    >
      <Card
        style={{width: '100%', backgroundColor: 'transparent', ...cardStyles}}
        variant="outlined"
      >
        <CardContent>
          <CardHeader
            avatar={
              isLoading ? (
                <Skeleton variant="circle">
                  <Avatar />
                </Skeleton>
              ) : (
                <UserAvatar user={details} />
              )
            }
            action={
              canEdit && (
                <EditIcon
                  onClick={onEdit}
                  className={classes.icon}
                  style={{
                    marginBottom: 0,
                    marginLeft: '5px',
                    display: 'inline-block',
                    fontSize: 16,
                  }}
                />
              )
            }
            title={
              isLoading ? (
                <Skeleton />
              ) : (
                `${providerAndQualification(details)}${
                  role ? ` (${role})` : ''
                }`
              )
            }
            subheader={
              isLoading ? (
                <Skeleton />
              ) : (
                providerUserStatusWithSpeciality(details)
              )
            }
          />
          {viewerType !== UserType.Guardian && (
            <Typography className={componentClasses.detail}>
              {isLoading ? (
                <Skeleton />
              ) : (
                <>Email: {details.email.orElse('-')}</>
              )}
            </Typography>
          )}

          {(includeMissing || !isEmpty(phone)) && (
            <Typography className={componentClasses.detail}>
              {isLoading ? (
                <Skeleton />
              ) : (
                <>
                  Phone:{' '}
                  {getPhoneNumberString({
                    no: phone,
                    blankFiller: '-',
                  })}
                </>
              )}
            </Typography>
          )}
          {(includeMissing || !isEmpty(fax)) && (
            <Typography className={componentClasses.detail}>
              {isLoading ? (
                <Skeleton />
              ) : (
                <>
                  Fax:{' '}
                  {getPhoneNumberString({
                    no: fax,
                    blankFiller: '-',
                  })}
                </>
              )}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
