import {createStyles, makeStyles} from '@material-ui/core';

export const useVideoStyles = makeStyles(() =>
  createStyles({
    galleryContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& #gallery': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
        flexWrap: 'wrap',
        maxHeight: 'calc(var(--height) * var(--rows))',
        backgroundColor: '#3a3a3e',
      },
    },

    videoContainer: {
      width: 'var(--width)',
      height: 'var(--height)',
      backgroundColor: '#3a3a3e',
      position: 'relative',
    },

    mainScreenContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      backgroundColor: '#3a3a3e',
    },

    mainScreenElement: {
      height: 'var(--mainScreenElementHeight)',
      width: '100%',
      backgroundColor: '#3a3a3e',
      position: 'relative',
    },
    examRoomContainer: {
      '& video': {
        height: '100%',
        width: '100%',
      },
    },
  }),
);
