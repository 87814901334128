import {Button, Grid} from '@material-ui/core';
import React, {useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {useApi} from '@src/api/useApi';
import {PrivatePage} from '@src/components/PrivatePage';
import {BackButton} from '@src/components/shared/BackButton';
import AssignedFormListEdit from '@src/components/shared/AssignedFormListEdit';
import PageLoading from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {PageHeader} from '@src/components/ui/layout';
import {useResources} from '@src/hooks';
import {SimpleVisit} from '@src/models';
import SelectBlankForm from '@src/components/shared/SelectBlankForm';
import {SelectInput} from '@src/components/ui/form/SelectInput';

type Props = RouteComponentProps<{patientId: string}>;

// eslint-disable-next-line import/no-unused-modules
export default function FormsOverview({match}: Props) {
  const patientId = +match.params.patientId;
  const [selectedVisitId, setSelectedVisitId] = useState<number | null>(null);
  const [newBlankFormId, setNewBlankFormId] = useState<number | null>(null);
  const api = useApi();
  const [asyncForms, refreshForms] = useResources(
    () => api.patient(patientId).getForms(),
    [api, patientId],
  );
  const [patientVisits] = useResources<SimpleVisit>(
    async () => await api.patient(patientId).getActiveVisits(),
    [api, patientId],
  );

  const setSelectedVisitChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const visitId = e.target.value === '0' ? null : Number(e.target.value);
    setSelectedVisitId(visitId);
  };

  const deleteHandler = (formId: number) => {
    api
      .assignedForms(formId)
      .delete()
      .then(refreshForms);
  };

  const saveHandler = () => {
    if (!newBlankFormId) {
      return;
    }

    if (selectedVisitId) {
      api
        .visit(selectedVisitId)
        .addAssignedForm(newBlankFormId)
        .then(refreshForms);
    } else {
      api
        .patient(patientId)
        .addAssignedForm(newBlankFormId)
        .then(refreshForms);
    }

    if (
      asyncForms
        .getAllOptional()
        .orElse([])
        .filter(f => f.id === newBlankFormId).length === 0
    ) {
      setNewBlankFormId(null);
    }
  };
  const noVisitOption = {
    label: 'Form not associated to a visit',
    value: 0,
  };

  const visitOptions = [
    noVisitOption,
    ...patientVisits
      .getAllOptional()
      .orElse([])
      .map(v => ({
        label: v.start.toLocaleDateString(),
        value: `${v.id}`,
      })),
  ];

  return (
    <PageLoading
      active={!asyncForms.isLoaded() || !patientVisits.isLoaded()}
      message="Loading Forms"
    >
      <PrivatePage style={{paddingBottom: '2rem'}}>
        <Grid container justify="space-between" alignItems="center">
          <PageHeader>Patient's Forms</PageHeader>
          <div>
            <BackButton />
          </div>
        </Grid>

        <Grid container justify="center">
          <Grid item xs={12} md={8}>
            <SelectBlankForm
              forms={asyncForms.getAllOptional().orElse([])}
              newBlankFormId={newBlankFormId}
              setNewBlankFormId={setNewBlankFormId}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <SelectInput
              value={
                selectedVisitId !== null ? `${selectedVisitId}` : undefined
              }
              name="visit-select"
              placeholder="Select the visit to assign the form to"
              title={'Visit'}
              options={visitOptions}
              disabled={newBlankFormId === null}
              onChange={e => setSelectedVisitChangeHandler(e)}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Button
              color="primary"
              variant="contained"
              onClick={saveHandler}
              disabled={newBlankFormId === null}
            >
              Assign form
            </Button>
          </Grid>
          <Grid item xs={12} md={8}>
            <AssignedFormListEdit
              forms={asyncForms.getAllOptional().orElse([])}
              onDelete={deleteHandler}
              showBadge={false}
            />
          </Grid>
        </Grid>
      </PrivatePage>
    </PageLoading>
  );
}
