import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {format} from 'date-fns';
import React from 'react';
import {Grid} from '@material-ui/core';
import {CareTeamCard} from '@src/components/ui/molecules/cards/professionals/CareTeamCard';
import {ContactCard} from '@src/components/shared/PatientDashboard/SCP/panels/Contacts/ContactCard';
import {SCPHeading} from '@src/components/shared/PatientDashboard/SCP/SCPHeading';
import {PatientFundamentals} from '@src/components/shared/PatientDashboard/PatientFundamentals';
import {PHIWarning} from '@src/components/shared/PatientDashboard/SCP/SCPPrintout/PHIWarning';
import {
  MedicalHistory,
  SharedCarePlan,
  LonelyPatient,
  UserType,
  PatientRelationship,
} from '@src/models';

type Props = {
  currentMedicalHistory: AsyncData<MedicalHistory>;
  scp: SharedCarePlan;
  patient: LonelyPatient;
  guardianships: readonly PatientRelationship[];
};

export const PrintoutContent = ({
  currentMedicalHistory,
  guardianships,
  scp,
  patient,
}: Props) => {
  const details = currentMedicalHistory
    .getOptional()
    .map(cmh => cmh.details)
    .orNothing();

  return (
    <Grid container direction="row" style={{padding: '2rem'}}>
      <PHIWarning />
      <PatientFundamentals
        color="dark"
        currentMedicalHistory={currentMedicalHistory}
        patient={patient}
      />
      <Grid item xs={12}>
        <Grid item style={{marginBottom: '1rem'}}>
          <SCPHeading>Summary</SCPHeading>
          {scp.emergencySummary}
        </Grid>
        <Grid item style={{marginBottom: '1rem'}}>
          <SCPHeading>Active Diagnoses</SCPHeading>
          {scp.diagnosis}
        </Grid>

        <Grid item style={{marginBottom: '1rem'}}>
          <SCPHeading>Acute Management</SCPHeading>
          {scp.acuteManagementSteps}
        </Grid>
        <Grid item style={{marginBottom: '1rem'}}>
          <SCPHeading>Medications</SCPHeading>
          {details?.medications}
        </Grid>
        <Grid item style={{marginBottom: '1rem'}}>
          <SCPHeading>Technology</SCPHeading>
          {scp.technology}
        </Grid>
        <Grid item style={{marginBottom: '1rem'}}>
          <SCPHeading>Labs/Images to Consider</SCPHeading>
          {scp.labsOrImaging}
        </Grid>
        <Grid item style={{marginBottom: '1rem'}}>
          <SCPHeading>
            When I present to the Emergency Department please consider:
          </SCPHeading>
          {scp.communicationPreferences}
        </Grid>
        <Grid item style={{marginBottom: '1rem'}}>
          <SCPHeading>Communication Preferences</SCPHeading>
          {scp.communicationPreferences}
        </Grid>

        <Grid item xs={6} style={{marginBottom: '1rem'}}>
          <SCPHeading>Emergency Contacts</SCPHeading>
          {guardianships.map(g => (
            <ContactCard
              key={g.person.id}
              contact={g.person}
              canEdit={false}
              onEdit={() => {}}
              relationship={g.relationship ?? 'Not given'}
            />
          ))}
          {scp.contacts.map(contact => (
            <ContactCard
              key={contact.id}
              contact={contact}
              canEdit={false}
              onEdit={() => {}}
            />
          ))}
        </Grid>
        <Grid item xs={6} style={{marginBottom: '1rem'}}>
          <SCPHeading>Medical Team Contacts</SCPHeading>
          {patient.pcp
            .map(p => (
              <CareTeamCard
                details={p}
                includeMissing={false}
                canEdit={false}
                onEdit={() => {}}
                viewerType={UserType.Guardian}
              />
            ))
            .orNull()}

          {patient.careTeam
            .map(teamMember => teamMember.providerDetails)
            .filter(d => d.isPresent())
            .map(d => d.get())
            .map(d => (
              <CareTeamCard
                details={d}
                includeMissing={false}
                canEdit={false}
                onEdit={() => {}}
                viewerType={UserType.Guardian}
              />
            ))}
        </Grid>
      </Grid>
      {/* <PrintFooter> */}
      <div>
        Shared Care Plan for Refyne Connected Care Patient: {patient.uid};
        Printed: {format(new Date(), 'MM.dd.yyyy HH:mm')}
      </div>
      {/* </PrintFooter> */}
    </Grid>
  );
};
