import * as React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import styled from 'styled-components';
import {ObjectSchema, TestContext} from 'yup';
import {ErrorMessage} from '../../../ui/form/ErrorMessage';
import {md} from '../../../ui/theme';
import {ExamInput} from '../inputs/ExamInput';
import {emptyExamsValues} from '../functions';
import {FormSection} from './FormSection';
import {ExamCode, EXAM_CODES} from './systems';
import {yObject, yString} from '@src/schema/types';
import {isEmpty} from '@src/util/typeTests';

const ExamsList = styled.div`
  margin-top: ${md};
`;

const countExams = function(
  this: TestContext,
  exams: Record<ExamCode, string>,
) {
  const completedExamCount = Object.values(exams).filter(
    r => (r?.length ?? 0) > 0,
  ).length;
  const {general, environment} = exams;

  if (
    completedExamCount < 4 ||
    !((environment ?? '').length !== 0) ||
    !((general ?? '').length !== 0)
  ) {
    // eslint-disable-next-line no-invalid-this
    return this.createError({
      // eslint-disable-next-line no-invalid-this
      path: this.path,
      message:
        'Please provide Documentation of General and Location and two other systems that were examined',
    });
  }
  return true;
};

export const examSchema = {
  exams: yObject<Record<ExamCode, string>>({
    environment: yString,
    head: yString,
    general: yString,
    neuro_psych: yString,
    neck: yString,
    enmt: yString,
    eyes: yString,
    gastrointestinal: yString,
    cardiovascular: yString,
    skin: yString,
    respiratory: yString,
    musculoskeletal: yString,
  }).when(
    '$isSigning',
    (isSigning: boolean, s: ObjectSchema<Record<ExamCode, string>>) =>
      isSigning ? s.test('count-exams', '', countExams) : s,
  ),
};

export type ExamValues = {
  exams: Record<ExamCode, string>;
};

export const defaultExamValues = (): ExamValues => ({
  exams: emptyExamsValues(),
});

export const ExamLabels = {
  exams: 'Exam',
};

export function ExamSection() {
  const {errors, setValue, control} = useFormContext<ExamValues>();
  const examErrors = errors.exams ?? {};
  //@ts-ignore
  const errorMessage = (examErrors?.message as string | undefined) ?? '';

  return (
    <FormSection title={ExamLabels.exams} className="exams">
      {!isEmpty(errorMessage) && (
        //  This class is manually inserted here in order to accommodate scrolling
        //  form errors into view. Since this is a custom error, unless we add
        //  this class, our document.querySelector call won't find it.
        <span className="Mui-error">
          <ErrorMessage
            //@ts-ignore
            message={errorMessage}
          />
        </span>
      )}

      <ExamsList>
        {EXAM_CODES.map(code => (
          <Controller
            key={code}
            control={control}
            name={`exams.${code}`}
            render={({value}, {isTouched}) => (
              <ExamInput
                key={code}
                placeholder="Remarks"
                code={code}
                error={examErrors[code]?.message}
                touched={isTouched}
                remarks={value}
                onRemarksChange={remarks => {
                  setValue(`exams.${code}`, remarks, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
              />
            )}
          />
        ))}
      </ExamsList>
    </FormSection>
  );
}
