import React from 'react';
import {List, ListSubheader} from '@material-ui/core';
import {getNoFilesText} from '../../../shared/patient/files/FilesOverview/util/text';
import {useStyles} from '../../../shared/patient/files/FilesOverview/util/styles';
import {GeneralFile} from '@src/models';
import {Files} from '@src/components/ui/organisms/uploaders/files/Folder/Files';
import {NoFiles} from '@src/components/ui/organisms/uploaders/files/Folder/NoFiles';

export function GeneralFolder({files}: {files: readonly GeneralFile[]}) {
  const classes = useStyles();

  return (
    <>
      <List
        component="nav"
        subheader={<ListSubheader component="div">General Files</ListSubheader>}
        className={classes.root}
      >
        {files.length <= 0 && (
          <NoFiles noFilesText={getNoFilesText('general')} />
        )}
        {files.length > 0 && <Files files={files} open={true} />}
      </List>
    </>
  );
}
