import {Optional} from '@ahanapediatrics/ahana-fp';
import {VmShape, VolumeMeter} from '@ahanapediatrics/react-volume-meter';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Grid} from '@material-ui/core';
import {getAudioContext, MediaFailure} from '../../../../../util/videoChat';
import {AsyncActionButton, SelectInput} from '../../../../ui/form';
import {ParagraphText} from '../../../../ui/layout/text/body/ParagraphText';
import {MuiHeading} from '../../../../ui/layout/MuiHeading';
import {updateAudioInputDevice, updateMediaFailure} from './functions';

import {Devices} from './MediaDeviceManager';
import {WorkingReporter} from './WorkingReporter';

type Props = {
  devices: Optional<Devices>;
  selectedAudioInput: Optional<MediaDeviceInfo>;
  selectAudioInput: (d: Optional<string>) => unknown;
  setMediaFailure: (f: Optional<MediaFailure>) => unknown;
  onContextChangeState: (state: string) => unknown;
  isWorking: Optional<boolean>;
  onWorking?: (b: boolean) => unknown;
};

export const AudioInConfigSection = ({
  devices,
  isWorking,
  onContextChangeState,
  onWorking,
  selectedAudioInput,
  selectAudioInput,
  setMediaFailure,
}: Props) => {
  const options = devices
    .map(d => d.audioInputs)
    .orElse([])
    .map(a => ({
      value: a.deviceId,
      label: a.label || `Mic ${a.deviceId}`,
    }));

  const [activatingMic, setActivatingMic] = useState(false);
  const [contextState, setContextState] = useState<string>('pending');
  const [audioInput, setAudioInput] = useState<Optional<MediaStream>>(
    Optional.empty(),
  );
  const audioContext = useRef(getAudioContext());

  const updateContextState = useCallback(() => {
    const {state} = audioContext.current;
    setContextState(state);
    onContextChangeState(state);
  }, [audioContext.current]);

  useEffect(() => {
    updateContextState();
    const {current} = audioContext;
    current.addEventListener('statechange', updateContextState);
    return () => {
      current.removeEventListener('statechange', updateContextState);
    };
  }, [updateContextState]);

  useEffect(() => {
    updateAudioInputDevice(selectedAudioInput.map(a => a.deviceId)).then(
      result =>
        result.apply(
          updateMediaFailure(setMediaFailure),
          (stream: MediaStream) => {
            setMediaFailure(Optional.of(MediaFailure.None));
            setAudioInput(Optional.of(stream));
          },
        ),
    );
  }, [selectedAudioInput, audioContext]);

  useEffect(() => {
    return () => {
      audioInput.ifPresent(stream =>
        stream.getTracks().forEach(track => track.stop()),
      );
    };
  }, [audioInput]);

  return (
    <Grid container direction="row">
      <MuiHeading
        variant="h6"
        color="primary"
        style={{marginBottom: '1rem', width: '100%'}}
      >
        Are the green bars moving?
      </MuiHeading>
      {contextState === 'running' ? (
        <ParagraphText>
          Now, say a few words out loud. You should see the green lights flash,
          indicating that the microphone is picking you up. If not, try choosing
          a different microphone from the second dropdown.
        </ParagraphText>
      ) : (
        <ParagraphText>
          Next, you'll need to activate your microphone, by pressing the button
          below.
        </ParagraphText>
      )}
      <Grid item xs={12} style={{marginBottom: '3rem'}}>
        {audioInput.isPresent() ? (
          <Grid item style={{marginBottom: '2rem'}}>
            <VolumeMeter
              width={300}
              height={50}
              audioContext={audioContext.current}
              stream={audioInput}
              shape={VmShape.VM_FLAT}
              blocks={20}
              activateButton={activate => (
                <AsyncActionButton
                  actionInProgress={activatingMic}
                  actionWord={'Activate microphone'}
                  onClick={() => {
                    setActivatingMic(true);
                    activate()
                      .catch(e => {
                        console.error(
                          'Error thrown while resuming Audio Context',
                        );
                        console.error(e);
                      })
                      .finally(() => {
                        setActivatingMic(false);
                      });
                  }}
                />
              )}
            />
          </Grid>
        ) : (
          <ParagraphText>
            No valid input source selected, please choose one from the list
            below
          </ParagraphText>
        )}
        <SelectInput
          title="Audio Input"
          name="audioInput"
          options={options}
          placeholder="Please select an audio input"
          value={selectedAudioInput.map(a => a.deviceId).orElse('')}
          onChange={e => {
            selectAudioInput(Optional.of(e.target.value));
          }}
        />
        {onWorking && (
          <div style={{marginTop: '1rem'}}>
            <WorkingReporter
              disabled={contextState !== 'running'}
              isWorking={isWorking}
              reportWorking={onWorking}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};
