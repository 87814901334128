import React, {useState} from 'react';
import {Professional} from '../../../models';
import {Button, OptionInput, OtherValue} from '../../ui/form';
import {Modal} from '../../ui/layout/Modal';
import {useApi} from '@src/api/useApi';
import {UserId} from '@src/models/User';

const SPECIALITIES = [
  {
    label: 'Pediatrics',
    value: 'Pediatrics',
  },
  {
    label: 'Family Medicine',
    value: 'Family Medicine',
  },
  {
    label: 'Not given',
    value: '',
  },
  {
    label: 'Other',
    value: OtherValue,
  },
];

const isKnownSpeciality = (spec: string) =>
  SPECIALITIES.some(q => q.value === spec);

type Props = {
  speciality: string;
  onUpdate: (provider: Professional) => void;
  onClose: () => void;
  providerId: UserId;
  show: boolean;
};

export function SpecialityModal({
  speciality,
  onUpdate,
  onClose,
  providerId,
  show,
}: Props) {
  const api = useApi();
  const [spec, setSpeciality] = useState(
    isKnownSpeciality(speciality) ? speciality : OtherValue,
  );
  const [alternateSpeciality, setAlternateSpeciality] = useState(
    isKnownSpeciality(speciality) ? '' : speciality,
  );
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = () => {
    setSubmitting(true);
    api
      .provider(providerId)
      .updateDetails({
        speciality: spec !== 'other' ? spec : alternateSpeciality,
      })
      .then((provider: Professional) => {
        onUpdate(provider);
        setSubmitting(false);
        onClose();
      });
  };

  return (
    <Modal
      show={show}
      title="Speciality"
      onClose={onClose}
      modalActions={
        <Button
          disabled={isSubmitting}
          bSize="small"
          bStyle="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      }
    >
      <div>
        <p>Please select your medical speciality.</p>
        <OptionInput
          options={SPECIALITIES}
          selection={spec}
          makeSelection={setSpeciality}
          otherSelection={alternateSpeciality}
          makeOtherSelection={setAlternateSpeciality}
          otherText={'Please enter your speciality here'}
        />
      </div>
    </Modal>
  );
}
