import React, {useEffect} from 'react';
import {Table, TableBody, TableContainer} from '@material-ui/core';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {ExpandableSection} from '../ExpandableSection';
import {useStyles as useGeneralStyles} from '../shared/styles';
import {
  isSectionMergeable,
  Mergeable,
} from '../ExpandableSection/isMergeable/isSectionMergeable';
import {TableHeader} from '../shared/Tables/TableHeader';
import {useStyles} from '../shared/Tables/styles';
import {getDetailColumnHeadings} from '../shared/Tables/TableHeader/detailsHeaderUtils';
import {Rows} from './Rows';
import {BasicDetailsResult} from './Rows/utils/resultFunctions';
import {Patient} from '@src/models';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';

type Props = {
  patientOne: AsyncData<Patient>;
  patientTwo: AsyncData<Patient>;
  setMergeable: (mergeable: Mergeable) => unknown;
  mergeable: Mergeable;
  basicDetailsResult: BasicDetailsResult;
  setBasicDetailsResult: (r: BasicDetailsResult) => unknown;
};

export function BasicDetailsComparer({
  patientOne,
  patientTwo,
  setMergeable,
  mergeable,
  basicDetailsResult,
  setBasicDetailsResult,
}: Props) {
  const classes = {...useStyles(), ...useGeneralStyles()};

  useEffect(() => {
    setMergeable(
      isSectionMergeable({
        p1: patientOne,
        p2: patientTwo,
        sectionName: 'basicDetails',
        basicDetailsResult,
      }),
    );
  }, [patientOne, patientTwo, setMergeable, basicDetailsResult]);

  const headings = getDetailColumnHeadings({patientOne, patientTwo});

  return (
    <ExpandableSection title="Basic Details" mergeable={mergeable}>
      <TableContainer>
        <div className={classes.comparerMessage}>
          {mergeable === 'CONFLICT' && (
            <>
              <ParagraphText>
                The Patient's Basic Details cannot be merged in their current
                state because there are some conflicting fields.
              </ParagraphText>
              <ParagraphText>
                For each field, please choose to keep either one of the record's
                values or add an override value.
              </ParagraphText>
              <ParagraphText>
                The final value will be shown in the <Bold>Result</Bold> column
                for each field.
              </ParagraphText>
            </>
          )}
          {mergeable === 'PASS' && (
            <ParagraphText>
              This Patient's Basic Details can be merged.
            </ParagraphText>
          )}
        </div>

        <Table size="small">
          <TableHeader headings={headings} />
          <TableBody>
            <Rows
              patientOne={patientOne}
              patientTwo={patientTwo}
              result={basicDetailsResult}
              setResult={setBasicDetailsResult}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </ExpandableSection>
  );
}
