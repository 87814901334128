import {convertToAppFile, AppFile, VisitFile} from '@src/models';
import {AppAPI} from '@src/api/AppAPI';
import {SigningResult} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';

export const onUploadFile = ({
  api,
  visitIdForUpload,
  setFiles,
  files,
}: {
  api: AppAPI;
  setFiles: (f: readonly VisitFile[]) => unknown;
  files: readonly VisitFile[];
  visitIdForUpload: number;
}) => (result: SigningResult<AppFile>, file: File): Promise<AppFile> => {
  return api
    .visit(visitIdForUpload)
    .addFile({...result, contentType: file.type})
    .then(m => {
      setFiles([...files, m]);
      return m;
    })
    .then(f => convertToAppFile(f));
};

/**
 *
 * Visit Selector Functions
 *
 * These functions have to do with the drop-down selector we use in the UploadModal
 * to choose which Visit we upload to.
 */
export const handleChange = ({
  setVisitIdForUpload,
}: {
  setVisitIdForUpload: (id: number) => unknown;
}) => (event: React.ChangeEvent<{value: unknown}>) => {
  setVisitIdForUpload(event.target.value as number);
};
