import {useCallback} from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {useApi} from '@src/api/useApi';
import {SCPChangeRequestStatusAction, SharedCarePlan} from '@src/models';

/**
 *
 * Uses SCPApi.changeRequestStatusAction method.
 */
export const useStatusAction = ({
  scp,
  changeRequestId,
  action,
}: {
  scp: Optional<SharedCarePlan>;
  changeRequestId: number;
  action: SCPChangeRequestStatusAction;
}) => {
  const api = useApi();

  return useCallback(() => {
    return api
      .scp(scp.map(m => m.id).orElse(0))
      .changeRequestStatusAction(changeRequestId, action);
  }, [api, scp, changeRequestId, action]);
};
