import {Optional} from '@ahanapediatrics/ahana-fp';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import React from 'react';

type Props = {
  value: Optional<boolean>;
  setValue: (b: boolean) => unknown;
  message: string;
  disabled?: boolean;
  style?: Object;
};

export const Acknowledge = ({
  value,
  setValue,
  message,
  disabled = false,
  style = {},
}: Props) => {
  return (
    <FormControlLabel
      style={style}
      control={
        <Checkbox
          disabled={disabled}
          checked={value.orElse(false)}
          onChange={e => {
            setValue(e.target.checked);
          }}
          color="primary"
        />
      }
      label={message}
    />
  );
};
