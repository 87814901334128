import {Optional} from '@ahanapediatrics/ahana-fp';
import {ConfirmStep} from './steps/ConfirmStep';
import {ConsentStep} from './steps/ConsentStep';
import {NonProfessionalInfoStep} from './steps/NonProfessionalInfoStep';
import {ProfessionalInfoStep} from './steps/ProfessionalInfoStep';
import {NonProfessionalBasics} from './steps/NonProfessionalInfoStep/UserInfoForm';
import {ProfessionalBasics} from './steps/ProfessionalInfoStep/ProviderInfoForm';
import {LegalDocument} from '@src/models';
import {StepComponent} from '@src/hooks/useSteps';

export type FirstTimeStep =
  | 'ProfessionalInformation'
  | 'NonProfessionalInformation'
  | 'LegalDocumentation'
  | 'Confirm';

export type UserBasics = NonProfessionalBasics | ProfessionalBasics;

export type StepProps = {
  userBasics: UserBasics;
  setUserBasics: (d: UserBasics) => unknown;
  email: Optional<string>;
  requiredDocs: readonly LegalDocument[];
  unsignedDocs: readonly LegalDocument[];
  setUnsignedDocs: (d: readonly LegalDocument[]) => unknown;
};
/**
 * This maps a step type to its name
 */
export const StepNames: Record<FirstTimeStep, string> = {
  ProfessionalInformation: 'Your Information',
  NonProfessionalInformation: 'Your Information',
  LegalDocumentation: 'Consent Forms',
  Confirm: 'Confirm',
};

/**
 * This maps a step type to its component
 */
export const StepComponents: Record<FirstTimeStep, StepComponent<StepProps>> = {
  NonProfessionalInformation: NonProfessionalInfoStep,
  ProfessionalInformation: ProfessionalInfoStep,
  LegalDocumentation: ConsentStep,
  Confirm: ConfirmStep,
};
