import {Divider, Typography} from '@material-ui/core';
import React, {MutableRefObject} from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {MedicalHistoryValues} from '../../NewPatient/steps/MedicalHistoryStep/PatientMedicalHistoryForm';
import {PatientDetailsValues} from '../patientDetailsUtils';
import {ConnectedLogin} from '../../NewPatient/stepFunctions';
import {PatientInformation} from '../PatientInformation';
import {MedicalHistorySummmary} from './MedicalHistorySummmary';
import {ConnectedLoginsSummary} from './ConnectedLoginsSummary';
import {useStyles} from './styles';
import {CareTeamCard} from '@src/components/ui/molecules/cards/professionals/CareTeamCard';
import {Banner} from '@src/components/ui/layout/Banner';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {useUser} from '@src/hooks';
import {ProviderDetails, UserType} from '@src/models';
import {reference} from '@src/util/stringManipulation/languageHelpers';

type Props = {
  moveGuard: MutableRefObject<(dir: 'back' | 'next') => Promise<boolean>>;
  medicalHistory?: MedicalHistoryValues;
  patientDetails: PatientDetailsValues;
  details: Optional<ProviderDetails>;
  providerNotKnown: boolean;
  includeMedicalHistory?: boolean;
  patientIsCurrentUser: boolean;
  connectedLogins?: ConnectedLogin[];
};

export function ConfirmStep({
  moveGuard,
  medicalHistory,
  patientDetails,
  details,
  providerNotKnown,
  includeMedicalHistory = false,
  patientIsCurrentUser,
  connectedLogins = [],
}: Props) {
  const [, userType] = useUser();
  const classes = useStyles();

  moveGuard.current = async () => true;

  return (
    <>
      <RequestHeader>Confirm</RequestHeader>
      <RequestInstructions>
        <p>Please review the details below.</p>
        <p>If everything looks right, click the Confirm button below.</p>
        <p>
          If you need to make any changes, you can do so by clicking Back and
          making the necessary changes.
        </p>
      </RequestInstructions>

      <Divider className={classes.divider} />
      <Typography
        variant="h2"
        className={classes.heading}
        style={{marginBottom: '1rem'}}
      >
        Patient
      </Typography>

      <PatientInformation patientDetails={patientDetails} />

      {connectedLogins.length > 0 && (
        <>
          <Divider className={classes.divider} />

          <ConnectedLoginsSummary connectedLogins={connectedLogins} />
        </>
      )}

      {includeMedicalHistory && medicalHistory && (
        <>
          <Divider className={classes.divider} />

          <MedicalHistorySummmary
            patientIsUser={patientIsCurrentUser}
            medicalHistory={medicalHistory}
            patientDetails={patientDetails}
          />
        </>
      )}

      <Divider className={classes.divider} />
      <Typography variant="h2" className={classes.heading}>
        Provider
      </Typography>
      {providerNotKnown ? (
        <Banner type="info">
          You weren't able to give us details about{' '}
          {reference('this patient', patientIsCurrentUser)('poss')} doctor at
          this time. You can always update this information later.
        </Banner>
      ) : (
        <CareTeamCard
          className={classes.contactCard}
          canEdit={false}
          includeMissing={false}
          details={details.orNull()}
          onEdit={() => {}}
          viewerType={userType}
        />
      )}

      {userType === UserType.Guardian && !patientIsCurrentUser && (
        <>
          <Divider className={classes.divider} />
          <Typography variant="h2">You</Typography>
          <Typography variant="body1">
            You told us that you are {patientDetails.firstName}'s{' '}
            {patientDetails.guardianRelationship}
          </Typography>
        </>
      )}
    </>
  );
}
