import React, {useState} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {Grid, Paper} from '@material-ui/core';
import {ExpandableSection} from '../ExpandableSection';
import {Mergeable} from '../ExpandableSection/isMergeable/isSectionMergeable';
import {useStyles as useGeneralStyles} from '../shared/styles';
import {FailText} from '../shared/ExplanationText/FailText';
import {useStyles} from './styles';
import {GeneralFolder} from './GeneralFolder';
import {VisitsFolders} from './VisitFolders';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {getEmptyMergeOptions, MergeOptions} from '@src/models/Patient';
import {TabLabels} from '@src/components/shared/patient/files/FilesOverview/TabLabels';
import {ScpFolders} from '@src/components/shared/patient/files/FilesOverview/ScpFolders';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';

type Props = {
  mergeable: Mergeable;
  mergeOptions: AsyncData<MergeOptions>;
};

export function FilesComparer({mergeable, mergeOptions}: Props) {
  const {
    files: {scpFiles, visitFiles, generalFiles},
  } = mergeOptions.getOptional().orElse(getEmptyMergeOptions());
  const classes = {...useStyles(), ...useGeneralStyles()};

  const [tab, setTab] = useState<number>(0);

  return (
    <ExpandableSection
      title="Files"
      mergeable={mergeable}
      mergeOptions={mergeOptions}
    >
      <Grid container direction="column">
        {mergeable === 'FAIL' && <FailText />}
        {mergeable === 'PASS' && (
          <Grid item className={classes.comparerMessage}>
            <ParagraphText style={{marginBottom: 0}}>
              The Files can be merged automatically.
            </ParagraphText>
            <ParagraphText style={{marginBottom: 0}}>
              Please review the list of Files below that will be included in the
              merge.{' '}
              <Bold>
                These files represent the files for both Patient records
                combined.
              </Bold>
            </ParagraphText>
          </Grid>
        )}

        <Paper
          style={{maxHeight: '100vh', overflow: 'auto', width: '60%'}}
          variant="outlined"
        >
          <Grid item xs={12} style={{maxHeight: '100%', overflow: 'auto'}}>
            <TabLabels scpFiles={scpFiles} tab={tab} setTab={setTab} />
            {tab === 0 && <GeneralFolder files={generalFiles} />}

            {tab === 1 && <VisitsFolders files={visitFiles} />}
            {tab === 2 && <ScpFolders files={scpFiles} />}
          </Grid>
        </Paper>
      </Grid>
    </ExpandableSection>
  );
}
