import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {Patient, LonelyPatient} from '@src/models';
import {AppAPI} from '@src/api/AppAPI';

export const onUpdatePatient = (
  api: AppAPI,
  patients: AsyncData<Patient>,
  setPatients: (p: readonly Patient[]) => unknown,
) => (pt: LonelyPatient) => {
  api
    .patient(pt.id)
    .get()
    .then(patient => {
      if (patients.isLoaded()) {
        const patientIndex = patients
          .value()
          .findIndex(p => +p.id === +patient.id);
        if (patientIndex < 0) {
          throw new Error(
            `Tried to update a non-existent Patient (${patient.id})`,
          );
        }
        if (patients.isLoaded()) {
          const newPatients = patients
            .update(patientIndex, patient)
            .getAllOptional()
            .orNull();

          if (newPatients) {
            setPatients(newPatients);
          }
        }
      } else {
        console.warn(
          `Tried to update patient ${patient.id} before Patients were loaded`,
        );
      }
    });
};
