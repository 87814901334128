import {Optional} from '@ahanapediatrics/ahana-fp';
import {useCallback} from 'react';
import {useApi} from '@src/api/useApi';
import {SharedCarePlan} from '@src/models';
import {useRequestHandler} from '@src/hooks/useRequestHandler';

const useUpdateChangeRequest = ({
  scp,
  editedValue,
  changeRequestId,
}: {
  scp: Optional<SharedCarePlan>;
  changeRequestId: number;
  editedValue: string;
}) => {
  const api = useApi();

  return useCallback(() => {
    return api
      .scp(scp.map(m => m.id).orElse(0))
      .updateChangeRequest(changeRequestId, {
        newValue: editedValue,
      });
  }, [api, scp, changeRequestId, editedValue]);
};

export const useOnUpdateChangeRequest = ({
  scp,
  runBeforeRequest,
  asyncRunAfterRequest,
  runFinally,
  changeRequestId,
  editedValue,
  runOnError,
}: {
  scp: Optional<SharedCarePlan>;
  runBeforeRequest: () => void;
  asyncRunAfterRequest: (scp: SharedCarePlan) => Promise<unknown>;
  runFinally: () => void;
  runOnError: () => void;
  changeRequestId: number;
  editedValue: string;
}) => {
  const updateChangeRequest = useUpdateChangeRequest({
    scp,
    changeRequestId,
    editedValue,
  });

  return useRequestHandler({
    runBeforeRequest,
    asyncRequest: updateChangeRequest,
    asyncRunAfterRequest,
    runFinally,
    runOnError,
  });
};
