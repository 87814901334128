import React from 'react';
import {OnCallPeriodPurpose} from '..';
import {ShiftGroupSelector, ShiftFrequency} from '../ShiftGroupSelector';
import {FormSection} from './FormSection';
import {CallPool} from '@src/models';

type Props = {
  frequency: ShiftFrequency;
  providerGroups: readonly CallPool[];
  purpose: OnCallPeriodPurpose;
  selectedProviderGroups: number[];
  setSelectedProviderGroups: (v: number[]) => unknown;
  requiredGroupsSelections?: number[];
};

export function CallPoolsSection({
  frequency,
  providerGroups,
  purpose,
  selectedProviderGroups,
  setSelectedProviderGroups,
  requiredGroupsSelections,
}: Props) {
  const content = (
    <ShiftGroupSelector
      callPools={providerGroups}
      frequency={frequency}
      purpose={purpose}
      selectedCallPools={selectedProviderGroups}
      setSelectedCallPools={setSelectedProviderGroups}
      requiredGroupsSelections={requiredGroupsSelections}
    />
  );

  return <FormSection title="Provider Groups" content={content} />;
}
