import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import {getLinkHref, Routes} from '@src/components/shared/general/AppRoutes';
import {Button} from '@src/components/ui/form/Button/index';
import {sm} from '@src/components/ui/theme';
import {LonelyPatient, LonelyVisit} from '@src/models';

const StyledButton = styled(Button)`
  margin-left: ${sm};
`;

type Props = {
  visits: AsyncData<LonelyVisit>;
  patient: LonelyPatient;
  history: RouteComponentProps<{}>['history'];
};

export function getActionButtons({visits, patient, history}: Props) {
  if (!visits.isLoaded()) {
    return [
      <div key={'checking-visits'}>
        Checking Visits <FontAwesomeIcon spin={true} icon={'spinner'} />
      </div>,
    ];
  }

  const actionSet = [
    <StyledButton
      key="scheduleButton"
      bStyle="primary"
      onClick={() => {
        history.push(`/create-visit/${patient.id}`);
      }}
    >
      Create Visit
    </StyledButton>,
  ];

  actionSet.unshift(
    <StyledButton
      bStyle="primary"
      onClick={() => {
        const patientId = patient.id;
        history.push(getLinkHref(Routes.SCP, {patientId}));
      }}
    >
      {patient.hasScp ? 'View' : 'Create'} Shared Care Plan
    </StyledButton>,
    <StyledButton
      bStyle="primary"
      onClick={() => {
        const patientId = patient.id;
        history.push(`/files-overview/${patientId}`);
      }}
    >
      View Files
    </StyledButton>,
    <StyledButton
      bStyle="primary"
      onClick={() => {
        const patientId = patient.id;
        history.push(`/patients/${patientId}/forms`);
      }}
    >
      All Patient Forms
    </StyledButton>,
  );

  return actionSet;
}
