import React from 'react';
import {Grid} from '@material-ui/core';
import clsx from 'clsx';
import {Button} from '@src/components/ui/form';
import {Modal} from '@src/components/ui/layout/Modal';
import {useStyles} from '@src/components/shared/VideoChat/ExamRoomModals/layout';

type Props = {
  showModal: boolean;
  onLeave: () => void;
  onReturnToRoom: () => void;
};

export function PauseVisitModal({showModal, onLeave, onReturnToRoom}: Props) {
  const classes = useStyles();

  return (
    <Modal
      show={showModal}
      title="Are you sure?"
      onClose={onReturnToRoom}
      modalActions={
        <>
          <Button onClick={onLeave} bStyle="warning">
            Pause
          </Button>
          <Button onClick={onReturnToRoom} bStyle="primary">
            Return To Visit
          </Button>
        </>
      }
    >
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          className={clsx(classes.modalRow, classes.modalInstructions)}
        >
          Don't worry, you'll still be able to return to this visit later if you
          select <span style={{fontWeight: 700}}>Pause</span>.
        </Grid>
      </Grid>
    </Modal>
  );
}
