import React from 'react';
import {Grid} from '@material-ui/core';
import {useStyles} from '../CreateProvider/styles';
import {EndpointForm} from './EndpointForm';
import {PrivatePage} from '@src/components/PrivatePage';
import {PageHeader} from '@src/components/ui/layout';

// eslint-disable-next-line import/no-unused-modules
export default function CreateEndpointPage() {
  const classes = useStyles();

  return (
    <PrivatePage>
      <PageHeader>Create an Endpoint</PageHeader>

      <Grid container direction="row" className={classes.formContainer}>
        <EndpointForm />
      </Grid>
    </PrivatePage>
  );
}
