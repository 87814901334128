import {Grid, makeStyles} from '@material-ui/core';
import React from 'react';
import {mdLg, md} from '../../theme';

const useStyles = makeStyles({
  buttonContainer: {
    marginTop: `${md}`,
  },
  formContainer: {
    padding: `${mdLg} 0`,
  },
});

type FormContainerProps = {
  submitButton: React.ReactNode;
  children: React.ReactNode;
};

export function FormContainer({submitButton, children}: FormContainerProps) {
  const classes = useStyles();

  return (
    <Grid container direction="row" className={classes.formContainer}>
      <Grid item xs={12} md={6}>
        {children}
      </Grid>
      <Grid item xs={12} className={classes.buttonContainer}>
        {submitButton}
      </Grid>
    </Grid>
  );
}
