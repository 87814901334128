import React, {ReactNode, useCallback, useReducer, useState} from 'react';
import {Grid} from '@material-ui/core';
import {S3UploaderContainer} from '@src/components/ui/organisms/uploaders/S3UploaderContainer';
import {UploadsList} from '@src/components/ui/organisms/uploaders/files/UploadsList';
import {uploadingReducer} from '@src/components/shared/PatientDashboard/uploadingReducer';
import {BasicInstructions} from '@src/components/ui/organisms/uploaders/files/FileUploader/instructionsTemplates/BasicInstructions';
import {useStyles} from '@src/components/ui/organisms/uploaders/files/FileUploader/styles';
import {AppFile} from '@src/models';
import {SigningResult} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';
import {useGetFileSignature} from '@src/hooks/s3Uploading/useGetFileSignature';

export function FileUploader({
  onFileUploadedToS3,
  selector,
  instructions,
}: {
  selector?: ReactNode;
  instructions?: ReactNode;
  onFileUploadedToS3: (
    result: SigningResult<AppFile>,
    file: File,
  ) => Promise<AppFile>;
}) {
  const onUpdate = () => {};
  const [uploadingFiles, dispatch] = useReducer(uploadingReducer(onUpdate), []);

  const [uploadedFiles, setUploadedFiles] = useState<AppFile[]>([]);
  const getFileSignature = useGetFileSignature();

  const classes = useStyles();

  const onAttachFileToResource = useCallback(
    async (result: SigningResult<AppFile>, file: File) => {
      onFileUploadedToS3(result, file)
        .then(m => {
          dispatch({
            type: 'complete',
            payload: {file},
          });

          setUploadedFiles([...uploadedFiles, m]);
        })
        .catch(() => {
          dispatch({
            type: 'error',
            payload: {
              file,
              error:
                'There was a problem adding this file. Please contact support for assistance',
            },
          });
        });
    },
    [setUploadedFiles, uploadedFiles, onFileUploadedToS3],
  );

  return (
    <>
      <Grid className={classes.rowContainer} container direction="row">
        {instructions ?? <BasicInstructions />}
      </Grid>
      {selector && (
        <Grid className={classes.rowContainer} container direction="row">
          {selector}
        </Grid>
      )}
      <Grid className={classes.rowContainer} container direction="row">
        <S3UploaderContainer
          getSignature={getFileSignature}
          onAttachFileToResource={onAttachFileToResource}
          onAddNewFile={file => {
            dispatch({
              type: 'add',
              payload: {file},
            });
          }}
          onUploadFail={(error, file) => {
            dispatch({
              type: 'error',
              payload: {
                file,
                error:
                  'There was a problem uploading this file. Please contact support for assistance',
              },
            });
          }}
          onCompleteFile={file => {}}
          onUpdateFile={(file, progress) => {
            dispatch({
              type: 'update',
              payload: {file, progress},
            });
          }}
        />
      </Grid>
      <Grid className={classes.rowContainer} container direction="row">
        <UploadsList
          uploadedFiles={uploadedFiles}
          uploadingFiles={uploadingFiles}
          hideTitle
          hideNoUploadsText
        />
      </Grid>
    </>
  );
}
