import styled from 'styled-components';
import {md, mdLg, lg} from '@src/components/ui/theme';
import {AsyncActionButton} from '@src/components/ui/form';
import {Button} from '@src/components/ui/form/Button/index';
import {ButtonRowContainer} from '@src/components/ui/layout/ButtonContainers';

export const StyledButton = styled(Button)`
  margin-top: ${mdLg};
  margin-right: ${md};
`;

export const StyledAsyncActionButton = styled(AsyncActionButton)`
  margin-top: ${mdLg};
  margin-right: ${md};
`;

export const StyledButtonRowContainer = styled(ButtonRowContainer)`
  width: auto;
  float: left;
  margin: ${mdLg} 0 ${md} 0;
  button {
    margin: 0 ${lg} 0 0;
  }
`;
