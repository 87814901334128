import {
  Controller,
  Control,
  FieldError,
  DeepMap,
  FormState,
} from 'react-hook-form';
import React from 'react';
import {TextInput, PhoneInput} from '@src/components/ui/form';
import {yObject, rqString} from '@src/schema/types';
import {getRequiredPhoneValidator} from '@src/util/forms/validation';

export type NonProfessionalBasics = {
  firstName: string;
  lastName: string;
  phone: string;
};

type Props = {
  control: Control<NonProfessionalBasics>;
  errors: DeepMap<NonProfessionalBasics, FieldError>;
  formState: FormState<NonProfessionalBasics>;
};

export const schema = yObject({
  firstName: rqString('Please tell us your first name'),
  lastName: rqString('Please tell us your last name'),
  phone: getRequiredPhoneValidator(),
});

export function UserInfoForm({control, errors, formState}: Props) {
  const {touched} = formState;

  return (
    <div>
      <Controller
        control={control}
        as={TextInput}
        name="firstName"
        title="First Name"
        required
        error={errors.firstName?.message}
        touched={touched.firstName}
      />
      <Controller
        control={control}
        as={TextInput}
        name="lastName"
        title="Last Name"
        required
        error={errors.lastName?.message}
        touched={touched.lastName}
      />
      <Controller
        control={control}
        as={PhoneInput}
        name="phone"
        title="Phone Number"
        required
        error={errors.phone?.message}
        touched={touched.phone}
      />
    </div>
  );
}
