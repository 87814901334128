import {Optional} from '@ahanapediatrics/ahana-fp';
import React from 'react';
import {SearchOrEnter} from '../layout';
import {handleEnterManually} from '../functions';
import {FormMode} from '../../../../util/provider/forms/addOrEdit';
import {ProviderDetails} from '@src/models';

type Props = {
  onChange: (details: Optional<ProviderDetails>, type: FormMode) => void;
  setEntryMode: (v: 'search' | 'type') => void;
  setShowDetails: (v: boolean) => void;
  setNoProviderSelected: (v: boolean) => void;
};

export function ManualEntryOption({
  onChange,
  setEntryMode,
  setShowDetails,
  setNoProviderSelected,
}: Props) {
  return (
    <SearchOrEnter
      onClick={() => {
        handleEnterManually({
          setShowDetails,
          setEntryMode,
          onChange,
          setNoProviderSelected,
        });
      }}
    >
      Enter doctor's information manually
    </SearchOrEnter>
  );
}
