import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useMemo} from 'react';
import {useAll} from '../../invoiceTargets/useAll';
import {FormResources, useFormResources} from './useFormResources';
import {InvoiceTarget} from '@src/models';

export type CreateDocumentPageResources = {
  invoiceTargets: AsyncData<InvoiceTarget>;
  formResources: FormResources;
};

export function useCreateDocumentPageResources() {
  const [invoiceTargets] = useAll();

  const formResources = useFormResources();

  return useMemo(() => ({invoiceTargets, formResources}), [
    invoiceTargets,
    formResources,
  ]);
}
