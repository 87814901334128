import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import * as yup from 'yup';
import {TextInput} from '../../../ui/form';
import {FormSection} from './FormSection';
import {yString} from '@src/schema/types';

export const reasonForVisitSchema = {
  chief_complaint: yup.string().when('$isSigning', {
    is: true,
    then: yup
      .string()
      .required('Please be sure to include the chief complaint'),
    otherwise: yString,
  }),
  hpi: yup.string().when('$isSigning', {
    is: true,
    then: yup
      .string()
      .required(
        'Please include a history of the present illness, however brief',
      ),
    otherwise: yString,
  }),
};

export type ReasonForVisitValues = {
  chief_complaint: string;
  hpi: string;
};

export const defaultReasonForVisitValues = (): ReasonForVisitValues => ({
  chief_complaint: '',
  hpi: '',
});

export const ReasonForVisitLabels = {
  chief_complaint: 'Chief complaint',
  hpi: 'History of present illness',
};

type Props = {
  isSigning: boolean;
};

export function ReasonForVisitSection({isSigning}: Props) {
  const {errors, control} = useFormContext<ReasonForVisitValues>();
  return (
    <FormSection title="Reason for Visit">
      <Controller
        control={control}
        name="chief_complaint"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            value={value}
            required={isSigning}
            autoExpand
            name={name}
            title={ReasonForVisitLabels.chief_complaint}
            placeholder="Chief complaint"
            onChange={onChange}
            onBlur={onBlur}
            touched={isTouched}
            error={errors.chief_complaint?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="hpi"
        render={({onChange, onBlur, value, name}, {isTouched}) => (
          <TextInput
            value={value}
            required={isSigning}
            autoExpand
            name={name}
            title={ReasonForVisitLabels.hpi}
            placeholder="HPI"
            onChange={onChange}
            onBlur={onBlur}
            touched={isTouched}
            error={errors.hpi?.message}
          />
        )}
      />
    </FormSection>
  );
}
