import styled from 'styled-components';
import {h2, md, primary, sm, xxl} from '../../../../ui/theme';

export const ConfigurationInstructions = styled.div`
  margin-bottom: ${sm};
`;

export const StyledSessionConfigForm = styled.div<{scrollable?: boolean}>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: ${md};

  @media screen and (max-width: 767px) {
    padding-bottom: ${xxl};
  }
`;

export const SessionConfigModalContents = styled.div`
  max-width: 75%;
  height: 100%;
`;

export const Section = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const VideoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;

  video {
    width: 100%;
  }
`;

export const MessageContainer = styled.div`
  font-size: ${h2};
  color: ${primary};
  margin-bottom: ${sm};
  text-align: center;
`;

export const ConfigSection = styled.div`
  margin-top: ${md};
`;
