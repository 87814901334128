import {Optional} from '@ahanapediatrics/ahana-fp';
import {format} from 'date-fns';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {RouteComponentProps, useHistory} from 'react-router';
import {useDecision} from '@optimizely/react-sdk';
import {Button} from '../../ui/form';
import {CreateVisitStep, StepComponents, StepNames} from './functions';
import {InputValue, useInput} from '@src/hooks/useInput';
import {useResources} from '@src/hooks';
import {
  $NewVisit,
  CallPool,
  Patient,
  ProviderDetails,
  Endpoint,
} from '@src/models';
import {mergeDateAndTime} from '@src/util/dateManipulation/mergeDateAndTime';
import {FlowPage} from '@src/components/shared/forms/FlowPage';
import {useApi} from '@src/api/useApi';
import {BlankForm} from '@src/models/BlankForm';
import {Features} from '@src/features';
import {isEmpty} from '@src/util/typeTests';
import {flashError} from '@src/components/shared/notifications/flash';

type VisitType = 'Scheduled' | 'OnDemand' | null;
export type StepProps = {
  blankForms: readonly BlankForm[];
  callPool: CallPool | null;
  details: Optional<ProviderDetails>;
  endpointId: InputValue<Endpoint['id'] | null>;
  patient: Patient | null;
  selectedBlankFormIds: InputValue<number[]>;
  setCallPool: (cp: CallPool | null) => unknown;
  setDetails: (p: Optional<ProviderDetails>) => unknown;
  startDate: InputValue<Date | null>;
  startTime: InputValue<Date | null>;
  visitNotes: InputValue<string>;
  visitType: InputValue<VisitType>;
};

type Props = RouteComponentProps<{
  patientId: string;
}>;

// eslint-disable-next-line import/no-unused-modules
export default function CreateVisit({match}: Props) {
  const api = useApi();
  const history = useHistory();
  const [newFormSystem] = useDecision(Features.NewFormSystem);

  const patientId = +(match.params.patientId ?? 0);

  const [callPool, setCallPool] = useState<CallPool | null>(null);
  const [details, setDetails] = useState(Optional.empty<ProviderDetails>());

  const [blankForms] = useResources<BlankForm>(
    () => api.blankForms().getAll(),
    [api],
  );

  const [patient] = useResources<Patient>(() => api.patient(patientId).get(), [
    api,
    patientId,
  ]);

  const moveGuard = useRef(async () => true);

  const dontUseCreditCard = useInput<boolean | null>(null);
  const dontUseInsurance = useInput<boolean | null>(null);
  const endpointId = useInput<Endpoint['id'] | null>(null);
  const selectedBlankFormIds = useInput<number[]>([]);
  const startDate = useInput<Date | null>(null);
  const startTime = useInput<Date | null>(null);
  const visitNotes = useInput<string>('');
  const visitType = useInput<VisitType>(null);

  const resetState = () => {
    dontUseCreditCard.set(null);
    dontUseInsurance.set(null);
    selectedBlankFormIds.set([]);
    setDetails(Optional.empty());
    startDate.set(null);
    startTime.set(null);
    visitNotes.set('');
    visitType.set(null);
  };

  /*
   * Whenever the Visit Type changes, we should clear the Call Pool
   */
  useEffect(() => {
    setCallPool(null);
  }, [visitType.value]);

  const getPlan = useCallback(() => {
    const stepPlan: CreateVisitStep[] = ['VisitType'];

    if (visitType.value === 'Scheduled') {
      stepPlan.push('ProviderSelection');
    }

    stepPlan.push('VisitDetails');
    stepPlan.push('ProviderGroup');

    if (
      newFormSystem.enabled &&
      !isEmpty(blankForms.getAllOptional().orNothing())
    ) {
      stepPlan.push('PatientForms');
    }

    stepPlan.push('Confirmation');

    return stepPlan;
  }, [blankForms, newFormSystem.enabled, visitType.value]);

  const readyForFlow =
    patient.isLoaded() &&
    (newFormSystem.enabled ? blankForms.isLoaded() : true);

  const performTask = useCallback(async () => {
    const start = mergeDateAndTime(
      startDate.value,
      startTime.value ? format(startTime.value, 'HH:mm') : '',
    );

    const visitData: $NewVisit = {
      blankFormIds: selectedBlankFormIds.value,
      callPoolId: callPool?.id ?? 0,
      dontUseCreditCard: dontUseCreditCard.value ?? false,
      dontUseInsurance: dontUseInsurance.value ?? false,
      endpointId: endpointId.value ?? undefined,
      healthcareNotes: visitNotes.value,
      onDemand: visitType.value === 'OnDemand',
      providerDetails: details.orNothing(),
      start,
    };

    return api
      .patient(patientId)
      .createVisit(visitData)
      .catch((error: unknown) => {
        flashError(
          <>
            Something went wrong setting up this visit. Please contact Support
            or try again.
          </>,
        );

        return Promise.reject(error);
      });
  }, [
    api,
    callPool,
    details,
    dontUseCreditCard,
    dontUseInsurance,
    endpointId,
    patientId,
    selectedBlankFormIds,
    startDate,
    startTime,
    visitNotes,
    visitType,
  ]);

  const onTaskPerformed = async () => {
    history.push(`/patient/${patientId}`);
    return true;
  };

  const stepProperties: StepProps = {
    blankForms: blankForms.getAllOptional().orElse([]),
    callPool,
    details,
    endpointId,
    patient: patient.getOptional().orNull(),
    selectedBlankFormIds,
    setCallPool,
    setDetails,
    startDate,
    startTime,
    visitNotes,
    visitType,
  };

  return (
    <FlowPage
      dataReady={readyForFlow}
      title="Create Visit"
      moveGuard={moveGuard}
      getPlan={getPlan}
      resetState={resetState}
      StepComponents={StepComponents}
      StepNames={StepNames}
      stepProperties={stepProperties}
      onCancel={() => history.goBack()}
      onPerformTask={performTask}
      onTaskPerformed={onTaskPerformed}
      getForwardNavigationButtons={({
        proceedToNextStep,
        isLastStep,
        nextAvailable,
      }) => [
        <Button
          disabled={!nextAvailable}
          bStyle="primary"
          onClick={proceedToNextStep}
        >
          {isLastStep ? 'Confirm' : 'Next'}
        </Button>,
      ]}
    />
  );
}
