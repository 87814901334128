import {format} from 'date-fns';
import React, {useState} from 'react';
import {addBreadcrumb, Severity} from '@sentry/browser';
import {Guardian, ResponsiblePerson} from '../../../models';
import {PrivatePage} from '../../PrivatePage';
import {Button} from '../../ui/form';
import {DataPoint, Section, SectionTitle} from '../../ui/layout/InfoPage';
import {PageHeader} from '../../ui/layout/PageHeader';
import {PhoneNumber} from '../../ui/layout/PhoneNumber';
import {GuardianInfoModal} from './GuardianInfoModal';
import {DataPointName, DataPointValue} from './layout';
import {isEmpty} from '@src/util/typeTests';
import {useUser} from '@src/hooks/useUser';
import {useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {ApplicationErrorBoundary} from '@src/components/shared/general/ApplicationErrorBoundary';
import {UserId} from '@src/models/User';

// eslint-disable-next-line import/no-unused-modules
export default function GuardianInfoPage() {
  const api = useApi();
  const [user, , updateUser] = useUser();
  const [showGuardianModal, setShowGuardianModal] = useState(false);
  const [guardian] = useResources<Guardian>(
    () => api.guardian(user.getOptional().property('id', 0 as UserId)).get(),
    [api, user],
  );

  if (!guardian.isLoaded()) {
    return (
      <PrivatePage>
        <PageLoading
          active={!guardian.isLoaded()}
          message="Loading patient information"
          fullSize
        >
          <div></div>
        </PageLoading>
      </PrivatePage>
    );
  }

  const responsiblePersonDetails = guardian
    .map(g => g.responsiblePersonDetails)
    .singleValue()
    .orNull();

  if (responsiblePersonDetails === null) {
    addBreadcrumb({
      category: 'data',
      level: Severity.Error,
      message: `responsiblePersonDetails not loaded for guardian with id ${guardian
        .map(g => g.id)
        .orElse(0 as UserId)} in GuardianInfoPage component`,
    });
    console.error(
      `responsiblePersonDetails not loaded for guardian with id ${guardian
        .map(g => g.id)
        .orElse(0 as UserId)} in GuardianInfoPage component`,
    );
    return <ApplicationErrorBoundary />;
  }

  const {
    fullName,
    dob,
    email,
    phone,
    address: {line1, line2, city, state, zipcode},
  } = responsiblePersonDetails;

  return (
    <PrivatePage>
      <PageHeader>About me</PageHeader>
      <Section>
        <SectionTitle>My Info</SectionTitle>
        <DataPoint>
          <DataPointName>Name: </DataPointName>
          <DataPointValue>{fullName}</DataPointValue>
        </DataPoint>
        <DataPoint>
          <DataPointName>DOB: </DataPointName>
          <DataPointValue>
            {dob.map(d => format(d, 'do MMMM yyyy')).orElse('Not given')}
          </DataPointValue>
        </DataPoint>
        <DataPoint>
          <DataPointName>Email: </DataPointName>
          <DataPointValue>{email.orElse('-')}</DataPointValue>
        </DataPoint>
        <DataPoint>
          <DataPointName>Phone: </DataPointName>
          <PhoneNumber no={phone ?? ''} blankFiller="Not given" />
        </DataPoint>
        <DataPoint>
          <DataPointName>Address:</DataPointName>
          <DataPointValue>
            {isEmpty(line1) ? (
              'Not given'
            ) : (
              <>
                {line1}
                <br />
                {(line2 ?? '').length > 0 && (
                  <>
                    {line2}
                    <br />
                  </>
                )}
                {city}
                <br />
                {state} {zipcode}
              </>
            )}
          </DataPointValue>
        </DataPoint>
      </Section>
      <Button onClick={() => setShowGuardianModal(true)} bStyle="primary">
        Edit
      </Button>
      {showGuardianModal && (
        <GuardianInfoModal
          show
          onHide={() => setShowGuardianModal(false)}
          onClose={() => setShowGuardianModal(false)}
          responsiblePerson={responsiblePersonDetails}
          onChangeResponsiblePerson={async (rp: ResponsiblePerson) =>
            api.getUser().then(updateUser)
          }
        />
      )}
    </PrivatePage>
  );
}
