import * as React from 'react';
import {useCallback, useState, useRef, useEffect} from 'react';
import {Modal} from '../../layout/Modal';
import {ParagraphText} from '../../layout/text/body/ParagraphText';
import {TextInput} from '../TextInput';
import {NIHRequester} from './NIHRequester';
import {
  ICD10ModalContent,
  ICD10TableContainer,
  ICD10Table,
  ICD10TableHead,
  ICD10TableRow,
  ICD10TableHeadCell,
  ICD10TableBody,
  ICD10TableCell,
} from './layout';
import {ICD10Type} from '.';
import {ICD10Code} from '@src/models';

type Props = {
  show: boolean;
  initialValue: string;
  onSelectCode: (code: ICD10Type) => void;
  onClose: () => void;
};

export function ICD10Modal({initialValue, onClose, onSelectCode, show}: Props) {
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState<ICD10Code[]>([]);
  const [value, setValue] = useState(initialValue);
  const requester = useRef(new NIHRequester());

  const onSelect = useCallback(
    (code: ICD10Type) => {
      onSelectCode(code);
      onClose();
    },
    [onClose, onSelectCode],
  );

  const searchForCode = useCallback((term: string) => {
    setLoading(true);
    requester.current.get(term, 500).then(cs => {
      setLoading(false);
      setCodes(cs.sort((a, b) => a.code.localeCompare(b.code)));
    });
  }, []);

  useEffect(() => {
    console.log('Initial Search: ' + value);
    searchForCode(value);
  }, [value, searchForCode]);

  return (
    <Modal show={show} title="ICD10 Code Lookup" onClose={onClose}>
      <ICD10ModalContent>
        <ParagraphText>
          Type in your diagnosis or an ICD10 code to search the NIH database.
        </ParagraphText>
        <ParagraphText>
          The search returns up to a maximum of 500 results, so if you don't
          find what you're looking for, try refining your search terms further.
        </ParagraphText>
        <TextInput
          name={'icd-10'}
          value={value}
          onChange={e => {
            setValue(e.target.value);
            searchForCode(e.target.value);
          }}
          title={'Search'}
          placeholder={'Type a diagnosis or IDC10 code here'}
        />
        <ICD10TableContainer>
          <ICD10Table>
            <ICD10TableHead>
              <ICD10TableRow>
                <ICD10TableHeadCell>Code</ICD10TableHeadCell>
                <ICD10TableHeadCell>Description</ICD10TableHeadCell>
              </ICD10TableRow>
            </ICD10TableHead>
            <ICD10TableBody>
              {loading && (
                <ICD10TableRow>
                  <ICD10TableCell colSpan={2}>
                    Searching for codes
                  </ICD10TableCell>
                </ICD10TableRow>
              )}
              {!loading && codes.length === 0 && (
                <ICD10TableRow>
                  <ICD10TableCell colSpan={2}>No matches found</ICD10TableCell>
                </ICD10TableRow>
              )}
              {!loading &&
                codes.length > 0 &&
                codes.map(code => (
                  <ICD10TableRow
                    key={code.code}
                    onClick={() => {
                      onSelect(code);
                    }}
                  >
                    <ICD10TableCell>{code.code}</ICD10TableCell>
                    <ICD10TableCell>{code.description}</ICD10TableCell>
                  </ICD10TableRow>
                ))}
            </ICD10TableBody>
          </ICD10Table>
        </ICD10TableContainer>
      </ICD10ModalContent>
    </Modal>
  );
}
