import {makeStyles} from '@material-ui/styles';
import {Autocomplete, useJsApiLoader} from '@react-google-maps/api';
import classnames from 'classnames';
import update from 'immutability-helper';
import * as React from 'react';
import {
  ChangeEventHandler,
  FormEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import {createGlobalStyle, css} from 'styled-components';
import {notifications} from '../../theme/z-indexes';
import {forceUpper} from '../Input';
import {TextInput} from '../TextInput';
import {
  convertPlaceToAddress,
  getDefaultStreetAddress,
  StreetAddress,
} from './functions';
import {
  AddressDetails,
  AddressLine2Input,
  AddressWideInput,
  City,
  EnterManually,
  HouseAndStreet,
  SearchBoxContainer,
  StateAndZip,
} from './layout';

const addressSearch = css`
  .pac-container {
    z-index: ${notifications};
  }
`;

export const AddressSearchStyle = createGlobalStyle`
  ${addressSearch}
`;

const useStyles = makeStyles(() => ({
  stateInput: {
    marginRight: '8px',
    width: '9%',
  },

  zipInput: {
    width: '15%',
  },
}));

type Props = {
  disabled?: boolean;
  required?: boolean;
  error?: string | boolean;
  touched?: boolean;
  value?: StreetAddress;
  onChange?: (address: StreetAddress) => void;
};

export const AddressInput = ({
  value,
  disabled = false,
  required = false,
  touched,
  error,
  onChange,
}: Props) => {
  const classes = useStyles();
  const [hideDetails, setHideDetails] = useState(false);
  const [searchString, setSearcString] = useState('');
  const autocompleteComponent = useRef<google.maps.places.Autocomplete>();

  const attributionsRef = useRef<HTMLDivElement>(null);

  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDW14uJJcDlHILiZ17d49yTWGdfAer_uHc',
    libraries: ['places'],
  });

  const onAutocompleteLoad = useCallback(
    (ac: google.maps.places.Autocomplete) => {
      autocompleteComponent.current = ac;
    },
    [],
  );

  const toggleVisibility = () => {
    if (!hideDetails && onChange) {
      onChange(getDefaultStreetAddress());
    }
    setHideDetails(!hideDetails);
  };

  const handleChange = (
    name: string,
    modifier: <T extends HTMLInputElement>(
      e: FormEvent<T>,
    ) => unknown = () => {},
  ): ChangeEventHandler<HTMLInputElement> => e => {
    modifier(e);
    const {value: tgtValue} = e.target;
    if (onChange) {
      onChange(
        update(value ?? getDefaultStreetAddress(), {
          [name]: {
            $set: tgtValue,
          },
        }),
      );
    }
  };

  const locationChosen = () => {
    const prediction = autocompleteComponent.current?.getPlace() ?? null;
    const placeId = prediction?.place_id ?? null;
    if (placeId === null) {
      return;
    }
    const service = new google.maps.places.PlacesService(
      attributionsRef.current as HTMLDivElement,
    );
    service.getDetails({placeId}, place => {
      const address = convertPlaceToAddress(place);
      setHideDetails(false);
      if (onChange) {
        onChange(address);
      }
    });
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <div className={classnames(['address-input', {disabled}])}>
      <div>
        <SearchBoxContainer show={hideDetails}>
          <Autocomplete
            onLoad={onAutocompleteLoad}
            onPlaceChanged={locationChosen}
          >
            <TextInput
              name="address_search"
              value={searchString}
              onChange={e => setSearcString(e.currentTarget.value)}
              placeholder="Start typing to search for your address"
            />
          </Autocomplete>
        </SearchBoxContainer>
      </div>
      {!hideDetails && value && (
        <AddressDetails>
          <HouseAndStreet>
            <AddressWideInput
              required={required}
              error={error}
              touched={touched}
              value={value.line1}
              title="Street address"
              name="line1"
              onChange={handleChange('line1')}
            />
            <AddressLine2Input
              value={value.line2}
              title="Apt/Unit"
              name="line2"
              onChange={handleChange('line2')}
            />
          </HouseAndStreet>
          <City>
            <AddressWideInput
              required={required}
              value={value.city}
              title="City"
              name="city"
              onChange={handleChange('city')}
            />
            <StateAndZip>
              <TextInput
                maxLength={2}
                required={required}
                className={classes.stateInput}
                value={value.state}
                title="State"
                name="state"
                onChange={handleChange('state', forceUpper)}
              />
              <TextInput
                required={required}
                className={classes.zipInput}
                value={value.zipcode}
                title="Zip Code"
                name="zipcode"
                onChange={handleChange('zipcode')}
              />
            </StateAndZip>
          </City>
        </AddressDetails>
      )}
      <EnterManually onClick={toggleVisibility}>
        {hideDetails ? 'Enter address manually' : 'Search for address'}
      </EnterManually>
      <div ref={attributionsRef} />
    </div>
  );
};

export {StreetAddress} from './functions';
