import Done from '@material-ui/icons/Done';
import React from 'react';
import {
  Indicator,
  Line,
  LineContainer,
  StepContainer,
  StepName,
  StepNumber,
} from './layout';

type Props = {
  complete: boolean;
  last?: boolean;
  name: string;
  step: number;
  upcoming: boolean;
};

const Step = ({complete, last, name, step, upcoming}: Props) => (
  <StepContainer last={last || false}>
    <Indicator upcoming={upcoming}>
      <StepNumber>
        {complete ? <Done style={{color: 'white'}} /> : step + 1}
      </StepNumber>
    </Indicator>
    <LineContainer>
      <Line></Line>
    </LineContainer>
    <StepName>{name || 'Step Name'}</StepName>
  </StepContainer>
);

export default Step;
