import {Grid} from '@material-ui/core';
import React from 'react';
import {CompletedUpload} from './CompletedUpload';
import UploadingFile from './File';
import {usePageContext} from '@src/components/separateAdminApp/CreateDocumentPage';
import {useStyles} from '@src/components/ui/organisms/uploaders/files/UploadsList/styles';
import {InProgressFile} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';
import {isNothing} from '@src/util/typeTests';

const toIncompleteFile = (f: InProgressFile) => (
  <UploadingFile
    key={f.file.name}
    file={f.file}
    progress={f.progress}
    completed={f.completed}
    error={f.error}
  />
);

type Props = {
  uploadingFiles: InProgressFile[];
  onClickEyeIcon?: () => void;
};

export const UploadsList = ({uploadingFiles, onClickEyeIcon}: Props) => {
  const classes = useStyles();
  const {
    formResources: {uploadedFile},
  } = usePageContext();

  const uploading = uploadingFiles.length;

  return (
    <Grid container direction="row" style={{marginTop: '2rem'}}>
      <Grid item xs={12} className={classes.gridItem}>
        {!isNothing(uploadedFile) && (
          <CompletedUpload
            key={uploadedFile.name}
            file={uploadedFile}
            onClickEyeIcon={onClickEyeIcon}
          />
        )}
      </Grid>

      {uploading > 0 && (
        <Grid item xs={12} className={classes.gridItem}>
          {uploadingFiles.map(toIncompleteFile)}
        </Grid>
      )}
    </Grid>
  );
};
