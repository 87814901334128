import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableContainer} from '@material-ui/core';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {ExpandableSection} from '../ExpandableSection';
import {
  isSectionMergeable,
  Mergeable,
} from '../ExpandableSection/isMergeable/isSectionMergeable';
import {TableHeader} from '../shared/Tables/TableHeader';
import {useStyles} from '../shared/Tables/styles';
import {getDetailColumnHeadings} from '../shared/Tables/TableHeader/detailsHeaderUtils';
import {useStyles as useGeneralStyles} from '../shared/styles';
import {Rows} from './Rows';
import {populateResultWithLoadedData} from './Rows/utils/medicalHistoryResultFunctions';
import {MedicalHistoryResult} from './Rows/utils/resultFunctions';
import {
  getMedicalHistoryRow,
  MedicalHistoryUIElement,
} from './Rows/utils/getMedicalHistoryRow';
import {Patient} from '@src/models';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';
import {useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {allLoaded} from '@src/util/apiHelpers';

type Props = {
  patientOne: AsyncData<Patient>;
  patientTwo: AsyncData<Patient>;
  firstId: number;
  secondId: number;
  setMergeable: (mergeable: Mergeable) => unknown;
  mergeable: Mergeable;
  medicalHistoryResult: MedicalHistoryResult;
  setMedicalHistoryResult: (r: MedicalHistoryResult) => unknown;
};

export function MedicalHistoryComparer({
  patientOne,
  patientTwo,
  firstId,
  secondId,
  setMergeable,
  mergeable,
  medicalHistoryResult,
  setMedicalHistoryResult,
}: Props) {
  const classes = {...useStyles(), ...useGeneralStyles()};

  const headings = getDetailColumnHeadings({patientOne, patientTwo});

  const api = useApi();

  const [rows, setRows] = useState<MedicalHistoryUIElement[] | null>(null);

  const [patientOneHistory] = useResources(
    () => api.patient(firstId).getCurrentMedicalHistory(),
    [api, firstId],
  );

  const [patientTwoHistory] = useResources(
    () => api.patient(secondId).getCurrentMedicalHistory(),
    [api, secondId],
  );

  // Setting the rows should happen before populating
  // the result.
  const readyToGetRows =
    allLoaded(patientOne, patientTwo, patientOneHistory, patientTwoHistory) &&
    rows === null;

  useEffect(() => {
    if (readyToGetRows) {
      setRows(
        getMedicalHistoryRow({
          patientOneHistory: patientOneHistory.getOptional().orNull(),
          patientTwoHistory: patientTwoHistory.getOptional().orNull(),
        }),
      );
    }
  }, [
    patientOne,
    patientTwo,
    patientOneHistory,
    patientTwoHistory,
    setMergeable,
    rows,
  ]);

  useEffect(() => {
    if (
      allLoaded(patientOne, patientTwo, patientOneHistory, patientTwoHistory) &&
      rows !== null
    ) {
      const newResult = populateResultWithLoadedData({
        result: medicalHistoryResult,
        rows,
      });

      setMedicalHistoryResult(newResult);

      setMergeable(
        isSectionMergeable({
          p1: patientOne,
          p2: patientTwo,
          sectionName: 'medicalHistory',
          medicalHistoryResult: newResult,
        }),
      );
    }
  }, [
    patientOne,
    patientTwo,
    patientOneHistory,
    patientTwoHistory,
    setMergeable,
    rows,
  ]);

  return (
    <ExpandableSection
      title="Medical History"
      mergeable={rows === null ? 'CHECKING' : mergeable}
    >
      <TableContainer>
        <div className={classes.comparerMessage}>
          {mergeable === 'CONFLICT' && (
            <>
              <ParagraphText>
                The Patient's Medical History cannot be merged in their current
                state because there are some conflicting fields.
              </ParagraphText>
              <ParagraphText>
                For each field, please choose to keep either one of the record's
                values or add an override value.
              </ParagraphText>
              <ParagraphText>
                The final value will be shown in the <Bold>Result</Bold> column
                for each field.
              </ParagraphText>
            </>
          )}
          {mergeable === 'PASS' && (
            <ParagraphText>
              This Patient's Medical History can be merged.
            </ParagraphText>
          )}
        </div>

        <Table size="small">
          <TableHeader headings={headings} />
          <TableBody>
            <Rows
              patientOne={patientOne}
              patientTwo={patientTwo}
              result={medicalHistoryResult}
              setResult={setMedicalHistoryResult}
              rows={rows ?? []}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </ExpandableSection>
  );
}
