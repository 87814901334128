import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useState} from 'react';
import {CareTeamCard} from '../../ui/molecules/cards/professionals/CareTeamCard';
import {ProviderInfoFormStyled, SearchablePcpDisplay} from './layout';
import {ManualEntry} from './ManualEntry';
import {ChooseEntryType} from './ChooseEntryType';
import {NoProviderSelectedBanner} from './NoProviderSelectedBanner';
import {SearchByProvider} from '@src/components/ui/form/search/SearchByProvider';
import {ProviderDetails, UserType} from '@src/models';
import {getProviderDescription} from '@src/util/provider/providerDescription';
import {isEmpty} from '@src/util/typeTests';
import {Context, FormMode} from '@src/util/provider/forms/addOrEdit';
import {ProviderDetailsId} from '@src/models/ProviderDetails';

type Props = {
  onChange: (pcpInfo: Optional<ProviderDetails>, type: FormMode) => void;
  details: Optional<ProviderDetails>;
  providerDescription: string;
  userType: UserType;
  sharedCarePlanContextOptions?: {
    hideManualOption?: boolean;
    setNoSCPOwner?: () => void;
  };
  selectionOrEditContext: Context;
};

export function ProviderSelector({
  details,
  onChange,
  providerDescription,
  userType,
  sharedCarePlanContextOptions,
  selectionOrEditContext,
}: Props) {
  const infoId = details.map(p => p.id).orElse(0 as ProviderDetailsId);
  const providerId = details.map(p => p.providerId).orNothing();

  const searchable = details.map(p => p.searchable).orElse(false);

  const [showDetails, setShowDetails] = useState(
    details.map(d => !isEmpty(d.lastName)).orElse(false),
  );
  const [entryMode, setEntryMode] = useState<'search' | 'type'>('search');

  const [noProviderSelected, setNoProviderSelected] = useState(
    !details.isPresent(),
  );

  const isNew = infoId === 0;

  const searchForProvider = !noProviderSelected && !showDetails;
  const enterProvider =
    !noProviderSelected && (showDetails || entryMode === 'type') && !searchable;
  const knownProvider = (showDetails || entryMode === 'type') && searchable;

  return (
    <ProviderInfoFormStyled>
      {noProviderSelected && (
        <NoProviderSelectedBanner
          selectionOrEditContext={selectionOrEditContext}
        />
      )}

      {searchForProvider && (
        <SearchByProvider
          name="provider"
          userType="Provider"
          title={providerDescription}
          placeholder="Search our system for your doctor"
          disabled={false}
          selectedUser={
            !isNew && providerId ? details.property('fullName', '') : ''
          }
          selectUser={p => {
            onChange(Optional.of(p), FormMode.ProviderSearch);
            setShowDetails(true);
          }}
          renderUser={user =>
            user ? <div>{getProviderDescription(user)}</div> : <div />
          }
          touched={false}
        />
      )}

      {enterProvider && (
        <div style={{marginBottom: '1rem'}}>
          <ManualEntry
            details={details}
            userType={userType}
            onChange={onChange}
            selectionOrEditContext={selectionOrEditContext}
          />
        </div>
      )}

      {knownProvider && (
        <SearchablePcpDisplay>
          <CareTeamCard
            canEdit={false}
            includeMissing={false}
            details={details.orNull()}
            onEdit={() => {}}
            viewerType={userType}
          />
        </SearchablePcpDisplay>
      )}

      <ChooseEntryType
        details={details}
        onChange={onChange}
        userType={userType}
        selectionOrEditContext={selectionOrEditContext}
        noProviderSelected={noProviderSelected}
        showDetails={showDetails}
        entryMode={entryMode}
        setShowDetails={setShowDetails}
        setEntryMode={setEntryMode}
        setNoProviderSelected={setNoProviderSelected}
        sharedCarePlanContextOptions={sharedCarePlanContextOptions}
      />
    </ProviderInfoFormStyled>
  );
}
