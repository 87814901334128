import React, {useEffect} from 'react';
import {Grid} from '@material-ui/core';
import {BannerContainer} from '../layout';
import {StepProps} from '.';
import {PhoneInput, TextInput} from '@src/components/ui/form';
import {Banner} from '@src/components/ui/layout/EmergencyAlert';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';
import {RequestHeader} from '@src/components/ui/layout/NewThingRequest';
import {InputValue} from '@src/hooks/useInput';

type Props = StepProps & {
  primaryReason: InputValue<string>;
  fallbackNumber: InputValue<string>;
  location: InputValue<string>;
};

export function DetailsStep({
  fallbackNumber,
  location,
  primaryReason,
  setNextAvailable,
}: Props) {
  useEffect(() => {
    setNextAvailable(
      primaryReason.value.trim().length > 0 &&
        fallbackNumber.value.trim().length >= 14 &&
        location.value.trim().length > 0,
    );
  }, [primaryReason, location, fallbackNumber, setNextAvailable]);

  return (
    <>
      <RequestHeader>Details</RequestHeader>
      <Grid container>
        <TextInput
          required
          lines={1}
          name="primaryReason"
          title="Reason for Visit"
          autoExpand
          value={primaryReason.value}
          onChange={e => primaryReason.set(e.target.value)}
          instructions="What's the reason for this visit?"
        />

        <PhoneInput
          required
          name="phone"
          title="Phone"
          value={fallbackNumber.value}
          onChange={phone => fallbackNumber.set(phone)}
          instructions="What number can we reach you at if there a connection problems?"
        />
        <ParagraphText>
          <Bold>Note:</Bold> The phone number that you provide is a backup
          number to reach you in case the video connection has any difficulties.
        </ParagraphText>
        <TextInput
          required
          name="location"
          title="Zip Code"
          type="number"
          value={location.value}
          onChange={e => location.set(e.target.value)}
          instructions="Please enter your zip code"
        />
        <BannerContainer>
          <Banner
            title="If this is an emergency, please call 911."
            message="Seeing providers in Refyne Connected Care may require a wait time of a few minutes and is not intended for life-threatening emergencies."
            type="caution"
          />
        </BannerContainer>
      </Grid>
    </>
  );
}
