import React from 'react';
import {Grid} from '@material-ui/core';
import {usePageContext} from '..';
import {LightHeading} from '@src/components/ui/layout/text/heading/LightHeading';
import {ConfirmModal} from '@src/components/ui/layout/ConfirmModal';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';
import {AddableList} from '@src/components/ui/molecules/lists/AddableList';
import {NAMES} from '@src/components/ui/layout/text/names';

export function Available() {
  const {
    clientConfigurationsResources: {
      addableSectionResources: {
        addableClientConfigurations,
        onAddClientConfiguration,
        handleAddClicked,
        handleCancelAdd,
        clientConfigurationIdToAdd,
      },
    },
  } = usePageContext();

  const mappable = addableClientConfigurations
    .getAllOptional()
    .orElse([])
    .map(e => ({label: e.customerCode, id: e.id}));

  return (
    <>
      <Grid item xs={12}>
        <LightHeading>Available</LightHeading>
      </Grid>
      <Grid item xs={12}>
        <AddableList
          handleAddClicked={handleAddClicked}
          mappable={mappable}
          emptyMessage={`This  ${toTitleCase(
            NAMES.callPool,
          )} currently has no  ${toTitleCase(
            NAMES.customerCode,
          )}s that can be added to it.`}
        />
      </Grid>

      <ConfirmModal
        onConfirm={onAddClientConfiguration}
        text={`Confirming will add this ${toTitleCase(
          NAMES.customerCode,
        )} to the ${toTitleCase(NAMES.callPool)}.`}
        show={clientConfigurationIdToAdd !== null}
        onHide={handleCancelAdd}
        onCancel={handleCancelAdd}
        cancelText="Cancel"
        confirmText={`Add ${toTitleCase(NAMES.customerCode)}`}
        titleText={`Add ${toTitleCase(NAMES.customerCode)}`}
      />
    </>
  );
}
