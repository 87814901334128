import {Typography} from '@material-ui/core';
import React from 'react';
import {useStyles} from './layout';
import {
  ButtonContainer,
  LabelContainer,
  LabelContents,
} from '@src/components/shared/SelectorLabels';
import {Button} from '@src/components/ui/form';
import {ProviderDetails} from '@src/models';
import {providerAndQualification} from '@src/util/provider';
import {ProviderDetailsId} from '@src/models/ProviderDetails';

type Props = {
  details: ProviderDetails;
  selected: boolean;
  onClick: (id: ProviderDetailsId) => unknown;
};

export function ProviderLabel({details, selected, onClick}: Props) {
  const classes = useStyles();
  return (
    <LabelContainer selected={selected} style={{padding: '.5em .5em'}}>
      <LabelContents>
        <Typography variant="h4" className={classes.poolName}>
          {providerAndQualification(details)}
        </Typography>
      </LabelContents>
      <ButtonContainer>
        <Button
          bStyle="contained"
          onClick={e => {
            e.preventDefault();
            onClick(details.id ?? (0 as ProviderDetailsId));
          }}
        >
          Select
        </Button>
      </ButtonContainer>
    </LabelContainer>
  );
}
