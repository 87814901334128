import {yupResolver} from '@hookform/resolvers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import React, {MutableRefObject, useCallback, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styled from 'styled-components';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {PageLoading} from '../../../../ui/atoms/progressBarsAndIndicators/PageLoading';
import {UseMyDetailsSwitch} from './UseMyDetailsSwitch';
import {
  patientDetailsSchema,
  PatientDetailsValues,
  patientToValues,
  personToPatientDetailsValues,
} from '@src/components/shared/forms/SharedPatient/patientDetailsUtils';
import {
  DateInput,
  PhoneInput,
  SelectInput,
  TextInput,
} from '@src/components/ui/form';
import {MuiCheckBoxInput} from '@src/components/ui/form/MuiCheckBoxInput';
import {useUser} from '@src/hooks';
import {Guardian, ResponsiblePerson, UserType} from '@src/models';
import {getKeys} from '@src/util/objectManipulation/getKeys';
import {useApi} from '@src/api/useApi';
import {HelpToolTip} from '@src/components/ui/atoms/buttonsAndIcons/HelpToolTip';
import {reference} from '@src/util/stringManipulation/languageHelpers';

type Props = {
  mode: 'create' | 'update';
  moveGuard: MutableRefObject<(dir: 'back' | 'next') => Promise<boolean>>;
  patientDetails: PatientDetailsValues | undefined;
  setPatientDetails: (d: PatientDetailsValues) => unknown;
  disabled?: boolean;
  existingPersonForIndependentPatient: Optional<ResponsiblePerson>;
  setExistingPersonForIndependentPatient: (
    v: Optional<ResponsiblePerson>,
  ) => unknown;
  patientId?: number;
  patientIsCurrentUser?: boolean;
  setPatientIsCurrentUser?: (b: boolean) => unknown;
};

const PatientDetailsFormStyled = styled.div`
  width: 100%;
`;

export function PatientDetailsForm({
  mode,
  disabled = false,
  moveGuard,
  setPatientDetails,
  patientDetails,
  existingPersonForIndependentPatient,
  setExistingPersonForIndependentPatient,
  patientId,
  patientIsCurrentUser,
  setPatientIsCurrentUser,
}: Props) {
  const api = useApi();
  const [user, userType] = useUser();
  const isUserGuardian = userType === UserType.Guardian;
  const [includeUseMyDetailsOption, setIncludeUseMyDetailsOption] = useState<
    boolean
  >(isUserGuardian && mode !== 'update');

  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    ...patientToValues(Optional.empty(), Optional.empty()),
    ...(patientDetails ?? {}),
  };
  if (mode === 'update' || existingPersonForIndependentPatient.isPresent()) {
    defaultValues.authorized = true;
  }

  const {
    control,
    formState,
    handleSubmit,
    errors,
    setValue,
    getValues,
    clearErrors,
  } = useForm<PatientDetailsValues>({
    defaultValues,
    resolver: yupResolver(patientDetailsSchema(isUserGuardian, mode)),
  });

  const {touched, isSubmitting} = formState;

  const isIndependentPatientCheckbox = existingPersonForIndependentPatient.isPresent();
  const isPatientCurrentUser = isIndependentPatientCheckbox && isUserGuardian;

  const shouldDisableRequiredField = (
    fieldName: keyof PatientDetailsValues,
  ) => {
    const hasValue = Boolean(
      getValues()[fieldName] || defaultValues[fieldName],
    );
    const personExists = existingPersonForIndependentPatient.isPresent();

    return (
      (disabled || isSubmitting || isPatientCurrentUser || personExists) &&
      hasValue
    );
  };

  const toolTipText = useCallback(
    (datapoint: string) => {
      const ref = reference('This patient', isPatientCurrentUser);

      return `${ref('Poss')} ${datapoint} can be changed from ${ref('poss', {
        alternate: true,
      })} About Me page ${
        !isPatientCurrentUser ? 'which they must log in to access' : ''
      }`;
    },
    [isPatientCurrentUser],
  );

  const findAndSetSelfRelationship = async () => {
    const guardian = user
      .getOptional()
      .cast<Guardian>(() => true)
      .orElseThrow(() => new Error('Unexpected error loading Patient form'));

    api
      .guardian(guardian.id)
      .findSelfRelationship()
      .then(r => {
        if (r) {
          setIncludeUseMyDetailsOption(false);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (isUserGuardian && mode === 'create') {
      findAndSetSelfRelationship().finally(() => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    if (mode === 'update') {
      api
        .patient(patientId)
        .findSelfRelationship()
        .then(r => {
          if (r) {
            api
              .responsiblePerson(r.person.id)
              .get()
              .then(rp => {
                setValues(
                  personToPatientDetailsValues(Optional.of(rp), {
                    ...getValues(),
                    isSelf: true,
                  }),
                );
                setExistingPersonForIndependentPatient(Optional.of(rp));
              });
          }
        })
        .finally(() => setIsLoading(false))
        .catch(e => console.warn(e));
    }
  }, []);

  moveGuard.current = async (dir: 'back' | 'next') => {
    if (dir === 'back') {
      return true;
    }
    return new Promise(res =>
      handleSubmit(
        data => {
          setPatientDetails(data);
          res(true);
        },

        e => {
          res(false);
        },
      )(),
    );
  };

  const setValues = useCallback(
    (values: PatientDetailsValues) => {
      getKeys(values).forEach((key: keyof PatientDetailsValues) => {
        setValue(key, values[key], {shouldDirty: true});
      });
    },
    [setValue],
  );

  return (
    <PageLoading active={isLoading} message="Loading">
      <PatientDetailsFormStyled>
        <div hidden={!includeUseMyDetailsOption}>
          <UseMyDetailsSwitch
            disabled={disabled}
            existingPersonForIndependentPatient={
              existingPersonForIndependentPatient
            }
            setExistingPersonForIndependentPatient={
              setExistingPersonForIndependentPatient
            }
            control={control}
            touched={touched}
            defaultValues={defaultValues}
            isSubmitting={isSubmitting}
            errors={errors}
            setValue={setValue}
            setValues={setValues}
            getValues={getValues}
            clearErrors={clearErrors}
            setPatientIsCurrentUser={setPatientIsCurrentUser}
          />
        </div>

        <Controller
          control={control}
          disabled={shouldDisableRequiredField('firstName')}
          as={TextInput}
          name="firstName"
          title={`${isPatientCurrentUser ? 'Your' : "Patient's"} first name`}
          required
          error={errors.firstName?.message}
          touched={touched.firstName}
          endAdornment={
            shouldDisableRequiredField('firstName') ? (
              <HelpToolTip text={toolTipText('first name')} />
            ) : null
          }
        />

        <div hidden={!isIndependentPatientCheckbox}>
          <Controller
            control={control}
            disabled={shouldDisableRequiredField('email')}
            as={TextInput}
            name="email"
            title={`${isPatientCurrentUser ? 'Your' : "Patient's"} email`}
            required
            error={errors.firstName?.message}
            touched={touched.firstName}
            endAdornment={
              shouldDisableRequiredField('email') ? (
                <HelpToolTip text={toolTipText('email name')} />
              ) : null
            }
          />
        </div>

        <Controller
          control={control}
          disabled={shouldDisableRequiredField('lastName')}
          as={TextInput}
          name="lastName"
          title={`${isPatientCurrentUser ? 'Your' : "Patient's"} last name`}
          required
          error={errors.lastName?.message}
          touched={touched.lastName}
          endAdornment={
            shouldDisableRequiredField('lastName') ? (
              <HelpToolTip text={toolTipText('last name')} />
            ) : null
          }
        />

        <Controller
          control={control}
          disabled={disabled || isSubmitting}
          as={SelectInput}
          name="pronouns"
          title={`${isPatientCurrentUser ? 'Your' : "Patient's"} pronouns`}
          error={errors.pronouns?.message}
          placeholder={`What pronouns ${
            isPatientCurrentUser ? 'do you' : 'does this patient'
          }  use? For example: he/him/his, she/her/hers`}
          options={[
            {label: 'He', value: 'he'},
            {label: 'She', value: 'she'},
            {label: 'They', value: 'they'},
          ]}
          touched={touched.pronouns}
        />
        <Controller
          control={control}
          disabled={disabled || isSubmitting}
          as={SelectInput}
          name="genderAssignedAtBirth"
          error={errors.genderAssignedAtBirth?.message}
          placeholder={`What gender ${
            isPatientCurrentUser ? 'were you' : 'was this patient'
          }  assigned at birth?`}
          options={[
            {label: 'Male', value: 'Male'},
            {label: 'Female', value: 'Female'},
          ]}
          title={`${
            isPatientCurrentUser ? 'Your' : "Patient's"
          } gender assignment at birth`}
          touched={touched.genderAssignedAtBirth}
        />

        {isUserGuardian && mode === 'create' && (
          <div hidden={existingPersonForIndependentPatient.isPresent()}>
            <Controller
              control={control}
              disabled={disabled || isSubmitting}
              as={MuiCheckBoxInput}
              onChange={(value: boolean) => {
                setValue('authorized', value);
              }}
              name="authorized"
              error={errors.authorized?.message}
              title={`I am authorized to seek medical care for ${
                isPatientCurrentUser ? 'myself' : 'this patient'
              }`}
            />
          </div>
        )}

        <Controller
          control={control}
          disabled={disabled || isSubmitting}
          as={TextInput}
          name="nickName"
          title={`${
            isPatientCurrentUser ? 'Your' : "Patient's"
          } preferred name (if appropriate)`}
          error={errors.nickName?.message}
          touched={touched.nickName}
        />

        <Controller
          control={control}
          disabled={shouldDisableRequiredField('phone')}
          as={PhoneInput}
          onChange={(value: string) => {
            setValue('phone', value);
          }}
          name="phone"
          title="Best phone number for follow-ups"
          required
          error={errors.phone?.message}
          touched={touched.phone}
          endAdornment={
            shouldDisableRequiredField('phone') ? (
              <HelpToolTip text={toolTipText('phone number')} />
            ) : null
          }
        />
        <Controller
          control={control}
          disabled={shouldDisableRequiredField('dob')}
          as={DateInput}
          onChange={(value: MaterialUiPickersDate) => {
            setValue('dob', value);
          }}
          name="dob"
          label={`${isPatientCurrentUser ? 'Your' : "Patient's"} date of birth`}
          required
          error={errors.dob?.message}
        />

        {isUserGuardian && (
          <div
            hidden={
              patientIsCurrentUser ||
              existingPersonForIndependentPatient.isPresent()
            }
          >
            <Controller
              control={control}
              disabled={shouldDisableRequiredField('guardianRelationship')}
              as={TextInput}
              name="guardianRelationship"
              title="Your relationship with this patient"
              placeholder="e.g. Father, Step-Mother, Parent, Aunt"
              required
              error={errors.guardianRelationship?.message}
              touched={touched.guardianRelationship}
            />
          </div>
        )}
      </PatientDetailsFormStyled>
    </PageLoading>
  );
}
