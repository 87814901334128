import React from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  svg: {
    height: '30px',
    x: '0px',
    y: '0px',
    viewBox: '0 0 113.6 25',
    maxWidth: '100%',
  },
  navLogo: {
    zIndex: 2,
    marginRight: '0px',
    maxWidth: '124px',
    minWidth: '124px',
  },
}));

export function AhanaLogo() {
  const classes = useStyles();
  return (
    <svg className={classes.svg}>
      <path
        fill={'white'}
        d="M95.1,7.6c-4.8,0-8.7,3.9-8.7,8.7s3.9,8.7,8.7,8.7c2,0,3.8-0.7,5.2-1.8V25h3.5v-8.7
          C103.8,11.5,99.9,7.6,95.1,7.6z M95.1,21.5c-2.9,0-5.2-2.3-5.2-5.2s2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2S98,21.5,95.1,21.5z
          M64.8,25v-8.7c0-4.8,3.9-8.7,8.7-8.7s8.7,3.9,8.7,8.7V25h-3.5v-8.7c0-2.9-2.3-5.2-5.2-5.2s-5.2,2.3-5.2,5.2V25H64.8z M51.9,7.6
          c-4.8,0-8.7,3.9-8.7,8.7s3.9,8.7,8.7,8.7c2,0,3.8-0.7,5.2-1.8V25h3.5v-8.7C60.6,11.5,56.7,7.6,51.9,7.6z M51.9,21.5
          c-2.9,0-5.2-2.3-5.2-5.2s2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2S54.8,21.5,51.9,21.5z M8.7,7.6C3.9,7.6,0,11.5,0,16.3S3.9,25,8.7,25
          c2,0,3.8-0.7,5.2-1.8V25h3.5v-8.7C17.4,11.5,13.5,7.6,8.7,7.6z M8.7,21.5c-2.9,0-5.2-2.3-5.2-5.2s2.3-5.2,5.2-5.2s5.2,2.3,5.2,5.2
          S11.6,21.5,8.7,21.5z M21.6,25V0h3.5v9.3c1.5-1.1,3.3-1.8,5.2-1.8c4.8,0,8.7,3.9,8.7,8.7V25h-3.5v-8.7c0-2.9-2.3-5.2-5.2-5.2
          s-5.2,2.3-5.2,5.2V25h-1.6H21.6z M109.9,20.7h1c0.2,0,0.3,0,0.5,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.3
          c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.2s-0.2,0.1-0.3,0.1l0.8,1.3h-0.5l-0.8-1.3h-0.4
          v1.3h-0.4V20.7z M110.3,22h0.5c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2s0-0.2-0.1-0.2
          c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0h-0.5V22z M110.8,25c-1.6,0-2.8-1.3-2.8-2.8s1.3-2.8,2.8-2.8
          s2.8,1.3,2.8,2.8S112.4,25,110.8,25z M110.8,19.7c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4S112.1,19.7,110.8,19.7z"
      />
    </svg>
  );
}
