import React, {useCallback, useState} from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {SharedCarePlan, SCPChangeRequest, SCPStatus} from '@src/models';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {flashError} from '@src/components/shared/notifications/flash';
import {useOnApprove} from '@src/hooks/scp/changeRequests/statusActions/useOnApprove';

export function ApproveButton({
  scp,
  changeRequest,
  asyncRunAfterApproved,
  runFinally,
  isInRow = false,
}: {
  scp: Optional<SharedCarePlan>;
  changeRequest: SCPChangeRequest;
  asyncRunAfterApproved: (scp: SharedCarePlan) => Promise<unknown>;
  runFinally: () => unknown;
  isInRow?: boolean;
}) {
  const scpStatus = scp.map(m => m.status).orNothing();
  const [isApproving, setIsApproving] = useState(false);

  const onApprove = useOnApprove({
    runBeforeRequest: useCallback(() => setIsApproving(true), []),
    asyncRunAfterRequest: useCallback(asyncRunAfterApproved, [
      asyncRunAfterApproved,
    ]),
    runFinally: useCallback(() => {
      setIsApproving(false);
      runFinally();
    }, [runFinally]),
    runOnError: useCallback(
      () => flashError('Something went wrong approving this change request'),
      [],
    ),
    scp,
    changeRequestId: changeRequest.id,
  });

  return (
    <MuiAsyncActionButton
      bSize="small"
      disabled={
        scpStatus === SCPStatus.Draft ||
        scpStatus === SCPStatus.OwnershipExpired
      }
      onClick={onApprove}
      actionWord="Approve"
      actionInProgress={isApproving}
      isInRow={isInRow}
    />
  );
}
