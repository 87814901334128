import React from 'react';
import {Grid} from '@material-ui/core';
import {EndpointOption} from './EndpointOption';
import {Endpoint} from '@src/models';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';

type Props = {
  endpoints: Endpoint[];
  selectedEndpoint: Endpoint['id'] | null;
  selectEndpoint: (id: Endpoint['id']) => unknown;
};

// eslint-disable-next-line import/no-unused-modules
export default function EndpointSelection({
  endpoints,
  selectEndpoint,
  selectedEndpoint,
}: Props) {
  return (
    <>
      <RequestHeader>Where will this Visit be taking place?</RequestHeader>
      <RequestInstructions>
        This Provider Group provides telemedecine visits via a number of
        different channels.{' '}
      </RequestInstructions>
      <RequestInstructions>
        Please choose the one that is best suited for the patient.
      </RequestInstructions>
      <Grid container direction="column">
        {endpoints.map(endpoint => (
          <EndpointOption
            key={endpoint.id}
            endpoint={endpoint}
            selectEndpoint={selectEndpoint}
            selected={endpoint.id === selectedEndpoint}
          />
        ))}
      </Grid>
    </>
  );
}
