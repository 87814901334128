import React from 'react';
import {addBreadcrumb, Severity} from '@sentry/browser';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {RemoteParticipant, Room} from 'twilio-video';
import {Drawer, IconButton} from '@material-ui/core';
import clsx from 'clsx';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {Modal} from './ExamRoomModals';
import {useStyles, DOCUMENTATION_WIDTH, SIDEBAR_WIDTH} from './layout';
import {RoomControls} from './RoomControls';
import {
  disconnectFromTwilioRoom,
  reconnectToTwilioRoom,
} from '@src/util/videoChat';
import {useUser} from '@src/hooks';
import {
  MedicalHistory,
  SharedCarePlan,
  LonelyVisit,
  VisitDocumentation,
} from '@src/models';
import {useAssignProfessional} from '@src/components/providerSide/VisitTable/VisitActions/useAssignProfessional';

type SidebarProps = {
  showModal: (m: Modal) => unknown;
  showDocumentation: boolean;
  sidebarOpen: boolean;
  room: Room | null;
  setDocumentation: (v?: VisitDocumentation | Error) => void;
  visit: AsyncData<LonelyVisit>;
  updateVisit: (v: LonelyVisit) => unknown;
  setShowDocumentation: (v: boolean) => void;
  participants: RemoteParticipant[];
  medicalHistory: AsyncData<MedicalHistory>;
  setSidebarOpen: (v: boolean) => void;
  documentation: AsyncData<VisitDocumentation>;
  scp: AsyncData<SharedCarePlan>;
  disablePresentSCP: boolean;
};

export function Sidebar({
  showModal,
  showDocumentation,
  sidebarOpen,
  room,
  setDocumentation,
  setShowDocumentation,
  updateVisit,
  visit,
  participants,
  medicalHistory,
  setSidebarOpen,
  scp,
  documentation,
  disablePresentSCP,
}: SidebarProps) {
  const classes = useStyles({
    width: showDocumentation ? DOCUMENTATION_WIDTH : SIDEBAR_WIDTH,
  });
  const [, userType] = useUser();

  const onAssignProfessional = useAssignProfessional(
    visit.getOptional(),
    updateVisit,
  );

  return (
    <>
      <Drawer
        id="side-drawer"
        open={sidebarOpen}
        anchor="left"
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: sidebarOpen,
          [classes.drawerClose]: !sidebarOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: sidebarOpen,
            [classes.drawerClose]: !sidebarOpen,
          }),
        }}
        SlideProps={{
          onEnter: () => {
            console.log('entered');
          },
          onEntering: () => {
            console.log('entered');
          },
          onEntered: () => {
            console.log('entered');
          },
        }}
      >
        <div className={classes.toolbar}>
          {!showDocumentation && sidebarOpen && (
            <IconButton
              onClick={() => {
                setSidebarOpen(false);
              }}
              disabled={showDocumentation}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          )}

          {!showDocumentation && !sidebarOpen && (
            <IconButton
              onClick={() => {
                setSidebarOpen(true);
              }}
              disabled={showDocumentation}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          )}
        </div>
        <RoomControls
          disablePresentSCP={disablePresentSCP}
          showButtonText={sidebarOpen}
          onReconnect={() => {
            addBreadcrumb({
              category: 'exam-room',
              message: 'Clicked Reconnect Button',
              level: Severity.Info,
            });

            if (room) {
              disconnectFromTwilioRoom({
                room,
              });
            }

            reconnectToTwilioRoom({
              visit: visit.getOptional(),
              room,
              participants,
            });
          }}
          visit={visit.getOptional()}
          currentMedicalHistory={medicalHistory}
          documentation={documentation}
          completeVisit={() => showModal('endVisitModal')}
          pauseVisitForCurrentUser={() =>
            showModal('nonProfessionalLeaveConfirmationModal')
          }
          scp={scp}
          onPresentSCP={() => showModal('presentSCP')}
          onAssignProfessional={onAssignProfessional}
          onDocumentationSave={newDoc => {
            setDocumentation(newDoc);
          }}
          showDocumentation={showDocumentation}
          toggleDocumentation={toggleDoc => {
            if (toggleDoc) {
              setSidebarOpen(true);
            }
            setShowDocumentation(toggleDoc);
          }}
          userType={userType}
          room={room}
        />
      </Drawer>
    </>
  );
}
