import React, {useState} from 'react';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {StepProps} from '..';
import {ResponsiblePersonView} from './ResponsiblePersonView';
import {RequestHeader} from '@src/components/ui/layout/NewThingRequest';
import {FinanciallyResponsiblePersonFormModal} from '@src/components/shared/forms/FinanciallyResponsiblePersonFormModal';
import {useUser} from '@src/hooks';
import {Guardian, UserType} from '@src/models';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

export function ResponsiblePersonStep({
  patient,
  financialRelationship,
  setNextAvailable,
  onUpdatePatient,
}: StepProps) {
  const [user] = useUser();
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [canProceed, setCanProceed] = useState(false);

  const personId = user
    .getOptional()
    .cast<Guardian>(u => u.userType === UserType.Guardian)
    .map(g => g.responsiblePersonDetails)
    .property('id', 0 as NonProfessionalId);

  const userRelationshipWithPatient = Optional.of(patient)
    .map(o => o.relationships)
    .map(rs => rs.find(r => (r.personId = personId)));

  setNextAvailable(canProceed);

  return (
    <>
      <RequestHeader>Responsible Person</RequestHeader>
      <ResponsiblePersonView
        patient={Optional.of(patient)}
        financialRelationship={financialRelationship}
        canProceed={canProceed}
        onProceed={() => {
          setCanProceed(true);
        }}
        onEdit={() => {
          setMode('edit');
        }}
      />

      <FinanciallyResponsiblePersonFormModal
        patient={patient}
        userRelationshipWithPatient={userRelationshipWithPatient}
        relationship={financialRelationship}
        onUpdatePatient={async () => {
          await onUpdatePatient();
          setCanProceed(true);
        }}
        onDone={() => {
          setMode('view');
        }}
        show={mode === 'edit'}
      />
    </>
  );
}
