import {AppBar, Toolbar, useMediaQuery} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import React, {useState} from 'react';
import {DrawerSkeleton} from './DrawerSkeleton';
import {useStyles} from './styles';
import {TabSkeleton} from './TabSkeleton';
import {UserType} from '@src/models';

export function LoadingNavigation() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          {isMobile ? (
            <DrawerSkeleton
              permissions={{}}
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              userType={UserType.Unknown}
            />
          ) : (
            <TabSkeleton userType={UserType.Unknown} permissions={{}} />
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}
