import React from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import {ExpandableSection} from '../ExpandableSection';
import {Mergeable} from '../ExpandableSection/isMergeable/isSectionMergeable';
import {useStyles as useGeneralStyles} from '../shared/styles';
import {FailText} from '../shared/ExplanationText/FailText';
import {useStyles} from './styles';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {getEmptyMergeOptions, MergeOptions} from '@src/models/Patient';
import {providerAndQualification} from '@src/util/provider';
import {UserAvatar} from '@src/components/ui/layout/UserAvatar';

type Props = {
  mergeable: Mergeable;
  mergeOptions: AsyncData<MergeOptions>;
};

export function CareTeamsComparer({mergeable, mergeOptions}: Props) {
  const {careTeam} = mergeOptions.getOptional().orElse(getEmptyMergeOptions());
  const classes = {...useStyles(), ...useGeneralStyles()};

  return (
    <ExpandableSection
      title="Care Team"
      mergeable={mergeable}
      mergeOptions={mergeOptions}
    >
      <Grid container direction="column">
        {mergeable === 'FAIL' && <FailText />}
        {mergeable === 'PASS' && (
          <Grid item className={classes.comparerMessage}>
            <ParagraphText style={{marginBottom: 0}}>
              The Care Teams can be merged automatically.
            </ParagraphText>
            <ParagraphText style={{marginBottom: 0}}>
              Please review the list of Care Team Members below that will be
              included in the merge.
            </ParagraphText>
          </Grid>
        )}
        {careTeam.length > 0 && (
          <Grid item style={{width: 'fit-content'}}>
            <Paper>
              <List subheader={<li />} className={classes.root}>
                {careTeam.map(pd => {
                  return (
                    <li key={pd.id}>
                      <ul>
                        <ListItem key={pd.id} divider={true}>
                          <ListItemText
                            primary={
                              <Typography variant="body1">
                                <UserAvatar user={pd} />
                                {providerAndQualification(pd)}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </ul>
                    </li>
                  );
                })}
              </List>
            </Paper>
          </Grid>
        )}
      </Grid>
    </ExpandableSection>
  );
}
