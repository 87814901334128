import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';
import {SharedCarePlan, ProviderDetails} from '@src/models';
import {UserId} from '@src/models/User';

export function getScpOwnerAsProviderDetails(
  scp: Optional<SharedCarePlan>,
): ProviderDetails | undefined {
  return scp.map(m => m.providerDetails).orNothing();
}

export function getScpOwnerProviderId(
  scp: AsyncData<SharedCarePlan | null>,
): UserId | undefined {
  const aScp = scp.getOptional().orNull();

  if (aScp === null) {
    return undefined;
  }

  const scpOwner = getScpOwnerAsProviderDetails(Optional.of(aScp));
  return scpOwner?.providerId ?? undefined;
}
