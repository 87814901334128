import React from 'react';
import {Controller} from 'react-hook-form';
import {Common} from './Common';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';
import {Button} from '@src/components/ui/form';
import {Modal} from '@src/components/ui/layout/Modal';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {useCreateClientConfigurationForm} from '@src/hooks/clientConfigurations/useCreateClientConfigurationForm';
import {NAMES} from '@src/components/ui/layout/text/names';

type Props = {
  show: boolean;
  onClose: () => unknown;
  invoiceTargetId: number;
  onCreated: () => Promise<unknown>;
};

export function CreateClientConfigurationModal({
  show,
  onClose,
  onCreated,
  invoiceTargetId,
}: Props) {
  const {
    control,
    errors,
    formHandler,
    submitting,
  } = useCreateClientConfigurationForm(onCreated, invoiceTargetId);

  const submitButton = (
    <MuiAsyncActionButton
      bStyle="primary"
      bSize="normal"
      onClick={formHandler}
      actionInProgress={submitting}
      actionWord="Submit"
      disabled={submitting}
    >
      Submit
    </MuiAsyncActionButton>
  );

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={`Create ${toTitleCase(NAMES.customerCode)}`}
      modalActions={[<Button onClick={onClose}>Close</Button>, submitButton]}
    >
      {/* Without this hack, the form will not be able to persist the passed-in value and will fail form validation */}
      <div hidden>
        <Controller
          control={control}
          name="invoiceTargetId"
          value={invoiceTargetId}
          render={({}) => <div></div>}
        />
      </div>

      <Common control={control} errors={errors} />
    </Modal>
  );
}
