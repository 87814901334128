import {useMemo, useEffect, useState} from 'react';
import * as yup from 'yup';
import {Control, DeepMap, FieldError, useForm} from 'react-hook-form';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {yupResolver} from '@hookform/resolvers';
import {getRequiredMessage} from '@src/util/forms/getRequiredMessage';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {useApi} from '@src/api/useApi';
import {useAllGroups} from '@src/hooks/providerGroups/useAllGroups';
import {CallPool, SimpleDocument} from '@src/models';
import {FormHandler} from '@src/hooks/forms';

export type FormResources = {
  submitting: boolean;
  formHandler: FormHandler;
  control: Control<CreateDocumentValues>;
  errors: DeepMap<CreateDocumentValues, FieldError>;
  allFields: CreateDocumentValues;
  selectedCallPoolIds: number[];
  setSelectedCallPoolIds: (ids: number[]) => unknown;
  invoiceTargetGroups: AsyncData<CallPool>;
  uploadedFile: SimpleDocument | null;
  setUploadedFile: (v: SimpleDocument | null) => unknown;
  showNeedsUploadError: boolean;
  viewingUploadedFile: boolean;
  setViewingUploadedFile: (v: boolean) => unknown;
};

type CreateDocumentValues = {
  name: string;
  description: string;
  invoiceTargetId: string;
};

function getSchema() {
  return yup.object().shape({
    name: yup.string().required(getRequiredMessage('a', 'name for the form')),
    description: yup
      .string()
      .required(getRequiredMessage('a', 'description for the form')),
    invoiceTargetId: yup
      .string()
      .required(getRequiredMessage('the', 'customer for the form')),
  });
}

export function useFormResources(): FormResources {
  const api = useApi();

  const schema = getSchema();

  const [submitting, setSubmitting] = useState(false);
  const [selectedCallPoolIds, setSelectedCallPoolIds] = useState<number[]>([]);
  const [uploadedFile, setUploadedFile] = useState<SimpleDocument | null>(null);
  const [viewingUploadedFile, setViewingUploadedFile] = useState<boolean>(
    false,
  );

  const [showNeedsUploadError, setShowNeedsUploadError] = useState<boolean>(
    false,
  );

  const [allGroups] = useAllGroups({includeUnapproved: true});
  const [invoiceTargetGroups, setInvoiceTargetGroups] = useState<
    AsyncData<CallPool>
  >(allGroups);

  const {control, errors, handleSubmit, reset, watch} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      description: '',
      // This is a string because of some edge cases in the way
      // the SelectInput displays the placeholder and error messages
      // if null, 0, or NaN is provided.
      invoiceTargetId: '',
    },
  });

  const allFields = watch();
  const invoiceTargetIdState = allFields.invoiceTargetId;

  useEffect(() => {
    setSelectedCallPoolIds([]);

    if (allGroups.isLoaded()) {
      setInvoiceTargetGroups(
        allGroups.filter(
          g => `${g.invoiceTargetId.orElse(0)}` === invoiceTargetIdState,
        ),
      );
    }
  }, [allGroups, invoiceTargetIdState]);

  const formHandler = handleSubmit(data => {
    if (uploadedFile === null) {
      setShowNeedsUploadError(true);
      return;
    }

    setShowNeedsUploadError(false);

    const {name, description, invoiceTargetId} = data;

    setSubmitting(true);

    api
      .legalDocument()
      .create({
        name,
        description,
        invoiceTargetId: parseInt(invoiceTargetId),
        callPoolIds: selectedCallPoolIds,
        url: uploadedFile.name,
      })
      .then(() => {
        reset();
        setUploadedFile(null);
        setSelectedCallPoolIds([]);
        setShowNeedsUploadError(false);
        flashSuccess('Success');
      })
      .catch(() => {
        flashError('Something went wrong');
      })
      .finally(() => {
        setSubmitting(false);
      });
  });

  return useMemo(
    () => ({
      allFields,
      selectedCallPoolIds,
      setSelectedCallPoolIds,
      submitting,
      formHandler,
      control,
      errors,
      invoiceTargetGroups,
      uploadedFile,
      setUploadedFile,
      showNeedsUploadError,
      viewingUploadedFile,
      setViewingUploadedFile,
    }),
    [
      allFields,
      selectedCallPoolIds,
      setSelectedCallPoolIds,
      submitting,
      formHandler,
      control,
      errors,
      invoiceTargetGroups,
      uploadedFile,
      setUploadedFile,
      showNeedsUploadError,
      viewingUploadedFile,
      setViewingUploadedFile,
    ],
  );
}
