import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, List, ListItem, ListItemText} from '@material-ui/core';
import partition from 'lodash/partition';
import React, {useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {useStyles} from './layout';
import {AssignedForm} from '@src/models';

interface Props {
  forms: AssignedForm[] | readonly AssignedForm[];
}

export default function AssignedFormList({forms}: Props) {
  const classes = useStyles();
  const [completedForms, incompleteForms] = useMemo(
    () => partition(forms, form => form.completedAt.isPresent()),
    [forms],
  );

  const clickHandler = (e: React.MouseEvent) => {
    setSortAscending(!sortAscending);
    e.preventDefault();
  };
  const [sortAscending, setSortAscending] = useState<boolean>(false);
  return (
    <div>
      <div className={classes.sortLink}>
        <Link onClick={e => clickHandler(e)} to={'#'}>
          <FontAwesomeIcon
            icon={['fas', 'sort']}
            fixedWidth
            size="sm"
            className={classes.actionItem}
          />{' '}
          Order {sortAscending ? 'Ascending' : 'Descending'}
        </Link>
      </div>
      <div>
        <List>
          {[...incompleteForms, ...completedForms]
            .sort((a, b) => (sortAscending ? a.id - b.id : b.id - a.id))
            .map(form => (
              <ListItem key={form.id}>
                <Badge
                  invisible={form.completedAt.isPresent()}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  variant="dot"
                  color="error"
                >
                  <ListItemText>
                    <Link to={`/form/${form.id}`} target={'_parent'}>
                      {form.blankForm.get().name}
                    </Link>
                  </ListItemText>
                </Badge>
              </ListItem>
            ))}
        </List>
      </div>
    </div>
  );
}
