import React from 'react';
import {ParagraphText} from '../../../ui/layout/text/body/ParagraphText';

export function IndependentPatientText() {
  return (
    <>
      <ParagraphText>
        Once you deactivate your patient record, you will no longer be able to
        seek healthcare for yourself on Refyne Connected Care, and your patient
        card will not appear on your dashboard anymore.
      </ParagraphText>
      <ParagraphText>
        You can add yourself back by clicking <b>Add Patient</b>, if necessary.
      </ParagraphText>
    </>
  );
}
