import {PaginationOptions} from '../../api/AppAPI';

/**
 * Send requests to consecutive pages of resources from a paginated endpoint
 * until all resources are received
 *
 * @param pageGetter - a function that actually gets the entries
 */
export const getAllPages = async <T extends {}>(
  pageGetter: (options: PaginationOptions) => Promise<T[]>,
) => {
  let resources: T[] = [];
  let page: T[];
  let start = 0;
  const pageSize = 10;
  do {
    page = await pageGetter({start, pageSize});
    resources = resources.concat(page);
    start += pageSize;
  } while (page.length === pageSize);

  return resources;
};
