import React from 'react';
import {useHistory} from 'react-router';
import {Grid, Link} from '@material-ui/core';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';

type Props = {
  link: string;
  label: string;
};

export function ActionLink({link, label}: Props) {
  const history = useHistory();

  return (
    <Grid>
      <ParagraphText>
        <Link onClick={() => history.push(link)}>{label}</Link>
      </ParagraphText>
    </Grid>
  );
}
