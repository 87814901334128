import React, {useRef} from 'react';
import {UnpackNestedValue} from 'react-hook-form';
import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';
import {SidebarDocumentation} from '../RoomControls/layout';
import {getCanSign} from '@src/components/providerSide/DocumentationRoom/functions';
import {VisitDocumentationForm} from '@src/components/providerSide/VisitDocumentationForm';
import {VisitDocumentationValues} from '@src/components/providerSide/VisitDocumentationForm/sections';
import {useApi} from '@src/api/useApi';
import {LonelyVisit, VisitDocumentation} from '@src/models';
import {visitDocumentationValuesToRequest} from '@src/components/providerSide/VisitDocumentationForm/functions';

type Props = {
  oVisit: Optional<LonelyVisit>;
  onDocumentationSave: (d: VisitDocumentation) => void;
  showDocumentation: boolean;
  toggleDocumentation: (toggle: boolean) => unknown;
  documentation: AsyncData<VisitDocumentation>;
  initialValues: AsyncData<VisitDocumentationValues>;
};

export function VisitDocumentationModal({
  oVisit,
  onDocumentationSave,
  showDocumentation,
  toggleDocumentation,
  documentation,
  initialValues,
}: Props) {
  const visit = oVisit.get();

  const formRef = useRef<HTMLDivElement>(null);

  const hideDocumentation = () => toggleDocumentation(false);

  const api = useApi();

  const onSign = async () => {
    if (!oVisit.isPresent()) {
      return;
    }
    const v = oVisit.get();

    const updatedVisit = await api.visit(v.id).sign();
    updatedVisit.visitDocumentation.ifPresent(onDocumentationSave);
  };

  const onSave = async (
    values: UnpackNestedValue<VisitDocumentationValues>,
  ) => {
    if (!oVisit.isPresent()) {
      return;
    }
    const v = oVisit.get();

    const request = visitDocumentationValuesToRequest(values);
    const doc = await api.visit(v.id).updateDocumentation(request);

    onDocumentationSave(doc);

    const {current} = formRef;
    if (current === null) {
      throw new Error('No reference to the form was found');
    }
  };

  return (
    <SidebarDocumentation hidden={!showDocumentation} ref={formRef}>
      <VisitDocumentationForm
        initialValues={initialValues.singleValue()}
        patient={visit.patient}
        canSign={getCanSign({
          documentation: documentation.getOptional(),
        })}
        onSave={onSave}
        onSign={onSign}
        onDone={hideDocumentation}
        onDoneText="Save and Return To Menu"
        showTopButtons
      />
    </SidebarDocumentation>
  );
}
