import {useCallback, useEffect, useMemo, useState} from 'react';
import {FieldName, SetFieldValue, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {yupResolver} from '@hookform/resolvers';
import {
  ProfessionalSearchResources,
  useProfessionalSearch,
} from './useProfessionalSearch';
import {getEmailFromDetails} from './utils/getEmailFromDetails';
import {hasUserAccount} from './utils/hasUserAccount';
import {getRequiredMessage} from '@src/util/forms/getRequiredMessage';
import {FormControl, FormErrors, FormHandler} from '@src/hooks/forms';
import {useApi} from '@src/api/useApi';
import {isEmpty, isNothing} from '@src/util/typeTests';
import {NAMES} from '@src/components/ui/layout/text/names';
import {ProviderDetails, ProviderDetailsId} from '@src/models/ProviderDetails';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {ResourceConflictException} from '@src/api/exceptions';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';

export type ConvertToUserPageResources = {
  formResources: {
    formHandler: FormHandler;
    errors: FormErrors<ConvertUserValues>;
    submitting: boolean;
    control: FormControl<ConvertUserValues>;
    emailTooltipText: string | null;
    professionalAlreadyUser: boolean;
  };
  searchResources: ProfessionalSearchResources;
};

type ConvertUserValues = {
  email: string;
};

function getSchema() {
  return yup.object().shape({
    email: yup.string().required(getRequiredMessage('an', 'email')),
  });
}

function useEmailTooltipText({
  setValue,
  oDetails,
}: {
  setValue: (
    name: FieldName<{email: string}>,
    value: SetFieldValue<{email: string}>,
  ) => void;
  oDetails: Optional<ProviderDetails>;
}) {
  const [databaseEmail, setDatabaseEmail] = useState<string | null>('');

  useEffect(() => {
    // Update form value.
    if (!isNothing(databaseEmail) && !isEmpty(databaseEmail)) {
      setValue('email', databaseEmail);
    }
  }, [databaseEmail, oDetails, setValue]);

  useEffect(() => {
    const identifierEmail = getEmailFromDetails(oDetails);
    setDatabaseEmail(identifierEmail);
  }, [oDetails]);

  const noDatabaseEmail =
    !isNothing(oDetails.orNull()) && isEmpty(databaseEmail);

  const emailTooltipText = noDatabaseEmail
    ? `This ${toTitleCase(
        NAMES.provider,
      )} doesn't have an email address yet. An email address is required to convert their account to a ${toTitleCase(
        NAMES.user,
      )} account.`
    : null;

  return emailTooltipText;
}

export function useConvertToUserPage(): ConvertToUserPageResources {
  const schema = getSchema();
  const api = useApi();

  const [submitting, setSubmitting] = useState(false);
  const {control, errors, handleSubmit, reset: resetForm, setValue} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const searchResources = useProfessionalSearch({
    includeUnapprovedMembershipGroups: true,
  });
  const {details, resetSearch} = searchResources;
  const oDetails = details.getOptional();

  const professionalAlreadyUser = hasUserAccount(details.getOptional());
  const emailTooltipText = useEmailTooltipText({oDetails, setValue});

  const resetPage = useCallback(() => {
    resetForm();
    resetSearch();
  }, [resetSearch, resetForm]);

  const formHandler = handleSubmit(data => {
    const {email} = data;
    setSubmitting(true);

    api
      .user()
      .convertToUser({
        providerDetailsId: oDetails
          .map(d => d.id)
          .orElse(0 as ProviderDetailsId),
        email,
      })
      .then(() => {
        resetPage();
        flashSuccess('Success');
      })
      .catch(e => {
        let message = 'Something went wrong.';
        if (e instanceof ResourceConflictException) {
          message = `This email address is already being used for a ${toTitleCase(
            NAMES.professional,
          )}.`;
        }

        flashError(`${message}`);
      })
      .finally(() => {
        setSubmitting(false);
      });
  });

  return useMemo(
    () => ({
      formResources: {
        submitting,
        control,
        errors,
        formHandler,
        emailTooltipText,
        professionalAlreadyUser,
      },
      searchResources,
    }),
    [
      control,
      emailTooltipText,
      errors,
      formHandler,
      professionalAlreadyUser,
      searchResources,
      submitting,
    ],
  );
}
