import {makeStyles} from '@material-ui/core/styles';
import {xs} from '../../theme';

export const useIconStyles = makeStyles(theme => ({
  clickableIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 16,
    marginRight: 'auto',
    padding: xs,
  },
  greyIcon: {
    color: theme.palette.grey[500],
  },
}));
