import React, {useState} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  useTheme,
} from '@material-ui/core';
import {StepperButtons} from '../../shared/Stepper/StepperButtons';
import {useStyles} from '../styles';
import {PersonCard} from '../../shared/PersonCard';
import {SelectOne} from '../../shared/SelectOne';
import {ReviewRecordStep} from './ReviewRecordStep';
import {Result} from './Result';
import {SelectRelationshipNameStep} from './SelectRelationshipNameStep';
import {getStepContent, getSteps} from './stepperUtils';
import {Patient, ResponsiblePerson} from '@src/models';
import {isEmpty, isNothing} from '@src/util/typeTests';
import {MergeOptionsFinanciallyResponsible} from '@src/models/Patient';

type Props = {
  patientOne: AsyncData<Patient>;
  patientTwo: AsyncData<Patient>;
  setFinanciallyResponsibleResult: (p: ResponsiblePerson | null) => unknown;
  financiallyResponsibleResult: ResponsiblePerson | null;
  selectedRelationshipName: string;
  setSelectedRelationshipName: (n: string) => unknown;
  financiallyResponsibleMergeOptions: MergeOptionsFinanciallyResponsible;
};

export function RelationshipStepper({
  patientOne,
  patientTwo,
  financiallyResponsibleResult,
  setFinanciallyResponsibleResult,
  financiallyResponsibleMergeOptions,
  selectedRelationshipName,
  setSelectedRelationshipName,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);

  const needToSelectPerson =
    financiallyResponsibleMergeOptions.personOptions.length > 1;

  const steps = getSteps(needToSelectPerson);

  const isLastStep =
    !isNothing(financiallyResponsibleResult) && activeStep > steps.length - 1;

  const renderSelectRecordStep = activeStep === 0 && needToSelectPerson;
  const renderReviewRecordStep = activeStep === 0 && !needToSelectPerson;

  const optionOne = financiallyResponsibleMergeOptions.personOptions[0];
  const optionTwo = financiallyResponsibleMergeOptions.personOptions[1];

  const isOption1Selected =
    !isNothing(financiallyResponsibleResult) &&
    financiallyResponsibleResult.id === optionOne?.id;

  const isOption2Selected =
    !isNothing(financiallyResponsibleResult) &&
    financiallyResponsibleResult.id === optionTwo?.id;

  const optionOneCard = (
    <PersonCard
      person={optionOne ?? null}
      cardStyles={
        isOption1Selected
          ? {
              borderColor: theme.palette.success.main,
              borderWidth: 'thick',
            }
          : {}
      }
      containerStyles={{padding: 0}}
    />
  );

  const optionTwoCard = (
    <PersonCard
      person={optionTwo ?? null}
      cardStyles={
        isOption2Selected
          ? {
              borderColor: theme.palette.success.main,
              borderWidth: 'thick',
            }
          : {}
      }
      containerStyles={{padding: 0}}
    />
  );

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      className={classes.root}
    >
      {steps.map((label, index) => {
        return (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>
                {getStepContent(index, needToSelectPerson)}
              </Typography>
              <div className={classes.actionsContainer}>
                {renderSelectRecordStep && (
                  <SelectOne
                    patientOne={patientOne}
                    patientTwo={patientTwo}
                    cards={{
                      optionOneCard,
                      optionTwoCard,
                    }}
                    optionOneSelected={isOption1Selected}
                    optionTwoSelected={isOption2Selected}
                    onSelectOptionOne={() => {
                      setFinanciallyResponsibleResult(optionOne);
                    }}
                    onSelectOptionTwo={() => {
                      setFinanciallyResponsibleResult(optionTwo);
                    }}
                    instructions={[]}
                  />
                )}
                {renderReviewRecordStep && (
                  <ReviewRecordStep
                    financiallyResponsibleResult={
                      financiallyResponsibleMergeOptions.personOptions[0]
                    }
                  />
                )}
                {activeStep === 1 && (
                  <SelectRelationshipNameStep
                    optionOne={
                      financiallyResponsibleMergeOptions.nameOptions[0]
                    }
                    optionTwo={
                      financiallyResponsibleMergeOptions.nameOptions[1]
                    }
                    selectedRelationshipName={selectedRelationshipName}
                    setSelectedRelationshipName={setSelectedRelationshipName}
                  />
                )}
                <StepperButtons
                  activeStep={activeStep}
                  steps={steps}
                  setActiveStep={setActiveStep}
                  disabled={
                    (activeStep === 0 &&
                      isNothing(financiallyResponsibleResult)) ||
                    (activeStep === 1 && isEmpty(selectedRelationshipName))
                  }
                />
              </div>
            </StepContent>
          </Step>
        );
      })}

      {isLastStep && (
        <Result
          financiallyResponsibleResult={
            financiallyResponsibleResult as ResponsiblePerson
          }
          selectedRelationshipName={selectedRelationshipName}
        />
      )}
    </Stepper>
  );
}
