import {AppBar, Toolbar, useMediaQuery} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import React from 'react';
import {Drawer} from './Drawer';
import {useStyles} from './styles';
import {Tabs} from './Tabs';
import {PermissionsProps} from '.';
import {UserType} from '@src/models';
import {useLogout} from '@src/hooks/useLogout';

export function AdminNavigation({
  permissions,
}: {
  permissions: PermissionsProps;
}) {
  const logout = useLogout();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onLogout = async () => {
    logout();
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        {isMobile ? (
          <Drawer
            userType={UserType.Admin}
            onLogout={onLogout}
            permissions={permissions}
          />
        ) : (
          <Tabs
            userType={UserType.Admin}
            onLogout={onLogout}
            permissions={permissions}
          />
        )}
      </Toolbar>
    </AppBar>
  );
}
