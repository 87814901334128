import React from 'react';
import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';
import {UploadFilesModal} from '../ExamRoomModals/UploadFilesModal';
import {ShareLinkModal} from '../../ExamRoomModals/ShareLinkModal';
import {ViewFilesModal} from '../ExamRoomModals/ViewFilesModal';
import {VisitDocumentationModal} from '../ExamRoomModals/VisitDocumentationModal';
import {VisitInfoModal} from '../ExamRoomModals/VisitInfoModal';
import {AssignProfessionalModal} from '../ExamRoomModals/AssignProfessionalModal';
import {
  MedicalHistory,
  AppFile,
  LonelyVisit,
  VisitDocumentation,
} from '@src/models';
import {VisitDocumentationValues} from '@src/components/providerSide/VisitDocumentationForm/sections';
import {ProviderDetailsId} from '@src/models/ProviderDetails';

type Props = {
  currentMedicalHistory: AsyncData<MedicalHistory>;
  documentation: AsyncData<VisitDocumentation>;
  files: AppFile[];
  initialValues: AsyncData<VisitDocumentationValues>;
  isProfessional: boolean;
  oVisit: Optional<LonelyVisit>;
  onAssignProfessional: (id: ProviderDetailsId) => Promise<unknown>;
  onDocumentationSave: (d: VisitDocumentation) => void;
  setShowUploadModal: (v: boolean) => unknown;
  setShowFilesList: (v: boolean) => unknown;
  setShowShareModal: (v: boolean) => unknown;
  setShowVisitInfo: (v: boolean) => unknown;
  setShowAssignModal: (v: boolean) => unknown;
  showUploadModal: boolean;
  showDocumentation: boolean;
  showFilesList: boolean;
  showShareModal: boolean;
  showVisitInfo: boolean;
  showAssignModal: boolean;
  toggleDocumentation: (toggle: boolean) => unknown;
};

export function Modals({
  isProfessional,
  initialValues,
  oVisit,
  onAssignProfessional,
  onDocumentationSave,
  showDocumentation,
  toggleDocumentation,
  documentation,
  showUploadModal,
  setShowUploadModal,
  showFilesList,
  setShowFilesList,
  files,
  setShowAssignModal,
  showAssignModal,
  showVisitInfo,
  setShowVisitInfo,
  showShareModal,
  setShowShareModal,
  currentMedicalHistory,
}: Props) {
  const visit = oVisit.get();
  const patient = visit.patient;

  return (
    <>
      {isProfessional && initialValues.isLoaded() && (
        <VisitDocumentationModal
          oVisit={oVisit}
          onDocumentationSave={onDocumentationSave}
          showDocumentation={showDocumentation}
          toggleDocumentation={toggleDocumentation}
          documentation={documentation}
          initialValues={initialValues}
        />
      )}
      {isProfessional && (
        <AssignProfessionalModal
          visit={visit}
          returnText="Return to visit"
          showModal={showAssignModal}
          returnToPatient={() => setShowAssignModal(false)}
          onAssignProfessional={id => {
            return onAssignProfessional(id);
          }}
        />
      )}
      <UploadFilesModal
        visit={visit}
        show={showUploadModal}
        onClose={() => setShowUploadModal(false)}
      />
      <ViewFilesModal
        showModal={showFilesList}
        setShowModal={setShowFilesList}
        files={files}
      />
      <VisitInfoModal
        visit={visit}
        showModal={showVisitInfo}
        setShowModal={setShowVisitInfo}
        currentMedicalHistory={currentMedicalHistory}
      />
      {showShareModal && (
        <ShareLinkModal
          patient={patient}
          visitId={visit.id}
          showModal={showShareModal}
          setShowModal={setShowShareModal}
        />
      )}
    </>
  );
}
