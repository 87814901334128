import React, {useState} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {
  CircularProgress,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import RemoveIcon from '@material-ui/icons/Remove';
import {useStyles} from '../styles';
import {ProviderPartner, User} from '@src/models';
import {getProviderDescription} from '@src/util/provider';
import {AppAPI} from '@src/api/AppAPI';

const api = AppAPI.getInstance();

type Props = {
  partnership: ProviderPartner;
  setPartners: (ps: ProviderPartner[]) => unknown;
  user: AsyncData<User>;
  setShowFlashError: (v: boolean) => unknown;
  setShowFlashSuccess: (v: boolean) => unknown;
};

export function PartnerRow({
  partnership,
  setPartners,
  setShowFlashError,
  setShowFlashSuccess,

  user,
}: Props) {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const description = partnership.partnerDetails
    .map(getProviderDescription)
    .orNothing();

  return (
    <List>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <AssignmentIndIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={description || ''} />
        <IconButton
          className={classes.deleteIcon}
          onClick={() => {
            if (user.isLoaded()) {
              const providerAPI = api.provider(user.singleValue().id);
              providerAPI
                .deletePartnership(partnership.id)
                .then(ps => {
                  setShowFlashSuccess(true);
                  setPartners(ps);

                  setTimeout(() => {
                    setShowFlashSuccess(false);
                  }, 6000);
                })
                .catch(e => {
                  setShowFlashError(true);
                  setTimeout(() => setShowFlashError(false), 6000);
                })
                .finally(() => setIsSubmitting(false));
            }
          }}
        >
          {isSubmitting ? (
            <CircularProgress size={18} style={{padding: 0, margin: 0}} />
          ) : (
            <RemoveIcon />
          )}
        </IconButton>
      </ListItem>
    </List>
  );
}
