import {Optional} from '@ahanapediatrics/ahana-fp';
import {RouteComponentProps} from 'react-router';
import {
  DataTrack,
  Participant,
  RemoteParticipant,
  RemoteTrack,
  Room,
} from 'twilio-video';
import {NetworkQualityDataSetters} from '../../shared/participants/utils/networkQuality/networkQuality';
import {onAdmitted} from './admittedEventHandlers';
import {
  onTrackSubscribedOrPublished,
  onTrackUnsubscribedOrUnpublished,
} from './eventHandlers';
import {AdmittanceProps} from './types';
import {MediaFailure} from '@src/util/videoChat/userMedia/streams';
import {
  attachNetworkQualityEventListener,
  disconnectFromTwilioRoom,
  stopLocalDataTracks,
  stopTracks,
  Tracks,
} from '@src/util/videoChat';
import {
  AudioInputInfo,
  AudioOutputInfo,
  VideoInputInfo,
} from '@src/store/reducers/media';

export type AttachDataTrackEventListenerProps = {
  dataTrack: DataTrack;
  room: Room | null;
  visitId: number;
  onAdmittedProps: {
    audioIn: Optional<AudioInputInfo>;
    audioOut: Optional<AudioOutputInfo>;
    videoIn: Optional<VideoInputInfo>;
    onMediaFailure: (v: Optional<MediaFailure>) => unknown;
  };
  history: RouteComponentProps<{}>['history'];
  setSelfWaitingForAdmittance: (v: boolean) => unknown;
};

export function attachDataTrackEventListener({
  dataTrack,
  room,
  onAdmittedProps: {audioIn, audioOut, videoIn, onMediaFailure},
  history,
  setSelfWaitingForAdmittance,
  visitId,
}: AttachDataTrackEventListenerProps): void {
  dataTrack?.on('message', message => {
    const json = JSON.parse(message);
    const {visitorSid, admitted} = json;

    if (room?.localParticipant.sid === visitorSid && admitted) {
      onAdmitted({visitId, audioIn, audioOut, videoIn, onMediaFailure, room});
      setSelfWaitingForAdmittance(false);
      return;
    }

    if (room?.localParticipant.sid === visitorSid && !admitted) {
      stopTracks({room});
      stopLocalDataTracks({room});
      disconnectFromTwilioRoom({room});

      history.push(`/dead-end/deniedAdmission/${visitId}`);
    }
  });
}

export type AttachParticipantEventListenersProps = {
  participant: Participant | RemoteParticipant;
  setAudioTracks?: (fn: (vts: Tracks) => Tracks) => void;
  setVideoTracks?: (fn: (vts: Tracks) => Tracks) => void;
  room: Room | null;
  onAdmittedProps: AdmittanceProps;
  history: RouteComponentProps<{}>['history'];
  setSelfWaitingForAdmittance: (v: boolean) => unknown;
  visitId: number;
  networkQualityDataSetters: NetworkQualityDataSetters;
  isLocal: boolean;
};

export function attachParticipantEventListeners({
  participant,
  setAudioTracks,
  setVideoTracks,
  room,
  onAdmittedProps: {audioIn, audioOut, videoIn, onMediaFailure},
  history,
  setSelfWaitingForAdmittance,
  visitId,
  networkQualityDataSetters,
  isLocal,
}: AttachParticipantEventListenersProps) {
  attachNetworkQualityEventListener({
    participant,
    networkQualityDataSetters,
    visitId,
    isLocal,
  });

  participant.on('trackSubscribed', (t: RemoteTrack) => {
    onTrackSubscribedOrPublished({
      track: t,
      setAudioTracks,
      setVideoTracks,
      room,
      onAdmittedProps: {audioIn, audioOut, videoIn, onMediaFailure},
      history,
      setSelfWaitingForAdmittance,
      visitId,
    });
  });

  participant.on('trackUnsubscribed', (t: RemoteTrack) => {
    onTrackUnsubscribedOrUnpublished({
      track: t,
      setAudioTracks,
      setVideoTracks,
      room,
      onAdmittedProps: {audioIn, audioOut, videoIn, onMediaFailure},
      history,
      setSelfWaitingForAdmittance,
      visitId,
    });
  });

  participant.on('trackPublished', t => {
    onTrackSubscribedOrPublished({
      track: t.track,
      setAudioTracks,
      setVideoTracks,
      room,
      onAdmittedProps: {audioIn, audioOut, videoIn, onMediaFailure},
      history,
      setSelfWaitingForAdmittance,
      visitId,
    });
  });

  participant.on('trackUnpublished', t => {
    onTrackUnsubscribedOrUnpublished({
      track: t.track,
      setAudioTracks,
      setVideoTracks,
      room,
      onAdmittedProps: {audioIn, audioOut, videoIn, onMediaFailure},
      history,
      setSelfWaitingForAdmittance,
      visitId,
    });
  });
}
