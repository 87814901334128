import {FontAwesomeIconProps} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import AssignedFormList from './AssignedFormsList';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';
import {Modal} from '@src/components/ui/layout/Modal';
import {AssignedForm} from '@src/models';

interface Props extends Omit<FontAwesomeIconProps, 'icon'> {
  className?: string;
  forms: readonly AssignedForm[];
}

export default function FormNotificationModal({
  className,
  forms,
  ...props
}: Props) {
  const [showModal, setShowModal] = useState(false);

  if (forms.length === 0) {
    return null;
  }

  return (
    <>
      <ClickableIcon
        className={className}
        title="Assigned Forms require your attention."
        icon={['fas', 'file-exclamation']}
        onClick={() => setShowModal(true)}
        {...props}
      />

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title="Pending Assigned Forms"
        fullWidth
        maxWidth="sm"
      >
        <AssignedFormList forms={forms} />
      </Modal>
    </>
  );
}
