import React from 'react';
import {TransferOwnership} from '@src/components/shared/PatientDashboard/SCP/panels/controls/shared/TransferOwnership';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {OwnerRequest} from '@src/components/shared/PatientDashboard/SCP/panels/controls/OwnerControls/OwnerRequest';
import {Review} from '@src/components/shared/PatientDashboard/SCP/panels/controls/OwnerControls/Review';
import {SCPStatus} from '@src/models';
import {useUser} from '@src/hooks';

export function OwnerControls() {
  const [user] = useUser();
  const {
    patient,
    setScp,
    scp,
    setPatient,
    ownerId,
    selectedPanel,
  } = usePageContext();

  const oScp = scp.getOptional();

  if (
    selectedPanel !== 'ownerControls' ||
    user
      .getOptional()
      .map(u => u.id)
      .orNothing() !== ownerId ||
    !ownerId
  )
    return null;

  if (oScp.map(m => m?.status).orNothing() === SCPStatus.Draft) {
    return (
      <OwnerRequest
        patient={patient.getOptional()}
        setScp={setScp}
        setPatient={setPatient}
      />
    );
  }

  return (
    <>
      <Review />
      <TransferOwnership />
    </>
  );
}
