import React, {useCallback, useEffect, useState} from 'react';
import {UnpackNestedValue} from 'react-hook-form';
import {useParams, Prompt} from 'react-router';
import {PageLoading} from '../../ui/atoms/progressBarsAndIndicators/PageLoading';
import {VisitDocumentationForm} from '../VisitDocumentationForm';
import {VisitDocumentationValues} from '../VisitDocumentationForm/sections';
import {DashboardContent} from '../PastVisitsDashboard/layout';
import {
  visitDocumentationValuesToRequest,
  visitDocumentationToValues,
} from '../VisitDocumentationForm/functions';
import {getCanSign} from './functions';
import {useAsync, useReturnPath} from '@src/hooks';
import {VisitDocumentation, LonelyVisit} from '@src/models';
import {useApi} from '@src/api/useApi';
import {PrivatePage} from '@src/components/PrivatePage';
import {allLoaded} from '@src/util/apiHelpers/allLoaded';
import {useOnPageLeave} from '@src/hooks/useOnPageLeave';

// eslint-disable-next-line import/no-unused-modules
export default function DocumentationRoom() {
  const api = useApi();
  const [visit, setVisit] = useAsync<LonelyVisit>();
  const [documentation, setDocumentation] = useAsync<VisitDocumentation>();
  const params = useParams<{visitId: string}>();
  const visitId = +params.visitId;
  const [didSign, setDidSign] = useState(false);

  const goBack = useReturnPath();

  const CONFIRM_LEAVE_MESSAGE =
    'Please make sure that you have saved any pending changes before leaving this page.';

  const onPageLeave = useCallback((e: Event) => {
    e.preventDefault();
    return CONFIRM_LEAVE_MESSAGE;
  }, []);

  useOnPageLeave(onPageLeave);

  useEffect(() => {
    api
      .visit(visitId)
      .get()
      .then(setVisit);

    api
      .visit(visitId)
      .getDocumentation()
      .then(setDocumentation);
  }, [api, visitId, setDocumentation, setVisit]);

  const onSign = async () => {
    await api.visit(Number(visitId)).sign();
    setDidSign(true);
  };

  const onSave = async (
    values: UnpackNestedValue<VisitDocumentationValues>,
  ) => {
    const visitAPI = api.visit(+visitId);

    const request = visitDocumentationValuesToRequest(values);
    await visitAPI.updateDocumentation(request);
  };

  const loaded = allLoaded(visit, documentation);

  return (
    <PrivatePage>
      <DashboardContent>
        <PageLoading active={!loaded} message="Loading documentation">
          {loaded && (
            <VisitDocumentationForm
              initialValues={documentation
                .map(visitDocumentationToValues)
                .singleValue()}
              canSign={getCanSign({
                documentation: documentation.getOptional(),
              })}
              patient={visit.singleValue().patient}
              onDone={() => {
                goBack();
              }}
              onSign={onSign}
              onSave={onSave}
            />
          )}

          {!didSign && <Prompt message={CONFIRM_LEAVE_MESSAGE} />}
        </PageLoading>
      </DashboardContent>
    </PrivatePage>
  );
}
