import {Mergeable} from '.';
import {SimpleVisit} from '@src/models';

export const isVisitsSectionMergeable = ({
  p1Visits,
  p2Visits,
}: {
  p1Visits: readonly SimpleVisit[];
  p2Visits: readonly SimpleVisit[];
}): Mergeable => {
  if (p1Visits.length > 0 || p2Visits.length > 0) {
    return 'FAIL';
  } else {
    return 'PASS';
  }
};
