import React, {ReactNode} from 'react';
import {Modal} from '@src/components/ui/layout/Modal';
import {FileUploader} from '@src/components/ui/organisms/uploaders/files/FileUploader';
import {AppFile} from '@src/models';
import {SigningResult} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';

export function UploadModal({
  show,
  setShow,
  onFileUploadedToS3,
  selector,
  instructions,
}: {
  show: boolean;
  setShow: (v: boolean) => unknown;
  selector?: ReactNode;
  instructions?: ReactNode;
  onFileUploadedToS3: (
    result: SigningResult<AppFile>,
    file: File,
  ) => Promise<AppFile>;
}) {
  return (
    <Modal
      show={show}
      onClose={() => {
        setShow(!show);
      }}
      title="Upload File"
    >
      <FileUploader
        onFileUploadedToS3={onFileUploadedToS3}
        selector={selector}
        instructions={instructions}
      />
    </Modal>
  );
}
