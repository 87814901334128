import {DataGrid} from '@material-ui/data-grid';
import React from 'react';
import {Grid} from '@material-ui/core';
import {CustomerCodesModal} from './editCustomerCodes/CustomerCodesModal';
import {DocumentsModal} from '@src/components/separateAdminApp/ListCallPools/editDocuments/DocumentsModal';
import {AddProviderModal} from '@src/components/separateAdminApp/ListCallPools/addProvider/AddProviderModal';
import {getColumns} from '@src/components/separateAdminApp/ListCallPools/getColumns';
import {EndpointsModal} from '@src/components/separateAdminApp/ListCallPools/editEndpoints/EndpointsModal';
import {getRows} from '@src/components/separateAdminApp/ListCallPools/getRows';
import {usePageContext} from '@src/components/separateAdminApp/ListCallPools/index';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {PageHeader} from '@src/components/ui/layout/PageHeader';
import {PrivatePage} from '@src/components/PrivatePage';

export function ListCallPools() {
  const {
    callPools,
    showEditEndpoints,
    showAddProfessional,
    setShowEditEndpoints,
    setShowAddProfessional,
    editDocumentsModalResources: {
      setEditDocumentsForGroupId,
      editDocumentsForGroupId,
    },
    clientConfigurationsResources: {
      editClientConfigurationsForGroupId,
      setEditClientConfigurationsForGroupId,
    },
  } = usePageContext();

  return (
    <PrivatePage>
      <PageHeader>Groups</PageHeader>
      <PageLoading active={!callPools.isLoaded()} message="Loading Groups">
        <Grid style={{height: 520}} xs={12}>
          <DataGrid
            rows={getRows(callPools)}
            columns={getColumns({
              setShowEditEndpoints,
              setShowAddProfessional,
              setEditDocumentsForGroupId,
              setShowEditCustomerCodes: setEditClientConfigurationsForGroupId,
            })}
            disableColumnSelector
            disableSelectionOnClick
          />
        </Grid>
      </PageLoading>

      {showAddProfessional !== null && <AddProviderModal />}
      {showEditEndpoints !== null && <EndpointsModal />}
      {editDocumentsForGroupId !== null && <DocumentsModal />}
      {editClientConfigurationsForGroupId !== null && <CustomerCodesModal />}
    </PrivatePage>
  );
}
