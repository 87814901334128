import React, {useState} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {AddManagerModal} from './AddManagerModal';
import {ManagersTable} from './ManagersTable';
import {ProviderDetails} from '@src/models';
import {Button} from '@src/components/ui/form';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {useUser} from '@src/hooks';

type Props = {
  callPoolId: number;
  managers: AsyncData<ProviderDetails>;
  reloadManagers: () => Promise<unknown>;
};

export function ManagersSection({callPoolId, managers, reloadManagers}: Props) {
  const [showAddManagerModal, setShowAddManagerModal] = useState(false);
  const [user] = useUser();

  const aManagers = managers.getAllOptional().orElse([]);

  const detailsId = user
    .singleValue()
    .providerDetails.property('id', 0 as ProviderDetailsId);

  const isUserManager = aManagers.some(m => m.id === detailsId);

  return (
    <>
      <AddManagerModal
        callPoolId={callPoolId}
        show={showAddManagerModal}
        setShow={setShowAddManagerModal}
        onAdded={reloadManagers}
        managerIds={managers
          .getAllOptional()
          .orElse([])
          .map(m => m.id)}
      />
      <ManagersTable
        callPoolId={callPoolId}
        managers={managers}
        onRemoved={reloadManagers}
        isUserManager={isUserManager}
      />
      {isUserManager && (
        <Button
          onClick={() => {
            setShowAddManagerModal(true);
          }}
          bStyle="primary"
          style={{margin: '1rem 0'}}
        >
          Add Manager
        </Button>
      )}
    </>
  );
}
