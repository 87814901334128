import {Optional} from '@ahanapediatrics/ahana-fp';
import {AppAPI} from '@src/api/AppAPI';
import {Guardian, User, UserType} from '@src/models';

export async function isCurrentUserPatient({
  api,
  patientId,
  user,
}: {
  api: AppAPI;
  patientId: number;
  user: Optional<User>;
}): Promise<boolean> {
  if (
    user.map(u => u.userType).orElse(UserType.Unknown) !== UserType.Guardian
  ) {
    return false;
  }

  return api
    .patient(patientId)
    .findSelfRelationship()
    .then(r => {
      if (r === null) {
        return false;
      }

      const selfId = r?.personId;
      return user
        .cast<Guardian>(u => u.userType === UserType.Guardian)
        .map(g => g.responsiblePersonDetails)
        .map(rp => rp.id)
        .map(rpId => rpId === selfId)
        .orElse(false);
    })
    .catch(e => {
      console.warn(e);
      return false;
    });
}
