import React, {useState, useCallback} from 'react';
import {EditConnectedLoginModal} from '../EditConnectedLoginModal';
import {Connection} from './Connection';
import {ConnectionText, ConnectionTextContext} from './ConnectionText';
import {
  Invitation,
  PatientRelationship,
  LonelyPatient,
  UserType,
} from '@src/models';
import {useUser} from '@src/hooks';
import {NonProfessionalId} from '@src/models/ResponsiblePerson';

type Props = {
  currentInvitations: readonly Invitation[];
  className?: string;
  patient: LonelyPatient;
  relationship: PatientRelationship;
  reloadRelationships: () => Promise<unknown>;
};
export function CurrentRelationship({
  className = '',
  patient,
  relationship,
  reloadRelationships,
  currentInvitations,
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const [user, userType] = useUser();
  const {person, relationship: relName, isSelf} = relationship;

  const userPersonId = user
    .getOptional()
    .map(u => u.responsiblePersonDetails)
    .property('id', 0 as NonProfessionalId);

  const onClose = useCallback(() => setShowModal(false), []);

  return (
    <>
      <Connection
        className={className}
        edittable={
          userType === UserType.Professional ||
          relationship.personId === userPersonId
        }
        isSelf={isSelf}
        patient={patient}
        onClickConnectionAction={() => setShowModal(true)}
        icon="pencil"
      >
        <ConnectionText
          context={ConnectionTextContext.Connected}
          isSelf={isSelf}
          fullName={person.fullName}
          email={person.email.orElse('')}
          relationshipName={relName}
        />
      </Connection>

      <EditConnectedLoginModal
        currentInvitations={currentInvitations}
        show={showModal}
        onClose={onClose}
        readOnly={false}
        relationship={relationship}
        patient={patient}
        guardiansCount={0}
        updatePatient={() => {}}
        onRemoveRelationship={async () => {
          await reloadRelationships();
          onClose();
        }}
        onUpdateRelationship={reloadRelationships}
      />
    </>
  );
}
