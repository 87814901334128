import React, {useState, useMemo, useCallback} from 'react';
import {
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Typography,
  useTheme,
  Grid,
} from '@material-ui/core';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStyles as useManagerTableStyles} from '@src/components/providerSide/providerGroups/ProviderGroupRosterPage/MonthTable/Day/Shifts/styles';
import {Modal} from '@src/components/ui/layout/Modal';
import {AsyncActionButton} from '@src/components/ui/form';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {OnCallPeriod, ProviderDetails} from '@src/models';
import {UserId} from '@src/models/User';
import {useApi} from '@src/api/useApi';
import {byProp} from '@src/util/filters/byProp';
import {byName} from '@src/util/sorters/byName';
import {getKeys} from '@src/util/objectManipulation/getKeys';
import {isNothing} from '@src/util/typeTests';
import {LoadingIndeterminate} from '@src/components/ui/atoms/progressBarsAndIndicators/LoadingIndeterminate';
import {usePageContext} from '@src/components/providerSide/providerGroups/ProviderGroupRosterPage';
import {primary} from '@src/components/ui/theme';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {
  isMemberOfSomeGroups,
  isProviderAuthorizedForPeriod,
} from '@src/util/callPools/shifts/providerGroupAuthorization';
import {useIconStyles} from '@src/components/ui/atoms/buttonsAndIcons/styles';
import {getProviderDescription} from '@src/util/provider';

type Props = {
  show: boolean;
  setShow: (v: boolean) => unknown;
  period: OnCallPeriod;
  onAdded: () => unknown;
  periodPoolsMembers: AsyncData<ProviderDetails>;
};

function ProviderListItem({
  member,
  period,
  handleCheckboxChange,
  setShowMoreInfoModal,
  state,
}: {
  member: ProviderDetails;
  period: OnCallPeriod;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setShowMoreInfoModal: (v: HelpModalReason) => unknown;
  state: Record<string, boolean>;
}) {
  const iconClasses = useIconStyles();

  const {managedGroups} = usePageContext();
  const aManagedGroups = managedGroups.getAllOptional().orElse([]);

  const canManageProvider = isMemberOfSomeGroups(member, aManagedGroups);

  const providerAuthorized = isProviderAuthorizedForPeriod(member, period);

  const disable = !canManageProvider || !providerAuthorized;

  return (
    <span>
      <FormControlLabel
        key={member.id}
        control={
          <Checkbox
            color="primary"
            disabled={disable}
            checked={state[member.providerId ?? 0]}
            onChange={handleCheckboxChange}
            name={`${member.providerId}`}
          />
        }
        label={getProviderDescription(member)}
      />
      {disable && (
        <FontAwesomeIcon
          icon={['fas', 'question']}
          size="sm"
          color={primary}
          className={iconClasses.clickableIcon}
          onClick={() => {
            setShowMoreInfoModal(
              !canManageProvider ? 'NOT_MANAGER' : 'PROVIDER_NOT_AUTHORIZED',
            );
          }}
        />
      )}
    </span>
  );
}

type HelpModalReason = 'NOT_MANAGER' | 'PROVIDER_NOT_AUTHORIZED';

export function PopulateCallPeriod({
  show,
  setShow,
  period,
  onAdded,
  periodPoolsMembers,
}: Props) {
  const theme = useTheme();
  const api = useApi();
  const loading = !periodPoolsMembers.isLoaded();
  const managerTableClasses = useManagerTableStyles({loading});

  const {providers} = period;

  const defaultState = useMemo(() => {
    return providers.reduce<Record<string, boolean>>((acc, member) => {
      return {...acc, [member.id]: true};
    }, {});
  }, [providers]);

  const [state, setState] = useState<Record<string, boolean>>(defaultState);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [
    showMoreInfoModal,
    setShowMoreInfoModal,
  ] = useState<HelpModalReason | null>(null);

  const resetHelpModal = useCallback(() => {
    setShowMoreInfoModal(null);
  }, [setShowMoreInfoModal]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({...state, [event.target.name]: event.target.checked});
  };

  const updateProviders = useCallback(() => {
    api
      .onCallPeriods(period.id)
      .updateProviders(
        getKeys(state)
          .filter(k => state[k])
          .map(id => parseInt(id) as UserId),
      )
      .then(() => {
        onAdded();
        flashSuccess('Providers updated');
      })
      .catch(() => {
        flashError(
          'Oops! Something went wrong update Providers for this On-Call Period',
        );
      })
      .finally(() => {
        setIsSubmitting(false);
        setShow(false);
      });
  }, [api, period, onAdded, setIsSubmitting, state, setShow]);

  if (isNothing(period)) {
    return (
      <Typography
        style={{
          fontWeight: 700,
          fontSize: 18,
          color: theme.palette.warning.main,
        }}
      >
        Oops! That On-Call Period is no longer valid. Please refresh your page
        or contact Support for assistance.
      </Typography>
    );
  }

  return (
    <Modal
      show={show}
      onClose={() => {
        setShow(false);
      }}
      title="Edit Professionals"
      modalActions={
        <AsyncActionButton
          actionInProgress={isSubmitting}
          actionWord="Submit"
          onClick={updateProviders}
          bStyle="primary"
        />
      }
    >
      <FormControl
        component="fieldset"
        className={managerTableClasses.modalListContainer}
      >
        <LoadingIndeterminate active={loading} customDelayMs={2000}>
          <FormLabel component="legend">Professionals</FormLabel>
          <Grid>
            <FormGroup>
              {periodPoolsMembers.getAllOptional().orElse([]).length === 0 && (
                <ParagraphText>
                  This Shift currently has no Professionals that can be added to
                  it.
                </ParagraphText>
              )}
              {periodPoolsMembers
                .getAllOptional()
                .orElse([])
                .filter(byProp('seesPatients'))
                .sort(byName)
                .map(m => (
                  <ProviderListItem
                    member={m}
                    handleCheckboxChange={handleCheckboxChange}
                    period={period}
                    state={state}
                    setShowMoreInfoModal={setShowMoreInfoModal}
                  />
                ))}
            </FormGroup>
          </Grid>
        </LoadingIndeterminate>
      </FormControl>
      {showMoreInfoModal && (
        <Modal
          show={true}
          title="Why can't I edit this Professional?"
          onClose={resetHelpModal}
        >
          {showMoreInfoModal === 'NOT_MANAGER' ? (
            <ParagraphText>
              Because you aren't a Manager of any of this Professional's Groups,
              you cannot add/remove them.
            </ParagraphText>
          ) : (
            <ParagraphText>
              Because this Professional is not a member of all of this Shift's
              Groups, you cannot add them.
            </ParagraphText>
          )}
        </Modal>
      )}
    </Modal>
  );
}
