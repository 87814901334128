import React, {useEffect, useState} from 'react';
import {Grid, Typography, Button} from '@material-ui/core';
import {PageContent, PageTitle, ErrorMessage} from '../CreateProvider/layout';
import {PDFDownloadButton} from '../PdfDownloadButton';
import {LonelyVisit, VisitState} from '@src/models';
import {useApi} from '@src/api/useApi';
import {visitIsSigned} from '@src/util/visits';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';

const PAGE_SIZE = 10;

// eslint-disable-next-line import/no-unused-modules
export default function VisitReports() {
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [visits, setVisits] = useState<LonelyVisit[]>([]);
  const [currentPage, setCurrentPage] = useState(-1);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState('');
  const [visibleStates, setVisibleStates] = useState<VisitState[]>([
    VisitState.COMPLETED,
  ]);

  useEffect(() => {
    if (!loading && page !== currentPage) {
      setLoading(true);
      api
        .visit()
        .getAll(page * PAGE_SIZE, PAGE_SIZE)
        .then(({count, rows}) => {
          setTotal(count);
          return rows.sort((b, a) => a.uid.localeCompare(b.uid));
        })
        .then(vs => {
          setVisits(v => v.concat(vs));
        })
        .catch(e => {
          setError(`Error getting visits: ${e.message}`);
        })
        .finally(() => {
          setCurrentPage(page);
          setLoading(false);
        });
    }
  }, [api, currentPage, page, loading]);

  return (
    <>
      <PageContent>
        <PageTitle landing>Visit Reports</PageTitle>
        <PageLoading active={loading} message="Loading Reports">
          <Grid container direction="row">
            {[
              VisitState.CANCELED,
              VisitState.COMPLETED,
              VisitState.CREATED,
              VisitState.DOCUMENTING,
              VisitState.EXPIRED,
              VisitState.IN_PROGRESS,
            ].map(status => (
              <Grid item key={status} style={{marginBottom: '2rem'}}>
                <Button
                  color="primary"
                  variant={
                    visibleStates.includes(status) ? 'contained' : 'outlined'
                  }
                  onClick={() => {
                    if (visibleStates.includes(status)) {
                      setVisibleStates(visibleStates.filter(s => s !== status));
                    } else {
                      setVisibleStates(visibleStates.concat(status));
                    }
                  }}
                >
                  {status}
                </Button>
              </Grid>
            ))}
          </Grid>

          {error !== '' && <ErrorMessage>{error}</ErrorMessage>}
          <Grid container spacing={1}>
            {!loading && visits.length === 0 && (
              <Grid item xs={12}>
                There are no visits in the system
              </Grid>
            )}
            {visits
              .filter(v => visibleStates.includes(v.status))
              .map(visit => (
                <Grid item xs={12} key={visit.uid}>
                  <Typography variant="h5" color="primary">
                    {visit.uid} [{visit.status}]
                  </Typography>

                  <Typography variant="body1">
                    Patient {visit.patient.fullName} with{' '}
                    {visit.providerDetails.property('fullName', 'No Doctor')}
                  </Typography>
                  <Grid item xs={12}>
                    <PDFDownloadButton
                      fileUrl={api.visit(visit.id).getReportDownloadURL()}
                      authorized
                      text="Download Report"
                    />
                  </Grid>

                  {visitIsSigned(visit) && (
                    <Grid item xs={12}>
                      <PDFDownloadButton
                        fileUrl={api
                          .visit(visit.id)
                          .getDocumentationDownloadURL()}
                        authorized
                        text="Download Documentation"
                      />
                    </Grid>
                  )}
                </Grid>
              ))}

            {total > visits.length && (
              <Button onClick={() => setPage(page + 1)}>Get More...</Button>
            )}
          </Grid>
        </PageLoading>
      </PageContent>
    </>
  );
}
