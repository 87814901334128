import {Optional} from '@ahanapediatrics/ahana-fp';
import {Grid, Typography, Checkbox} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import React, {useState} from 'react';
import styled from 'styled-components';
import {CardContents} from '../../InfoCard';
import {useStyles} from '../styles';
import {LastUpdated} from './LastUpdated';
import {TextInput} from '@src/components/ui/form';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {SCPHeading} from '@src/components/shared/PatientDashboard/SCP/SCPHeading';
import {
  MedicalHistory,
  MedicalHistoryDetails,
  medicalHistoryDetailsFromJson,
} from '@src/models';
import {useApi} from '@src/api/useApi';
import {getQualification} from '@src/util/provider/getQualification';

type Option = {
  value: string;
  label: string;
};

type Props = {
  canEdit: boolean;
  placeholder?: string;
  instructions?: string;
  medicalHistory: Optional<MedicalHistory>;
  onUpdateMedicalHistory: (medicalHistory: MedicalHistory) => void;
  options?: Option[];
  patientId: number;
  property: keyof MedicalHistoryDetails;
  title: string;
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export function HistoryBox({
  canEdit = false,
  placeholder = '',
  instructions = '',
  medicalHistory,
  onUpdateMedicalHistory,
  options,
  patientId,
  property,
  title,
}: Props) {
  const api = useApi();
  const classes = useStyles();

  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [saving, setSaving] = useState(false);
  const [stepsUnderEdit, setStepsUnderEdit] = useState('');

  const stepValue: string =
    medicalHistory
      .map(m => m.details[property])
      .cast<string>(v => typeof v === 'string')
      .orElse('') || placeholder;

  const stepDisplay = (v => {
    if (!options) {
      return v;
    }
    const option = options.find(o => o.value === v);
    return option ? option.label : v;
  })(stepValue);

  const propModification = medicalHistory.map(m => m.modifications[property]);
  const updated = propModification.map(mod => mod.when);
  const updater = propModification.map(mod => mod.who);

  return (
    <Grid container direction="row" className={classes.rowContainer}>
      <Grid item>
        <SCPHeading>{title}</SCPHeading>
      </Grid>
      {canEdit && (
        <Grid item>
          {mode === 'view' && (
            <EditIcon
              onClick={() => {
                setMode('edit');
                setStepsUnderEdit(stepValue);
              }}
              className={classes.icon}
            />
          )}

          {mode !== 'view' && (
            <div
              onClick={() => {
                setMode('view');
              }}
            >
              <CloseIcon className={classes.icon} />
            </div>
          )}
        </Grid>
      )}

      {instructions && (
        <Grid item xs={12} className={classes.gridItem}>
          <Typography variant="caption" style={{fontStyle: 'italic'}}>
            {instructions}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} className={classes.gridItem}>
        <LastUpdated
          updated={{
            lastUpdatedBy: updater,
            updatedAt: updated,
            qualification: Optional.of(updater.map(getQualification).orNull()),
          }}
        />
      </Grid>
      {mode === 'view' && (
        <Grid
          item
          xs={12}
          className={classes.gridItem}
          style={{marginTop: '2rem'}}
        >
          <ParagraphText>{stepDisplay}</ParagraphText>
        </Grid>
      )}

      <CardContents>
        {mode === 'edit' && (
          <Grid item xs={12} className={classes.gridItem}>
            {options && (
              <div>
                {options.map(o => (
                  <Checkbox
                    key={o.value}
                    checked={stepsUnderEdit === o.value}
                    name="steps"
                    onChange={e => setStepsUnderEdit(o.value)}
                    title={o.label}
                  />
                ))}
              </div>
            )}
            {!options && (
              <TextInput
                name="steps"
                value={stepsUnderEdit}
                onChange={e => setStepsUnderEdit(e.target.value)}
                lines={3}
                autoExpand={true}
                placeholder={instructions}
              />
            )}
            <ButtonContainer>
              <MuiAsyncActionButton
                actionInProgress={saving}
                bStyle="primary"
                bSize="small"
                actionWord="Save"
                onClick={() => {
                  setSaving(true);
                  api
                    .patient(patientId)
                    .updateMedicalHistory({
                      ...medicalHistory
                        .map(m => m.details)
                        .orElseGet(() => medicalHistoryDetailsFromJson({})),

                      [property]: stepsUnderEdit,
                    })
                    .then(mh => {
                      onUpdateMedicalHistory(mh);
                      setSaving(false);
                      setMode('view');
                    });
                }}
              />
            </ButtonContainer>
          </Grid>
        )}
      </CardContents>
    </Grid>
  );
}
