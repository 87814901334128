import React from 'react';
import {PatientDetailsForm} from '../../../SharedPatient/PatientDetailsForm';
import {StepProps} from '../../stepFunctions';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {FlowProps} from '@src/hooks/useSteps';

export function PatientDetailsStep({
  moveGuard,
  patientDetails,
  setPatientDetails,
  existingPersonForIndependentPatient,
  setExistingPersonForIndependentPatient,
  setPatientIsCurrentUser,
}: StepProps & FlowProps) {
  return (
    <>
      <RequestHeader>Patient Details</RequestHeader>
      <RequestInstructions>
        <p>Let's start by getting the basic information about this patient.</p>
      </RequestInstructions>
      <PatientDetailsForm
        setPatientDetails={setPatientDetails}
        mode="create"
        moveGuard={moveGuard}
        patientDetails={patientDetails}
        existingPersonForIndependentPatient={
          existingPersonForIndependentPatient
        }
        setExistingPersonForIndependentPatient={
          setExistingPersonForIndependentPatient
        }
        setPatientIsCurrentUser={setPatientIsCurrentUser}
      />
    </>
  );
}
