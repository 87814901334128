import React, {ReactNode} from 'react';
import {Typography} from '@material-ui/core';
import {Variant} from '@material-ui/core/styles/createTypography';
import {Skeleton} from '@material-ui/lab';
import {useStyles} from './layout';
import {LonelyPatient} from '@src/models';

type DisplayProps = {
  condensed?: boolean;
  color?: 'dark' | 'light';
  variant?: Variant;
  renderer: (p: LonelyPatient) => ReactNode;
  patient?: LonelyPatient;
};

export function PatientFundamental({
  condensed = false,
  color,
  renderer,
  patient,
  variant = 'subtitle1',
}: DisplayProps) {
  const classes = useStyles();
  return (
    <Typography
      variant={variant}
      className={`${condensed ? classes.condensedText : classes.text} ${
        color === 'dark' ? classes.dark : ''
      }`}
    >
      {patient ? <>{renderer(patient)}</> : <Skeleton />}
    </Typography>
  );
}
