import React, {useCallback} from 'react';
import {useAssignProfessional} from './useAssignProfessional';
import {LonelyVisit} from '@src/models';
import {AssignProfessionalModal} from '@src/components/shared/VideoChat/ExamRoomModals/AssignProfessionalModal';

type AssignProfessionalProps = {
  onUpdate: (v: LonelyVisit) => unknown;
  returnText?: string;
  visit: LonelyVisit;
  onClose: () => unknown;
  showAssignModal: boolean;
};

export function AssignProfessionalAction({
  onUpdate,
  returnText,
  visit,
  onClose,
  showAssignModal,
}: AssignProfessionalProps) {
  const onAssign = useCallback(
    (v: LonelyVisit) => {
      onClose();
      onUpdate(v);
    },
    [onUpdate, onClose],
  );

  const assignProfessional = useAssignProfessional(visit, onAssign);

  return (
    <>
      {showAssignModal && (
        <AssignProfessionalModal
          onAssignProfessional={assignProfessional}
          visit={visit}
          showModal={showAssignModal}
          returnToPatient={onClose}
          returnText={returnText}
        />
      )}
    </>
  );
}
