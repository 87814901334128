import React, {useCallback} from 'react';
import {FormLabel, Paper, TextField as MuiTextField} from '@material-ui/core';
import {TextField} from '@src/models';

interface Props {
  field: TextField;
  onChange?: (value: string | null) => unknown;
  readonly?: boolean;
  value: string | null;
}

export default function FormTextInput({
  field,
  onChange = () => {},
  readonly = true,
  value,
}: Props) {
  const id = `form-text-input-${field.id}`;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value === '' ? null : e.target.value);
    },
    [onChange],
  );

  return (
    <Paper style={{padding: '1em'}}>
      <FormLabel component="div" required={field.required} disabled={readonly}>
        {/*
         * Please note the subset of HTML tags considered valid within a <label> tag.
         * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label#technical_summary
         *
         * > Phrasing content, but no descendant label elements.
         * > No labelable elements other than the labeled control are allowed.
         *
         * https://developer.mozilla.org/en-US/docs/Web/HTML/Content_categories#phrasing_content
         */}
        <label
          className="inline-block"
          htmlFor={id}
          dangerouslySetInnerHTML={{__html: field.description}}
        />
      </FormLabel>
      <MuiTextField
        id={id}
        name={field.name}
        value={String(value ?? null)}
        placeholder=""
        onChange={handleChange}
        disabled={readonly}
        fullWidth
      />
    </Paper>
  );
}
