import React from 'react';
import {useStyles} from '../../FinanciallyResponsibleComparer/styles';
import {Button} from '@src/components/ui/form';

type Props = {
  activeStep: number;
  steps: string[];
  setActiveStep: (v: number) => unknown;
  disabled: boolean;
};

export function StepperButtons({
  activeStep,
  steps,
  setActiveStep,
  disabled,
}: Props) {
  const classes = useStyles();

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div className={classes.buttonsContainer}>
      <Button
        disabled={activeStep === 0}
        onClick={handleBack}
        className={classes.button}
        bSize="small"
      >
        Back
      </Button>

      <Button
        bStyle="contained"
        bSize="small"
        onClick={handleNext}
        className={classes.button}
        disabled={disabled}
      >
        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
      </Button>
    </div>
  );
}
