import {useCallback, useRef, useState, MutableRefObject} from 'react';

export type FlowProps = {
  moveGuard: MutableRefObject<(dir: 'back' | 'next') => Promise<boolean>>;
  proceedToNextStep: () => unknown;
  setNextAvailable: (available: boolean) => unknown;
};

/**
 * This is guaranteed to be a valid component
 */
export type StepComponent<T> = (props: FlowProps & T) => JSX.Element | null;

/**
 * This hook handles all the internals of step management through a plan
 *
 * It returns:
 *
 * ready - indicates that the steps have been set up and are ready to go
 * currentStep - the number of the current step
 * isLastStep - a boolean that is true on the last step
 * Component - the component representing the current step
 * {forward, backward, reset} - functions for navigating the steps
 * stepNames - an ordered list of the steps names
 * updateFlow - a function for setting the steps in a flow
 */
export const useSteps = <T, N extends string>(
  StepComponents: Record<N, StepComponent<T>>,
  StepNames: Record<N, string>,
): [
  boolean,
  number,
  boolean,
  StepComponent<T>,
  {forward: () => unknown; backward: () => unknown; reset: () => unknown},
  string[],
  (plan: N[]) => unknown,
] => {
  const [ready, setReady] = useState(false);
  const [steps, setSteps] = useState<
    {name: string; component: StepComponent<T>}[]
  >([]);
  const [currentStep, setCurrentStep] = useState(0);

  const isLastStep = currentStep + 1 === steps.length;
  const Component: StepComponent<T> =
    steps[currentStep]?.component ?? (() => null);

  const forward = useCallback(() => setCurrentStep(currentStep + 1), [
    currentStep,
  ]);
  const backward = useCallback(() => setCurrentStep(currentStep - 1), [
    currentStep,
  ]);
  const reset = useCallback(() => setCurrentStep(0), []);

  const updateFlow = useRef((plan: N[]) => {
    setSteps(
      plan.map(p => ({name: StepNames[p], component: StepComponents[p]})),
    );
    setReady(true);
  });

  return [
    ready,
    currentStep,
    isLastStep,
    Component,
    {forward, backward, reset},
    steps.map(s => s.name),
    updateFlow.current,
  ];
};
