import React, {useState} from 'react';
import {Grid} from '@material-ui/core';
import {AsyncActionButton, Button, TextInput} from '../../ui/form';
import {Modal} from '../../ui/layout/Modal';
import {ParagraphText} from '../../ui/layout/text/body/ParagraphText';
import {EditIcon} from './InfoCard';
import {AppFile} from '@src/models';
import {useApi} from '@src/api/useApi';

type Props = {
  file: AppFile;
  onSave: (file: AppFile) => unknown;
};
export const EditFileIcon: React.FunctionComponent<Props> = ({
  file,
  onSave,
}) => {
  const api = useApi();
  const [comment, setComment] = useState(file.comment.orElse(''));
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);

  return (
    <>
      <EditIcon
        icon="pencil"
        onClick={() => {
          setShow(true);
        }}
      />
      <Modal show={show} title="File description">
        <Grid container direction="row">
          <Grid item xs={12}>
            <ParagraphText>
              If your file's name is not descriptive enough, you can add a
              comment to explain what this file is.
            </ParagraphText>

            <TextInput
              name="comment"
              value={comment}
              placeholder={file.filename}
              onChange={e => setComment(e.target.value)}
            />
            <AsyncActionButton
              isInRow={true}
              actionInProgress={saving}
              actionWord="Save"
              bStyle="primary"
              onClick={() => {
                setSaving(true);
                api
                  .file(file.id)
                  .setComment(comment)
                  .then(onSave)
                  .then(() => {
                    setSaving(false);
                    setShow(false);
                  });
              }}
            />

            <Button
              isInRow={true}
              disabled={saving}
              onClick={() => {
                setComment(file.comment.orElse(''));
                setShow(false);
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
