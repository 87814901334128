import React, {useEffect} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {ExpandableSection} from '../ExpandableSection';
import {FailText} from '../shared/ExplanationText/FailText';
import {
  isSectionMergeable,
  Mergeable,
} from '../ExpandableSection/isMergeable/isSectionMergeable';
import {ComparerMessage} from '../shared/ComparerMessage';
import {Patient} from '@src/models';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';

type Props = {
  patientOne: AsyncData<Patient>;
  patientTwo: AsyncData<Patient>;
  setMergeable: (mergeable: Mergeable) => unknown;
  mergeable: Mergeable;
};

export function ScpComparer({
  patientOne,
  patientTwo,
  setMergeable,
  mergeable,
}: Props) {
  useEffect(() => {
    setMergeable(
      isSectionMergeable({
        p1: patientOne,
        p2: patientTwo,
        sectionName: 'scp',
      }),
    );
  }, [patientOne, patientTwo]);

  return (
    <ExpandableSection title="Shared Care Plan" mergeable={mergeable}>
      {mergeable === 'FAIL' && <FailText />}
      {mergeable === 'PASS' && (
        <ComparerMessage>
          <ParagraphText style={{marginBottom: 0}}>
            Shared Care Plans can be merged automatically.
          </ParagraphText>
        </ComparerMessage>
      )}
    </ExpandableSection>
  );
}
