import React, {ReactNode} from 'react';
import Typography from '@material-ui/core/Typography';
import {Grid} from '@material-ui/core';
import {ViewContentContainer} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/ViewContentContainer';
import {md, sm} from '@src/components/ui/theme';
import {isEmpty} from '@src/util/typeTests';

export function Value({
  lastUpdated,
  currentValue,
}: {
  lastUpdated?: ReactNode;
  currentValue: string;
}) {
  if (isEmpty(currentValue)) {
    return null;
  }

  return (
    <ViewContentContainer>
      <Grid container direction="row">
        <Grid item xs={12} style={{marginTop: sm}}>
          <Typography>{currentValue}</Typography>
        </Grid>
        {lastUpdated && (
          <Grid item xs={12} style={{marginTop: md}}>
            {lastUpdated}
          </Grid>
        )}
      </Grid>
    </ViewContentContainer>
  );
}
