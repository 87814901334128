import {Controller, useForm} from 'react-hook-form';
import React, {useCallback, useEffect} from 'react';
import {yupResolver} from '@hookform/resolvers';
import {Modal} from '@src/components/ui/layout/Modal';
import {TextInput, AsyncActionButton} from '@src/components/ui/form';
import {yObject, rqString} from '@src/schema/types';
import {CallPool} from '@src/models';
import {PayloadForProviderGroupDetails} from '@src/api/CallPoolAPI';

type IdentificationModalProps = {
  show: boolean;
  setProviderGroupDetails: (
    r: PayloadForProviderGroupDetails,
  ) => Promise<unknown>;
  setShowEditProviderGroupModal: (v: boolean) => unknown;
  providerGroup: CallPool;
};

const schema = yObject({
  nameForProviders: rqString('Please include a name for Professionals'),
  nameForGuardians: rqString('Please include a name for Non-Professionals'),
  description: rqString('Please include a description'),
});

export function EditProviderGroupModal({
  show,
  setProviderGroupDetails,
  setShowEditProviderGroupModal,
  providerGroup,
}: IdentificationModalProps) {
  const {
    formState: {isSubmitting},
    control,
    errors,
    handleSubmit,
    reset,
  } = useForm<PayloadForProviderGroupDetails>({
    defaultValues: {
      nameForProviders: providerGroup.nameForProviders,
      nameForGuardians: providerGroup.nameForGuardians,
      description: providerGroup.description,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (providerGroup) {
      reset({
        nameForProviders: providerGroup.nameForProviders,
        nameForGuardians: providerGroup.nameForGuardians,
        description: providerGroup.description,
      });
    }
  }, [providerGroup]);

  const closeModal = useCallback(() => setShowEditProviderGroupModal(false), [
    setShowEditProviderGroupModal,
  ]);

  const submit = handleSubmit(data =>
    setProviderGroupDetails(data).then(closeModal),
  );

  return (
    <Modal
      onClose={closeModal}
      show={show}
      title="Provider Group Information"
      modalActions={
        <AsyncActionButton
          actionInProgress={isSubmitting}
          actionWord="Submit"
          bStyle="primary"
          onClick={submit}
          style={{marginTop: '2rem'}}
          disabled={isSubmitting}
        />
      }
    >
      <Controller
        control={control}
        name="nameForProviders"
        title="Name For Professionals"
        render={({name, onChange, value}, {isTouched}) => (
          <TextInput
            onChange={onChange}
            name={name}
            title="Name For Professionals"
            value={value}
            required
            error={errors.nameForProviders?.message}
            touched={isTouched}
          />
        )}
      />
      <Controller
        control={control}
        name="nameForGuardians"
        title="Name For Non-Professionals"
        render={({name, onChange, value}, {isTouched}) => (
          <TextInput
            onChange={onChange}
            name={name}
            title="Name For Non-Professionals"
            value={value}
            required
            error={errors.nameForGuardians?.message}
            touched={isTouched}
          />
        )}
      />

      <Controller
        control={control}
        name="description"
        title="Description"
        render={({name, onChange, value}, {isTouched}) => (
          <TextInput
            onChange={onChange}
            name={name}
            title="Description"
            value={value}
            required
            error={errors.description?.message}
            touched={isTouched}
          />
        )}
      />
    </Modal>
  );
}
