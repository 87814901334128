import React from 'react';
import {Grid} from '@material-ui/core';
import {useStyles} from '../CreateProvider/styles';
import {CustomerForm} from './CustomerForm';
import {PrivatePage} from '@src/components/PrivatePage';
import {PageHeader} from '@src/components/ui/layout';

// eslint-disable-next-line import/no-unused-modules
export default function CreateCustomerPage() {
  const classes = useStyles();

  return (
    <PrivatePage>
      <PageHeader>Create a Customer</PageHeader>

      <Grid container direction="row" className={classes.formContainer}>
        <CustomerForm />
      </Grid>
    </PrivatePage>
  );
}
