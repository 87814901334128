import {ProviderDetails} from '@src/models';

export type ProviderRow = {
  lastName: string;
  firstName: string;
  qualification: string;
  speciality: string;
  seesPatients: string;
  id: string;
};

export const COLUMNS: Record<keyof ProviderRow, string> = {
  lastName: 'Last Name',
  firstName: 'First Name',
  qualification: 'Qualification',
  speciality: 'Specialty',
  seesPatients: 'Sees Patients',
  id: 'id',
};

export function getProviderRow(providerDetails: ProviderDetails): ProviderRow {
  return {
    lastName: providerDetails.lastName ?? '',
    firstName: providerDetails.firstName ?? '',
    qualification: providerDetails.qualification ?? '',
    speciality: providerDetails.speciality ?? '',
    seesPatients: providerDetails.seesPatients ? 'Yes' : 'No',
    id: `${providerDetails.id}`,
  };
}
