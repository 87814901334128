import React from 'react';
import {useHistory} from 'react-router';
import {
  AdminToolsContainer,
  useStyles,
} from '../separateAdminApp/CreateProvider/layout';
import {PrivatePage} from '@src/components/PrivatePage';
import {PageHeader} from '@src/components/ui/layout';
import {ActionCard} from '@src/components/separateAdminApp/ActionCard';
import {useUser} from '@src/hooks';
import {UserType} from '@src/models';

// eslint-disable-next-line import/no-unused-modules
export default function AdminDashboard() {
  const history = useHistory();
  const classes = useStyles();
  const [, userType, , permissions] = useUser();

  const canCreateProviders = permissions.includes('provider:create');
  const canCreateGroups = permissions.includes('callPool:create');

  return (
    <PrivatePage>
      <PageHeader>Management Dashboard</PageHeader>
      <AdminToolsContainer>
        {(canCreateProviders || userType === UserType.Admin) && (
          <ActionCard
            className={classes.actionCard}
            label="Create Provider"
            link="/create-provider"
            icon="user-plus"
            history={history}
          />
        )}

        {(canCreateGroups || userType === UserType.Admin) && (
          <ActionCard
            className={classes.actionCard}
            label="Create Call Pool"
            link="/create-group"
            icon="users-medical"
            history={history}
          />
        )}
      </AdminToolsContainer>
    </PrivatePage>
  );
}
