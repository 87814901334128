import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  FormEvent,
  FormEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import {error, sm, xs} from '../theme';

export type InputProps<T, E = HTMLInputElement> = {
  className?: string;
  disabled?: boolean;
  error?: string | boolean;
  hidden?: boolean;
  instructions?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputProps?: {[key: string]: any};
  name: string;
  onKeyDown?: KeyboardEventHandler;
  onKeyUp?: KeyboardEventHandler;
  onInput?: FormEventHandler<E>;
  onClick?: MouseEventHandler<E>;
  placeholder?: string;
  required?: boolean;
  title?: string;
  touched?: boolean;
  value?: T | undefined;
  endAdornment?: ReactNode;
};

export const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${error};
  position: absolute;
  right: ${sm};
  top: ${xs};
`;

export const forceLower = <T extends HTMLInputElement>(e: FormEvent<T>) => {
  e.currentTarget.value = e.currentTarget.value.toLowerCase();
};

export const forceUpper = <T extends HTMLInputElement>(e: FormEvent<T>) => {
  e.currentTarget.value = e.currentTarget.value.toUpperCase();
};
