import React from 'react';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {Button} from '@src/components/ui/form';
import {Modal} from '@src/components/ui/layout/Modal';

type Props = {
  show: boolean;
  onClose: () => unknown;
};

export function SCPExplainerModal({show, onClose}: Props) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      title="What is a shared care plan?"
      modalActions={<Button onClick={onClose}>Close</Button>}
    >
      <ParagraphText>
        A shared care plan is a secure, cloud-based emergency care plan to
        improve communication and delivery of medical care for patients with
        medical complexity and special health care needs.
      </ParagraphText>
      <ParagraphText>
        A shared care plan is a secure, cloud-based emergency care plan to
        improve communication and delivery of medical care for patients with
        medical complexity and special health care needs.
      </ParagraphText>
      <ParagraphText>
        If you already have a shared care plan in Refyne Connected Care, you can
        view it by clicking on the button. If you don't, you can ask your
        healthcare provider to create one, or you can create one yourself and
        nominate them to be the owner.
      </ParagraphText>
    </Modal>
  );
}
