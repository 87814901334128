import {makeStyles} from '@material-ui/core/styles';
import {md} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  contentCell: {
    color: theme.palette.grey[200],
    padding: '1rem 0 1rem 1rem',
    fontSize: 16,
    fontWeight: 300,
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  labelCell: {
    fontSize: 16,
    fontWeight: 700,
    paddingLeft: 0,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    color: theme.palette.common.white,
    padding: '1rem 0',
  },

  leftSection: {
    backgroundColor: theme.palette.secondary.main,
    padding: '2rem',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      borderTopRightRadius: theme.spacing(0.5),
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  rightSection: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      borderBottomLeftRadius: theme.spacing(0.5),
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: theme.spacing(0.5),
    },
  },
  detailsSection: {
    marginBottom: md,
  },
}));
