import React, {useEffect, useState, MutableRefObject} from 'react';
import {DashboardContent} from '../../../providerSide/PastVisitsDashboard/layout';
import {Header, NavigationContainer} from './layout';
import {getBackNavigationButtons} from './functions';
import Step from './Step';
import {PrivatePage} from '@src/components/PrivatePage';
import {Button} from '@src/components/ui/form';
import {PageHeader} from '@src/components/ui/layout';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {RequestBox} from '@src/components/ui/layout/NewThingRequest';
import {TwoGroupsOfButtons} from '@src/components/ui/molecules/buttons/TwoGroupsOfButtons';
import {useSteps, StepComponent} from '@src/hooks/useSteps';
import {StepperContainer} from '@src/components/guardianSide/CreateOrJoinVisit/layout';

/**
 * Flow Page
 *
 * className:       This is the usual component property for adding a class for styling
 * dataReady:       This is a flag that allows users to hold off on starting up a
 *                  flow until all the relevant data is in place
 * getForwardNavigationButtons:  A callback to return buttons for proceeding in the flow. The
 *                  Start and Back buttons are hard coded into this component
 * getPlan:         A callback to get this list of steps for the flow
 * moveGuard:       This is a _Ref_ to a function that returns a Promise<boolean>
 *                  Each step should update its value to represent the necessary
 *                  guards on proceeding (or going back). This would include things
 *                  like form validation and saving data
 * onCancel:        If this is set, the flow can be canceled and this handler
 *                  will be called
 * onPerformTask:   When the flow is over, this is called so that the task associated
 *                  with the flow can be performed
 * onTaskPerformed: This is called with the results of the peformed task
 *                  If it returns true, then the flow is reset
 * resetState:      This is called when this component is being reset so that the
 *                  state held externally to it can be reset
 * StepComponents:  These are the components that make up the steps of the flow
 * StepNames:       These are the names of the steps for displaying at the top of the flow
 * stepProperties:  This is an object holding all the relevant properties for the steps
 * title:           This is the title of this flow
 */
type Props<StepProperties, Result, Names extends string> = {
  className?: string;
  dataReady?: boolean;
  getForwardNavigationButtons: (opts: {
    currentStepName: string;
    isLastStep: boolean;
    proceedToNextStep: () => unknown;
    nextAvailable: boolean;
    saving: boolean;
  }) => JSX.Element[] | [];
  getPlan: () => Names[];
  moveGuard: MutableRefObject<(dir: 'back' | 'next') => Promise<boolean>>;
  onCancel?: () => unknown;
  onPerformTask: () => Promise<Result | null>;
  onTaskPerformed: (result: Result | null) => Promise<boolean>;
  resetState: () => unknown;
  StepComponents: Record<Names, StepComponent<StepProperties>>;
  StepNames: Record<Names, string>;
  stepProperties: StepProperties;
  title: string;
};

export function FlowPage<S, R, N extends string>({
  className,
  dataReady = true,
  getForwardNavigationButtons,
  getPlan,
  moveGuard,
  resetState,
  onCancel,
  onPerformTask,
  onTaskPerformed,
  StepComponents,
  StepNames,
  stepProperties,
  title,
}: Props<S, R, N>) {
  const [saving, setSaving] = useState(false);
  const [nextAvailable, setNextAvailable] = useState(false);
  const [iteration, setIteration] = useState(0);

  /*
   * FLOW MANAGEMENT START
   */
  const [
    flowReady,
    currentStep,
    isLastStep,
    Component,
    {forward, backward, reset},
    stepNames,
    updateFlow,
  ] = useSteps<S, N>(StepComponents, StepNames);

  const resetForm = () => {
    resetState();
    reset();
    setIteration(iteration + 1);
  };

  const proceedToNextStep = () => {
    moveGuard.current('next').then(proceed => {
      if (proceed) {
        if (isLastStep) {
          setSaving(true);
          onPerformTask()
            .then(onTaskPerformed)
            .then(doReset => {
              if (doReset) {
                resetForm();
              }
            })
            .catch(() => setSaving(false));
        } else {
          setNextAvailable(false);
          forward();
        }
      }
    });
  };
  const goBackAStep = () => {
    moveGuard.current('back').then(proceed => {
      if (proceed) {
        backward();
      }
    });
  };
  /*
   * FLOW MANAGEMENT END
   */

  useEffect(() => {
    const flow = getPlan();
    updateFlow(flow);
  }, [getPlan, updateFlow]);

  const ready = dataReady && flowReady;

  return (
    <PrivatePage>
      <PageLoading active={!ready} message="Loading patient information">
        <DashboardContent className={className}>
          <Header>
            <PageHeader>{title}</PageHeader>
            {onCancel && <Button onClick={onCancel}>Cancel</Button>}
          </Header>
          <StepperContainer>
            {stepNames.map((name, stepNumber) => (
              <Step
                key={stepNumber}
                step={stepNumber}
                name={name}
                complete={currentStep > stepNumber}
                upcoming={currentStep < stepNumber}
              />
            ))}
          </StepperContainer>
          <RequestBox>
            {ready && Component && (
              <Component
                key={iteration}
                moveGuard={moveGuard}
                proceedToNextStep={proceedToNextStep}
                setNextAvailable={setNextAvailable}
                {...stepProperties}
              />
            )}
          </RequestBox>
          <NavigationContainer>
            <TwoGroupsOfButtons
              leftOrBottomGroup={getBackNavigationButtons({
                currentStep,
                goBackAStep,
                saving,
                resetForm,
              })}
              rightOrTopGroup={getForwardNavigationButtons({
                currentStepName: stepNames[currentStep],
                isLastStep,
                proceedToNextStep,
                saving,
                nextAvailable,
              })}
            />
          </NavigationContainer>
        </DashboardContent>
      </PageLoading>
    </PrivatePage>
  );
}
