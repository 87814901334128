import {Room} from 'twilio-video';
import {
  attachParticipantConnected,
  attachParticipantDisconnected,
} from '@src/util/videoChat/twilio/anonymousVisitor';

import {attachParticipantReconnecting} from '@src/util/videoChat/twilio/shared';

import {SetParticipants} from '@src/util/videoChat';

export type AttachRoomEventListenersProps = {
  room: Room;
  setParticipants: SetParticipants;
  setNotification: (n: JSX.Element | null) => unknown;
  setShowReloadingModal: (v: boolean) => unknown;
};

export function attachRoomEventListeners({
  room,
  setParticipants,
  setNotification,
  setShowReloadingModal,
}: AttachRoomEventListenersProps) {
  attachParticipantConnected({
    room,
    setParticipants,
    setNotification,
  });

  attachParticipantDisconnected({
    room,
    setParticipants,
  });

  attachParticipantReconnecting({
    room,
    setNotification,
    setShowReloadingModal,
  });
}
