import React, {useEffect} from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useStyles} from '../../shared/Tables/styles';
import {getMergeStateForRow} from '../../shared/Tables/getMergeStateForRow';
import {ResultIconCell} from '../../shared/Tables/ResultIconCell';
import {BasicDetailsResult} from './utils/resultFunctions';
import {ExistingDataCell} from './ExistingDataCell';
import {getBasicDetailsRows} from './utils/getBasicDetailsRows';
import {OverrideCell} from './OverrideCell';
import {ResultCell} from './ResultCell';
import {populateResultWithLoadedData} from './utils/basicDetailsResultFunctions';
import {Patient} from '@src/models';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';

type Props = {
  patientOne: AsyncData<Patient>;
  patientTwo: AsyncData<Patient>;
  setResult: (r: BasicDetailsResult) => unknown;
  result: BasicDetailsResult;
};

export function Rows({patientOne, patientTwo, result, setResult}: Props) {
  const classes = useStyles();

  const rows = getBasicDetailsRows({patientOne, patientTwo});

  useEffect(() => {
    if (patientOne.isLoaded() && patientTwo.isLoaded()) {
      populateResultWithLoadedData({result, rows, setResult});
    }
  }, [patientOne, patientTwo]);

  return (
    <>
      {rows.map(r => {
        const mergeState = getMergeStateForRow({
          patientOnePreexistingValue: r.patientOne,
          patientTwoPreexistingValue: r.patientTwo,
          result: result[r.name].text,
          radioSelection: result[r.name].radioSelection,
          isLoading: !patientOne.isLoaded() || !patientTwo.isLoaded(),
        });

        return (
          <TableRow key={r.name}>
            <TableCell className={classes.tableCell}>
              <Bold>{r.label}</Bold>
            </TableCell>
            <ExistingDataCell
              radioLabel={'patientOne'}
              result={result}
              setResult={setResult}
              row={r}
            />
            <ExistingDataCell
              radioLabel={'patientTwo'}
              result={result}
              setResult={setResult}
              row={r}
            />
            <OverrideCell result={result} setResult={setResult} row={r} />
            <ResultCell result={result} row={r} />
            <ResultIconCell mergeState={mergeState} />
          </TableRow>
        );
      })}
    </>
  );
}
