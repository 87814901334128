import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {CallPool} from '@src/models';

export function getRows(callPools: AsyncData<CallPool>) {
  return callPools
    .getAllOptional()
    .orElse([])
    .map(c => {
      return {
        '': '',
        id: c.id,
        nameForProviders: c.nameForProviders,
        nameForGuardians: c.nameForGuardians,
        description: c.description,
        approved: c.approved,
        getInsurance: (c.rules.payments?.insurance ?? 'None') !== 'None',
        getCreditCard: (c.rules.payments?.creditCard ?? 'None') !== 'None',
        document: c.documentInAhana,
        endpoints: c.endpoints ?? [],
      };
    });
}
