import {Optional} from '@ahanapediatrics/ahana-fp';
import {compareDesc} from 'date-fns';
import {LonelyPatient, User, TrackedModel} from '../../../models';

export interface Updatable {
  updatedAt: Optional<Date>;
  lastUpdatedBy: Optional<User>;
  qualification: Optional<string>;
}

export const getEmptyUpdater = (): Updatable => {
  return {
    updatedAt: Optional.empty(),
    lastUpdatedBy: Optional.empty(),
    qualification: Optional.empty(),
  };
};

const concat = <T>(a: Optional<T[]>, b: Optional<T[]>): Optional<T[]> => {
  if (!a.isPresent()) {
    return b;
  }
  if (!b.isPresent()) {
    return a;
  }

  return Optional.of([...a.get(), ...b.get()]);
};

const defaultIfEmpty = <T>(acc: T[], list: T, idx: number, arr: T[]) => {
  if (arr.length > 0) {
    return arr;
  }
  return acc;
};

export const identifyLastUpdater = (
  patient: Optional<LonelyPatient>,
  ...updatables: Optional<Updatable[]>[]
): Updatable => {
  return updatables
    .reduce(concat, Optional.of([]))
    .orElse([])
    .map(c => ({
      updatedAt: c.updatedAt,
      lastUpdatedBy: c.lastUpdatedBy,
      qualification: c.qualification,
    }))
    .reduce(defaultIfEmpty, [
      patient
        .map(p => ({
          updatedAt: Optional.of(p.updatedAt),
          lastUpdatedBy: p.lastUpdatedBy,
          qualification: Optional.empty<string>(),
        }))
        .orElse({
          updatedAt: Optional.empty<Date>(),
          lastUpdatedBy: Optional.empty<User>(),
          qualification: Optional.empty<string>(),
        }),
    ])
    .sort((a, b) =>
      compareDesc(
        a.updatedAt.orElse(new Date(0)),
        b.updatedAt.orElse(new Date(0)),
      ),
    )[0];
};

export const fromTrackedModel = (tm: TrackedModel): Updatable => ({
  lastUpdatedBy: tm.lastUpdatedBy,
  updatedAt: Optional.of(tm.updatedAt),
  qualification: Optional.empty(),
});
