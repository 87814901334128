import React from 'react';
import _ from 'lodash';
import {List, ListSubheader} from '@material-ui/core';
import {useStyles} from '../../../../shared/patient/files/FilesOverview/util/styles';
import {getNoFilesText} from '../../../../shared/patient/files/FilesOverview/util/text';
import {VisitFile, SimpleVisit} from '@src/models';
import {
  getDate,
  getStartTime,
  getVisitProviderDescription,
} from '@src/util/visits/getVisitDescriptions';
import {Folder} from '@src/components/ui/organisms/uploaders/files/Folder';
import {NoFiles} from '@src/components/ui/organisms/uploaders/files/Folder/NoFiles';

const byVisitStart = (v1: SimpleVisit, v2: SimpleVisit) =>
  v2.start.getTime() - v1.start.getTime();

export function VisitsFolders({files}: {files: readonly VisitFile[]}) {
  const classes = useStyles();

  const groupedByVisit = Object.values(_.groupBy(files, f => f.visit.id));

  const groupedAndChronological = groupedByVisit.sort((group1, group2) =>
    byVisitStart(group1[0].visit, group2[0].visit),
  );

  const visitFiles = groupedAndChronological.map(group => {
    const folderVisit = group[0].visit;

    const labels = {
      mainText: {
        primary: <>{getDate(folderVisit.start)}</>,
        secondary: (
          <>
            at {getStartTime(folderVisit.start)} with{' '}
            {getVisitProviderDescription(folderVisit)}
          </>
        ),
      },
    };

    return <Folder files={group} labels={labels} key={folderVisit.id} />;
  });

  return (
    <List
      component="nav"
      subheader={
        <ListSubheader component="div">Files From All Visits</ListSubheader>
      }
      className={classes.root}
    >
      {files.length > 0 && visitFiles}
      {files.length === 0 && (
        <NoFiles
          noFilesText={getNoFilesText('visits')}
          omitUploadInstructions={true}
        />
      )}
    </List>
  );
}
