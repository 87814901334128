import {Divider, Grid} from '@material-ui/core';
import React, {useRef, useState} from 'react';
import {Button} from '../../../../ui/form';
import {Modal} from '../../../../ui/layout/Modal';
import {ParagraphText} from '../../../../ui/layout/text/body/ParagraphText';
import {UploadingFileManager} from './UploadingFileManager';
import {UploadsList} from '@src/components/ui/organisms/uploaders/files/UploadsList';
import {APIEvents, sendEvent} from '@src/events';
import {VisitFile, SimpleVisit} from '@src/models';
import {useApi} from '@src/api/useApi';
import {
  InProgressFile,
  SigningResult,
} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';
import {S3UploaderContainer} from '@src/components/ui/organisms/uploaders/S3UploaderContainer';
import {lg} from '@src/components/ui/theme';
import {useGetFileSignature} from '@src/hooks/s3Uploading/useGetFileSignature';

type Props = {
  visit: SimpleVisit;
  show: boolean;
  onClose: () => void;
};

export function UploadFilesModal({visit, show, onClose}: Props) {
  const api = useApi();
  const [uploadingFiles, setUploadingFiles] = useState<InProgressFile[]>([]);

  const fileManager = useRef(new UploadingFileManager(setUploadingFiles, []));
  const getFileSignature = useGetFileSignature();

  return (
    <>
      <Modal
        show={show}
        title="Upload Files"
        onClose={onClose}
        modalActions={<Button onClick={onClose}>Close</Button>}
      >
        <ParagraphText>
          You can upload images, videos, or documents from your device here.
        </ParagraphText>
        <ParagraphText>
          Click on the button below to select a file. It will be uploaded
          immediately and kept securely.
        </ParagraphText>
        <Grid style={{marginBottom: `${lg}`}}>
          <S3UploaderContainer
            getSignature={getFileSignature}
            onAttachFileToResource={(
              result: SigningResult<VisitFile>,
              file: File,
            ) =>
              api
                .visit(visit.id)
                .addFile({
                  ...result,
                  contentType: file.type,
                })
                .then(() => {
                  // This should happen due to an incoming message from the event server, but sometimes does not
                  sendEvent(APIEvents.VisitUpdate, {visitId: visit.id});
                })
            }
            onUploadFail={(error, file) =>
              fileManager.current.errorFile(
                'There was a problem uploading this file. Please contact support for assistance',
              )(file)
            }
            onAddNewFile={fileManager.current.addNewUploadingFile}
            onUpdateFile={fileManager.current.updateFile}
            onCompleteFile={fileManager.current.completeFile}
          />
        </Grid>

        <Divider style={{marginTop: '2rem'}} />

        <Grid item style={{marginTop: '1rem'}}>
          <UploadsList
            uploadedFiles={visit.files}
            uploadingFiles={uploadingFiles}
          />
        </Grid>
      </Modal>
    </>
  );
}
