import {Optional} from '@ahanapediatrics/ahana-fp';
import {AppAPI} from '@src/api/AppAPI';
import {SharedCarePlan, ProviderDetails, LonelyPatient} from '@src/models';
import {convertDetailsToRequest} from '@src/api/ProviderDetailsAPI';
import {UserId} from '@src/models/User';

interface CreateWithOwnerParams {
  errors: {[field: string]: string};
  ownershipExpiration?: Date;
  patientId: number;
  providerId: UserId;
  setCreating: (b: boolean) => void;
  setErrors: (e: {[field: string]: string}) => unknown;

  onCreated?: () => void;
  nextDetails?: ProviderDetails;
}

interface CreateWithoutOwnerParams {
  errors: {[field: string]: string};
  patientId: number;
  details: ProviderDetails | null;
  setErrors: (e: {[field: string]: string}) => unknown;
  setCreating: (b: boolean) => void;
  onCreated?: () => void;
}

interface SetApprovalExpiration {
  approvalExpiration: Date;
  patientId: number;
  setCreating: (b: boolean) => void;
  setScp: (scp: SharedCarePlan) => void;
  setPatient: (p: LonelyPatient) => unknown;
  onCreated?: () => void;
}

interface SetNewSCP {
  patientId: number;
  setCreating: (b: boolean) => void;
  setScp: (scp: SharedCarePlan) => void;
  setPatient: (p: LonelyPatient) => unknown;
}

export const createSCPWithOwner = (api: AppAPI) => ({
  errors,
  ownershipExpiration,
  patientId,
  providerId,
  setCreating,
  setErrors,
  onCreated = () => {},
  nextDetails,
}: CreateWithOwnerParams) => {
  const patientApi = api.patient(patientId);
  setCreating(true);

  patientApi
    .createSCPWithOwner(
      providerId,
      ownershipExpiration,
      convertDetailsToRequest(Optional.of(nextDetails)).orNothing(),
    )
    .then(onCreated)
    .catch(e => {
      console.log(e);
      setCreating(false);
      setErrors({...errors, createSCP: e.message});
    });
};

export const createSCPWithoutOwner = (api: AppAPI) => ({
  errors,
  patientId,
  details,
  setCreating,
  setErrors,

  onCreated = () => {},
}: CreateWithoutOwnerParams) => {
  const patientApi = api.patient(patientId);
  setCreating(true);

  patientApi
    .createSCPWithoutOwner(
      convertDetailsToRequest(Optional.of(details)).orNull(),
    )
    .then(onCreated)
    .catch(e => {
      console.log(e);
      setCreating(false);
      setErrors({...errors, createSCP: e.message});
    });
};

export const setApprovalExpiration = (api: AppAPI) => ({
  patientId,
  setCreating,
  setScp: setScp = () => {},
  setPatient = () => {},
  approvalExpiration,
}: SetApprovalExpiration) => {
  const patientApi = api.patient(patientId);
  setCreating(true);

  return patientApi
    .getSCP()
    .then(newScp => {
      return api.scp(newScp.id).updateApprovalExpiration(approvalExpiration);
    })
    .then(setScp)
    .then(() => {
      patientApi.get().then(setPatient);
    })
    .catch(e => {
      console.log(e);
    });
};

export const setNewSCP = (api: AppAPI) => ({
  patientId,
  setCreating,
  setScp: setScp = () => {},
  setPatient = () => {},
}: SetNewSCP) => {
  const patientApi = api.patient(patientId);
  setCreating(true);

  return patientApi
    .getSCP()
    .then(setScp)
    .then(() => {
      patientApi.get().then(setPatient);
    })
    .catch(e => {
      console.log(e);
    });
};
