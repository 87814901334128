import {GridColDef} from '@material-ui/data-grid';
import React, {useCallback, useState} from 'react';
import {Menu, MenuItem} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {capitalize} from 'lodash';
import {ExpandingBox} from '@src/components/shared/ExpandingBox';
import {ToolTip} from '@src/components/ui/atoms/buttonsAndIcons/ToolTip';
import {NAMES} from '@src/components/ui/layout/text/names';

const BooleanIcon = (params: {value?: unknown}) => {
  return (
    <>
      {params.value ? (
        <FontAwesomeIcon icon={['far', 'check-circle']} color="primary" />
      ) : null}
    </>
  );
};

const AdminGroupIcon = () => {
  return (
    <div style={{whiteSpace: 'break-spaces'}}>
      <ToolTip
        icon={<FontAwesomeIcon icon={['fas', 'users-cog']} color="primary" />}
        text={`This ${capitalize(
          NAMES.callPool,
        )} does not support Visits and will not be visible to ${capitalize(
          NAMES.guardian,
        )}s`}
      />
    </div>
  );
};

function ActionMenu({
  setShowAddProfessional,
  setShowEditEndpoints,
  setEditDocumentsForGroupId,
  setShowEditCustomerCodes,
  callPoolId,
}: {
  setShowEditEndpoints: (callPoolId: number) => unknown;
  setShowAddProfessional: (callPoolId: number) => unknown;
  setEditDocumentsForGroupId: (callPoolId: number) => unknown;
  setShowEditCustomerCodes: (callPoolId: number) => unknown;
  callPoolId: number;
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <FontAwesomeIcon onClick={handleClick} icon={['fas', 'chevron-down']} />

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setShowEditEndpoints(callPoolId);
            handleClose();
          }}
        >
          Edit Endpoints
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowAddProfessional(callPoolId);
            handleClose();
          }}
        >
          Add Professional
        </MenuItem>
        <MenuItem
          onClick={() => {
            setEditDocumentsForGroupId(callPoolId);
            handleClose();
          }}
        >
          Edit Forms
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowEditCustomerCodes(callPoolId);
            handleClose();
          }}
        >
          Edit Customer Codes
        </MenuItem>
      </Menu>
    </>
  );
}

type GetColumnsProps = {
  setShowEditEndpoints: (callPoolId: number | null) => unknown;
  setShowAddProfessional: (callPoolId: number | null) => unknown;
  setEditDocumentsForGroupId: (callPoolId: number | null) => unknown;
  setShowEditCustomerCodes: (callPoolId: number) => unknown;
};

export function getColumns({
  setShowEditEndpoints,
  setShowAddProfessional,
  setEditDocumentsForGroupId,
  setShowEditCustomerCodes,
}: GetColumnsProps): GridColDef[] {
  return [
    {
      field: '',
      headerName: '',
      width: 50,
      renderCell: params => (
        <ActionMenu
          setShowAddProfessional={setShowAddProfessional}
          callPoolId={parseInt(`${params.id}`)}
          setShowEditEndpoints={setShowEditEndpoints}
          setEditDocumentsForGroupId={setEditDocumentsForGroupId}
          setShowEditCustomerCodes={setShowEditCustomerCodes}
        />
      ),
      sortable: false,
      resizable: false,
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {field: 'id', width: 70},
    {
      field: 'nameForProviders',
      headerName: 'Name For Providers',
      width: 250,
      renderCell: ExpandingBox,
    },
    {
      field: 'nameForGuardians',
      headerName: 'Name For Guardians',
      width: 250,
      renderCell: params =>
        params.value?.valueOf() ? ExpandingBox(params) : <AdminGroupIcon />,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
      renderCell: ExpandingBox,
    },
    {
      field: 'approved',
      headerName: 'Approved',
      width: 130,
      renderCell: params => <BooleanIcon value={params.value} />,
    },
    {
      field: 'getInsurance',
      headerName: 'Insurance',
      width: 130,
      renderCell: params => <BooleanIcon value={params.value} />,
    },
    {
      field: 'getCreditCard',
      headerName: 'Credit Card',
      width: 150,
      renderCell: params => <BooleanIcon value={params.value} />,
    },
    {
      field: 'document',
      headerName: 'Document in Refyne Connected Care',
      width: 150,
      renderCell: params => <BooleanIcon value={params.value} />,
    },
  ];
}
