import React, {useEffect} from 'react';
import {useParams, useHistory} from 'react-router';
import {Grid, Link} from '@material-ui/core';
import {Rules} from './Rules';
import {ProviderGroupCard} from './ProviderGroupCard';
import {useStyles} from './layout';
import {ManagersSection} from './ManagersSection';
import {MembersSection} from './MembersSection';
import {RelatedProviderGroups} from './RelatedProviderGroups';
import {Availability} from './Availability';
import {useApi} from '@src/api/useApi';
import {useResources, useUser, useAsync} from '@src/hooks';
import {CallPool} from '@src/models';
import {isNothing} from '@src/util/typeTests';
import {allLoaded} from '@src/util/apiHelpers';
import {Banner} from '@src/components/ui/layout/Banner';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {requestHelp} from '@src/util/applicationEvents/requestHelp';
import {Button} from '@src/components/ui/form';
import {PageSection} from '@src/components/ui/atoms/section/PageSection';
import {PageLoadingIndeterminate} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoadingIndeterminate';
import {NAMES} from '@src/components/ui/layout/text/names';

// eslint-disable-next-line import/no-unused-modules
export default function ProviderGroupOverviewPage() {
  const api = useApi();
  const params = useParams<{id: string}>();
  const classes = useStyles();
  const history = useHistory();
  const [user] = useUser();
  const [isPermitted, setPermitted] = useAsync<boolean>();

  const callPoolId = +params.id;
  const [providerGroup, , setGroup, resetGroup] = useResources(
    () => api.callPool(callPoolId).get(),
    [api, callPoolId],
  );

  const [members, reloadMembers] = useResources(
    () => api.callPool(callPoolId).getMembers(),
    [api, callPoolId],
  );

  const [managers, reloadManagers] = useResources(
    () =>
      api
        .callPool(callPoolId)
        .getManagers()
        .then(mgrs => mgrs.map(m => m.providerDetails)),
    [api, callPoolId],
  );

  useEffect(() => {
    if (!allLoaded(members, user, managers)) {
      return;
    }
    const providerDetailsId = user.singleValue().providerDetails.property('id');
    setPermitted(
      [...members.value(), ...managers.value()].some(
        m => providerDetailsId === m.id,
      ),
    );
  }, [managers, members, setPermitted, user]);

  const parent: CallPool | null =
    providerGroup
      .getOptional()
      .map(g => g.parentPool)
      .orNull() ?? providerGroup.getOptional().orNull();

  const children = parent?.children?.orNull() ?? [];

  const actualProviderGroup = providerGroup.getOptional().orNull();

  return (
    <PageLoadingIndeterminate
      active={!allLoaded(isPermitted, providerGroup, managers)}
      message="Loading"
    >
      <Grid container direction="column" className={classes.overviewContainer}>
        <Grid
          item
          container
          className={classes.backContainer}
          justify="flex-end"
          direction="row"
        >
          <Grid item>
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        {isPermitted.orElse(false) ? (
          <>
            <ProviderGroupCard
              providerGroup={providerGroup.getOptional().orNothing()}
              onUpdateGroup={setGroup}
            />
            <PageSection title="Members">
              <MembersSection
                callPoolId={callPoolId}
                members={members}
                reloadMembers={reloadMembers}
              />
            </PageSection>
            <PageSection title="Rules">
              <Rules
                providerGroup={providerGroup.getOptional().orNothing()}
                resetGroup={resetGroup}
              />
            </PageSection>

            <PageSection title="Availability">
              {actualProviderGroup?.rules.visitsUnsupported ? (
                <ParagraphText>
                  This {NAMES.group} is not set up to accept {NAMES.visit}s. If
                  you would like to change that, please see the "rules" section
                  on this page and toggle {`"Support ${NAMES.visit}s" to "Yes"`}
                  .
                </ParagraphText>
              ) : (
                <Availability
                  providerGroup={providerGroup.getOptional().orNothing()}
                />
              )}
            </PageSection>

            <PageSection
              title="Managers"
              description="Depending on permissions settings, Managers can make changes to the Group name, modify members, and change the Group schedule."
            >
              <ManagersSection
                callPoolId={callPoolId}
                managers={managers}
                reloadManagers={reloadManagers}
              />
            </PageSection>

            {!isNothing(parent) && children.length !== 0 && (
              <PageSection title="Related Provider Groups">
                <RelatedProviderGroups
                  providerGroup={providerGroup.getOptional().orNothing()}
                  children={children}
                  parent={parent}
                />
              </PageSection>
            )}
          </>
        ) : (
          <Grid item className={classes.alertGrid}>
            <Banner
              type="error"
              title="You are not a member of this Provider Group"
            >
              <ParagraphText>
                The page you have arrived at is for a Provider Group that you
                are not a member of.
              </ParagraphText>
              <ParagraphText>
                If you{' '}
                <Link onClick={() => history.push('/shifts-and-groups')}>
                  click here{' '}
                </Link>
                , you'll be taken to your list of groups.
              </ParagraphText>
              <ParagraphText>
                If you think you're seeing this in error,{' '}
                <Link onClick={requestHelp}>click here</Link> to get help.
              </ParagraphText>
            </Banner>
          </Grid>
        )}
      </Grid>
    </PageLoadingIndeterminate>
  );
}
