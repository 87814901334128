import {useCallback, useEffect, useMemo, useState} from 'react';
import {getDaysInMonth} from 'date-fns';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {
  PeriodsMappedToDaysOfSelectedMonth,
  usePeriodsMappedToDaysOfSelectedMonth,
} from './usePeriodsMappedToDaysOfSelectedMonth';
import {
  useArrayOfWeeksForMonthBuilder,
  Week,
} from '@src/hooks/calendars/useArrayOfWeeksForMonthBuilder';

export type MonthTableResources = {
  arrayOfWeeks: Week[];
  reloadPeriodsMappedToDaysOfSelectedMonth: () => Promise<unknown>;
  showSinglePeriodModal: boolean;
  setShowSinglePeriodModal: (v: boolean) => unknown;
  isDayInMonth: (v: number) => unknown;
  periodsMappedToDaysOfSelectedMonth: AsyncData<
    PeriodsMappedToDaysOfSelectedMonth
  >;
};

export const useMonthTableResources = ({
  callPoolId,
  rosterMonth,
}: {
  callPoolId: number;
  rosterMonth: Date;
}): MonthTableResources => {
  const [
    periodsMappedToDaysOfSelectedMonth,
    reloadPeriodsMappedToDaysOfSelectedMonth,
    resetPeriodsMappedToDaysOfSelectedMonth,
  ] = usePeriodsMappedToDaysOfSelectedMonth(callPoolId, rosterMonth);
  const arrayOfWeeks = useArrayOfWeeksForMonthBuilder(rosterMonth);
  const isDayInMonth = useCallback(
    (day: number) => day >= 1 && day <= getDaysInMonth(rosterMonth),
    [rosterMonth],
  );

  const [showSinglePeriodModal, setShowSinglePeriodModal] = useState(false);

  useEffect(() => {
    resetPeriodsMappedToDaysOfSelectedMonth();
    reloadPeriodsMappedToDaysOfSelectedMonth();
  }, [
    reloadPeriodsMappedToDaysOfSelectedMonth,
    resetPeriodsMappedToDaysOfSelectedMonth,
    rosterMonth,
  ]);

  return useMemo(() => {
    return {
      arrayOfWeeks,
      showSinglePeriodModal,
      setShowSinglePeriodModal,
      periodsMappedToDaysOfSelectedMonth,
      reloadPeriodsMappedToDaysOfSelectedMonth,
      isDayInMonth,
    };
  }, [
    arrayOfWeeks,
    isDayInMonth,
    periodsMappedToDaysOfSelectedMonth,
    reloadPeriodsMappedToDaysOfSelectedMonth,
    showSinglePeriodModal,
  ]);
};
