import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {backdrop, grey, md, sm, xs} from '../../ui/theme';

export const GuardianInfo = styled.div`
  border-radius: ${xs};
  border: solid thin ${grey};
  background-color: ${backdrop};
  margin: ${md} 0;
  padding: ${sm};
`;

export const GuardianContactIcon = styled(FontAwesomeIcon)``;

export const GuardianContactRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: ${sm};

  ${GuardianContactIcon} {
    margin-right: ${sm};
  }
`;
