import React, {
  createContext,
  useCallback,
  useRef,
  useState,
  ReactComponentElement,
  ReactNode,
} from 'react';
import {Button, Menu} from '@material-ui/core';
import ContextMenuItem from './ContextMenuItem';

interface Props {
  label: ReactNode | ReactNode[];
  children:
    | ReactComponentElement<typeof ContextMenuItem>
    | ReactComponentElement<typeof ContextMenuItem>[];
  hAlign: 'left' | 'right';
  vAlign: 'top' | 'bottom';
}

export const ContextMenuContext = createContext<{
  isOpen: boolean;
  setOpened: () => unknown;
  setClosed: () => unknown;
}>({
  isOpen: false,
  setOpened: () => {},
  setClosed: () => {},
});

export function ContextMenu({label, children, hAlign, vAlign}: Props) {
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const setOpened = useCallback(() => {
    setIsOpen(true);
  }, []);

  const setClosed = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <ContextMenuContext.Provider value={{isOpen, setOpened, setClosed}}>
      <Button ref={anchorEl} onClick={setOpened}>
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl.current}
        getContentAnchorEl={null}
        open={isOpen}
        onClose={setClosed}
        anchorOrigin={{
          vertical: vAlign,
          horizontal: hAlign,
        }}
        transformOrigin={{
          vertical: vAlign === 'top' ? 'bottom' : 'top',
          horizontal: hAlign,
        }}
      >
        {children}
      </Menu>
    </ContextMenuContext.Provider>
  );
}

export {ContextMenuItem};
