import React, {useState, useCallback} from 'react';
import {AsyncActionButton} from '../../../ui/form';
import {
  CardAge,
  CardContainer,
  CardHeader,
  CardTitle,
} from '../ExpandedCard/Card';
import {PatientContent, VisitButtons} from '../ExpandedCard/layout';
import {RelationshipMessage} from './layout';
import asYearsOrMonths from '@src/util/dateManipulation/asYearsOrMonths';
import {Invitation} from '@src/models';
import {useApi} from '@src/api/useApi';
import {flashError} from '@src/components/shared/notifications/flash';

type Props = {
  invitation: Invitation;
  onApprove: (patientId: number) => unknown;
};

export function UnapprovedPatientDetails({invitation, onApprove}: Props) {
  const api = useApi();
  const [approving, setApproving] = useState(false);
  const {patient} = invitation;

  const approveGuardianship = useCallback(() => {
    api
      .patient(invitation.patientId)
      .acceptInvitation(invitation.email)
      .then(p => {
        setApproving(false);
        onApprove(p.id);
      })
      .catch(e => {
        flashError(
          'There was a problem accepting this invite. Please contact support.',
        );
      });
  }, [api, invitation, onApprove]);

  if (!patient.isPresent()) {
    return null;
  }

  return (
    <CardContainer>
      <CardHeader>
        <CardTitle>
          {patient.property('preferredFirstName', '')}{' '}
          {patient.property('lastName', '')}
          <CardAge>
            (
            {asYearsOrMonths(patient.property('dob', new Date()), {
              shortened: true,
            })}
            )
          </CardAge>
        </CardTitle>
      </CardHeader>
      <PatientContent>
        {invitation.isSelf ? (
          <RelationshipMessage>
            You were registered as a patient. Please click the button below to
            accept this invitation.
          </RelationshipMessage>
        ) : (
          <RelationshipMessage>
            You were connected to {patient.property('firstName', '')}'s Patient
            Record. Please click the button below to accept this invitation.
          </RelationshipMessage>
        )}

        <VisitButtons>
          <AsyncActionButton
            actionInProgress={approving}
            actionWord={
              invitation.isSelf ? 'Accept Invitation' : 'Confirm relationship'
            }
            onClick={() => {
              setApproving(true);
              approveGuardianship();
            }}
          />
        </VisitButtons>
      </PatientContent>
    </CardContainer>
  );
}
