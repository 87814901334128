import React, {ReactNode, useState} from 'react';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import {Tooltip} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.main,
      cursor: 'default',
      fontSize: 'inherit',
    },
  }),
);

export function ToolTip({text, icon}: {text: string; icon: ReactNode}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const classes = useStyles();

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowTooltip(false);
      }}
    >
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        title={text}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <div
          style={{cursor: 'pointer'}}
          onClick={e => {
            setShowTooltip(!showTooltip);
          }}
          className={classes.icon}
        >
          {icon}
        </div>
      </Tooltip>
    </ClickAwayListener>
  );
}
