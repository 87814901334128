import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useEffect, useState} from 'react';
import CreditCardSummary from '../../../CreditCardSummary';
import {Acknowledge} from '@src/components/ui/form/Acknowledge';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {useUser} from '@src/hooks';
import {Patient} from '@src/models';
import {PaymentControl, PaymentProcessorInfo} from '@src/models/CallPool';
import {userIsPatient} from '@src/util/users/userIsPatient';
import {reference} from '@src/util/stringManipulation/languageHelpers';
import {isNothing} from '@src/util/typeTests';

type Props = {
  onAddOrEdit: () => unknown;
  creditCardControl: PaymentControl;
  patient: Patient;
  setNextAvailable: (a: boolean) => unknown;
  paymentProcessorInfo: PaymentProcessorInfo;
  callPoolId: number;
};

export const CreditCardView = ({
  onAddOrEdit,
  creditCardControl,
  patient,
  setNextAvailable,
  paymentProcessorInfo,
  callPoolId,
}: Props) => {
  const {creditCards} = patient;
  const creditCardForVisitCallPool = creditCards.find(
    card => card.callPoolId === callPoolId,
  );
  const [user] = useUser();
  const [ready, setReady] = useState(false);

  const hasCreditCard = !isNothing(creditCardForVisitCallPool);

  const isSelf = userIsPatient(Optional.of(patient), user.getOptional());
  const r = reference(patient.firstName, isSelf);

  const isReady = hasCreditCard || creditCardControl === 'Optional';

  useEffect(() => {
    setNextAvailable(ready);
  }, [ready, setNextAvailable]);

  return (
    <>
      <ParagraphText>
        To support this visit, we need to gather {r('poss')} credit card
        information.
      </ParagraphText>
      {creditCardControl === 'Optional' && (
        <ParagraphText>
          If you don't have {r('poss')} credit card information available right
          now, it's OK to skip this step. Someone will follow up with you after
          your visit.
        </ParagraphText>
      )}
      <CreditCardSummary
        editable
        patient={Optional.of(patient)}
        creditCardForVisitCallPool={Optional.of(creditCardForVisitCallPool)}
        onAddOrEdit={onAddOrEdit}
        paymentProcessorInfo={paymentProcessorInfo}
        callPoolId={callPoolId}
      />
      <Acknowledge
        value={Optional.of(ready)}
        setValue={() => {
          setReady(true);
        }}
        message="Confirm that the information above is correct"
        disabled={!isReady}
        style={{marginTop: '1rem'}}
      />
    </>
  );
};
