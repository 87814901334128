import clsx from 'clsx';
import React from 'react';
import {Grid} from '@material-ui/core';
import {useStyles} from './layout';
import {Button} from '@src/components/ui/form';
import {Modal} from '@src/components/ui/layout/Modal';

type Props = {
  showModal: boolean;
  onPlanToReturn: () => void;
  onReturnToRoom: () => void;
  onLeavePermanently: () => void;
};

export function GuardianLeaveRoom({
  showModal,
  onPlanToReturn,
  onReturnToRoom,
  onLeavePermanently,
}: Props) {
  const classes = useStyles();

  return (
    <Modal
      show={showModal}
      title="You're about to leave this visit"
      onClose={onReturnToRoom}
      modalActions={
        <Button onClick={onReturnToRoom} bStyle="primary">
          Return To Visit
        </Button>
      }
    >
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          className={clsx(classes.modalRow, classes.modalInstructions)}
        >
          If you are planning to return to this visit, please click the button
          below.
        </Grid>
        <Grid item className={classes.modalRow}>
          <Button onClick={onPlanToReturn} bStyle="primary">
            I'll Be Back Soon
          </Button>
        </Grid>

        <Grid
          item
          className={clsx(classes.modalRow, classes.modalInstructions)}
        >
          If you don't plan on returning, please click the button below.
        </Grid>
        <Grid item className={classes.modalRow}>
          <Button onClick={onLeavePermanently} bStyle="primary">
            Leave Visit
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}
