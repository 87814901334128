import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {BasicDetailsResult} from '../../BasicDetailsComparer/Rows/utils/resultFunctions';
import {MedicalHistoryResult} from '../../MedicalHistoryComparer/Rows/utils/resultFunctions';
import {isPcpSectionMergeable} from './primaryCareProvider';
import {isCareTeamsSectionMergeable} from './careTeams';

import {
  isDetailsSectionMergeable,
  isVisitsSectionMergeable,
  isPaymentInfoSectionMergeable,
  isScpSectionMergeable,
} from '.';
import {Patient, SimpleVisit} from '@src/models';
import {isNothing} from '@src/util/typeTests';

export type Mergeable = 'CHECKING' | 'PASS' | 'CONFLICT' | 'RESOLVED' | 'FAIL';
type SectionName =
  | 'basicDetails'
  | 'primaryCare'
  | 'careTeams'
  | 'visits'
  | 'paymentInfo'
  | 'creditCard'
  | 'scp'
  | 'medicalHistory';

export const isSectionMergeable = ({
  p1,
  p2,
  sectionName,
  basicDetailsResult,
  medicalHistoryResult,
  visits,
}: {
  p1: AsyncData<Patient>;
  p2: AsyncData<Patient>;
  sectionName: SectionName;
  basicDetailsResult?: BasicDetailsResult;
  medicalHistoryResult?: MedicalHistoryResult;
  visits?: {p1Visits: readonly SimpleVisit[]; p2Visits: readonly SimpleVisit[]};
}): Mergeable => {
  const {p1Visits, p2Visits} = visits ?? {};

  if (!p1.isLoaded() || !p2.isLoaded()) {
    return 'CHECKING';
  }

  if (sectionName === 'basicDetails' && !isNothing(basicDetailsResult)) {
    return isDetailsSectionMergeable(basicDetailsResult);
  } else if (
    sectionName === 'medicalHistory' &&
    !isNothing(medicalHistoryResult)
  ) {
    return isDetailsSectionMergeable(medicalHistoryResult);
  } else if (sectionName === 'primaryCare') {
    return isPcpSectionMergeable({
      p1: p1.getOptional(),
      p2: p2.getOptional(),
    });
  } else if (sectionName === 'careTeams') {
    return isCareTeamsSectionMergeable({
      p1: p1.getOptional(),
      p2: p2.getOptional(),
    });
  } else if (sectionName === 'paymentInfo') {
    return isPaymentInfoSectionMergeable({
      p1: p1.getOptional(),
      p2: p2.getOptional(),
    });
  } else if (
    sectionName === 'visits' &&
    !isNothing(p1Visits) &&
    !isNothing(p2Visits)
  ) {
    return isVisitsSectionMergeable({p1Visits, p2Visits});
  } else if (sectionName === 'scp') {
    return isScpSectionMergeable({
      p1: p1.getOptional(),
      p2: p2.getOptional(),
    });
  } else {
    return 'FAIL';
  }
};
