import {useMemo} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {CallPool, Endpoint} from '@src/models';

/**
 * Given a CallPool and a list of Endpoints, return the subset of
 * Endpoints from the list that are not added to the CallPool.
 */
export function useUnaddedEndpoints({
  endpoints,
  callPool,
}: {
  endpoints: AsyncData<Endpoint>;
  callPool: CallPool;
}) {
  const callPoolEndpoints = callPool.endpoints;
  const aEndpoints = endpoints.getAllOptional().orElse([]);

  return useMemo(() => {
    const callPoolEndpointsMap = callPoolEndpoints.reduce((acc, e) => {
      return {...acc, [e.id]: true};
    }, {} as Record<number, boolean>);

    return aEndpoints.filter(e => !callPoolEndpointsMap[e.id]);
  }, [callPoolEndpoints, aEndpoints]);
}
