import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useMemo, useState} from 'react';
import {useMembers} from '../useMembers';
import {useGroup} from '../useGroup';
import {useManagedGroups} from '../useManagedGroups';
import {
  MonthTableResources,
  useMonthTableResources,
} from './useMonthTableResources';
import {
  DayComponentResources,
  useDayComponentResources,
} from './useDayComponentResources';
import {
  AddPeriodModalsResources,
  useAddPeriodModalsResources,
} from './useAddPeriodModalsResources';
import {usePageAuthorizationState} from './usePageAuthorizationState';
import {CallPool, ProviderDetails} from '@src/models';
import {allLoaded} from '@src/util/apiHelpers';
import {useUser} from '@src/hooks';
import {UserId} from '@src/models/User';

export type RosterResources = {
  rosterPageGroup: AsyncData<CallPool>;
  setGroup: (v?: CallPool | readonly CallPool[] | Error) => void;
  rosterPageGroupMembers: AsyncData<ProviderDetails>;
  isPageLoading: boolean;
  rosterMonth: Date;
  setRosterMonth: (month: Date) => unknown;
  dayComponent: DayComponentResources;
  addPeriodModal: AddPeriodModalsResources;
  monthTable: MonthTableResources;
  managedGroups: AsyncData<CallPool>;
};

export const useRosterResources = ({
  callPoolId,
}: {
  callPoolId: number;
}): RosterResources => {
  const [user] = useUser();

  const [rosterMonth, setRosterMonth] = useState(new Date());
  const [rosterPageGroup, , setGroup] = useGroup(callPoolId);
  const [rosterPageGroupMembers] = useMembers(callPoolId);
  const [userAuthorizedToAccessRoster] = usePageAuthorizationState(
    rosterPageGroupMembers,
  );

  const [managedGroups] = useManagedGroups({
    professionalId: user
      .getOptional()
      .map(u => u.id)
      .orElse(0 as UserId),
    includeVisitsUnsupported: false,
  });

  const isPageLoading = !allLoaded(
    userAuthorizedToAccessRoster,
    rosterPageGroup,
  );

  const dayComponent = useDayComponentResources();
  const monthTable = useMonthTableResources({callPoolId, rosterMonth});
  const addPeriodModal = useAddPeriodModalsResources();

  return useMemo(() => {
    return {
      rosterPageGroup,
      setGroup,
      rosterPageGroupMembers,
      isPageLoading,
      setRosterMonth,
      rosterMonth,
      dayComponent,
      monthTable,
      addPeriodModal,
      managedGroups,
    };
  }, [
    rosterPageGroup,
    setGroup,
    rosterPageGroupMembers,
    isPageLoading,
    setRosterMonth,
    rosterMonth,
    dayComponent,
    monthTable,
    addPeriodModal,
    managedGroups,
  ]);
};
