import React from 'react';
import {Grid, Paper} from '@material-ui/core';
import {WEEK_ARRAY} from '@src/components/providerSide/OnCallPeriodModals/RepeatingShiftModal/functions';

export function DaysOfWeekHeader({className}: {className: string}) {
  return (
    <Grid item xs={12}>
      <Grid container direction="row" spacing={1}>
        {WEEK_ARRAY.map(day => (
          <Grid item xs key={day}>
            <Paper className={className}>{day}</Paper>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
