import React from 'react';
import {Room} from 'twilio-video';
import ParticipantVideo from './ParticipantVideo';
import {
  findScreenSharingParticipant,
  SetParticipants,
} from '@src/util/videoChat';

type GetScreensharingElement = {
  isAnyoneScreensharing: boolean;
  room: Room;
  showDocumentation: boolean;
  cutRoomAudio: boolean;
  onVideoTracksStateChange: () => void;
  isLocalScreensharing: boolean;
  setParticipants: SetParticipants;
  setWaitingParticipants: SetParticipants;
  hide: boolean;
  patientId: number;
  visitId: number;
  isLocalAudioMuted: boolean;
  setIsLocalAudioMuted: (muted: boolean) => void;
  isLocalVideoDisabled: boolean;
  setIsLocalVideoDisabled: (disabled: boolean) => void;
};

export function getScreensharingElement({
  room,
  isAnyoneScreensharing,
  cutRoomAudio,
  showDocumentation,
  onVideoTracksStateChange,
  isLocalScreensharing,
  setParticipants,
  setWaitingParticipants,
  hide,
  patientId,
  visitId,
  isLocalAudioMuted,
  setIsLocalAudioMuted,
  isLocalVideoDisabled,
  setIsLocalVideoDisabled,
}: GetScreensharingElement) {
  const [participant, , participantType] = findScreenSharingParticipant({
    room,
  });

  if (participant) {
    return (
      <ParticipantVideo
        visitId={visitId}
        participant={participant}
        showDocumentation={showDocumentation}
        isLocal={participantType === 'local'}
        cutRoomAudio={cutRoomAudio}
        room={room}
        isAnyoneScreensharing={isAnyoneScreensharing}
        isMainScreenElement={true}
        onVideoTracksStateChange={onVideoTracksStateChange}
        isLocalScreensharing={isLocalScreensharing}
        setParticipants={setParticipants}
        setWaitingParticipants={setWaitingParticipants}
        hide={hide}
        patientId={patientId}
        isLocalAudioMuted={isLocalAudioMuted}
        setIsLocalAudioMuted={setIsLocalAudioMuted}
        isLocalVideoDisabled={isLocalVideoDisabled}
        setIsLocalVideoDisabled={setIsLocalVideoDisabled}
      />
    );
  }

  return null;
}
