import {Formik, FormikActions} from 'formik';
import React, {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {LonelyVisit} from '../../models';
import {Button} from '../ui/form';
import {Modal} from '../ui/layout/Modal';
import AddendumForm, {AddendumFormValues, schema} from './AddendumForm';
import {useApi} from '@src/api/useApi';

type OwnProps = {
  visit: LonelyVisit;
  onUpdateNote?: (a: LonelyVisit) => void;
};

type Props = OwnProps;

const ModalButtons = styled.div`
  text-align: right;
`;

export const AddAddendum: FunctionComponent<Props> = ({
  visit,
  onUpdateNote,
}) => {
  const api = useApi();
  const [showModal, setShowModal] = useState(false);

  const submitAddendum = (
    values: AddendumFormValues,
    {setSubmitting}: FormikActions<AddendumFormValues>,
  ) => {
    setSubmitting(true);
    api
      .visit(visit.id)
      .addAddendum(values.content)
      .then(a => {
        if (onUpdateNote) {
          onUpdateNote(a);
        }
      })
      .finally(() => {
        setSubmitting(false);
        setShowModal(false);
      });
  };

  return (
    <>
      <Button
        bStyle="primary"
        bSize="small"
        onClick={() => {
          setShowModal(true);
        }}
      >
        Add Addendum
      </Button>
      <Modal
        show={showModal}
        title="Add Addendum"
        onClose={() => {
          setShowModal(false);
        }}
      >
        <Formik
          validationSchema={schema}
          initialValues={schema.default()}
          onSubmit={submitAddendum}
          render={props => (
            <div>
              <AddendumForm {...props} />
              <ModalButtons>
                <Button
                  bSize="small"
                  bStyle="primary"
                  onClick={props.submitForm}
                >
                  Save
                </Button>
              </ModalButtons>
            </div>
          )}
        />
      </Modal>
    </>
  );
};

export default AddAddendum;
