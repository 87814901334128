import React, {useState, useCallback, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Optional} from '@ahanapediatrics/ahana-fp';
import styled from 'styled-components';
import {captureException} from '@sentry/browser';
import {Grid, FormControlLabel, Switch} from '@material-ui/core';
import {InstructionsText} from './InstructionsText';
import {useStyles} from './styles';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {ResponsiblePersonCard} from '@src/components/shared/forms/FinanciallyResponsiblePersonFormModal/ResponsiblePersonCard';
import {Modal} from '@src/components/ui/layout/Modal';
import {SearchByGuardian} from '@src/components/ui/form/search/SearchByGuardian';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {TextInput, Button} from '@src/components/ui/form';
import {sm} from '@src/components/ui/theme';
import {useApi} from '@src/api/useApi';
import {useUser} from '@src/hooks/useUser';
import {
  LonelyPatient,
  UserType,
  ResponsiblePerson,
  Invitation,
  PatientRelationship,
} from '@src/models';
import {isEmpty, isNothing} from '@src/util/typeTests';

type Props = {
  onInvitationCreated: (patient: LonelyPatient) => void;
  onHide: () => void;
  patientId: number;
  currentInvitations: readonly Invitation[];
  currentRelationships: readonly PatientRelationship[];
  patientName: string;
  show: boolean;
  mode: 'modal' | 'form';
  submitButtonText?: {actionWord: string; text: string};
};

const GuardianSearchSpinner = styled.div<{searching: boolean}>`
  display: ${props => (props.searching ? 'block' : 'none')};
  position: absolute;
  right: ${sm};
  top: ${sm};
`;

/**
 * This is a Form/Modal that allows you to connect a Patient to a Person.
 * It handles searching for a current Person or creating a brand new one.
 */
export function InvitePerson({
  currentInvitations,
  currentRelationships,
  onInvitationCreated,
  onHide,
  patientId,
  patientName,
  show,
  mode,
  submitButtonText,
}: Props) {
  const api = useApi();
  const classes = useStyles();
  const [, userType] = useUser();

  const [person, setPerson] = useState<ResponsiblePerson | null>(null);
  const [isSelf, setIsSelf] = useState(false);
  const [email, setEmail] = useState('');
  const [relationshipName, setRelationshipName] = useState('');
  const [isInviting, setInviting] = useState(false);
  const [enterManually, setEnterManually] = useState(false);

  const hasPendingSelfInvitation = currentInvitations.some(i => i.isSelf);
  const hasCurrentSelfRelationship = currentRelationships.some(i => i.isSelf);

  const toggleSelf = useCallback(() => {
    setIsSelf(!isSelf);
    setRelationshipName(!isSelf ? 'Self' : '');
  }, [isSelf]);

  useEffect(() => {
    if (!person) {
      setEmail('');
    } else {
      const responsiblePersonDetails = person;
      if (isNothing(responsiblePersonDetails)) {
        const error = new Error(
          `responsiblePersonDetails not loaded for Connected Login with id ${person?.id ??
            0} in InvitePerson component`,
        );

        captureException(error);

        throw error;
      }
      setEmail(responsiblePersonDetails.email.orElse(''));
    }
  }, [person]);

  const onDone = useCallback(() => {
    setPerson(null);
    setIsSelf(false);
    setEmail('');
    setRelationshipName('');
    setInviting(false);
    setIsSelf(false);
    setEnterManually(false);
    onHide();
  }, [onHide]);

  const selectGuardian = useCallback(async () => {
    setInviting(true);
    api
      .patient(patientId)
      .invitePerson({email, isSelf, relationshipName})
      .then((p: LonelyPatient) => {
        flashSuccess(`Invitation email sent to ${email}`);
        onInvitationCreated(p);
        onDone();
      })
      .catch(() => {
        flashError(
          'There was a problem linking to this patient. Please contact support for assistance.',
        );
      })
      .finally(() => {
        setInviting(false);
      });
  }, [
    api,
    email,
    isSelf,
    onDone,
    onInvitationCreated,
    patientId,
    relationshipName,
  ]);

  if (!show) return null;

  const isConnectedLogin = userType === UserType.Guardian;
  /**
   * Be careful when changing this as it represents a data privacy risk.
   * Guardians cannot search for other Guardians.
   */
  const doSearch = !isConnectedLogin && !enterManually;

  const personRelationships = Optional.of(person)
    .map(p => p.relationships)
    .orElse([]);

  const hasSelfRelationship = personRelationships.some(r => r.isSelf);
  const preExistingRelationship = personRelationships.find(
    r => r.patientId === patientId,
  );

  const personEmail = Optional.of(person)
    .map(p => p.email)
    .orElse('');

  const hasPendingInvitationForPerson = currentInvitations.some(
    i => i.email === personEmail,
  );

  let relationshipText;
  let conflictingRelationship = false;

  if (hasPendingInvitationForPerson) {
    relationshipText = 'Invitation already pending';
    conflictingRelationship = true;
  } else if (preExistingRelationship) {
    relationshipText =
      preExistingRelationship.relationship ?? 'Already related';
    conflictingRelationship = true;
  } else if (isSelf) {
    relationshipText = hasSelfRelationship
      ? 'Already connected to a Patient'
      : 'Not yet linked';

    conflictingRelationship = hasSelfRelationship;
  } else {
    relationshipText = 'Not yet linked';
  }

  const form = (
    <>
      <InstructionsText isSelf={isSelf} />
      <Grid container direction="column">
        {!(hasCurrentSelfRelationship || hasPendingSelfInvitation) && (
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  name="patientIsLogin"
                  checked={isSelf}
                  color="primary"
                  inputProps={{'aria-label': 'checkbox'}}
                  onChange={toggleSelf}
                />
              }
              label="This is the Patient's login"
            />
          </Grid>
        )}
        {!isSelf && (
          <Grid item>
            <TextInput
              required={true}
              name="relationshipName"
              placeholder="Please enter the Connected Login's relationship to the Patient"
              value={relationshipName}
              onChange={e => {
                setRelationshipName(e.target.value);
              }}
              title="Connected Login's Relationship"
              touched={true}
            />
          </Grid>
        )}

        {doSearch && (
          <Grid item className={classes.inputGrid}>
            <SearchByGuardian
              name="guardian"
              selectedUser={person?.fullName ?? ''}
              selectUser={g => {
                setPerson(g);
              }}
              renderUser={(user: ResponsiblePerson) => (
                <div>
                  {user.fullName} ({user.email.orElse('No Email')})
                </div>
              )}
              title="Search for someone already in the system"
            />
          </Grid>
        )}
        {!doSearch && (
          <Grid item>
            <TextInput
              name="guardian"
              placeholder={`Please enter the ${
                isSelf && !isConnectedLogin ? "patient's" : "Connected Login's"
              } full email address`}
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
              title={`${
                isSelf && !isConnectedLogin ? 'Patient' : 'Connected Login'
              } email address`}
              touched={true}
            />
            <GuardianSearchSpinner searching={isInviting}>
              <FontAwesomeIcon icon={'spinner'} spin />
            </GuardianSearchSpinner>
          </Grid>
        )}
        {userType !== UserType.Guardian && (
          <Grid item className={classes.inputGrid}>
            <Button
              bStyle="outlined"
              onClick={() => {
                setEnterManually(!enterManually);
                setPerson(null);
                setEmail('');
              }}
            >
              {enterManually
                ? `Search for ${
                    isSelf && !isConnectedLogin ? 'patient' : 'Connected Login'
                  }`
                : 'Enter email for new user'}
            </Button>
          </Grid>
        )}
      </Grid>

      {person && (
        <ResponsiblePersonCard
          relationship={relationshipText}
          person={person}
        />
      )}
    </>
  );

  const submitButton = (
    <MuiAsyncActionButton
      bStyle="contained"
      bSize="medium"
      disabled={
        isInviting ||
        email.trim().length === 0 ||
        conflictingRelationship ||
        isEmpty(relationshipName)
      }
      onClick={selectGuardian}
      actionWord={
        hasPendingInvitationForPerson
          ? 'Invitation already pending'
          : preExistingRelationship
          ? 'Already associated with this Patient'
          : isSelf && hasSelfRelationship
          ? 'Already associated with another Patient'
          : isInviting
          ? submitButtonText?.actionWord || 'Inviting'
          : submitButtonText?.text ||
            `Invite this person to seek care for ${patientName}`
      }
      actionInProgress={isInviting}
    />
  );

  if (mode === 'modal') {
    return (
      <Modal
        onClose={onDone}
        show={show}
        title={`Invite ${
          isSelf && !isConnectedLogin ? 'Patient' : 'Connected Login'
        }`}
        modalActions={submitButton}
      >
        {form}
      </Modal>
    );
  } else {
    return (
      <>
        {form}
        {submitButton}
      </>
    );
  }
}
