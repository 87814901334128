import {useTheme} from '@material-ui/core';
import React from 'react';
import {ActionList, ActionProps, Props} from './ActionList';

export function AddableList({
  handleAddClicked,
  mappable,
  emptyMessage,
  secondaryActionProps,
}: Omit<Props, 'actionProps'> & {
  handleAddClicked: (id: number) => void;
  secondaryActionProps?: ActionProps;
}) {
  const theme = useTheme();

  return (
    <ActionList
      mappable={mappable}
      emptyMessage={emptyMessage}
      primaryActionProps={{
        icon: ['fas', 'plus-circle'],
        iconColor: theme.palette.success.main,
        handleActionClicked: handleAddClicked,
      }}
      secondaryActionProps={secondaryActionProps}
    />
  );
}
