import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';
import {Grid, Typography} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import React, {useState} from 'react';
import {CareTeamCard} from '@src/components/ui/molecules/cards/professionals/CareTeamCard';
import {
  identifyLastUpdater,
  Updatable,
} from '@src/components/shared/PatientDashboard/identifyLastUpdater';
import {AddOrEditMedicalTeamMemberModal} from '@src/components/shared/PatientDashboard/AddOrEditMedicalTeamMemberModal';
import {LastUpdated} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/LastUpdated';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/styles';
import {
  CareTeamMembership,
  LonelyPatient,
  Patient,
  ProviderDetails,
  UserType,
  SharedCarePlan,
} from '@src/models';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {SCPHeading} from '@src/components/shared/PatientDashboard/SCP/SCPHeading';
import {useApi} from '@src/api/useApi';
import {byId} from '@src/util/sorters/byId';

type Props = {
  scp: Optional<SharedCarePlan>;
  instructions?: string;
  patient: AsyncData<LonelyPatient>;
  onUpdatePatient: (patient: Patient) => void;
  canEdit?: boolean;
  viewerType: UserType;
};

export function CareTeam({
  instructions,
  scp,
  patient,
  onUpdatePatient,
  canEdit = false,
  viewerType,
}: Props) {
  const api = useApi();
  const classes = useStyles();

  const oPatient = patient.getOptional();
  const loading = !oPatient.isPresent();

  const pcp = oPatient.map(p => p.pcp).orNull();
  const planOwner = scp.map(m => m.providerDetails).orNull();

  const planOwnedByPcp = planOwner?.id === pcp?.id;

  const [infoToEdit, setInfoToEdit] = useState(
    Optional.empty<ProviderDetails>(),
  );
  const [showModal, setShowModal] = useState(false);

  const updatables = oPatient
    .map<{team: CareTeamMembership[]; updatable: Updatable}>(p => {
      const qualification = p.pcp.map(pc => pc.qualification);
      return {
        team: p.careTeam,
        updatable: {
          updatedAt: p.pcp.map(pc => pc.updatedAt),
          lastUpdatedBy: p.pcp.map(pc => pc.lastUpdatedBy),
          qualification,
        },
      };
    })
    .map(({team, updatable}) =>
      team
        .map(c => ({
          updatedAt: Optional.of(c.updatedAt),
          lastUpdatedBy: c.lastUpdatedBy,
          qualification: c.providerDetails.map(pd => pd.qualification),
        }))
        .concat(updatable),
    );

  const updated = identifyLastUpdater(oPatient, updatables);

  return (
    <Grid
      container
      direction="row"
      style={{
        marginBottom: '4rem',
      }}
      className={classes.rowContainer}
    >
      <Grid item>
        <SCPHeading>Medical Team</SCPHeading>
      </Grid>
      {canEdit && (
        <Grid item>
          <Add
            onClick={() => {
              setInfoToEdit(Optional.empty());
              setShowModal(true);
            }}
            className={classes.icon}
          />
        </Grid>
      )}

      {instructions && (
        <Grid item xs={12} className={classes.gridItem}>
          <Typography variant="caption" style={{fontStyle: 'italic'}}>
            {instructions}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} className={classes.gridItem}>
        <LastUpdated updated={updated} />
      </Grid>

      <PageLoading active={loading} message={'Loading patient details...'}>
        <Grid item xs={12} md={6}>
          <CareTeamCard
            details={planOwner}
            role={planOwnedByPcp ? 'Plan Owner & PCP' : 'Plan Owner'}
            canEdit={false}
            onEdit={() => {}}
            viewerType={viewerType}
          />
          {!planOwnedByPcp && (
            <CareTeamCard
              details={pcp}
              role="PCP"
              canEdit={false}
              onEdit={() => {}}
              viewerType={viewerType}
            />
          )}
          {patient
            .getOptional()
            .map(m => m.careTeam)
            .orElse([])
            .map(cm => cm.providerDetails)
            .filter(pd => pd.isPresent())
            .map(pd => pd.get())
            .sort(byId)
            .map(p => (
              <CareTeamCard
                key={p.id}
                details={p}
                canEdit={canEdit}
                onEdit={() => {
                  setInfoToEdit(Optional.of(p));
                  setShowModal(true);
                }}
                viewerType={viewerType}
              />
            ))}
        </Grid>

        {showModal && (
          <AddOrEditMedicalTeamMemberModal
            patientId={patient.singleValue().id}
            providerDetails={infoToEdit}
            show={true}
            onHide={() => setShowModal(false)}
            onUpdate={() => {
              const patientId = patient.singleValue().id;
              api
                .patient(patientId)
                .get()
                .then(pt => {
                  onUpdatePatient(pt);
                  setShowModal(false);
                });
            }}
            onDelete={() => {
              const patientId = patient.singleValue().id;
              api
                .patient(patientId)
                .get()
                .then(pt => {
                  onUpdatePatient(pt);
                  setShowModal(false);
                });
            }}
            userType={viewerType}
          />
        )}
      </PageLoading>
    </Grid>
  );
}
