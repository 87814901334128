import {AppAPI} from '@src/api/AppAPI';

export type AnonymousAccessRequestProps = {
  api: AppAPI;
  visitId: number;
  payload: PayloadForAnonymousVisitor;
};

export type PayloadForAnonymousVisitor = {
  firstName: string;
  lastName: string;
  visitorConfirmedAuthorization: boolean;
  patientName?: string;
};

export function anonymousAccessRequest({
  api,
  visitId,
  payload = {
    firstName: '',
    lastName: '',
    visitorConfirmedAuthorization: false,
    patientName: '',
  },
}: AnonymousAccessRequestProps) {
  return api.visit(visitId).anonymousAccessRequest({
    ...payload,
  });
}
