import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useEffect, useState} from 'react';
import {connect as reduxConnect} from 'react-redux';
import {isSupported, RemoteParticipant, Room} from 'twilio-video';
import {useStyles} from '../layout';
import {FactsDisplay} from '../../FactsDisplay';
import {SIDEBAR_WIDTH} from '../../VideoChat/layout';
import AnonymousParticipantVideo from './ParticipantVideo';
import {SidebarVideoGallery} from './SidebarVideoGallery';
import {
  getScreensharingElement,
  handleIsScreensharing,
  handleNotScreensharing,
} from './videoGalleryFunctions';
import {ReduxState} from '@src/store';
import {MediaFailure} from '@src/util/videoChat/userMedia/streams';
import {
  calculateAvailableWidth,
  detectIsAnyoneScreensharing,
} from '@src/util/videoChat';
import {
  AudioInputInfo,
  AudioOutputInfo,
  VideoInputInfo,
} from '@src/store/reducers/media';
import {useVideoStyles} from '@src/components/ui/layout/VideoStyles';

type StateProps = {
  audioIn: Optional<AudioInputInfo>;
  audioOut: Optional<AudioOutputInfo>;
  videoIn: Optional<VideoInputInfo>;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  audioIn: state.media.selectedAudioInput,
  audioOut: state.media.selectedAudioOutput,
  videoIn: state.media.selectedVideoInput,
});

type VideoGalleryProps = {
  participants: RemoteParticipant[];
  room: Room | null;
  isDisconnecting: boolean;
  cutRoomAudio: boolean;
  setNotification: (v: JSX.Element | null) => void;
  selfWaitingForAdmittance: boolean;
  setSelfWaitingForAdmittance: (v: boolean) => unknown;
  visitId: number;
  onMediaFailure: (v: Optional<MediaFailure>) => unknown;
};

// eslint-disable-next-line import/no-unused-modules
export function _VideoGallery({
  cutRoomAudio,
  isDisconnecting,
  room,
  setNotification,
  selfWaitingForAdmittance,
  setSelfWaitingForAdmittance,
  participants,
  visitId,
  videoIn,
  audioIn,
  audioOut,
  onMediaFailure,
}: VideoGalleryProps & StateProps) {
  const videoClasses = useVideoStyles();
  const [isAnyoneScreensharing, setIsAnyoneScreensharing] = useState<boolean>(
    false,
  );
  const [isLocalScreensharing, setIsLocalScreensharing] = useState<boolean>(
    false,
  );

  const classes = useStyles({
    width: SIDEBAR_WIDTH,
  });

  const waitingForRemote = participants.length <= 0;

  const doNotTransmit =
    !isSupported ||
    waitingForRemote ||
    selfWaitingForAdmittance ||
    isDisconnecting;

  useEffect(() => {
    if (detectIsAnyoneScreensharing({room})) {
      handleIsScreensharing({
        setIsAnyoneScreensharing,
        setIsLocalScreensharing,
        setNotification,
        room,
      });
    } else {
      handleNotScreensharing({
        setIsAnyoneScreensharing,
        setIsLocalScreensharing,
        setNotification,
        participants,
      });
    }
  }, [room, selfWaitingForAdmittance]);

  function onVideoTracksStateChange() {
    if (detectIsAnyoneScreensharing({room})) {
      handleIsScreensharing({
        room,
        setNotification,
        setIsLocalScreensharing,
        setIsAnyoneScreensharing,
      });
      return;
    } else {
      handleNotScreensharing({
        setIsAnyoneScreensharing,
        setNotification,
        setIsLocalScreensharing,
        participants,
      });
    }
  }

  if (!room) return null;

  const remoteParticipants = participants.map(participant => (
    <AnonymousParticipantVideo
      key={participant.sid}
      participant={participant}
      room={room}
      onVideoTracksStateChange={onVideoTracksStateChange}
      onAdmittedProps={{audioIn, audioOut, videoIn, onMediaFailure}}
      visitId={visitId}
      setSelfWaitingForAdmittance={setSelfWaitingForAdmittance}
      predicates={{
        isLocal: false,
        cutRoomAudio,
        isAnyoneScreensharing,
        isMainScreenElement: false,
        isLocalScreensharing,
        doNotTransmit,
        selfWaitingForAdmittance,
      }}
    />
  ));

  const localParticipant = (
    <AnonymousParticipantVideo
      participant={room?.localParticipant}
      room={room}
      onVideoTracksStateChange={onVideoTracksStateChange}
      onAdmittedProps={{audioIn, audioOut, videoIn, onMediaFailure}}
      visitId={visitId}
      setSelfWaitingForAdmittance={setSelfWaitingForAdmittance}
      predicates={{
        isLocal: true,
        cutRoomAudio,
        isAnyoneScreensharing,
        isMainScreenElement: false,
        isLocalScreensharing,
        doNotTransmit,
        selfWaitingForAdmittance,
      }}
    />
  );

  function getMainScreenElement() {
    if (isAnyoneScreensharing && room) {
      return getScreensharingElement({
        room,
        predicates: {
          isAnyoneScreensharing,
          isLocalScreensharing,
          cutRoomAudio,
          doNotTransmit,
          selfWaitingForAdmittance,
        },
        onVideoTracksStateChange,
        onAdmittedProps: {audioIn, audioOut, videoIn, onMediaFailure},
        setSelfWaitingForAdmittance,
        visitId,
      });
    }
  }

  const renderSidebarVideoGallery = isAnyoneScreensharing;

  if (isDisconnecting) {
    return null;
  }

  return (
    <div className={videoClasses.galleryContainer}>
      {renderSidebarVideoGallery && (
        <>
          {doNotTransmit && (
            <div
              style={{width: calculateAvailableWidth()}}
              className={classes.factsDisplayContainer}
            >
              <FactsDisplay facts={Optional.empty()} />
            </div>
          )}
          <SidebarVideoGallery
            localParticipant={localParticipant}
            remoteParticipants={remoteParticipants}
            mainScreenNode={getMainScreenElement()}
            room={room}
            hide={doNotTransmit}
          />
        </>
      )}

      {!renderSidebarVideoGallery && (
        <div id="gallery">
          {doNotTransmit && (
            <div
              style={{width: calculateAvailableWidth()}}
              className={classes.factsDisplayContainer}
            >
              <FactsDisplay facts={Optional.empty()} />
            </div>
          )}
          {localParticipant}
          {remoteParticipants}
        </div>
      )}
    </div>
  );
}

export const VideoGallery = reduxConnect(mapStateToProps)(_VideoGallery);
