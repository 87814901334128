import axios, {Canceler} from 'axios';
import {BasicQueryException} from '../../../../api/exceptions';
import {ICD10Type} from '.';

type NIHResponse = [number, string[], undefined, [string, string]];

export class NIHRequester {
  canceler?: Canceler;
  currentResult: ICD10Type[] = [];
  totalCount: number = 0;

  get(terms: string = '', maxList: number = 7): Promise<ICD10Type[]> {
    if (this.canceler) {
      this.canceler();
    }
    // eslint-disable-next-line import/no-named-as-default-member
    const cancelToken = new axios.CancelToken(c => (this.canceler = c));

    if (terms) {
      return axios
        .get<NIHResponse>(
          'https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search',
          {
            cancelToken,
            params: {
              maxList,
              sf: 'code,name',
              terms,
            },
          },
        )
        .then(response => {
          const [count, , , details] = response.data;
          this.totalCount = count;
          this.currentResult = details.map(detail => ({
            id: detail[0],
            code: detail[0],
            description: detail[1],
          }));
          return this.currentResult;
        })
        .catch(e => {
          if (!axios.isCancel(e)) {
            throw new BasicQueryException(
              `Something went wrong trying search for an ICD-10 code: ${e}`,
            );
          }
          return this.currentResult;
        });
    }

    return Promise.resolve([]);
  }
}
