import {AsyncData} from '@ahanapediatrics/ahana-fp';
import React from 'react';
import {Grid} from '@material-ui/core';
import {usePageContext} from '..';
import {HistoryBox} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/HistoryBox';
import {SCPInfoBox} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox';
import {SCPOwnerBox} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPOwnerBox';
import {ContactInfo} from '@src/components/shared/PatientDashboard/SCP/panels/Contacts/ContactInfo';
import {CareTeam} from '@src/components/shared/PatientDashboard/SCP/panels/Contacts/CareTeam';
import {SCPStatus, UserType, PatientRelationship} from '@src/models';
import {
  userCanEditProtectedField,
  userCanEditMedicalHistoryField,
} from '@src/util/sharedCarePlan/permissions';
import {useUser} from '@src/hooks';

type Props = {
  guardians: AsyncData<PatientRelationship>;
};

export const SummaryView = ({guardians}: Props) => {
  const {
    currentMedicalHistory,
    setCurrentMedicalHistory,
    scp,
    setScp,
    reloadChangeRequests,
    patient,
    setPatient,
    selectedPanel,
    reviewMode,
  } = usePageContext();

  const [user] = useUser();

  const oScp = scp.getOptional();
  const canCurrentUserEditProtected = userCanEditProtectedField(
    user.getOptional(),
    oScp,
  );

  const canCurrentUserEditHistory = userCanEditMedicalHistoryField(
    user.getOptional(),
    reviewMode,
  );

  const hasCommPrefs =
    oScp
      .map(m => m.communicationPreferences)
      .orElse('')
      .trim().length > 0;

  if (selectedPanel !== 'summaryView' || !oScp.isPresent()) return null;

  return (
    <Grid container direction="row">
      <SCPOwnerBox scp={oScp} />
      {oScp.map(m => m.status).orNothing() !== SCPStatus.Retracted && (
        <>
          <SCPInfoBox
            canEdit={canCurrentUserEditProtected}
            title="Summary"
            property="emergencySummary"
          />
          <SCPInfoBox
            canEdit={canCurrentUserEditProtected}
            title="Active Diagnoses"
            property="diagnosis"
          />
          <HistoryBox
            canEdit={canCurrentUserEditHistory}
            title="Allergies"
            property="allergies"
            placeholder="Unknown"
            medicalHistory={currentMedicalHistory.getOptional()}
            onUpdateMedicalHistory={setCurrentMedicalHistory}
            patientId={patient
              .getOptional()
              .map(p => p.id)
              .orElse(0)}
          />
          <HistoryBox
            canEdit={canCurrentUserEditHistory}
            title="Medications"
            property="medications"
            medicalHistory={currentMedicalHistory.getOptional()}
            onUpdateMedicalHistory={setCurrentMedicalHistory}
            patientId={patient
              .getOptional()
              .map(p => p.id)
              .orElse(0)}
          />
          {hasCommPrefs && (
            <SCPInfoBox
              canEdit={canCurrentUserEditHistory}
              title="Communication Preferences"
              property="communicationPreferences"
            />
          )}
          <ContactInfo
            user={user}
            patient={patient}
            onUpdateScp={m => {
              setScp(m);
              reloadChangeRequests();
            }}
            canEdit={true}
            updatePatient={setPatient}
            guardians={guardians}
          />
          <CareTeam
            scp={oScp}
            patient={patient}
            canEdit={true}
            onUpdatePatient={setPatient}
            viewerType={user
              .getOptional()
              .map(u => u.userType)
              .orElse(UserType.Unknown)}
          />
        </>
      )}
    </Grid>
  );
};
