import styled from 'styled-components';
import {h3, md, mobileMax, primary, sm, xs} from '../theme';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';
import {ButtonColumnContainer} from '@src/components/ui/layout/ButtonContainers';

export const FinancialSummaryBox = styled.div`
  background-color: #fff;
  border: 1px #000 solid;
  border-radius: ${xs};
  margin: ${sm} ${xs} ${md};

  @media screen and (max-width: ${mobileMax}) {
    max-width: 100%;
  }
`;

export const SummaryList = styled.ul`
  padding-left: ${md};
  margin-bottom: ${md};
  margin-top: ${xs};
  margin-left: ${xs};
`;

export const SummaryItem = styled.li`
  margin: ${xs} 0;
  list-style: none;
  line-height: 1.4em;
`;

export const SummaryTitle = styled.h1`
  font-size: ${h3};
  background-color: ${primary};
  color: #fff;
  padding: ${xs};
`;

const StyledClickable = styled(ClickableIcon)`
  font-size: 0.9em;
`;

export const SummarySectionTitle = styled.h2`
  color: ${primary};
  display: flex;
  flex-direction: row;
  font-size: ${h3};
  justify-content: flex-start;
  padding: ${xs};

  ${StyledClickable} {
    margin-left: ${sm};
  }
`;

export const SummarySectionContent = styled.div`
  padding: ${sm};
`;

export const ButtonColumnLeftContainer = styled(ButtonColumnContainer)`
  align-items: flex-start;
`;
