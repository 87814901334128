import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  rowContainer: {
    width: '100%',
    paddingLeft: '1em',
    paddingRight: '1em',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
    },

    marginTop: '2em',
    marginBottom: '2em',
    flexGrow: 1,
  },
  table: {
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 0.5rem',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
      padding: '2rem',
    },
  },
}));
