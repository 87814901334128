import {useCallback, useEffect} from 'react';

export function useOnPageLeave(handlePageLeave: (e: Event) => void) {
  const onPageLeave = useCallback((e: Event) => handlePageLeave(e), []);
  window.onbeforeunload = onPageLeave;
  window.onpopstate = onPageLeave;
  window.onpagehide = onPageLeave;

  // Clean up when component unmounts.
  useEffect(() => {
    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
      window.onpagehide = null;
    };
  }, []);
}
