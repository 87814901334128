import {Grid} from '@material-ui/core';
import {isAfter} from 'date-fns';
import React, {useCallback, useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import {Alert} from '@material-ui/lab';
import {PageContent, PageTitle} from '../CreateProvider/layout';
import {useApi} from '@src/api/useApi';
import {OnCall} from '@src/components/providerSide/ShiftsAndGroupsPage/OnCallSection';
import {SearchByProvider} from '@src/components/ui/form/search/SearchByProvider';
import {useAsync} from '@src/hooks';
import {OnCallPeriod, Professional, ProviderDetails} from '@src/models';
import {getAllPages} from '@src/util/apiHelpers/getAllPages';
import {getProviderDescription} from '@src/util/provider';
import {UserId} from '@src/models/User';
import {byStartAsc} from '@src/util/sorters/byStart';

type Props = RouteComponentProps<{providerId?: string}>;

// eslint-disable-next-line import/no-unused-modules
export default function CreateOnCallPeriodForm({
  match: {
    params: {providerId},
  },
}: Props) {
  const api = useApi();

  const [provider, setProvider, resetProvider] = useAsync<Professional>();
  const [details, setDetails, resetDetails] = useAsync<ProviderDetails>();
  const [onCallPeriods, setOnCallPeriods, resetOnCallPeriods] = useAsync<
    OnCallPeriod
  >();

  const deleteOnCallPeriod = useCallback(
    (period: OnCallPeriod) =>
      provider.getOptional().map(p =>
        api
          .provider(p.id)
          .deleteOnCallPeriod(period.id)
          .then(() => {
            setOnCallPeriods(
              onCallPeriods
                .filter(oncallPeriod => oncallPeriod.id !== period.id)
                .getAllOptional()
                .orElse([]),
            );
          }),
      ),
    [api, onCallPeriods, provider, setOnCallPeriods],
  );

  useEffect(() => {
    if (providerId && !provider.isAsked()) {
      setProvider();
      api
        .provider(+providerId as UserId)
        .get()
        .then(setProvider);
    }
  }, [api, provider, setProvider, providerId]);

  useEffect(() => {
    if (!provider.isAsked()) {
      details.getOptional().ifPresent(d => {
        const prId = d.providerId ?? 0;
        if (prId === 0) {
          return;
        }
        setProvider();
        api
          .provider(prId)
          .get()
          .then(setProvider);
      });
    }
  }, [api, details, provider, setProvider]);

  useEffect(() => {
    if (!onCallPeriods.isAsked()) {
      provider.getOptional().ifPresent(p => {
        setOnCallPeriods();
        getAllPages(options =>
          api.provider(p.id).getOnCallPeriods(options),
        ).then(result => {
          console.log(result);
          const periods = result
            .filter(period => isAfter(period.end, new Date()))
            .sort(byStartAsc);
          setOnCallPeriods(periods);
        });
      });
    }
  }, [api, onCallPeriods, provider, setOnCallPeriods]);

  return (
    <PageContent>
      <PageTitle landing>Create On Call Period</PageTitle>
      <Grid container direction="column">
        <Grid item>
          <SearchByProvider
            name="provider"
            userType="Provider"
            title="Search for a Provider"
            placeholder="Search for a Provider"
            disabled={false}
            selectedUser={details
              .getOptional()
              .map(p => p.fullName)
              .orElse('')}
            selectUser={p => {
              if (p) {
                setDetails(p);
              } else {
                resetDetails();
              }
              resetProvider();
              resetOnCallPeriods();
            }}
            renderUser={user =>
              user ? <div>{getProviderDescription(user)}</div> : <div />
            }
            touched={false}
          />
        </Grid>
        {!details.isLoaded() && (
          <Grid item>
            <div>Please select a Provider first.</div>
          </Grid>
        )}
        {details.isLoaded() &&
          details
            .getOptional()
            .map(i => i.providerId)
            .orElse(0 as UserId) === 0 && (
            <Alert severity={'error'}>
              You can only create On Call Periods for active users.
            </Alert>
          )}
        {provider
          .getOptional()
          .map(pr => (
            <OnCall
              onCallNumber={pr.phone}
              onCallPeriods={onCallPeriods}
              onCreateOnCallPeriods={periods => {
                let updatedPeriods = onCallPeriods;
                periods.forEach(period => {
                  const preExisting = onCallPeriods.findIndex(
                    oc => oc.id === period.id,
                  );
                  if (preExisting === -1) {
                    updatedPeriods = updatedPeriods.concat(period);
                  } else {
                    updatedPeriods = updatedPeriods.update(preExisting, period);
                  }
                });
                setOnCallPeriods(updatedPeriods.getAllOptional().orElse([]));
              }}
              onDeleteOnCallPeriod={deleteOnCallPeriod}
              providerId={pr.id}
            />
          ))
          .orNull()}
      </Grid>
    </PageContent>
  );
}
