import React from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {Grid} from '@material-ui/core';
import {Formik} from 'formik';
import {PatientMedicalHistoryForm, schema} from './PatientMedicalHistoryForm';
import {
  MedicalHistory,
  MedicalHistoryDetails,
  LonelyPatient,
} from '@src/models';
import {Button} from '@src/components/ui/form';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {Modal} from '@src/components/ui/layout/Modal';
import {useApi} from '@src/api/useApi';

type Props = {
  patient: LonelyPatient;
  currentMedicalHistory: AsyncData<MedicalHistory>;
  onSubmitComplete: (medicalHistory?: MedicalHistory) => void;
  onCancel: () => void;
  showModal: boolean;
};

const EditPatientMedicalHistoryModal = ({
  currentMedicalHistory,
  patient,
  showModal,
  onCancel,
  onSubmitComplete,
}: Props) => {
  const api = useApi();
  const submit = (values: MedicalHistoryDetails) => {
    api
      .patient(patient.id)
      .updateMedicalHistory(values)
      .then((updatedHistory: MedicalHistory) => {
        onSubmitComplete(updatedHistory);
      });
  };

  const headerText = `${
    patient.medicalHistoryVersion === 1 ? 'Add' : 'Edit'
  } Medical History`;

  if (!currentMedicalHistory.isLoaded()) {
    return null;
  }

  return (
    <Modal show={showModal} title={headerText} fullScreen onClose={onCancel}>
      <Formik
        onSubmit={submit}
        validationSchema={schema}
        initialValues={{
          ...schema.default(),
          ...currentMedicalHistory.singleValue().details,
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isSubmitting,
          dirty,
          submitForm,
          resetForm,
        }) => {
          const action = () => {
            if (dirty) {
              submitForm();
            } else {
              onSubmitComplete();
            }
          };
          const close = () => {
            resetForm();
            onCancel();
          };

          return (
            <>
              <div>
                <div>
                  Please feel free to add any medical history you like. You can
                  edit this later too.
                </div>
                <PatientMedicalHistoryForm
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  patient={patient}
                />
              </div>
              <Grid container direction="row">
                <Button disabled={isSubmitting} onClick={close} isInRow={true}>
                  Cancel
                </Button>
                <MuiAsyncActionButton
                  actionInProgress={isSubmitting}
                  actionWord="Save"
                  onClick={() => action()}
                />
              </Grid>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditPatientMedicalHistoryModal;
