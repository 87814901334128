import React, {useEffect, useMemo} from 'react';
import {partition} from 'lodash';
import {StepProps} from '..';
import {RequestHeader} from '@src/components/ui/layout/NewThingRequest';
import {useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {FormAccordion} from '@src/components/shared/AssignedFormPage/FormAccordion';

export function AssignedFormsStep({
  patient,
  setNextAvailable,
  visitId,
}: StepProps) {
  const api = useApi();

  const [visit, refetchVisit] = useResources(
    async () => await api.visit(visitId).get(),
    [visitId],
  );

  const forms = visit
    .getOptional()
    .map(v => v.assignedForms.orElse([]))
    .orElse([]);

  const [completedForms, incompleteForms] = useMemo(
    () => partition(forms, form => form.completedAt.isPresent()),
    [forms],
  );

  useEffect(() => {
    if (visit.isLoaded() && incompleteForms.length === 0) {
      setNextAvailable(true);
    }
  }, [incompleteForms]);

  return (
    <>
      <RequestHeader>Forms</RequestHeader>
      <FormAccordion
        forms={incompleteForms}
        onSubmit={succeeded => {
          if (succeeded) {
            refetchVisit();
          }
        }}
        key="incompleteForms"
      />
      <FormAccordion forms={completedForms} isCompleted key="completedForms" />
    </>
  );
}
