import React from 'react';
import {Grid} from '@material-ui/core';
import {Controller} from 'react-hook-form';
import {usePageContext} from '..';
import {SelectInput} from '@src/components/ui/form';
import {CallPoolsMultiselect} from '@src/components/ui/organisms/selectors/CallPoolsMultiselect';

export function CustomerAndGroupSelector() {
  const {
    invoiceTargets,
    formResources: {
      control,
      errors,
      allFields,
      setSelectedCallPoolIds,
      selectedCallPoolIds,
      invoiceTargetGroups,
    },
  } = usePageContext();

  const invoiceTargetIdState = allFields.invoiceTargetId;

  return (
    <>
      <Controller
        control={control}
        name="invoiceTargetId"
        render={({onChange, value, name}) => (
          <SelectInput
            required
            value={value}
            name={name}
            title="Customer"
            onChange={onChange}
            placeholder="Customer"
            options={invoiceTargets
              .getAllOptional()
              .orElse([])
              .map(target => ({
                label: target.customerName,
                value: `${target.id}`,
              }))}
            error={errors.invoiceTargetId?.message}
          />
        )}
      />

      <Grid
        item
        xs={12}
        md={8}
        style={{display: invoiceTargetIdState ? 'block' : 'none'}}
      >
        <CallPoolsMultiselect
          callPools={invoiceTargetGroups}
          onUpdateSelection={setSelectedCallPoolIds}
          selectedIds={selectedCallPoolIds}
        />
      </Grid>
    </>
  );
}
