import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useCallback, useState} from 'react';
import {Grid} from '@material-ui/core';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {Heading} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/Heading';
import {CurrentView} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/CurrentView';
import {
  UpdateModal,
  UpdateMode,
} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/UpdateModal';
import {PendingValue} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/PendingView';
import {Toggle} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/Toggle';
import {isUserRequester} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/utils/isUserRequest';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/styles';
import {SharedCarePlan, SCPChangeRequest} from '@src/models';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {isNothing} from '@src/util/typeTests';
import {useUser} from '@src/hooks';
import {allLoaded} from '@src/util/apiHelpers';
import {useRequestToDisplay} from '@src/hooks/scp/changeRequests/useRequestToDisplay';

type Props = {
  canEdit: boolean;
  propertyDescription?: string;
  property: keyof SharedCarePlan;
  title: string;
};

export function SCPInfoBox({
  canEdit = false,
  propertyDescription = '',
  property,
  title,
}: Props) {
  const classes = useStyles();

  const [user] = useUser();
  const oUser = user.getOptional().orNothing();

  const {scp, changeRequests, isOwner, isUserPartner} = usePageContext();

  const requestToDisplay: SCPChangeRequest | undefined = useRequestToDisplay({
    changeRequests,
    user,
    property,
    isOwnerOrPartner: isOwner || isUserPartner,
  });

  const [showUpdateModal, setShowUpdateModal] = useState<UpdateMode>(null);

  const [pendingChangeToggled, setPendingChangeToggled] = useState(false);

  const onDone = useCallback(() => setShowUpdateModal(null), [
    setShowUpdateModal,
  ]);

  const userOwnsNewestChange =
    !isNothing(requestToDisplay) && isUserRequester(requestToDisplay, oUser);

  return (
    <PageLoading message="Loading" active={!allLoaded(changeRequests, scp)}>
      <Grid className={classes.rowContainer} container direction="row">
        <Heading propertyDescription={propertyDescription} title={title} />

        <Grid container xs={12} md={8}>
          <Toggle
            setPendingChangeToggled={setPendingChangeToggled}
            pendingChangeToggled={pendingChangeToggled}
            requestToDisplay={requestToDisplay}
          />

          {!pendingChangeToggled && (
            <CurrentView
              canEdit={canEdit}
              property={property}
              setShowUpdateModal={setShowUpdateModal}
              userOwnsNewestChange={userOwnsNewestChange}
            />
          )}

          {!isNothing(requestToDisplay) && pendingChangeToggled && (
            <Grid item xs={12}>
              <PendingValue
                setShowUpdateModal={setShowUpdateModal}
                setPendingChangeToggled={setPendingChangeToggled}
                changeRequest={requestToDisplay}
              />
            </Grid>
          )}
        </Grid>

        {showUpdateModal !== null && (
          <UpdateModal
            propertyDescription={propertyDescription}
            property={property}
            onDone={onDone}
            show={showUpdateModal !== null}
            changeRequest={Optional.of(requestToDisplay)}
            userOwnsNewestChange={userOwnsNewestChange}
            updateMode={showUpdateModal}
          />
        )}
      </Grid>
    </PageLoading>
  );
}
