import React, {useCallback, useState} from 'react';
import {Grid} from '@material-ui/core';
import {ApproveButton} from '@src/components/shared/PatientDashboard/SCP/panels/SCPChangeLog/ApproveButton';
import {UpdateMode} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/SCPInfoBox/UpdateModal';
import {usePageContext} from '@src/components/shared/PatientDashboard/SCP';
import {MuiAsyncActionButton} from '@src/components/ui/form/MuiAsyncActionButton';
import {SCPChangeRequest, SCPChangeRequestStatus} from '@src/models';
import {Button} from '@src/components/ui/form';
import {
  flashError,
  flashSuccess,
} from '@src/components/shared/notifications/flash';
import {lg} from '@src/components/ui/theme';
import {useOnDelete} from '@src/hooks/scp/changeRequests/useOnDelete';
import {ReRequestButton} from '@src/components/shared/PatientDashboard/SCP/panels/SCPChangeLog/ReRequestButton';

export function Actions({
  changeRequest,
  setShowUpdateModal,
  setPendingChangeToggled,
}: {
  changeRequest: SCPChangeRequest;
  setShowUpdateModal: (v: UpdateMode) => unknown;
  setPendingChangeToggled: (toggled: boolean) => unknown;
}) {
  const {
    reloadChangeRequests,
    scp,
    isOwner,
    isUserPartner,
    reloadScp,
  } = usePageContext();
  const oScp = scp.getOptional();

  const hasOwnerAccess = isOwner || isUserPartner;
  const isObsolete = changeRequest.status === SCPChangeRequestStatus.Obsolete;

  const [deleting, setDeleting] = useState(false);

  const asyncRunAfterDeleted = useCallback(async () => {
    await reloadScp();
    await reloadChangeRequests();
    flashSuccess('Deleted');
    setPendingChangeToggled(false);
  }, [reloadChangeRequests, reloadScp, setPendingChangeToggled]);

  const runFinally = useCallback(() => {
    setDeleting(false);
  }, []);

  const runOnError = useCallback(() => {
    flashError('Something went wrong deleting this Change Request');
  }, []);

  const runBeforeRequest = useCallback(() => {
    setDeleting(true);
  }, []);

  const onDelete = useOnDelete({
    runBeforeRequest,
    asyncRunAfterRequest: asyncRunAfterDeleted,
    runFinally,
    runOnError,
    scp: oScp,
    changeRequestId: changeRequest.id,
  });

  const handleEditButtonClicked = useCallback(() => {
    setShowUpdateModal('edit');
  }, [setShowUpdateModal]);

  const runFinallyAfterApproved = useCallback(() => {
    setPendingChangeToggled(false);
  }, [setPendingChangeToggled]);

  const asyncRunAfterApproved = useCallback(async () => {
    await reloadScp();
    await reloadChangeRequests();
    flashSuccess('Approved');
  }, [reloadScp, reloadChangeRequests]);

  const asyncRunAfterReRequested = useCallback(async () => {
    await reloadScp();
    await reloadChangeRequests();
    flashSuccess(
      'Re-Requested! You can now edit or delete your Pending Change Request',
    );
  }, [reloadScp, reloadChangeRequests]);

  return (
    <>
      <Grid item xs={12} style={{marginTop: lg}}>
        <MuiAsyncActionButton
          bStyle="danger"
          bSize="small"
          onClick={onDelete}
          disabled={deleting}
          actionInProgress={deleting}
          actionWord="Delete"
          isInRow={true}
        />

        {isObsolete ? (
          <ReRequestButton
            isInRow={true}
            scp={oScp}
            changeRequest={changeRequest}
            asyncRunAfterReRequested={asyncRunAfterReRequested}
          />
        ) : (
          <Button
            bStyle="primary"
            bSize="small"
            onClick={handleEditButtonClicked}
            isInRow={true}
          >
            Edit
          </Button>
        )}

        {hasOwnerAccess && (
          <ApproveButton
            isInRow={true}
            scp={oScp}
            changeRequest={changeRequest}
            asyncRunAfterApproved={asyncRunAfterApproved}
            runFinally={runFinallyAfterApproved}
          />
        )}
      </Grid>
    </>
  );
}
