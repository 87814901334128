import React from 'react';
import {addBreadcrumb, Severity} from '@sentry/browser';
import {AnonymousExamRoom} from '../AnonymousVideoChat/AnonymousExamRoom';
import {ExamRoom} from './ExamRoom';
import {Reason as ErrorReason} from './pages/functions/types';
import {
  anonymousAccessRequest,
  PayloadForAnonymousVisitor,
} from '@src/util/videoChat/twilio/anonymousVisitor';
import {getAccessToken} from '@src/util/videoChat/twilio/authorizedUser';
import {AppAPI} from '@src/api/AppAPI';

/**
 * These are functions for VideoChat.tsx
 */

export function renderExamRoom({
  token,
  isAnonymous,
  visitId,
  anonInfo,
}: {
  token: string | null;
  isAnonymous: boolean;
  visitId: number;
  anonInfo: PayloadForAnonymousVisitor | null;
}) {
  if (token) {
    if (!isAnonymous) {
      return (
        <ExamRoom roomName={`${visitId}`} token={token} visitId={visitId} />
      );
    }

    if (isAnonymous && anonInfo) {
      return (
        <AnonymousExamRoom
          roomName={`${visitId}`}
          token={token}
          visitId={visitId}
        />
      );
    }
  }

  return null;
}

export function getAnonymousAccessToken({
  api,
  visitId,
  anonInfo,
  setError,
  setToken,
}: {
  api: AppAPI;
  visitId: number;
  anonInfo: PayloadForAnonymousVisitor;
  setError: (e: ErrorReason) => unknown;
  setToken: (t: string) => unknown;
}) {
  anonymousAccessRequest({
    api,
    visitId,
    payload: anonInfo,
  })
    .then(d => {
      addBreadcrumb({
        category: 'exam-room',
        message: 'Got access token for Twilio',
        level: Severity.Info,
      });

      if (!d.token) {
        setError('error');
      } else {
        setToken(d.token);
      }
    })
    .catch(e => {
      console.error(e);
      setError('error');
    });
}

export function getAuthorizedAccessToken({
  api,
  visitId,
  setError,
  setToken,
}: {
  api: AppAPI;
  visitId: number;
  setError: (e: ErrorReason) => unknown;
  setToken: (t: string) => unknown;
}) {
  getAccessToken({api, visitId})
    .then(d => {
      addBreadcrumb({
        category: 'exam-room',
        message: 'Got access token for Twilio',
        level: Severity.Info,
      });

      if (!d.token) {
        setError('error');
      } else {
        setToken(d.token);
      }
    })
    .catch(e => {
      console.error(e);
      setError('error');
    });
}
