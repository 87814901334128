import {Grid} from '@material-ui/core';
import React from 'react';
import {DashboardContent} from '../PastVisitsDashboard/layout';
import {ProviderSCPs} from './providerSCPs';
import {useStyles} from './styles';
import {UnfinishedBusinessAlert} from './UnfinishedBusinessAlert';
import {UnreviewedNotes} from './UnreviewedNotes';
import {PageHeader} from '@src/components/ui/layout';
import {PrivatePage} from '@src/components/PrivatePage';

// eslint-disable-next-line import/no-unused-modules
export default function ProviderDashboard() {
  const classes = useStyles();

  return (
    <PrivatePage>
      <DashboardContent>
        <PageHeader>Notes &amp; Plans</PageHeader>

        <UnfinishedBusinessAlert />
        <Grid container className={classes.rowContainer}>
          <Grid item xs={12} md={6} className={classes.table}>
            <ProviderSCPs />
          </Grid>
          <Grid item xs={12} md={6} className={classes.table}>
            <UnreviewedNotes />
          </Grid>
        </Grid>
      </DashboardContent>
    </PrivatePage>
  );
}
