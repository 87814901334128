import React, {useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Grid, Typography} from '@material-ui/core';
import {PrivatePage} from '../PrivatePage';
import {NAMES} from '../ui/layout/text/names';
import {PageSection} from '../ui/atoms/section/PageSection';
import {DashHead, DashName, useStyles} from './CreateProvider/layout';
import {ActionLink} from './ActionLink';
import ConfigService from '@src/ConfigService';
import {useStyles as useOverviewStyles} from '@src/components/providerSide/providerGroups/ProviderGroupOverviewPage/layout';
import {Routes} from '@src/components/apps/SeparateAdminApp';
import {toTitleCase} from '@src/util/stringManipulation/toTitleCase';

// eslint-disable-next-line import/no-unused-modules
export default function AdminDashboard() {
  const {isAuthenticated, user, getAccessTokenSilently} = useAuth0();

  const classes = {...useStyles(), ...useOverviewStyles()};

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          const configService = ConfigService.getEnvironmentInstance();
          const url = await configService.get('API_URL', '');
          await fetch(`${url}/ping`, {
            method: 'HEAD',
            mode: 'cors',
            credentials: 'include',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isAuthenticated) {
        getAccessTokenSilently();
      }
    }, 30_000);

    return () => clearInterval(intervalId);
  }, [getAccessTokenSilently, isAuthenticated]);

  return (
    <PrivatePage>
      <DashHead>
        <Typography variant="h1" gutterBottom>
          Welcome, <DashName>{user.nickname || 'loading...'}</DashName>
        </Typography>
      </DashHead>
      <Grid container direction="column" className={classes.overviewContainer}>
        <PageSection title="Create">
          <ActionLink
            label="Create Professional Account"
            link={Routes.CreateProvider}
          />

          <ActionLink
            label={`Create ${toTitleCase(NAMES.customer)}`}
            link={Routes.CreateCustomer}
          />
          <ActionLink
            label={`Create ${toTitleCase(NAMES.customerCode)}`}
            link={Routes.CreateCustomerCode}
          />

          <ActionLink
            label={`Create ${toTitleCase(NAMES.callPool)}`}
            link={Routes.CreateGroup}
          />
          <ActionLink
            label="Create On Call Period"
            link={Routes.CreatePeriod}
          />
          <ActionLink label="Create Endpoint" link={Routes.CreateEndpoint} />
          <ActionLink label="Create Form" link={Routes.CreateDocument} />
        </PageSection>
        <PageSection title="Modify">
          <ActionLink
            label="Convert Professional to User"
            link={Routes.ConvertToUser}
          />
        </PageSection>
        <PageSection title="View/Search">
          <ActionLink
            label="Search Providers"
            link={Routes.SearchForProvider}
          />
          <ActionLink
            label={`${toTitleCase(NAMES.callPool)}s`}
            link={Routes.ListCallPools}
          />
          <ActionLink label="Visits" link={Routes.ListVisitReports} />
        </PageSection>
      </Grid>
    </PrivatePage>
  );
}
