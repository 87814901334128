import styled from 'styled-components';
import {md, primary} from '../theme';

export const HeroContainer = styled.section`
  padding: ${md};
  margin: ${md};
  text-align: center;
`;

export const HeroHead = styled.h1`
  margin: 0;
  padding: 0;
  color: ${primary};
  font-weight: bold;
  font-size: 3em;
  line-height: 1em;
`;
