import {FileCategory} from './types';

export const categoryWords: Record<FileCategory, string> = {
  visits: 'Visits',
  scp: 'Shared Care Plan',
  general: 'General',
};

export const getNoFilesText = (categoryWord: FileCategory) => {
  return `No files were found under ${categoryWords[categoryWord]} Files.`;
};

/**
 *
 * If the Patient has no Visits or has no SCP, we display this message.
 */
export const getNoResourceText = (
  categoryWord: Omit<FileCategory, 'general'>,
) => {
  if (categoryWord === 'scp') {
    return 'There is no Shared Care Plan to attach files to.';
  } else if (categoryWord === 'visits') {
    return 'There are no Visits to attach files to.';
  }
};
