import {useCallback} from 'react';
import {useApi} from '@src/api/useApi';
import {useRequestHandler} from '@src/hooks/useRequestHandler';

const useAddProviderGroupEndpoint = ({
  endpointId,
  groupId,
}: {
  endpointId: number | null;
  groupId: number;
}) => {
  const api = useApi();

  return useCallback(async () => {
    if (endpointId === null) {
      return Promise.resolve();
    }

    return api.callPool(groupId).addEndpoint(endpointId);
  }, [api, endpointId, groupId]);
};

export const useOnAddProviderGroupEndpoint = ({
  runBeforeRequest,
  asyncRunAfterRequest,
  runFinally,
  endpointId,
  groupId,
  runOnError,
}: {
  runBeforeRequest: () => void;
  asyncRunAfterRequest: () => Promise<unknown>;
  runFinally: () => void;
  runOnError: () => void;
  endpointId: number | null;
  groupId: number;
}) => {
  const addEndpoint = useAddProviderGroupEndpoint({
    endpointId,
    groupId,
  });

  return useRequestHandler({
    runBeforeRequest,
    asyncRequest: addEndpoint,
    asyncRunAfterRequest,
    runFinally,
    runOnError,
  });
};
