import * as React from 'react';
import {
  Grid,
  useTheme,
  makeStyles,
  createStyles,
  useMediaQuery,
} from '@material-ui/core';
import {convertToFullWidth} from '../../form/Button/functions';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
);

type Props = {
  leftOrBottomGroup: JSX.Element[];
  rightOrTopGroup: JSX.Element[];
};

/**
 * This is a container for two groups of buttons.
 *  
 * Desktop layout:
 * Groups are denoted with brackets. The left side occupies 2/3rds of the screen width and the right occupies 1/3rd
 * if the screen is large enough for a horizontal layout of the groups.

 *
 * [<ButtonA/> <ButtonB/>]                  [<Button C/> <ButtonD/>]
 *
 * Mobile/Small screen layout:
 * Groups become become full-width and are arranged in a column. Note the order of the groups/buttons.
 *
 * [<ButtonC/>,
 *  <ButtonD/>]
 * [<ButtonA/>
 *  <ButtonB/>]
 */

export function TwoGroupsOfButtons({
  leftOrBottomGroup,
  rightOrTopGroup,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const smallOrLarger = useMediaQuery(theme.breakpoints.up('sm'));

  const rightGroupOrTopOfColumn = (
    <Grid item sm={4} xs={12}>
      <Grid
        container
        justify="flex-end"
        spacing={2}
        direction={smallOrLarger ? 'row' : 'column'}
      >
        {rightOrTopGroup.map((button, i) => (
          <Grid item key={i}>
            {convertToFullWidth(button)}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  const leftGroupOrBottomOfColumn = (
    <Grid item sm={8} xs={12}>
      <Grid
        container
        justify="flex-start"
        spacing={2}
        direction={smallOrLarger ? 'row' : 'column'}
      >
        {leftOrBottomGroup.map((button, i) => (
          <Grid item key={i}>
            {convertToFullWidth(button)}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={3} direction={smallOrLarger ? 'row' : 'column'}>
        {smallOrLarger ? (
          <>
            {leftGroupOrBottomOfColumn}

            {rightGroupOrTopOfColumn}
          </>
        ) : (
          <>
            {rightGroupOrTopOfColumn}
            {leftGroupOrBottomOfColumn}
          </>
        )}
      </Grid>
    </div>
  );
}
