import {makeStyles} from '@material-ui/core/styles';
import {mdLg, md} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  formContainer: {
    padding: `${mdLg} 0`,
  },
  buttonContainer: {
    padding: `${md} 0`,
  },
  seesPatientsFormContainer: {
    marginTop: `${mdLg}`,
  },
}));
