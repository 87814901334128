import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';
import {Grid, Typography} from '@material-ui/core';
import React, {useCallback, useReducer} from 'react';
import {usePageContext} from '..';
import {S3UploaderContainer} from '@src/components/ui/organisms/uploaders/S3UploaderContainer';
import {EditFileIcon} from '@src/components/shared/PatientDashboard/EditFileIcon';
import {uploadingReducer} from '@src/components/shared/PatientDashboard/uploadingReducer';
import {useStyles} from '@src/components/shared/PatientDashboard/SCP/styles';
import {UploadsList} from '@src/components/ui/organisms/uploaders/files/UploadsList';
import {useApi} from '@src/api/useApi';
import {PageLoading} from '@src/components/ui/atoms/progressBarsAndIndicators/PageLoading';
import {SCPHeading} from '@src/components/shared/PatientDashboard/SCP/SCPHeading';
import {AppFile} from '@src/models';
import {Banner} from '@src/components/ui/layout/Banner';
import {SigningResult} from '@src/components/ui/organisms/uploaders/S3UploaderContainer/types';
import {useGetFileSignature} from '@src/hooks/s3Uploading/useGetFileSignature';

type Props = {
  files: AsyncData<AppFile>;
  setFiles: (f: AppFile[]) => unknown;
};

export function Uploads({files, setFiles}: Props) {
  const {scp, setScp, selectedPanel} = usePageContext();

  const api = useApi();
  const classes = useStyles();

  const onUpdate = () => {};
  const [uploadingFiles, dispatch] = useReducer(uploadingReducer(onUpdate), []);

  const getFileSignature = useGetFileSignature();

  const onUpdateFile = useCallback((file, progress) => {
    dispatch({
      type: 'update',
      payload: {file, progress},
    });
  }, []);

  const onAddNewFile = useCallback(file => {
    dispatch({
      type: 'add',
      payload: {file},
    });
  }, []);

  const onUploadFail = useCallback((error, file) => {
    dispatch({
      type: 'error',
      payload: {
        file,
        error:
          'There was a problem uploading this file. Please contact support for assistance',
      },
    });
  }, []);

  const onCompleteFile = useCallback(() => {}, []);

  const aScp = scp.getOptional().orNull();

  if (aScp === null) {
    return null;
  }

  const oScp = Optional.of(aScp);

  const canAttachFiles = Optional.of(aScp)
    .map(m => m.links)
    .map(l => 'files' in l)
    .orElse(false);

  function onAttachFileToResource(result: SigningResult<AppFile>, file: File) {
    return api
      .scp(oScp.map(m => m.id).orElse(0))
      .addFile({
        ...result,
        contentType: file.type,
      })
      .then(newScp => {
        setScp(newScp);
        setFiles(newScp.files);
      })
      .then(m => {
        // This removes the file from the `uploadingFiles` array.
        dispatch({
          type: 'complete',
          payload: {file},
        });

        return m;
      })
      .catch(() => {
        dispatch({
          type: 'error',
          payload: {
            file,
            error:
              'There was a problem adding this file. Please contact support for assistance',
          },
        });
      });
  }

  if (selectedPanel !== 'uploads') return null;

  return (
    <Grid className={classes.rowContainer} container direction="row">
      <Grid container direction="row">
        <Grid item>
          <SCPHeading>Uploads</SCPHeading>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        className={classes.gridItem}
        style={{marginBottom: '1rem'}}
      >
        <Typography variant="caption" style={{fontStyle: 'italic'}}>
          Please provide document uploads here that may be important for a first
          responder or emergency care provider to reference. Examples could
          include: broviac card, trach safe diagram, POLST etc.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} style={{marginBottom: '1rem'}}>
        <PageLoading active={!files.isLoaded()} message="Loading files...">
          {files
            .getAllOptional()
            .map(fileList => (
              <UploadsList
                uploadedFiles={[...fileList]}
                uploadingFiles={uploadingFiles}
                hideTitle={true}
                FileEditIcon={EditFileIcon}
                onUpdateFile={f => {
                  setFiles(fileList.map(file => (f.id === file.id ? f : file)));
                }}
                onDeleteFile={f => {
                  setFiles(fileList.filter(file => f.id !== file.id));
                }}
              />
            ))
            .orNull()}
        </PageLoading>
      </Grid>

      {canAttachFiles && (
        <Grid item xs={12} style={{alignItems: 'left'}}>
          <Grid item xs={2}>
            <S3UploaderContainer
              onAttachFileToResource={onAttachFileToResource}
              onAddNewFile={onAddNewFile}
              onUploadFail={onUploadFail}
              onCompleteFile={onCompleteFile}
              onUpdateFile={onUpdateFile}
              getSignature={getFileSignature}
            />
          </Grid>
        </Grid>
      )}
      {!canAttachFiles && (
        <Banner type="info">
          "The only people who can add files to a Shared Care Plan are the
          patient Guardians, the Shared Care Plan owner, and members of the
          patient's care team."
        </Banner>
      )}
    </Grid>
  );
}
