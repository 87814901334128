import React from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {ClickableIcon} from '../ui/layout/ClickableIcon';
import {difference} from '@src/util/arrayManipulation/operations';

export enum SelectionStatus {
  All,
  Some,
  None,
}

const selectionIcons: Record<SelectionStatus, IconProp> = {
  [SelectionStatus.All]: ['far', 'check-square'],
  [SelectionStatus.Some]: ['far', 'minus-square'],
  [SelectionStatus.None]: ['far', 'square'],
};

function getSelectionStatus<T>(
  possible: readonly T[],
  selection: readonly T[],
) {
  if (selection.length === 0) {
    return SelectionStatus.None;
  }

  const allSelected =
    possible.length === selection.length &&
    difference(selection, possible).length === 0;

  return allSelected ? SelectionStatus.All : SelectionStatus.Some;
}

type Props<T> = {
  onClick: (isSelect: boolean) => unknown;
  possible: readonly T[];
  selection: readonly T[];
};
export function TriStateCheckbox<T>({onClick, possible, selection}: Props<T>) {
  const selectionStatus = getSelectionStatus(possible, selection);
  const selectionIcon = selectionIcons[selectionStatus];
  return (
    <ClickableIcon
      icon={selectionIcon}
      onClick={() => onClick(selectionStatus === SelectionStatus.None)}
    />
  );
}
