import {useReducer, useRef} from 'react';
import {AppAPI} from '@src/api/AppAPI';

export const approveDocument = (api: AppAPI) => (docId: number) =>
  api.legalDocument(docId).sign();

const reducer = (
  state: Record<number, boolean>,
  action: {
    type: 'addDoc' | 'acceptDoc' | 'unacceptDoc';
    docId: number;
    accepted?: boolean;
  },
) => {
  const {type, docId, accepted = false} = action;

  if (docId in state && type === 'addDoc') {
    return state;
  }

  switch (type) {
    case 'addDoc':
      return {...state, [docId]: accepted};
    case 'unacceptDoc':
      return {...state, [docId]: false};
    case 'acceptDoc':
      return {...state, [docId]: true};
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
};

export const useAcceptances = (
  initialState: Record<number, boolean>,
): [
  Record<number, boolean>,
  {
    add: (id: number, accepted?: boolean) => void;
    accept: (id: number) => void;
    unaccept: (id: number) => void;
  },
] => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addDocument = useRef((docId: number, accepted: boolean = false) =>
    dispatch({type: 'addDoc', docId, accepted}),
  );
  const acceptDocument = useRef((docId: number) =>
    dispatch({type: 'acceptDoc', docId}),
  );
  const unacceptDocument = useRef((docId: number) =>
    dispatch({type: 'unacceptDoc', docId}),
  );

  return [
    state,
    {
      add: addDocument.current,
      accept: acceptDocument.current,
      unaccept: unacceptDocument.current,
    },
  ];
};
