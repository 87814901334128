import React from 'react';
import {Form} from './Form';
import {createGenericContext} from '@src/util/reactContext/createGenericContext';
import {PageHeader} from '@src/components/ui/layout';
import {PrivatePage} from '@src/components/PrivatePage';
import {
  ConvertToUserPageResources,
  useConvertToUserPage,
} from '@src/hooks/people/professionals/useConvertToUserPage';

const [usePageContext, PageContextProvider] = createGenericContext<
  ConvertToUserPageResources
>();

// eslint-disable-next-line import/no-unused-modules
export default function ConvertToUserPage() {
  const resources = useConvertToUserPage();

  return (
    <PageContextProvider value={resources}>
      <PrivatePage>
        <PageHeader>Convert Professional to User</PageHeader>
        <Form />
      </PrivatePage>
    </PageContextProvider>
  );
}

export {usePageContext};
