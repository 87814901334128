import {Optional} from '@ahanapediatrics/ahana-fp';
import {PaymentInformation} from '@src/models';

export interface PaymentDetailsForm {
  blockedForNonPayment: boolean;
  insuranceProvider: string;
  insuranceId: string;
  insuranceGroup: string;
  hasHealthInsurance: boolean | null;
  hasInsuranceAndUnknownDetails: boolean | null;
  hasMedicaid: boolean | null;
  medicaidFollowUp: {
    name: string;
    email: string;
    phone: string;
  };
}

export const getInitialDetails = (): PaymentDetailsForm => ({
  blockedForNonPayment: false,
  hasHealthInsurance: null,
  hasInsuranceAndUnknownDetails: null,
  insuranceProvider: '',
  insuranceId: '',
  insuranceGroup: '',
  hasMedicaid: null,
  medicaidFollowUp: {
    name: '',
    email: '',
    phone: '',
  },
});

export const fromPaymentInformation = (
  paymentInformation: Optional<PaymentInformation>,
): PaymentDetailsForm =>
  paymentInformation
    .map<PaymentDetailsForm>(pi => ({
      hasHealthInsurance: pi.hasHealthInsurance,
      hasInsuranceAndUnknownDetails: pi.hasInsuranceAndUnknownDetails,
      insuranceProvider: pi.insuranceProvider || '',
      insuranceId: pi.insuranceId || '',
      insuranceGroup: pi.insuranceGroup || '',
      hasMedicaid: pi.hasMedicaid,
      blockedForNonPayment: false,
      creditCardComplete: false,
      medicaidFollowUp: {
        name: '',
        email: '',
        phone: '',
      },
      type: pi.isPrimary ? 'primary' : 'secondary',
    }))
    .orElseGet(getInitialDetails);
