import {useCallback} from 'react';
import {UpdateOnCallPeriodRequest} from '@src/api/OnCallAPI';
import {useApi} from '@src/api/useApi';
import {OnCallPeriod} from '@src/models';

export const useAddGroup = (
  period: OnCallPeriod,
  groupIdToAdd: number | null,
) => {
  const api = useApi();

  return useCallback(() => {
    if (groupIdToAdd === null) {
      return Promise.resolve(period);
    }

    const request: UpdateOnCallPeriodRequest = {
      start: period.start,
      end: period.end,
      selectedCallPools: [...period.callPools.map(g => g.id), groupIdToAdd],
    };

    return api.onCallPeriods(period.id).update(request);
  }, [period, groupIdToAdd, api]);
};
