import React, {useState} from 'react';
import {Controls} from './Controls';
import {getControls} from './functions/getControls';
import {RoomControlProps} from './functions/types';
import {HideableDiv} from './layout';
import {Modals} from './Modals';
import {visitDocumentationToValues} from '@src/components/providerSide/VisitDocumentationForm/functions';
import {UserType} from '@src/models';

export const RoomControls = ({
  completeVisit,
  currentMedicalHistory,
  documentation,
  pauseVisitForCurrentUser,
  scp,
  onAssignProfessional,
  onPresentSCP,
  onDocumentationSave,
  onReconnect,
  showDocumentation,
  toggleDocumentation,
  userType,
  visit: oVisit,
  showButtonText,
  disablePresentSCP,
  room,
}: RoomControlProps) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showFilesList, setShowFilesList] = useState(false);
  const [showVisitInfo, setShowVisitInfo] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);

  const viewDocumentation = () => toggleDocumentation(true);

  const isProfessional = userType === UserType.Professional;
  const visitPresent = oVisit.isPresent();

  if (!visitPresent) {
    return null;
  }

  const visit = oVisit.get();

  const {files = []} = visit;

  const initialValues = documentation.map(visitDocumentationToValues);

  const controls = getControls({
    completeVisit,
    files,
    initialValues,
    seesPatients: isProfessional,
    scp: scp,
    onAssignProfessional,
    onReconnect,
    pauseVisitForCurrentUser,
    setShowFilesList,
    setShowShareModal,
    setShowVisitInfo,
    showFilesList,
    showShareModal,
    showVisitInfo,
    viewDocumentation,
    visit: oVisit,
    setShowUploadModal,
    setShowAssignModal,
    onPresentSCP,
    disablePresentSCP,
    room,
  });

  return (
    <>
      <HideableDiv hidden={showDocumentation}>
        <Controls controls={controls} showButtonText={showButtonText} />
      </HideableDiv>

      <Modals
        isProfessional={isProfessional}
        initialValues={initialValues}
        oVisit={oVisit}
        onAssignProfessional={async id => {
          await onAssignProfessional(id);
          setShowAssignModal(false);
        }}
        onDocumentationSave={onDocumentationSave}
        showDocumentation={showDocumentation}
        toggleDocumentation={toggleDocumentation}
        documentation={documentation}
        setShowAssignModal={setShowAssignModal}
        showAssignModal={showAssignModal}
        showUploadModal={showUploadModal}
        setShowUploadModal={setShowUploadModal}
        showFilesList={showFilesList}
        setShowFilesList={setShowFilesList}
        files={files}
        showVisitInfo={showVisitInfo}
        setShowVisitInfo={setShowVisitInfo}
        showShareModal={showShareModal}
        setShowShareModal={setShowShareModal}
        currentMedicalHistory={currentMedicalHistory}
      />
    </>
  );
};
