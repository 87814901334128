import {Grid, IconButton, useMediaQuery} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import React, {useCallback} from 'react';
import {useHistory} from 'react-router';
import FormNotificationModal from '../../FormNotificationModal';
import {useStyles} from './layout';
import {useApi} from '@src/api/useApi';
import {PatientActionMenu} from '@src/components/shared/PatientActionMenu';
import {CondensedFundamentals} from '@src/components/shared/PatientDashboard/PatientFundamentals/CondensedFundamentals';
import {Button} from '@src/components/ui/form';
import {ClickableIcon} from '@src/components/ui/layout/ClickableIcon';
import {useResources} from '@src/hooks/useResources';
import {AssignedForm, LonelyPatient, Patient} from '@src/models';

type Props = {
  onExpandCard: () => unknown;
  onUpdatePatient: (p: LonelyPatient) => void;
  patient: Patient;
};

export function CondensedCard({onExpandCard, onUpdatePatient, patient}: Props) {
  const history = useHistory();
  const theme = useTheme();
  const api = useApi();

  const [incompleteForms] = useResources<AssignedForm>(
    async () => await api.patient(patient.id).getIncompleteForms(),
    [api, patient],
  );

  const matchesMedium = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const classes = useStyles();

  const requestVisit = useCallback(() => {
    history.push(`/visit-request/${patient.id}`);
    window.scrollTo(0, 0);
  }, [history, patient]);

  return (
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={1}>
        <ClickableIcon icon="angle-right" onClick={onExpandCard} />
      </Grid>
      <Grid item xs>
        <CondensedFundamentals patient={patient} />
      </Grid>

      {matchesMedium && (
        <Grid item xs={2}>
          <Button
            onClick={requestVisit}
            bStyle="outlined"
            isInRow
            className={classes.requestButton}
          >
            Request Visit
          </Button>
        </Grid>
      )}

      <Grid
        item
        xs={1}
        className={!matchesMedium ? classes.menuButtonContainer : ''}
      >
        <FormNotificationModal
          size="2x"
          forms={incompleteForms.getAllOptional().orElse([])}
        />
      </Grid>
      <Grid
        item
        xs={1}
        className={!matchesMedium ? classes.menuButtonContainer : ''}
      >
        <IconButton disableRipple className={classes.menuButton}>
          <PatientActionMenu
            patient={patient}
            updatePatient={onUpdatePatient}
          />
        </IconButton>
      </Grid>
    </Grid>
  );
}
