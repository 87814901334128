import {Avatar, createStyles, makeStyles, Theme} from '@material-ui/core';
import React from 'react';
import {ProviderDetails, ResponsiblePerson, LonelyPatient} from '@src/models';
import {lg} from '@src/components/ui/theme';

export type NamedIndividual =
  | LonelyPatient
  | ProviderDetails
  | ResponsiblePerson;
type Props = {
  condensed?: boolean;
  user: NamedIndividual;
  variant?: 'small';
  style?: Object;
  unknown?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.primary.main,
      fontSize: 16,
      color: theme.palette.primary.contrastText,
      margin: '0  auto 1rem',
    },
    condensedAvatar: {
      backgroundColor: theme.palette.primary.main,
      fontSize: 16,
      color: theme.palette.primary.contrastText,
    },
    small: {
      width: lg,
      height: lg,
    },
  }),
);

const firstName = (u: NamedIndividual) =>
  'preferredFirstName' in u ? u.preferredFirstName : u.firstName ?? '';
const lastName = (u: NamedIndividual) => u.lastName ?? '';

export function UserAvatar({
  condensed = false,
  user,
  style,
  unknown,
  variant,
}: Props) {
  const classes = useStyles();

  return (
    <Avatar
      className={`${variant === 'small' ? classes.small : ''} ${
        condensed ? classes.condensedAvatar : classes.avatar
      }`}
      style={style}
    >
      {unknown && '?'}
      {!unknown && (
        <>
          {firstName(user).charAt(0)}
          {lastName(user).charAt(0)}
        </>
      )}
    </Avatar>
  );
}
