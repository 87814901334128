import {Optional} from '@ahanapediatrics/ahana-fp';
import {useReducer, useRef, useState} from 'react';
import {
  AudioInputInfo,
  AudioOutputInfo,
  VideoInputInfo,
} from '@src/store/reducers/media';

export interface Devices {
  audioOutputs: Array<AudioOutputInfo>;
  audioInputs: Array<AudioInputInfo>;
  videoInputs: Array<VideoInputInfo>;
}

export interface MediaDeviceState {
  audioIn: Optional<AudioInputInfo>;
  audioOut: Optional<AudioOutputInfo>;
  videoIn: Optional<VideoInputInfo>;
}

export interface ChosenDeviceIds {
  audioInId: string;
  audioOutId: string;
  videoInId: string;
}

export const getInitialState = (
  initial: Partial<MediaDeviceState>,
): MediaDeviceState => ({
  audioIn: Optional.of(initial.audioIn),
  audioOut: Optional.of(initial.audioOut),
  videoIn: Optional.of(initial.videoIn),
});

export enum MediaDeviceActions {
  SetAudioIn,
  SetAudioOut,
  SetVideoIn,
  SetDevices,
}

type Action =
  | {
      type: MediaDeviceActions.SetAudioIn;
      device: Optional<AudioInputInfo>;
    }
  | {
      type: MediaDeviceActions.SetAudioOut;
      device: Optional<AudioOutputInfo>;
    }
  | {
      type: MediaDeviceActions.SetVideoIn;
      device: Optional<VideoInputInfo>;
    };

export const mediaDeviceReducer = (
  state: MediaDeviceState,
  action: Action,
): MediaDeviceState => {
  switch (action.type) {
    case MediaDeviceActions.SetAudioIn:
      return {...state, audioIn: Optional.of(action.device)};
    case MediaDeviceActions.SetAudioOut:
      return {...state, audioOut: Optional.of(action.device)};
    case MediaDeviceActions.SetVideoIn:
      return {...state, videoIn: Optional.of(action.device)};
    default:
      throw new Error(`Unknown action: ${action}`);
  }
};

export type Dispatchers = {
  audioIn: (device: Optional<AudioInputInfo>) => void;
  audioOut: (device: Optional<AudioOutputInfo>) => void;
  videoIn: (device: Optional<VideoInputInfo>) => void;
  devices: (devices: Devices) => void;
};

export const useDevices = (
  initial: Partial<MediaDeviceState> = {},
): [Optional<Devices>, MediaDeviceState, Dispatchers] => {
  const [deviceState, dispatch] = useReducer(
    mediaDeviceReducer,
    getInitialState(initial),
  );
  const [devices, setDevices] = useState<Optional<Devices>>(Optional.empty());

  const dispatchers = useRef({
    audioIn: (device: Optional<AudioInputInfo>) =>
      dispatch({type: MediaDeviceActions.SetAudioIn, device}),
    audioOut: (device: Optional<AudioOutputInfo>) =>
      dispatch({type: MediaDeviceActions.SetAudioOut, device}),
    videoIn: (device: Optional<VideoInputInfo>) =>
      dispatch({type: MediaDeviceActions.SetVideoIn, device}),
    devices: (d: Devices) => setDevices(Optional.of(d)),
  });

  return [devices, deviceState, dispatchers.current];
};
