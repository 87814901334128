export const TIME_ZONES = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',

  // Alaska.
  'America/Adak',
  'America/Yakutat',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Anchorage',
  'America/Nome',

  // Arizona.
  'America/Phoenix',

  // Hawaii.
  'Pacific/Honolulu',

  // Idaho.
  'America/Boise',

  // Indiana
  'America/Indiana/Marengo',
  'America/Indiana/Vincennes',
  'America/Indiana/Tell_City',
  'America/Indiana/Petersburg',
  'America/Indiana/Knox',
  'America/Indiana/Winamac',
  'America/Indiana/Vevay',
  'America/Indiana/Indianapolis',

  // Kentucky.
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',

  // Michigan.
  'America/Menominee',

  // North Dakota.
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',

  // Puerto Rico.
  'America/Puerto_Rico',

  // Virgin Islands.
  'America/St_Thomas',
].sort();
