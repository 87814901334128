import {ResponsiblePerson} from '@src/models';
import {AppAPI} from '@src/api/AppAPI';

export function findExistingPerson(
  personEmail: string,
  api: AppAPI,
): Promise<ResponsiblePerson | null> {
  return api
    .responsiblePerson()
    .findByEmail(personEmail)
    .catch(e => null);
}
