import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {CallPool, ProviderDetails} from '@src/models';
import {SetState, useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';

export type ProfessionalSearchResources = {
  details: AsyncData<ProviderDetails>;
  loadingOptions: boolean;
  membershipGroups: AsyncData<CallPool>;
  options: ProviderDetails[];
  value: ProviderDetails | null;
  resetSearch: () => unknown;
  setDetails: SetState<ProviderDetails>;
  setInputValue: (v: string) => unknown;
  setMembershipGroups: SetState<CallPool>;
  setOpen: (v: boolean) => unknown;
  setOptions: (v: ProviderDetails[]) => unknown;
  setValue: (v: ProviderDetails | null) => unknown;
};
const Noop = () => {};
export function useProfessionalSearch({
  onDetailsUpdated = Noop,
  onCallPoolsUpdated = Noop,
  includeUnapprovedMembershipGroups = false,
}: {
  onCallPoolsUpdated?: (c: CallPool[] | null) => unknown;
  onDetailsUpdated?: (d: ProviderDetails | null) => unknown;
  includeUnapprovedMembershipGroups?: boolean;
}): ProfessionalSearchResources {
  const api = useApi();
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<ProviderDetails | null>(null);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<ProviderDetails[]>([]);
  const [details, reloadDetails, setDetails, resetDetails] = useResources<
    ProviderDetails
  >(
    () =>
      api
        .providerDetails(value!.id)
        .get()
        .then(d => {
          onDetailsUpdated(d);
          return d;
        }),
    [api, value, onDetailsUpdated],
    {requestGate: () => value !== null},
  );
  const [
    membershipGroups,
    reloadMembershipGroups,
    resetMembershipGroups,
    setMembershipGroups,
  ] = useResources<CallPool>(
    () =>
      api
        .providerDetails(value!.id)
        .getMemberGroups({includeUnapproved: includeUnapprovedMembershipGroups})
        .then(cps => {
          onCallPoolsUpdated(cps);
          return cps;
        }),
    [api, value, onCallPoolsUpdated],
    {requestGate: () => value !== null},
  );
  const resetSearch = useCallback(() => {
    resetDetails();
    resetMembershipGroups();
    setInputValue('');
    setLoadingOptions(false);
    setOpen(false);
    setOptions([]);
    setValue(null);
  }, [
    resetDetails,
    resetMembershipGroups,
    setInputValue,
    setLoadingOptions,
    setOpen,
    setOptions,
    setValue,
  ]);
  useEffect(() => {
    if (inputValue.length < 3) {
      return () => {};
    }
    setLoadingOptions(true);
    api
      .provider()
      .search(inputValue, true)
      .then(setOptions)
      .catch(e => {
        console.error(e);
      })
      .then(() => {
        setLoadingOptions(false);
      });
    return () => {
      setLoadingOptions(false);
    };
  }, [inputValue, api]);
  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  useEffect(() => {
    if (value) {
      reloadDetails();
      reloadMembershipGroups();
    } else {
      resetDetails();
      resetMembershipGroups();
      onDetailsUpdated(null);
      onCallPoolsUpdated(null);
    }
  }, [
    onCallPoolsUpdated,
    onDetailsUpdated,
    reloadDetails,
    reloadMembershipGroups,
    resetDetails,
    resetMembershipGroups,
    value,
  ]);
  return useMemo(
    () => ({
      details,
      loadingOptions,
      membershipGroups,
      options,
      value,
      resetSearch,
      setDetails,
      setInputValue,
      setMembershipGroups,
      setOpen,
      setOptions,
      setValue,
    }),
    [
      details,
      loadingOptions,
      membershipGroups,
      options,
      value,
      resetSearch,
      setDetails,
      setInputValue,
      setMembershipGroups,
      setOpen,
      setOptions,
      setValue,
    ],
  );
}
