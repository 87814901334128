import React, {useCallback, useState} from 'react';
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Optional} from '@ahanapediatrics/ahana-fp';
import clsx from 'clsx';
import {UnaddableGroupModal} from './UnaddableGroupModal';
import {useStyles} from '@src/components/ui/molecules/lists/ActionList/styles';
import {CallPool, OnCallPeriod} from '@src/models';
import {usePageContext} from '@src/components/providerSide/providerGroups/ProviderGroupRosterPage';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {
  flashSuccess,
  flashError,
} from '@src/components/shared/notifications/flash';
import {HorizontalSection} from '@src/components/ui/atoms/section/HorizontalSection';
import {LightHeading} from '@src/components/ui/layout/text/heading/LightHeading';
import {ConfirmModal} from '@src/components/ui/layout/ConfirmModal';
import {useAddGroup} from '@src/hooks/providerGroups/periods/useAddGroup';
import {getProvidersNotAuthorizedForGroups} from '@src/util/callPools/shifts/providerGroupAuthorization';
import {ProviderDetails} from '@src/models/ProviderDetails';
import {primary} from '@src/components/ui/theme';
import {LoadingIndeterminate} from '@src/components/ui/atoms/progressBarsAndIndicators/LoadingIndeterminate';
import {useIconStyles} from '@src/components/ui/atoms/buttonsAndIcons/styles';

type Props = {
  period: OnCallPeriod;
};

export function AvailableGroups({period}: Props) {
  const {
    managedGroups,
    monthTable: {reloadPeriodsMappedToDaysOfSelectedMonth},
  } = usePageContext();

  const classes = useStyles();
  const iconStyles = useIconStyles();

  const currentGroups = period.callPools;

  const availableGroups = managedGroups
    .getAllOptional()
    .orElse([])
    .filter(g => {
      const inCurrent = currentGroups.some(pg => pg.id === g.id);
      return !inCurrent;
    });

  const [groupIdToAdd, setGroupIdToAdd] = useState<number | null>(null);
  const addGroup = useAddGroup(period, groupIdToAdd);
  const [
    unaddableGroupWithProviders,
    setUnaddableGroupWithProviders,
  ] = useState<{
    group: CallPool;
    unauthorizedProviders: Optional<ProviderDetails>[];
  } | null>(null);

  const resetUnaddableGroupModal = useCallback(() => {
    setUnaddableGroupWithProviders(null);
  }, [setUnaddableGroupWithProviders]);

  const onConfirmAdd = useCallback(async () => {
    addGroup()
      .then(() => {
        setGroupIdToAdd(null);
        reloadPeriodsMappedToDaysOfSelectedMonth();
        flashSuccess('Group added.');
      })
      .catch(() => {
        flashError('Oops! Something went wrong update a Group for this Shift.');
      })
      .finally(() => {
        setGroupIdToAdd(null);
      });
  }, [addGroup, reloadPeriodsMappedToDaysOfSelectedMonth]);

  return (
    <HorizontalSection lastSection>
      <Grid item xs={12}>
        <LightHeading>Available</LightHeading>
      </Grid>
      <Grid item xs={12}>
        <LoadingIndeterminate active={!managedGroups.isLoaded()}>
          {availableGroups.length > 0 ? (
            <TableContainer
              className={classes.tableContainer}
              component={Paper}
            >
              <Table>
                <TableBody>
                  {availableGroups.map(g => {
                    const unauthorizedProviders = getProvidersNotAuthorizedForGroups(
                      [g],
                      period,
                    );

                    return (
                      <TableRow key={g.id}>
                        <TableCell>{g.nameForProviders}</TableCell>
                        <TableCell>
                          <IconButton
                            className={`${iconStyles.clickableIcon} ${classes.plusIcon}`}
                          >
                            {unauthorizedProviders.length > 0 ? (
                              <FontAwesomeIcon
                                icon={['fas', 'question']}
                                size="sm"
                                color={primary}
                                className={iconStyles.clickableIcon}
                                onClick={() => {
                                  setUnaddableGroupWithProviders({
                                    group: g,
                                    unauthorizedProviders,
                                  });
                                }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={['fas', 'plus-circle']}
                                size="sm"
                                onClick={() => {
                                  setGroupIdToAdd(g.id);
                                }}
                                className={clsx(
                                  iconStyles.clickableIcon,
                                  classes.plusIcon,
                                )}
                              />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <ParagraphText>
              This shift currently has no groups that can be added to it.
            </ParagraphText>
          )}
        </LoadingIndeterminate>
      </Grid>
      {unaddableGroupWithProviders !== null && (
        <UnaddableGroupModal
          reset={resetUnaddableGroupModal}
          unauthorizedProviderDetails={
            unaddableGroupWithProviders?.unauthorizedProviders
          }
          group={unaddableGroupWithProviders.group}
        />
      )}

      <ConfirmModal
        onConfirm={onConfirmAdd}
        text="Confirming will add this Group to the Shift."
        show={groupIdToAdd !== null}
        onHide={() => {
          setGroupIdToAdd(null);
        }}
        onCancel={() => {
          setGroupIdToAdd(null);
        }}
        cancelText="Cancel"
        confirmText="Add Group"
        titleText="Add Group"
      />
    </HorizontalSection>
  );
}
