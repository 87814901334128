import {Optional, RemoteDataStatus} from '@ahanapediatrics/ahana-fp';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import {format} from 'date-fns';
import React, {useEffect} from 'react';
import {useStyles} from './layout';
import {StepProps} from '.';
import {useApi} from '@src/api/useApi';
import {PatientGuardianList} from '@src/components/shared/forms/CreateVisitForm/layout';
import {Alert} from '@src/components/ui/layout/Alert';
import {
  RequestHeader,
  RequestInstructions,
} from '@src/components/ui/layout/NewThingRequest';
import {useAsync, useUser} from '@src/hooks';
import {FlowProps} from '@src/hooks/useSteps';
import {PatientRelationship} from '@src/models';
import {providerAndQualification} from '@src/util/provider';
import {getCallPoolName} from '@src/util/callPools/getCallPoolName';
import {isNothing, isSomething} from '@src/util/typeTests';

type Props = StepProps & FlowProps;

export function ConfirmationStep({
  blankForms,
  callPool,
  details: pcpInfo,
  endpointId,
  patient,
  selectedBlankFormIds,
  setNextAvailable,
  startDate,
  startTime,
  visitNotes,
  visitType,
}: Props) {
  const api = useApi();
  const classes = useStyles();
  const [guardianships, setGuardianships] = useAsync<PatientRelationship>();
  const [, userType] = useUser();

  const patientIsUser = Optional.of(patient)
    .flatMap(p => p.selfRelationship)
    .isPresent();

  const selectedBlankForms = blankForms.filter(({id}) =>
    selectedBlankFormIds.value.includes(id),
  );

  useEffect(() => {
    if (guardianships.is(RemoteDataStatus.NotAsked)) {
      api
        .patient(patient?.id ?? 0)
        .getGuardianships()
        .then(setGuardianships);
    }
  }, [api, setGuardianships, guardianships, patient]);

  useEffect(() => {
    setNextAvailable(true);
  }, [setNextAvailable]);

  const guardianList = guardianships
    .map(g => g.person)
    .getAllOptional()
    .orElse([]);

  const endpoints = callPool?.endpoints ?? [];
  const endpoint = endpoints.find(e => e.id === endpointId.value);

  return (
    <>
      <RequestHeader>Confirm</RequestHeader>
      <RequestInstructions>
        <p>Please review the information below before creating this visit.</p>
      </RequestInstructions>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h3" className={classes.visitDetail}>
            {visitType.value === 'Scheduled'
              ? 'This is a Scheduled Visit'
              : 'This is an On-Demand Visit'}{' '}
            for {patient ? patient.fullName : 'this Patient'}.
          </Typography>
          {visitType.value === 'Scheduled' && (
            <Typography variant="h3" className={classes.visitDetail}>
              This visit is at {format(startTime.value!, 'h:mm bbb')} on{' '}
              {format(startDate.value!, 'LLLL do')}.
            </Typography>
          )}
          {pcpInfo
            .map(p => (
              <Typography variant="h3" className={classes.visitDetail}>
                {providerAndQualification(p)} will handle this Visit.
              </Typography>
            ))
            .orNull()}
          {visitNotes.value.trim().length > 0 && (
            <>
              <Typography variant="h3">You included these notes:</Typography>
              <Typography variant="h4" className={classes.visitDetail}>
                {visitNotes.value}
              </Typography>
            </>
          )}
          {!isNothing(callPool) && (
            <Typography variant="h3" className={classes.visitDetail}>
              This Visit will be placed into the{' '}
              {getCallPoolName(callPool, userType)} Provider Group
            </Typography>
          )}
          {isSomething(endpoint) && (
            <Typography variant="h3" className={classes.visitDetail}>
              It will take place at the '{endpoint.name}' location.
            </Typography>
          )}
          {selectedBlankForms.length > 0 && (
            <>
              <Typography variant="h3" className={classes.visitDetail}>
                The following forms will be assigned to the patient:
              </Typography>
              <List>
                {selectedBlankForms.map(blankForm => (
                  <ListItem key={blankForm.id}>
                    <ListItemText
                      primary={blankForm.name}
                      secondary={blankForm.description.orNull()}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Grid>
        {!patientIsUser && (
          <Grid item>
            <Alert aStyle="warn">
              Please make sure that the guardian who will be taking{' '}
              {patient!.firstName} to this visit is listed below. If not, please
              go back and &quot;Add Guardian&quot; so they will be able to
              log-in for {patient!.firstName}
              &apos;s visit.
              <PatientGuardianList>
                {guardianList.length === 0 && (
                  <li>This Patient has no Guardians</li>
                )}
                {[...guardianList]
                  .sort((a, b) => a.lastName.localeCompare(b.lastName))
                  .map(guardian => (
                    <li key={guardian.id}>
                      {guardian.fullName}
                      {guardian.email.map(e => `<${e}>`).orElse('-')}
                    </li>
                  ))}
              </PatientGuardianList>
            </Alert>
          </Grid>
        )}
      </Grid>
    </>
  );
}
