import {differenceInSeconds} from 'date-fns';
import * as React from 'react';
import {useFormContext, NestedValue, Controller} from 'react-hook-form';
import styled from 'styled-components';
import {mixed, ObjectSchema, TestContext} from 'yup';
import {isEmpty} from 'lodash';
import {SystemsInput} from '../inputs/SystemsInput';
import {emptyRosValues} from '../functions';
import {FormSection} from './FormSection';
import {SystemCode, SYSTEM_CODES} from './systems';
import {ErrorMessage} from '@src/components/ui/form/ErrorMessage';
import {lighterGrey, md, xxs} from '@src/components/ui/theme';
import {LonelyPatient, ReviewOfSystem} from '@src/models';
import {yBoolean, yObject, yString} from '@src/schema/types';

type YupTest<V> = (this: TestContext, val: V) => boolean;

function yupTest<V>(testFn: YupTest<V>, message: string) {
  /**
   * @this the yup TestContext
   */
  return function(this: TestContext, val: V) {
    if (testFn.call(this, val)) {
      return true;
    }

    return this.createError({
      path: this.path,
      message,
    });
  };
}

const reviewIsDone = (r: ReviewOfSystem | undefined) =>
  typeof r?.positive === 'boolean';

const testGeneralROS = yupTest<Record<SystemCode, ReviewOfSystem>>(
  values => reviewIsDone(values.general),
  'Please document General and two additional reviews of systems.',
);

const sufficientReviews = yupTest<Record<SystemCode, ReviewOfSystem>>(
  values => Object.values(values).filter(reviewIsDone).length >= 3,
  'Please document General and two additional reviews of systems.',
);

const checkForRemarks = yupTest<ReviewOfSystem>(
  value => !reviewIsDone(value) || value.remarks.length > 1,
  'Some remarks are required for a review to be complete',
);

const reviewSchema = yObject<ReviewOfSystem>({
  code: mixed(),
  remarks: yString,
  positive: yBoolean.nullable(true),
}).when('$isSigning', (isSigning: Boolean, s: ObjectSchema<ReviewOfSystem>) =>
  isSigning ? s.test('remarks-given', '', checkForRemarks) : s,
);

export const rosSchema = {
  reviewOfSystems: yObject<Record<SystemCode, ReviewOfSystem>>({
    general: reviewSchema,
    skin: reviewSchema,
    heent: reviewSchema,
    hematological: reviewSchema,
    genitourinary: reviewSchema,
    cardiovascular: reviewSchema,
    musculoskeletal: reviewSchema,
    neuro_psych: reviewSchema,
    respiratory: reviewSchema,
    gastrointestinal: reviewSchema,
  })
    .when(
      '$isSigning',
      (
        isSigned: boolean,
        s: ObjectSchema<Record<SystemCode, ReviewOfSystem>>,
      ) => (isSigned ? s.test('sufficient-reviews', '', sufficientReviews) : s),
    )
    .when(
      '$isSigning',
      (
        isSigned: boolean,
        s: ObjectSchema<Record<SystemCode, ReviewOfSystem>>,
      ) => (isSigned ? s.test('general-review', '', testGeneralROS) : s),
    ),
};

export const ReviewOfSystemsLabels = {
  reviewOfSystems: 'Review of systems',
};

const AUTO_REVIEWS: Array<Record<SystemCode, string>> = [
  {
    general: 'No fever or weight loss',
    neuro_psych: 'No abnormal motor movements or limpness',
    heent: 'No discharge or icterus; no rhinorrhea or oral lesions',
    respiratory: 'No cough or difficulty breathing',
    cardiovascular: 'No mottling or rapid heart rate',
    gastrointestinal: 'No vomiting or change in stools',
    genitourinary: 'No genital swelling or hematuria',
    skin: 'No rashes, no jaundice',
    musculoskeletal: 'No joint or muscle swelling or tenderness',
    hematological: 'No bruising or bleeding',
  },
  {
    general: 'No fever or weight loss',
    neuro_psych: 'No abnormal motor movements, change in behavior',
    heent:
      'No discharge or icterus; no ear pain/tugging, rhinorrhea, or oral lesions ',
    respiratory: 'No cough or difficulty breathing',
    cardiovascular: 'No mottling or rapid heart rate',
    gastrointestinal: 'No vomiting or diarrhea',
    genitourinary: 'No hematuria or change in urine output',
    skin: 'No rashes, no scratching/itching',
    musculoskeletal: 'No joint or muscle swelling or tenderness',
    hematological: 'No bruising or bleeding',
  },
  {
    general: 'No fever or weight loss',
    neuro_psych: 'No headache or abnormal movements',
    heent: 'No eye pain or discharge; no ear pain, rhinorrhea, or sore throat',
    respiratory: 'No cough or shortness of breath',
    cardiovascular: 'No palpitations or chest pain',
    gastrointestinal: 'No vomiting, diarrhea, or abdominal pain',
    genitourinary: 'No dysuria or hematuria',
    skin: 'No rashes, no itching',
    musculoskeletal: 'No muscle, bone, or joint tenderness',
    hematological: 'No bruising or bleeding',
  },
  {
    general: 'No fever or weight loss',
    neuro_psych:
      'No headache, weakness or numbness; no mood changes, feelings of depression',
    heent: 'No eye pain or discharge; no ear pain, rhinorrhea, or sore throat',
    respiratory: 'No cough or shortness of breath',
    cardiovascular: 'No palpitations or chest pain',
    gastrointestinal: 'No vomiting, diarrhea, or abdominal pain',
    genitourinary: 'No dysuria or hematuria or discharge ',
    skin: 'No rashes, no itching',
    musculoskeletal: 'No muscle, bone, or joint tenderness',
    hematological: 'No bruising or bleeding',
  },
];
const autoPopulator = (patient: LonelyPatient) => (code: SystemCode) => {
  const {dob} = patient;
  const ageInSeconds = differenceInSeconds(new Date(), dob);
  let tier = 3;
  if (ageInSeconds <= 2419200) {
    tier = 0;
  } else if (ageInSeconds <= 126230400) {
    tier = 1;
  } else if (ageInSeconds <= 347155200) {
    tier = 2;
  }

  return AUTO_REVIEWS[tier][code] || '';
};

export type ReviewOfSystemsValues = {
  reviewOfSystems: Record<
    SystemCode,
    NestedValue<{remarks: string; positive: boolean | null}>
  >;
};

export const defaultReviewOfSystemsValues = (): ReviewOfSystemsValues => ({
  reviewOfSystems: emptyRosValues(),
});

export type ReviewOfSystemsSectionType = {
  patient: LonelyPatient;
};

const SystemsList = styled.div`
  margin-top: ${md};
  & > div:nth-child(even) {
    background-color: ${lighterGrey};
    border-radius: ${xxs};
  }
`;

export function ReviewOfSystemsSection({patient}: ReviewOfSystemsSectionType) {
  const {errors, setValue, control} = useFormContext<ReviewOfSystemsValues>();
  const rosErrors = errors.reviewOfSystems ?? {};
  //@ts-ignore
  const errorMessage = rosErrors?.message ?? '';

  return (
    <FormSection
      title={ReviewOfSystemsLabels.reviewOfSystems}
      className="systems"
    >
      <>
        {!isEmpty(errorMessage) && (
          //  This class is manually inserted here in order to accommodate scrolling
          //  form errors into view. Since this is a custom error, unless we add
          //  this class, our document.querySelector call won't find it.
          <span className="Mui-error">
            <ErrorMessage
              //@ts-ignore
              message={errorMessage}
            />
          </span>
        )}

        <SystemsList>
          {SYSTEM_CODES.map(code => (
            <Controller
              key={code}
              control={control}
              name={`reviewOfSystems.${code}`}
              render={({value}) => (
                <SystemsInput
                  key={code}
                  code={code}
                  value={value}
                  error={rosErrors[code]?.message}
                  getAgeAppropriateNegativeSummary={autoPopulator(patient)}
                  placeholder="Remarks"
                  onChange={val => {
                    setValue(`reviewOfSystems.${code}`, val);
                  }}
                />
              )}
            />
          ))}
        </SystemsList>
      </>
    </FormSection>
  );
}
