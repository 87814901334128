import styled from 'styled-components';
import {primaryLight, sm, xs, xxs} from '../../../ui/theme';

export const VideoControls = styled.div`
  text-align: center;
`;

export const RoomControlsContainer = styled.div`
  height: 75%;
  padding: ${xxs};

  button {
    margin: ${xs} 0;
  }
`;

export const SaveComplete = styled.div`
  text-align: center;
  padding: ${xxs};
  background-color: ${primaryLight};
`;

export const SidebarDocumentation = styled.div<{hidden: boolean}>`
  height: 100%;
  overflow-y: scroll;
  padding: ${sm};
  white-space: normal;
`;

export const HideableDiv = styled.div<{hidden: boolean}>`
  display: ${props => (props.hidden ? 'none' : '')};
`;

export const Separator = styled.div`
  height: ${xxs};
  margin: ${sm} 0;
`;
