import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {capitalize} from 'lodash';
import React, {ChangeEvent} from 'react';
import {AddToCallPoolButton} from './AddToCallPoolButton';
import {CallPoolsCard} from './CallPoolsCard';
import {ProviderCard} from './ProviderCard';
import {ProviderDetails} from '@src/models';
import {ProviderDetailsId} from '@src/models/ProviderDetails';
import {isNothing} from '@src/util/typeTests';
import {NAMES} from '@src/components/ui/layout/text/names';
import {ProfessionalSearchResources} from '@src/hooks/people/professionals/useProfessionalSearch';
import {useApi} from '@src/api/useApi';
import {PageSection} from '@src/components/ui/atoms/section/PageSection';

type ViewProviderProps = {
  showOnlyProviderCard?: boolean;
  emailLabel?: string;
  professionalSearchResources: ProfessionalSearchResources;
};

// eslint-disable-next-line import/no-unused-modules
export default function SearchProvider({
  showOnlyProviderCard,
  emailLabel,
  professionalSearchResources,
}: ViewProviderProps) {
  const {
    setOpen,
    value,
    setValue,
    setOptions,
    options,
    setInputValue,
    loadingOptions,
    details,
    membershipGroups,
    setMembershipGroups,
  } = professionalSearchResources;

  const api = useApi();

  const groups = membershipGroups.getAllOptional().orElse([]);

  const onUpdate = () => {
    setMembershipGroups();
    api
      .providerDetails(value!.id)
      .getMemberGroups({includeUnapproved: true})
      .then(setMembershipGroups);
  };

  // TODO:
  // Why aren't we using <SearchByProvider /> here?
  // We should have one component for this.
  // It will take time to combine and test these so it should have
  // its own separate maintenance ticket.
  return (
    <>
      <Autocomplete
        style={{width: 400}}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, v) => option.id === v.id}
        getOptionLabel={option => `${option.firstName} ${option.lastName}`}
        value={value}
        onChange={(
          event: ChangeEvent<{}>,
          newValue: ProviderDetails | null,
        ) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        loading={loadingOptions}
        renderInput={params => (
          <TextField
            {...params}
            label={`Please start typing the ${capitalize(
              NAMES.provider,
            )}'s name`}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingOptions ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {!isNothing(details.getOptional().orNull()) && (
        <>
          <PageSection
            title={`${capitalize(NAMES.professional)} Information`}
            fullWidth
          >
            <ProviderCard details={details} emailLabel={emailLabel} />
          </PageSection>
          {!showOnlyProviderCard && (
            <PageSection
              title={`${capitalize(NAMES.callPool)} Information`}
              fullWidth
            >
              {groups.length === 0 ? (
                <AddToCallPoolButton
                  buttonLabel="Add to Call Pool"
                  detailsId={details
                    .getOptional()
                    .property('id', 0 as ProviderDetailsId)}
                  onUpdated={onUpdate}
                />
              ) : (
                <CallPoolsCard
                  detailsId={details
                    .getOptional()
                    .property('id', 0 as ProviderDetailsId)}
                  callPools={membershipGroups.getAllOptional()}
                  onUpdated={onUpdate}
                />
              )}
            </PageSection>
          )}
        </>
      )}
    </>
  );
}
