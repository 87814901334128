import React from 'react';
import {StepProps} from '..';
import {InsuranceView} from './InsuranceView';
import {RequestHeader} from '@src/components/ui/layout/NewThingRequest';
import {defaultPaymentRules} from '@src/models';

type Props = StepProps;

export function InsuranceStep({
  onUpdatePatient,
  patient,
  setNextAvailable,
  rules,
}: Props) {
  const {payments = defaultPaymentRules} = rules;

  return (
    <>
      <RequestHeader>Insurance</RequestHeader>

      <InsuranceView
        patient={patient}
        onAddOrEdit={onUpdatePatient}
        setNextAvailable={setNextAvailable}
        insuranceControl={payments.insurance}
        creditCardControl={payments.creditCard}
      />
    </>
  );
}
