import React, {useState, useEffect} from 'react';
import {IconDefinition, IconProp} from '@fortawesome/fontawesome-svg-core';
import {faSignalSlash} from '@fortawesome/pro-regular-svg-icons/faSignalSlash';
import {faSignal1} from '@fortawesome/pro-regular-svg-icons/faSignal1';
import {faSignal2} from '@fortawesome/pro-regular-svg-icons/faSignal2';
import {faSignal3} from '@fortawesome/pro-regular-svg-icons/faSignal3';
import {faSignal4} from '@fortawesome/pro-regular-svg-icons/faSignal4';
import {faSignal} from '@fortawesome/pro-regular-svg-icons/faSignal';
import {useTheme} from '@material-ui/core/styles';
import {Room} from 'twilio-video';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {VideoChatEvents} from '@src/events';
import {useWindowEvent} from '@src/hooks';
import {isNothing} from '@src/util/typeTests';
import {
  NetworkQualityData,
  handleNetworkQualityChange,
  NetworkQualityDataSetters,
} from '@src/util/videoChat';

type Props = {
  isLocal: boolean;
  room: Room | null;
  visitId: number;
  networkQualityData: NetworkQualityData;
  networkQualityDataSetters: NetworkQualityDataSetters;
};

export function NetworkIndicator({
  isLocal,
  room,
  visitId,
  networkQualityData,
  networkQualityDataSetters,
}: Props) {
  const [isUnstable, setIsUnstable] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (isNothing(room)) {
      return;
    }

    let intervalNumber: number | null = null;

    if (isLocal) {
      intervalNumber = window.setInterval(() => {
        handleNetworkQualityChange({
          visitId,
          participant: room.localParticipant,
          networkQualityData: {
            networkQualityStats: room.localParticipant.networkQualityStats,
            networkQualityLevel: room.localParticipant.networkQualityLevel,
          },
          networkQualityDataSetters,
          isLocal,
        });
      }, 600);
    }

    () => {
      if (intervalNumber !== null) {
        clearInterval(intervalNumber);
      }
    };
  }, []);

  useWindowEvent(VideoChatEvents.UnstableNetwork, () => {
    if (!isUnstable && isLocal) {
      setIsUnstable(true);
    }
  });

  useWindowEvent(VideoChatEvents.StableNetwork, () => {
    if (isUnstable && isLocal) {
      setIsUnstable(false);
    }
  });

  const icons: {[key: number]: IconDefinition} = {
    0: faSignalSlash,
    1: faSignal1,
    2: faSignal2,
    3: faSignal3,
    4: faSignal4,
    5: faSignal,
  };

  const {networkQualityLevel} = networkQualityData;

  const networkIcon =
    networkQualityLevel === null ? faSignalSlash : icons[networkQualityLevel];

  const networkIconColor =
    networkQualityLevel === null
      ? theme.palette.error.main
      : networkQualityLevel < 3 && networkQualityLevel > 0
      ? theme.palette.warning.main
      : theme.palette.common.white;

  return (
    <div
      style={{
        position: 'absolute',
        display: 'inline-block',
        textAlign: 'center',
        top: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      }}
    >
      <div
        style={{
          display: 'inline-block',
          marginLeft: '4px',
          float: 'right',
        }}
      >
        <FontAwesomeIcon
          icon={networkIcon as IconProp}
          size="1x"
          fixedWidth
          style={{
            float: 'right',
            color: networkIconColor,
          }}
        />
      </div>
      {isUnstable && (
        <div
          style={{
            marginLeft: '4px',
            float: 'left',
            color: theme.palette.common.white,
          }}
        >
          Your network connection is unstable
        </div>
      )}
    </div>
  );
}
