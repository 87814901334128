import {Optional} from '@ahanapediatrics/ahana-fp';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Grid} from '@material-ui/core';
import {
  getInitialDetails,
  fromPaymentInformation,
  PaymentDetailsForm,
} from '../PaymentDetails';
import EditInsuranceModal from './EditInsuranceModal';
import {PaymentInfoDescription} from './PaymentInfoDescription';
import {
  FinancialSummaryBox,
  SummarySectionContent,
  SummarySectionTitle,
  SummaryTitle,
} from '@src/components/ui/layout/FinancialSummaryBox';
import {useApi} from '@src/api/useApi';
import {Button, AsyncActionButton} from '@src/components/ui/form';
import {useReference} from '@src/hooks/useReference';
import {useConjugate} from '@src/hooks/useConjugate';
import {InsuranceType, Patient, PaymentInformation} from '@src/models';

type Props = {
  autoOpen?: boolean;
  editable?: boolean;
  primary: Optional<PaymentInformation>;
  secondary: Optional<PaymentInformation>;
  patient: Optional<Patient>;
  onInsuranceUpdated: () => unknown;
};

const noInsurance = Object.freeze({
  hasHealthInsurance: false,
  insuranceProvider: '',
  insuranceId: '',
  insuranceGroup: '',
  hasMedicaid: false,
  medicaidEmail: '',
  frontOfCard: '',
  backOfCard: '',
  clearCard: false,
  skipCreditCard: false,
  stripeToken: '',
});

function InsuranceSummary({
  autoOpen = false,
  editable = false,
  primary,
  secondary,
  patient,
  onInsuranceUpdated,
}: Props) {
  const api = useApi();
  const r = useReference(patient);
  const c = useConjugate(patient);
  const [updating, setUpdating] = useState({primary: false, secondary: false});
  const [editMode, setEditMode] = useState<'primary' | 'secondary' | 'none'>(
    'none',
  );
  const [details, setDetails] = useState<PaymentDetailsForm>(
    getInitialDetails(),
  );

  const autoOpened = useRef(false);

  const primaryAsked = primary.isPresent();

  const hasInsuranceAndUnknownDetails = primary.property(
    'hasInsuranceAndUnknownDetails',
    false,
  );
  const hasPrimary = primary.property('hasHealthInsurance', false);
  const hasSecondary = secondary.property('hasHealthInsurance', false);

  useEffect(() => {
    if (!primary.isPresent() && autoOpen && !autoOpened.current) {
      setEditMode('primary');
      autoOpened.current = true;
    }
  }, [primary, autoOpen]);

  const removeInsurance = useCallback(
    (types: InsuranceType[]) => () =>
      types.map(insuranceType => {
        setUpdating(u => ({...u, [insuranceType]: true}));
        return api
          .patient(patient.property('id', 0))
          .updatePaymentInformation(noInsurance, {
            primary: insuranceType === 'primary',
          })
          .then(onInsuranceUpdated)
          .then(() => setUpdating(u => ({...u, [insuranceType]: false})));
      }),
    [api, onInsuranceUpdated, patient],
  );

  return (
    <FinancialSummaryBox>
      <SummaryTitle>Insurance Summary</SummaryTitle>

      <SummarySectionContent>
        {!hasPrimary && !hasInsuranceAndUnknownDetails && (
          <>
            {primaryAsked ? (
              <>
                <SummarySectionTitle>Primary Insurance</SummarySectionTitle>
                <PaymentInfoDescription
                  patient={patient}
                  type="primary"
                  info={primary}
                />
              </>
            ) : (
              <SummarySectionContent>
                At the moment, we don't have any of {r('poss')} insurance
                information.
              </SummarySectionContent>
            )}
            <Grid container direction="row" spacing={1} xs={12}>
              <Grid item>
                <Button
                  bSize="small"
                  onClick={() => {
                    setDetails({
                      ...getInitialDetails(),
                      hasHealthInsurance: true,
                    });
                    setEditMode('primary');
                  }}
                >
                  {r('Nom', {first: true})} {c('have')} insurance
                </Button>
              </Grid>
              {(!primaryAsked || hasPrimary) && (
                <Grid item>
                  <Button
                    bSize="small"
                    bStyle="primary"
                    onClick={removeInsurance(['primary', 'secondary'])}
                  >
                    {r('Nom', {first: true})} {c('do')}n't have insurance
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
        {hasInsuranceAndUnknownDetails && (
          <>
            <PaymentInfoDescription
              patient={patient}
              type="unknownDetails"
              info={primary}
            />
            {editable && (
              <Grid container direction="row" spacing={1} xs={12}>
                <Grid item>
                  <Button
                    disabled={updating.primary}
                    bSize="small"
                    onClick={() => {
                      setDetails(fromPaymentInformation(primary));
                      setEditMode('primary');
                    }}
                  >
                    Complete Primary Insurance
                  </Button>
                </Grid>
                <Grid item>
                  <AsyncActionButton
                    disabled={updating.secondary}
                    actionInProgress={updating.primary}
                    actionWord={`${r('Nom', {
                      first: true,
                    })} no longer ${c('have')} insurance`}
                    actionInProgressWord="Updating"
                    bSize="small"
                    bStyle="primary"
                    onClick={removeInsurance(['primary', 'secondary'])}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
        {hasPrimary && (
          <>
            <PaymentInfoDescription
              patient={patient}
              type="primary"
              info={primary}
            />
            {editable && (
              <Grid container direction="row" spacing={1} xs={12}>
                <Grid item>
                  <Button
                    disabled={updating.primary}
                    bSize="small"
                    onClick={() => {
                      setDetails(fromPaymentInformation(primary));
                      setEditMode('primary');
                    }}
                  >
                    Edit Primary Insurance
                  </Button>
                </Grid>
                <Grid item>
                  <AsyncActionButton
                    disabled={updating.secondary}
                    actionInProgress={updating.primary}
                    actionWord={`${r('Nom', {
                      first: true,
                    })} no longer ${c('have')} insurance`}
                    actionInProgressWord="Updating"
                    bSize="small"
                    bStyle="primary"
                    onClick={removeInsurance(['primary', 'secondary'])}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
      </SummarySectionContent>
      {hasPrimary && (
        <>
          <SummarySectionTitle>Secondary Insurance</SummarySectionTitle>
          <SummarySectionContent>
            {hasSecondary && (
              <PaymentInfoDescription
                patient={patient}
                type="secondary"
                info={secondary}
              />
            )}
            {editable && (
              <Grid container direction="row" spacing={1} xs={12}>
                <Grid item>
                  <Button
                    bSize="small"
                    disabled={updating.secondary}
                    onClick={() => {
                      setDetails({
                        ...fromPaymentInformation(secondary),
                        hasHealthInsurance: true,
                      });
                      setEditMode('secondary');
                    }}
                  >
                    {hasSecondary
                      ? 'Edit Secondary Insurance'
                      : 'Add Secondary Insurance'}
                  </Button>
                </Grid>
                {hasSecondary && (
                  <Grid item>
                    <AsyncActionButton
                      disabled={updating.primary}
                      actionInProgress={updating.secondary}
                      actionWord={`${r('Nom', {
                        first: true,
                      })} no longer ${c('have')} secondary insurance`}
                      actionInProgressWord="Updating"
                      bSize="small"
                      bStyle="primary"
                      onClick={removeInsurance(['secondary'])}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </SummarySectionContent>
        </>
      )}

      <EditInsuranceModal
        details={details}
        onClose={() => setEditMode('none')}
        patient={patient.get()}
        type={editMode}
        onUpdateInsurance={onInsuranceUpdated}
      />
    </FinancialSummaryBox>
  );
}

export default InsuranceSummary;
