import React from 'react';
import {usePageContext} from '..';
import {HistoryBox} from '@src/components/shared/PatientDashboard/SCP/informationBoxes/HistoryBox';
import {userCanEditMedicalHistoryField} from '@src/util/sharedCarePlan/permissions';
import {useUser} from '@src/hooks';

export function MedicationOverview() {
  const [user] = useUser();

  const {
    currentMedicalHistory,
    setCurrentMedicalHistory,
    patient,
    reviewMode,
    selectedPanel,
  } = usePageContext();

  const canCurrentUserEdit = userCanEditMedicalHistoryField(
    user.getOptional(),
    reviewMode,
  );

  if (selectedPanel !== 'medications') return null;

  return (
    <HistoryBox
      canEdit={canCurrentUserEdit}
      title="Medications"
      property="medications"
      medicalHistory={currentMedicalHistory.getOptional()}
      onUpdateMedicalHistory={setCurrentMedicalHistory}
      patientId={patient
        .getOptional()
        .map(p => p.id)
        .orElse(0)}
      instructions="Please provide the name of medication, dose with units, route, frequency and include any notes next to it if it is relevant."
    />
  );
}
