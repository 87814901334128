import {Link, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import React, {useState, useCallback} from 'react';
import {Button} from '../ui/form';
import {Modal} from '../ui/layout/Modal';
import {useWindowEvent} from '@src/hooks';
import {ApplicationEvents} from '@src/events';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instructionsText: {
      fontSize: 18,
      color: theme.palette.common.black,
      marginTop: '1rem',
    },
  }),
);

// eslint-disable-next-line import/no-unused-modules
export default function GetHelpModal() {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const classes = useStyles();

  useWindowEvent(ApplicationEvents.RequestHelp, () => {
    setShowHelpModal(true);
  });

  const onClose = useCallback(() => setShowHelpModal(false), []);

  return (
    <Modal show={showHelpModal} onClose={onClose} title="Help">
      <Typography variant="body1" className={classes.instructionsText}>
        If you are having technical difficulties and need to reach a provider
        with{' '}
        <div className="underline inline-block">
          <Link
            target="_blank"
            href="https://montanapediatrics.org/contact-us/"
          >
            Montana Pediatrics
          </Link>
        </div>
        , you can reach them at the following numbers:
      </Typography>
      <div className="font-bold mt-4">Daytime Hours (7:30am - 6:00pm MT)</div>
      <Typography>(406) 272-4631 </Typography>
      <div className="font-bold mt-4">After-Hours (6:00pm - 7:30am MT)</div>
      <Typography>(855) 532-7913</Typography>
      <hr className="my-8" />
      <Typography>
        For general technical help or issues, contact{' '}
        <div className="underline inline-block">
          <Link
            target="_blank"
            href="https://vynemedical.com/contact/?utm_source=direct"
          >
            Vyne Medical
          </Link>
        </div>{' '}
        via phone or email at:
      </Typography>
      <div className="font-bold mt-4">Urgent Technical Concerns:</div>
      <Typography>(877) 864-2378</Typography>
      <div className="font-bold mt-4">Non-Urgent Technical Concerns:</div>
      <Typography>
        <Link href="mailto:support@vynecorp.com">support@vynecorp.com</Link>
      </Typography>
      <div className="flex items-center justify-end mt-10">
        <Button onClick={onClose}>Close</Button>
      </div>
    </Modal>
  );
}
