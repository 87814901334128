import React from 'react';
import {TableCell, Typography} from '@material-ui/core';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';

export function getColumn({width, content}: {width: string; content?: string}) {
  return (
    <TableCell width={width}>
      {content && (
        <Typography color="primary" variant="h6">
          <Bold>{content}</Bold>
        </Typography>
      )}
    </TableCell>
  );
}
