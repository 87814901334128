import {Typography, Grid} from '@material-ui/core';
import React from 'react';
import {PatientDetailsValues} from '../patientDetailsUtils';
import {MedicalHistoryValues} from '../../NewPatient/steps/MedicalHistoryStep/PatientMedicalHistoryForm';
import {useStyles} from './styles';
import {
  MedicalHistoryUIItem,
  getMedicalHistoryRenderList,
} from '@src/components/shared/forms/medicalHistoryFunctions';

type Props = {
  patientDetails: PatientDetailsValues;
  medicalHistory: MedicalHistoryValues;
  patientIsUser: boolean;
};

export function MedicalHistorySummmary({
  medicalHistory,
  patientDetails,
  patientIsUser,
}: Props) {
  const classes = useStyles();
  const renderItem = (item: MedicalHistoryUIItem) => {
    if (!medicalHistory[item.name]) {
      return null;
    }

    return (
      <Grid item key={item.name}>
        <b>{item.title}</b>: {medicalHistory[item.name] || 'Not given'}
      </Grid>
    );
  };

  const populatedList = getMedicalHistoryRenderList({
    patientName: patientDetails.firstName,
    excludeMeds: false,
    patientIsUser,
  })
    .filter(i => i.name !== 'allergies')
    .filter(item => !!medicalHistory[item.name]);

  if (populatedList.length === 0) {
    return null;
  }

  return (
    <div className={classes.section}>
      <Typography variant="h2" className={classes.heading}>
        Medical History
      </Typography>
      <Grid container direction="column">
        {populatedList.map(renderItem)}
      </Grid>
    </div>
  );
}
