import {useEffect, useMemo, useState} from 'react';
import {AsyncData} from '@ahanapediatrics/ahana-fp';
import {useGroupsProviders} from '../periods/useGroupsProviders';
import {OnCallPeriod, ProviderDetails} from '@src/models';
import {isNothing} from '@src/util/typeTests';

export type DayComponentResources = {
  setPeriodToPopulate: (p: OnCallPeriod | null) => unknown;
  periodToPopulate: OnCallPeriod | null;
  providerOptionsForPeriodToPopulate: AsyncData<ProviderDetails>;
};

export const useDayComponentResources = (): DayComponentResources => {
  const [periodToPopulate, setPeriodToPopulate] = useState<OnCallPeriod | null>(
    null,
  );

  const [
    providerOptionsForPeriodToPopulate,
    reloadProviderOptionsForPeriodToPopulate,
    ,
    resetResources,
  ] = useGroupsProviders(periodToPopulate?.id ?? 0);

  useEffect(() => {
    if (isNothing(periodToPopulate)) {
      resetResources();
    } else {
      reloadProviderOptionsForPeriodToPopulate();
    }
  }, [
    periodToPopulate,
    reloadProviderOptionsForPeriodToPopulate,
    resetResources,
  ]);

  return useMemo(
    () => ({
      setPeriodToPopulate,
      periodToPopulate,
      providerOptionsForPeriodToPopulate,
    }),
    [setPeriodToPopulate, periodToPopulate, providerOptionsForPeriodToPopulate],
  );
};
