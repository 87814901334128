import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {format} from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import {PhoneNumber} from '@src/components/ui/layout/PhoneNumber';
import {sm} from '@src/components/ui/theme';
import {UserAvatar} from '@src/components/ui/layout/UserAvatar';
import {ResponsiblePerson} from '@src/models';
import {toSentenceCase} from '@src/util/stringManipulation/toTitleCase';
import {isNothing} from '@src/util/typeTests';

const ContactInfoItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: ${sm};
  margin-top: ${sm};
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const ContactFieldName = styled.div`
  min-width: 8em;
  font-weight: bold;
`;

const ContactList = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70vw;
`;

type Props = {
  person: ResponsiblePerson | null;
  relationship: string;
  cardContainerStyles?: Object;
};

const useStyles = makeStyles(theme => ({
  cardHeader: {
    paddingBottom: 0,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 16,
    color: 'rgba(255,255,255,0.5)',
  },
}));

export const ResponsiblePersonCard = ({
  person,
  relationship,
  cardContainerStyles = {},
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isNothing(person)) {
    return null;
  }

  const name = `${person.firstName} ${person.lastName}`;
  const rel = relationship;
  const phone = person.phone || '';

  return (
    <Grid
      container
      direction={isMobile ? 'column' : 'row'}
      style={cardContainerStyles}
    >
      <Card>
        <CardHeader
          avatar={<UserAvatar user={person} />}
          className={classes.cardHeader}
          title={name}
          subheader={rel ? `(${toSentenceCase(rel)})` : ''}
        />
        <CardContent>
          <ContactList>
            <ContactInfoItem>
              <ContactFieldName>DOB:</ContactFieldName>
              <div>
                {person.dob.map(d => format(d, 'MM.dd.yyyy')).orElse('-')}
              </div>
            </ContactInfoItem>
            <ContactInfoItem>
              <ContactFieldName>Primary phone:</ContactFieldName>{' '}
              <PhoneNumber no={phone} />
            </ContactInfoItem>
            <ContactInfoItem>
              <ContactFieldName>Email:</ContactFieldName>{' '}
              {person.email.orElse('-')}
            </ContactInfoItem>
            <ContactInfoItem>
              <ContactFieldName>Address:</ContactFieldName>{' '}
              <div>
                {person.address.line1}
                <br />
                {(person.address.line2 ?? '').length > 0 && (
                  <>
                    {person.address.line2}
                    <br />
                  </>
                )}
                {person.address.city}
                <br />
                {person.address.state} {person.address.zipcode}
              </div>
            </ContactInfoItem>
          </ContactList>
        </CardContent>
      </Card>
    </Grid>
  );
};
