import {makeStyles} from '@material-ui/core';
import {md, primary, xs} from '@src/components/ui/theme';

export const useStyles = makeStyles(() => ({
  root: {padding: xs},
  connected: {
    padding: md,
    border: `solid thin ${primary}`,
    borderRadius: xs,
  },
  buttonContainer: {
    margin: 'auto',
    width: '40%',
  },
}));
