import React, {useState} from 'react';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import {Tooltip} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.main,
      cursor: 'default',
      fontSize: 'inherit',
    },
  }),
);

export function HelpToolTip({text}: {text: string}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const classes = useStyles();

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowTooltip(false);
      }}
    >
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        title={text}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <HelpIcon
          style={{cursor: 'pointer'}}
          onClick={e => {
            setShowTooltip(!showTooltip);
          }}
          className={classes.icon}
        />
      </Tooltip>
    </ClickAwayListener>
  );
}
