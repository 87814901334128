import React from 'react';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';

export function BasicInstructions() {
  return (
    <ParagraphText>
      Click the button below to upload a file from your device.
    </ParagraphText>
  );
}
