import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Optional} from '@ahanapediatrics/ahana-fp';

import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {LonelyVisit, VisitDocumentation} from '../../models';
import {isNothing} from '../../util/typeTests';
import {VisitDocumentationModal} from '../providerSide/VisitDocumentationModal';
import {lg, primaryLight, xxs} from '../ui/theme';
import {Modal} from '../ui/layout/Modal';
import {VisitDocumentationModal as EditModal} from './VideoChat/ExamRoomModals/VisitDocumentationModal';
import {useResources} from '@src/hooks';
import {useApi} from '@src/api/useApi';
import {visitDocumentationToValues} from '@src/components/providerSide/VisitDocumentationForm/functions';

type Props = {
  visit: LonelyVisit;
  onUpdateVisit: (a: LonelyVisit) => void;
};

const VisitLinkButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${xxs};
  height: ${lg};
  width: ${lg};
  margin: ${xxs};

  .disabled {
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    :not(.disabled) {
      cursor: pointer;
      color: ${primaryLight};
    }
  }

  .svg-inline--fa {
    width: 100%;
    height: 75%;
  }
`;

export function VisitLink({visit, onUpdateVisit}: Props) {
  const api = useApi();

  const [documentation, , setDocumentation] = useResources<VisitDocumentation>(
    () => api.visit(visit.id).getDocumentation(),
    [api, visit.id],
  );

  const [showVisitModal, setShowVisitModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEditButtonClicked = useCallback(() => {
    setShowEditModal(true);
  }, [setShowEditModal]);

  const handleViewDocumentationClicked = useCallback(() => {
    setShowVisitModal(true);
  }, [setShowVisitModal]);

  const handleCancelViewDocumentation = useCallback(() => {
    setShowVisitModal(false);
  }, [setShowVisitModal]);

  // Purposefully empty.
  const removeNote = useCallback(() => {}, []);

  const initialValues = documentation.map(visitDocumentationToValues);

  const isDocumentationSigned = visit.visitDocumentation
    .map(v => v.signature)
    .filter(s => !isNothing(s.signingDate))
    .isPresent();

  if (!visit || !documentation.isLoaded()) {
    return null;
  }

  return (
    <>
      {isDocumentationSigned ? (
        <>
          <VisitLinkButton onClick={handleViewDocumentationClicked}>
            <FontAwesomeIcon icon={'file-contract'} />
          </VisitLinkButton>
          <VisitLinkButton className={'disabled'}>
            <FontAwesomeIcon icon={'file-circle-plus'} className={'disabled'} />
          </VisitLinkButton>
        </>
      ) : (
        <>
          <VisitLinkButton className={'disabled'}>
            <FontAwesomeIcon icon={'file-contract'} className={'disabled'} />
          </VisitLinkButton>
          <VisitLinkButton onClick={handleEditButtonClicked}>
            <FontAwesomeIcon icon={'file-circle-plus'} />
          </VisitLinkButton>
        </>
      )}
      <VisitDocumentationModal
        note={visit}
        onClose={handleCancelViewDocumentation}
        show={showVisitModal}
        updateNote={onUpdateVisit}
        removeNote={removeNote}
        canReview={true}
      />
      <Modal
        title="Edit Documentation"
        show={showEditModal}
        onClose={() => setShowEditModal(false)}
      >
        <EditModal
          oVisit={Optional.of(visit)}
          onDocumentationSave={setDocumentation}
          showDocumentation={showEditModal}
          toggleDocumentation={editModal => {
            if (editModal) {
              setShowEditModal(!editModal);
            } else {
              setShowEditModal(editModal);
            }
          }}
          documentation={documentation}
          initialValues={initialValues}
        />
      </Modal>
    </>
  );
}
