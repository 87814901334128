import React from 'react';
import {TableCell, Typography} from '@material-ui/core';
import {useStyles} from '../../shared/Tables/styles';
import {BasicDetailsResult} from './utils/resultFunctions';
import {BasicDetailsUIElement} from './utils/getBasicDetailsRows';

type Props = {
  result: BasicDetailsResult;
  row: BasicDetailsUIElement;
};

export function ResultCell({result, row}: Props) {
  const classes = useStyles();

  return (
    <TableCell className={classes.tableCell}>
      <Typography color="primary" variant="body1">
        {result[row.name].text}
      </Typography>
    </TableCell>
  );
}
