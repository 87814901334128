import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import {Alert} from '@src/components/ui/layout/Alert';
import {md} from '@src/components/ui/theme';

const WarningBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const WarningText = styled.div`
  margin-left: ${md};
`;

export const PHIWarning = () => (
  <Alert aStyle="warn">
    <WarningBody>
      <FontAwesomeIcon icon="exclamation-triangle" />
      <WarningText>
        <div>This printout contains personal health information (PHI)</div>
        <div>
          Healthcare Providers must treat this as HIPAA-protected PHI, secure it
          appropriately during use and dispose of it appropriately after use.
        </div>
      </WarningText>
    </WarningBody>
  </Alert>
);
