import {Optional} from '@ahanapediatrics/ahana-fp';
import {Mergeable} from '.';
import {Patient} from '@src/models';

export const isScpSectionMergeable = ({
  p1,
  p2,
}: {
  p1: Optional<Patient>;
  p2: Optional<Patient>;
}): Mergeable => {
  const p1HasScp = p1.map(p => p.hasScp).orElse(false);
  const p2HasScp = p2.map(p => p.hasScp).orElse(false);

  if (p1HasScp || p2HasScp) {
    return 'FAIL';
  } else {
    return 'PASS';
  }
};
