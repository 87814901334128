import React from 'react';
import styled from 'styled-components';
import {NoPcpMessage} from './layout';
import {Banner} from '@src/components/ui/layout/Banner';
import {Context} from '@src/util/provider/forms/addOrEdit';
import {md} from '@src/components/ui/theme';

type Props = {
  selectionOrEditContext: Context;
};

const Message = styled.div`
  margin-bottom: ${md};
`;

const MESSAGES: Record<Context, JSX.Element | null> = {
  [Context.SharedCarePlanOwner]: (
    <Banner type="warning" style={{marginBottom: '1rem'}}>
      You've told us that you don't have any information about which doctor
      should own this shared care plan. You can continue creating and editing
      the care plan, but it will be in a draft state until a doctor is
      designated as its owner.
    </Banner>
  ),
  [Context.AddMedicalTeamMember]: (
    <Message>
      To add a team member to this care plan, you can either search for a
      provider already in the Refyne Connected Care system or add a new one.
    </Message>
  ),
  [Context.EditMedicalTeamMember]: null,
  [Context.PrimaryCareProvider]: null,
};

export function NoProviderSelectedBanner({selectionOrEditContext}: Props) {
  const message = MESSAGES[selectionOrEditContext];
  if (message !== null) {
    return message;
  }
  return (
    <NoPcpMessage>
      You've told us that you don't have any information about this patient's{' '}
      doctor
    </NoPcpMessage>
  );
}
