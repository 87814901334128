import {makeStyles} from '@material-ui/core/styles';
import {md, sm, lg, xl} from '@src/components/ui/theme';

export const useStyles = makeStyles(theme => ({
  // Instructions
  comparerMessage: {
    padding: md,
  },
  // Icons
  checkIcon: {
    color: theme.palette.success.main,
    marginLeft: sm,
  },
  // Cards
  cardColumn: {
    padding: sm,
  },
  heading: {
    marginBottom: sm,
  },
  subtitle: {
    marginLeft: sm,
    display: 'inline-block',
  },
  buttonContainer: {
    marginTop: sm,
    margin: '0 auto',
  },
  cardButton: {paddingRight: xl, paddingLeft: xl},
  // Stepper
  root: {
    width: '100%',
  },
  button: {
    marginTop: sm,
    marginRight: sm,
  },
  actionsContainer: {
    marginTop: lg,
  },
  resetContainer: {
    padding: md,
  },
  buttonsContainer: {
    marginTop: lg,
  },

  // Containers
  messageContainer: {
    padding: md,
  },
}));
