import {Badge, createStyles, Typography, withStyles} from '@material-ui/core';
import React from 'react';
import {useStyles} from './layout';
import {
  LabelContainer,
  LabelContents,
  LabelDescription,
  ButtonContainer,
} from '@src/components/shared/SelectorLabels';
import {Button} from '@src/components/ui/form';
import {CallPoolOption} from '@src/util/callPools/sortCallPools';
import {useUser} from '@src/hooks';
import {getCallPoolName} from '@src/util/callPools/getCallPoolName';

type Props = {
  callPoolOption: CallPoolOption;
  selected: boolean;
  onClick: (id: number) => unknown;
};

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      right: -8,
      top: 4,
    },
  }),
)(Badge);

export function CallPoolLabel({callPoolOption, selected, onClick}: Props) {
  const classes = useStyles();
  const childCount = callPoolOption.children.length;

  const [, userType] = useUser();

  return (
    <LabelContainer selected={selected} style={{padding: '.5em .5em'}}>
      <LabelContents>
        <StyledBadge color="primary" badgeContent={childCount}>
          <Typography variant="h4" className={classes.poolName}>
            {getCallPoolName(callPoolOption, userType)}
          </Typography>
        </StyledBadge>

        <LabelDescription>{callPoolOption.description} </LabelDescription>
      </LabelContents>
      {callPoolOption.available && (
        <ButtonContainer>
          <Button
            bStyle="contained"
            onClick={e => {
              e.preventDefault();
              onClick(callPoolOption.id);
            }}
          >
            Select this option
          </Button>
        </ButtonContainer>
      )}
    </LabelContainer>
  );
}
