import {SCPChangeRequest, User} from '@src/models';
import {isNothing} from '@src/util/typeTests';

export function isUserRequester(cr: SCPChangeRequest, user?: User) {
  if (isNothing(user)) {
    return false;
  }

  return user.id === cr.requester?.id;
}
