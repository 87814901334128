import {BasicDetailsResult} from '../../BasicDetailsComparer/Rows/utils/resultFunctions';
import {MedicalHistoryResult} from '../../MedicalHistoryComparer/Rows/utils/resultFunctions';

import {Mergeable} from '.';

export const isDetailsSectionMergeable = (
  result: BasicDetailsResult | MedicalHistoryResult,
): Mergeable => {
  if (
    Object.values(result).every(
      fieldResult =>
        fieldResult.text.length > 0 && fieldResult.radioSelection !== null,
    )
  ) {
    return 'PASS';
  } else {
    return 'CONFLICT';
  }
};
