import {OptimizelyDecision} from '@optimizely/react-sdk';
import React, {lazy} from 'react';
import {Routes} from '../../shared/general/AppRoutes';
import {ProtectedRoute} from '../../shared/general/ProtectedRoute';
import {UserType} from '@src/models';

const PastVisitsDashboard = lazy(() =>
  import('../../providerSide/PastVisitsDashboard'),
);

const ProviderGroupRosterPage = lazy(() =>
  import('../../providerSide/providerGroups/ProviderGroupRosterPage'),
);

const ProviderGroupOverviewPage = lazy(() =>
  import('../../providerSide/providerGroups/ProviderGroupOverviewPage'),
);

const ReportingOverview = lazy(() =>
  import('../../providerSide/reporting/ReportingOverview'),
);
const ProviderInfoPage = lazy(() =>
  import('../../providerSide/ProviderInfoPage'),
);

const OnCallDashboard = lazy(() =>
  import('../../providerSide/oncall/OnCallDashboard'),
);

const NewPatientDeadEnd = lazy(() =>
  import(
    '../../shared/forms/NewPatient/steps/PatientDetailsStep/NewPatientDeadEnd'
  ),
);
const MergePatientsDeadEnd = lazy(() =>
  import('../../providerSide/MergePatients/MergePatientsDeadEnd'),
);

const ProviderDashboard = lazy(() =>
  import('../../providerSide/ProviderDashboard'),
);
const ShiftsAndGroupsPage = lazy(() =>
  import('../../providerSide/ShiftsAndGroupsPage'),
);

const CallOutPage = lazy(() => import('../../providerSide/CallOutPage'));
const CreateVisit = lazy(() => import('../../providerSide/CreateVisit'));
const MergePatients = lazy(() => import('../../providerSide/MergePatients'));
const DocumentationRoom = lazy(() =>
  import('../../providerSide/DocumentationRoom'),
);
const PatientSearchPage = lazy(() =>
  import('../../providerSide/hcu/PatientSearchPage'),
);

const StoredReportsPage = lazy(() =>
  import('../../providerSide/StoredReportsPage'),
);

export function ProviderSideRoutes({
  mergePatients,
}: {
  mergePatients: OptimizelyDecision;
}) {
  const providerSideRoutes = [
    <ProtectedRoute
      path="/on-call"
      key="/on-call"
      component={OnCallDashboard}
      requiredUserType={[UserType.Professional]}
    />,
    <ProtectedRoute
      path="/reporting"
      key="/reporting"
      component={ReportingOverview}
      requiredUserType={[UserType.Professional]}
    />,
    <ProtectedRoute
      path="/past-visits"
      key="/past-visits"
      component={PastVisitsDashboard}
      requiredUserType={[UserType.Professional]}
    />,
    <ProtectedRoute
      path="/provider-info"
      key="/provider-info"
      component={ProviderInfoPage}
      requiredUserType={[UserType.Professional]}
    />,
    <ProtectedRoute
      path="/shifts-and-groups"
      key="/shifts-and-groups"
      component={ShiftsAndGroupsPage}
      requiredUserType={[UserType.Professional]}
    />,
    <ProtectedRoute
      path="/new-patient/dead-end/:patientId"
      key="/new-patient/dead-end/:patientId"
      component={NewPatientDeadEnd}
      requiredUserType={[UserType.Professional]}
    />,
    <ProtectedRoute
      path={Routes.Documentation}
      key={Routes.Documentation}
      component={DocumentationRoom}
      requiredUserType={[UserType.Professional]}
    />,
    <ProtectedRoute
      path="/call-out/:visitId"
      key="/call-out/:visitId"
      component={CallOutPage}
      requiredUserType={[UserType.Professional]}
    />,
    <ProtectedRoute
      exact
      path={['/patient-search', '/patient-search/:id']}
      key={'/patient-search'}
      component={PatientSearchPage}
      requiredUserType={[UserType.Professional]}
    />,
    <ProtectedRoute
      path="/create-visit/:patientId"
      key="/create-visit/:patientId"
      component={CreateVisit}
      requiredUserType={[UserType.Professional]}
    />,
    <ProtectedRoute
      exact
      path={['/provider-dashboard']}
      key={'/provider-dashboard'}
      component={ProviderDashboard}
      requiredUserType={[UserType.Professional]}
    />,
    <ProtectedRoute
      exact
      path={Routes.StoredReports}
      key={Routes.StoredReports}
      component={StoredReportsPage}
      requiredUserType={[UserType.Professional]}
    />,
    <ProtectedRoute
      path={'/provider-group/:id/roster'}
      key={'/provider-group/:id/roster'}
      component={ProviderGroupRosterPage}
      requiredUserType={[UserType.Professional]}
    />,
    <ProtectedRoute
      path={'/provider-group/:id'}
      key={'/provider-group/:id'}
      component={ProviderGroupOverviewPage}
      requiredUserType={[UserType.Professional]}
    />,
  ];

  if (mergePatients.enabled) {
    providerSideRoutes.push(
      <ProtectedRoute
        path="/merge-patients/:firstId/:secondId"
        key="/merge-patients/:firstId/:secondId"
        component={MergePatients}
        requiredUserType={[UserType.Professional]}
      />,
      <ProtectedRoute
        path="/merge-patients/dead-end"
        key="/merge-patients/dead-end"
        component={MergePatientsDeadEnd}
        requiredUserType={[UserType.Professional]}
      />,
    );
  }

  return providerSideRoutes;
}

export default ProviderSideRoutes;
