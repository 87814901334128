import {HAS_CONFLICT_MESSAGE} from './saveForProvider/selfRelationship/checkForConflictsAndLink';
import {flashError} from '@src/components/shared/notifications/flash';

export function handleErrors(e: Error): null {
  if (e.message === HAS_CONFLICT_MESSAGE) {
    flashError(HAS_CONFLICT_MESSAGE);
  }

  flashError('Something went wrong creating a new patient.');

  return null;
}
