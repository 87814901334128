import {AsyncData, Optional} from '@ahanapediatrics/ahana-fp';
import {Guardian, User, UserType, ResponsiblePerson} from '@src/models';
import {
  PatientDetailsValues,
  personToPatientDetailsValues,
} from '@src/components/shared/forms/SharedPatient/patientDetailsUtils';

type ToggleMyDetailsProps = {
  shouldUseDetails: boolean;
  setExistingPersonForIndependentPatient?: (
    v: Optional<ResponsiblePerson>,
  ) => unknown;
  clearErrors: () => unknown;
  user: AsyncData<User>;
  setValues: (v: PatientDetailsValues) => unknown;
  getValues: () => PatientDetailsValues;
  defaultValues: PatientDetailsValues;
};

export const toggleMyDetails = ({
  shouldUseDetails,
  setExistingPersonForIndependentPatient,
  clearErrors,
  user,
  getValues,
  setValues,
  defaultValues,
}: ToggleMyDetailsProps) => {
  const userAsPerson = user
    .getOptional()
    .cast<Guardian>(u => u.userType === UserType.Guardian)
    .map(g => g.responsiblePersonDetails);

  if (!setExistingPersonForIndependentPatient) {
    return;
  }

  clearErrors();

  if (shouldUseDetails) {
    setExistingPersonForIndependentPatient(userAsPerson);

    setValues(
      personToPatientDetailsValues(userAsPerson, {
        ...getValues(),
        isSelf: true,
      }),
    );
  } else {
    setExistingPersonForIndependentPatient(Optional.empty());
    setValues({
      ...defaultValues,
      authorized: false,
    });
  }
};
