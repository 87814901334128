/*
 * A collection of basic set operations
 * Credit to https://stackoverflow.com/a/33034768/261122
 */

/*
 * Corresponds to set operation:
 *
 *  arr1 ∩ arr2
 */
// eslint-disable-next-line import/no-unused-modules
export const intersection = <T>(arr1: T[], arr2: T[]) =>
  arr1.filter(x => arr2.includes(x));

/*
 * Corresponds to set operation:
 *
 *  arr1 - arr2
 *
 * That is, the relative complement or difference of 2 sets
 */
export const difference = <T>(arr1: readonly T[], arr2: readonly T[]) =>
  arr1.filter(x => !arr2.includes(x));

/*
 * Corresponds to set operation:
 *
 *  arr1 Δ arr2
 *
 * That is, the symmetric difference of 2 sets
 */
// eslint-disable-next-line import/no-unused-modules
export const symmetricDifference = <T>(arr1: T[], arr2: T[]) =>
  arr1
    .filter(x => !arr2.includes(x))
    .concat(arr2.filter(x => !arr1.includes(x)));
