import {Optional} from '@ahanapediatrics/ahana-fp';
import {userIsProfessional} from '../users/userIsProfessional';
import {SharedCarePlan, SCPStatus, User} from '@src/models';

export const userCanEditProtectedField = (
  user: Optional<User>,
  scp: Optional<SharedCarePlan>,
) =>
  userIsProfessional(user) &&
  scp.map(m => m.status).orNothing() !== SCPStatus.Draft;

export const userCanEditMedicalHistoryField = (
  user: Optional<User>,
  reviewMode: boolean,
) => userIsProfessional(user) || !reviewMode;
