import {differenceInHours, differenceInMinutes} from 'date-fns';
import {PeriodToEdit, getNewPeriod} from '../../functions';
import {OnCallPeriod} from '@src/models';

export const convertOnCallPeriod = (oc: OnCallPeriod): PeriodToEdit => {
  const p = getNewPeriod();
  p.id = oc.id;
  p.startDate = oc.start;
  p.startTime = oc.start;
  p.hours = differenceInHours(oc.end, oc.start);
  p.minutes = Math.max(0, differenceInMinutes(oc.end, oc.start) - p.hours * 60);
  p.selectedCallPools = oc.callPools.map(c => c.id);

  return p;
};
