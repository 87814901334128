import React from 'react';
import {Grid} from '@material-ui/core';
import {useStyles} from './layout';

type Props = {className?: string};
export function NoConnectedLogins({className = ''}: Props) {
  const classes = useStyles();
  return (
    <Grid container className={className} direction="row" spacing={0}>
      <Grid item className={classes.messageText}>
        This Patient doesn't have any Connected Logins yet
      </Grid>
    </Grid>
  );
}
