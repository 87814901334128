/* eslint-disable import/no-unused-modules */
import {defaultBasicDetailsResult} from '../BasicDetailsComparer/Rows/utils/basicDetailsResultFunctions';
import {BasicDetailsResult} from '../BasicDetailsComparer/Rows/utils/resultFunctions';
import {defaultMedicalHistoryResult} from '../MedicalHistoryComparer/Rows/utils/medicalHistoryResultFunctions';
import {MedicalHistoryResult} from '../MedicalHistoryComparer/Rows/utils/resultFunctions';
import {ConnectedLoginsResult} from '../shared/types';
import {InputValue, useInput} from '@src/hooks';
import {ProviderDetails, ResponsiblePerson} from '@src/models';
import {PaymentInformationResult} from '@src/models/Patient';

type Results = {
  basicDetailsResult: BasicDetailsResult;
  paymentInformationResult: PaymentInformationResult;
  pcpResult: ProviderDetails | null;
  financiallyResponsibleResult: ResponsiblePerson | null;
  financiallyResponsibleName: InputValue<string | null>;
  connectedLogins: ConnectedLoginsResult;
  medicalHistoryResult: MedicalHistoryResult;
};

type ResultAction =
  | {
      type: 'SET_BASIC_DETAILS';
      basicDetails: BasicDetailsResult;
    }
  | {type: 'SET_PAYMENT_INFO'; paymentInfo: PaymentInformationResult}
  | {type: 'SET_PCP'; providerDetails: ProviderDetails | null}
  | {
      type: 'SET_FINANCIALLY_RESPONSIBLE_RESULT';
      financiallyResponsiblePerson: ResponsiblePerson | null;
    }
  | {
      type: 'SET_FINANCIALLY_RESPONSIBLE_NAME';
      financiallyResponsibleName: string | null;
    }
  | {type: 'SET_CONNECTED_LOGINS'; connectedLogins: ConnectedLoginsResult}
  | {
      type: 'SET_MEDICAL_HISTORY';
      medicalHistory: MedicalHistoryResult;
    };

export function useInitialResultsState(): Results {
  return {
    basicDetailsResult: defaultBasicDetailsResult,
    paymentInformationResult: {},
    pcpResult: null,
    financiallyResponsibleResult: null,
    financiallyResponsibleName: useInput<null | string>(null),
    connectedLogins: {},
    medicalHistoryResult: defaultMedicalHistoryResult,
  };
}

export function updatingResultReducer(state: Results, action: ResultAction) {
  switch (action.type) {
    case 'SET_BASIC_DETAILS':
      return {...state, basicDetailsResult: action.basicDetails};
    case 'SET_PAYMENT_INFO':
      return {...state, paymentInformationResult: action.paymentInfo};
    case 'SET_PCP':
      return {...state, pcpResult: action.providerDetails};
    case 'SET_FINANCIALLY_RESPONSIBLE_RESULT':
      return {
        ...state,
        financiallyResponsibleResult: action.financiallyResponsiblePerson,
      };
    case 'SET_FINANCIALLY_RESPONSIBLE_NAME':
      state.financiallyResponsibleName.set(action.financiallyResponsibleName);
      return state;
    case 'SET_CONNECTED_LOGINS':
      return {...state, connectedLogins: action.connectedLogins};
    case 'SET_MEDICAL_HISTORY':
      return {...state, medicalHistory: action.medicalHistory};
    default:
      return state;
  }
}
