import {useEffect, useRef} from 'react';
import {Handler, MessageType, useSocketContext} from '@src/contexts';
import {LonelyVisit} from '@src/models';

export const useVisitUpdateEvents = (
  visitIds: readonly number[],
  listener: Handler<MessageType.VisitUpdate>,
) => {
  const socket = useSocketContext();

  const visitIdsRef = useRef(visitIds);
  const listenerRef = useRef(listener);

  useEffect(() => {
    visitIdsRef.current = visitIds;
  }, [visitIds]);

  useEffect(() => {
    listenerRef.current = listener;
  }, [listener]);

  useEffect(() => {
    const handleVisitUpdate = (visit: LonelyVisit) => {
      if (visitIdsRef.current.includes(visit.id)) {
        listenerRef.current(visit);
      }
    };

    socket.on(MessageType.VisitUpdate, handleVisitUpdate);

    return () => {
      socket.off(MessageType.VisitUpdate, handleVisitUpdate);
    };
  }, [socket]);
};
