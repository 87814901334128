import React from 'react';
import {DeadEndPage} from '@src/components/ui/layout/pages/deadEnd/DeadEndPage';
import {ParagraphText} from '@src/components/ui/layout/text/body/ParagraphText';
import {Bold} from '@src/components/ui/layout/text/decoration/Bold';
import {useQuery} from '@src/hooks/useQuery';

// eslint-disable-next-line import/no-unused-modules
export default function MergePatientsDeadEnd() {
  const query = useQuery();
  const notFound = query.get('reason') === 'not-found';
  const patientsMatch = query.get('reason') === 'patients-match';

  return (
    <DeadEndPage
      title="Oops! Something went wrong."
      buttonText="Start Over"
      buttonTarget="/patient-search"
      className=""
      severity="info"
    >
      {patientsMatch && (
        <ParagraphText>
          It looks like you selected the same Patient twice to merge. Please
          select two separate Patients before merging.
        </ParagraphText>
      )}

      {notFound && (
        <ParagraphText>
          It looks like one of the Patients could not be found. Please make sure
          the Patient you selected is still in our system.
        </ParagraphText>
      )}

      <ParagraphText>
        To try again, please click <Bold>Start Over</Bold>.
      </ParagraphText>
    </DeadEndPage>
  );
}
