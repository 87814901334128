import styled from 'styled-components';
import {
  md,
  xs,
  lighterGrey,
  xl,
  secondary,
  sm,
  primaryLight,
  dangerLightest,
  lg,
  secondaryText,
} from '@src/components/ui/theme';

export const Table = styled.div`
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
`;

export const StyledCell = styled.div<{
  orderNumber: number;
}>`
  order: ${props => `${props.orderNumber}`};
  box-sizing: border-box;
  flex-grow: 1;
  width: 14.25%;
  height: ${md};
  padding: ${md};
  overflow: hidden;
  border: solid ${xs} white;
  background: ${lighterGrey};
  text-align: center;
`;

export const StyledColumnHeading = styled(StyledCell)`
  height: ${xl};
  line-height: ${xl};
  padding: 0;
  background: ${secondary};
  color: '#ffffff';
  font-weight: 400;
  user-select: none;
  display: inline-block;
`;

export const CellText = styled.div<{
  cellLabel?: boolean;
  time?: boolean;
}>`
  font-size: ${md};
  font-weight: ${props => (props.cellLabel ? '500' : '300')};
  text-align: center;
  color: ${secondaryText};
  user-select: none;
  margin-bottom: ${props => (props.time ? `0` : `${sm}`)};
`;

export const StyledAssignment = styled.div`
  margin-top: ${md};
  box-sizing: border-box;
  height: 65%;
  background: ${primaryLight};
  text-align: center;
  padding: ${md};
`;

export const StyledNoProvider = styled(StyledAssignment)`
  background: ${dangerLightest};
`;

export const Icon = styled.div`
  cursor: pointer;
  position: relative;
  top: -35%;
  left: 40%;
`;

export const StyledRosterHeading = styled.div`
  margin-bottom: ${md};
  color: ${secondary};
  text-align: center;
  font-weight: 500;
  font-size: ${lg};
  line-height: 1em;
`;

export const MonthYear = styled.div`
  display: inline-block;
  user-select: none;
  width: 30%;
`;

export const ArrowWrapper = styled.span`
  width: ${md};
`;
