import styled from 'styled-components';

export const DataPointName = styled.div`
  display: inline-block;
  font-weight: bold;
  width: 5em;
`;

export const DataPointValue = styled.div`
  font-weight: normal;
`;
