import React from 'react';
import {useForm} from 'react-hook-form';
import {Optional} from '@ahanapediatrics/ahana-fp';
import {yupResolver} from '@hookform/resolvers';
import {ResponsiblePerson} from '../../../models';
import {GuardianInfoForm} from '../../shared/forms/GuardianInfoForm';
import {valuesToGuardianInfo} from '../../shared/forms/GuardianInfoForm/utilities';
import {
  responsiblePersonToValues,
  FinanciallyResponsiblePersonValues,
} from '../../shared/forms/FinanciallyResponsiblePersonFormModal/functions';
import {PageLoading} from '../../ui/atoms/progressBarsAndIndicators/PageLoading';
import {Modal} from '../../ui/layout/Modal';
import {useApi} from '@src/api/useApi';
import {plainGuardianSchema} from '@src/models/ResponsiblePerson';

type Props = {
  responsiblePerson: ResponsiblePerson;
  show: boolean;
  onHide: () => void;
  onClose?: () => void;
  onChangeResponsiblePerson?: (rp: ResponsiblePerson) => Promise<unknown>;
};

export function GuardianInfoModal({
  responsiblePerson,
  show,
  onHide,
  onClose,
  onChangeResponsiblePerson,
}: Props) {
  const api = useApi();

  const saveGuardianInfo = async (
    values: FinanciallyResponsiblePersonValues,
  ) => {
    const responsiblePersonPayload = valuesToGuardianInfo(values);

    let rp: ResponsiblePerson;
    if (responsiblePerson.id) {
      rp = await api.responsiblePerson(responsiblePerson.id).update({
        ...responsiblePersonPayload,
        id: responsiblePerson.id,
      });
    } else {
      rp = await api.responsiblePerson().create({
        ...responsiblePersonPayload,
      });
    }
    let waitBeforeClose: Promise<unknown> = Promise.resolve();
    if (onChangeResponsiblePerson) {
      waitBeforeClose = onChangeResponsiblePerson(rp);
    }

    await waitBeforeClose;

    onHide();
  };

  const defaultValues = responsiblePersonToValues(
    Optional.of(responsiblePerson),
  );
  const {
    errors,
    control,
    reset,
    handleSubmit,
    setValue,
    formState: {isSubmitting},
  } = useForm({
    defaultValues,
    resolver: yupResolver(plainGuardianSchema),
  });

  let closer = () => {};
  if (onClose) {
    closer = () => {
      reset();
      onHide();
      onClose();
    };
  }

  const onSubmit = handleSubmit(saveGuardianInfo);

  const setDob = (d: Date | null) => setValue('dob', d);

  return (
    <Modal show={show} onClose={closer} title="Guardian Information">
      <PageLoading
        active={!responsiblePerson}
        message={'Loading Guardian Info...'}
      >
        <GuardianInfoForm
          errors={errors}
          control={control}
          canEditEmail={!responsiblePerson.guardianId}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          setDob={setDob}
        />
      </PageLoading>
    </Modal>
  );
}
