import React from 'react';
import {Button, Paper} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {isNothing} from '@src/util/typeTests';

interface Props {
  name?: string | null;
  font?: string | null;
  imageUri?: string | null;
  readonly?: boolean;
  onEdit?: () => unknown;
}

export default function ReadView({
  font,
  imageUri,
  name,
  readonly = false,
  onEdit = () => {},
}: Props) {
  return (
    <Paper style={{display: 'inline-block'}}>
      {readonly || (
        <div style={{textAlign: 'right'}}>
          <Button onClick={onEdit} title="Edit Signature">
            <FontAwesomeIcon icon="pencil" />
          </Button>
        </div>
      )}
      {isNothing(imageUri) ? (
        <div
          style={{
            fontFamily: `'${font}', cursive`,
            fontSize: '26pt',
            padding: '0.75em',
          }}
        >
          {name}
        </div>
      ) : (
        <img src={imageUri} />
      )}
    </Paper>
  );
}
