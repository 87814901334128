import {Button, Card, CardActions, CardContent} from '@material-ui/core';
import * as H from 'history';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconName} from '@fortawesome/fontawesome-svg-core';

type Props = {
  className?: string;
  disabled?: boolean;
  history: H.History;
  link: string;
  icon: IconName;
  label: string;
};

export function ActionCard({
  className,
  disabled = false,
  link,
  history,
  icon,
  label,
}: Props) {
  return (
    <Card className={className ?? ''}>
      <CardContent>
        <FontAwesomeIcon
          fixedWidth
          size="lg"
          icon={['fad', disabled ? 'ban' : icon]}
          color="secondary"
        />
      </CardContent>
      <CardActions>
        <Button
          disabled={disabled}
          onClick={() => {
            history.push(link);
          }}
          size="small"
        >
          {label}
        </Button>
      </CardActions>
    </Card>
  );
}
