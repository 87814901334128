import React, {useState, useCallback} from 'react';
import {useTheme} from '@material-ui/core/styles';
import {
  Grid,
  IconButton,
  Paper,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {ProviderGroupDetails} from './ProviderGroupDetails';
import {useStyles} from './styles';
import {EditProviderGroupModal} from './EditProviderGroupModal';
import {CallPool} from '@src/models';
import {useApi} from '@src/api/useApi';
import {PayloadForProviderGroupDetails} from '@src/api/CallPoolAPI';

type Props = {
  providerGroup?: CallPool;
  onUpdateGroup: (group: CallPool) => unknown;
};

export function ProviderGroupCard({providerGroup, onUpdateGroup}: Props) {
  const api = useApi();
  const classes = useStyles();
  const theme = useTheme();
  const matchesMedium = useMediaQuery(theme.breakpoints.up('md'));
  const [showDetailsModel, setShowDetailsModal] = useState(false);

  const updateGroup = useCallback(
    (groupDetails: PayloadForProviderGroupDetails) =>
      api
        .callPool(providerGroup?.id ?? 0)
        .updateDetails(groupDetails)
        .then(onUpdateGroup),
    [api, onUpdateGroup, providerGroup],
  );

  if (!providerGroup) {
    return null;
  }

  return (
    <Grid
      className={classes.rowContainer}
      style={{marginBottom: '0'}}
      container
      direction={matchesMedium ? 'row' : 'column'}
    >
      {/* Left Side */}
      <Grid item md={5} xs={12}>
        <Paper className={classes.leftSection} style={{padding: '1rem 2rem'}}>
          <Grid
            container
            direction="row"
            className={classes.leftSectionContent}
          >
            <Grid item xs={1}>
              <IconButton
                onClick={() => {
                  setShowDetailsModal(true);
                }}
                className={classes.icon}
              >
                <EditIcon className={classes.icon} />
              </IconButton>
              <EditProviderGroupModal
                providerGroup={providerGroup}
                show={showDetailsModel}
                setShowEditProviderGroupModal={() => setShowDetailsModal(false)}
                setProviderGroupDetails={updateGroup}
              />
            </Grid>

            <Grid item xs>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.leftSectionContent}
              >
                <Typography variant="h2">
                  {providerGroup.nameForProviders}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* Right Side */}
      <Grid item md={7} xs={12}>
        <Paper className={classes.rightSection} style={{padding: '1rem 2rem'}}>
          <Grid item container direction="row">
            <ProviderGroupDetails providerGroup={providerGroup} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
